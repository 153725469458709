const dateFormat = {
  mmddyyyy: "MM/dd/yyyy",
  ddmmyyyy: "dd/MM/yyyy",
  yyyymmdd: "yyyy/MM/dd",
};

const DATE_FORMAT_INFO = {
  monthDayYear: {
    label: "MM/DD/YYYY",
    value: dateFormat.mmddyyyy,
  },
  dayMonthYear: {
    label: "DD/MM/YYYY",
    value: dateFormat.ddmmyyyy,
  },
  yearMonthDay: {
    label: "YYYY/MM/DD",
    value: dateFormat.yyyymmdd,
  },
};

const DATE_FORMAT_SETTING = [
  DATE_FORMAT_INFO.monthDayYear,
  DATE_FORMAT_INFO.dayMonthYear,
  DATE_FORMAT_INFO.yearMonthDay,
];

const EXPORT_TIME_FORMAT = {
  t12Hours: {
    label: "12 Hours",
    value: 1,
  },
  t24Hours: {
    label: "24 Hours",
    value: 2,
  },
};

const TIME_FORMAT_SETTING = [
  EXPORT_TIME_FORMAT.t24Hours,
  EXPORT_TIME_FORMAT.t12Hours,
];

const DATE_FORMAT_DEFAULT = DATE_FORMAT_INFO.monthDayYear;
const TIME_FORMAT_DEFAULT = EXPORT_TIME_FORMAT.t24Hours;

const MESSAGE_QUANTITY_CONFIG = [
  { label: "Tagged Only", value: 0 },
  { label: "Same calendar date", value: -1 },
  { label: "+-5", value: 5 },
  { label: "+-10", value: 10 },
  { label: "+-25", value: 25 },
  { label: "+-50", value: 50 },
];

const MESSAGE_CONFIG_DEFAULT = { label: "Tagged Only", value: 0 };

const DELIMITER_TYPE = {
  datDelimiter: {
    label: "Concordance DAT delimiter",
    value: `þ\u0014þ&&&þ`,
  },
  pipeTilde: {
    label: "Pipe/Tilde",
    value: "|&&&~",
  },
  commaQuote: {
    label: "Comma/Quote",
    value: `'&&&"`,
  },
};

const DELIMITER_SELECT_OPTION = [
  DELIMITER_TYPE.datDelimiter,
  DELIMITER_TYPE.pipeTilde,
  DELIMITER_TYPE.commaQuote,
];

const DELIMITER_OPTION_DEFAULT = DELIMITER_TYPE.datDelimiter;

const DOCUMENT_SIZE = {
  all: { label: "All", value: 1 },
  break24: {
    label: "24 Hours Size",
    value: 2,
  },
  breakOnGap: {
    label: "Break by conversation gap",
    value: 3,
  },
};

const DOCUMENT_SIZE_OPTION = [DOCUMENT_SIZE.break24, DOCUMENT_SIZE.all, DOCUMENT_SIZE.breakOnGap];

const DOCUMENT_SIZE_DEFAULT = DOCUMENT_SIZE.break24;

const CONTROL_PREFIX_DEFAULT = "DOC_";
const CONTROL_NUMBER_DEFAULT = 1;
const VOLUME_PREFIX_DEFAULT = "VOL";
const VOLUME_NUMBER_DEFAULT = 1;

const EXPORT_TYPE = {
  exportDAT: 1,
  exportNativeFiles: 2,
  exportRSMF: 4,
  exportEML: 5,
};

const EXPORT_ACTION = {
  saveAndRun: 1,
  save: 2,
  saveDraft: 3,
  reset: 0,
};

const EXPORT_SUMMARY_ITEM = {
  messageCount: "Messages",
  emailCount: "Emails",
  otherTypeCount: "Other Data Type",
  physicalFileCount: "Attachments",
  cost: "Estimated Cost",
  size: "Estimated Size",
  totalItems: "Total Items",
};

const EXPORT_PREVIEW_LIST_TYPE = {
  [EXPORT_TYPE.exportDAT]: "Export DAT",
  [EXPORT_TYPE.exportNativeFiles]: "Export Native File(s)",
  [EXPORT_TYPE.scheduleExport]: "Schedule Export",
  [EXPORT_TYPE.exportRSMF]: "Export RSMF",
  [EXPORT_TYPE.exportEML]: "Export EML",
};

const MSG_CONFIRM = {
  delete: "Are you sure you want to delete this export?",
  cancel: `This will cancel all your scheduled actions and change your export's status to "Ready". Are you sure you want to cancel?`,
  back: "You have unsaved changes. Are you sure you want to cancel? Tips: You can Save as Draft for “unsure” exports.",
  saveDraft: "Are you sure you want to save this export as draft?",
  reset: "Are you sure you want to reset export fields?",
};

const STATUS_EXPORT = {
  ready: {
    label: "Ready",
    value: 1,
    color: "#62b58f",
    background: "rgba(98, 181, 143, 0.15)",
  },
  completed: {
    label: "Completed",
    value: 2,
    color: "#8a8a8a",
    background: "rgba(138, 138, 138, 0.15)",
  },
  inprogress: {
    label: "In progress",
    value: 3,
    color: "#1580ef",
    background: "rgba(21, 128, 239, 0.1)",
  },
  fail: {
    label: "Failed",
    value: 4,
    color: "#F2726F",
    background: "rgba(242, 114, 111, 0.15)",
  },
  scheduled: {
    label: "Scheduled",
    value: 5,
    color: "#5D62B5",
    background: "rgba(93, 98, 181, 0.15)",
  },
  draft: {
    label: "Draft",
    value: 6,
    color: "#D9D9D9",
    background: "",
  },
  canceling: {
    label: "Cancelling",
    value: 7,
    color: "#F6A34F",
    background: "rgba(246, 163, 79, 0.3)",
  },
};

const ACTION_NAME = {
  run: "Run",
  clone: "Clone",
  delete: "Delete",
  cancel: "Cancel",
  reschedule: "Reschedule",
  cancelSchedule: "Cancel Schedule",
  restart: "Restart",
  edit: "Edit",
  download: "Download",
  print: "Print",
};

const BUTTON_ACTION = {
  saveDraft: 1,
  reset: 2,
  back: 3,
  breadCrumb: 4,
};

const EXPORT_TOOLTIP = `Or: select at least one field (Saved searches or Tag) <br />
And: select both fields`;

export {
  DATE_FORMAT_INFO,
  DATE_FORMAT_SETTING,
  DATE_FORMAT_DEFAULT,
  TIME_FORMAT_DEFAULT,
  TIME_FORMAT_SETTING,
  MESSAGE_QUANTITY_CONFIG,
  MESSAGE_CONFIG_DEFAULT,
  DELIMITER_TYPE,
  DELIMITER_SELECT_OPTION,
  DELIMITER_OPTION_DEFAULT,
  DOCUMENT_SIZE,
  DOCUMENT_SIZE_OPTION,
  DOCUMENT_SIZE_DEFAULT,
  CONTROL_PREFIX_DEFAULT,
  VOLUME_PREFIX_DEFAULT,
  CONTROL_NUMBER_DEFAULT,
  VOLUME_NUMBER_DEFAULT,
  EXPORT_TYPE,
  EXPORT_ACTION,
  EXPORT_SUMMARY_ITEM,
  EXPORT_PREVIEW_LIST_TYPE,
  ACTION_NAME,
  STATUS_EXPORT,
  EXPORT_TIME_FORMAT,
  MSG_CONFIRM,
  BUTTON_ACTION,
  EXPORT_TOOLTIP,
};
