import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import FormInput from "components/shared/form-input-control/form-input/FormInput";
import FormAction from "components/shared/form-action/FormAction";

// Styles
import styles from "./FormInputControl.module.scss";

const FormInputControl = (props) => {
  const {
    isCreating = false,
    currentID = null,
    dataID = 0,
    editValue = "",
    inputValue = "",
    placeholder = "",
    onChange = () => {},
    onFocus = () => {},
    handleEditForm = () => {},
    handleSaveForm = () => {},
    handleCancelForm = () => {},
    clearFocus = () => {},
    handleDeleteForm,
    className = "",
    errorMsg = "",
  } = props;

  const renderEditForm = () => (
    <React.Fragment>
      <span className={styles["form-control-label"]} title={editValue}>
        {editValue}
      </span>
      <FormAction
        onEdit={handleEditForm}
        onDelete={handleDeleteForm}
        isEdit={true}
      />
    </React.Fragment>
  );

  const renderCreateForm = () => {
    return currentID === dataID && isCreating ? (
      <FormInput
        value={inputValue}
        placeholder={placeholder}
        onChange={onChange}
        handleSaveForm={handleSaveForm}
        handleCancelForm={handleCancelForm}
        clearFocus={clearFocus}
      />
    ) : (
      <FormInput placeholder={placeholder} onFocus={onFocus} />
    );
  };

  return (
    <div className={clsx(styles["form-control"], className)}>
      {currentID === dataID && !isCreating ? (
        <FormInput
          value={inputValue}
          placeholder={inputValue}
          onChange={onChange}
          handleSaveForm={handleSaveForm}
          handleCancelForm={handleCancelForm}
          clearFocus={clearFocus}
          errorMsg={errorMsg}
        />
      ) : editValue ? (
        renderEditForm()
      ) : (
        renderCreateForm()
      )}
    </div>
  );
};

FormInputControl.propTypes = {
  isCreating: PropTypes.bool,
  currentID: PropTypes.any,
  dataID: PropTypes.any,
  errorMsg: PropTypes.string,
  className: PropTypes.string,
  inputValue: PropTypes.string,
  editValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  handleEditForm: PropTypes.func,
  handleDeleteForm: PropTypes.func,
  handleSaveForm: PropTypes.func,
  handleCancelForm: PropTypes.func,
  clearFocus: PropTypes.func,
};

export default FormInputControl;
