import React from "react";
import PropTypes from "prop-types";

//Styles
import styles from "./EmptyPage.module.scss";
import clsx from "clsx";

export default function EmptyPage({
  messages,
  isImgnotFound,
  size = "",
  imageName = "image-notfound",
}) {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100">
      <div
        className={clsx(styles[`empty-${size}`], styles[`empty-${imageName}`])}
      >
        <div className={styles["not-found-image"]}>
          {!isImgnotFound && (
            <img src={`/images/${imageName}.png`} alt="not found" />
          )}
        </div>
        <p className={styles["project-no-results"]}>{messages}</p>
      </div>
    </div>
  );
}

EmptyPage.propTypes = {
  messages: PropTypes.string,
  imageName: PropTypes.string,
  size: PropTypes.any,
  isImgnotFound: PropTypes.bool,
};
