import axios, { authHeader } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const getAddressFromLocation = (latitude, longitude) =>
  axios.get(`https://atlas.microsoft.com/search/address/reverse/json`, {
    headers: authHeader(),
    params: {
      "subscription-key": process.env.REACT_APP_AZURE_MAP_KEY,
      "api-version": 1,
      language: "en-US",
      query: `${latitude}, ${longitude}`,
    },
  });

// use fetchAPI because if use axios service return error code 423 page will navigate error423.
const getLinkPreviewApi = (url, signal) => {
  const data = {
    q: url,
    key: process.env.REACT_APP_LINKPREVIEW_API_KEY,
  };
  return fetch("https://api.linkpreview.net", {
    method: METHOD_API.post,
    mode: "cors",
    body: JSON.stringify(data),
    signal,
  });
};

const searchContactApi = (projectId, searchInput, isEntity = true) => {
  return axios(
    `/common/${projectId}/${isEntity ? "searchEntity" : "searchContact"}`,
    {
      headers: authHeader(),
      method: METHOD_API.get,
      params: {
        searchInput: searchInput,
      },
    }
  );
};

const checkPauseDbApi = (projectId) => {
  return axios(`/helper/checkPausedDatabase`, {
    headers: authHeader(),
    method: METHOD_API.get,
    params: { projectId },
  });
};

export {
  getAddressFromLocation,
  getLinkPreviewApi,
  searchContactApi,
  checkPauseDbApi,
};
