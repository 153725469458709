import React from "react";
import PropTypes from "prop-types";

// Constants
import { HEAT_MAP_COLORS } from "constants/Constants";

// Components
import ProgressColorBarItem from "components/shared/progress-bar-item/ProgressColorBarItem";

const ProgressColorBar = ({ maxCount }) => {
  return (
    <>
      {HEAT_MAP_COLORS.map((row, i) => {
        const countTo = maxCount - (maxCount / 11) * i;
        const countFrom = maxCount - (maxCount / 11) * (i + 1);
        if (
          Number(countFrom.toFixed()) + 1 <= Number(countTo.toFixed()) &&
          countTo.toFixed() > 0
        ) {
          return (
            <ProgressColorBarItem
              key={i}
              countTo={countTo}
              countFrom={countFrom}
              bgColor={row}
              index={i}
              value={row}
              average={maxCount / 11}
            />
          );
        }
        return "";
      })}
    </>
  );
};

export default ProgressColorBar;

ProgressColorBar.propTypes = {
  maxCount: PropTypes.number,
};
