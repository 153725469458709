import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import Checkbox from "components/shared/checkbox/Checkbox";

// Helpers
import { getSourceIconByName } from "helpers/CommonHelper";

// Styles
import styles from "./FilterOption.module.scss";

const FilterOption = ({
  filterLabel = "",
  filterValue = "",
  options = [],
  listDataChecked = [],
  getData = () => {},
}) => {
  const [isShowValue, setIsShowValue] = useState(true);
  const [selectedList, setSelectedList] = useState(listDataChecked);

  const handleSelectAllChange = (event) => {
    const dataAfter = event.target.checked
      ? [...options.map((item) => item.value), filterValue]
      : [];
    getData({
      key: filterValue,
      data: dataAfter.filter((item) => item !== filterValue),
    });
    setSelectedList(dataAfter);
  };

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;
    let dataAfter = [];
    if (!checked) {
      dataAfter = selectedList.filter(
        (item) => item !== value && item !== filterValue
      );
    } else {
      if (selectedList.length === options.length - 1) {
        dataAfter = [...options.map((item) => item.value), filterValue];
      } else dataAfter = [...selectedList, value];
    }
    getData({
      key: filterValue,
      data: dataAfter.filter((item) => item !== filterValue),
    });
    setSelectedList(dataAfter);
  };

  return (
    <div className={styles["wrap"]}>
      <span
        className={styles["filter-label"]}
        onClick={() => setIsShowValue(!isShowValue)}
      >
        <div className={styles["filter-label-left"]}>
          <Checkbox
            id={filterValue}
            isChecked={selectedList.includes(filterValue)}
            labelSmall={true}
            handleClick={handleSelectAllChange}
          />
          <span>{filterLabel}</span>
        </div>
        <div className={styles["filter-label-right"]}>
          <span className={styles["total-value"]}>{`(${
            selectedList.filter((item) => item !== filterValue)?.length || 0
          })`}</span>
          <img
            src={`/images/${isShowValue ? "up" : "down"}-icon.svg`}
            alt="arrow down"
            className={styles["arrow-icon"]}
          />
        </div>
      </span>
      {isShowValue && (
        <ul className={styles["filter-value"]}>
          {options?.map((item) => (
            <li className={styles["filter-value-item"]} key={item.value}>
              <div className={styles["source"]}>
                {item?.icon && (
                  <img
                    src={getSourceIconByName(item.label, "other")}
                    alt="source"
                    className={styles["source-icon"]}
                  />
                )}
                <span className={styles["source-name"]}>{item.label}</span>
              </div>
              <Checkbox
                id={`${item.value}-${filterValue}`}
                value={item.value}
                isChecked={selectedList.includes(item.value)}
                labelSmall={true}
                handleClick={handleCheckboxChange}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

FilterOption.propTypes = {
  filterValue: PropTypes.string,
  filterLabel: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
  listDataChecked: PropTypes.any,
  getData: PropTypes.func,
};

export default FilterOption;
