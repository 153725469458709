import React from "react";
import PropTypes from "prop-types";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

//Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

//Helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";

const InfoCallLog = ({ callLogInfo }) => {
  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) => (
          <InfoResultItem
            key={item.label}
            label={item.label}
            value={getInformationByKey(item.key, callLogInfo)}
            isIcon={item.key === PARAMETERS.typeLabel}
            type={callLogInfo[PARAMETERS.type]}
          />
        ))}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.callLog)}</>;
};

InfoCallLog.propTypes = {
  callLogInfo: PropTypes.object,
};

export default InfoCallLog;
