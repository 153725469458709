import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {
      email: "",
      username: "",
      avatarHasChanged: false,
    },
  },
  reducers: {
    updateEmail: (state, action) => {
      state.email = action.payload;
    },
    updateUsername: (state, action) => {
      state.username = action.payload;
    },
    updateAllData: (state, action) => {
      state.userInfo = { ...state.userInfo, ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateEmail, updateUsername, updateAllData } = userSlice.actions;
export const UserReducer = userSlice.reducer;
