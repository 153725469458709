import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { BeatLoader } from "react-spinners";

// Components
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import { Button } from "components/shared/button/Button";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./ConfigWithProjectId.module.scss";

const ConfigWithProjectId = (props) => {
  const {
    titleConfig = "",
    projectId = 0,
    isShowProjectId = true,
    isLoading = false,
    isLoadingProject = false,
    options = [],
    onChangeProjectId = () => {},
    handleRunConfig = () => {},
  } = props;

  return (
    <div className={styles["wrap"]}>
      <h3>{titleConfig}</h3>
      {isShowProjectId ? (
        <div className={clsx(styles["form-group"], "app-form-group")}>
          <label className="app-form-label">
            Project Name
            <span className={styles["form-required"]}> *</span>
          </label>
          <DropdownGeneral
            className={clsx(
              "dropdown-react-select",
              "w-100",
              styles["select-project"]
            )}
            placeholder="Select Project Name"
            options={options}
            isLoading={isLoadingProject}
            isDisabled={isLoadingProject}
            onChange={onChangeProjectId}
            value={options.filter((item) => item.value === projectId)}
          />
        </div>
      ) : ""}
      {isLoading ? (
        <BeatLoader
          color={COLORS.black}
          loading={true}
          size={8}
          className={styles["beat-loader"]}
        />
      ) : (
        <Button
          name={titleConfig}
          className="btn-primary-fill"
          handleClick={handleRunConfig}
        />
      )}
    </div>
  );
};

ConfigWithProjectId.propTypes = {
  titleConfig: PropTypes.string,
  projectId: PropTypes.number,
  options: PropTypes.array,
  isShowProjectId: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingProject: PropTypes.bool,
  onChangeProjectId: PropTypes.func,
  handleRunConfig: PropTypes.func,
};

export default ConfigWithProjectId;
