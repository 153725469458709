import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";

// Components
import EventTimelineDetail from "components/event-timeline/event-timeline-detail/EventTimelineDetail";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import PrintExport from "components/shared/print-export/PrintExport";

// Services
import {
  exportMessageResultToExcel,
  exportEmailResultToExcel,
} from "services/EventTimelineService";

// Store
import { fetchTagsList } from "store/TagReducer";
import {
  setEventTimelineDetail,
  setParamSearchTimeLine,
} from "store/EventTimelineReducer";
import { resetDateTimeMess } from "store/DatePickerReducer";
import { setIsGoToOrigin } from "store/CommonReducer";

// Constants
import { CHAT_EMAIL_TYPE, DATA_TYPE } from "constants/DataType";
import { PRINT_SCREEN_NAME, PAGE_NAME, PATH_NAME } from "constants/Common";
import { breadCrumbTimelineDetail } from "constants/BreadCrumbConstants";

// Helpers
import { getTitleHeaderFromType } from "helpers/ItemDetailHelper";
import { getCurrentTime } from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./EventTimelineDetailPage.module.scss";

const EventTimelineDetailPage = () => {
  const { projectId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [headerTitle, setHeaderTitle] = useState(
    _get(state, "headerTitle", "")
  );
  const [eventTimelineType, setEventTimelineType] = useState(
    _get(state, "eventTimeLineType", "")
  );
  const [eventTimelineID, setEventTimelineID] = useState(
    _get(state, "id", null)
  );
  const pageName = _get(state, "pageName", null);

  const { messageViewDatePicker: dateFilterMsg } = useSelector(
    (state) => state.datePicker
  );
  const { eventTimelineDetail, paramSearch } = useSelector(
    (state) => state.eventTimeline
  );
  const isGoToOrigin = useSelector((state) => state.common.isGoToOrigin);

  const redirectToEventTimeline = () =>
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.eventTimeline}`);

  const handleGoBack = () => {
    if (state?.pathname) navigate(state.pathname);
    else redirectToEventTimeline();
  };

  const downloadFile = (blobData, fileName) => {
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const exportMessageToExcel = async () => {
    setIsLoading(true);
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
      dateFilterMsg;
    const params = {
      Start: dateTimeDataStart,
      End: dateTimeDataEnd,
      PageNumber: 1,
      includeBlankDate: includeBlankDate,
      isNewest: paramSearch?.isNewest,
      chatID: eventTimelineDetail?.chatID,
      pageName: state?.pageName,
    };
    try {
      const result = await exportMessageResultToExcel(
        projectId,
        eventTimelineID,
        params
      );
      downloadFile(result.data, `StreemView_Message_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportEmailDetailToExcel = async () => {
    setIsLoading(true);
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
      dateFilterMsg;
    const params = {
      Start: dateTimeDataStart,
      End: dateTimeDataEnd,
      includeBlankDate: includeBlankDate,
      PageNumber: 1,
      isNewest: paramSearch?.isNewest,
      pageName: state?.pageName,
    };
    try {
      const result = await exportEmailResultToExcel(
        projectId,
        eventTimelineID,
        params
      );
      downloadFile(result.data, `StreemView_Email_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = () => {
    if (
      eventTimelineType === DATA_TYPE.chat ||
      eventTimelineType === DATA_TYPE.chatGroup
    )
      exportMessageToExcel();
    else exportEmailDetailToExcel();
  };

  useEffect(() => {
    dispatch(fetchTagsList({ projectId }));
    if (isGoToOrigin) {
      const { type, id } = eventTimelineDetail;
      setEventTimelineType(type);
      setHeaderTitle(getTitleHeaderFromType(type));
      setEventTimelineID(id);
      setIsLoading(false);
      dispatch(setIsGoToOrigin(false));
      return;
    } else if (!state?.id && !eventTimelineDetail?.id) {
      redirectToEventTimeline();
    } else {
      setEventTimelineType(state?.eventTimeLineType);
      setHeaderTitle(state?.headerTitle);
      setEventTimelineID(state?.id);
    }
    return () => {
      dispatch(setParamSearchTimeLine({ searchMsgInput: null }));
      dispatch(resetDateTimeMess());
    };
  }, []);

  return (
    <Container fluid>
      <div className="main" id="main">
        <div className={styles["back-btn"]}>
          <BreadCrumb
            goBack={handleGoBack}
            breadCrumbData={breadCrumbTimelineDetail(
              state?.headerTitle ||
                getTitleHeaderFromType(eventTimelineDetail?.type)
            )}
          />
        </div>
        <div className={styles["et-detail-header"]}>
          <h2>{headerTitle}</h2>
          <PrintExport
            screenName={PRINT_SCREEN_NAME.eventTimelineDetail}
            isDisplayTime
            handleExport={handleExport}
            isExportExcel={CHAT_EMAIL_TYPE.includes(eventTimelineType)}
          />
        </div>
        <EventTimelineDetail
          eventTimelineType={eventTimelineType}
          eventTimelineID={eventTimelineID}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          pageName={pageName}
          titleActivity={PAGE_NAME.eventTimeline}
          dataDetail={eventTimelineDetail}
          updateStoreDetail={(data) => dispatch(setEventTimelineDetail(data))}
          searchMsgInput={paramSearch?.searchMsgInput}
        />
      </div>
    </Container>
  );
};

export default EventTimelineDetailPage;
