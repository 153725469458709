import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

//Helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";
import { trimObject } from "helpers/ObjectHelper";
import { getAddressAzureMap } from "helpers/LocationHelper";

const InfoLocation = ({ locationInfo = {} }) => {
  const [address, setAddress] = useState("");

  const getAddress = async () => {
    const addressMap = await getAddressAzureMap(
      locationInfo.latitude,
      locationInfo.longitude
    );
    setAddress(addressMap);
  };

  useEffect(() => {
    getAddress();
  }, []);

  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) =>
          item.key === PARAMETERS.address ? (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={address}
            />
          ) : (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={getInformationByKey(item.key, trimObject(locationInfo))}
              isIcon={item.key === PARAMETERS.typeLabel}
              type={locationInfo[PARAMETERS.type]}
            />
          )
        )}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.location)}</>;
};

InfoLocation.propTypes = {
  locationInfo: PropTypes.object,
};

export default InfoLocation;
