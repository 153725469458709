import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

// Components
import SourceIcon from "../source-icon/SourceIcon";

// Styles
import styles from "./SourceTypeList.module.scss";

const SourceTypeList = ({
  sourceList = [],
  limitSource = 5,
  isDropdown = false,
}) => {
  const sourceListRef = useRef(null);
  const titleSourceRef = useRef(null);

  const [isShowAll, setIsShowAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState(sourceList[0]);

  const sortSourceName = (array = []) =>
    array.sort((nameA, nameB) => nameA.localeCompare(nameB));

  const sourceShortened = sortSourceName(sourceList.slice(0, limitSource));
  const sourceFull = sortSourceName(
    sourceList.slice(limitSource, sourceList.length)
  );

  useOnClickOutside(
    sourceListRef,
    () => setIsShowAll(false),
    titleSourceRef?.current
  );

  const handleSelectSource = (source) => setSelectedItem(source);

  return (
    <Fragment>
      {isDropdown ? (
        <div
          className={styles["dropdown-source"]}
          onClick={() => setIsShowAll(!isShowAll)}
          ref={titleSourceRef}
        >
          <SourceIcon sourceType={selectedItem} defaultIcon="other" />
          <span className={styles["source-title"]}>
            <span>{selectedItem}</span>
            <img
              src="/images/icons/arrow-down.svg"
              alt="arrow-down"
              className={styles["arrow-icon"]}
            />
          </span>
          <ul
            className={styles["source-list"]}
            ref={sourceListRef}
            hidden={!isShowAll}
          >
            {sourceList.map((item) => (
              <li key={item} onClick={() => handleSelectSource(item)}>
                <SourceIcon sourceType={item} defaultIcon="other" />
                <span className={selectedItem === item ? styles["active"] : ""}>
                  {item}
                </span>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className={styles["source-list"]}>
          <div className={styles["source-info"]}>
            {sourceShortened.length > 0 &&
              sourceShortened.map((item) => (
                <SourceIcon key={item} sourceType={item} defaultIcon="other" />
              ))}
            {sourceFull.length > 0 && (
              <span className={styles["total-source"]}>
                <span
                  onClick={() => setIsShowAll(!isShowAll)}
                  ref={titleSourceRef}
                >
                  <span>{Number(sourceFull.length)}</span>
                  <img
                    src="/images/icons/arrow-down.svg"
                    alt="arrow-down"
                    className={styles["arrow-icon"]}
                  />
                </span>
                <ul
                  className={styles["source-list"]}
                  ref={sourceListRef}
                  hidden={!isShowAll}
                >
                  {sourceFull.map((item) => (
                    <li key={item}>
                      <SourceIcon sourceType={item} defaultIcon="other" />
                    </li>
                  ))}
                </ul>
              </span>
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

SourceTypeList.propTypes = {
  sourceList: PropTypes.arrayOf(PropTypes.string),
  limitSource: PropTypes.number,
  isDropdown: PropTypes.bool,
};

export default SourceTypeList;
