import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";
import { isPossiblePhoneNumber } from "react-phone-number-input";

// Constants
import { SOCIAL_LIST } from "constants/Constants";

// Helpers
import { getSourceIconById } from "helpers/CommonHelper";
import { renderPhoneNumber } from "helpers/FormatterHelper";

// Styles
import styles from "./AssociatedIdentifierView.module.scss";

const AssociatedIdentifierView = ({
  associated: { phones = [], emails = [], socials = [] },
}) => {
  const seeMoreRef = useRef(null);

  const [seeMore, setSeeMore] = useState(false);

  useOnClickOutside(seeMoreRef, () => setSeeMore(false));

  const listContactConvert = [phones[0], emails[0], socials[0]];
  const listContactConvertMore = [
    ...phones.slice(1),
    ...emails.slice(1),
    ...socials.slice(1),
  ];
  const associatedData = [...phones, ...emails, ...socials];

  const renderListContact = (options = []) =>
    options?.length > 0 &&
    options.map(
      (item) =>
        item?.value && (
          <p key={item.id} className={styles["associated-item"]}>
            <img
              src={getSourceIconById(SOCIAL_LIST, item.socialType)}
              alt="source-icon"
            />
            <span title={item.value}>
              {isPossiblePhoneNumber(item.value)
                ? renderPhoneNumber(item.value)
                : item.value}
            </span>
          </p>
        )
    );

  return (
    <div className={styles["wrap"]}>
      <div className={styles["label"]}>
        <h5>{`Associated Identifiers ${
          associatedData.length > 0 ? `(${associatedData.length})` : ""
        }`}</h5>
        {associatedData.length > 0 && (
          <div className={styles["see-more"]} ref={seeMoreRef}>
            <span
              onClick={() => setSeeMore(!seeMore)}
              hidden={listContactConvertMore.length === 0}
            >
              <span className={styles["see-more-label"]}>More</span>
              <img
                className={styles["see-more-icon"]}
                src="/images/see-more.svg"
                alt="More"
              />
            </span>
            <div hidden={!seeMore} className={styles["see-more-list"]}>
              {listContactConvertMore.length > 0 ? (
                renderListContact(listContactConvertMore)
              ) : (
                <span className={styles["no-more"]}>No more</span>
              )}
            </div>
          </div>
        )}
      </div>
      {associatedData.length > 0 ? (
        renderListContact(listContactConvert)
      ) : (
        <p className={styles["no-result"]}>
          {"No contacts are assigned"}
        </p>
      )}
    </div>
  );
};

AssociatedIdentifierView.propTypes = {
  associated: PropTypes.shape({
    phones: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        socialType: PropTypes.number,
        value: PropTypes.string,
      })
    ),
    emails: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        socialType: PropTypes.number,
        value: PropTypes.string,
      })
    ),
    socials: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        socialType: PropTypes.number,
        value: PropTypes.string,
      })
    ),
  })
};

export default AssociatedIdentifierView;
