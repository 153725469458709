import PropTypes from "prop-types";
import React from "react";
import { Modal } from "react-bootstrap";

//components
import { Button } from "components/shared/button/Button";

//styles
import styles from "./PopupNotification.module.scss";

const PopupNotification = (props) => {
  const { isShow, handleClose, content, title, buttonName = "OK" } = props;

  return (
    <Modal className={styles["popup-notification-dialog"]} show={isShow}>
      <Modal.Header className={styles["modal-header-custom"]}>
        <Modal.Title className={styles["modal-header-title"]}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <div className="d-flex justify-content-center">
        <p className={styles["content"]}>{content}</p>
      </div>
      <Modal.Footer className={styles["modal-footer-custom"]}>
        <Button
          className="btn-secondary-md"
          isBorder={false}
          name={buttonName}
          handleClick={handleClose}
        />
      </Modal.Footer>
    </Modal>
  );
};

PopupNotification.propTypes = {
  isShow: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  buttonName: PropTypes.string,
  handleClose: PropTypes.func,
};

export default PopupNotification;
