import PropTypes from "prop-types";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import styles from "./Button.module.scss";
import clsx from "clsx";

const MsButton = (props, ref) => {
  const {
    name = "",
    iconUrl = "",
    disabledIcon = "",
    altIcon = "",
    className = "",
    handleClick,
    isBorder = true,
    isDisabled = false,
    type = "button",
    fillType = "",
    onKeyDown,
    ...rest
  } = props;
  const buttonRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      handleFocus,
    }),
    []
  );

  const handleFocus = () => {
    buttonRef.current.focus();
  };
  return (
    <div
      className={clsx(
        styles["btn-container"],
        "btn-common",
        styles[`btn-container-${fillType}`]
      )}
    >
      <button
        ref={buttonRef}
        disabled={isDisabled}
        {...rest}
        type={type}
        onKeyDown={onKeyDown}
        onClick={handleClick}
        className={clsx(
          iconUrl ? styles["btn-display"] : "",
          styles[className],
          isBorder ? styles["btn-border"] : styles["btn-border-none"]
        )}
      >
        {(iconUrl || disabledIcon) && (
          <img
            src={isDisabled ? disabledIcon || iconUrl : iconUrl}
            alt={altIcon}
            className={!name ? styles["btn-no-name"] : ""}
          />
        )}
        <span>{name}</span>
      </button>
    </div>
  );
};
const Button = forwardRef(MsButton);

export { Button };

MsButton.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  iconUrl: PropTypes.string,
  disabledIcon: PropTypes.string,
  altIcon: PropTypes.string,
  className: PropTypes.string,
  fillType: PropTypes.string,
  isBorder: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  onKeyDown: PropTypes.func,
};
