import React from "react";
import { PropTypes } from "prop-types";
import clsx from "clsx";

// Helper
import { keepHtmlEntity } from "helpers/CommonHelper";
import { isHtmlTag } from "helpers/TextHelper";

// Styles
import styles from "./ConsolidatedQueryItem.module.scss";

const ConsolidatedQueryItem = ({
  type,
  query,
}) => {
  const className = type.className;

  return (
    <div
      className={clsx(
        styles[className],
        styles["container"],
      )}
    >
      <div className={styles["content"]}>
        <div className={styles["header"]}>
          <p
            className={clsx(
              styles["title"],
              styles["title-active"]
            )}
          >
            {type.title}
          </p>
        </div>
        <div className={styles["query-container"]}>
          <p
            className={clsx(
              styles["query"],
              styles["query-active"]
            )}
            dangerouslySetInnerHTML={{
              __html: isHtmlTag(query) ? query : keepHtmlEntity(query),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};

ConsolidatedQueryItem.propTypes = {
  type: PropTypes.object,
  query: PropTypes.any,
};

export default ConsolidatedQueryItem;
