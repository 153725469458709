import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import QuickFilter from "components/shared/quick-filter/QuickFilter";
import TimelineOverview from "components/event-timeline/timeline/TimelineOverview";

//Store
import {
  setQuickSelect,
  setParamSearchTimeLine,
  setValidTime,
  resetStoreTimeline,
  setIsTimelineOrigin,
} from "store/EventTimelineReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";

// Services
import {
  getEventTimelineQuickSelect,
  getTimelineDates
} from "services/EventTimelineService";
import { fetchTagsList } from "store/TagReducer";

//Constants
import { 
  QUICK_SELECT_ITEM
} from "constants/Common";
import {
  DATA_TYPE
} from "constants/DataType";

// Helpers
import {
  convertContact,
  convertFilter,
  convertTag,
  convertTypeToObject,
  getFilterValue,
  getFilterLabel,
} from "helpers/CommonHelper";

//Styles
import styles from "./EventTimelinePage.module.scss";

const EventTimelinePage = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const [filterLoading, setFilterLoading] = useState(false);
  const [quickFilterData, setQuickFilterData] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const {
    quickSelect = {},
    paramSearch,
    validTime: { currentYear, keepDate },
    isTimelineOrigin,
  } = useSelector((state) => state.eventTimeline);

  const {
    dataSources,
    dataTypes,
    entities,
    participants,
    tags,
    searchMsgInput
  } = paramSearch;

  // Get data from store
  const selectTags = useSelector((state) => state.tag.tags.selectTags);

  const applyQS = useCallback((data) => {
    const { dataSources, dataTypes, entities, participants, tags } =
      data;
    dispatch(
      setParamSearchTimeLine({
        date: paramSearch.date,
        dataSources: dataSources.map((item) => ({
          custodianName: item.label,
          dataSourceId: item.value.toString(),
          dataSourceType: item.sourceType,
        })),
        dataTypes: getFilterValue(dataTypes),
        entities: getFilterValue(entities),
        participants: getFilterValue(participants),
        tags: getFilterValue(tags),
      })
    );

    dispatch(setValidTime({ keepDate: true }));
  });

  const resetParamSearch = () => {
    dispatch(
      setParamSearchTimeLine({
        dataSources: [],
        dataTypes: [],
        entities: [],
        participants: [],
        tags: [],
      })
    );
  };

  // Fetch data display quick select
  const fetchQueryTypes = async () => {
    setFilterLoading(true);
    try {
      const { data } = await getEventTimelineQuickSelect(projectId);
      if (!data) return;
      const {
        custodianSources: dataSources = [],
        dataTypes = [],
        entities = [],
        participants = [],
      } = data;
      dispatch(
        setQuickSelect({
          dataSources,
          dataTypes,
          entities,
          participants,
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setFilterLoading(false);
    }
  };

  const fetchValidYear = async () => {
    try {
      dispatch(setValidTime({ validYearsLoading: true }));

      const { data: response } = await getTimelineData();

      if (response.timelineDateItems[0].groupByValues && response.timelineDateItems[0].totalGroupsHit !== 0) {
        const yearsString = Object.keys(response.timelineDateItems[0].groupByValues);
        const years = yearsString.map(year => {
          const parsedYear = parseInt(year, 10);
          if (isNaN(parsedYear))
            console.warn(`Failed to parse year: ${year}`);

          return parsedYear;
        }).filter(year => !isNaN(year) && year !== 0);

        fetchValidTime(years[years.length - 1]);

        dispatch(setValidTime({ years, currentYear: years[years.length - 1] }));
      }
      else {
        dispatch(setValidTime({ months: [], years: [] }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setValidTime({ validYearsLoading: false }));
    }
  };

  const fetchValidTime = async (year) => {
    try {
      dispatch(setValidTime({ validTimeLoading: true }));

      const { data: response } = await getTimelineData(year);

      var monthsAndDaysData = []
      for (const monthsAndDays of response.timelineDateItems)
        monthsAndDaysData.push(getMonthAndDayObject(monthsAndDays.groupByValues));

      dispatch(setValidTime({ months: monthsAndDaysData || [] }));
    } catch (error) {
      console.log(error);
      dispatch(setValidTime({ months: [] }));
    } finally {
      dispatch(setValidTime({ validTimeLoading: false }));
    }
  };

  const getTimelineData = async (year = null) => {
    const body = {
      searchInput: searchMsgInput,
      dataSources: dataSources?.map((item) => item.dataSourceId),
      dataTypes: dataTypes,
      entities: entities,
      participants: participants,
      tags: tags,
    };
  
    if (year)
      body.year = year.toString();
  
    const result = await getTimelineDates(projectId, body);
    return result;
  };

  const getMonthAndDayObject = (groupValues) => {
    const years = new Set();
    const months = new Set();
    const days = [];
  
    for (const key of Object.keys(groupValues)) {
      const [year, month, day] = [
        key.slice(0, 4),
        key.slice(4, 6),
        key.slice(6, 8)
      ];
  
      years.add(year);
      months.add(month);
      days.push(day);
    }
  
    if (years.size !== 1 || months.size !== 1) return null;
  
    const month = parseInt([...months][0], 10);
    const dayString = days.map(day => parseInt(day, 10)).join(",");
  
    return { month, day: dayString };
  };

  const disableQuickSelect = () => {
    return ["dataSources", "dataTypes", "entities", "participants", "tags"].every(
      (key) => paramSearch[key].length === 0
    );
  };  

  useEffect(() => {
    if (isTimelineOrigin) dispatch(setIsTimelineOrigin(false));
    else {
      dispatch(fetchTagsList({ projectId }));
      dispatch(fetchDataSourceList(projectId));
      fetchQueryTypes();
      !keepDate && fetchValidYear();
      dispatch(resetStoreTimeline());
    }
    setFirstLoad(false);
    dispatch(setValidTime({ validTimeLoading: false }));
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      dispatch(fetchTagsList({ projectId }));
      dispatch(fetchDataSourceList(projectId));
      fetchQueryTypes();
      fetchValidYear();
      dispatch(resetStoreTimeline());
      dispatch(setValidTime({ currentYear: new Date().getFullYear() }));
    }
  }, [projectId]);

  useEffect(() => {
    if (paramSearch.date !== null)
      fetchValidTime(currentYear);
  }, [currentYear]);

  useEffect(() => {
    dispatch(setQuickSelect({ tags: selectTags || [] }));
  }, [selectTags, filterLoading]);

  useEffect(() => {
    if (!firstLoad)
    {
      dispatch(setValidTime({ keepDate: true }));
      fetchValidYear();
    }
  }, [dataSources, dataTypes, entities, participants, tags, searchMsgInput]);

  useEffect(() => {
    !filterLoading &&
      setQuickFilterData([
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataSources.value),
          filterType: QUICK_SELECT_ITEM.dataSources.value,
          filterData: {
            dataSelected: convertFilter(
              dataSources,
              QUICK_SELECT_ITEM.dataSources.value
            ),
            dataUnSelect: convertFilter(
              quickSelect.dataSources,
              QUICK_SELECT_ITEM.dataSources.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataTypes.value),
          filterType: QUICK_SELECT_ITEM.dataTypes.value,
          filterData: {
            dataSelected: convertTypeToObject(
              quickSelect.dataTypes.filter(item => item.value !== DATA_TYPE.creditCard),
              dataTypes
            ),
            dataUnSelect: quickSelect.dataTypes.filter(item => item.value !== DATA_TYPE.creditCard),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.entities.value),
          filterType: QUICK_SELECT_ITEM.entities.value,
          filterData: {
            dataSelected: convertFilter(
              convertContact(quickSelect.entities, entities),
              QUICK_SELECT_ITEM.entities.value
            ),
            dataUnSelect: convertFilter(
              quickSelect.entities,
              QUICK_SELECT_ITEM.entities.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.identifiers.value),
          filterType: QUICK_SELECT_ITEM.participants.value,
          filterData: {
            dataSelected: convertFilter(
              convertContact(
                quickSelect.participants,
                participants
              ),
              QUICK_SELECT_ITEM.participants.value
            ),
            dataUnSelect: convertFilter(
              quickSelect.participants,
              QUICK_SELECT_ITEM.participants.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.tags.value),
          filterType: QUICK_SELECT_ITEM.tags.value,
          filterData: {
            dataSelected: convertFilter(
              convertTag(quickSelect.tags, tags),
              QUICK_SELECT_ITEM.tags.value
            ),
            dataUnSelect: convertFilter(
              quickSelect.tags,
              QUICK_SELECT_ITEM.tags.value
            ),
          },
        },
      ]);
  }, [filterLoading, quickSelect.tags]);

  return (
    <div className={styles["event-timeline"]}>
      <div className={styles["et-quick-select"]}>
        <QuickFilter
          data={quickFilterData}
          loading={filterLoading}
          onApply={applyQS}
          onReset={resetParamSearch}
          disableActions={disableQuickSelect}
        />
      </div>
      <div className={styles["et-overview"]}>
        <TimelineOverview />
      </div>
    </div>
  );
};

export default EventTimelinePage;
