import { createSlice } from "@reduxjs/toolkit";

const filterTagSlice = createSlice({
  name: "filter-tag",
  initialState: {
    selectedTags: [],
    actionType: '',
    isError: false
  },
  reducers: {
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    setActionType: (state, action) => {
        state.actionType = action.payload;
    },
    setIsError: (state, action) => {
        state.isError = action.payload;
    },
    resetFilterTagState: () => ({
        selectedTags: [],
        actionType: '',
        isError: false
    }),
  }
});

export const { 
    setSelectedTags, 
    setActionType,
    setIsError,
    resetFilterTagState 
} = filterTagSlice.actions;

export const FilterTagReducer = filterTagSlice.reducer;
