import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// Helpers
import { getFileNameFromUrl } from "helpers/GetFileNameHelper";
import { keepHtmlEntity } from "helpers/CommonHelper";
import { getFileNameFromContentDisposition } from "helpers/DownloadFileHelper";

// Constants
import { LIST_IMAGE_TYPE, LIST_VIDEO_TYPE } from "constants/Constants";

// Component
import EmbedImageAttachment from "components/shared/file-components/embed-image-attachment/EmbedImageAttachment";
import PreviewVideo from "../preview-video/PreviewVideo";

// Services
import { downloadNativeFile } from "services";

// Styles
import styles from "./PreviewNote.module.scss";

const PreviewNote = ({ matterId, data }) => {
  const { projectId } = useParams();
  const handleDownloadFile = (attachmentData) => {
    if (!attachmentData?.url) return;
    const downloadFile = async () => {
      try {
        const response = await downloadNativeFile(matterId, attachmentData?.attachmentID);
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',
          getFileNameFromContentDisposition(response.headers['content-disposition']));
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error('This attachment cannot be found.');
        } else {
          toast.error('An error occurred while downloading the attachment.');
        }
      }
    };
    downloadFile();
  };

  const renderAttachNotAvailable = (key) => (
    <div key={key} className={styles["attachment-not-available"]}>
      <img src="/images/icons/attachment.svg" alt="attachment" />
      <p>The attachment is not available.</p>
    </div>
  );

  const renderAttachments = (attachmentData) => {
    const fileType = attachmentData?.url?.split(".").pop().trim().toLowerCase();
    if (LIST_IMAGE_TYPE.includes(fileType)) {
      return (
        <figure key={attachmentData.attachmentID} className="pt-4">
          <EmbedImageAttachment matterId={projectId} attachmentId={attachmentData?.attachmentID} />
        </figure>
      );
    } else if (Object.values(LIST_VIDEO_TYPE).includes(fileType)) {
      return (
        <figure key={attachmentData.attachmentID} className="pt-3">
          <PreviewVideo matterId={projectId} attachmentId={attachmentData?.id} />
        </figure>
      );
    } else {
      return attachmentData.url ? (
        <figure key={attachmentData.attachmentID} className="pt-3">
          <a
            href={attachmentData.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/images/file-attach-blue.svg"
              className="pb-2"
              alt="Attachment file"
            />
          </a>
        </figure>
      ) : (
        renderAttachNotAvailable(attachmentData.attachmentID)
      );
    }
  };

  return (
    <div className={styles["preview-not-rs-wrap"]}>
      <p className={styles["not-rs-title"]}>{data?.title}</p>
      <p
        className={styles["not-rs-content"]}
        dangerouslySetInnerHTML={{
          __html: keepHtmlEntity(data?.body?.replaceAll("\n", "<br/>")),
        }}
      ></p>
      {data?.attachments?.map((item) => {
        return (
          <div key={item.attachmentID} className={styles["attach-item"]}>
            {renderAttachments(item)}
            {item?.url ? (
              <div
                className={styles["attach-item-link"]}
                onClick={() => handleDownloadFile(item)}
              >
                {getFileNameFromUrl(item.url)}
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </div>
  );
};

PreviewNote.propTypes = {
  matterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.object,
};

export default PreviewNote;
