import axios, { authHeader, getAbortController } from "./ApiConfig";

const GetSearchTerms = (projectId) => {
  return axios.get(`/searchTerms/${projectId}`, {
    headers: authHeader(),
    signal: getAbortController().signal,
  });
};

const GetSearchTermById = (projectId, searchId) => {
  return axios.get(`/searchTerms/${projectId}/${searchId}`, {
    headers: authHeader(),
  });
}

const AddSearchTerms = (projectId, params = {}) => {
  return axios.post(`/searchTerms/${projectId}`, params, {
    headers: authHeader()
  });
};

const UpdateSearchTerm = (projectId, searchId, params = {}) => {
  return axios.put(`/searchTerms/${projectId}/${searchId}`, params, {
    headers: authHeader()
  });
};

const RunSearchTerms = (projectId, params = {}) => {
  return axios.post(`/searchTerms/${projectId}/RunSearchTerms`, params, {
    headers: authHeader()
  });
};

const DeleteSearchTerm = (projectId, searchId) => {
  return axios.delete(`/searchTerms/${projectId}/${searchId}`, {
    headers: authHeader()
  })
};

const GetSearchTermsScope = (projectId) => {
  return axios.get(`/searchTerms/${projectId}/scope`, {
    headers: authHeader(),
    signal: getAbortController().signal,
  });
};

const UpdateSearchTermsScope = (projectId, params = {}) => {
  return axios.put(`/searchTerms/${projectId}/scope`, params, {
    headers: authHeader()
  });
};

export {
  GetSearchTerms,
  GetSearchTermById,
  AddSearchTerms,
  UpdateSearchTerm,
  RunSearchTerms,
  DeleteSearchTerm,
  GetSearchTermsScope,
  UpdateSearchTermsScope
};
