import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useToken } from "hook/auth";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import clsx from "clsx";

// Constants
import { UserProfileValidation as Message } from "constants/UserProfileValidation";
import { FILE_SIZE, IMAGE_TYPE_ACCEPTED_STRING } from "constants/Constants";
import { FIELD_VALIDATION } from "constants/RegexConstant";
import {
  AUTH_ROLE,
  ROLE_LIST_ADMIN,
  YUP_METHOD,
  YUP_TEST,
} from "constants/AuthConstant";

// Services
import { getClients } from "services/ProjectAdministrationService";

// Components
import { Button } from "components/shared/button/Button";
import { DropdownSelector } from "components/shared/dropdown-selector/DropdownSelector";
import DefaultAvatar from "assets/images/default_avatar.png";

// Helpers
import { getFileTypeFromName } from "helpers/GetFileNameHelper";

// Constants
import { DEFAULT_NAME } from "constants/Common";

// Styles
import styles from "./CreateUserModal.module.scss";

export const CreateUserModal = (props) => {
  const {
    isShow = false,
    handleClose,
    handleSubmit,
    errors,
    onClearErrorText,
  } = props;
  const { isAdmin } = useToken();

  const [previewImage, setPreviewImage] = useState(DefaultAvatar);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const clientList = clients.map((item) => ({
    name: item.clientName,
    id: item.clientId,
  }));

  const selectAccountId = (name) =>
    clientList.find((item) => item.name === name)?.id;

  Yup.addMethod(Yup.string, YUP_METHOD.emailValid, function (errorMessage) {
    return this.test(YUP_TEST.specialKeyword, errorMessage, function (value) {
      return value?.match(FIELD_VALIDATION.emailUserModal);
    });
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      role: AUTH_ROLE.user,
      clientName: "",
      image: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .trim()
        .max(100, Message.firstName.max_length)
        .required(Message.firstName.required),
      lastName: Yup.string()
        .trim()
        .max(100, Message.lastName.max_length)
        .required(Message.lastName.required),
      email: Yup.string()
        .trim()
        .emailValid(Message.email.invalid)
        .max(100, Message.email.max_length)
        .required(Message.email.required),
      image: Yup.mixed()
        .test({
          message: Message.avatar.file_type,
          test: (image) =>
            image
              ? IMAGE_TYPE_ACCEPTED_STRING.includes(
                getFileTypeFromName(image.name)
              )
              : true,
        })
        .test({
          message: Message.avatar.file_size,
          test: (image) => (image ? image.size <= FILE_SIZE.MB20 : true),
        })
        .test({
          message: Message.avatar.required,
          test: (image) => (image ? image.size !== 0 : true),
        }),
    }),
    onSubmit: (values) => {
      const accountId = selectAccountId(values.clientName);
      handleSubmit({ ...values, accountId });
    },
  });

  const fetchDataClient = async () => {
    setLoading(true);
    try {
      const result = await getClients();
      setClients(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const choosePhoto = () => {
    document.getElementById("image").click();
  };

  const onChangeImgInput = (event) => {
    const { files } = event.currentTarget;
    if (files.length > 0) formik.setFieldValue("image", files[0]);
  };

  useEffect(() => {
    fetchDataClient();
  }, []);

  useEffect(() => {
    clients && formik.setFieldValue("clientName", clients[0]?.clientName);
  }, [clients]);

  useEffect(() => {
    formik.values.image &&
      setPreviewImage(URL.createObjectURL(formik.values.image));
  }, [formik.values.image]);

  useEffect(() => {
    onClearErrorText();
  }, [formik.values.email]);

  return (
    <Modal
      className={styles["modal-container"]}
      show={isShow}
      onHide={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <Modal.Header className={styles["modal-header"]}>
            <p className={styles["modal-header-title"]}>Create user</p>
            <div onClick={handleClose} className={styles["header-close"]}>
              <img src="/images/close-modal-icon.png" alt="Close" />
            </div>
          </Modal.Header>
          <Modal.Body className={styles["modal-body"]}>
            <div className={styles["user-information__photo"]}>
              <figure className={styles["user-information__photo-img"]}>
              <img
                    src={!formik.errors.image ? previewImage : DefaultAvatar}
                    alt="avatar user"
                    onClick={choosePhoto}
                    className="cursor-pointer"
                    onError={(e) => {
                      e.target.src = DefaultAvatar;
                    }}
                  />
              </figure>
              <div className={styles["user-information__photo-create"]}>
                <input
                  type="file"
                  hidden
                  id="image"
                  name="image"
                  onChange={onChangeImgInput}
                />
                <Button
                  type="button"
                  name="Upload Photo"
                  iconUrl="/images/ic-upload.svg"
                  handleClick={choosePhoto}
                />
                {(formik.errors.image || formik.touched.image) && (
                  <span className="error text-danger">
                    {formik.errors.image}
                  </span>
                )}
              </div>
            </div>
            <div className="mar_b24 d-flex justify-content-between w-100">
              <div className={clsx("mar_r16", styles["w-50"])}>
                <label
                  htmlFor="firstName"
                  className={styles["modal-body-label"]}
                >
                  First Name <span className="error text-danger">*</span>
                </label>
                <input
                  className={clsx("form-control", styles["modal-body-input"])}
                  type="text"
                  placeholder="Enter First Name"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <span className="error text-danger">
                    {formik.errors.firstName}
                  </span>
                )}
              </div>
              <div className={styles["w-50"]}>
                <label
                  htmlFor="lastName"
                  className={styles["modal-body-label"]}
                >
                  Last Name <span className="error text-danger">*</span>
                </label>
                <input
                  className={clsx("form-control", styles["modal-body-input"])}
                  type="text"
                  placeholder="Enter Last Name"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <span className="error text-danger">
                    {formik.errors.lastName}
                  </span>
                )}
              </div>
            </div>
            <div className="mar_b24">
              <label htmlFor="email" className={styles["modal-body-label"]}>
                Email Address <span className="error text-danger">*</span>
              </label>
              <input
                className={clsx("form-control", styles["modal-body-input"])}
                type="text"
                placeholder="Enter Email Address"
                id="email"
                name="email"
                value={formik.values.email?.trim()}
                onChange={formik.handleChange}
              />
              {formik.errors.email && formik.touched.email ? (
                <span className="error text-danger">{formik.errors.email}</span>
              ) : (
                errors === Message.email.exists && (
                  <span className="error text-danger">{errors}</span>
                )
              )}
            </div>
            <div className="mar_b24">
              <label htmlFor="role" className={styles["modal-body-label"]}>
                Role
              </label>
              {isAdmin() ? (
                <DropdownSelector
                  widthStyle="auto"
                  size="md"
                  className={clsx("form-select", styles["modal-body-select"])}
                  itemList={ROLE_LIST_ADMIN}
                  id="role"
                  name="role"
                  selectedItem={formik.values.role}
                  onSelect={(event) => {
                    formik.setFieldValue("role", event.name);
                    formik.setFieldValue(
                      "clientName",
                      event.name === AUTH_ROLE.admin
                        ? DEFAULT_NAME.downstreemAdmin
                        : clientList[0].name
                    );
                  }}
                  isNormalLetter
                />
              ) : (
                <input
                  className={clsx("form-control", styles["modal-body-input"])}
                  type="text"
                  value={formik.values.role}
                  id="role"
                  name="role"
                  disabled
                />
              )}
            </div>
            {isAdmin() && (
              <div className="mar_b24">
                <label
                  htmlFor="clientName"
                  className={styles["modal-body-label"]}
                >
                  Client
                </label>
                <DropdownSelector
                  widthStyle="auto"
                  size="md"
                  className={clsx("form-select", styles["modal-body-select"])}
                  itemList={clientList}
                  id="clientName"
                  name="clientName"
                  selectedItem={
                    formik.values.role === AUTH_ROLE.admin
                      ? DEFAULT_NAME.downstreemAdmin
                      : formik.values.clientName
                  }
                  onSelect={(event) => {
                    formik.setFieldValue("clientName", event.name);
                  }}
                  isLoading={loading}
                  isNormalLetter
                  isDisable={formik.values.role === AUTH_ROLE.admin}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer className={styles["modal-footer"]}>
            <Button name="Cancel" handleClick={handleClose} />
            <Button
              name="Create User"
              type="submit"
              isBorder={false}
              className="btn-primary-fill"
            />
          </Modal.Footer>
        </div>
      </form>
    </Modal>
  );
};

CreateUserModal.propTypes = {
  isShow: PropTypes.bool,
  errors: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  onClearErrorText: PropTypes.func,
};
