import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Constants
import { IMAGE_TYPE_ACCEPTED_STRING } from "constants/Constants";

// Components
import MatterAvatar from "../file-components/matter-avatar/MatterAvatar";

// Styles
import styles from "./CreateEntityAvatar.module.scss";

const CreateEntityAvatar = (props) => {
  const { matterId, entityId, handleSelectAvatar = () => { } } = props;

  const selectImgBtnRef = useRef(null);
  const [newAvatarUrl, setNewAvatarUrl] = useState(null);

  const chooseImage = () =>
    selectImgBtnRef.current && selectImgBtnRef.current.click();

  const onChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setNewAvatarUrl(reader.result);
    };

    if (file) {
      const url = reader.readAsDataURL(file);
      setNewAvatarUrl(url);
    }

    handleSelectAvatar(e);
  }

  return (
    <div className={styles["avatar"]}>
      <figure>
        {newAvatarUrl ? (
          <img
            src={newAvatarUrl}
            alt="Entity Avatar"
          />) : (
          <MatterAvatar
            matterId={matterId}
            id={entityId}
            avatarType="entity"
          />
        )}
      </figure>
      <span onClick={chooseImage} className={styles["choose-avatar"]}>
        <img src="/images/avatar-update.png" alt="avatar update" />
      </span>
      <input
        type="file"
        className="hidden"
        accept={IMAGE_TYPE_ACCEPTED_STRING}
        ref={selectImgBtnRef}
        onChange={onChange}
      />
    </div>
  );
};

CreateEntityAvatar.propTypes = {
  matterId: PropTypes.number.isRequired,
  entityId: PropTypes.string.isRequired,
  handleSelectAvatar: PropTypes.func.isRequired,
};

export default CreateEntityAvatar;
