import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

// Services
import { getQueryTypes } from "services/ISService";

// Redux
import { resetIsData, setIsData, setQueryType } from "store/ISReducer";
import { resetDateTime } from "store/DatePickerReducer";

// Constants
import { PROJECT_CONSTANT } from "constants/ProjectConstant";
import { LOCAL_STORAGE } from "constants/LocalStorage";
import { QUICK_SELECT_ITEM } from "constants/Common";

// Components
import SearchSelector from "components/shared/search-selector/SearchSelector";

// Styles
import styles from "./QueryTypes.module.scss";

const QueryTypes = () => {
  const dispatch = useDispatch();
  const { searchId, projectId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const localStorageProjectId =
    JSON.parse(localStorage.getItem(PROJECT_CONSTANT.projectId)) || "";

  const {
    isData: { dataSources, entities, dataTypes },
    queryType,
  } = useSelector((state) => state.is);

  const handleUpdate = (type, data) => {
    dispatch(setIsData({ [type]: data.map((item) => item?.id || item) }));
  };

  const getQueryType = async () => {
    setIsLoading(true);
    if (!localStorageProjectId)
      localStorage.setItem(PROJECT_CONSTANT.projectId, projectId);
    if (parseInt(projectId) !== localStorageProjectId && !searchId) {
      dispatch(resetIsData());
      dispatch(resetDateTime());
      localStorage.removeItem(`${LOCAL_STORAGE.searchData}${projectId}`);
    }

    try {
      const result = await getQueryTypes(projectId);
      dispatch(setQueryType(result.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (!error.response.status) return;
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getQueryType();
  }, [projectId]);

  return (
    <div className={styles["query-types"]}>
      {!isLoading ? (
        <>
          <div className={styles["query-type"]}>
            <SearchSelector
              title="Data Sources"
              placeholder="Find source"
              selectList={queryType?.custodianSources}
              selectValue="dataSourceId"
              selectName="custodianName"
              onSubmit={(e) =>
                handleUpdate(QUICK_SELECT_ITEM.dataSources.value, e)
              }
              suggestions={queryType?.custodianSources}
              data={dataSources}
            />
          </div>
          <div className={styles["query-type"]}>
            <SearchSelector
              title={QUICK_SELECT_ITEM.entities.label}
              placeholder="Find people"
              selectList={queryType?.entities}
              suggestions={queryType?.entities}
              onSubmit={(e) =>
                handleUpdate(QUICK_SELECT_ITEM.entities.value, e)
              }
              data={entities}
              selectValue="id"
              selectName="name"
            />
          </div>
          <div className={styles["query-type"]}>
            <SearchSelector
              reverseDropdown
              title={QUICK_SELECT_ITEM.dataTypes.label}
              placeholder="Find data type"
              selectList={queryType?.dataTypes}
              suggestions={queryType?.dataTypes}
              onSubmit={(e) =>
                handleUpdate(QUICK_SELECT_ITEM.dataTypes.value, e)
              }
              data={dataTypes}
              selectValue="value"
              selectName="label"
            />
          </div>
        </>
      ) : (
        <div className="h-100 d-flex align-items-center">
          <Spinner animation="border" variant="primary" role="status" />
        </div>
      )}
    </div>
  );
};

export default QueryTypes;
