import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

// Store
import {
  setCurrentImportId,
  setIsCancelImport,
  setIsSuccess,
  updateImportItemInQueue,
  setImportLoading,
  removeImportItemFromQueueById
} from 'store/ImportBlobReducer';
import { setSourceData, setSourceDetail, updateDataSourceProgress } from 'store/DataSourceReducer';

// Services
import { startNativeImport } from 'services/ImportManagementService';

// Constants
import {
  STATUS_IMPORT_BLOB,
  TOAST_MESSAGE_IMPORT_NATIVES
} from 'constants/Common';
import { IMPORT_BLOB_ACTION } from 'constants/DataSourceConstant';
import { IMPORT_STATUS } from 'constants/UploadConstant';

// Helpers
import { uploadFileToBlob } from 'helpers/ImportUploadHelpers';
import { clampedAll } from 'helpers/ImportPromiseAllHelper';
import {
  findIdCanImport,
  getPercent,
  getTotalSizeInFiles,
  handleTotalFileImport,
} from 'helpers/DataSourceHelper';

const NativeUploadManager = () => {
  const dispatch = useDispatch();
  const { currentImportId, importQueue, action } = useSelector(state => state.importBlob);
  const { sourceData: { sourceList } } = useSelector(state => state.dataSource.dataSourceManagement);

  const updateImportById = (importId, data = {}, otherImportData = {}) => {
    // Check if the import is still in the queue
    const importItem = importQueue.find(item => item.id === importId);
    if (!importItem) {
      return;
    }
    dispatch(
      updateImportItemInQueue({ id: importId, ...data })
    );
    if (otherImportData) {
      const otherImports = importQueue.filter((item) => item.id !== importId);
      otherImports.forEach((item) => {
        dispatch(
          updateImportItemInQueue({ id: item.id, ...otherImportData })
        );
      });
    }
  };

  const executeImport = (data) => {
    updateImportById(
      data.id,
      { status: STATUS_IMPORT_BLOB.inprogress, isDisable: false },
      { isDisable: true }
    );
    dispatch(setImportLoading(true));
    setTimeout(() => {
      const { files, folderName, projectId, dataSourceID } = data;
      dispatch(setIsCancelImport(false));
      onUploadFolder({ importId: data.id, files, folderName, projectId, dataSourceID });
    }, 1000);
  };

  const findImportById = (importId) =>
    importQueue.find((item) => item.id === importId);

  const getBytesImporting = (importId) => {
    return action !== IMPORT_BLOB_ACTION.resume ||
      action === IMPORT_BLOB_ACTION.pause
      ? []
      : findImportById(importId)?.listByte;
  };

  const onUploadFolder = async (data) => {
    dispatch(setIsSuccess(false));
    const { importId, files = [], folderName = "", projectId, dataSourceID } = data;
    if (files.length === 0) return;
    try {
      const cloneListFile = [...files];
      const MAX_PARALLEL_REQUEST = 10;
      const apiCalls = cloneListFile.map(
        (file) => () => uploadFileToBlob({
          matterId: projectId,
          dataSourceId: dataSourceID,
          folderName,
          file,
        }));
      dispatch(setImportLoading(false));
      const dataResult = await clampedAll({
        array: apiCalls,
        clamp: MAX_PARALLEL_REQUEST,
        setListByte: updateImportProgress,
        listBytes: getBytesImporting(importId),
        importId: importId,
      });
      const { blobBytes, blobCount = 0 } = handleTotalFileImport(dataResult);
      if (blobBytes === getTotalSizeInFiles(cloneListFile)) {
        const dataUpdate = { projectId, dataSourceID, blobCount, blobBytes };
        await startNativeImport(dataUpdate);
        dispatch(setIsSuccess(true));
        deleteImportDone();
      }
    } catch (error) {
      updateImportById(importId, { isFailed: true });
      console.log(error);
      toast.error(error.message === 'Upload canceled' ? TOAST_MESSAGE_IMPORT_NATIVES.cancel : TOAST_MESSAGE_IMPORT_NATIVES.failed);
      updateDataSourceStatus(dataSourceID, IMPORT_STATUS.failed);
      deleteImportDone();
    }
  };

  const updateDataSourceStatus = (dataSourceId, status) => {
    dispatch(
        setSourceData((prevState) => ({
          ...prevState,
          sourceList: prevState.sourceList.map((item) =>
            item.dataSourceId === dataSourceId
              ? { ...item, nativeStatus: status }
              : item
          ),
        }))
      );

      dispatch(
        setSourceDetail((prevState) =>
          prevState.dataSourceId === dataSourceId
            ? { ...prevState, nativeStatus: status }
            : prevState
        )
      );
  };

  const updateImportProgress = (updatedBytes, importId) => {
    if (importId !== currentImportId || updatedBytes?.length === 0) return;

    const currentImport = findImportById(importId);
    const { percent, totalSize, uploadedSize } = getPercent(importId, updatedBytes, importQueue);
    
    if (percent > (currentImport.percent || 0)) {
      const dataSource = sourceList.find(item => item.dataSourceId === currentImport.dataSourceID);
      if (percent > dataSource?.uploadProgress) {
        dispatch(updateDataSourceProgress({ 
          dataSourceId: dataSource.dataSourceId, 
          uploadProgress: percent,
          totalSize,
          uploadedSize
        }));
      }

      if (
        percent === 100 ||
        (importQueue.length > 0 && currentImport?.files.length === updatedBytes.length)
      ) {
        deleteImportDone();
      } else {
        updateImportById(importId, { 
          percent, 
          listByte: updatedBytes,
        });
      }
    }
  };

  const deleteImportDone = () => {
    dispatch(setCurrentImportId(""));
    dispatch(removeImportItemFromQueueById(currentImportId));
  };

  const getFilesImporting = (totalFiles = [], importedFiles = []) => {
    return totalFiles.filter(
      (total) =>
        !importedFiles.find(
          (item) => item.name === total.name && item.size === total.size
        )
    );
  };

  useEffect(() => {
    if (currentImportId) {
      const currentImport = findImportById(currentImportId);
      const { files = [], listByte = [] } = currentImport;
      executeImport({
        ...currentImport,
        files: getFilesImporting(files, listByte),
      });
    }
  }, [currentImportId]);

  useEffect(() => {
    if (!currentImportId) {
      const nextImportId = findIdCanImport(importQueue);
      if (!nextImportId) {
        return;
      }
      dispatch(setCurrentImportId(nextImportId));
    }
  }, [importQueue])

  return null;
};

const NativeUploadManagerPortal = () => {
  return ReactDOM.createPortal(
    <NativeUploadManager />,
    document.body
  );
};

export default NativeUploadManagerPortal;
