import { camelCase } from "lodash";
import { PAGE_NAME, PATH_NAME } from "./Common";

export const breadCrumbChatReview = [
  { value: PATH_NAME.communicationReview, label: "Review / Chat" },
];

export const breadCrumbEmailReview = [
  { value: PATH_NAME.emailReview, label: "Review / eMail" },
];

export const breadCrumbPeople = [
  { value: PATH_NAME.people, label: PAGE_NAME.people },
];

export const breadCrumbDataSource = [
  { value: PATH_NAME.dataSources, label: PAGE_NAME.dataSources },
];

export const breadCrumbStreems = (path) => {
  const streems = { value: PATH_NAME.streems, label: PAGE_NAME.streems };
  if (path === PATH_NAME.dataSources) return [...breadCrumbDataSource, streems];
  else if (
    [PATH_NAME.communicationAnalyzer, PATH_NAME.eventTimeline].includes(
      path
    )
  )
    return [...getInsightsBreadcrumb(path), streems];
  else return [streems];
};

export const getInsightsBreadcrumb = (path) => {
  if (path === PATH_NAME.communicationAnalyzer)
    return breadCrumbCommunicationAnalyzer();
  else if (path === PATH_NAME.eventTimeline)
    return breadCrumbEventTimeline();
}

export const breadCrumbEventTimeline = (path) => {
  const eventTimeline = { value: PATH_NAME.eventTimeline, label: PAGE_NAME.eventTimeline };
  return path
    ? [eventTimeline, { value: path, label: PAGE_NAME[camelCase(path)] }]
    : [eventTimeline];
};

export const breadCrumbCommunicationAnalyzer = (path) => {
  const communicationAnalyzer = { value: PATH_NAME.communicationAnalyzer, label: PAGE_NAME.communicationAnalyzer };
  return path
    ? [communicationAnalyzer, { value: path, label: PAGE_NAME[camelCase(path)] }]
    : [communicationAnalyzer];
};

export const breadCrumbSearch = (path) => {
  const search = { value: PATH_NAME.searchTerms, label: PAGE_NAME.search };
  return path
    ? [search, { value: path, label: PAGE_NAME[camelCase(path)] }]
    : [search];
};

export const breadCrumbIntelligentSearch = (path, searchId = "") => {
  const intelligentSearch = {
    value: `${PATH_NAME.intelligentSearch}/${searchId}`,
    label: PAGE_NAME.intelligentSearch,
  };
  return path
    ? [
        ...breadCrumbStreems(),
        { value: path, label: PAGE_NAME[camelCase(path)] },
        intelligentSearch,
      ]
    : [...breadCrumbStreems(), intelligentSearch];
};

export const breadCrumbSavedSearch = (path) => {
  const savedSearches = {
    value: PATH_NAME.savedSearches,
    label: PAGE_NAME.savedSearches,
  };
  return path
    ? [
        ...breadCrumbStreems(),
        { value: path, label: PAGE_NAME[camelCase(path)] },
        savedSearches,
      ]
    : [...breadCrumbStreems(), savedSearches];
};

export const breadCrumbSearchResult = (
  path,
  searchResultId,
  intelligentSearchPath = ""
) => {
  if (searchResultId || intelligentSearchPath) {
    if (path === PATH_NAME.savedSearches)
      return [
        ...breadCrumbSavedSearch(
          path !== intelligentSearchPath ? intelligentSearchPath : null
        ),
        {
          value: `${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/${searchResultId}`,
          label: PAGE_NAME.searchResult,
        },
      ];
    else
      return [
        ...breadCrumbIntelligentSearch(
          path !== intelligentSearchPath ? intelligentSearchPath : null,
          searchResultId
        ),
        {
          value: `${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/${searchResultId}`,
          label: PAGE_NAME.searchResult,
        },
      ];
  } else
    return [
      ...breadCrumbStreems(),
      { value: path, label: PAGE_NAME[camelCase(path)] },
      {
        value: `${path}/${PATH_NAME.searchResult}`,
        label: PAGE_NAME.searchResult,
      },
    ];
};

export const breadCrumbExportManagement = [
  { value: PATH_NAME.exportManagement, label: PAGE_NAME.exportManagement },
];

export const breadCrumbCreateExport = (id) => [
  ...breadCrumbExportManagement,
  {
    value: PATH_NAME.createExport,
    label: `${id ? "Edit Export" : "Create Export"}`,
  },
];

export const breadCrumbTagManagement = [
  { value: PATH_NAME.tagManagement, label: PAGE_NAME.tagManagement },
];

export const breadCrumbTimelineDetail = (title) => [
  ...breadCrumbEventTimeline(),
  {
    value: `${PATH_NAME.eventTimeline}/${PATH_NAME.detail}`,
    label: title || "Details",
  },
];

export const breadCrumbItemDetail = ({
  pathname = "",
  fromScreen,
  searchResultId,
  itemDetailTitle,
  timelineDetailTitle,
  intelligentSearchPath,
}) => {
  const itemDetail = { value: PATH_NAME.itemDetail, label: itemDetailTitle };
  switch (pathname) {
    case PATH_NAME.detail: //event-timeline detail
      return [...breadCrumbTimelineDetail(timelineDetailTitle), itemDetail];
    case searchResultId:
    case PATH_NAME.searchResult: // search result: intelligent-search, saved-search, concept-visualizer
      return [
        ...breadCrumbSearchResult(
          fromScreen,
          searchResultId,
          intelligentSearchPath
        ),
        itemDetail,
      ];
    case PATH_NAME.streems: // streems: data-sources, communication-analyzer, communication-analysis
      if (
        [
          PATH_NAME.dataSources,
          PATH_NAME.communicationAnalyzer,
        ].includes(fromScreen)
      )
        return [...breadCrumbStreems(fromScreen), itemDetail];
      else return [...breadCrumbStreems(), itemDetail];
    default:
      return [...breadCrumbStreems(), itemDetail];
  }
};
