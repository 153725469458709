import { useState } from "react";
import { baseUrl } from "../services/ApiConfig";
// Constants
import { AUTH_CONSTANT } from "constants/AuthConstant";

export const setDeviceToken = (deviceToken) => {
  localStorage.setItem(AUTH_CONSTANT.deviceToken, deviceToken);
};

export const getDeviceToken = () => {
  const deviceToken = localStorage.getItem(AUTH_CONSTANT.deviceToken);
  return deviceToken;
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_CONSTANT.token);
};
export const removeUserInfo = () => {
  localStorage.removeItem(AUTH_CONSTANT.userInfo);
};

export const saveUserLogin = (username) => {
  localStorage.setItem(AUTH_CONSTANT.userName, JSON.stringify(username));
};
export const getUserLogin = () => {
  const username = localStorage.getItem(AUTH_CONSTANT.userName);
  return username ? JSON.parse(username) : null;
};

export const removeUserLogin = () => {
  localStorage.removeItem(AUTH_CONSTANT.userName);
};
export const useToken = () => {
  const getToken = () => {
    const tokenString = localStorage.getItem(AUTH_CONSTANT.token);
    return tokenString ? JSON.parse(tokenString) : null;
  };

  const getUser = () => {
    const userString = localStorage.getItem(AUTH_CONSTANT.userInfo);
    return userString
      ? JSON.parse(userString)
      : {
          userName: "user",
          clientName: "Downstreem LLC",
          clientImage: `${baseUrl}/images/maven.png`,
          email: "",
          avatar: `${baseUrl}/images/maven.png`,
          lastName: "Downstreem User",
        };
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (userToken) => {
    localStorage.setItem(AUTH_CONSTANT.token, JSON.stringify(userToken));
    setToken(userToken);
  };

  const saveUser = (userData) => {
    localStorage.setItem(AUTH_CONSTANT.userInfo, JSON.stringify(userData));
    setUser(userData);
  };

  const isAdmin = () => {
    try {
      return user && user.roles && user.roles.includes("Admin");
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const isClientAdmin = () => {
    try {
      return user && user.roles && user.roles.includes("Client Admin");
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  const isUser = () => {
    try {
      return (
        user &&
        user.roles &&
        !user.roles.includes("Client Admin") &&
        !user.roles.includes("Admin")
      );
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return {
    setToken: saveToken,
    setUser: saveUser,
    token,
    user,
    isAdmin,
    isClientAdmin,
    isUser,
  };
};
