const AUTH_CONSTANT = {
  accountFound: "accountFound",
  previousPath: "previousPath",
  userName: "username",
  userInfo: "user-infor",
  token: "token",
  deviceToken: "device-token",
  tokenForgotPass: "tokenForgotPass",
  timeout: "timeOut",
  typeSendCode: "typeSendCode",
  dontShowNotiDownLoad: "dont-show-notification-download",
};

const AUTH_ROLE = {
  user: "User",
  clientAdmin: "Client Admin",
  admin: "Admin",
};

const USER_STATUS = {
  pending: 0,
  activated: 1,
};

const YUP_METHOD = {
  phoneLength: "phoneLength",
  emailValid: "emailValid",
};

const YUP_TEST = {
  minMax: "min-max",
  specialKeyword: "special-keyword",
};

const COL_TABLE_UA = {
  userName: "userName",
  email: "email",
  phone: "phone",
  project: "project",
  dateActive: "dateActive",
  status: "status",
};

const ROLE_LIST_ADMIN = [
  { name: AUTH_ROLE.user },
  { name: AUTH_ROLE.clientAdmin },
  { name: AUTH_ROLE.admin },
];

const COPY_RIGHT_LINK = {
  termOfUse: "https://www.downstreem.com/tou",
  privacyPolicy: "https://www.downstreem.com/privacypolicy",
};

export {
  AUTH_CONSTANT,
  AUTH_ROLE,
  USER_STATUS,
  COL_TABLE_UA,
  ROLE_LIST_ADMIN,
  YUP_METHOD,
  YUP_TEST,
  COPY_RIGHT_LINK,
};
