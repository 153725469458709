import axios, { authHeader, getAbortController } from "./ApiConfig";
import { checkBodyConvertTimeToUTC } from "helpers/ObjectHelper";

const getListEventTimelineApi = (projectId, paramBody) =>
  axios(`/eventTimeline/${projectId}`, {
    headers: authHeader(),
    method: "post",
    data: paramBody,
    timeout: 180000
  });

const getTimelineDates = (projectId, paramBody) =>
  axios(`/eventTimeline/${projectId}/timelineDates`, {
    headers: authHeader(),
    method: "post",
    data: paramBody
  });

const getChatParticipants = (projectId, groupId) =>
  axios(`/eventTimeline/${projectId}/chatParticipants/${groupId}`, {
    headers: authHeader(),
    method: "get"
  });

const getEventTimelineQuickSelect = (projectId) =>
  axios.get(`/review/${projectId}/quickSelect`, {
    headers: authHeader(),
  });

// reuse api of intelligent search because it's the same with IS result detail
const getEventTimelineDetailApi = (path, projectId, isResultId, params = {}) =>
  axios(`/intelligentSearch/${path}/${projectId}/${isResultId}`, {
    headers: authHeader(),
    method: "get",
    params: checkBodyConvertTimeToUTC(params),
    signal: getAbortController().signal,
    timeout: 0,
  });

const exportMessageResultToExcel = (projectId, isResultId, params = {}) => {
  params = checkBodyConvertTimeToUTC(params);
  return axios(
    `/intelligentSearch/chat/${projectId}/${isResultId}/exportExcel`,
    {
      headers: authHeader(),
      method: "get",
      params: {
        ...params,
        TimeOffset: new Date().getTimezoneOffset(),
      },
      responseType: "blob",
    }
  );
};

const exportEmailResultToExcel = (projectId, isResultId, params = {}) => {
  params = checkBodyConvertTimeToUTC(params);
  return axios(
    `/intelligentSearch/email/${projectId}/${isResultId}/exportExcel`,
    {
      headers: authHeader(),
      method: "get",
      params: {
        ...params,
        TimeOffset: new Date().getTimezoneOffset(),
      },
      responseType: "blob",
    }
  );
};

export {
  getListEventTimelineApi,
  getEventTimelineQuickSelect,
  getEventTimelineDetailApi,
  exportMessageResultToExcel,
  exportEmailResultToExcel,
  getTimelineDates,
  getChatParticipants
};
