// Helpers
import { toThousandsNumber } from "helpers/FormatterHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getDeletedStatus } from "./TextHelper";
import { getDuration } from "helpers/FormatterHelper";

// Constants
import { PARAMETERS } from "constants/Common";
import { DATE_TIME_TYPE } from "constants/Constants";

export const getInformationByKey = (key, data) => {
  switch (key) {
    case PARAMETERS.type:
    case PARAMETERS.typeLabel:
      return data[key];
    case PARAMETERS.totalMessage:
    case PARAMETERS.messageCount:
      return toThousandsNumber(data[key]);
    case PARAMETERS.dateTime:
    case PARAMETERS.createdDate:
    case PARAMETERS.updatedDate:
    case PARAMETERS.lastConnection:
    case PARAMETERS.lastAutoConnection:
    case PARAMETERS.dateLastUsed:
    case PARAMETERS.expirationDate:
    case PARAMETERS.creationTime:
    case PARAMETERS.dateFirst:
    case PARAMETERS.dateLast:
    case PARAMETERS.lastVisited:
    case PARAMETERS.startTime:
    case PARAMETERS.endTime:
      return formatDateTime({
        dateTime: data[key] || "",
        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
      });
    case PARAMETERS.duration:
      return data[key] ? getDuration(data[key]) : "";
    case PARAMETERS.deletedState:
      return getDeletedStatus(data[key]);
    case PARAMETERS.latitude:
    case PARAMETERS.longitude: {
      if (data[key] === 0) return 0;
      return data[key];
    }
    default:
      return data[key] || "";
  }
};

export const getNameByDataSource = (id, sources) =>
  sources.find((source) => source.dataSourceId === id)?.custodianName;
