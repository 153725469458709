import BenzAMRRecorder from "benz-amr-recorder";

// Helpers
import { formatDuration } from "helpers/DateTimeFormatterHelper";

// Constants
import { FILE_TYPE } from "constants/DataType";

export const getDurationByUrl = (url = "") => {
  if (!url) return "";
  const newUrl = url;
  return new Promise((resolve) => {
    if (url.indexOf(`.${FILE_TYPE.amr}`) === -1) {
      // For video type, parse to audio to get duration
      const media = new Audio(newUrl);
      media.onloadeddata = () => {
        resolve(formatDuration(Math.floor(media.duration)));
      };
      media.onemptied = () => {
        resolve("");
      }
      media.onerror = async () => {
        //handle browser firefox get onloadeddata error
        const video = document.querySelector(".video-react-video");
        if (!video) {
          resolve("");
          return;
        }
        if (isNaN(video.duration)) {
          const newDuration = await getDurationByUrl(url);
          resolve(newDuration);
          return;
        }
        resolve(formatDuration(video.duration));
      };
    } else {
      const amr = new BenzAMRRecorder();
      // get duration type audio/amr
      amr
        .initWithUrl(newUrl)
        .then(() => resolve(formatDuration(Math.floor(amr.getDuration()))))
        .catch(() => resolve(""));
    }
  });
};
