import { createSlice } from "@reduxjs/toolkit";
import { SORT_BY } from "constants/Constants";

const initialState = {
  savedSearch: {
    searchInput: null,
    pageIndex: 1,
    columnSort: "dateCreated",
    orderBy: SORT_BY.desc,
    sortColumns: [
      { type: "type", isAcs: true },
      { type: "searchName", isAcs: true },
      { type: "user", isAcs: true },
      { type: "count", isAcs: true },
      { type: "dateCreated", isAcs: false },
      { type: "dateLastRun", isAcs: true },
    ],
  },
  projectAdminSearch: {
    searchInput: null,
    pageIndex: 1,
  },
};
const searchConditionSlice = createSlice({
  name: "searchCondition",
  initialState,
  reducers: {
    setSavedSearchCondition: (state, action) => {
      state.savedSearch = { ...state.savedSearch, ...action.payload };
    },
    clearSavedSearchCondition: (state) => {
      state.savedSearch = initialState.savedSearch;
    },
    setProjectAdminSearchCondition: (state, action) => {
      state.projectAdminSearch = action.payload;
    },
    clearProjectAdminSearchCondition: (state) => {
      state.projectAdminSearch = initialState.projectAdminSearch;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setSavedSearchCondition,
  clearSavedSearchCondition,
  setProjectAdminSearchCondition,
  clearProjectAdminSearchCondition,
} = searchConditionSlice.actions;
export const SearchConditionReducer = searchConditionSlice.reducer;
