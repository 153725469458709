import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

// Constants
import { CONTENT_FILTER_CONSTANT } from "constants/ContentFilter";

// Components
import PIITranscription from "./PIITranscription/PIITranscription";

// Styles
import styles from "./PIIBubble.module.scss";

const PIIBubble = () => {
  const popupPIIRef = useRef();
  const [isDisplayPIIPopup, setIsDisplayPIIPopup] = useState(false);
  const contentFilter = JSON.parse(
    localStorage.getItem(CONTENT_FILTER_CONSTANT())
  );

  useOnClickOutside(
    popupPIIRef,
    () => isDisplayPIIPopup && setIsDisplayPIIPopup(false)
  );

  return (
    <>
      {contentFilter?.isPII ? (
        <div className={styles["PII"]}>
          <div
            className={styles["PII-circle"]}
            onClick={() => setIsDisplayPIIPopup(true)}
          >
            <div className={styles["PII-circle-text"]}>PII</div>
          </div>
          <div className={styles["PII-popup"]}>
            {isDisplayPIIPopup && (
              <div ref={popupPIIRef}>
                <PIITranscription
                  toggle={isDisplayPIIPopup}
                  handleClosePopup={() => setIsDisplayPIIPopup(false)}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

PIIBubble.propTypes = {
  handleClosePopup: PropTypes.func,
};

export default PIIBubble;
