import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { RenderTagSelected } from "components/shared/render-tag-selected/RenderTagSelected";
import RenderAttachment from "components/shared/attachment-view/RenderAttachment";
import SourceIcon from "components/shared/source-icon/SourceIcon";

// helper
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getEmailToOrCc, getMailSender } from "helpers/EmailReviewHelper";

// Constants
import {
  CONVERSATION_ACTION,
  DATE_TIME_TYPE,
  EMAIL_SEND_MODE,
  SLACK_SOURCE,
} from "constants/Constants";

// Styles
import styles from "./EmailItem.module.scss";

export const EmailItem = (props) => {
  const {
    data,
    isShowCheck = true,
    handleChecked,
    checkedEmail,
  } = props;

  const EVENT_SOURCE = "Event";

  const renderEmailMode = () => {
    return Object.values(EMAIL_SEND_MODE).map((emailMode, index) => {
      const emailModeValue = getEmailToOrCc(emailMode, data?.emailParties);
      if (emailModeValue) {
        return (
          <p key={index} className={styles["email-header-info-to"]}>
            {emailModeValue ? `${emailMode}: ` : ""}
            <span dangerouslySetInnerHTML={{ __html: emailModeValue }}></span>
          </p>
        );
      }
      return "";
    });
  };

  return (
    <div
      className={clsx(styles["email-wrap"])}
      id={`email-item-${data?.docID}`}
    >
      <div className="d-flex justify-content-between">
        {data.source !== EVENT_SOURCE ? (
          <div className={styles["email-box"]}>
            <p
              className={styles["email-title"]}
              dangerouslySetInnerHTML={{ __html: data.subject }}
            ></p>
            <div className={styles["email-header"]}>
              <SourceIcon
                sourceType={
                  data.snippet === SLACK_SOURCE ? "slack" : data?.source
                }
                defaultIcon="email"
              />
              <div className={styles["email-header-info"]}>
                <p className={styles["email-header-info-name"]}>
                  <span
                    className={styles["mail-sender"]}
                    dangerouslySetInnerHTML={{
                      __html: `${`<b>${
                        getMailSender(data?.emailParties)?.name
                      }</b>`} < ${getMailSender(data?.emailParties)?.email}>`,
                    }}
                  ></span>
                </p>
                <p className={styles["email-header-info-date"]}>
                  {formatDateTime({
                    dateTime: data?.dateSend,
                    type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
                  })}
                </p>
                {renderEmailMode()}
              </div>
            </div>
            <div className={styles["email-content-wrap"]}>
              <div dangerouslySetInnerHTML={{ __html: data.body }}></div>
            </div>
            {data?.attachments?.length > 0 && data.attachments.map((item) => {
              const { attachmentID } = item;
              return (
                <div
                  key={attachmentID}
                  className={styles["attach-item"]}
                  id={`email-item-${attachmentID}`}
                >
                  <RenderAttachment attachment={item} isAttachmentEmail />
                </div>
              );
            })}
          </div>
        ) : (
          <div className={styles["conversation-action"]}>
            <span
              className={clsx(
                styles["content"],
                data.labels === CONVERSATION_ACTION.leave ? styles["leave"] : ""
              )}
            >
              <p
                className={styles["email-title"]}
                dangerouslySetInnerHTML={{ __html: data.body }}
              ></p>
            </span>
            <span className={styles["date"]}>
              {formatDateTime({
                dateTime: data.dateSend,
                type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
              })}
            </span>
          </div>
        )}

        <div className={styles["email-action"]}>
          {isShowCheck && (
            <input
              className={styles["input-check"]}
              type="checkbox"
              onChange={handleChecked}
              value={data?.emailID}
              checked={checkedEmail?.some((val) => val === data?.emailID)}
            />
          )}
          <div className={styles["tag-thumb-wrap"]}>
            <RenderTagSelected tagsSelected={data?.tags} />
          </div>
        </div>
      </div>
    </div>
  );
};

EmailItem.propTypes = {
  isBorderTop: PropTypes.bool,
  isShowCheck: PropTypes.bool,
  value: PropTypes.bool,
  data: PropTypes.object,
  checkedEmail: PropTypes.array,
  handleChecked: PropTypes.func,
  getHighlightedText: PropTypes.func,
};
