import React from "react";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";

// Components
import { Button } from "components/shared/button/Button";
import FieldSelect from "components/shared/field/field-select/FieldSelect";
import FieldUpload from "components/shared/field/field-upload/FieldUpload";

// Constants & Helpers
import { SOURCE_VALIDATION, VALIDATION_TEXT } from "constants/Validations";
import { getFileTypeFromName } from "helpers/GetFileNameHelper";
import { IMPORT_TYPE } from "constants/DataSourceConstant";

// Styles
import styles from "./AddSourceDataModal.module.scss";

const AddSourceDataModal = (props) => {
  const { show = false, onClose, onSubmit } = props;

  const fileTypeList = Object.values(IMPORT_TYPE).sort((a, b) =>
    a.label.localeCompare(b.label)
  );

  const addSourceSchema = Yup.object().shape({
    sourceTypeId: Yup.string().required(VALIDATION_TEXT.required),
    fileUpload: Yup.mixed().required(VALIDATION_TEXT.required),
  });

  const getTypeExtension = (id) =>
    fileTypeList.find((item) => item.value === id)?.typeExtension;

  const handleSubmitForm = (values, setFieldError) => {
    if (
      getFileTypeFromName(values.fileUpload.name) !==
      getTypeExtension(values.sourceTypeId)
    ) {
      setFieldError("fileUpload", SOURCE_VALIDATION.typeExtension.invalid);
      return;
    }
    onSubmit(values);
  };

  return (
    <Formik
      initialValues={{
        sourceTypeId: "",
        fileUpload: null,
      }}
      validationSchema={addSourceSchema}
      onSubmit={(values, { setFieldError }) =>
        handleSubmitForm(values, setFieldError)
      }
    >
      {({ values, handleSubmit }) => (
        <Form>
          <Modal
            show={show}
            onHide={onClose}
            centered
            backdrop="static"
            className={styles["modal-container"]}
            dialogClassName={styles["modal-dialog"]}
            contentClassName={styles["modal-content"]}
          >
            <Modal.Header closeButton className={styles["modal-header"]}>
              <Modal.Title>Add Source Data</Modal.Title>
            </Modal.Header>
            <Modal.Body className={styles["modal-body"]}>
              <FieldSelect
                label="Source Type"
                name="sourceTypeId"
                placeHolder="Please select an option..."
                options={fileTypeList}
                required
              />
              {values.sourceTypeId && (
                <p>
                  *Note: You only upload the file .
                  {getTypeExtension(values.sourceTypeId)?.toUpperCase()}
                </p>
              )}
              <FieldUpload
                label="Upload Path"
                name="fileUpload"
                placeHolder="Source Path..."
                required
              />
            </Modal.Body>
            <Modal.Footer className={styles["modal-footer"]}>
              <div className={styles["group-btn"]}>
                <Button name="Cancel" handleClick={onClose} />
                <Button
                  className="btn-primary-fill"
                  name="Add"
                  type="submit"
                  handleClick={handleSubmit}
                />
              </div>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

AddSourceDataModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default AddSourceDataModal;
