import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { useDispatch } from "react-redux";

// Store
import { setDuration } from "store/MediaControlReducer";

// Components
import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  VolumeMenuButton,
} from "video-react";
import CircularProgressBar from "../circular-progress-bar/CircularProgressBar";
import SpinnerLoading from "../spinner-loading/SpinnerLoading";

// Constants
import { DATA_TYPE } from "constants/DataType";
import { CONTENT_TYPE } from "constants/Constants";

// Helpers
import { getFileNameFromUrl } from "helpers/GetFileNameHelper";
import { getDurationByUrl } from "helpers/UrlHelper";

// Styles
import styles from "./MediaConvert.module.scss";

const MediaConvert = ({ url, type, isConvert = false }) => {
  const dispatch = useDispatch();

  const [newUrl, setNewUrl] = useState("");
  const [progress, setProgress] = useState(0);

  let blobUrl = null;
  let ffmpeg = null;

  const fileName = getFileNameFromUrl(url);
  ffmpeg = createFFmpeg({
    log: false,
    progress: ({ ratio }) => {
      setProgress(Math.round(ratio * 100));
    },
  });

  const doTranscode = async () => {
    try {
      if (!ffmpeg.isLoaded()) await ffmpeg.load();
      ffmpeg.FS(
        "writeFile",
        fileName,
        await fetchFile(url)
      );
      // Convert mp4 if file is video
      if (type === DATA_TYPE.video) {
        await ffmpeg.run("-i", fileName, "output.mp4");
        const data = ffmpeg.FS("readFile", "output.mp4");
        blobUrl = URL.createObjectURL(
          new Blob([data.buffer], { type: CONTENT_TYPE.mp4 })
        );
        setNewUrl(blobUrl);
      }

      // Convert mp3 if file is audio
      if (type === DATA_TYPE.audio) {
        await ffmpeg.run("-i", fileName, "output.mp3");
        const data = ffmpeg.FS("readFile", "output.mp3");
        blobUrl = URL.createObjectURL(
          new Blob([data.buffer], { type: CONTENT_TYPE.mpeg })
        );
        setNewUrl(blobUrl);
      }

      // get duration after convert
      const duration = await getDurationByUrl(blobUrl);
      dispatch(setDuration(duration));
    } catch (error) {
      console.log(error);
      setNewUrl(url);
    }
  };

  const cancelTranscode = () => {
    try {
      ffmpeg.exit();
    } catch (error) {
      console.log(error);
    }
    ffmpeg = null;
  };

  useEffect(() => {
    doTranscode();

    return () => {
      dispatch(setDuration(null));
      cancelTranscode(); //cancel create ffmpeg if exits
      URL.revokeObjectURL(blobUrl); //revoke blob when unmounted
    };
  }, []);

  const renderVideo = () => (
    <Player src={newUrl} fluid={false} width="100%" height="100%">
      <BigPlayButton position="center" />
      <ControlBar autoHide={false}>
        <ReplayControl seconds={10} order={2} />
        <ForwardControl seconds={10} order={3} />
        <VolumeMenuButton vertical order={4} />
        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={5} />
      </ControlBar>
    </Player>
  );

  const renderAudio = () => (
    <audio controls src={newUrl} title={getFileNameFromUrl(url)}>
      <a href={newUrl}>Download audio</a>
    </audio>
  );

  return newUrl ? (
    <>
      {type === DATA_TYPE.video && renderVideo()}
      {type === DATA_TYPE.audio && renderAudio()}
    </>
  ) : (
    <div className={styles["loading"]}>
      {isConvert ? (
        <CircularProgressBar
          value={progress}
          text={progress}
          width={70}
          height={70}
        />
      ) : (
        <SpinnerLoading />
      )}
    </div>
  );
};

MediaConvert.propTypes = {
  url: PropTypes.string,
  type: PropTypes.oneOf([DATA_TYPE.video, DATA_TYPE.audio]),
  isConvert: PropTypes.bool,
};

export default MediaConvert;
