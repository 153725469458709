import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Spinner } from "react-bootstrap";
import { Calendar } from "react-date-range";
import { enGB } from "react-date-range/dist/locale";

// Components
import { Button } from "components/shared/button/Button";
import { TimePicker } from "components/shared/date-picker/TimePicker";

// Constants & Helpers
import { DATE_TIME, DATE_TIME_TYPE } from "constants/Constants";
import { DATE_SELECTOR } from "constants/DateSelector";
import {
  formatDateTime,
  formatDateTimeWithOriginal,
  getHourByFrame,
  getMinuteDisplay,
  formatDataTimeValue,
  convertDate,
} from "helpers/DateTimeFormatterHelper";

// Styles
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./styles.scss";

const DISABLE_TYPE = {
  before: "before",
  after: "after",
};
export const OnlyDateSelector = (props) => {
  const {
    initDate,
    handleSubmit,
    showDatePicker,
    handleClose,
    btnSubmitName = "Save",
    disableBtnReset = true,
    disableType,
    errorMsg = "",
    title = "Date Filter",
    loading = false,
  } = props;
  const today = new Date();

  const [timeSingle, setTimeSingle] = useState(convertDate(today));
  const [timeFrameStart, setTimeFrameStart] = useState(
    today.getHours() < 12
      ? DATE_SELECTOR.timeFrame.am
      : DATE_SELECTOR.timeFrame.pm
  );
  const [hourStart, setHourStart] = useState(today.getHours());
  const [minuteStart, setMinuteStart] = useState(today.getMinutes());
  const [displayDateSelect, setDisplayDateSelect] = useState(null);
  const [dateCurrent, setDateCurrent] = useState(today);
  const [isChangeDate, setIsChangeDate] = useState(false);

  const handleSelectSingleDate = (e) => {
    setTimeSingle(convertDate(e));
  };

  const onSelectAmOrPmStart = (e) => {
    setTimeFrameStart(
      e.target.innerHTML === DATE_SELECTOR.timeFrame.am
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm
    );
    initialDisplayDate(true);
    setIsChangeDate(true);
  };

  const onChangeHourStart = (e) => {
    let dataParse = parseInt(e.target.value);
    setHourStart(dataParse);
    setIsChangeDate(true);
  };

  const onChangeMinuteStart = (e) => {
    let dataParse = parseInt(e.target.value);
    setMinuteStart(dataParse);
    setIsChangeDate(true);
  };

  const initialDisplayDate = () => {
    const dateStart = dateCurrent !== today && dateCurrent;
    const minuteStartCheck = minuteStart;
    let hourStartCheck = hourStart;
    // Check if
    if (timeFrameStart === DATE_SELECTOR.timeFrame.am) {
      hourStartCheck = hourStartCheck === 12 ? 0 : hourStartCheck;
    }
    hourStartCheck = getHourByFrame(timeFrameStart, hourStartCheck);

    const displayDateSelectInitial = {
      dateStart,
      timeStart: `${
        hourStartCheck > 12 ? hourStartCheck - 12 : hourStartCheck
      }:${minuteStartCheck}`,
      timeFrameStart,
    };
    // Update single time
    setTimeSingle(
      formatDateTime({
        dateTime: dateStart,
        type: DATE_TIME_TYPE.YYYYMMDD,
        addZulu: false,
      })
    );
    setDisplayDateSelect(displayDateSelectInitial);
  };

  const onSelectCurrentDate = (date) => {
    handleSelectSingleDate(date);
    setDateCurrent(date);
    setIsChangeDate(true);
  };

  // Handle submit save btn
  const handleSubmitDate = async () => {
    const dateTimeDataStart = formatDataTimeValue({
      date: timeSingle,
      timeFrame: timeFrameStart,
      hour: hourStart,
      minute: getMinuteDisplay(minuteStart),
    });
    handleSubmit(dateTimeDataStart);
  };

  // Handle reset filter
  const handleClearFilter = () => {
    // Reset current date
    handleSelectSingleDate(today);
    setDateCurrent(today);
    let start = today;
    // Update time picker
    setHourStart(start.getHours());
    setMinuteStart(start.getMinutes());
    setTimeFrameStart(
      start.getHours() < 12
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm
    );
    handleClose();
    setIsChangeDate(false);
    handleSubmit(start);
  };

  const handleDisableDays = (date) => {
    if (disableType === DISABLE_TYPE.after) return date > today ? true : false;
    if (disableType === DISABLE_TYPE.before) {
      const dayBefore = new Date();
      dayBefore.setDate(dayBefore.getDate() - 1);
      return date < dayBefore ? true : false;
    }
  };

  const onHideDatePicker = () => {
    handleClose();
    setIsChangeDate(false);
  };

  // Observe to change display date
  useEffect(() => {
    initialDisplayDate();
  }, [hourStart, minuteStart, dateCurrent]);

  useEffect(() => {
    initialDisplayDate(true);
  }, [timeFrameStart]);

  useEffect(() => {
    if (isChangeDate) return;
    if (initDate) {
      const dateInit = new Date(initDate);
      setDateCurrent(dateInit);
      setHourStart(dateInit.getHours());
      setMinuteStart(dateInit.getMinutes());
      setTimeFrameStart(
        dateInit.getHours() < 12
          ? DATE_SELECTOR.timeFrame.am
          : DATE_SELECTOR.timeFrame.pm
      );
    }
  }, [initDate]);

  return (
    <div>
      <Modal
        className="date-picker-control only-date-picker"
        show={showDatePicker}
        onHide={onHideDatePicker}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="date-picker-control__body body-picker">
          <Calendar
            date={dateCurrent}
            onChange={onSelectCurrentDate}
            locale={enGB}
            disabledDay={handleDisableDays}
            showPreview={false}
            minDate={new Date(DATE_TIME.minDate)}
          />
          <div className="d-flex justify-content-around">
            <TimePicker
              displayDateSelect={displayDateSelect}
              selectAmOrPm={onSelectAmOrPmStart}
              onChangeHour={onChangeHourStart}
              onChangeMinute={onChangeMinuteStart}
              initialDisplayDate={() => {}}
            />
          </div>
          <span className="error">
            {errorMsg}
            {loading && <Spinner variant="primary" animation="border" />}
          </span>
        </Modal.Body>
        <Modal.Footer className="date-picker-control__footer">
          <div className="group-btn_clear_submit">
            <div className="select-date-container">
              <span className="select-date-container__title">Selected:</span>
              <b className="pe-2">
                {formatDateTime({
                  dateTime: displayDateSelect?.dateStart,
                  type: DATE_TIME_TYPE.MMM_DD_YYYY,
                  addZulu: false,
                })}
              </b>
              <span className="pe-2">
                {formatDateTimeWithOriginal({
                  dateTime: displayDateSelect?.timeStart,
                  original: DATE_TIME_TYPE.HHmm,
                  newFormat: DATE_TIME_TYPE.HHmm,
                  addZulu: false,
                })}
              </span>
              {displayDateSelect?.timeFrameStart}
            </div>
            <div className="btn_group">
              {!disableBtnReset && (
                <Button
                  name="Reset"
                  className="btn_clear_filter_date"
                  handleClick={() => {
                    handleClearFilter();
                  }}
                />
              )}
              <Button
                name={btnSubmitName}
                className="btn-primary-fill"
                handleClick={handleSubmitDate}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

OnlyDateSelector.propTypes = {
  initDate: PropTypes.any,
  btnSubmitName: PropTypes.string,
  title: PropTypes.string,
  errorMsg: PropTypes.string,
  disableBtnReset: PropTypes.bool,
  disableBtnCancel: PropTypes.bool,
  showDatePicker: PropTypes.bool,
  isDisable: PropTypes.bool,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
  disableType: PropTypes.oneOf([DISABLE_TYPE.before, DISABLE_TYPE.after]),
};
