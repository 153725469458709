import axios, { authHeader, getAbortController } from "./ApiConfig";

// Constants & Helpers
import { METHOD_API } from "constants/Common";

const searchStreems = (projectId, paramBody) =>
  axios(`/search/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: paramBody,
    signal: getAbortController().signal,
    timeout: 210000
  }
);

const getSearchHits = (paramBody) =>
  axios(`/search/getSearchHits`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: paramBody,
    signal: getAbortController().signal,
    timeout: 210000
  }
);

const getMessageRange = (paramBody) =>
  axios(`/search/getMessageRange`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: paramBody,
    signal: getAbortController().signal
  }
);

export {
    searchStreems,
    getSearchHits,
    getMessageRange
};
