import { DATA_TYPE, DATA_TYPE_LABEL } from "constants/DataType";

const EMAIL_PREVIEW = {
  PII_Transcription: [
    {
      name: "Name",
      color: "#FFB0AC",
      data: ["Kerry Larrence", "John Doe", "Times New"],
    },
    {
      name: "Email",
      color: "#FFEDAC",
      data: ["kerry-l@email.com"],
    },
    {
      name: "Phone number",
      color: "#ACF0FF",
      data: ["1-225-334-2345"],
    },
  ],
  dataTypesChatEmailReview: [
    { label: DATA_TYPE_LABEL.chat, value: DATA_TYPE.chat },
    { label: DATA_TYPE_LABEL.email, value: DATA_TYPE.email },
  ],
};

const EMAIL_KEY = {
  mode: 0,
  subject: 1,
  partyName: 2,
  partyMail: 3,
  body: 4,
  from: 5,
  to: 6,
};

export { EMAIL_PREVIEW, EMAIL_KEY };
