import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";

// Constants
import { DATE_SELECTOR } from "constants/DateSelector";
import { EVENT_KEY_LISTENER, KEY_CODE } from "constants/Common";

// Styles
import "components/shared/date-picker/styles.scss";

export const TimePicker = (props) => {
  const {
    hourRef,
    minuteRef,
    selectAmOrPm,
    onChangeMinute,
    onChangeHour,
    displayDateSelect,
    typeDateFilter,
    initialDisplayDate,
  } = props;

  const loadingZeros = (input) => {
    if (
      input.target.value &&
      input.target.value.toString().length < 2 &&
      input.target.value >= 0
    ) {
      input.target.value = "0" + input.target.value;
    }
  };

  const maxLengthCheck = (input) => {
    if (input.target.value.length > input.target.maxLength) {
      input.target.value = input.target.value.slice(0, input.target.maxLength);
    }
  };

  const keyUpHour = (input) => {
    if (input.target.value > 12) input.target.value = "12";
    else if (input.target.value < 0) input.target.value = "0";
    if (!input.target.value) input.target.value = "0";
  };

  const keyUpMinutes = (input) => {
    if (input.target.value > 59) input.target.value = "59";
    else if (input.target.value < 0) input.target.value = "0";
    if (!input.target.value) input.target.value = "0";
  };

  const timeStart = displayDateSelect?.timeStart;
  const timeEnd = displayDateSelect?.timeEnd;
  const eventKeyAM = 0;
  const eventKeyPM = 1;

  const getTime = (frame) =>
    frame > 0 ? (frame < 10 ? "0" + frame : frame) : "";

  // Always jump to end of number, prevent user move cursor
  useEffect(() => {
    const inputTimeStart = document.getElementsByClassName("input-time");
    [...inputTimeStart]?.forEach((element) => {
      element.addEventListener(EVENT_KEY_LISTENER.keydown, function (e) {
        if (e.keyCode === KEY_CODE.arrowRight || e.keyCode === KEY_CODE.arrowLeft)
          e.preventDefault();
      });
      element.addEventListener(
        EVENT_KEY_LISTENER.click,
        function handleClick() {
          const value = element.value;
          element.value = "";
          element.value = value;
        }
      );
    });
  });

  return (
    <div className="time-picker-control">
      <input
        className="input-time"
        ref={hourRef}
        type="number"
        min="0"
        max="12"
        maxLength="3"
        onInput={(e) => {
          maxLengthCheck(e);
          keyUpHour(e);
        }}
        onKeyUp={keyUpHour}
        onKeyDown={(e) =>
          ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
        }
        onChange={onChangeHour}
        onBlur={(e) => {
          loadingZeros(e);
          initialDisplayDate(true); // used to check end time greater than start time
        }}
        onClick={loadingZeros}
        placeholder="00"
        value={
          typeDateFilter === DATE_SELECTOR.type.between
            ? getTime(timeEnd?.substr(0, timeEnd.indexOf(":")))
            : getTime(timeStart?.substr(0, timeStart.indexOf(":")))
        }
      />
      :
      <input
        className="input-time"
        ref={minuteRef}
        type="number"
        min="0"
        max="59"
        maxLength="3"
        onInput={(e) => {
          maxLengthCheck(e);
          keyUpMinutes(e);
        }}
        onKeyUp={keyUpMinutes}
        onKeyDown={(e) =>
          ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
        }
        onChange={onChangeMinute}
        onBlur={(e) => {
          loadingZeros(e);
          initialDisplayDate(true); // used to check end time greater than start time
        }}
        onClick={loadingZeros}
        placeholder="00"
        value={
          typeDateFilter === DATE_SELECTOR.type.between
            ? getTime(timeEnd?.substr(timeEnd.indexOf(":") + 1))
            : getTime(timeStart?.substr(timeStart.indexOf(":") + 1))
        }
      />
      {typeDateFilter !== DATE_SELECTOR.type.between ? (
        <>
          <Nav
            variant="pills"
            defaultActiveKey={
              displayDateSelect?.timeFrameStart === DATE_SELECTOR.timeFrame.am
                ? eventKeyAM
                : eventKeyPM
            }
            className="btn-group"
            activeKey={
              displayDateSelect?.timeFrameStart === DATE_SELECTOR.timeFrame.am
                ? eventKeyAM
                : eventKeyPM
            }
          >
            <Nav.Item>
              <Nav.Link onClick={selectAmOrPm} eventKey={eventKeyAM}>
                AM
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={selectAmOrPm} eventKey={eventKeyPM}>
                PM
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </>
      ) : (
        <>
          <Nav
            variant="pills"
            defaultActiveKey={
              displayDateSelect?.timeFrameEnd === DATE_SELECTOR.timeFrame.am
                ? eventKeyAM
                : eventKeyPM
            }
            className="btn-group"
            activeKey={
              displayDateSelect?.timeFrameEnd === DATE_SELECTOR.timeFrame.am
                ? eventKeyAM
                : eventKeyPM
            }
          >
            <Nav.Item>
              <Nav.Link onClick={selectAmOrPm} eventKey={eventKeyAM}>
                AM
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link onClick={selectAmOrPm} eventKey={eventKeyPM}>
                PM
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </>
      )}
    </div>
  );
};

TimePicker.propTypes = {
  hourRef: PropTypes.any,
  minuteRef: PropTypes.any,
  selectAmOrPm: PropTypes.any,
  typeDateFilter: PropTypes.number,
  displayDateSelect: PropTypes.object,
  onChangeHour: PropTypes.func,
  onChangeMinute: PropTypes.func,
  initialDisplayDate: PropTypes.func,
};
