const SAVED_SEARCH_QUERIES = {
  queries: [
    { label: "Sources", key: "sources" },
    { label: "Entities", key: "entities" },
    { label: "Data Types", key: "dataTypes" },
    { label: "Date", key: "date" },
    { label: "Search Type", key: "queryJSON" },
  ],
};

const PARAMETERS_SAVED_SEARCH = {
  sources: "sources",
  entities: "entities",
  dataTypes: "dataTypes",
  date: "date",
  queryJSON: "queryJSON",
};

const SEARCH_STATUS = {
  active: "Actived",
  deleted: "Deleted",
};

export { SAVED_SEARCH_QUERIES, PARAMETERS_SAVED_SEARCH, SEARCH_STATUS };
