import React from "react";
import PropTypes from "prop-types";

// Helpers
import { getSourceIconByName } from "helpers/CommonHelper";

// Styles
import styles from "./SourceIcon.module.scss";

const SourceIcon = ({ sourceType = "", defaultIcon = "" }) => {
  return (
    <span className={styles["source-icon"]} title={sourceType}>
      <img
        src={getSourceIconByName(sourceType, defaultIcon)}
        alt={sourceType}
      />
    </span>
  );
};

SourceIcon.propTypes = {
  sourceType: PropTypes.string,
  defaultIcon: PropTypes.string,
};

export default SourceIcon;
