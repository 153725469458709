import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import FilterItem from "../select-data/FilterItem";
import { Spinner } from "react-bootstrap";

// Constants
import { DATA_SOURCE_TYPES } from "constants/Constants";

// Helpers
import { getTypeIconSrc } from "helpers/CommonHelper";

// Styles
import styles from "./FilterList.module.scss";

const FilterList = (props) => {
  // Get data from props
  const {
    data = [],
    isSelected = true,
    onSelect = () => { },
    onSelectAll = () => { },
    inputSearch = "",
    isEmoji = false,
    disable = false,
    disableSelectAll = false,
    isSuggestion = true,
    searchLoading = false,
    isFormat = false,
    notSelectedOption = {},
    isNotSelected = false,
  } = props;

  // Define label select
  const labelSelectAll = isSelected
    ? { label: "Deselect All" }
    : { label: "Select All" };

  const ITEMS_PER_RENDER = 50;
  const [totalItemsLoad, setTotalItems] = useState(ITEMS_PER_RENDER);
  const [filteredList, setFilteredList] = useState([]);
  const [totalSearch, setTotalSearch] = useState([]);
  const [currentPosition, setCurrentPosition] = useState(0);

  // Adding data when scroll list
  const loadMoreItem = (event) => {
    const { offsetHeight, scrollTop, scrollHeight } = event.currentTarget;
    // Detect scroll end
    if (
      offsetHeight + scrollTop >= scrollHeight &&
      data.length >= totalItemsLoad - ITEMS_PER_RENDER
    ) {
      // Get current total items
      const totalItems = totalItemsLoad + ITEMS_PER_RENDER;
      // Adding data in list
      setFilteredList([
        ...filteredList,
        ...totalSearch.slice(totalItemsLoad, totalItems),
      ]);
      setTotalItems(totalItems);
    }
  };

  const isDisplaySelectControl = () =>
    filteredList.length > 0 && !isEmoji && !inputSearch && !disableSelectAll;

  useEffect(() => {
    const searchFiltered = isSuggestion
      ? data?.filter(
        (item) =>
          item.label
            ?.trim()
            .toLowerCase()
            .indexOf(inputSearch?.trim()?.toLowerCase()) !== -1
      )
      : data;
    setFilteredList(
      searchFiltered.slice(
        0,
        currentPosition > ITEMS_PER_RENDER ? currentPosition : ITEMS_PER_RENDER
      )
    );
    setTotalSearch(searchFiltered);
    setTotalItems(
      currentPosition > ITEMS_PER_RENDER ? currentPosition : ITEMS_PER_RENDER
    );
  }, [inputSearch, JSON.stringify(data)]);

  return (
    <div className={styles["wrap"]}>
      <p className={styles["select-label"]}>
        {isSelected ? "Selected " : "Not Selected "}
        {`(${totalSearch.length})`}
      </p>

      {searchLoading ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <div onScroll={loadMoreItem} className={styles["select-list"]}>
          {(isDisplaySelectControl() ||
            (!isSuggestion &&
              filteredList.length > 0 &&
              !disableSelectAll)) && (
              <>
                <FilterItem
                  data={labelSelectAll}
                  iconSrc="/images/icons/layer-icon.svg"
                  isSelected={isSelected}
                  onSelect={onSelectAll}
                  disable={disable}
                />

              </>
            )}
            {notSelectedOption.label && (
              <FilterItem
                data={notSelectedOption}
                iconClass="e-icons e-circle-remove"
                isSelected={isNotSelected}
                onSelect={() => {
                  notSelectedOption.onSelectCallback();
                }}
                disable={disable}
              />
            )}
          <div className={styles[isEmoji ? "emojis" : ""]}>
            {filteredList.map((item, index) => (
              <FilterItem
                key={index}
                data={item}
                iconSrc={getTypeIconSrc(
                  DATA_SOURCE_TYPES,
                  item.sourceType
                )}
                isSelected={isSelected}
                onSelect={(data) => {
                  onSelect(data);
                  setCurrentPosition(index);
                }}
                inputSearch={isSuggestion ? inputSearch : ""}
                isEmoji={isEmoji}
                disable={disable}
                isFormat={isFormat}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

FilterList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sourceType: PropTypes.string,
      hotKey: PropTypes.string,
    })
  ),
  disableSelectAll: PropTypes.bool,
  isEmoji: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  inputSearch: PropTypes.string,
  isSelected: PropTypes.bool,
  disable: PropTypes.bool,
  searchLoading: PropTypes.bool,
  isFormat: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  handleScroll: PropTypes.func,
  notSelectedOption: PropTypes.shape({
    label: PropTypes.string,
    onSelectCallback: PropTypes.func,
  }),
  isNotSelected: PropTypes.bool,
};

export default FilterList;
