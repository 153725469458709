import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  BigPlayButton,
  ControlBar,
  ForwardControl,
  PlaybackRateMenuButton,
  Player,
  ReplayControl,
  VolumeMenuButton,
} from "video-react";

// Constants & Helpers
import { DATA_TYPE } from "constants/DataType";
import { getUserAgent } from "helpers/UserAgentHelper";
import { AUTH_CONSTANT } from "constants/AuthConstant";

// Components
import MediaConvert from "components/shared/media-convert/MediaConvert";
import { Button } from "components/shared/button/Button";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";

// Services
import { getMediaThumbnail, streamNativeMedia } from 'services';

// Store
import { setDuration } from "store/MediaControlReducer";

// Helpers
import { formatDuration } from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./PreviewVideo.module.scss";
import "video-react/dist/video-react.css";

const PreviewVideo = (props) => {
  const dispatch = useDispatch();

  const { matterId, attachmentId, allowEventPropagation = true } = props;

  const { duration: reduxDuration } = useSelector((state) => state.mediaControl);

  const STATUS_TYPE = {
    playing: "Playing",
    converting: "Converting",
  };

  const videoPlayerRef = useRef(null);

  const [status, setStatus] = useState(STATUS_TYPE.playing);
  const [errorMessage, setErrorMessage] = useState("");

  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [localDuration, setLocalDuration] = useState("");

  const [isThumbnailLoading, setIsThumbnailLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);

  const isFirefoxBrowser = getUserAgent().isFirefox;

  const preventPropagation = (event) => {
    event.stopPropagation();
  };

  const renderVideo = () => {
    if (status === STATUS_TYPE.playing) {
      return renderVideoFollowUrl();
    } else if (status === STATUS_TYPE.converting) {
      fetchVideo().then();
      return (
        <MediaConvert
          url={videoUrl}
          type={DATA_TYPE.video}
          isConvert={status === STATUS_TYPE.converting}
        />
      );
    } else return renderError();
  };

  const fetchVideo = async () => {
    setIsVideoLoading(true);
    try {
      const response = await streamNativeMedia(matterId, attachmentId);
      const videoSrc = URL.createObjectURL(new Blob([response.data]));
      setVideoUrl(videoSrc);
    } catch (error) {
      setStatus("");
      if (error.response?.status === 400 || error?.response?.status === 404)
        setErrorMessage("Failed to load video.");
      else if (error.code === 'ECONNABORTED')
        setErrorMessage("The request timed out. The video file may be too large, please try downloading the file.");
    } finally {
      setIsVideoLoading(false);
    }
  };

  const renderError = () => {
    if (errorMessage) {
      return <div className={styles["error-container"]}>
        <img
          src="/images/image-notfound.png"
          alt="not found"
        />
        <p>{errorMessage}</p>
      </div>
    }

    return isFirefoxBrowser ? (
      <div className={styles["error-container"]}>
        <p>
          Cannot play back the file. The format is not supported. Please use
          Chrome or Microsoft Edge to view
        </p>
      </div>
    ) : (
      <div className={styles["error-container"]}>
        <p>
          Cannot play back the file. The format is not supported. Please convert
          here to view
        </p>
        <Button
          name="Convert"
          className="btn-medium-blue"
          handleClick={() => setStatus(STATUS_TYPE.converting)}
        />
      </div>
    )
  };

  const renderVideoFollowUrl = () => (
    <>
      {!isThumbnailLoading ? (
        <>
          <Player
            ref={videoPlayerRef}
            src={videoUrl}
            poster={thumbnailUrl}
            fluid={false}
            width="100%"
            height="100%"
            autoPlay={true}
          >
            <BigPlayButton className={styles["hidden-play-button"]} />
            <ControlBar autoHide={false}>
              <ReplayControl seconds={10} order={2} />
              <ForwardControl seconds={10} order={3} />
              <VolumeMenuButton vertical order={4} />
              <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={5} />
            </ControlBar>
          </Player>

          {isVideoLoading ? (
            <SpinnerLoading />
          ) : (!videoUrl || videoUrl.trim() === "") && (
            <button
              onClick={() => {
                if (!videoUrl) {
                  fetchVideoDirect();
                }
              }}
              className={styles["load-video-button"]}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 5v14l11-7L8 5z" fill="currentColor" />
              </svg>
            </button>
          )}
        </>
      ) : (
        <SpinnerLoading />
      )}
    </>
  );

  const fetchVideoDirect = () => {
    setIsVideoLoading(true);
    // hook into error events from React-Video's video element
    videoPlayerRef?.current?.video?.video?.addEventListener("error", (error) => {
      setStatus("");
      setErrorMessage("Failed to play the video");
    });

    const url = getSrcUrl();
    setVideoUrl(url);

    videoPlayerRef?.current?.subscribeToStateChange((state, prevState) => {
      if (!localDuration) {
        setLocalDuration(state.duration);
      }
    });

    setIsVideoLoading(false);
  };

  const getSrcUrl = () => {
    const apiEndpoint = `${process.env.REACT_APP_API_URL}/media/${matterId}/streamMedia/${attachmentId}`;
    const token = JSON.parse(localStorage.getItem(AUTH_CONSTANT.token) || null);
    const apiQuery = `?access_token=${token}`;
    return `${apiEndpoint}${apiQuery}`;
  };

  const fetchThumbnail = async () => {
    if (!thumbnailUrl && attachmentId) {
      setIsThumbnailLoading(true);
      try {
        const response = await getMediaThumbnail(matterId, attachmentId);
        const imageUrl = URL.createObjectURL(response.data);

        setThumbnailUrl(imageUrl);
      } catch (error) {
        setErrorMessage("Failed to load thumbnail.");
      } finally {
        setIsThumbnailLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchThumbnail();
  }, [attachmentId]);

  useEffect(() => {
    if (reduxDuration !== localDuration) {
      dispatch(setDuration(formatDuration(localDuration)));
    }
  }, [localDuration]);

  return <div
    className={styles["video-container"]}
    onClick={!allowEventPropagation ? preventPropagation : undefined}
  >
    {renderVideo()}
  </div>;
};

PreviewVideo.propTypes = {
  matterId: PropTypes.string || PropTypes.number,
  attachmentId: PropTypes.string,
  allowEventPropagation: PropTypes.bool
};

export default PreviewVideo;
