import React from "react";
import PropTypes from "prop-types";

// Constants and Helpers
import { DATE_TIME_TYPE } from "constants/Constants";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";

// Components
import SourceIcon from "../source-icon/SourceIcon";

// Styles
import styles from "./MessageHeader.module.scss";

const MessageHeader = ({ name = "", dateTime = "", sourceType = "", subject = "", addZulu = true }) => {
  return (
    <div className={styles["wrap"]}>
      <div className={styles["name-wrap"]}>
        <span className={styles["name-person"]} title={name}>
          {name}
        </span>
        <span className={styles["name-dot"]} />
        <p className={styles["chat-time"]}>
          {formatDateTime({
            dateTime,
            type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
            addZulu: addZulu
          })}
        </p>
        {sourceType && (
          <React.Fragment>
            <span className={styles["name-dot"]} />
            <SourceIcon sourceType={sourceType} defaultIcon="other" />
          </React.Fragment>
        )}
      </div>
      {subject && (
        <>
          <span className={styles["subject-wrap"]}>
            Subject: {subject}
          </span>
          <div className={styles["divider-line"]} />
        </>
      )}
    </div>
  );
};

MessageHeader.propTypes = {
  name: PropTypes.string,
  dateTime: PropTypes.string,
  sourceType: PropTypes.string,
  subject: PropTypes.string,
  addZulu: PropTypes.bool
};

export default MessageHeader;
