import React from "react";
import clsx from "clsx";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";

// Components
import { Button } from "components/shared/button/Button";
import FieldInput from "components/shared/field/field-input/FieldInput";
import FieldSelect from "components/shared/field/field-select/FieldSelect";
import FieldDateSelector from "components/shared/field/field-date-selector/FieldDateSelector";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";

// Constants
import { SOURCE_VALIDATION, VALIDATION_TEXT } from "constants/Validations";
import { KEY_CODE } from "constants/Common";
import { IMPORT_PROCESS, JOB_CATEGORY } from "constants/DataSourceConstant";

// Styles
import styles from "./CreateSourceModal.module.scss";

const CreateSourceModal = (props) => {
  const {
    show = false,
    onClose,
    onSubmit,
    projectList,
    sourceTypeList,
    isDisabledBtn,
    isEdit = false,
    dataDefault,
    jobCategory,
    importProcess,
    loading = false,
  } = props;

  const { projectId } = useParams();
  const isCanEditPhoneOwner = 
    !isEdit ||
    (isEdit &&
      JOB_CATEGORY.draft.label === jobCategory &&
      [IMPORT_PROCESS.ready.value, IMPORT_PROCESS.failed.value].includes(
        importProcess
      )
    );

  const sourceSchema = Yup.object().shape({
    projectId: projectId
      ? Yup.string().notRequired()
      : Yup.string().required(VALIDATION_TEXT.required),
    custodianName: Yup.string()
      .trim()
      .required(VALIDATION_TEXT.required)
      .max(128, SOURCE_VALIDATION.custodianName.maxLength),
    dataSourceTypeId: Yup.string().required(VALIDATION_TEXT.required),
    deviceName: Yup.string()
      .trim()
      .required(VALIDATION_TEXT.required)
      .max(128, SOURCE_VALIDATION.deviceName.maxLength),
    analystName: Yup.string()
      .trim()
      .required(VALIDATION_TEXT.required)
      .max(128, SOURCE_VALIDATION.analystName.maxLength),
    identifier: Yup.string()
      .trim()
      .max(128, SOURCE_VALIDATION.identifier.maxLength),
  });

  const onKeyDown = (event) => {
    if (event.keyCode === KEY_CODE.enter) event.preventDefault();
  };

  return (
    <Formik
      initialValues={
        isEdit
          ? dataDefault
          : {
              projectId: "",
              custodianName: "",
              analystName: "",
              dataSourceTypeId: "",
              deviceName: "",
              identifier: "",
              collectionDate: null
            }
      }
      validationSchema={sourceSchema}
      onSubmit={(values) => {
        const params = {
          ...values,
          collectionDate: values.collectionDate
            ? new Date(values.collectionDate).toISOString()
            : ""
        };
        onSubmit(params);
      }}
    >
      {(form) => (
        <Form className={styles["form-container"]} onKeyDown={onKeyDown}>
          <Modal
            show={show}
            onHide={onClose}
            centered
            backdrop="static"
            className={styles["modal-container"]}
            dialogClassName={styles["modal-dialog"]}
            contentClassName={styles["modal-content"]}
          >
            <Modal.Header closeButton className={styles["modal-header"]}>
              <Modal.Title>
                {isEdit ? "Update Data Source" : "Create New Data Source"}
                {loading && <SpinnerLoading />}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={clsx(styles["modal-body"])}>
              {!projectId && (
                <FieldSelect
                  label="Project Name"
                  name="projectId"
                  placeHolder="Project Name"
                  placeHolderSearch="Search Project Name"
                  options={projectList}
                  required
                />
              )}
              <FieldInput
                label="Custodian Name"
                name="custodianName"
                placeHolder="Custodian Name"
                required
              />
              <FieldSelect
                label="Source Type"
                name="dataSourceTypeId"
                placeHolder="Source Type"
                placeHolderSearch="Search Source Type"
                options={sourceTypeList}
                hideSearchIcon={true}
                required
              />
              <FieldInput
                label="Device Name"
                name="deviceName"
                placeHolder="Device Name"
                required
              />
              <FieldInput
                label="Analyst Name"
                name="analystName"
                placeHolder="Analyst Name"
                required
              />
              <FieldInput
                label="Phone Owner Identifier"
                name="identifier"
                placeHolder="Phone Owner Identifier"
                disabled={!isCanEditPhoneOwner}
              />
              <FieldDateSelector
                label="Collection Date"
                name="collectionDate"
                placeHolder="MM/DD/YYYY HH:MM"
              />
            </Modal.Body>
            <Modal.Footer className={styles["modal-footer"]}>
              <div className={styles["group-btn"]}>
                <Button name="Cancel" handleClick={onClose} />
                <Button
                  className="btn-primary-fill"
                  name={isEdit ? "Save" : "Create"}
                  type="submit"
                  handleClick={form.handleSubmit}
                  isDisabled={isDisabledBtn}
                />
              </div>
            </Modal.Footer>
          </Modal>
        </Form>
      )}
    </Formik>
  );
};

CreateSourceModal.propTypes = {
  show: PropTypes.bool,
  isEdit: PropTypes.bool,
  isDisabledBtn: PropTypes.bool,
  loading: PropTypes.bool,
  jobCategory: PropTypes.string,
  importProcess: PropTypes.number,
  projectList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  sourceTypeList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  dataDefault: PropTypes.shape({
    custodianName: PropTypes.string,
    dataSourceTypeId: PropTypes.number,
    deviceName: PropTypes.string,
    identifier: PropTypes.string,
    collectionDate: PropTypes.any
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateSourceModal;
