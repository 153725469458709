import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";

// Components
import { PaginationResult } from "components/shared/paging/PaginationResult";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import StatusView from "components/shared/status-view/StatusView";
import ActionMenu from "components/shared/action-menu/ActionMenu";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import DropdownFilter from "components/shared/dropdown-filter/DropdownFilter";
import SimpleProgressBar from "components/shared/simple-progress-bar/SimpleProgressBar";

// Constants
import { DATE_TIME_TYPE, SORT_BY } from "constants/Constants";
import { COMMON_TEXT, PAGINATION } from "constants/Common";
import {
  COLUMN_NAME_SOURCE,
  IMPORT_PROCESS,
  DATA_IMPORT_STAGES,
  NATIVE_IMPORT_STAGES,
  JOB_CATEGORY
} from "constants/DataSourceConstant";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { formatBytes } from "helpers/FormatterHelper";

// Styles
import styles from "./DataSourceTable.module.scss";

const DataSourceTable = ({
  handleSwitchRecord = () => { },
  handleSortTable = () => { },
  handlePagingTable = () => { },
  handleDeleteTable = () => { },
  handleImportData = () => { },
  handleSubmitSearch = () => { },
  handleImportDataRetry = () => { },
  getStatusSource,
  inputRef,
  searchInputRef,
  dataSourceFilter,
  handleSaveFilter,
  handleCancelDataImport = () => { },
  handleCancelNativeImport = () => { },
  handleCancelUpload = () => { },
  checkRoleCancel = () => { },
  handleImportNativeFiles,
}) => {
  const initSort = Object.values(COLUMN_NAME_SOURCE).map((item) => ({
    label: item.label,
    value: item.value,
    isAcs: item.value === COLUMN_NAME_SOURCE.createdDate.value ? false : true,
  }));

  const [sortColumns, setSortColumns] = useState(initSort);

  const {
    loading,
    paramSearchDataSource: {
      search,
      paginationParams,
      importProcesses,
      jobCategories,
    },
    sourceData: { sourceList, totalRecords, suggestions },
    sourceDetail,
  } = useSelector((state) => state.dataSource.dataSourceManagement);

  const classSort = (columnName) => {
    const obj = sortColumns.find((item) => item.value === columnName);
    return `sort ${obj?.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  const onSortTable = (columnName) => {
    const obj = sortColumns.find((item) => item.value === columnName);
    const newSortColumns = sortColumns.map((item) => ({
      ...item,
      isAcs: item.value === columnName ? !obj.isAcs : true,
    }));
    setSortColumns(newSortColumns);
    const sortParam = {
      columnSort: obj.value,
      orderBy: !obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
    };
    handleSortTable(sortParam);
  };

  const getDefaultValue = (value) => value || COMMON_TEXT.default;

  const getProgress = (item) => {
    if (!isImportRunning(item.importProcess) && !isImportRunning(item.nativeStatus)) {
      return COMMON_TEXT.default;
    }

    if (item.uploadProgress) {
      return getUploadProgressBar(item);
    } else if (item.importStage && item.nativeStatus !== IMPORT_PROCESS.uploading.value) {
      return getImportStageProgressBar(item);
    } else {
      return COMMON_TEXT.default;
    }
  };

  const isImportRunning = (status) =>
    status === IMPORT_PROCESS.inprogress.value || status === IMPORT_PROCESS.canceling.value || status === IMPORT_PROCESS.uploading.value;

  const getUploadProgressBar = (item) => {
    return (<SimpleProgressBar
      percentage={item.uploadProgress ?? 0}
      label={`${formatBytes(item.uploadedSize)} / ${formatBytes(item.totalSize)}`}
    />);
  };

  const getImportStageProgressBar = (item) => {
    const jobStages = DATA_IMPORT_STAGES.some((stage) => stage.key === item.importStage)
      ? DATA_IMPORT_STAGES
      : NATIVE_IMPORT_STAGES

    const currentStageIndex = jobStages.findIndex((stage) => stage.key === item.importStage) + 1;
    const totalStages = jobStages.length;
    const percentage = ((currentStageIndex / totalStages) * 100).toFixed(0);

    return (
      <SimpleProgressBar
        percentage={percentage ?? 0}
        label={`Step ${currentStageIndex} / ${totalStages} (${percentage}%)`}
      />);
  };

  const getAction = (params) => {
    const { importProcess, nativeStatus, importedBy } = params;
    if (shouldNotShowActions(importProcess, nativeStatus, importedBy)) {
      return "";
    }

    const actionMenu = getActionMenu(params);
    
    return <ActionMenu options={actionMenu.filter(item => item)} />;
  };

  const shouldNotShowActions = (importProcess, nativeStatus, importedBy) => {
    return (
      IMPORT_PROCESS.canceling.value === importProcess ||
      IMPORT_PROCESS.canceling.value === nativeStatus ||
      IMPORT_PROCESS.pending.value === nativeStatus ||
      IMPORT_PROCESS.pending.value === importProcess ||
      checkRoleCancel(importedBy, importProcess)
    );
  };

  const getActionMenu = (params) => {
    const { importProcess, nativeStatus, dataSourceId, hasLoadFile, jobCategory } = params;

    if (importProcess === IMPORT_PROCESS.inprogress.value) {
      return [
        { name: "Cancel Data Import", handleAction: handleCancelDataImport },
      ];
    } else if (nativeStatus === IMPORT_PROCESS.inprogress.value) {
      return [
        { name: "Cancel Native Import", handleAction: handleCancelNativeImport },
      ];
    } else if (importProcess !== IMPORT_PROCESS.completed.value) {
      return [
        { name: "Import Load File", handleAction: handleImportData },
        hasLoadFile && { name: "Retry Import", handleAction: handleImportDataRetry },
        jobCategory === JOB_CATEGORY.draft.label && { name: "Delete", handleAction: handleDeleteTable },
      ];
    } else if (importProcess === IMPORT_PROCESS.completed.value &&
      (nativeStatus === IMPORT_PROCESS.ready.value || 
       nativeStatus === IMPORT_PROCESS.completed.value || 
       nativeStatus === IMPORT_PROCESS.failed.value)) {
      return [{ name: "Import Native Files", handleAction: () => handleImportNativeFiles(dataSourceId) }];
    } else if (nativeStatus === IMPORT_PROCESS.uploading.value) {
      return [{ name: "Cancel Upload", handleAction: handleCancelUpload }];
    }

    return [];
  };

  return (
    <div className={styles["wrap"]}>
      <div className={styles["source-control"]}>
        <DropdownFilter
          filterTitle="Data Source Filter"
          subTitle="Choose a filter"
          filterOption={dataSourceFilter}
          handleSaveFilter={handleSaveFilter}
        />
        <SearchInputGeneral
          placeholder="Search Data Sources"
          inputRef={inputRef}
          isAutoComplete={true}
          suggestions={suggestions}
          onSubmitSearch={handleSubmitSearch}
          ref={searchInputRef}
          searchInput={search}
        />
      </div>
      {loading ? (
        <SpinnerLoading />
      ) : totalRecords > 0 ? (
        <Fragment>
          <p className={styles["total-source"]}>{`${totalRecords} Source${totalRecords > 1 ? "s" : ""
            }`}</p>
          <table>
            <thead>
              <tr>
                {initSort.map((item) => (
                  <th key={item.value}>
                    <span
                      className={clsx(
                        classSort(item.value),
                        [
                          COLUMN_NAME_SOURCE.action.value,
                          COLUMN_NAME_SOURCE.progress.value
                        ].includes(item.value)
                          ? styles["no-sort"]
                          : ""
                      )}
                      onClick={() => onSortTable(item.value)}
                    >
                      {item.label}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sourceList.map((item) => (
                <tr
                  key={item.dataSourceId}
                  onClick={() => handleSwitchRecord(item.dataSourceId)}
                  className={
                    item.dataSourceId === sourceDetail.dataSourceId
                      ? styles["active"]
                      : ""
                  }
                >
                  <td title={item.dataSourceDescription}>
                    {getDefaultValue(item.dataSourceDescription)}
                  </td>
                  <td title={item.custodianName}>
                    {getDefaultValue(item.custodianName)}
                  </td>
                  <td>
                    {getDefaultValue(
                      formatDateTime({
                        dateTime: item.createdDate,
                        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
                      })
                    )}
                  </td>
                  <td>
                    {getDefaultValue(
                      formatDateTime({
                        dateTime: item.importedDate,
                        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
                      })
                    )}
                  </td>
                  <td>
                    {item.importProcess ? (
                      <StatusView
                        status={getStatusSource(item.importProcess)}
                        loading={[
                          IMPORT_PROCESS.inprogress.value,
                          IMPORT_PROCESS.canceling.value,
                        ].includes(item.importProcess)}
                      />
                    ) : (
                      COMMON_TEXT.default
                    )}
                  </td>
                  <td>
                    {item.nativeStatus ? (
                      <StatusView
                        status={getStatusSource(item.nativeStatus)}
                        loading={[
                          IMPORT_PROCESS.uploading.value,
                          IMPORT_PROCESS.inprogress.value,
                          IMPORT_PROCESS.canceling.value,
                        ].includes(item.nativeStatus)}
                      />
                    ) : (
                      COMMON_TEXT.default
                    )}
                  </td>
                  <td>
                    {getProgress(item)}
                  </td>
                  <td>
                    {getDefaultValue(formatBytes(item.totalImportSize))}
                  </td>
                  <td>
                    {getAction({
                      importProcess: item.importProcess,
                      nativeStatus: item.nativeStatus,
                      dataSourceId: item.dataSourceId,
                      jobCategory: item.jobCategory,
                      importedBy: item.importedBy,
                      hasLoadFile: item.hasLoadFile,
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {totalRecords > 0 && (
            <PaginationResult
              forcePage={
                paginationParams.pageNumber
                  ? paginationParams.pageNumber - 1
                  : 0
              }
              perPage={PAGINATION.screen.medium}
              totalRecord={totalRecords}
              pageItems={sourceList.length}
              handlePagingClick={handlePagingTable}
              isSmall
              nameRecord={totalRecords > 1 ? "data sources" : "data source"}
              isUpdateCurrentPage={loading}
            />
          )}
        </Fragment>
      ) : (
        <div className={styles["no-result"]}>
          <EmptyPage
            messages={
              search || importProcesses?.length > 0 || jobCategories?.length > 0
                ? "No results found. Please try again."
                : "No data sources found"
            }
          />
        </div>
      )}
    </div>
  );
};

DataSourceTable.propTypes = {
  inputRef: PropTypes.any,
  searchInputRef: PropTypes.any,
  dataSourceFilter: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
        })
      ),
      checkedList: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  handleSwitchRecord: PropTypes.func,
  handleSortTable: PropTypes.func,
  handlePagingTable: PropTypes.func,
  handleDeleteTable: PropTypes.func,
  handleImportData: PropTypes.func,
  handleSubmitSearch: PropTypes.func,
  getStatusSource: PropTypes.func,
  handleSaveFilter: PropTypes.func,
  handleCancelDataImport: PropTypes.func,
  handleCancelNativeImport: PropTypes.func,
  checkRoleCancel: PropTypes.func,
  handleImportNativeFiles: PropTypes.func,
  handleCancelUpload: PropTypes.func,
  handleImportDataRetry: PropTypes.func,
};

export default DataSourceTable;
