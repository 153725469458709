import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner } from "react-bootstrap";
import { useToken } from "hook/auth";
import { useDispatch } from "react-redux";
import _get from "lodash/get";
import { toast } from "react-toastify";

// Constant
import { PATH_NAME } from "constants/Common";

// Store
import { updateAllData } from "store/UserReducer";

// Services
import { loginAuth0 } from "services";

// Styles
import styles from "./AuthLanding.module.scss";

const AuthLanding = () => {
  const { isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, logout } = useAuth0();
  const { setUser, setToken } = useToken();
  const [isGettingToken, setIsGettingToken] = useState(true);

  const getIDTokenAuth0 = async () => {
    setIsGettingToken(true);
    try {
      const claims = await getIdTokenClaims();
      if (claims.__raw) getUserInformation(claims.__raw);
      else navigate("/login");
    } catch (e) {
      console.log(e);
      setIsGettingToken(false);
    }
  };

  const onLoginFailed = (error = "") => {
    toast.error(error, { style: { marginTop: -80 } });
    setTimeout(() => {
      setIsGettingToken(false);
      logout({ returnTo: `${window.location.origin}/login` });
    }, 1500);
  };

  const getUserInformation = async (IDToken) => {
    try {
      const info = await loginAuth0(IDToken);
      setToken(_get(info.data, "token", ""));
      setUser(_get(info.data, "user", {}));
      dispatch(updateAllData(info.data.user));
      setIsGettingToken(false);
      navigate(`/${PATH_NAME.matters}`, { replace: true });
    } catch (e) {
      console.log(e);
      onLoginFailed("User not found!");
    }
  };

  useEffect(() => {
    if (isAuthenticated) getIDTokenAuth0();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!error) return;
    onLoginFailed(error.message);
  }, [error]);

  return (
    <div className={styles["loading"]}>
      {isLoading ||
        (isGettingToken && <Spinner animation="border" variant="primary" />)}
    </div>
  );
};

export default AuthLanding;
