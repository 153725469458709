import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

// Components
import { DateSelector } from "./../shared/date-picker/DateSelector";
import { DropdownSearchOption } from "./../shared/dropdown-selector/DropdownSearchOption";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";

// Constants
import {
  TYPE_CLICK,
  OPTION_SORT,
  OPTION_SELECT_MESSAGE,
} from "constants/Common";

// Styles
import "./FilterControl.scss";

const FilterControl = (props) => {
  const {
    searchInput,
    onSearchByDate,
    onSearchKeyword,
    index,
    setIndex,
    optionSelected,
    setOptionSelected,
    optionSortSelected,
    setOptionSortSelected,
    totalSearchCount,
    totalMessageContainKeyWord = 0,
    isLoadingMore,
    isLoading,
    onClearSearch,
    setTypeClick,
    isShowSearch,
    setIsShowSearch,
    setIsShowPreviewModal,
    isEmailReview = false,
    isTable = false,
    optionSelectedMessage = OPTION_SELECT_MESSAGE.all,
  } = props;
  const isShowPreviewModal = useSelector(
    (state) => state.is.isShowPreviewModal
  );
  const datePickerData = useSelector(
    (state) => state.datePicker
  );

  const inputRef = useRef();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isModalPreview, setIsModalPreview] = useState(false);

  const options = Object.values(OPTION_SELECT_MESSAGE).map((item) => ({
    name: item,
    value: item,
  }));
  const optionSortMessages = Object.values(OPTION_SORT).map((item) => ({
    name: item,
    value: item,
  }));

  const onSearchHandle = () => {
    if (isLoading) return;
    const keyword = inputRef.current.value.trim();
    onSearchKeyword(keyword);
  };

  const handleOpenDatePicker = () => {
    if (isLoading) return;
    setShowDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleClearSearch = () => {
    if (isLoading) return;
    onClearSearch();
    onSearchKeyword("");
  };

  const handleSetIsSearch = () => {
    // If API is loading, user can't change showSearch to call API
    if (isLoading) return;

    if (isShowSearch) {
      setOptionSelected(OPTION_SELECT_MESSAGE.all);
      if (searchInput?.length > 0) handleClearSearch();
    }
    setIsShowSearch(!isShowSearch);
  };

  const handleSelectedIndex = (type) => {
    if (isLoading || totalSearchCount <= 0) return;
    if (type === TYPE_CLICK.up && index > 0) setIndex(index - 1);
    else if (
      type === TYPE_CLICK.down &&
      !isLoadingMore &&
      index < totalSearchCount - 1
    )
      setIndex(index + 1);
    setTypeClick(type);
  };

  const handleSetOptionSortSelected = (option) => {
    if (isLoading) return;
    setOptionSortSelected(option);
    setOptionSelected(OPTION_SELECT_MESSAGE.all);
  };

  useEffect(() => {
    if (isShowSearch) {
      setOptionSelected(
        optionSelectedMessage !== OPTION_SELECT_MESSAGE.all
          ? optionSelectedMessage
          : OPTION_SELECT_MESSAGE.all
      );
    }
  }, [isShowSearch]);

  useEffect(() => {
    searchInput && setIsShowSearch(true);
  }, [searchInput]);

  useEffect(() => {
    if (!isShowPreviewModal && isModalPreview) {
      setOptionSortSelected(OPTION_SORT.oldToNew);
      if (searchInput?.length > 0) handleClearSearch();
      setIsShowSearch(false);
      if (setIsShowPreviewModal) setIsShowPreviewModal(false);
    }
    setIsModalPreview(isShowPreviewModal);
  }, [isShowPreviewModal]);

  return (
    <div className="conversation-information-filter d-flex align-items-center justify-content-between">
      <div className="note-tooltip-group">
        <img className="note-tooltip" src="/images/note.svg" alt="note" />
        <span className="tooltip-description">
          Please note: Emoji and keyword searches cannot be combined. Please
          search each separately
        </span>
      </div>
      <DateSelector
        showDatePicker={showDatePicker}
        handleClose={handleCloseDatePicker}
        handleSubmit={(data) => {
          onSearchByDate(data);
          handleCloseDatePicker();
        }}
        data={{ 
          startDate: datePickerData.messageViewDatePicker.dateTimeDataStart, 
          endDate: datePickerData.messageViewDatePicker.dateTimeDataEnd 
        }}
        stateName="messageViewDatePicker"
      />
      <div className="conversation-information-filter-search d-flex align-items-center">
        <div className="conversation-information-filter-type--calendar mar_r10 cursor-pointer">
          <img
            src={
              datePickerData.messageViewDatePicker.dateTimeDataStart !== null ||
              datePickerData.messageViewDatePicker.dateTimeDataEnd !== null
                ? "/images/calendar-icon-checked.svg"
                : "/images/calendar-icon.svg"
            }
            alt="icon"
            onClick={handleOpenDatePicker}
          />
        </div>
        <a
          className="search-button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={handleSetIsSearch}
        >
          <img
            src={`/images/${isShowSearch ? "plus" : "search-icon"}.svg`}
            alt="search"
          />
        </a>
        {isShowSearch ? (
          <div className="search-input-container dropdown-menu drop-up">
            <div className="search-input-container dropdown-menu drop-up d-block">
              <div className="search-parent-element align-items-center">
                <DropdownSearchOption
                  itemList={optionSortMessages}
                  selectedItem={optionSortSelected}
                  containerClassName="dropdown-container"
                  size="lg"
                  widthStyle="auto"
                  onSelect={handleSetOptionSortSelected}
                />
                <div className="vertical-divider"></div>
                {isEmailReview && isTable && (
                  <div className="warning-tooltip">
                    <img src="/images/warning-icon.svg" alt="warning" />
                    <div className="warning-description">
                      <span>
                        Table view does not support searching of the eMail body.
                        Please switch to chat (bubble) view.
                      </span>
                    </div>
                  </div>
                )}
                <SearchInputGeneral
                  inputRef={inputRef}
                  placeholder="Search"
                  name="search"
                  onSubmitSearch={onSearchHandle}
                  handleClearSearch={handleClearSearch}
                  searchInput={searchInput}
                  hasPreventClearText={isLoading}
                />
                <p className="count-index">
                  {totalSearchCount === 0 ? 0 : index + 1}/{totalSearchCount}
                  {totalSearchCount > 0
                    ? `/${totalMessageContainKeyWord}`
                    : "/0"}
                </p>
                {isLoadingMore && (
                  <div className="filter-spinner">
                    <Spinner
                      className="spinner spinner-section"
                      animation="border"
                      variant="primary"
                    />
                  </div>
                )}
                <div className="vertical-divider"></div>
                <div className="up-down-container">
                  <a
                    className="up-search"
                    onClick={() => handleSelectedIndex(TYPE_CLICK.up)}
                  >
                    <img
                      src={
                        index === 0
                          ? "/images/up-icon-disable.svg"
                          : "/images/up-icon.svg"
                      }
                      alt={TYPE_CLICK.up}
                    />
                  </a>
                  <a
                    className="down-search"
                    onClick={() => handleSelectedIndex(TYPE_CLICK.down)}
                  >
                    <img
                      src={
                        index < totalSearchCount - 1
                          ? "/images/down-icon.svg"
                          : "/images/down-icon-disable.svg"
                      }
                      alt={TYPE_CLICK.down}
                    />
                  </a>
                </div>
                {!isEmailReview && (
                  <>
                    <div className="vertical-divider"></div>
                    <DropdownSearchOption
                      itemList={options}
                      selectedItem={optionSelected}
                      containerClassName="dropdown-search-option"
                      size="lg"
                      widthStyle="auto"
                      onSelect={setOptionSelected}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

FilterControl.propTypes = {
  optionSortSelected: PropTypes.string,
  index: PropTypes.number,
  totalSearchCount: PropTypes.number,
  isLoadingMore: PropTypes.bool,
  isLoading: PropTypes.bool,
  isShowSearch: PropTypes.bool,
  isEmailReview: PropTypes.bool,
  isTable: PropTypes.bool,
  totalMessageContainKeyWord: PropTypes.number,
  optionSelectedMessage: PropTypes.any,
  optionSelected: PropTypes.any,
  searchInput: PropTypes.any,
  datePicker: PropTypes.object,
  setIsShowSearch: PropTypes.func,
  onSearchKeyword: PropTypes.func,
  setIndex: PropTypes.func,
  setOptionSelected: PropTypes.func,
  setOptionSortSelected: PropTypes.func,
  onSearchByDate: PropTypes.func,
  onClearSearch: PropTypes.func,
  setTypeClick: PropTypes.func,
  setIsShowPreviewModal: PropTypes.func,
};

export { FilterControl };
