import React from "react";
import PropTypes from "prop-types";
import uniq from "lodash/uniq";
import { useSelector } from "react-redux";

// Styles
import styles from "./RenderTagSelected.module.scss";

export const RenderTagSelected = (props) => {
  const { tagsSelected = [], isHorizontal = false } = props;
  const { showTags } = useSelector((state) => state.tag.tags);

  const tagsListTmp = [];
  showTags.forEach((TagSelects) => {
    uniq(tagsSelected).forEach((itemTag) => {
      if (TagSelects.tagID === itemTag) tagsListTmp.push(TagSelects);
    });
  });

  return tagsListTmp.length > 0
    ? tagsListTmp.map((itemTag) => (
        <span
          key={itemTag.tagID}
          className={styles[isHorizontal ? "tag-horizontal" : "tag-item"]}
          style={{ backgroundColor: itemTag.color }}
          title={itemTag.name}
        ></span>
      ))
    : "";
};

RenderTagSelected.propTypes = {
  tagsSelected: PropTypes.array,
  isHorizontal: PropTypes.bool,
};
