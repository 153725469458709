import React from "react";
import PropTypes from "prop-types";
// import clsx from "clsx";

// Components
import CollapseSelect from "components/shared/collapse-select/CollapseSelect";

// Constants
import { EMAIL_PREVIEW } from "constants/EmailPreview";

// Styles
import styles from "./PIITranscription.module.scss";

const PIITranscription = ({ handleClosePopup }) => {
  return (
    <div className={styles["PII-Popup"]}>
      <div className={styles["PII-Popup-header"]}>
        PII Transcription
        <img
          src="/images/icons/close-square.svg"
          className={styles["close-btn"]}
          onClick={handleClosePopup}
          alt="Close square"
        />
      </div>
      <div className={styles["PII-Popup-description"]}>
        Auto-highlighted the keywords containing PII.
      </div>
      <div className={styles["PII-Popup-collapse"]}>
        {EMAIL_PREVIEW?.PII_Transcription?.map((el, index) => (
          <div key={index}>
            <CollapseSelect name={el.name} color={el.color} data={el.data} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PIITranscription;

PIITranscription.propTypes = {
  handleClosePopup: PropTypes.func,
};
