import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";

// Store
import { 
    setEditMatterDateLoading,
    updateMatterDateRange, 
    clearMatterDateRange 
} from "store/MatterDateReducer";

// Services
import * as projectAdminService from "services/ProjectAdministrationService"

// Components
import SpinnerLoading from "../spinner-loading/SpinnerLoading";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import { DateSelector } from "components/shared/date-picker/DateSelector";

// Constants
import { COMMON_TEXT } from "constants/Common";

// Hooks
import { useToken } from "hook/auth";

// Styles
import styles from "./MatterDate.module.scss";

// Static Constants
const IN_PROGRESS_INDICATOR = 2;
const CANCELLING_INDICATOR = 5;

const MatterDate = () => {
  const dispatch = useDispatch();

  const { projectId } = useParams();
  const { editMatterDateLoading, matterDateRange } = useSelector((state) => state.matterDate)
  const dataSourceManagement = useSelector((state) => state.dataSource.dataSourceManagement);
  
  const { isAdmin, isClientAdmin } = useToken();

  const [showMatterDateFilter, setShowMatterDateFilter] = useState(false);
  const [showMatterDateSelection, setShowMatterDateSelection] = useState(true);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [displayMatterDateRange, setDisplayMatterDateRange] = useState("");
  const [isFirstLoadComponent, setIsFirstLoadComponent] = useState(true);
  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const onSelectDates = (data) => {
    setSelectedDates({
      startDate: data.dateTimeDataStart === null ? null : moment(new Date(data.dateTimeDataStart).toUTCString()).format(),
      endDate: data.dateTimeDataEnd === null ? null : moment(new Date(data.dateTimeDataEnd).toUTCString()).format()
    });
  }
  const resetSelectedDates = () => {
    setSelectedDates({
      startDate: null,
      endDate: null,
    });
  };

  const onShowMatterDateFilter = () => setShowMatterDateFilter(true);
  const onHideMatterDateFilter = () => setShowMatterDateFilter(false);

  const onShowConfirmPopup = () => setShowConfirmPopup(true);
  const onHideConfirmPopup = () => setShowConfirmPopup(false)

  const fetchMatterDateRange = async () => {
    try {
      dispatch(setEditMatterDateLoading(true));

      const response = await projectAdminService.getMatterDateRange(projectId)
      const dateObject = {
        startDate: response.data.startDate === null ? null : new Date(response.data.startDate),
        endDate: response.data.endDate === null ? null : new Date(response.data.endDate)
      }

      dispatch(updateMatterDateRange(dateObject));
    } catch (error) {
      dispatch(clearMatterDateRange());
      console.log(error)
    } finally {
      dispatch(setEditMatterDateLoading(false));
    }
  };

  const onHandleSubmitMatterDate = async () => {
    onHideConfirmPopup();

    try {
      dispatch(setEditMatterDateLoading(true));

      const payload = {
        startDate: selectedDates.startDate,
        endDate: selectedDates.endDate
      } 

      await projectAdminService.setMatterDateRange(payload, projectId);
      dispatch(updateMatterDateRange(payload));
    } catch (error) {
      console.log(error)
    } finally {
      onHideMatterDateFilter();
      dispatch(setEditMatterDateLoading(false));
    }
  };

  const generateDisplayString = (startDate, endDate) => {
    switch (true) {
      case startDate !== null && endDate !== null:
        return `${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format('MM/DD/YYYY')}`;
      case startDate !== null:
        return `After ${moment(startDate).format('MM/DD/YYYY')}`;
      case endDate !== null:
        return `Before ${moment(endDate).format('MM/DD/YYYY')}`;
      default:
        return "No Date Scope Applied";
    }
  };

  const confirmPopup = {
    value: 2,
    content: "You are changing the matter date scope. This will result in changes to document groupings, which require re-indexing.",
    textConfirm: COMMON_TEXT.confirm,
    textReject: COMMON_TEXT.cancel,
    type: "confirm",
    handleSubmit: () => onHandleSubmitMatterDate()
  };

  const isAdminOrClientAdmin = isAdmin() || isClientAdmin();

  useEffect(() => {
    setIsFirstLoadComponent(false);
  }, []);

  useEffect(() => {
    const displayString = generateDisplayString(matterDateRange.startDate, matterDateRange.endDate);
    setDisplayMatterDateRange(displayString);
  }, [matterDateRange]);

  useEffect(() => {
    if (!isFirstLoadComponent) {
      fetchMatterDateRange();
    }
  }, [projectId, isFirstLoadComponent]);

  useEffect(() => {
    for (const source of dataSourceManagement.sourceData.sourceList) {
      if (source.importProcess === IN_PROGRESS_INDICATOR || source.importProcess === CANCELLING_INDICATOR) {
        setShowMatterDateSelection(false);
        return;
      }
    }
    setShowMatterDateSelection(true);
  }, [dataSourceManagement.sourceData.sourceList]);

  return (
    <>
      <div className={styles["wrap"]} id="matter-date">
        <p className={styles["title"]}>Matter Date Scope: </p>
        <div className={`${styles["box-wrap"]} ${(!showMatterDateSelection && isAdminOrClientAdmin) ? styles.disabled : ''}`}>
          {isAdminOrClientAdmin && (
            <>
              <PopupConfirm
                isShow={showConfirmPopup}
                handleClose={() => setShowConfirmPopup(false)}
                handleSubmit={confirmPopup.handleSubmit}
                content={confirmPopup.content}
                textConfirm={confirmPopup.textConfirm}
                type={confirmPopup.type}
                textReject={confirmPopup.textReject}
                handleReject={() => {
                  resetSelectedDates();
                  setShowConfirmPopup(false);
                }}
              />
              <DateSelector
                showDatePicker={showMatterDateFilter}
                handleClose={onHideMatterDateFilter}
                handleSubmit={(data) => {
                  onSelectDates(data);
                  onShowConfirmPopup();
                }}
                data={{ startDate: matterDateRange.startDate, endDate: matterDateRange.endDate }}
                stateName="matterDatePicker"
              />
            </>
          )}
          <div className={styles["matter-date-control"]}>
            {editMatterDateLoading ? (
              <div className={styles["matter-date-spinner"]}>
                <SpinnerLoading />
              </div>
            ) : (
              <>
                <p className={styles["date-range"]}>{displayMatterDateRange}</p>
                {isAdminOrClientAdmin && (
                  <div className={styles["btn-group"]}>
                    <div className="mar_r10 cursor-pointer">
                      <img
                        src={
                          matterDateRange.startDate !== null ||
                          matterDateRange.endDate !== null
                            ? "/images/calendar-icon-checked.svg"
                            : "/images/calendar-icon.svg"
                        }
                        alt="icon"
                        onClick={onShowMatterDateFilter}
                        className={!showMatterDateSelection ? styles.disabled : ''}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

MatterDate.propTypes = {};

export default MatterDate;
