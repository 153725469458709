import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// Helpers
import { formatDuration } from "helpers/DateTimeFormatterHelper";

// Store
import { setDuration } from "store/MediaControlReducer";

// Styles
import styles from "./AmrConvertAudio.module.scss";

const AmrConvertAudio = ({
  src,
  amr,
  onFailure = () => { },
  allowEventPropagation = false
}) => {
  const dispatch = useDispatch();

  const [isPlay, setIsPlay] = useState(false);
  const [amrCurrentPosition, setAmrCurrentPosition] = useState(0);
  const [amrDuration, setAmrDuration] = useState(0);
  const [myInterVal, setMyInterVal] = useState();

  const onChangePlay = async (number, canPlay) => {
    //  init first time
    try {
      console.log("number", number);
      console.log("canPlay", canPlay);

      if (!amr || !amr.isInit()) await initUrlToAMR();
      setIsPlay(!canPlay);

      let newNumber = Number(number);
      if (amrCurrentPosition >= amrDuration && canPlay) {
        newNumber = 0;
        amr.stop();
        resetInterval();
        return;
      }

      if (canPlay) {
        clearInterval(myInterVal);
        amr.pause();
        return;
      }

      if (amrCurrentPosition === 0) {
        amr.play();
      }
      else {
        amr.resume();
      }

      setNewInterVal(newNumber);
    } catch (error) {
      console.error(error);
      onFailure();
    }
  };

  // Change amr position to update new setinterval
  const handleCurrentAmrPosition = (e) => {
    setIsPlay(true);
    const currentPosition = e.target.value;
    setAmrCurrentPosition(currentPosition);
    amr.setPosition(currentPosition);
    clearInterval(myInterVal);
    setNewInterVal(Math.floor(currentPosition));
  };

  const setNewInterVal = (number) => {
    let newNumber = number;
    const newInterval = setInterval(() => {
      newNumber = newNumber + 1;
      if (newNumber >= amrDuration) {
        amr.stop();
        setAmrCurrentPosition(0);
        setIsPlay(false);
        clearInterval(newInterval);
      } else {
        setAmrCurrentPosition(newNumber);
      }
    }, 1000);
    setMyInterVal(newInterval);
  };

  const resetInterval = () => {
    setIsPlay(false);
    clearInterval(myInterVal);
  };

  const initUrlToAMR = async () => {
    await amr.initWithUrl(src);
    setAmrDuration(amr.getDuration());
    dispatch(setDuration(formatDuration(amr.getDuration())));
  };

  useEffect(async () => {
    //  init first time
    if (amr && !amr.isInit()) {
      await initUrlToAMR();
    }
    
    return () => {
      clearInterval(myInterVal);
      if (amr.isInit()) {
        amr.stop();
      }
    };
  }, []);

  return (
    <div 
      className={styles["voice-mail-container"]}
      onClick={allowEventPropagation ? undefined : (e) => e.stopPropagation()}
    >
      <div className={styles["media-icon"]}>
        <img
          src={`/images/${isPlay ? "pause" : "play"}-audio-icon.svg`}
          onClick={(e) => onChangePlay(amrCurrentPosition, isPlay)}
          className={styles["play-pause"]}
          alt={`${isPlay ? "Pause" : "Play"}`}
        />
        <div className={styles["hover"]}></div>
      </div>
      <div className={styles["voice-mail-body"]}>
        <span>
          {(amrCurrentPosition < 600
            ? formatDuration(amrCurrentPosition).substring(4)
            : formatDuration(amrCurrentPosition).substring(3)) || "0:00"}{" "}
          /{" "}
          {(amrDuration < 600
            ? formatDuration(amrDuration).substring(4)
            : formatDuration(amrDuration).substring(3)) || "0:00"}
        </span>
        <input
          type="range"
          className={styles["progress-bar"]}
          min={0}
          max={Math.floor(amrDuration)}
          value={
            Math.floor(amrCurrentPosition) ||
            Math.floor((amrCurrentPosition / amrDuration) * 100) ||
            0
          }
          onChange={handleCurrentAmrPosition}
        />
      </div>
    </div>
  );
};

AmrConvertAudio.propTypes = {
  src: PropTypes.string,
  amr: PropTypes.any,
  onFailure: PropTypes.func,
  allowEventPropagation: PropTypes.bool,
};

export default AmrConvertAudio;
