import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';

// helpers
import { getFileNameFromContentDisposition } from '../../../helpers/DownloadFileHelper';

// Services
import { downloadNativeFile } from '../../../services/AttachmentService';

// Styles
import styles from './DownloadAttachmentButton.module.scss';

const DownloadAttachmentButton = (props) => {
    const {
        matterId,
        attachmentId,
        hidden = false,
        isIcon = true,
    } = props;

    const handleClick = () => {
        const downloadFile = async () => {
            try {
                const response = await downloadNativeFile(matterId, attachmentId);
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',
                    getFileNameFromContentDisposition(response.headers['content-disposition']));
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    toast.error('This attachment cannot be found.');
                } else {
                    toast.error('An error occurred while downloading the attachment.');
                }
            }
        };
        downloadFile();
    };

    return hidden ? (<></>)
        : (
            <>
                <button
                    type='button'
                    onClick={handleClick}
                    className={clsx(isIcon ? styles["download-btn-icon"] : styles["download-btn"])}
                >
                    {isIcon ? (
                        <img
                            src='/images/icons/download.svg'
                            alt='Download'
                        />
                    ) : (
                        <span>Download File</span>
                    )}
                </button>
            </>)
};

DownloadAttachmentButton.propTypes = {
    matterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    attachmentId: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    isIcon: PropTypes.bool,
};

export default DownloadAttachmentButton;