// Constant
import { DATA_TYPE } from "./DataType";

export const SCREEN_WIDTH_3839 = 3839;
export const SCREEN_WIDTH_2559 = 2559;

export const BAR_OPTIONS_DEFAULT = {
  indexAxis: "y",
  layout: {
    padding: {
      right: 100,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      labels: {
        boxWidth: 0,
      },
    },
  },
};

export const PIE_OPTIONS_DEFAULT = {
  plugins: {
    legend: {
      position: "right",
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      titleFont: {
        size:
          window.innerWidth > SCREEN_WIDTH_3839
            ? 32
            : window.innerWidth > SCREEN_WIDTH_2559
            ? 24
            : 20,
      },
      bodyFont: {
        size:
          window.innerWidth > SCREEN_WIDTH_3839
            ? 24
            : window.innerWidth > SCREEN_WIDTH_2559
            ? 16
            : 14,
      },
      displayColors: false,
      backgroundColor: "rgba(34, 34, 34, 0.8)",
      titleMarginBottom: 16,
      bodySpacing: 16,
    },
  },
};

export const PIE_COLORS = [
  "#5D62B5",
  "#29C3BE",
  "#F2726F",
  "#FFC533",
  "#62B58F",
  "#BC95DF",
  "#67CDF2",
];

export const HEAT_MAP_COLORS = [
  "#13375B",
  "#184778",
  "#1D5EA0",
  "#2371C2",
  "#1580EF",
  "#3894F3",
  "#52A3F7",
  "#74B4F8",
  "#94C6FA",
  "#CFE5FC",
  "#EAF4FE",
  "#f9f9f9",
];

export const COM_ANALYSIS_COLOR = [
  "#29C3BE",
  "#F2726F",
  "#FF8933",
  "#1580EF",
  "#BE33FF",
  "#5D62B5",
  "#FFC533",
  "#67CDF2",
  "#BC95DF",
  "#62B58F",
];

export const SHOW_TYPE = {
  chat: 1,
  table: 2,
};

export const IMAGE_TYPE_ACCEPTED = ["image/png", "image/jpeg", "image/jpg"];

export const IMAGE_TYPE_ACCEPTED_STRING = ".jpeg,.png,.jpg";

export const TIME_OUT_RESEND_OTP = 60;

export const FILE_SIZE = {
  MB20: 20 * 1024 * 1024,
};

export const CONSOLIDATED_TYPE = {
  source: {
    title: "Data Sources",
    className: "source",
  },
  entity: {
    title: "People",
    className: "entity",
  },
  datatype: {
    title: "Data Types",
    className: "datatype",
  },
  date: {
    title: "Date",
    className: "date",
  },
  global: {
    title: "Search Term",
    className: "global",
  },
};

export const CONSOLIDATE_QUERY_DEFAULT = "No component added.";

export const TAG_COLORS = [
  "#1580EF",
  "#5D62B5",
  "#29C3BE",
  "#F2726F",
  "#FFC533",
  "#62B58F",
  "#BC95DF",
  "#67CDF2",
];

export const DATA_SOURCE_TYPES = [
  {
    id: 1,
    icon: "mobile-icon",
    name: "Mobile Device",
  },
  {
    id: 2,
    icon: "computer-icon",
    name: "Computer",
  },
  {
    id: 3,
    icon: "file-server-icon",
    name: "File Server",
  },
  {
    id: 4,
    icon: "email-cloud-icon",
    name: "eMail (Cloud/Server)",
  },
  {
    id: 5,
    icon: "cloud-icon",
    name: "Cloud-based non-email",
  },
  {
    id: 6,
    icon: "external-icon",
    name: "External Drive",
  },
  {
    id: 7,
    icon: "internal-icon",
    name: "Internal Drive",
  },
  {
    id: 8,
    icon: "autostreem-icon",
    name: "AutoStreem",
  },
  {
    id: 9,
    icon: "other-icon",
    name: "Other",
  },
  {
    id: 10,
    icon: "slack",
    name: "Slack",
  },
];

export const DATA_SOURCE_ICON = {
  computer: "computer",
  other: "other",
  fileServer: "file server",
  cloudBased: "cloud-based non-email",
  autoStreem: "autostreem",
  internalDrive: "internal drive",
  email: "email (cloud/server)",
  mobile: "mobile device",
  externalDrive: "external drive",
};

export const PAGE_WITHOUT_EXCEL = {
  eventTimeline: "event-timeline",
};

export const PAGE_HAVE_EXCEL = {
  communicationAnalyser: "communication-analyzer",
};

export const CONCEPT_VISUALIZER = {
  omitConceptSearch: {
    title: "conceptSearch",
    keyword: "keyword",
    listNameExcept: "listNameExcept",
    typeSearch: "typeSearch",
    conceptSearchKeyword: "conceptSearchKeyword",
    nodeDataCheckList: "nodeDataCheckList",
  },
  typeSearchResult: {
    keyword: 1,
    word: 2,
  },
};

export const MONTHS = [
  {
    value: 1,
    label: "Jan",
  },
  {
    value: 2,
    label: "Feb",
  },
  {
    value: 3,
    label: "Mar",
  },
  {
    value: 4,
    label: "Apr",
  },
  {
    value: 5,
    label: "May",
  },
  {
    value: 6,
    label: "Jun",
  },
  {
    value: 7,
    label: "Jul",
  },
  {
    value: 8,
    label: "Aug",
  },
  {
    value: 9,
    label: "Sep",
  },
  {
    value: 10,
    label: "Oct",
  },
  {
    value: 11,
    label: "Nov",
  },
  {
    value: 12,
    label: "Dec",
  },
];

export const DATA_SOURCE_TYPES_EMAIL = "eMail (Cloud/Server)";

export const SEARCH_BY_TIME_LIST = [
  { name: "15 minutes", value: 15 },
  { name: "30 minutes", value: 30 },
  { name: "1 hour", value: 60 },
  { name: "2 hours", value: 120 },
  { name: "4 hours", value: 240 },
  { name: "6 hours", value: 360 },
  { name: "12 hours", value: 720 },
];

export const DATE_TIME_TYPE = {
  MM_DD_YYYY_hma: "MM/DD/yyyy, h:mm a",
  MM_DD_YYYY_hmsa: "MM/DD/YYYY h:mm:ss a",
  MM_DD_YYYY_hhmmA: "MM/DD/YYYY hh:mm A",
  MM_DD_YYYY_hhmmssA: "MM/DD/YYYY hh:mm:ss A",
  YYYY_MM_DD_hhmmssA: "YYYY/MM/DD hh:mm:ss A",
  YYYYMMDD_hhmmss: "YYYY-MM-DDTHH:mm:ss",
  ISO: "YYYY-MM-DDTHH:mm:ss",
  YYYYMMDD: "YYYY-MM-DD",
  MMMDDYYYY: "MMM DD, YYYY",
  MM_DD_YYYY: "MM/DD/YYYY",
  LT: "LT",
  HHmmDDMMMYYYY: "HH:mm, DD MMM, YYYY",
  YYYYMMDDhmmss: "YYYYMMDDhmmss",
  YYYYMMDDhhmmss: "YYYYMMDDhhmmss",
  MM_DD_YYYY_LT: "MM/DD/YYYY LT",
  MMM_DD_YYYY: "MMM DD, YYYY",
  HHmmss: "HH:mm:ss",
  HHmm: "HH:mm",
  HH: "HH",
  mm: "mm",
  a: "a",
  dddd_D: "dddd D",
  D: "D",
  YYYY: "YYYY",
};

export const DATE_TIME = {
  minDate: "1900-01-01",
};

export const INDEX_QUICK_SELECT = {
  dataSources: 0,
  locationTypes: 1,
  dataTypes: 2,
  entities: 3,
  participants: 4,
  tags: 5,
};

export const SORT_BY = {
  asc: "asc",
  desc: "desc",
  sortDesc: "sort-desc",
  sortAsc: "sort-asc",
};

export const LIST_VIDEO_TYPE = {
  mov: "mov",
  threeGp: "3gp",
  mp4: "mp4",
  m4v: "m4v",
};

export const CONTENT_TYPE = {
  mp4: "video/mp4",
  mpeg: "audio/mpeg",
  mov: "video/quicktime",
};

export const LIST_IMAGE_TYPE = ["jpg", "png", "jpeg", "heic"];

export const LIST_IMAGE_CONVERT_TYPE = [
  "image/heic",
  "image/heic-sequence",
  "heic",
  "heic-sequence",
  "image/tiff",
  "tiff",
];

export const TAG_ACTION = {
  tag: "tag",
  unTag: "un-tag",
};

export const TAG_RESPONSE_MESSAGE = {
  unTagFailed: "Un-tag failed",
  tagFailed: "Tag failed",
};

export const TITLE_ATTACHMENT_MODAL = {
  attachment: "Attachment Review",
};

export const TYPE_WITH_TAG_ACTION = [
  DATA_TYPE.voicemail,
  DATA_TYPE.image,
  DATA_TYPE.video,
  DATA_TYPE.note,
  DATA_TYPE.historyLink,
  DATA_TYPE.location,
  DATA_TYPE.audio,
  DATA_TYPE.emailThread,
  DATA_TYPE.email,
];

export const EMAIL_SEND_MODE = {
  to: "To",
  cc: "Cc",
  bcc: "Bcc",
};

export const TOTAL_ITEM = {
  screen4K: 20,
  fullHD: 10,
};

export const SOCIAL_ENUM = {
  messenger: 1,
  twitter: 2,
  instagram: 3,
  linkedin: 4,
  skype: 5,
  viber: 6,
  whatsapp: 7,
  telegram: 8,
  snapchat: 9,
  discord: 10,
  reddit: 11,
  pinterest: 12,
  youtube: 13,
  tiktok: 14,
  qq: 15,
  douyin: 16,
  weibo: 17,
  kuaishou: 18,
  wechat: 19,
  phone: 20,
  email: 21,
};

export const SOCIAL_LIST = [
  {
    id: SOCIAL_ENUM.messenger,
    name: "Messenger",
    icon: "messenger",
  },
  {
    id: SOCIAL_ENUM.twitter,
    name: "Twitter",
    icon: "twitter",
  },
  {
    id: SOCIAL_ENUM.instagram,
    name: "Instagram",
    icon: "instagram",
  },
  {
    id: SOCIAL_ENUM.linkedin,
    name: "Linkedin",
    icon: "linkedin",
  },
  {
    id: SOCIAL_ENUM.skype,
    name: "Skype",
    icon: "skype",
  },
  {
    id: SOCIAL_ENUM.viber,
    name: "Viber",
    icon: "viber",
  },
  {
    id: SOCIAL_ENUM.whatsapp,
    name: "WhatsApp",
    icon: "whatsapp",
  },
  {
    id: SOCIAL_ENUM.telegram,
    name: "Telegram",
    icon: "telegram",
  },
  {
    id: SOCIAL_ENUM.snapchat,
    name: "Snapchat",
    icon: "snap-chat",
  },
  {
    id: SOCIAL_ENUM.discord,
    name: "Discord",
    icon: "discord",
  },
  {
    id: SOCIAL_ENUM.reddit,
    name: "Reddit",
    icon: "reddit",
  },
  {
    id: SOCIAL_ENUM.pinterest,
    name: "Pinterest",
    icon: "pinterest",
  },
  {
    id: SOCIAL_ENUM.youtube,
    name: "YouTube",
    icon: "youtube",
  },
  {
    id: SOCIAL_ENUM.tiktok,
    name: "TikTok",
    icon: "tiktok",
  },
  {
    id: SOCIAL_ENUM.qq,
    name: "QQ",
    icon: "qq",
  },
  {
    id: SOCIAL_ENUM.douyin,
    name: "Douyin",
    icon: "douyin",
  },
  {
    id: SOCIAL_ENUM.weibo,
    name: "Weibo",
    icon: "weibo",
  },
  {
    id: SOCIAL_ENUM.kuaishou,
    name: "Kuaishou",
    icon: "kuaishou",
  },
  {
    id: SOCIAL_ENUM.wechat,
    name: "WeChat",
    icon: "wechat",
  },
  {
    id: SOCIAL_ENUM.phone,
    name: "Phone Number",
    icon: "phone",
  },
  {
    id: SOCIAL_ENUM.email,
    name: "Email Address",
    icon: "email",
  },
  {
    id: 22,
    name: "Others",
    icon: "other",
  },
];

export const SOURCE_TYPE_LIST = [
  ...SOCIAL_LIST,
  {
    id: 23,
    name: "Phone",
    icon: "phone",
  },
  {
    id: 24,
    name: "Emails",
    icon: "email",
  },
  {
    id: 25,
    name: "Gmail",
    icon: "gmail",
  },
  {
    id: 26,
    name: "FaceTime",
    icon: "facetime",
  },
  {
    id: 27,
    name: "Native Messages",
    icon: "native-message",
  },
  {
    id: 28,
    name: "iMessage",
    icon: "native-message",
  },
  {
    id: 29,
    name: "Android CallLog database",
    icon: "database",
  },
  {
    id: 30,
    name: "eMail",
    icon: "email",
  },
  {
    id: 31,
    name: "Facebook",
    icon: "facebook",
  },
  {
    id: 32,
    name: "Slack",
    icon: "slack",
  },
  {
    id: 33,
    name: "Chat Group",
    icon: "chatGroup",
  },
  {
    id: 34,
    name: "Email Thread",
    icon: "emailThread",
  },
  {
    id: 35,
    name: "Teams",
    icon: "teams",
  }
];

export const SLACK_SOURCE = "Slack";

export const CONVERSATION_ACTION = {
  leave: "leave",
  join: "join",
};

export const SEARCH_COUNT_LIMIT = 500;