import { USER_AGENT_TEXT } from "constants/Common";
import { isReadValue } from "./ObjectHelper";

export const getUserAgent = () => {
  const { vendor, userAgent } = window.navigator;
  const isChromium = window.chrome;
  const isOpera = isReadValue(typeof window.opr);
  const isIEedge = userAgent.indexOf(USER_AGENT_TEXT.edge) > -1;
  const isFirefox =
    userAgent.toLowerCase().indexOf(USER_AGENT_TEXT.fireFox) > -1;
  const isIOSChrome = userAgent.match(USER_AGENT_TEXT.iosChrome);

  const isChrome =
    (isReadValue(isChromium) &&
      isReadValue(typeof isChromium) &&
      vendor === USER_AGENT_TEXT.google &&
      !isOpera &&
      !isIEedge) ||
    isIOSChrome;

  return {
    isChromium,
    isOpera,
    isIEedge,
    isFirefox,
    isIOSChrome,
    isChrome,
  };
};
