import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import { useOnClickOutside } from "hook/click-outside";

// Constants and Helpers
import { ELEMENT_ID } from "constants/ElementConstant";
import { getCurrentTime } from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./PrintExport.module.scss";

const PrintExport = ({
  screenName = "",
  exportOption = [],
  isDisplayTime = false,
  isExportExcel = true,
  handleExport = () => {},
  exportLoading = false,
}) => {
  const printExportRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  useOnClickOutside(printExportRef, () => setToggle(false));

  const toggleExport = () => setToggle(!toggle);

  const handlePrintPDF = () => {
    const originalTitle = document.title;
    const dateTime = getCurrentTime();
    let title = `StreemView_${screenName}`;
    if (isDisplayTime) title += `_${dateTime}`;
    document.title = title;
    setTimeout(
      () => {
        window.print();
        document.title = originalTitle;
      },
      screenName === 0
    );
  };

  return (
    <div className={styles["print-export"]} ref={printExportRef}>
      {exportLoading && <Spinner animation="border" variant="primary" />}
      <span className={styles["print"]}>
        <img
          src="/images/print-icon.svg"
          alt="print"
          onClick={handlePrintPDF}
          id={ELEMENT_ID.idPrint}
        />
        <p>Print to PDF</p>
      </span>
      {isExportExcel && (
        <span className={styles["export"]}>
          <img
            src="/images/export-icon.svg"
            alt="export"
            onClick={exportOption.length > 1 ? toggleExport : handleExport}
          />
          <p>Export to Excel</p>
        </span>
      )}
      {toggle && exportOption.length > 0 && (
        <ul className={styles["menu-option"]}>
          {exportOption.map((item, index) => (
            <li onClick={item.onclick} key={index}>
              {item.exportName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

PrintExport.propTypes = {
  screenName: PropTypes.string,
  isDisplayTime: PropTypes.bool,
  isExportExcel: PropTypes.bool,
  exportLoading: PropTypes.bool,
  exportOption: PropTypes.arrayOf(
    PropTypes.shape({
      exportName: PropTypes.string,
      onclick: PropTypes.func,
    })
  ),
  handleExport: PropTypes.func,
};

export default PrintExport;
