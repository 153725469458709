import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Constants
import { TYPE_CLICK } from "constants/Common";

// Styles
import styles from "./RecordControl.module.scss";

const RecordControl = (props) => {
  const {
    disablePrevious,
    disableNext,
    handleRecordChange,
    showRecordID = false,
    recordID = "",
  } = props;

  const handleSwitchItem = (type) => handleRecordChange(type);

  return (
      <div className={styles["record-control-wrap"]}>  
        <button
          type="button"
          className={clsx(
            "mar_r10",
            styles["btn"],
            styles["btn-previous-record"]
          )}
          disabled={disablePrevious}
          onClick={() => handleSwitchItem(TYPE_CLICK.previous)}
        >
          <img
            src={`/images/right_move_ic${
              disablePrevious ? "-disabled" : ""
            }.svg`}
            alt={TYPE_CLICK.previous}
          />
        </button>
        {showRecordID && (
          <div className={styles["record-id"]}>
            <p>{recordID}</p>
          </div>
        )}
        <button
          type="button"
          className={clsx(styles["btn"], styles["btn-next-record"])}
          disabled={disableNext}
          onClick={() => handleSwitchItem(TYPE_CLICK.next)}
        >
          <img
            src={`/images/left_move_ic${
              disableNext ? "-disabled" : ""
            }.svg`}
            alt={TYPE_CLICK.next}
          />
        </button>
      </div>
  );
};

RecordControl.propTypes = {
  disablePrevious: PropTypes.bool,
  disableNext: PropTypes.bool,
  showRecordID: PropTypes.bool,
  recordID: PropTypes.string,
  handleRecordChange: PropTypes.func,
};

export default RecordControl;