import React, { useRef, useState } from "react";
import Select from "react-select";
import { PropTypes } from "prop-types";
import clsx from "clsx";
import { useOnClickOutside } from "hook/click-outside";

// Constants
import { COLORS, KEY_CODE } from "constants/Common";

// Styles
import styles from "./DropdownGeneral.module.scss";

const DropdownGeneral = (props) => {
  const {
    className = "",
    classNameContainer = "",
    options = [],
    onChange = () => {},
    value = [],
    isSearchable = true,
    placeholder = "Select",
    isDisabled = false,
    isLoading = false,
  } = props;
  
  const [isInsideSelect, setIsInsideSelect] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const reactSelectRef = useRef();
  useOnClickOutside(reactSelectRef, () => setMenuIsOpen(false));

  const getBackgroundColor = (isSelected, isFocused) => {
    if (isSelected) return COLORS.main;
    if (isFocused && isInsideSelect) return COLORS.hoverSelect;
    return "";
  };

  const onKeyDown = (e) => {
    if (e.keyCode === KEY_CODE.enter) setMenuIsOpen(false);
  };

  return (
    <div
      ref={reactSelectRef}
      onMouseLeave={() => setIsInsideSelect(false)}
      onMouseEnter={() => setIsInsideSelect(true)}
      onClick={() => !isDisabled && setMenuIsOpen(!menuIsOpen)}
      className={classNameContainer}
    >
      <Select
        className={clsx(
          className,
          !isSearchable ? styles["dropdown-selected"] : ""
        )}
        classNamePrefix="react-select"
        isSearchable={isSearchable}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
        noOptionsMessage={() => (isSearchable ? null : "No options")}
        styles={{
          option: (styles, state) => ({
            ...styles,
            backgroundColor: getBackgroundColor(
              state.isSelected,
              state.isFocused
            ),
            "&:hover": {
              ...styles,
            },
          }),
        }}
        onKeyDown={onKeyDown}
        isDisabled={isDisabled}
        isLoading={isLoading}
        menuIsOpen={menuIsOpen}
      />
    </div>
  );
};

DropdownGeneral.propTypes = {
  value: PropTypes.array,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  classNameContainer: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

export default DropdownGeneral;
