import { createSlice } from "@reduxjs/toolkit";
import { SORT_BY } from "constants/Constants";
import { STATUS_EXPORT } from "constants/ExportConstant";

export const initialState = {
  paramSearch: {
    searchInput: "",
    exportStatuses: [],
    paginationParams: {
      columnSort: "createdDate",
      orderBy: SORT_BY.desc,
      pageSize: 10,
      pageNumber: 1,
    },
  },
  checkedStatus: [],
  createExport: {
    projectId: 0,
    userID: "",
    exportName: "",
    exportTime: "",
    scheduleTime: "",
    isScheduleExport: false,
    action: 0,
    status: 0,
    totalItems: 0,
    exportInformation: "",
    parameterConfig: {
      typeExport: [],
      tagID: [],
      excludeTags: [],
      condition: 0,
      exportAroundItems: 0,
      startingControlPrefix: "",
      startingControlNumber: 0,
      startingVolumePrefix: "",
      startingVolumeNumber: 0,
      rsmfDocumentSize: 0,
      emlDocumentSize: 0,
      delimiter: "",
      dateFormat: "",
      timeZone: 0,
    },
  },
  loadingExportSummary: false,
  exportNotification: {
    exportId: "",
    exportStatus: STATUS_EXPORT.ready.value,
  },
  originalData: "",
  currentTime: null,
  paramCondition: {
    isCreateExport: false,
    isCreateExportScreen: false,
  },
};

const exportManagementSlice = createSlice({
  name: "export",
  initialState,
  reducers: {
    setCreateExport: (state, action) => {
      state.createExport = {
        ...state.createExport,
        ...action.payload,
      };
    },
    setParameterConfig: (state, action) => {
      state.createExport.parameterConfig = {
        ...state.createExport.parameterConfig,
        ...action.payload,
      };
    },
    setLoadingExportSummary: (state, action) => {
      state.loadingExportSummary = action.payload;
    },
    setOriginalData: (state, action) => {
      state.originalData = action.payload;
    },
    setExportNotification: (state, action) => {
      state.exportNotification = action.payload;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setExportParam: (state, action) => {
      state.paramSearch = action.payload;
    },
    setParamCondition: (state, action) => {
      state.paramCondition = { ...state.paramCondition, ...action.payload };
    },
    setCheckedStatus: (state, action) => {
      state.checkedStatus = action.payload;
    },
    resetStoreExport: (state) => {
      state.createExport = initialState.createExport;
      state.currentTime = initialState.currentTime;
      state.exportNotification = initialState.exportNotification;
      state.loadingExportSummary = initialState.loadingExportSummary;
      state.originalData = initialState.originalData;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setParameterConfig,
  setCreateExport,
  setLoadingExportSummary,
  resetStoreExport,
  setExportNotification,
  setOriginalData,
  setCurrentTime,
  setExportParam,
  setParamCondition,
  setCheckedStatus,
} = exportManagementSlice.actions;
export const ExportManagementReducer = exportManagementSlice.reducer;
