export const TAG_VALIDATION = {
  tagName: {
    required: "Tag name is required.",
    maxLength: "Name must be less than or equal to 100 characters.",
    exist: "Tag Name has already existed.",
  },
  color: {
    required: "Color is required.",
    exist: "Color is used. Please choose the different one.",
  },
  hotKey: {
    required: "Hotkey is required.",
    exist: "Hotkey is used. Please choose the different one.",
  },
};

export const IMPORT_VALIDATIONS = {
  projectId: {
    required: "Matter Name is required.",
  },
  sourceFile: {
    required: "Source Path is required.",
    fileType: "Please upload the file that has the correct extension.",
  },
  dataSourceId: {
    required: "Data Source is required.",
    number: "Data Source must be the numbers.",
  },
  analystName: {
    required: "Analyst Name is required.",
    maxLength: "Analyst Name must be less than or equal to 100 characters.",
  },
  deviceName: {
    required: "Device Name is required.",
    maxLength: "Device Name must be less than or equal to 100 characters.",
  },
  phoneOwner: {
    required: "Phone Owner is required.",
    maxLength: "Phone Owner must be less than or equal to 100 characters.",
  },
  sourceType: {
    required: "Source Type is required.",
    maxLength: "Source Type must be less than or equal to 100 characters.",
  },
  custodianName: {
    required: "Custodian Name is required.",
    maxLength: "Custodian Name must be less than or equal to 100 characters.",
  },
};

export const EXPORT_VALIDATIONS = {
  exportName: {
    required: "This is a required field.",
    maxLength: "The maximum length is 100 characters. Please try again.",
    invalid: "Invalid format.",
    exist: "Export name does already exist. Please create a unique name.",
  },
  savedSearch: {
    required: "Either Saved Search or Tag is required.",
  },
  tagID: {
    required: "Either Saved Search or Tag is required.",
  },
  settingStarting: {
    requiredAll: "This is required field.",
    invalid: "Invalid format.",
    maxLengthNumber: "The maximum length is 9 characters. Please try again.",
    maxLengthPrefix: "The maximum length is 100 characters. Please try again.",
  },
  scheduleTime: {
    error: "Date/Time range cannot be in the past.",
    canNotReschedule: "Cannot be rescheduled",
  },
};

export const MATTER_VALIDATION = {
  required: "This field is required.",
  exists: "This name already exists.",
  matterName_max: "The Matter Name must be less than or equal to 128 characters.",
  reference_max: "The Client Matter Reference must be less than or equal to 128 characters.",
};

export const VALIDATION_TEXT = {
  required: "This field is required.",
  max_128: "The field must be less than or equal to 128 characters.",
  max_255: "The field must be less than or equal to 255 characters.",
};

export const SOURCE_VALIDATION = {
  custodianName: {
    maxLength:
      "The Custodian Name must be less than or equal to 128 characters.",
  },
  deviceName: {
    maxLength: "The Device Name must be less than or equal to 128 characters.",
  },
  analystName: {
    maxLength:
      "The Analyst Name must be less than or equal to 128 characters.",
  },
  identifier: {
    maxLength:
      "The Phone Owner Identifier must be less than or equal to 128 characters.",
  },
  typeExtension: {
    invalid: "Please upload the file that has the correct extension.",
  },
};
