import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import axios, { handleAbortRequest } from "services/ApiConfig";
import { getUserProfileApi } from "services/UserProfileService";
import { removeToken, removeUserInfo, useToken } from "hook/auth";
import { useAuth0 } from "@auth0/auth0-react";

//Redux
import { updateAllData } from "store/UserReducer";
import { fetchProjectList } from "store/ProjectReducer";
import { setIsPausedDb } from "store/CommonReducer";

//Components
import { UserProfile } from "pages/user-profile/UserProfile";
import { SavedSearchPage } from "pages/intelligent-search/saved-search/SavedSearchPage";
import { UserAdministration } from "pages/user-administration/UserAdministration";
import ProjectsAdministration from "pages/project/ProjectsAdministration";
import ActiveProject from "pages/project/ActiveProject";
import Header from "components/header/Header";
import IntelligentSearchPage from "pages/intelligent-search/IntelligentSearchPage";
import IntelligentSearchResultPage from "pages/intelligent-search/search-result/IntelligentSearchResultPage";
import GeneralReviewPage from "pages/streems/GeneralReviewPage";
import TagManagementPage from "pages/tag-management/TagManagement";
import PopupNotification from "components/shared/popup/popup-notification/PopupNotification";
import EventTimelineDetailPage from "pages/event-timeline/event-timeline-detail/EventTimelineDetailPage";
import AnalyticsContainerPage from "pages/intelligent-dashboard/analytics-container/AnalyticsContainerPage";
import ErrorResult from "components/shared/error/ErrorResult";
import ItemDetailPage from "pages/item-detail/ItemDetailPage";
import StreemViewConfigPage from "pages/streemview-config/StreemViewConfigPage";
import CreateExportPage from "pages/export-management/create-export/CreateExportPage";
import { ExportManagementPage } from "pages/export-management/ExportManagementPage";
import IdentifierEntityManagement from "pages/identifier-entity-management/IdentifierEntityManagement";
import DataSourcePage from "pages/data-source/DataSourcePage";
import SearchPage from "pages/search-terms/SearchTermsPage";
import { PopupNotice } from "components/shared/popup/popup-notice/PopupNotice";
import MatterNotification from "components/matter-notification/MatterNotification";

// Constants
import {
  AXIOS_RESPONSE_CODE,
  EVENT_KEY_LISTENER,
  EVENT_LISTENER,
  PATHNAME_ITEMS,
  PATH_NAME
} from "constants/Common";
import {
  ERROR_PAGES,
  ERROR_STATUS,
  RESPONSE_STATUS,
} from "constants/StatusCodeConstant";
import { LOCAL_STORAGE } from "constants/LocalStorage";

// Services
import { checkPauseDbApi } from "services/CommonService";

// Styles
import errorPageStyles from "pages/error/Error.module.scss";
import { setEntityAvatarUrls } from "store/AvatarReducer";

const PrivateRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { token, isUser } = useToken();
  const { logout: logOutAuth0 } = useAuth0();

  const timeRecallCheckPausedDb = 3000;
  const isPausedDb = useSelector((state) => state.common.isPausedDb);
  const { matterSubscription } = useSelector((state) => state.notification);

  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState("");
  const [showTimeOut, setShowTimeOut] = useState(false);

  const adminPages = [
    { path: PATH_NAME.matters, component: ProjectsAdministration },
    { path: PATH_NAME.userAdministration, component: UserAdministration },
    { path: PATH_NAME.configuration, component: StreemViewConfigPage },
  ];

  const startPathUserPage = `${PATH_NAME.matters}/:projectId/`;
  const userPages = [
    { path: PATH_NAME.userProfile, component: UserProfile },
  ];

  const elementPages = [
    { path: "", component: isUser() ? ActiveProject : ProjectsAdministration },
    { path: PATH_NAME.matters, component: ActiveProject },
    {
      path: `${startPathUserPage}${PATH_NAME.intelligentSearch}`,
      component: IntelligentSearchPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.intelligentSearch}/:searchId`,
      component: IntelligentSearchPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}`,
      component: IntelligentSearchResultPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/:searchResultId`,
      component: IntelligentSearchResultPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.savedSearches}`,
      component: SavedSearchPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.dataSources}`,
      component: DataSourcePage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.eventTimeline}`,
      component: AnalyticsContainerPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.communicationAnalyzer}`,
      component: AnalyticsContainerPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.itemDetail}`,
      component: ItemDetailPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.eventTimeline}/${PATH_NAME.detail}`,
      component: EventTimelineDetailPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.tagManagement}`,
      component: TagManagementPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.createExport}`,
      component: CreateExportPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.exportManagement}/:exportId`,
      component: CreateExportPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.exportManagement}`,
      component: ExportManagementPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.people}`,
      component: IdentifierEntityManagement,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.streems}`,
      component: GeneralReviewPage,
    },
    {
      path: `${startPathUserPage}${PATH_NAME.searchTerms}`,
      component: SearchPage,
    },
  ];

  const excludedRoutes = [
    'lib/ej2-pdfviewer-lib',
  ];

  const checkIsPausedDb = async (projectId) => {
    dispatch(setIsPausedDb(true));
    setTimeout(async () => {
      try {
        const response = await checkPauseDbApi(projectId);
        if (response?.status === RESPONSE_STATUS.success)
          dispatch(setIsPausedDb(false));
      } catch (error) {
        console.log(error);
      }
    }, timeRecallCheckPausedDb);
  };

  axios.interceptors.response.use(
    (response) => {
      // Do something with response data
      setError(null);
      return response;
    },
    (errorRes) => {
      if (ERROR_STATUS.databasePaused === errorRes.response.status) {
        checkIsPausedDb(Number(errorRes.response.data.Message));
        return;
      }

      if (
        errorRes.code === AXIOS_RESPONSE_CODE.timeOut ||
        errorRes.response.status === ERROR_STATUS.requestTimeOut
      ) {
        // Prevent always showing popup when reloading the page in firefox case API is not finished
        if (
          performance.getEntriesByType("navigation")?.length > 0 &&
          performance.getEntriesByType("navigation")[0].type !==
          EVENT_LISTENER.reload
        )
          window.dispatchEvent(
            new CustomEvent(EVENT_KEY_LISTENER.timeOut, {
              detail: { key: EVENT_KEY_LISTENER.timeOut, newValue: true },
            })
          );
        // Cancel request
        handleAbortRequest();

        return Promise.reject({
          status: ERROR_STATUS.requestTimeOut,
          message: "Timeout request",
        });
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Check new error is different from the old error code will continue to handle
      if (errorRes.response.status !== error) {
        switch (errorRes.response.status) {
          case ERROR_STATUS.unAuthorized:
            removeToken();
            removeUserInfo();
            // Prevent navigate to login page when inside
            if (window.location.href.indexOf("login") === -1) {
              localStorage.setItem(LOCAL_STORAGE.previousPath, pathname);
              logOutAuth0({
                returnTo: `${window.location.origin}/login`,
              });
            }
            setError(ERROR_STATUS.unAuthorized);
            break;
          case ERROR_STATUS.accessDenied:
            setError(ERROR_STATUS.accessDenied);
            navigate(PATHNAME_ITEMS.error403);
            break;
          case ERROR_STATUS.pageNotFound:
            setError(ERROR_STATUS.pageNotFound);
            navigate(PATHNAME_ITEMS.error404);
            break;
          case ERROR_STATUS.serverError:
            setError(ERROR_STATUS.serverError);
            navigate(PATHNAME_ITEMS.error500);
            break;
          case ERROR_STATUS.locked:
            if (errorRes.config.url !== PATHNAME_ITEMS.authLogin) {
              if (token) navigate(PATHNAME_ITEMS.error423);
              removeToken();
              removeUserInfo();
              setError(ERROR_STATUS.locked);
            }
            break;
          case ERROR_STATUS.expectationFailed:
            setPopupType("");
            setShowPopup(true);
            break;

          default:
            break;
        }
      }
      return Promise.reject(errorRes);
    }
  );

  const renderComponent = (component) => {
    const Component = component;
    return <Component />;
  };

  const renderErrorPage = ({ status, content, imgErr, textHyperLink }) => (
    <div className={errorPageStyles.container}>
      <ErrorResult
        status={status}
        content={content}
        imgErr={imgErr}
        textHyperLink={textHyperLink}
      />
    </div>
  );

  const handleClosePopup = () => {
    if (popupType) window.location.reload();
    else {
      removeToken();
      removeUserInfo();
      navigate(PATHNAME_ITEMS.login);
    }
  };

  const handleClose = (e) => {
    e.preventDefault();
    window.dispatchEvent(
      new CustomEvent(EVENT_KEY_LISTENER.timeOut, {
        detail: { key: EVENT_KEY_LISTENER.timeOut, newValue: false },
      })
    );
  };

  const getUserProfile = async () => {
    const userInfo = await getUserProfileApi();
    dispatch(updateAllData(userInfo.data));
  };

  const RenderPage = ({ code = 0, children }) => {
    useEffect(() => {
      if (code) setError(code);
    }, []);
    return children;
  };

  useEffect(() => {
    window.addEventListener(EVENT_KEY_LISTENER.storage, (e) => {
      // Listen when local storage changes
      if (JSON.parse(e.storageArea.token) !== token && !document.hasFocus()) {
        window.location.reload();
      }
    });

    window.addEventListener(EVENT_KEY_LISTENER.offline, () => {
      setPopupType("network");
      setShowPopup(true);
    });

    const timeOutEventHandler = (event) => {
      if (event.detail.key === EVENT_KEY_LISTENER.timeOut)
        setShowTimeOut(JSON.parse(event.detail.newValue));
    };
    // Hook up the event handler
    window.addEventListener(EVENT_KEY_LISTENER.timeOut, timeOutEventHandler);
    return () => {
      window.removeEventListener(EVENT_KEY_LISTENER.storage, null);
      window.removeEventListener(EVENT_KEY_LISTENER.offline, null);
      window.removeEventListener(
        EVENT_KEY_LISTENER.timeOut,
        timeOutEventHandler
      );
    };
  }, []);

  useEffect(() => {
    if (pathname === PATHNAME_ITEMS.error400) return;
    if (!token) {
      navigate(`/login`);
      return;
    }

    if (
      isUser() &&
      [
        PATHNAME_ITEMS.userManagement,
        PATHNAME_ITEMS.importManagement,
        PATHNAME_ITEMS.importTool,
      ].includes(pathname)
    ) {
      navigate(PATHNAME_ITEMS.error403);
      return;
    }

    getUserProfile();
    dispatch(fetchProjectList());
  }, [token]);

  useEffect(() => {
    dispatch(setEntityAvatarUrls([]));
  }, [matterSubscription?.currentId])

  return (
    <>
      <Header errorCode={error} />
      <MatterNotification />
      <PopupNotification
        isShow={showPopup}
        handleClose={handleClosePopup}
        title="Notification"
        content={
          popupType
            ? "Your internet connection was interrupted. Please try again."
            : "Your role in the system has been changed. Please log out and access to the website again."
        }
        buttonName={popupType ? "Reload" : "OK"}
      />
      <PopupNotification
        isShow={showTimeOut}
        handleClose={handleClose}
        title="Request Timed Out"
        content="This request takes too long process, it is timed out by the server."
        buttonName="OK"
      />
      <PopupNotice
        isShow={isPausedDb}
        content="The matter is coming online..."
      />
      {!isPausedDb && (
        <>
          {!excludedRoutes.includes(pathname) && (
            <Routes>
              {!isUser() &&
                adminPages.map((page) => (
                  <Route
                    key={page.path}
                    path={page.path}
                    element={renderComponent(page.component)}
                  />
                ))}

              {userPages.map((page, userIdx) => (
                <Route
                  index={!!page.index}
                  key={page.path || userIdx}
                  path={!page.index ? page.path : false}
                  element={renderComponent(page.component)}
                />
              ))}

              {elementPages.map((page) => (
                <Route
                  key={page.path}
                  path={page.path}
                  element={renderComponent(page.component)}
                />
              ))}

              {ERROR_PAGES.map((errorPage) => (
                <Route
                  key={errorPage.path}
                  path={errorPage.path}
                  element={
                    <RenderPage code={errorPage.code}>
                      {renderErrorPage(errorPage.info)}
                    </RenderPage>
                  }
                />
              ))}
            </Routes>
          )}
        </>
      )}
    </>
  );
};

export default PrivateRoute;
