import {
  FIELD_VALIDATION,
  HTML_REGEX_STR,
  SPECIAL_CHAR_REGEX,
} from "constants/RegexConstant";
import { isReadValue } from "./ObjectHelper";
import { detectTextInHtml } from "./TextHelper";

const replaceAllSpaceText = (text) => {
  while (text?.indexOf(" ") !== -1) {
    text = text?.replace(" ", "");
  }
  return text;
};

// Get list emoji from search input then change to array if it is all emojies
const changeAllEmojiToArray = (highlight) => {
  let isAllEmoji = true;
  let searchText = replaceAllSpaceText(highlight);
  let data = searchText.split(FIELD_VALIDATION.emoji);
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item !== "") {
      isAllEmoji = false;
      break;
    }
  }
  if (isAllEmoji)
    data = searchText.match(new RegExp(FIELD_VALIDATION.emoji, "gi"));
  return { data, isAllEmoji };
};

const keepSpecialCharRegex = (chars = [], text = "") => {
  let result = text;
  chars.forEach((item) => {
    if (!text.includes(item)) return;
    result = result.replaceAll(item, `\\${item}`);
  });
  return new RegExp(result, "gi");
};

const replaceText = (word) =>
  `${FIELD_VALIDATION.keywordReplaceHighlight}${word}${FIELD_VALIDATION.keywordReplaceHighlight}`;

const subStringSearch = (text = "", highlighList = [], isAllEmoji = false) => {
  highlighList.forEach((item) => {
    let result = "";
    if (!item) return;
    if (!isAllEmoji) {
      while (text.toLowerCase().includes(item.toLowerCase())) {
        const indexFound = text.toLowerCase().indexOf(item.toLowerCase());
        const word = text.slice(indexFound, indexFound + item.length);
        // Concat highlight search with word found
        result += text
          .slice(0, indexFound + item.length)
          .replace(
            keepSpecialCharRegex(SPECIAL_CHAR_REGEX, item),
            replaceText(word)
          );
        text = text.slice(indexFound + item.length, text.length);
      }
      result += text;
      text = result;
    } else {
      text = text.replaceAll(
        keepSpecialCharRegex(SPECIAL_CHAR_REGEX, item),
        replaceText(item)
      );
    }
  });
  return text.split(FIELD_VALIDATION.keywordReplaceHighlight);
};

const isExistHighlight = (highlights = [], text = "") =>
  highlights.filter((item) => item?.toLowerCase() === text.toLowerCase())
    .length > 0;

const getListIndexHighlight = (bodyParams) => {
  const { data = [], highlights = [], listIndex = [], id, key } = bodyParams;
  if (data?.length <= 0) return;
  let parts = [];
  let index = 0;
  let isDone = false;
  for (let i = 0; i < data.length; i++) {
    let highlighIndex = -1;
    const highlighText = data[i];
    if (isReadValue(highlighText)) {
      if (isExistHighlight(highlights, highlighText)) {
        let countDuplicate = listIndex.filter(
          (item) => item.id === id && item.isDone && item.key === key
        ).length;

        if (countDuplicate === 0) {
          for (let j = i + 1; j < data.length; j++) {
            if (isExistHighlight(highlights, data[j])) {
              countDuplicate++;
              break;
            }
          }
          if (i === data.length - 1 || countDuplicate === 0) isDone = true;
          listIndex.push({ isDone, index: listIndex.length, id, key });
        }
        const listIndexOfMessageNeedToFind = listIndex.filter(
          (item) => item.id === id && item.key === key
        );
        if (listIndexOfMessageNeedToFind.length > 0) {
          highlighIndex = listIndexOfMessageNeedToFind[index]?.index;
          index++;
        } else highlighIndex = listIndex.length;
      }
    }
    parts.push({ text: highlighText, index: highlighIndex });
  }
  return { parts, listIndex };
};

const wordStartWithSpecialChar = (word = "") => {
  const isExist =
    SPECIAL_CHAR_REGEX.filter((item) => word.startsWith(item)).length > 0;
  return isExist ? `\\${word}` : word;
};

const searchHtmlText = (body = "", currentIndex, search = "") => {
  let text =
    body
      ?.replaceAll(new RegExp(HTML_REGEX_STR.comment, "g"), "")
      .replaceAll(new RegExp(HTML_REGEX_STR.style, "g"), "")
      .replaceAll(new RegExp(HTML_REGEX_STR.title, "g"), "") || "";

  let result = "";
  let i = currentIndex;
  while (detectTextInHtml(text).toLowerCase().includes(search.toLowerCase())) {
    const indexOfWord = text.toLowerCase().indexOf(search.toLowerCase());
    let word = text.slice(indexOfWord, indexOfWord + search.length) || "";
    // Concat highlight search with word found

    const textReplace = `<span style="background-color:yellow; color:black" id="element-pos-${i}">${word}</span>`;

    const dataReplace = text.replace(
      new RegExp(`(?![^<]*>)${wordStartWithSpecialChar(word)}`, "gi"),
      textReplace
    );

    const indexSlice = dataReplace.indexOf(textReplace);
    result += dataReplace.slice(0, indexSlice + textReplace.length);

    text = dataReplace.slice(
      indexSlice + textReplace.length,
      dataReplace.length
    );
    i++;
  }
  result += text;
  text = result;
  return { text: result, index: i };
};

const getBodySearch = (email, indexSearch, searchInput) => {
  const subject = searchHtmlText(email.subject, indexSearch, searchInput);
  indexSearch = subject.index;

  const emailParties = email.emailParties.map((item) => {
    const nameResult = searchHtmlText(item.name, indexSearch, searchInput);
    indexSearch = nameResult.index;

    const emailResult = searchHtmlText(item.email, indexSearch, searchInput);
    indexSearch = emailResult.index;
    return {
      ...item,
      name: nameResult.text,
      email: emailResult.text,
    };
  });

  const body = searchHtmlText(email.body, indexSearch, searchInput);
  return {
    data: { ...email, body: body.text, emailParties, subject: subject.text },
    index: body.index,
  };
};

const createIndexHighlight = (emailList = [], searchInput) => {
  let indexSearch = 0;
  const emailAfter = emailList.map((item) => {
    const result = getBodySearch(item, indexSearch, searchInput);
    indexSearch = result.index;
    return result.data;
  });

  return { data: emailAfter, totalIndex: indexSearch };
};

export {
  changeAllEmojiToArray,
  subStringSearch,
  getListIndexHighlight,
  searchHtmlText,
  getBodySearch,
  createIndexHighlight,
};
