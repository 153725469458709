import axios, { authHeader } from "./ApiConfig";

const assignUser = (userId, projectId) =>
  axios.post(
    `/projectAdmin/assignUser`,
    {
      ProjectId: parseInt(projectId),
      UserId: userId,
    },
    {
      headers: authHeader(),
    }
  );

const removeUser = (userId, projectId) =>
  axios.post(
    `/projectAdmin/removeUser`,
    {
      ProjectId: parseInt(projectId),
      UserId: userId,
    },
    {
      headers: authHeader(),
    }
  );

export { assignUser, removeUser };
