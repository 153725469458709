import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { HEAT_MAP_COLORS } from "constants/Constants";

// Styles
import styles from "./ProgressColorBarItem.module.scss";

const ProgressColorBarItem = (props) => {
  const { bgColor, value, countTo, countFrom, index, average } = props;

  const renderItem = () => {
    let messageRange = "";
    if (index === 0) {
      messageRange = `> ${Number(countTo - average).toFixed()}`;
    } else if (countFrom + 1 > 0 && countTo > 0) {
      const countToDis = countTo.toFixed();
      const countFromDis = Number(countFrom.toFixed()) + 1;

      messageRange = `${countFromDis}${
        countToDis === countFromDis ? "" : ` - ${countToDis}`
      }`;
    }
    return <span>{messageRange}</span>;
  };

  return (
    <div className={styles["progress-bar-wrap"]}>
      <div
        className={clsx("me-3", styles["progress-bar-cell"])}
        style={{
          background: `${
            bgColor === HEAT_MAP_COLORS[HEAT_MAP_COLORS.length - 1]
              ? "#fff"
              : bgColor
          }`,
          backgroundImage: `url(${
            typeof value === "number" && value === -1
              ? "/images/empty-box-icon.svg"
              : ""
          })`,
        }}
      ></div>
      {renderItem()}
    </div>
  );
};

export default ProgressColorBarItem;

ProgressColorBarItem.propTypes = {
  bgColor: PropTypes.string,
  value: PropTypes.any,
  countTo: PropTypes.number,
  countFrom: PropTypes.number,
  index: PropTypes.number,
  average: PropTypes.any,
};
