import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import clsx from "clsx";

// Services
import { getManageSearchDetail } from "services/SavedSearchService";

// Components
import { CombineSearch } from "components/intelligent-search/saved-search/combine-search/CombineSearch";
import SavedSearchQueries from "components/intelligent-search/saved-search/saved-search-queries/SavedSearchQueries";
import SuggestionItem from "../search-input/suggestion-item/SuggestionItem";

// Constants & Helpers
import { SAVED_SEARCH_QUERIES } from "constants/SavedSearchConstant";
import { COMMON_TEXT } from "constants/Common";
import {
  handleConsolidatedType,
  handleDataQueries,
} from "helpers/SavedSearchHelpers";
import { formatShowNumber } from "helpers/FormatterHelper";

// Styles
import styles from "./PreviewSavedSearch.module.scss";

const PreviewSavedSearch = (props) => {
  const {
    savedSearch,
    searchInput,
    selectedItem,
    handleChangeMenu,
    isReset = false,
    dataSourceList,
  } = props;

  const { searchID, searchName } = savedSearch;
  const { projectId } = useParams();
  const [showPreview, setShowPreview] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewData, setPreviewData] = useState({});

  const previewSavedSearch = {
    type: {
      label: "Type",
      value: previewData.type || COMMON_TEXT.default,
    },
    user: {
      label: "User",
      value: previewData.userName || COMMON_TEXT.default,
    },
    count: {
      label: "Count",
      value: formatShowNumber(previewData.count),
    },
    queries: {
      label: "Queries",
      value: previewData.queryJSON || COMMON_TEXT.default,
      isQueries: true,
    },
  };

  const handleShowOrHidePreview = () => setShowPreview(!showPreview);

  const convertJson = (data = []) => data.map((item) => JSON.parse(item));

  const fetchSavedSearchID = async (searchId) => {
    try {
      setPreviewLoading(true);
      const result = await getManageSearchDetail(projectId, searchId);
      if (!result || !result.data) return;
      const searchDetail = result.data.object;
      setPreviewData({
        ...searchDetail,
        entities:
          searchDetail.entities?.length > 0
            ? convertJson(searchDetail.entities)
            : [],
      });
    } catch (error) {
      console.log(error);
      setPreviewData({});
    } finally {
      setPreviewLoading(false);
    }
  };

  const renderSearchQueries = (searchQueries, data) => {
    const newData = {
      ...data,
      entities: data?.entities?.map((item) => item.Name),
    };
    return searchQueries.map((item, index) => (
      <SavedSearchQueries
        key={index}
        label={handleConsolidatedType(item.key, newData)}
        dataQueries={handleDataQueries(item.key, newData, dataSourceList)}
        isPreview
      />
    ));
  };

  const renderPreviewQueries = () => (
    <div className={styles["search-queries"]}>
      {previewData.isCombination ? (
        <CombineSearch
          queryJSON={previewData.queryJSON}
          condition={previewData.condition}
        />
      ) : (
        renderSearchQueries(SAVED_SEARCH_QUERIES.queries, previewData)
      )}
    </div>
  );

  const PreviewItem = useCallback(() => {
    return (
      <ul className={styles["preview-save-search"]}>
        {previewLoading ? (
          <Spinner animation="border" variant="primary" role="status" />
        ) : (
          <>
            {Object.keys(previewSavedSearch).map((item, index) => (
              <li key={index}>
                <span className={styles["preview-label"]}>
                  {previewSavedSearch[item].label}
                </span>
                {previewSavedSearch[item].isQueries ? (
                  renderPreviewQueries()
                ) : (
                  <span className={styles["preview-value"]}>
                    {previewSavedSearch[item].value}
                  </span>
                )}
              </li>
            ))}
          </>
        )}
      </ul>
    );
  }, [previewLoading]);

  useEffect(() => {
    showPreview && fetchSavedSearchID(searchID);
  }, [showPreview]);

  useEffect(() => {
    isReset && setShowPreview(false);
  }, [isReset]);

  return (
    <li className={styles["menu-list-item"]}>
      <span className={styles["list-item-search"]}>
        <div
          className={clsx(
            styles["search-name"],
            searchID === selectedItem ? styles["active"] : ""
          )}
          onClick={handleShowOrHidePreview}
        >
          <img
            src={`/images/${showPreview ? "up" : "down"}-icon.svg`}
            alt={showPreview ? "up-icon" : "down-icon"}
          />
          {searchInput ? (
            <SuggestionItem data={searchName} input={searchInput.trim()} />
          ) : (
            <span>{searchName}</span>
          )}
        </div>
        <span
          className={clsx(
            styles["selected-search"],
            searchID === selectedItem ? styles["active"] : ""
          )}
          onClick={() => handleChangeMenu(searchID)}
        >
          {searchID === selectedItem ? "Selected" : "Select"}
        </span>
      </span>
      {showPreview && <PreviewItem />}
    </li>
  );
};

PreviewSavedSearch.propTypes = {
  savedSearch: PropTypes.shape({
    searchID: PropTypes.number,
    searchName: PropTypes.string,
  }),
  searchInput: PropTypes.string,
  selectedItem: PropTypes.any,
  isReset: PropTypes.bool,
  dataSourceList: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      custodianName: PropTypes.string,
      dataSourceID: PropTypes.number,
      dataSourceTypeId: PropTypes.number,
      dataSourceTypeName: PropTypes.string,
    })
  ),
  handleChangeMenu: PropTypes.func,
};

export default PreviewSavedSearch;
