import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import styles from "./Checkbox.module.scss";

const Checkbox = ({
  id,
  type = "checkbox",
  size = "sm", // sm = 12px, md = 14px, lg = 16px
  name = "",
  label = "",
  handleClick = () => {},
  isChecked = false,
  className = "",
  disabled = false,
  isColor = false,
  color = "",
  value = "",
  labelSmall = false,
  isIndeterminate = false
}) => {
  return (
    <div
      className={clsx(
        styles["check-box"],
        styles[`check-box-${size}`],
        { [styles['indeterminate']]: isIndeterminate },
        styles[`${isColor && "check-box-black"}`]
      )}
    >
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        checked={isChecked && !isIndeterminate}
        className={clsx("me-2", className)}
        disabled={disabled}
        value={value}
        ref={inputRef => {
          if (inputRef) {
            inputRef.indeterminate = isIndeterminate;
          }
        }}
      />
      <label
        htmlFor={id}
        className={disabled ? styles["checkbox-disabled"] : ""}
      >
        <span
          className={clsx(
            styles["label"],
            !label ? styles["label-unset"] : "",
            labelSmall ? styles["label-small"] : ""
          )}
        >
          {color && (
            <span
              style={{ backgroundColor: `${color}` }}
              className={styles["color"]}
            ></span>
          )}
          {label}
        </span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  isChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  isColor: PropTypes.bool,
  labelSmall: PropTypes.bool,
  handleClick: PropTypes.func,
  isIndeterminate: PropTypes.bool,
};

export default Checkbox;
