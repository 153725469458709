import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import clsx from "clsx";

// Components
import ErrorInline from "../error-inline/ErrorInline";

// Styles
import styles from "./FormGroupValidation.module.scss";

const FormGroupValidation = ({
  label = "",
  id = "",
  value = "",
  isRequired = false,
  isTextArea = false,
  errors = "",
  touched = false,
  onChange = () => {},
}) => {
  return (
    <Form.Group className={clsx("app-form-group", styles["form-group"])}>
      <Form.Label className={styles["form-label"]}>
        <span>
          {label}
          {isRequired && <span className="text-danger"> *</span>}
        </span>
        {isTextArea && (
          <span className={styles["count-word"]}>
            {value?.length || 0} {`${value?.length > 1 ? "words" : "word"}`}
          </span>
        )}
      </Form.Label>
      {isTextArea ? (
        <React.Fragment>
          <Form.Control
            as="textarea"
            className={clsx(
              "app-form-textarea",
              styles["form-textarea"],
              errors && touched ? "error" : ""
            )}
            id={id}
            placeholder="Input your Note..."
            value={value}
            onChange={onChange}
          />
          <div className={styles["form-error"]}>
            <ErrorInline errorMsg={errors} touched={touched} />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Form.Control
            type="text"
            placeholder={`Input ${label.toLowerCase()}...`}
            className={clsx(
              "app-form-input",
              styles["form-text"],
              errors && touched ? "error" : ""
            )}
            id={id}
            value={value}
            onChange={onChange}
          />
          <div className={styles["form-error"]}>
            <ErrorInline errorMsg={errors} touched={touched} />
          </div>
        </React.Fragment>
      )}
    </Form.Group>
  );
};

FormGroupValidation.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.string,
  isRequired: PropTypes.bool,
  isTextArea: PropTypes.bool,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FormGroupValidation;
