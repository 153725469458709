import React, { useState } from "react";
import { PropTypes } from "prop-types";
import lowerCase from "lodash/lowerCase";
import clsx from "clsx";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { renderStrongTag } from "helpers/FormatterHelper";
import { checkAndReplaceTagEquivalent, isHtmlTag } from "helpers/TextHelper";
import { keepHtmlEntity } from "helpers/CommonHelper";

//Constants
import { DATE_TIME_TYPE } from "constants/Constants";
import { DATA_TYPE_LABEL } from "constants/DataType";
import { SEARCH_SELECTOR } from "constants/SearchSelect";

//styles
import styles from "./SavedSearchQueries.module.scss";

const SavedSearchQueries = (props) => {
  const { label, dataQueries, isPreview = false } = props;

  const [hoveredCart, setHoveredCart] = useState(-1);

  const showCartHandler = (event) => setHoveredCart(event);
  const hideCartHandler = () => setHoveredCart(-1);

  //Check null props
  if (!dataQueries) return <span></span>;
  const className = label?.className;

  const renderDataQueries = () => {
    let value = "";
    //show dataSource queries
    if (dataQueries.sources)
      value = checkAndReplaceTagEquivalent(
        dataQueries.sources
          ?.map((item) => `${item.deviceName} - ${item.custodianName}`)
          .join(", ")
      );

    //show entities queries
    if (dataQueries.entities)
      value = checkAndReplaceTagEquivalent(
        dataQueries.entities.map((item) => item).join(", ")
      );

    //show dataTypes queries
    if (dataQueries.dataTypes)
      value = dataQueries.dataTypes
        .map((item) => `${DATA_TYPE_LABEL[item?.toLowerCase()] || item}`)
        .join(", ");

    if (dataQueries.startDate) {
      const startDate = formatDateTime({
        dateTime: dataQueries.startDate,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
      });
      value = `After ${renderStrongTag(startDate)}`;
    }

    if (dataQueries.endDate) {
      const endDate = formatDateTime({
        dateTime: dataQueries.endDate,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
      });
      value = `Before ${renderStrongTag(endDate)}`;
    }

    //show from start to end date
    if (dataQueries.startDate && dataQueries.endDate) {
      const startDate = formatDateTime({
        dateTime: dataQueries.startDate,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
      });
      const endDate = formatDateTime({
        dateTime: dataQueries.endDate,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
      });
      value = `From ${renderStrongTag(startDate)} to ${renderStrongTag(
        endDate
      )}`;
    }

    //Handle queries nested type global search
    const getQueryNested = (temp) => {
      const { nested, method, withinWords, keyword, nestedMethod } = temp;
      if (!nested) {
        if (method) {
          value += ` ${lowerCase(method)} `;
          if (method === SEARCH_SELECTOR.method.within)
            value += ` ${renderStrongTag(withinWords)} words of `;
        }
        value += renderStrongTag(checkAndReplaceTagEquivalent(keyword));
      } else {
        value += ` ${lowerCase(nestedMethod)}`;
        if (nestedMethod === SEARCH_SELECTOR.method.within)
          value += ` ${renderStrongTag(withinWords)} words of `;
        value += " ( ";
        nested.forEach((item) => getQueryNested(item));
        value += " ) ";
      }
    };

    //Show queries type global search
    if (dataQueries.globalSearch?.length) {
      const data = JSON.parse(dataQueries.globalSearch);
      data?.globalSearch?.forEach((rootItem) => getQueryNested(rootItem));
    }
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: isHtmlTag(value) ? value : keepHtmlEntity(value),
        }}
      ></span>
    );
  };

  return (
    label !== undefined && Object.keys(label).length > 0 && (
      <div
        className={clsx(
          styles[className],
          styles["container"],
          isPreview ? styles["preview-container"] : ""
        )}
        onMouseLeave={hideCartHandler}
        onMouseEnter={showCartHandler}
      >
        <div className={styles["content"]}>
          <p className={styles["title"]}>{label.title}</p>
        </div>
        <div
          style={{
            top: hoveredCart.clientY + "px",
            left: hoveredCart.clientX - 200 + "px",
            display: hoveredCart === -1 && "none",
          }}
          className={styles["preview-description"]}
        >
          <div className={styles["description"]}>
            <p>{label.title}</p>
            {renderDataQueries()}
          </div>
        </div>
      </div>
    )
  )
};

SavedSearchQueries.propTypes = {
  label: PropTypes.object,
  isPreview: PropTypes.bool,
  dataQueries: PropTypes.shape({
    sources: PropTypes.array,
    entities: PropTypes.array,
    dataTypes: PropTypes.array,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    globalSearch: PropTypes.string,
  }),
};

export default SavedSearchQueries;
