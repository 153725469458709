import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  datePicker: {
    dateTimeDataStart: null,
    dateTimeDataEnd: null,
    typeFilter: 0,
    includeBlankDate: true,
  },
  messageViewDatePicker: {
    dateTimeDataStart: null,
    dateTimeDataEnd: null,
    typeFilter: 0,
    includeBlankDate: true,
  },
};

const datePickerSlice = createSlice({
  name: "date-picker",
  initialState,
  reducers: {
    updateDateTime: (state, action) => {
      state.datePicker = {
        ...state.datePicker,
        ...action.payload,
      };
    },
    updateDateTimeMessageViewPicker: (state, action) => {
      state.messageViewDatePicker = {
        ...state.messageViewDatePicker,
        ...action.payload,
      };
    },
    resetDateTime: (state) => {
      state.datePicker = {
        dateTimeDataStart: null,
        dateTimeDataEnd: null,
        typeFilter: 0,
        includeBlankDate: true,
      };
    },
    resetDateTimeMess: (state) => {
      state.messageViewDatePicker = {
        dateTimeDataStart: null,
        dateTimeDataEnd: null,
        typeFilter: 0,
        includeBlankDate: true,
      };
    },
    clearDatePickerData: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  updateDateTime,
  updateDateTimeMessageViewPicker,
  resetDateTime,
  resetDateTimeMess,
  clearDatePickerData,
} = datePickerSlice.actions;
export const DatePickerReducer = datePickerSlice.reducer;
