import axios from "axios";
import { authHeader } from "./ApiConfig";

const getMediaThumbnail = async (projectId, attachmentId) => {
    return await axios({
        method: 'get',
        baseURL: `${process.env.REACT_APP_API_URL}/media/${projectId}/thumbnail/${attachmentId}`,
        timeout: 30000,
        headers: {
          ...authHeader(),
        },
        responseType: 'blob'
      });
}

export {
  getMediaThumbnail
}
