import React, { memo, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// Constants
import { EVENT_KEY_LISTENER } from "constants/Common";
import { MAP_STYLE, ZOOM_MAP } from "constants/LocationMap";

const AzureMaps = (props) => {
  const atlas = window.atlas;
  const mapRef = useRef(null);
  const { dataAttach = [], isMultiple = true } = props;
  const [coordinates, setCoordinates] = useState([]);

  const getCoordinates = () => {
    if (dataAttach.length === 0) return;
    const locations = [];
    dataAttach.forEach((item) =>
      locations.push([item.longitude, item.latitude])
    );
    return locations;
  };

  const convertToCoordinatesAzure = (data) =>
    data.map((item) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [item[0], item[1]],
      },
    }));

  const removeElement = (classNames) => {
    classNames.forEach((item) => {
      const ele = document.querySelectorAll(`.${item}`);
      ele.length > 1 && ele[0].remove();
    });
  };

  const removeAllElementMap = () =>
    removeElement([
      "mapboxgl-canvas-container",
      "mapboxgl-control-container",
      "atlas-control-container",
      "mapboxgl-canary",
    ]);

  const getMap = () => {
    const element = mapRef.current;
    const map = new atlas.Map(element, {
      view: "Auto",
      //Add authentication details for connecting to Azure Maps.
      authOptions: {
        authType: "subscriptionKey",
        subscriptionKey: process.env.REACT_APP_AZURE_MAP_KEY,
      },
      style: localStorage.getItem("mapStyle"),
    });

    const cameraSetting =
      coordinates.length > 0
        ? {
            bounds: atlas.data.BoundingBox.fromData({
              type: "FeatureCollection",
              features: convertToCoordinatesAzure(coordinates),
            }),
            padding: 50,
          }
        : {
            center: coordinates[0],
            zoom: ZOOM_MAP.zoom_10,
          };

    map.setCamera(cameraSetting);

    if (!isMultiple) {
      map.setCamera({
        zoom: ZOOM_MAP.zoom_15,
      });

      map.controls.add(
        [
          new atlas.control.StyleControl({
            mapStyles: MAP_STYLE.all,
          }),
          new atlas.control.ZoomControl(),
        ],
        {
          position: "top-right",
        }
      );

      map.controls.add(
        [
          new atlas.control.CompassControl({
            rotationDegreesDelta: 10,
            style: "dark",
          }),
          new atlas.control.PitchControl({
            pitchDegreesDelta: 5,
            style: "dark",
          }),
          new atlas.control.TrafficControl({
            incidents: true,
          }),
        ],
        {
          position: "bottom-right",
        }
      );
      map.events.add("styledata", () => {
        localStorage.setItem("mapStyle", map.getStyle().style);
      });
      removeAllElementMap();
    }
    map.events.add(EVENT_KEY_LISTENER.ready, () => {
      const markers = [];
      coordinates.forEach((coordinate) => {
        const marker = new atlas.HtmlMarker({
          position: coordinate,
          color: "red",
        });
        markers.push(marker);
      });
      //Clear the markers that are on the map and add the new set of markers.
      map.markers.clear();
      map.markers.add(markers);
    });
  };

  useEffect(() => {
    getMap();
  }, [coordinates]);

  useEffect(() => {
    setCoordinates(getCoordinates());
  }, []);

  return (
    <div
      id="previewMap"
      className="azure-map"
      ref={mapRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    />
  );
};

AzureMaps.propTypes = {
  dataAttach: PropTypes.array,
  isMultiple: PropTypes.bool,
};

export default memo(AzureMaps);
