import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./HeatMapCell.module.scss";
import clsx from "clsx";

export const HeatMapCell = (props) => {
  const {
    heatMapColor,
    count,
    onClickCell,
    members = { firstMember: "", secondMember: "" },
  } = props;

  return (
    <>
      <td
        style={{
          background: `${
            count === -1
              ? "url('/images/empty-box-icon.svg')"
              : `${heatMapColor.bgColor}`
          }`,
          color: `${heatMapColor.color}`,
        }}
        className={clsx(
          styles["heatmap-cell"],
          styles[count <= 0 && "heatmap-cell-disable"]
        )}
        onClick={onClickCell}
      >
        <div className={styles["heatmap-cell-info"]}>
          <b className={styles["count-msg"]}>{count > 0 && count}</b>
          <span className={styles[count > 0 && "total-msg"]}>
            <p>{members.firstMember}</p>
            <p>{members.secondMember}</p>
            Total messages: {count === -1 ? 0 : count}
          </span>
        </div>
      </td>
    </>
  );
};

HeatMapCell.propTypes = {
  heatMapColor: PropTypes.object,
  count: PropTypes.any,
  members: PropTypes.shape({
    firstMember: PropTypes.string,
    secondMember: PropTypes.string,
  }),
  onClickCell: PropTypes.func,
};
