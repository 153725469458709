import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./ErrorInline.module.scss";

const ErrorInline = ({ errorMsg = "", touched = false }) => {
  return (
    errorMsg &&
    touched && <span className={styles["error-message"]}>{errorMsg}</span>
  );
};

ErrorInline.propTypes = {
  errorMsg: PropTypes.string,
  touched: PropTypes.bool,
};

export default ErrorInline;
