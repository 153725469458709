import React from "react";
import PropTypes from "prop-types";
import { FadeLoader } from "react-spinners";

// Constants
import { COLORS } from "constants/Common";

// Styles
import styles from "./StatusView.module.scss";

const StatusView = ({ loading, status }) => {
  return (
    <div
      style={{
        borderColor: status.borderColor,
        backgroundColor: status.backgroundColor,
      }}
      className={styles["wrap"]}
    >
      <span>{status.name}</span>
      {loading && (
        <FadeLoader
          className={styles["loading"]}
          color={COLORS.blue}
          height={6}
          width={2}
          margin={-10}
          speedMultiplier={1}
        />
      )}
    </div>
  );
};

StatusView.propTypes = {
  loading: PropTypes.bool,
  status: PropTypes.shape({
    name: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  }),
};

export default StatusView;
