import { createSlice } from "@reduxjs/toolkit";

const avatarSlice = createSlice({
  name: "avatar",
  initialState: {
    entityUrls: [], // id, url
    userUrls: [], // id, url
    fetchComplete: false
  },
  reducers: {
    addEntityAvatarUrl: (state, action) => {
      state.entityUrls = [...state.entityUrls, action.payload];
    },
    addUserAvatarUrl: (state, action) => {
      state.userUrls = [...state.userUrls, action.payload];
    },
    removeUserAvatarUrl: (state, action) => {
      state.userUrls = state.userUrls?.filter((item) => item.id !== action.payload);
    },
    setEntityAvatarUrls: (state, action) => {
      state.entityUrls = action.payload;
    },
    removeEntityAvatarUrl: (state, action) => {
      state.entityUrls = state.entityUrls?.filter((item) => item.id !== action.payload);
    },
    setFetchAvatarComplete: (state, action) => {
      state.fetchComplete = action.payload;
    },
  },
});

export const {
  addEntityAvatarUrl,
  addUserAvatarUrl,
  removeUserAvatarUrl,
  setEntityAvatarUrls,
  removeEntityAvatarUrl,
  setFetchAvatarComplete,
} = avatarSlice.actions;
export const AvatarReducer = avatarSlice.reducer;
