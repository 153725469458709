import axios, { authHeader, getAbortController } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const getManageSearch = (projectId, params = {}) => {
  const {
    Search,
    PaginationParams: { PageSize, PageNumber, ColumnSort, OrderBy },
  } = params;
  return axios.get(`/manageSearch/${projectId}`, {
    headers: authHeader(),
    params: {
      Search,
      "PaginationParams.PageSize": PageSize,
      "PaginationParams.PageNumber": PageNumber,
      "PaginationParams.ColumnSort": ColumnSort,
      "PaginationParams.OrderBy": OrderBy,
    },
    signal: getAbortController().signal,
  });
};

const getManageSearchDetail = (projectId, searchId) =>
  axios.get(`/manageSearch/${projectId}/${searchId}`, {
    headers: authHeader(),
  });

const updateSearchName = (projectId, searchId, params = {}) =>
  axios(`/manageSearch/${projectId}/${searchId}/fastEdit`, {
    method: METHOD_API.put,
    headers: authHeader(),
    params,
  });

const getCombineSearch = (projectId, params) =>
  axios(`/manageSearch/${projectId}/combineSearch`, {
    method: METHOD_API.post,
    headers: authHeader(),
    data: params,
  });

const deleteSavedSearch = (projectId, searchID) =>
  axios(`/manageSearch/${projectId}/${searchID}`, {
    method: METHOD_API.delete,
    headers: authHeader(),
  });

export {
  getManageSearch,
  getManageSearchDetail,
  updateSearchName,
  getCombineSearch,
  deleteSavedSearch,
};
