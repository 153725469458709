import axios, { authHeader } from "./ApiConfig";

const applyFilterTag = async (matterId, data) => {
    return await axios.post(`tag/${matterId}/filterTag`, 
    data, {
        headers: authHeader(),
    });
};

const applyFilterUntag = async (matterId, data) => {
    return await axios.post(`tag/${matterId}/filterUntag`, 
    data, {
        headers: authHeader(),
    });
};

export {
    applyFilterTag,
    applyFilterUntag
};