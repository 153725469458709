import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Styles
import styles from "./Tags.module.scss";

const Tags = ({
  isLoading,
  isPreviewLoading,
  tagList,
  onSelectTags
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [updateTags, setUpdateTags] = useState(false);
  const [negateTagQuery, setNegateTagQuery] = useState(false);

  const {
    filteredTags,
  } = useSelector((state) => state.generalRV);

  const notTagged = {
    tagID: -1,
    name: 'Select Not Tagged',
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (tagID) => {
    setNegateTagQuery(false);
  
    const isNotTaggedSelected = tagID === notTagged.tagID;
    const isCurrentlySelected = selectedTags.includes(tagID);
  
    if (isNotTaggedSelected && !isCurrentlySelected) {
      setSelectedTags([tagID]);
      setNegateTagQuery(true);
    } else {
      setSelectedTags((prevSelectedTags) => {
        var updatedTags = prevSelectedTags;

        if (selectedTags.includes(notTagged.tagID))
          updatedTags = updatedTags.filter((id) => id !== notTagged.tagID);
  
        return isCurrentlySelected
          ? updatedTags.filter((id) => id !== tagID)
          : [...updatedTags, tagID];
      });
    }
  
    setUpdateTags(true);
  };  

  useEffect(() => {
    if (updateTags) {
      onSelectTags(selectedTags, negateTagQuery);
      setUpdateTags(false);
    }
  }, [selectedTags]);

  useEffect(() => {
    setSelectedTags(filteredTags);
  }, [filteredTags]);

  const renderTags = (tags) => {
    const tagsWithDefault = [notTagged, ...tags];
    return tagsWithDefault.map((tag) => (
      <div key={tag.tagID} className={clsx(styles["tag-item"])}>
        <input
          type="checkbox"
          className={clsx(styles["tag-checkbox"])}
          value={tag.tagID}
          id={`tagCheckbox_${tag.tagID}`}
          checked={selectedTags.includes(tag.tagID)}
          onChange={() => handleCheckboxChange(tag.tagID)}
          disabled={isPreviewLoading}
        />
        {tag.tagID === -1 ? (
          <span className={clsx("e-icons e-circle-remove", clsx(styles["not-tagged"]))}></span>
        ) : (
          <span
            className={clsx(styles["tag-color"])}
            style={{ backgroundColor: tag.color }}
            title={tag.name}
          />
        )}
        <label htmlFor={`tagCheckbox_${tag.tagID}`}>
          <span className={clsx(styles["tag-name"])}>
            {tag.name}
          </span>
        </label>
      </div>
    ));
  };  

  return (
    <>
      <div className={styles["tags-label"]}>
        <p className={styles["tags-title"]}>Tags</p>
        <img
          src="/images/icons/collapse-icon.svg"
          alt="collapse-icon"
          className={clsx(
            "cursor-pointer",
            styles[isOpen ? "collapse-icon" : "expand-icon"]
          )}
          onClick={handleToggle}
        />
      </div>
      {isOpen && (
        <>
          {!isLoading ? (
            <div className={clsx(styles["container"])}>
              {renderTags(tagList)}
            </div>
          ) : (
            <div className={styles["spinner-container"]}>
              <Spinner
                className={styles["spinner-loading"]}
                animation="border"
                variant="primary"
                role="status"
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

Tags.propTypes = {
  isLoading: PropTypes.bool,
  isPreviewLoading: PropTypes.bool,
  tagList: PropTypes.array,
  onSelectTags: PropTypes.func,
};

export default Tags;