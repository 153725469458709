import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useSelector } from "react-redux";
import lowerCase from "lodash/lowerCase";

//Constants
import { SEARCH_SELECTOR } from "constants/SearchSelect";

//Components
import { Button } from "components/shared/button/Button";
import SearchGlobal from "./search-global/SearchGlobal";

//Styles
import styles from "./SearchConfig.module.scss";

const SearchConfig = (props, ref) => {
  useImperativeHandle(
    ref,
    () => ({
      handleClearSearch,
    }),
    []
  );

  const searchCompRef = useRef("");

  const { isData: iSData, queriesRemoved } = useSelector((state) => state.is);
  const { globalSearch } = iSData;

  const [canUpdate, setCanUpdate] = useState(false);
  const [currentQueries, setCurrentQueries] = useState([]);

  const handleClearSearch = () => {
    searchCompRef.current.handleClearSearch();
  };

  const handleUndoRemoveQueries = () => {
    searchCompRef.current.handleUndoRemove();
  };

  const handleUpdateQueries = () => {
    searchCompRef.current.handleUpdateData();
  };

  const handleChangeQueries = (queries) => {
    setCurrentQueries([...queries]);
    if (queriesRemoved) {
      setCanUpdate(true);
    }
    else {
      let isCanUpdate = true;
      queries.forEach((item) => {
        if (
          (!item.keyword.trim() &&
            lowerCase(item?.method) !==
            lowerCase(SEARCH_SELECTOR.method.nested)) ||
          (lowerCase(item?.method) ===
            lowerCase(SEARCH_SELECTOR.method.within) &&
            !item?.withinWords.trim())
        )
          isCanUpdate = false;
        item.nested?.length > 0 &&
          item.nested.forEach((childItem) => {
            if (
              (!childItem.keyword.trim() &&
                lowerCase(childItem.method) !==
                lowerCase(SEARCH_SELECTOR.method.nested)) ||
              (lowerCase(childItem?.method) ===
                lowerCase(SEARCH_SELECTOR.method.within) &&
                !childItem?.withinWords.trim())
            )
              isCanUpdate = false;
            childItem.nested?.length > 0 &&
              childItem.nested.forEach((grandItem) => {
                if (
                  !grandItem.keyword.trim() ||
                  (lowerCase(grandItem?.method) ===
                    lowerCase(SEARCH_SELECTOR.method.within) &&
                    !grandItem?.withinWords.trim())
                )
                  isCanUpdate = false;
              });
          });
      });
      setCanUpdate(isCanUpdate);
    }
  };

  const isDisabledUpdate = () => {
    const originalData =
      iSData.globalSearch?.length === 0 &&
      currentQueries?.length === 1 && !currentQueries[0].keyword;
    return (
      !canUpdate ||
      JSON.stringify(currentQueries) ===
      JSON.stringify(iSData.globalSearch) ||
      originalData
    );
  };

  return (
    <div className={styles["config-search"]}>
      <div className={styles["cs-header"]}>
        <h3>Search Term</h3>
        <div className={styles["cs-buttons"]}>
          <Button
            className="btn-secondary"
            name="Undo"
            isDisabled={!queriesRemoved}
            handleClick={handleUndoRemoveQueries}
          />
          <Button
            className="btn-secondary"
            name="Update"
            handleClick={handleUpdateQueries}
            isDisabled={isDisabledUpdate()}
          />
        </div>
      </div>
      <div className={styles["cs-body"]}>
        <SearchGlobal
          ref={searchCompRef}
          globalQueries={globalSearch}
          onChangeQueries={handleChangeQueries}
        />
      </div>
    </div>
  );
};

export default forwardRef(SearchConfig);
