import React from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Helpers
import { getProjectName } from "helpers/GetFileNameHelper";

// Constants
import { PATH_NAME } from "constants/Common";

// Styles
import styles from "./BreadCrumb.module.scss";
import clsx from "clsx";

const BreadCrumb = (props) => {
  const { 
    goBack, 
    breadCrumbData = [],
    handleBackBreadCrumb = () => {},
    isPopup = false,
  } = props;
  const { projectId } = useParams();
  const navigate = useNavigate();

  // Get project list from store
  const projectList = useSelector((state) => state.projects.projects) || [];

  const data = [
    { value: PATH_NAME.matters, label: "Matter List" }, 
    { value: projectId, label: getProjectName(projectList, projectId) }, 
    ...breadCrumbData
  ];

  const handleNavigate = (item) => {
    if (item.value === PATH_NAME.matters) navigate(`/${PATH_NAME.matters}`);
    else if (item.value === projectId)
      navigate(`/${PATH_NAME.matters}/${projectId}`);
    else
      navigate(
        `/${PATH_NAME.matters}/${projectId}/${item.value?.toLowerCase()}`
      );
  };

  const handleNavigateBreadCrumb = (item) => handleBackBreadCrumb(item);

  return (
    <div className={styles["bread-crumb-container"]}>
      <img
        onClick={goBack}
        className={styles["icon"]}
        src="/images/circle-left-arrow.svg"
        alt="Back Icon"
      />
      <div className={styles["bread-crumb"]}>
        {data?.map((item, index) => {
          return (
            <span
              onClick={() =>
                isPopup ? handleNavigateBreadCrumb(item) : handleNavigate(item)
              }
              key={index}
              className={clsx(
                index === data?.length - 1
                  ? styles["bread-crumb-disable"]
                  : styles["bread-crumb-active"],
                styles["bread-crumb-item"]
              )}
              hidden={item.value === projectId} // TODO: hide breadcrumb Matter Name
            >
              {item?.label}
              {index < data?.length - 1 && <span>{">"}</span>}
            </span>
          );
        })}
      </div>
    </div>
  );
};

BreadCrumb.propTypes = {
  breadCrumbData: PropTypes.any,
  isPopup: PropTypes.bool,
  goBack: PropTypes.func,
  handleBackBreadCrumb: PropTypes.func,
};

export default BreadCrumb;
