import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import clsx from "clsx";
import { useToken } from "hook/auth";
import { useSelector } from "react-redux";

// Services
import {
  getManageUser,
  deleteUser,
  createUser,
  editUser,
} from "services/UserAdministrationService";

// Constants
import {
  DATE_TIME_TYPE,
  SCREEN_WIDTH_3839,
  SORT_BY,
} from "constants/Constants";
import { COMMON_TEXT } from "constants/Common";
import { USER_STATUS, COL_TABLE_UA, AUTH_ROLE } from "constants/AuthConstant";

// Components
import { PaginationResult } from "components/shared/paging/PaginationResult";
import { Button } from "components/shared/button/Button";
import { CreateUserModal } from "components/user-administration/create-user-modal/CreateUserModal";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import { EditUserModal } from "components/user-administration/edit-user-modal/EditUserModal";
import { Loading } from "components/shared/loading/Loading";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { trimObject } from "helpers/ObjectHelper";

// Store
import { removeUserAvatarUrl } from "store/AvatarReducer";

//styles
import styles from "./UserAdministration.module.scss";

export const UserAdministration = () => {
  const inputRef = useRef();
  const { isClientAdmin } = useToken();
  const dispatch = useDispatch();

  const initSort = [
    { type: COL_TABLE_UA.userName, isAcs: true },
    { type: COL_TABLE_UA.email, isAcs: true },
    { type: COL_TABLE_UA.phone, isAcs: true },
    { type: COL_TABLE_UA.project, isAcs: true },
    { type: COL_TABLE_UA.dateActive, isAcs: true },
    { type: COL_TABLE_UA.status, isAcs: true },
  ];

  const itemPerPage = {
    lg: 20,
    md: 10,
  };

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [perPage] = useState(
    window.innerWidth > SCREEN_WIDTH_3839 ? itemPerPage.lg : itemPerPage.md
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [reloading, setReloading] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [userId, setUserId] = useState("");
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [errors, setErrors] = useState("");
  const [sortColumns, setSortColumns] = useState(initSort);
  const [sortParams, setSortParams] = useState({
    ColumnSort: null,
    OrderBy: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [keyWordSearch, setKeyWordSearch] = useState("");

  const reRender = () => setReloading(!reloading);

  //get user from store
  const userIDStore = useSelector((state) => state.user.userInfo.id);

  const onSortTable = (columnName) => {
    const obj = sortColumns.find((col) => col.type === columnName);
    obj.isAcs = !obj.isAcs;
    setSortColumns([...initSort.filter((col) => col.type !== columnName), obj]);
    setSortParams({
      ColumnSort: obj.type,
      OrderBy: obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
    });
    reRender();
  };

  const classSort = (columnName) => {
    const obj = sortColumns.find((col) => col.type === columnName);
    return `sort ${obj?.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  const fetchManageUserData = async () => {
    setLoading(true);
    const params = {
      Search: keyWordSearch,
      PaginationParams: {
        ...sortParams,
        PageSize: perPage,
        PageNumber: currentPage,
      },
    };
    try {
      const dataResult = await getManageUser(params);
      setUserData(dataResult.data.items);
      setSuggestions(dataResult.data.selectName);
      setTotalRecord(dataResult.data.totalRecords);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onSearchHandle = () => {
    setKeyWordSearch(inputRef.current?.value?.trim() || "");
    setCurrentPage(1);
    setIsSearch(true);
    reRender();
  };

  const renderStatus = (status) =>
    status === USER_STATUS.pending ? (
      <span className={styles["status-pending"]}>Pending</span>
    ) : (
      <span className={styles["status-activated"]}>Activated</span>
    );

  const renderAssignedProject = (project, index) => {
    if (project?.length > 1) {
      return (
        <td className={styles["td-project"]}>
          <div className={styles["assigned-project"]}>
            <span>{project.length} Matters</span>
            <div
              className={clsx(
                styles["project-description"],
                project.length > 2 && index > userData?.length - 3
                  ? styles["project-description-rev"]
                  : ""
              )}
            >
              {project.map((item) => (
                <p key={item.projectId}>{item.projectName}</p>
              ))}
            </div>
          </div>
        </td>
      );
    }

    if (project?.length === 0) {
      return (
        <td>
          <span> - </span>
        </td>
      );
    }

    return project.map((item) => (
      <td key={item.projectId}>
        <span title={item.projectName}>
          {item.projectName.length > 30
            ? `${item.projectName.slice(0, 29)}...`
            : item.projectName}
        </span>
      </td>
    ));
  };

  //handle submit create
  const handleCreateUser = async (params) => {
    setIsLoading(true);
    try {
      const result = await createUser(trimObject(params));
      if (!result) return;
      setShowPopupCreate(false);
      toast.success("User created successfully");
      setErrors("");
      if (currentPage === 1) reRender();
      else setCurrentPage(1);
    } catch (error) {
      console.log(error);
      setErrors(error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  //show modal edit
  const handleShowEditDialog = (userId) => {
    setUserId(userId);
    setShowPopupEdit(true);
  };

  //handle save edit
  const handleEditUser = async (params) => {
    setIsLoading(true);
    try {
      const result = await editUser(userId, trimObject(params));
      if (!result) return;
      setShowPopupEdit(false);
      toast.success("User updated successfully");
      setErrors("");
      dispatch(removeUserAvatarUrl(userId));
      reRender();
    } catch (error) {
      console.log(error);
      setErrors(error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowPopupCreate(false);
    setShowPopupEdit(false);
    setErrors("");
  };

  //show modal delete
  const handleShowDeleteDialog = (userId) => {
    setUserId(userId);
    setShowConfirmDelete(true);
  };

  //handle confirm delete
  const onHandleDeleteUser = async () => {
    setShowConfirmDelete(false);
    setIsLoading(true);
    try {
      const result = await deleteUser(userId);
      if (!result) return;
      if (currentPage !== 1 && userData.length % perPage === 1) {
        setCurrentPage(currentPage - 1);
      }
      reRender();
      toast.success("User deleted successfully");
    } catch {
      setShowConfirmDelete(false);
      toast.error("User deleted failed");
    } finally {
      setIsLoading(false);
    }
  };

  const getPhoneNumber = (item) => {
    if (!item.areaCode && !item.phoneNumber) return COMMON_TEXT.unknown;
    const areaCode = item.areaCode ? `(${item.areaCode})` : "";
    const phoneNumber = item.phoneNumber || "";
    return `${areaCode} ${phoneNumber}`;
  };

  useEffect(() => {
    fetchManageUserData();
  }, [currentPage, reloading]);

  return (
    <div className={clsx(styles["user-administration-page"], "main")}>
      <Loading loading={isLoading} isBlur />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <h2 className={styles["user-administration-header"]}>
            User Administration
          </h2>
        </div>
        <div className="d-flex align-items-center">
          <Button
            name="Create User"
            iconUrl="/images/icon-user.svg"
            altIcon="Create User"
            className="btn-primary"
            handleClick={() => setShowPopupCreate(true)}
          />
          {showPopupCreate && (
            <CreateUserModal
              handleClose={handleCloseModal}
              isShow={showPopupCreate}
              handleSubmit={handleCreateUser}
              errors={errors}
              onClearErrorText={() => {
                setErrors("");
              }}
            />
          )}
          {showPopupEdit && (
            <EditUserModal
              handleClose={handleCloseModal}
              isShow={showPopupEdit}
              handleSubmit={handleEditUser}
              userId={userId}
              errors={errors}
              onClearErrorText={() => {
                setErrors("");
              }}
            />
          )}
          <PopupConfirm
            isShow={showConfirmDelete}
            handleClose={() => setShowConfirmDelete(false)}
            handleSubmit={onHandleDeleteUser}
            content="Are you sure you want to delete this user?"
            textConfirm="Remove"
            type="remove"
            isDeleteUser
          />
        </div>
      </div>
      <div className={styles["user-administration-body"]}>
        <div className="d-flex justify-content-between align-items-center">
          <div className={styles["count-user"]}>
            {userData?.length > 0 && (
              <span>
                {Number(totalRecord)} User{totalRecord > 1 ? "s" : ""}
              </span>
            )}
          </div>
          {suggestions?.length > 0 && (
            <SearchInputGeneral
              inputRef={inputRef}
              placeholder="Search Users"
              name="search"
              onSubmitSearch={onSearchHandle}
              suggestions={suggestions}
              isAutoComplete={true}
            />
          )}
        </div>
        {loading ? (
          <div className={styles["disable-hover"]}>
            <Spinner
              className={styles["loading"]}
              animation="border"
              variant="primary"
            />
          </div>
        ) : (
          <>
            {userData?.length > 0 ? (
              <>
                <div className={styles["table-user-administration"]}>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <a
                            className={classSort(COL_TABLE_UA.userName)}
                            onClick={() =>
                              onSortTable(
                                COL_TABLE_UA.userName,
                                sortColumns.userName
                              )
                            }
                          >
                            &nbsp;Display Name
                          </a>
                        </th>
                        <th>
                          <a
                            className={classSort(COL_TABLE_UA.email)}
                            onClick={() => onSortTable(COL_TABLE_UA.email)}
                          >
                            &nbsp;Username/eMail
                          </a>
                        </th>
                        <th>Phone Number</th>
                        <th>
                          <a
                            className={classSort(COL_TABLE_UA.project)}
                            onClick={() => onSortTable(COL_TABLE_UA.project)}
                          >
                            &nbsp;Assigned Matter
                          </a>
                        </th>
                        <th>
                          <a
                            className={classSort(COL_TABLE_UA.dateActive)}
                            onClick={() => onSortTable(COL_TABLE_UA.dateActive)}
                          >
                            &nbsp;Date Active
                          </a>
                        </th>
                        <th>
                          <a
                            className={classSort(COL_TABLE_UA.status)}
                            onClick={() => onSortTable(COL_TABLE_UA.status)}
                          >
                            &nbsp;Status
                          </a>
                        </th>
                        <th>
                          <span>Role</span>
                        </th>
                        <th>
                          <span>Action</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {userData?.length > 0 &&
                        userData.map((item, index) => (
                          <tr key={item.id}>
                            <td title={`${item.firstName} ${item.lastName}`}>
                              {`${item.firstName} ${item.lastName}`}
                            </td>
                            <td title={item.email}>{item.email}</td>
                            <td title={getPhoneNumber(item)}>
                              {getPhoneNumber(item)}
                            </td>
                            {renderAssignedProject(item.projects, index)}
                            <td>
                              {item.dateActive !== null ? (
                                <>
                                  {formatDateTime({
                                    dateTime: item.dateActive,
                                    type: DATE_TIME_TYPE.MM_DD_YYYY,
                                  })}
                                </>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td>{renderStatus(item.status)}</td>
                            <td>{item.roles}</td>
                            <td>
                              <div
                                hidden={
                                  (isClientAdmin() &&
                                    item.roles[0] === AUTH_ROLE.admin) ||
                                  item.id === userIDStore
                                }
                              >
                                <span
                                  className={styles.action}
                                  onClick={() => handleShowEditDialog(item.id)}
                                >
                                  Edit
                                </span>
                                <span
                                  className={styles.action}
                                  onClick={() =>
                                    handleShowDeleteDialog(item.id)
                                  }
                                >
                                  Delete
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <PaginationResult
                  perPage={perPage}
                  forcePage={currentPage - 1}
                  totalRecord={Number(totalRecord)}
                  pageItems={userData?.length}
                  handlePagingClick={(e) => setCurrentPage(e.selected + 1)}
                  isSmall
                />
              </>
            ) : (
              <div className={styles["no-result"]}>
                <EmptyPage
                  messages={
                    isSearch
                      ? "No results found. Please try again."
                      : `You don't have any user yet.`
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
