import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

// Constants
import { PROJECT_CONSTANT } from "constants/ProjectConstant";
import { PATH_NAME } from "constants/Common";

// Helpers
import { getTimeFromNow } from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./LastActivity.module.scss";

const LastActivity = (props) => {
  const {
    logoUrl,
    name,
    title,
    content,
    postTime,
    className = "",
    altLogo = "",
    projectId,
    parentComp,
    isDisabled = true,
  } = props;

  const toProjectDetail = (e) => {
    //Scroll Top when no projectId
    if (!projectId) e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
    localStorage.setItem(PROJECT_CONSTANT.projectDetail, parentComp || "");
  };

  const component = (children) =>
    isDisabled ? (
      <span>{children}</span>
    ) : (
      <Link
        to={projectId ? `/${PATH_NAME.matters}/${projectId}` : ""}
        onClick={(e) => toProjectDetail(e)}
      >
        {children}
      </Link>
    );

  return (
    <div className={clsx(styles["last-activity"], className)}>
      {component(
        <Fragment>
          <div className={styles["last-activity__ttl"]}>
            <figure>
              <img src={logoUrl} alt={altLogo} />
            </figure>
            <span title={name}>{name}</span>
          </div>
          <div className={styles["last-activity__content"]}>
            <p className={styles["last-activity__content-title"]}>{title}</p>
            <p
              className={styles["last-activity__content-detail"]}
              title={content}
            >
              {content}
            </p>
          </div>
          <p className={styles["last-activity__post-time"]}>
            {getTimeFromNow(postTime)}
          </p>
        </Fragment>
      )}
    </div>
  );
};

LastActivity.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  link: PropTypes.string,
  postTime: PropTypes.string,
  className: PropTypes.string,
  altLogo: PropTypes.string,
  parentComp: PropTypes.string,
  projectId: PropTypes.number,
  isDisabled: PropTypes.bool,
};

export default LastActivity;
