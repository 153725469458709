import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./PreviewNotAvailable.module.scss";

const PreviewNotAvailable = ({ message }) => {
  return (
    <p className={styles["preview-not-available"]}>
      {message || "Preview not available for this type"}
    </p>
  );
};

PreviewNotAvailable.propTypes = {
  message: PropTypes.string,
};

export default PreviewNotAvailable;
