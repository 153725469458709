// constants
import { TYPE_CLICK, PAGINATION } from "constants/Common";

export const nextRecord = (record) => record + 1;
export const previousRecord = (record) => record - 1;

export const onSwitchRecord = (
  type,
  pageIndexCurrent,
  recordCurrent,
  totalRecordInPage,
  totalRecords
) => {
  let pageIndex = pageIndexCurrent;
  let recordNumber =
    type === TYPE_CLICK.previous
      ? previousRecord(recordCurrent)
      : nextRecord(recordCurrent);
  if (pageIndex < PAGINATION.firstIndexOnPage)
    return { recordNumber, pageIndex };
  if (recordNumber === totalRecordInPage) {
    if (pageIndex < Math.ceil(totalRecords / PAGINATION.recordPerPageTable)) {
      recordNumber = PAGINATION.firstIndexOnPage;
      pageIndex = nextRecord(pageIndex);
    } else recordNumber = previousRecord(recordNumber);
  }
  if (recordNumber === -1) {
    if (pageIndex > PAGINATION.firstIndexOnPage) {
      recordNumber = PAGINATION.lastIndexOnPage;
      pageIndex = previousRecord(pageIndex)
    } else recordNumber = PAGINATION.firstIndexOnPage;
  }
  return { recordNumber, pageIndex };
};

export const calcRecordNumber = (pageIndex, recordNumber) => {
  const record = pageIndex * PAGINATION.recordPerPageTable + recordNumber + 1;
  return record || null;
};
