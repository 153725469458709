import { React } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { logOut } from "services/AuthService";
import { useAuth0 } from "@auth0/auth0-react";

import "./styles.scss";

const UserProfileNav = ({ activeIndex }) => {
  const { logout } = useAuth0();

  const onLogOut = async () => {
    try {
      await logOut().then(() =>
        logout({ returnTo: `${window.location.origin}/login` })
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="user-profile__nav">
      <ul>
        <li>
          <Link
            to="/user-profile"
            className={`${
              activeIndex === 0 ? "active" : ""
            } user-profile__nav-item user-information my-3`}
          >
            User Information
          </Link>
        </li>
        <li>
          <Link
            to="/login"
            className="user-profile__nav-item log-out my-3"
            onClick={onLogOut}
          >
            Log Out
          </Link>
        </li>
      </ul>
    </div>
  );
};

UserProfileNav.propTypes = {
  activeIndex: PropTypes.number,
};

export { UserProfileNav };
