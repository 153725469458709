import { useEffect } from "react";
import { EVENT_KEY_LISTENER } from "constants/Common";

const useKeyDownListener = (callback, dependencies) => {
  useEffect(() => {
    const keydownListener = (event) => {
      callback(event);
    };

    window.addEventListener(EVENT_KEY_LISTENER.keydown, keydownListener, true);

    return () => {
      window.removeEventListener(
        EVENT_KEY_LISTENER.keydown,
        keydownListener,
        true
      );
    };
  }, dependencies);
};

export default useKeyDownListener;
