import { METHOD_API } from "constants/Common";
import axios, { authHeader, authHeaderFormData } from "./ApiConfig";

// Api get User Profile
const getUserProfileApi = () =>
  axios.get(`/user/profile`, {
    headers: authHeader(),
  });

// Api update User Profile
const updateUserProfile = (dataUpdateUserProfile) =>
  axios(`/user/profileUpdate`, {
    method: METHOD_API.post,
    headers: authHeader(),
    data: dataUpdateUserProfile,
  });

// Update avatar of User Profile
const updateImage = (imgUpdate) => {
  if (!imgUpdate) return;
  const formData = new FormData();
  formData.append("UpdateImage", imgUpdate);
  return axios.put(`/user/avatar`, formData, {
    headers: authHeaderFormData(),
  });
};

const getUserAvatar = () => {
  return axios.get('/user/avatar', {
    headers: authHeader(),
    responseType: "blob",
  });
};

export { getUserProfileApi, updateUserProfile, updateImage, getUserAvatar };
