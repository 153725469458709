const COLUMN_NAME = {
  identifier: {
    value: "identifier",
    label: "Identifiers",
  },
  sources: {
    value: "sourceCount",
    label: "Source(s)",
  },
  entity: {
    value: "entityName",
    label: "People",
  },
  preferredName: {
    value: "preferredName",
    label: "Preferred Name",
  },
  likelyName: {
    value: "likelyName",
    label: "Likely Name",
  },
  namesPercent: {
    value: "namesPercent",
    label: "Name(s)",
  },
  occurrences: {
    value: "occurrences",
    label: "Occurrences",
  },
  dateFirst: {
    value: "dateFirst",
    label: "Date First",
  },
  dateLast: {
    value: "dateLast",
    label: "Date Last",
  },
};

const IDENTIFIER_LIST = [
  {
    value: "2",
    label: "Phone Number",
    icon: "phone",
  },
  {
    value: "3",
    label: "Email Address",
    icon: "email",
  },
];

export { COLUMN_NAME, IDENTIFIER_LIST };
