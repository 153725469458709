import { React } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserInformation } from "components/user-profile/UserInformation";
import { UserProfileNav } from "components/user-profile/UserProfileNav";
import "components/user-profile/styles.scss";
import { PATHNAME_ITEMS } from "constants/Common";

const UserProfile = () => {
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.user.userInfo);

  const goBack = () => navigate(PATHNAME_ITEMS.matters);

  return (
    <div className="container-fluid user-profile">
      <div className="main">
        <div className="user-profile__header">
          <h2>Settings</h2>
        </div>
        <div className="row">
          <div className="col-12 col-lg-3 col-xl-3 pe-3">
            <UserProfileNav activeIndex={0} />
          </div>
          <div className="col-12 col-lg-9 col-xl-9 ps-4">
            <UserInformation goBack={goBack} userProfile={userProfile} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { UserProfile };
