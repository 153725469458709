import React from "react";
import PropTypes from "prop-types";

//helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoNetwork = ({ networkInfo }) => {
  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList?.map((item) => (
          <InfoResultItem
            key={item.label}
            label={item.label}
            value={getInformationByKey(item.key, networkInfo)}
            isIcon={item.key === PARAMETERS.typeLabel}
            type={networkInfo[PARAMETERS.type]}
            isReview={true}
          />
        ))}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.network)}</>;
};

InfoNetwork.propTypes = {
  networkInfo: PropTypes.object,
};

export default InfoNetwork;
