import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { CONVERSATION_ACTION, DATE_TIME_TYPE } from "constants/Constants";
import { PAGINATION } from "constants/Common";

export const onConvertMessages = (data = [], page = 0) => {
  if (!data) data = [];
  return data.map((item, index) => {
    const {
      instantMessageID,
      name,
      timeStamp,
      body,
      tags,
      attachments,
      identifier,
      source,
      label,
      order,
      subject,
      searchHits,
      searchTermHits
    } = item;
    return {
      id: page === 0 ? index + 1 : index + 1 + page * PAGINATION.perPage,
      instantMessageID,
      select: 0,
      name,
      date: formatDateTime({
        dateTime: timeStamp,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
      }),
      time: formatDateTime({ dateTime: timeStamp, type: DATE_TIME_TYPE.LT }),
      identifier,
      body,
      tags,
      attachments,
      timeStamp,
      source,
      label,
      order,
      subject,
      searchHits,
      searchTermHits
    };
  });
};

export const isCheckJoinLeave = (label) =>
  label === CONVERSATION_ACTION.leave || label === CONVERSATION_ACTION.join;
