const DATE_SELECTOR = {
  type: {
    unKnow: -1,
    before: 0,
    after: 1,
    between: 2,
  },
  timeFrame: {
    am: "AM",
    pm: "PM",
    start: "start",
    end: "end",
  },
};

export { DATE_SELECTOR };
