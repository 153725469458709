import React from "react";
import PropTypes from "prop-types";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

//Helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoNotes = ({ noteInfo }) => {
  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) => (
          <InfoResultItem
            key={item.label}
            label={item.label}
            value={getInformationByKey(item.key, noteInfo)}
            isIcon={item.key === PARAMETERS.typeLabel}
            type={noteInfo[PARAMETERS.type]}
          />
        ))}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.note)}</>;
};

InfoNotes.propTypes = {
  noteInfo: PropTypes.object,
};

export default InfoNotes;
