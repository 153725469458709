import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lowerCase } from "lodash";
import clsx from "clsx";

//Redux
import { setIsData } from "store/ISReducer";

//Constants
import {
  CONSOLIDATED_TYPE,
  CONSOLIDATE_QUERY_DEFAULT,
  DATE_TIME_TYPE,
} from "constants/Constants";
import { DATA_TYPE_LABEL } from "constants/DataType";
import { SEARCH_SELECTOR } from "constants/SearchSelect";

//Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { checkAndReplaceTagEquivalent } from "helpers/TextHelper";

// Components
import ConsolidatedQueryItem from "../consolidated-query-item/ConsolidatedQueryItem";
import { DateSelector } from "components/shared/date-picker/DateSelector";
import { Button } from "components/shared/button/Button";

// Styles
import styles from "./ConsolidatedQuery.module.scss";

const ConsolidatedQuery = () => {
  const dispatch = useDispatch();
  const {
    isData: {
      dataSources,
      entities,
      dataTypes,
      globalSearch,
    },
    queryType,
  } = useSelector((state) => state.is);

  const { dateTimeDataStart, dateTimeDataEnd } = useSelector(
    (state) => state.datePicker.datePicker
  );

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dataSourceQuery, setDataSourceQuery] = useState(null);
  const [entityQuery, setEntityQuery] = useState(null);
  const [dataTypeQuery, setDataTypeQuery] = useState(null);
  const [periodQuery, setPeriodQuery] = useState(null);
  const [globalQuery, setGlobalQuery] = useState(null);

  const SEARCH_INCLUDE = "This search will include ";

  const renderDateTimeQuery = (dateTime) =>
    renderStrongTag(
      formatDateTime({
        dateTime,
        type: DATE_TIME_TYPE.MM_DD_YYYY,
        addZulu: false,
      })
    );

  const handleDateTimeQuery = () => {
    let query = CONSOLIDATE_QUERY_DEFAULT;
    if (dateTimeDataStart && dateTimeDataEnd)
      query = `This search will have date range from ${renderDateTimeQuery(
        dateTimeDataStart
      )} to ${renderDateTimeQuery(dateTimeDataEnd)}`;
    else if (dateTimeDataStart)
      query = `This search will have date after ${renderDateTimeQuery(
        dateTimeDataStart
      )}`;
    else if (dateTimeDataEnd)
      query = `This search will have date before ${renderDateTimeQuery(
        dateTimeDataEnd
      )}`;
    setPeriodQuery(query);
  };

  const getGlobalQuery = async () => {
    for await (const rootItem of globalSearch) {
      getQueryNested(rootItem);
    }
    setGlobalQuery(query);
  };

  let query = "";
  const getQueryNested = (temp) => {
    if (!temp.nested) {
      if (temp.method) {
        query += ` ${lowerCase(temp.method)} `;
        if (temp.method === SEARCH_SELECTOR.method.within) {
          query += ` ${renderStrongTag(temp.withinWords)} words of `;
        }
      }
      query += renderStrongTag(checkAndReplaceTagEquivalent(temp.keyword));
    } else {
      query += " " + lowerCase(temp.nestedMethod);
      if (temp.nestedMethod === SEARCH_SELECTOR.method.within) {
        query += ` ${renderStrongTag(temp.withinWords)} words of `;
      }
      query += " ( ";
      temp.nested.forEach((nested) => getQueryNested(nested));
      query += " ) ";
    }
  };

  const renderStrongTag = (string) => `<strong>${string}</strong>`;

  const handleSubmitDate = (data) => {
    dispatch(
      setIsData({
        startDate: data.dateTimeDataStart,
        endDate: data.dateTimeDataEnd,
        typeFilter: data.typeFilter,
      })
    );
    setShowDatePicker(false);
  };

  // DATA SOURCES UPDATE QUERY
  useEffect(() => {
    if (!dataSources.length)
      return setDataSourceQuery(CONSOLIDATE_QUERY_DEFAULT);
    const value = dataSources.map((item) => `${item.custodianName}`).join(", ");
    setDataSourceQuery(
      `${SEARCH_INCLUDE} ${renderStrongTag(
        checkAndReplaceTagEquivalent(value)
      )}`
    );
  }, [dataSources]);

  // ENTITIES UPDATE QUERY
  useEffect(() => {
    if (!entities.length) return setEntityQuery(CONSOLIDATE_QUERY_DEFAULT);
    const entitiesSelected =
      queryType?.entities?.filter((item) => entities.includes(item.id)) || [];
    const value = entitiesSelected.map((item) => `${item.name}`).join(", ");
    setEntityQuery(
      `${SEARCH_INCLUDE} ${renderStrongTag(
        checkAndReplaceTagEquivalent(value)
      )}`
    );
  }, [entities, queryType?.entities]);

  // DATA TYPES UPDATE QUERY
  useEffect(() => {
    if (!dataTypes.length) return setDataTypeQuery(CONSOLIDATE_QUERY_DEFAULT);
    const value = dataTypes
      .map((item) => `${DATA_TYPE_LABEL[item?.toLowerCase()] || item}`)
      .join(", ");
    setDataTypeQuery(`${SEARCH_INCLUDE} ${renderStrongTag(value)}`);
  }, [dataTypes]);

  // DATE TIME QUERY
  useEffect(() => {
    handleDateTimeQuery();
  }, [dateTimeDataStart, dateTimeDataEnd]);

  // GLOBAL SEARCH UPDATE QUERY
  useEffect(() => {
    if (!globalSearch.length) return setGlobalQuery(CONSOLIDATE_QUERY_DEFAULT);
    getGlobalQuery();
  }, [globalSearch]);

  return (
    <div className={clsx(styles["container"], "d-flex flex-column")}>
      <DateSelector
        handleSubmit={handleSubmitDate}
        showDatePicker={showDatePicker}
        handleClose={() => setShowDatePicker(false)}
        data={{ startDate: dateTimeDataStart, endDate: dateTimeDataEnd }}
      />
      <div className={styles["header"]}>
        <div className={styles["title"]}>Consolidated Query</div>
        <div>
          <Button
            name="Add Date Filter"
            className="btn-add-date-filter"
            handleClick={() => setShowDatePicker(true)}
            iconUrl="/images/icons/calendar.svg"
            altIcon="Calendar"
            isBorder={false}
          />
        </div>
      </div>
      <div className={clsx(styles["consolidated-list"], "flex-grow-1")}>
        <div className={styles["consolidated-list-content"]}>
          <ConsolidatedQueryItem
            type={CONSOLIDATED_TYPE.source}
            query={dataSourceQuery}
          />
          <ConsolidatedQueryItem
            type={CONSOLIDATED_TYPE.entity}
            query={entityQuery}
          />
          <ConsolidatedQueryItem
            type={CONSOLIDATED_TYPE.datatype}
            query={dataTypeQuery}
          />
          <ConsolidatedQueryItem
            type={CONSOLIDATED_TYPE.date}
            query={periodQuery}
          />
          <ConsolidatedQueryItem
            type={CONSOLIDATED_TYPE.global}
            query={globalQuery}
          />
        </div>
      </div>
    </div>
  );
};

export default ConsolidatedQuery;
