export const handlePressHotKey = (event, tagsList = [], callback) => {
  if (!event) return;
  const haveHotKey = tagsList?.filter(
    (tag) => tag.hotKey?.toLowerCase() === event.key.toLowerCase()
  );
  if (!haveHotKey?.length) return;
  event.preventDefault();
  callback(
    event,
    event.keyCode,
    haveHotKey.map((item) => item.tagID)
  );
};
