import React from "react";
import PropTypes from "prop-types";

// Constants
import { COMMON_REGEX } from "constants/RegexConstant";

//Styles
import styles from "./SuggestionItem.module.scss";

const SuggestionItem = ({ data, input }) => {
  const escapeRegExp = (str = "") => str.replace(COMMON_REGEX.escape, "\\$1");
  const regex = new RegExp(`(${escapeRegExp(input)})`, "gi");
  const parts = data?.split(regex);

  if (!input) return <span className={styles["no-highlight"]}>{data}</span>;
  return (
    <span>
      {parts
        ?.filter((part) => part)
        .map((part, index) =>
          regex.test(part) ? (
            <span className={styles["highlight"]} key={index}>
              {part}
            </span>
          ) : (
            <span className={styles["no-highlight"]} key={index}>
              {part}
            </span>
          )
        )}
    </span>
  );
};

SuggestionItem.propTypes = {
  data: PropTypes.string,
  input: PropTypes.string,
};

export default SuggestionItem;
