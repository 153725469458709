import React, { Fragment, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Calendar } from "react-date-range";
import { enGB } from "react-date-range/dist/locale";
import { useOnClickOutside } from "hook/click-outside";

// Components
import ErrorInline from "components/shared/error-inline/ErrorInline";
import { TimePicker } from "components/shared/date-picker/TimePicker";
import { Button } from "components/shared/button/Button";

// Constants and Helpers
import { DATE_TIME, DATE_TIME_TYPE } from "constants/Constants";
import { formatDateTime, formatDateTimeWithOriginal } from "helpers/DateTimeFormatterHelper";
import { DATE_SELECTOR } from "constants/DateSelector";

// Styles
import styles from "./FieldDateSelector.module.scss";

const FieldDateSelector = (props) => {
  const { name, label, placeHolder, required = false, disabled = false, onChange } = props;

  const datePickerRef = useRef(null);
  const [formDate, setFormDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [timeFrame, setTimeFrame] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [displayDateSelect, setDisplayDateSelect] = useState({
    dateStart: null,
    timeStart: null,
    timeFrameStart: null
  });

  useOnClickOutside(datePickerRef, () => setShowDatePicker(false));

  const setDefaultTime = () => {
    setTimeFrame(DATE_SELECTOR.timeFrame.am);
    setMinute(0);
    setHour(0);
  }

  const normalizedDatesAreSame = (firstDate, secondDate) => {
    firstDate.setHours(0);
    secondDate.setHours(0);

    firstDate.setMinutes(0);
    secondDate.setMinutes(0);

    return firstDate.getTime() === secondDate.getTime();
  }

  const clearData = () => {
    setFormDate(null);
    setDisplayDateSelect({
      dateStart: null,
      timeStart: null,
      timeFrameStart: null
    });
    setHour(null);
    setMinute(null);
    setTimeFrame(null);
  }

  const onSelectCurrentDate = (date, form, field) => {
    if (formDate && normalizedDatesAreSame(date, formDate)) {
      form.setFieldValue(field.name, null);
      clearData();
      setShowDatePicker(false);
    } else {
      if (!hour || !minute || !timeFrame)
        setDefaultTime();
  
      if (date) {
        date.setHours(hour);
        date.setMinutes(minute);
      }
  
      form.setFieldValue(field.name, date);
      setFormDate(date);
    }
  };  

  const onSelectAmOrPm = (e, form, field) => {
    if (formDate) {
      const selectedTimeFrame = e.target.innerHTML === DATE_SELECTOR.timeFrame.am
        ? DATE_SELECTOR.timeFrame.am
        : DATE_SELECTOR.timeFrame.pm;
  
      setTimeFrame(selectedTimeFrame);
  
      const dateTime = formDate;
      if (dateTime) {
        dateTime.setHours(selectedTimeFrame === DATE_SELECTOR.timeFrame.pm ? hour + 12 : hour);
        form.setFieldValue(field.name, dateTime);
      }
    }
  };

  const onChangeHour = (e, form, field) => {
    if (formDate) {
      let dataParse = parseInt(e.target.value);
      dataParse = dataParse === 12 ? 0 : dataParse;
  
      setHour(dataParse);
  
      const dateTime = formDate;
      if (dateTime) {
        dateTime.setHours(timeFrame === DATE_SELECTOR.timeFrame.pm ? dataParse + 12 : dataParse);
        form.setFieldValue(field.name, dateTime);
      }
    }
  };
  
  const onChangeMinute = (e, form, field) => {
    if (formDate) {
      const dataParse = parseInt(e.target.value);
  
      setMinute(dataParse);
  
      const dateTime = formDate;
      if (dateTime) {
        dateTime.setMinutes(dataParse);
        form.setFieldValue(field.name, dateTime);
      }
    }
  };
  
  const handleSubmitDate = async () => {
    onChange && onChange(formDate, name);
    setShowDatePicker(false);
  }

  useEffect(() => {
    setDisplayDateSelect({
      dateStart: formDate,
      timeStart: `${hour}:${minute}`,
      timeFrameStart: timeFrame
    });
  }, [hour, minute, timeFrame, formDate]);

  useEffect(() => {
    setDisplayDateSelect({
      dateStart: formDate,
      timeStart: `${hour}:${minute}`,
      timeFrameStart: timeFrame
    });
  }, [showDatePicker]);

  return (
    <Fragment>
      <Field name={name}>
        {({ field, form, meta }) => (
          <div className={`${styles["field-container"]} ${disabled ? styles.disabled : ""}`}>
            <label>
              {label}
              {required && <span className="asterisk"> *</span>}
            </label>
            <input
              type="text"
              {...field}
              value={formatDateTime({
                dateTime: field.value,
                type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
                addZulu: false,
              })}
              placeholder={placeHolder}
              className={`${styles.input} ${meta.touched && meta.error ? styles["input-error"] : ""} ${disabled ? styles.disabled : ""}`}
              readOnly
              onClick={() => !disabled && setShowDatePicker(true)} // Only open the date picker if not disabled
            />
            <img
              src="/images/icons/calendar-blue-point.svg"
              alt="calender icon"
              className={`${styles["calender-icon"]} ${disabled ? styles.disabled : ""}`}
              onClick={() => !disabled && setShowDatePicker(true)} // Only open the date picker if not disabled
            />
            {meta.touched && meta.error && (
              <div className={styles["form-error"]}>
                <ErrorInline errorMsg={meta.error} touched={meta.touched} />
              </div>
            )}
            {showDatePicker && (
              <div className={styles["calendar-container"]} ref={datePickerRef}>
                <Calendar
                  date={field.value || null}
                  onChange={(date) => {
                    onSelectCurrentDate(date, form, field);
                  }}
                  locale={enGB}
                  minDate={new Date(DATE_TIME.minDate)}
                  showPreview={false}
                />
                <div className={styles["time-picker-control"]}>
                  <TimePicker
                    displayDateSelect={displayDateSelect}
                    selectAmOrPm={(e) => {onSelectAmOrPm(e, form, field)}}
                    onChangeHour={(e) => {onChangeHour(e, form, field)}}
                    onChangeMinute={(e) => {onChangeMinute(e, form, field)}}
                    initialDisplayDate={() => {}}
                  />
                  </div>
                  <div className={styles["select-date-container"]}>
                    <span className={styles["select-date-container__title"]}>Selected:</span>
                      <b className="pe-2">
                        {formatDateTime({
                          dateTime: displayDateSelect?.dateStart,
                          type: DATE_TIME_TYPE.MMM_DD_YYYY,
                          addZulu: false,
                        })}
                      </b>
                    <span className="pe-2">
                      {formatDateTimeWithOriginal({
                        dateTime: displayDateSelect?.timeStart,
                        original: DATE_TIME_TYPE.HHmm,
                        newFormat: DATE_TIME_TYPE.HHmm,
                        addZulu: false,
                      })}
                    </span>
                    {displayDateSelect?.timeFrameStart}
                    <div className={styles["save-btn"]}>
                    <Button 
                      name="Save"
                      className="btn-small-blue"
                      handleClick={handleSubmitDate}
                    />
                  </div>
            
                  </div>
                
              </div>
            )}
          </div>
        )}
      </Field>
    </Fragment>
  );
};

FieldDateSelector.propTypes = {
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default FieldDateSelector;