import axios, { authHeader } from "./ApiConfig";

const getAttachmentReviewApi = (instantMessageID, projectId) =>
  axios.get(
    `/communicationReview/${projectId}/attachment/${instantMessageID}`,
    {
      headers: authHeader(),
    }
  );

const getSuggestWord = (projectId, data = {}) =>
  axios.post(`/communicationReview/getSuggestWord/${projectId}`, data, {
    headers: authHeader(),
  });

export {
  getAttachmentReviewApi,
  getSuggestWord,
};
