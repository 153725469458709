import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";

// Components
import { Button } from "components/shared/button/Button";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import PrintExport from "components/shared/print-export/PrintExport";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import CreateEntityModal from "components/shared/create-entity-modal/CreateEntityModal";
import EntityCardList from "components/manage-entity/entity-card-list/EntityCardList";
import EntityTableView from "components/manage-entity/entity-table-view/EntityTableView";
import { Loading } from "components/shared/loading/Loading";

// Constants
import { PAGE_NAME, PRINT_SCREEN_NAME } from "constants/Common";
import {
  SCREEN_WIDTH_2559,
  SCREEN_WIDTH_3839,
  SORT_BY,
  TOTAL_ITEM,
} from "constants/Constants";
import { FILE_TYPE } from "constants/DataType";
import { ERROR_STATUS } from "constants/StatusCodeConstant";

//Helpers
import { trimObject } from "helpers/ObjectHelper";

// Services
import {
  createNewEntityApi,
  deleteEntityApi,
  editEntityApi,
  exportExcelEntityApi,
  getEntityApi,
  uploadAvatarApi,
} from "services/EntityManagementService";

// Styles
import styles from "./EntitiesManagement.module.scss";

const EntitiesManagement = () => {
  const { projectId } = useParams();
  const inputRef = useRef(null);

  const itemsInLargeScreen = 10;
  const itemsInMediumScreen = 8;
  const itemsInSmallScreen = 6;
  const perPageCard =
    window.innerWidth > SCREEN_WIDTH_3839
      ? itemsInLargeScreen
      : window.innerWidth > SCREEN_WIDTH_2559
      ? itemsInMediumScreen
      : itemsInSmallScreen;
  const perPageTable =
    window.innerWidth > SCREEN_WIDTH_3839
      ? TOTAL_ITEM.screen4K
      : TOTAL_ITEM.fullHD;
  const initData = {
    items: [],
    totalRecords: 0,
    selectName: [],
  };
  const paramSearchInit = {
    search: "",
    paginationParams: {
      pageSize: perPageTable,
      pageNumber: 1,
      columnSort: "createdTime",
      orderBy: SORT_BY.desc,
    },
  }

  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(initData);
  const [paramSearch, setParamSearch] = useState(paramSearchInit);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [listIdChecked, setListIdChecked] = useState([]);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [entityID, setEntityID] = useState();
  const [isTableActive, setIsTableActive] = useState(true);
  const [errorIdentifierList, setErrorIdentifierList] = useState([]);
  const [isLoadingCreate, setIsLoadingCreate] = useState(false);
  const [isFirstLoadComponent, setIsFirstLoadComponent] = useState(true);
  const [exportLoading, setExportLoading] = useState(false);

  const resetModal = () => {
    setShowEntityModal(false);
    setEntityID(null);
    setErrorIdentifierList([]);
  };

  const fetchEntity = async (params) => {
    setIsLoading(true);
    try {
      const result = await getEntityApi(projectId, params);
      const data = result.data;
      setEntityData({
        items: data.items.map((item) => ({
          ...item,
          // convert identifier becomes standard structure
          identifier: item.identifier
            ? JSON.parse(item.identifier).map((item) => ({
                id: item.ID,
                value: item.Value,
                socialType: item.SocialType,
              }))
            : [],
          // Adding checked field, handle to selecting entity
          checked: listIdChecked.includes(item.entityID) ? true : false,
        })),
        totalRecords: data.totalRecords,
        selectName: data.selectName,
      });
      setParamSearch(params);
    } catch (err) {
      console.log(err);
      setEntityData(initData);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagingEntity = (event) => {
    const params = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: event.selected + 1,
      },
    };
    setParamSearch(params);
    fetchEntity(params);
  };

  const handleSearchEntity = () => {
    const keyword = inputRef.current.value?.trim() || "";
    const params = {
      ...paramSearch,
      search: keyword,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: 1,
      },
    };
    setListIdChecked([]);
    setEntityID(null);
    updateChecked(false);
    fetchEntity(params);
  };

  const handleSortTable = (sortParam) => {
    const params = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        columnSort: sortParam.columnSort,
        orderBy: sortParam.orderBy,
      },
    };
    fetchEntity(params);
  };

  const updateChecked = (isChecked) =>
    setEntityData({
      ...entityData,
      items: entityData.items.map((item) => {
        return { ...item, checked: isChecked };
      }),
    });

  const handleDeleteEntity = async () => {
    try {
      setShowConfirmDelete(false);
      setShowEntityModal(false);
      setIsLoading(true);
      await deleteEntityApi(projectId, listIdChecked);
      let params = { ...paramSearch };
      if (
        entityData.items.length === listIdChecked.length &&
        paramSearch.paginationParams.pageNumber !== 1
      ) {
        params = {
          ...paramSearch,
          paginationParams: {
            ...paramSearch.paginationParams,
            pageNumber: paramSearch.paginationParams.pageNumber - 1,
          },
        };
      }
      setListIdChecked([]);
      setEntityID(null);
      updateChecked(false);
      fetchEntity(params);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSubmitForm = async (data) => {
    try {
      const trimParam = trimObject(data);
      setErrorIdentifierList([]);
      setIsLoadingCreate(true);
      setIsLoading(true);
      let result;
      let params = { ...paramSearch };
      const formData = new FormData();
      formData.append(FILE_TYPE.image, data.avatar);
      if (data.entityID) {
        result = await editEntityApi(projectId, data.entityID, trimParam);
        await uploadAvatarApi(projectId, data.entityID, formData);
      } else {
        result = await createNewEntityApi(projectId, trimParam);
        await uploadAvatarApi(projectId, result.data.entityID, formData);
        params = {
          ...paramSearch,
          paginationParams: {
            ...paramSearch.paginationParams,
            pageNumber: 1,
          },
        };
      }
      resetModal();
      fetchEntity(params);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response.status === ERROR_STATUS.badRequest)
        setErrorIdentifierList(error.response.data?.errorIdentifiers);
    } finally {
      setIsLoadingCreate(false);
    }
  };

  const handleExportToExcel = async () => {
    try {
      setExportLoading(true);
      const result = await exportExcelEntityApi(projectId, paramSearch);
      const url = window.URL.createObjectURL(result.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "People.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const handleEditEntity = (entityID) => {
    setShowEntityModal(true);
    setEntityID(entityID);
  };

  const handleSelectAllItems = (event) => {
    const { checked } = event.target;
    updateChecked(checked);
    setListIdChecked(
      checked
        ? [
            ...new Set([
              ...listIdChecked,
              ...entityData.items.map((item) => item.entityID),
            ]),
          ]
        : listIdChecked.filter(
            (entityID) =>
              entityData.items.findIndex(
                (item) => item.entityID === entityID
              ) === -1
          )
    );
  };

  const handleSelectEntity = (checked, id) => {
    // Update checked Id list
    if (checked) setListIdChecked([...listIdChecked, id]);
    else setListIdChecked((prev) => prev.filter((item) => item !== id));

    // Update checked for entity data
    setEntityData({
      ...entityData,
      items: entityData.items.map((item) =>
        item.entityID === id ? { ...item, checked } : item
      ),
    });
  };

  // Update avatar for entity
  const handleUploadAvatar = ({ url, entityID }) =>
    setEntityData({
      ...entityData,
      items: entityData.items.map((item) =>
        item.entityID === entityID ? { ...item, image: url } : item
      ),
    });

  const getIsSelectAll = () => {
    let totalSelectedInPage = 0;
    entityData.items.forEach((item) => {
      if (!listIdChecked.includes(item.entityID)) return false;
      else totalSelectedInPage++;
    });
    return totalSelectedInPage === entityData.items.length ? true : false;
  };

  useEffect(() => {
    const params = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: 1,
        pageSize: isTableActive ? perPageTable : perPageCard,
      },
    };
    fetchEntity(params);
    setIsFirstLoadComponent(false);
  }, [isTableActive]);

  useEffect(() => {
    if (!isFirstLoadComponent) {
      setListIdChecked([]);
      setParamSearch(paramSearchInit);
      fetchEntity(paramSearchInit);
    }
  }, [projectId]);

  return (
    <div className={clsx(styles["wrap"])}>
      <Loading loading={isLoadingCreate} isBlur />
      <PopupConfirm
        isShow={showConfirmDelete}
        handleClose={() => {
          setShowConfirmDelete(false);
          setListIdChecked([]);
          updateChecked(false);
        }}
        handleSubmit={handleDeleteEntity}
        content={`Are you sure you want to delete ${`${
          entityID ? "this" : listIdChecked.length
        }`} ${listIdChecked.length > 1 ? "People" : "Person"}?`}
        textConfirm="Delete"
        type="remove"
      />
      {showEntityModal && (
        <CreateEntityModal
          isShow={showEntityModal}
          onHide={resetModal}
          onSubmitForm={handleSubmitForm}
          entityID={entityID}
          handleDeleteEntity={() => {
            setShowConfirmDelete(true);
            setListIdChecked(entityID ? [entityID] : []);
          }}
          errorIdentifierList={errorIdentifierList}
          setErrorIdentifierList={setErrorIdentifierList}
          entityDataDefault={entityData.items.find(
            (entity) => entity.entityID === entityID
          )}
        />
      )}
      <div className={styles["header"]}>
        <h2>{PAGE_NAME.managePeople}</h2>
        <div className={styles["header-control"]}>
          <PrintExport
            screenName={PRINT_SCREEN_NAME.people}
            handleExport={handleExportToExcel}
            exportLoading={exportLoading}
            isDisplayTime
          />
          <Button
            name="Create New Person"
            iconUrl="/images/plus-icon-white.svg"
            altIcon="Create Person"
            className="btn-primary-fill"
            handleClick={() => setShowEntityModal(true)}
          />
          <SearchInputGeneral
            placeholder="Search People"
            inputRef={inputRef}
            isAutoComplete={true}
            suggestions={entityData.selectName}
            onSubmitSearch={handleSearchEntity}
            searchInput={paramSearch.search}
          />
          <span
            className={clsx(
              styles["delete-icon"],
              isLoading || listIdChecked.length === 0 ? styles["disable"] : ""
            )}
            onClick={() => {
              listIdChecked.length > 0 && setShowConfirmDelete(true);
            }}
          >
            <img
              src={`/images/icons/delete-ic-${
                isLoading || listIdChecked.length === 0 ? "disable" : "orange"
              }.svg`}
              alt="delete"
            />
          </span>
          <span className={styles["option-view"]}>
            <img
              src={
                isTableActive
                  ? "/images/icons/table-icon.svg"
                  : "/images/icons/table-icon-disable.svg"
              }
              alt="table.svg"
              onClick={() => setIsTableActive(true)}
            />
            <img
              src={
                isTableActive
                  ? "/images/icons/card-icon-disable.svg"
                  : "/images/icons/card-icon.svg"
              }
              alt="card-icon"
              onClick={() => setIsTableActive(false)}
            />
          </span>
        </div>
      </div>
      {isTableActive ? (
        <EntityTableView
          perPage={perPageTable}
          isLoading={isLoading}
          pageNumber={paramSearch.paginationParams.pageNumber}
          searchInput={paramSearch.search}
          entityData={entityData}
          handlePagingItem={handlePagingEntity}
          handleSortTable={handleSortTable}
          handleEditEntity={handleEditEntity}
          getChecked={handleSelectEntity}
          getCheckedAll={handleSelectAllItems}
          isCheckedAll={getIsSelectAll()}
        />
      ) : (
        <EntityCardList
          perPage={perPageCard}
          isLoading={isLoading}
          pageNumber={paramSearch.paginationParams.pageNumber}
          searchInput={paramSearch.search}
          entityData={entityData}
          handlePagingItem={handlePagingEntity}
          getChecked={handleSelectEntity}
          getCheckedAll={handleSelectAllItems}
          handleEditEntity={handleEditEntity}
          isCheckedAll={getIsSelectAll()}
          handleUploadAvatar={handleUploadAvatar}
          matterId={projectId}
        />
      )}
    </div>
  );
};

export default EntitiesManagement;
