import { HEAT_MAP_COLORS } from "constants/Constants";

export const pickCountColor = (count, maxCount) => {
  const averageCount = maxCount > 0 ? maxCount / 11 : 0;
  const colorsRange = [];
  if (maxCount > 0 && averageCount > 0) {
    HEAT_MAP_COLORS.forEach((_, i) => {
      const countTo = maxCount - averageCount * i;
      const countFrom = maxCount - averageCount * (i + 1);
      if (
        Number(countFrom.toFixed()) + 1 <= Number(countTo.toFixed()) &&
        countTo.toFixed() > 0
      )
        colorsRange.push(HEAT_MAP_COLORS[i]);
    });
  }
  colorsRange.push("#f9f9f9");
  const cellColor = {
    bgColor: "",
    color: "black",
  };
  const checkColorDisplay = (number) =>
    count > maxCount - (maxCount < 11 ? 1 : averageCount) * number;

  for (let index = 0; index < 11; index++) {
    if (checkColorDisplay(index + 1)) {
      if (index < 4) cellColor.color = "#fff";
      cellColor.bgColor = colorsRange[index];
      break;
    }
  }
  if (!count) cellColor.bgColor = colorsRange[colorsRange.length - 1];
  return cellColor;
};
