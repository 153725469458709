import React from "react";
import PropTypes from "prop-types";

// Components
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import Checkbox from "components/shared/checkbox/Checkbox";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import EntityCard from "../entity-card/EntityCard";

//Styles
import styles from "./EntityCardList.module.scss";

const EntityCardList = ({
  isLoading = false,
  perPage,
  searchInput = "",
  entityData = {},
  pageNumber = 1,
  handlePagingItem = () => {},
  getChecked = () => {},
  getCheckedAll = () => {},
  handleEditEntity = () => {},
  isCheckedAll = false,
  handleUploadAvatar = () => {},
  matterId,
}) => {
  return (
    <div className={styles["entity-card-list"]}>
      <div className={styles["sub-header"]}>
        {entityData.totalRecords > 0 && (
          <Checkbox
            id="selectAll"
            name="selectAll"
            label="Select All"
            size="md"
            isChecked={isCheckedAll}
            handleClick={getCheckedAll}
          />
        )}
        {!isLoading && (
          <p className={styles["total-record"]}>
            {`${entityData.totalRecords} ${
              entityData.totalRecords > 1 ? "People" : "Person"
            }`}
          </p>
        )}
      </div>
      <div className={styles["entity-card"]}>
        {isLoading ? (
          <SpinnerLoading />
        ) : entityData.items?.length > 0 ? (
          entityData.items.map((item) => (
            <EntityCard
              key={item.entityID}
              entityData={item}
              checked={item.checked}
              getChecked={getChecked}
              handleEditEntity={() => handleEditEntity(item.entityID)}
              handleUploadAvatar={handleUploadAvatar}
              matterId={matterId}
            />
          ))
        ) : (
          <div className={styles["no-result"]}>
            <EmptyPage
              messages={
                searchInput
                  ? "No results found. Please try again."
                  : "This page is empty. Please create a new person"
              }
            />
          </div>
        )}
      </div>
      {entityData.totalRecords > 0 && (
        <PaginationResult
          forcePage={pageNumber - 1}
          perPage={perPage}
          totalRecord={entityData.totalRecords}
          pageItems={entityData.items.length}
          handlePagingClick={handlePagingItem}
          isSmall
          nameRecord={entityData.totalRecords > 1 ? "people" : "person"}
          isUpdateCurrentPage={isLoading}
        />
      )}
    </div>
  );
};

EntityCardList.propTypes = {
  isLoading: PropTypes.bool,
  isCheckedAll: PropTypes.bool,
  perPage: PropTypes.number,
  entityData: PropTypes.shape({
    items: PropTypes.array,
    totalRecords: PropTypes.number,
    selectName: PropTypes.arrayOf(PropTypes.string),
  }),
  searchInput: PropTypes.string,
  pageNumber: PropTypes.number,
  getChecked: PropTypes.func,
  getCheckedAll: PropTypes.func,
  handlePagingItem: PropTypes.func,
  handleEditEntity: PropTypes.func,
  handleUploadAvatar: PropTypes.func,
  matterId: PropTypes.number,
};

export default EntityCardList;
