import React from "react";
import clsx from "clsx";
import { useAuth0 } from "@auth0/auth0-react";

// Constants
import { COPY_RIGHT_LINK } from "constants/AuthConstant";
import { CONTENT_TYPE } from "constants/Constants";

// Videos
import StreemView2 from "assets/videos/StreemView2.mp4";
import StreemView4 from "assets/videos/StreemView4.mp4";
import StreemView8 from "assets/videos/StreemView8.mp4";
import StreemView9 from "assets/videos/StreemView9.mp4";
import StreemView10 from "assets/videos/StreemView10.mp4";
import StreemView12 from "assets/videos/StreemView12.mp4";

// Styles
import styles from "../auth/LoginAuth0.module.scss";

const LoginAuth0 = () => {
  const { loginWithRedirect } = useAuth0();

  const videos = (randomVideo) =>
    randomVideo[Math.floor(Math.random() * randomVideo.length)];

  const videoActivated = videos([
    StreemView2,
    StreemView4,
    StreemView8,
    StreemView9,
    StreemView10,
    StreemView12,
  ]);

  return (
    <div className={styles["login"]}>
      <div className={styles["login-container"]}>
        <div className={styles["login-form"]}>
          <h1 className={styles["logo"]}>
            <a className={styles["btn_alpha"]}>
              <img src="/images/streemview-logo.svg" alt="Streemview logo" />
            </a>
          </h1>
          <h2 className={styles["login-ttl"]}>Login</h2>
          <p className={styles["login-txt"]}>
            DOWNSTREEM is a professional digital forensics practice,
            specializing in Data Forensics and Advisory Services.
          </p>

          <button
            type="button"
            onClick={() => {
              loginWithRedirect({ prompt: "login" });
              localStorage.removeItem("showTextNote");
            }}
            className={clsx(styles["btn-login"], "btn-default")}
          >
            Continue
          </button>
          <div className={styles["copy-right"]}>
            <p>©️ 2024 Downstreem All rights reserved.</p>

            <ul className={styles["terms-privacy"]}>
              <li>
                <a
                  className="me-4"
                  href={COPY_RIGHT_LINK.termOfUse}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href={COPY_RIGHT_LINK.privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles["login-background"]}>
        <figure>
          <video
            autoPlay
            muted
            loop
            src={videoActivated}
            type={CONTENT_TYPE.mp4}
          />
        </figure>
      </div>
    </div>
  );
};

export default LoginAuth0;
