import React, { useState, useRef, useEffect } from "react";
import { useOnClickOutside } from "hook/click-outside";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";

// Constants
import { KEY_CODE } from "constants/Common";

// Style
import styles from "./DropdownSearchOption.module.scss";

export const DropdownSearchOption = (props) => {
  const {
    isBorder = true,
    type = "button",
    containerClassName = "",
    dropdownClassName = "",
    itemList = [],
    onSelect,
    selectedItem,
    size, //sm, md, lg
    widthStyle = "", //auto
    isUseValueLabel = false,
    loading = false,
    imgIcon = "",
    ...rest
  } = props;

  const dropdownRef = useRef(null);
  const [toggle, setToggle] = useState(false);
  const [focusItem, setFocusItem] = useState(selectedItem); // used for set focus item to display when still not set value

  const onToggleMenuHandle = () => {
    setToggle(!toggle);
  };

  const handleSelect = (selectedItem) => {
    setToggle(false);
    onSelect(isUseValueLabel ? selectedItem : selectedItem.name);
    setFocusItem(selectedItem.name);
  };

  const onKeyDown = (e) => {
    e.preventDefault();
    if (!toggle || !e.keyCode) return;
    const itemListSize = itemList.length;
    const currentFocusIndex = itemList.findIndex((item) => item === focusItem);
    switch (e.keyCode) {
      case KEY_CODE.arrowUp:
        if (currentFocusIndex > 0) {
          setFocusItem(itemList[currentFocusIndex - 1]);
        }
        if (currentFocusIndex === 0) {
          setFocusItem(itemList[itemListSize - 1]);
        }
        break;
      case KEY_CODE.arrowDown:
        if (currentFocusIndex < itemListSize - 1) {
          setFocusItem(itemList[currentFocusIndex + 1]);
        }
        if (currentFocusIndex === itemListSize - 1) {
          setFocusItem(itemList[0]);
        }
        break;
      case KEY_CODE.enter:
        onSelect(itemList[currentFocusIndex]);
        setToggle(false);
        break;
      default:
        break;
    }
  };

  useOnClickOutside(dropdownRef, () => toggle && setToggle(false));

  useEffect(() => {
    setFocusItem(selectedItem);
  }, [selectedItem]);

  return (
    <div
      className={clsx(
        styles["dropdown"],
        containerClassName,
        styles[size],
        widthStyle === "auto" ? styles["dropdown-auto"] : ""
      )}
      ref={dropdownRef}
      onKeyDown={onKeyDown}
    >
      <button
        {...rest}
        type={type}
        onClick={onToggleMenuHandle}
        className={isBorder ? styles["btn-border"] : styles["btn-border-none"]}
        disabled={loading}
      >
        {loading ? (
          <Spinner
            className="spinner-loading"
            animation="border"
            variant="primary"
          />
        ) : (
          <>
            <p>{selectedItem}</p>
            {imgIcon ? (
              <img src={imgIcon} alt="Arrow down" />
            ) : (
              <img
                src={`/images/icons/arrow-down${
                  size === "lg" || size === "md" ? "" : "-blue"
                }.svg`}
                alt="Arrow down"
              />
            )}
          </>
        )}
      </button>
      {toggle && (
        <ul className={clsx(styles["dropdown-selectors"], dropdownClassName)}>
          {itemList?.map((item, index) => (
            <li
              key={index}
              className={
                focusItem === item.name ? `active ${styles.selected}` : ""
              }
              onClick={() => handleSelect(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

DropdownSearchOption.propTypes = {
  type: PropTypes.string,
  altIcon: PropTypes.string,
  imgIcon: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  widthStyle: PropTypes.string,
  dropdownClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  isBorder: PropTypes.bool,
  isUseValueLabel: PropTypes.bool,
  loading: PropTypes.bool,
  itemList: PropTypes.array,
  selectedItem: PropTypes.any,
  onSelect: PropTypes.func,
};
