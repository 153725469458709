import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useOnClickOutside } from "hook/click-outside";
import clsx from "clsx";

// Components
import FormInputControl from "components/shared/form-input-control/FormInputControl";
import FormInput from "components/shared/form-input-control/form-input/FormInput";

// Constants
import { SOCIAL_ENUM, SOCIAL_LIST } from "constants/Constants";
import { ENTITY_VALIDATION } from "constants/EntityConstant";
import { FIELD_VALIDATION } from "constants/RegexConstant";

// Helpers
import { getSourceIconById } from "helpers/CommonHelper";

// Styles
import styles from "./AssociatedIdentifierForm.module.scss";

const AssociatedIdentifierForm = ({
  data = [],
  onChangeData = () => {},
  updateErrorIdentifierList = () => {},
  getStatusUpdate = () => {}, // get true -> updating, false => updated
  errorIdentifierList = [],
}) => {
  const addMoreRef = useRef(null);
  const socialListRef = useRef(null);

  const [isCreating, setIsCreating] = useState(false);
  const [isShowAssociatedList, setIsShowAssociatedList] = useState(false);
  const [socialType, setSocialType] = useState();
  const [inputValue, setInputValue] = useState("");
  const [associatedData, setAssociatedData] = useState([]);
  const [associatedCurrentID, setAssociatedCurrentID] = useState();
  const [errorMsg, setErrorMsg] = useState("");

  useOnClickOutside(
    socialListRef,
    () => {
      setIsShowAssociatedList(false);
      setIsCreating(false);
    },
    addMoreRef?.current
  );

  const getSourceName = (type) =>
    SOCIAL_LIST.find((item) => item.id === type)?.name;

  const sortAlphabet = (array = []) =>
    array.sort((a, b) => a.name.localeCompare(b.name));

  const onSelectSourceType = (id) => {
    setSocialType(id);
    setIsShowAssociatedList(false);
    updateStateComponent({ isCreate: true });
    getStatusUpdate(true);
  };

  const isValidPhone = (phone = "", associatedList = []) => {
    const phoneInput = phone.trim();
    let msg = "";
    if (!phoneInput) msg = ENTITY_VALIDATION.phone.required;
    else if (!phoneInput.match(FIELD_VALIDATION.phoneEntity))
      msg = ENTITY_VALIDATION.phone.invalid;
    else if (phoneInput.length < 9 || phoneInput.length > 20)
      msg = ENTITY_VALIDATION.phone.max_length;
    else if (associatedList.some((item) => item.value === phoneInput))
      msg = ENTITY_VALIDATION.phone.exists;
    setErrorMsg(msg);
    return msg ? false : true;
  };

  const isValidEmail = (email = "", associatedList = []) => {
    const emailInput = email.trim();
    let msg = "";
    if (!emailInput) msg = ENTITY_VALIDATION.email.required;
    else if (!emailInput.match(FIELD_VALIDATION.emailUserModal))
      msg = ENTITY_VALIDATION.email.invalid;
    else if (emailInput.length < 3 || emailInput.length > 100) 
      msg = ENTITY_VALIDATION.email.max_length;
    else if (associatedList.some((item) => item.value === emailInput))
      msg = ENTITY_VALIDATION.email.exists;
    setErrorMsg(msg);
    return msg ? false : true;
  };

  const isValidSocial = (social = "", associatedList = []) => {
    const socialInput = social.trim();
    let msg = "";
    if (!socialInput) msg = ENTITY_VALIDATION.social.required;
    else if (socialInput.length < 3 || socialInput.length > 100)
      msg = ENTITY_VALIDATION.social.max_length;
    else if (associatedList.some((item) => item.value === socialInput))
      msg = ENTITY_VALIDATION.social.exists;
    setErrorMsg(msg);
    return msg ? false : true;
  };

  const handleSaveForm = (data) => {
    const newAssociatedList = associatedData.filter(
      (item) => item.value !== data?.value
    );
    if (
      socialType === SOCIAL_ENUM.phone &&
      !isValidPhone(inputValue, newAssociatedList)
    )
      return;
    if (
      socialType === SOCIAL_ENUM.email &&
      !isValidEmail(inputValue, newAssociatedList)
    )
      return;
    if (
      ![SOCIAL_ENUM.email, SOCIAL_ENUM.phone].includes(socialType) &&
      !isValidSocial(inputValue, newAssociatedList)
    )
      return;
    let newAssociated = [];
    if (data?.id) {
      newAssociated = associatedData.map((item) => {
        if (item.id === data?.id) return { ...data, value: inputValue.trim() };
        else return item;
      });
      updateStateComponent({ value: inputValue.trim() });
    } else {
      newAssociated = [
        ...associatedData,
        {
          id: uuidv4(),
          value: inputValue.trim(),
          socialType,
        },
      ];
      updateStateComponent({ isCreate: false });
    }
    setAssociatedData(newAssociated);
    onChangeData(newAssociated);
    getStatusUpdate(false);
  };

  const handleDeleteForm = (value) => {
    const newAssociated = associatedData.filter((item) => item.value !== value);
    setAssociatedData(newAssociated);
    onChangeData(newAssociated);
    updateErrorIdentifierList(
      errorIdentifierList.filter((item) => item !== value)
    );
  };

  const handleEditForm = (data) => {
    updateStateComponent({
      isCreate: false,
      associatedID: data.id,
      value: data.value,
    });
    setErrorMsg("");
    updateErrorIdentifierList(
      errorIdentifierList.filter((item) => item !== data.value)
    );
    setSocialType(data.socialType);
    getStatusUpdate(true);
  };

  const updateStateComponent = ({
    isCreate = false,
    associatedID = null,
    value = "",
  }) => {
    setIsCreating(isCreate);
    setAssociatedCurrentID(associatedID);
    setInputValue(value);
  };

  useEffect(() => {
    setAssociatedData(data);
  }, [JSON.stringify(data)]);

  return (
    <div className={styles["wrap"]}>
      <h5>Associated Identifiers</h5>
      <div className={styles["associated-identifier"]}>
        {associatedData?.length > 0 && (
          <ul className={styles["associated-identifier-info"]}>
            {associatedData.map((item) => (
              <li key={item.id}>
                <img
                  src={getSourceIconById(SOCIAL_LIST, item.socialType)}
                  alt={getSourceName(item.socialType)}
                  className={styles["source-icon"]}
                />
                <FormInputControl
                  className={clsx(
                    styles["associated-form"],
                    errorIdentifierList.filter((value) => value === item.value)
                      .length > 0
                      ? styles["associated-form-error"]
                      : ""
                  )}
                  isCreating={isCreating}
                  currentID={associatedCurrentID}
                  dataID={item.id}
                  editValue={item.value}
                  inputValue={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  clearFocus={setAssociatedCurrentID}
                  handleEditForm={() => handleEditForm(item)}
                  handleDeleteForm={() => handleDeleteForm(item.value)}
                  handleSaveForm={() => handleSaveForm(item)}
                  handleCancelForm={() => {
                    updateStateComponent({ value: inputValue });
                    getStatusUpdate(false);
                  }}
                  errorMsg={errorMsg}
                />
              </li>
            ))}
          </ul>
        )}
        {isCreating && (
          <div className={styles["add-new-associated"]}>
            <span>
              <img
                src={getSourceIconById(SOCIAL_LIST, socialType)}
                alt={getSourceName(socialType)}
                className={styles["source-icon"]}
              />
              <FormInput
                value={inputValue}
                placeholder={`Input ${getSourceName(socialType)}...`}
                onChange={(e) => setInputValue(e.target.value)}
                handleSaveForm={handleSaveForm}
                handleCancelForm={() => {
                  updateStateComponent({ isCreate: false });
                  getStatusUpdate(false);
                }}
                errorMsg={errorMsg}
              />
            </span>
          </div>
        )}
        <span
          className={styles["add-more-row"]}
          onClick={() => {
            setIsShowAssociatedList(!isShowAssociatedList);
            setErrorMsg("");
            updateErrorIdentifierList([]);
          }}
          ref={addMoreRef}
        >
          Add Identifier
        </span>
        {isShowAssociatedList && (
          <ul className={styles["social-list"]} ref={socialListRef}>
            {sortAlphabet(SOCIAL_LIST).map((item) => (
              <li key={item.id} onClick={() => onSelectSourceType(item.id)}>
                <img src={`/images/icons/${item.icon}.svg`} alt={item.name} />
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

AssociatedIdentifierForm.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      socialType: PropTypes.number,
      value: PropTypes.string,
    })
  ),
  errorIdentifierList: PropTypes.arrayOf(PropTypes.string),
  onChangeData: PropTypes.func,
  updateErrorIdentifierList: PropTypes.func,
  getStatusUpdate: PropTypes.func,
};

export default AssociatedIdentifierForm;
