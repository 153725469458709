import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clone from "lodash/clone";
import clsx from "clsx";

//Components
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import { Container } from "react-bootstrap";
import PrintExport from "components/shared/print-export/PrintExport";
import { DateSelector } from "components/shared/date-picker/DateSelector";

// Services
import { exportHeatMapToExcelApi } from "services";

// Store
import {
  fetchHeatMap,
  setParamSearchHeatMap,
} from "store/HeatMapReducer";

// Constants & Helpers
import { PAGE_WITHOUT_EXCEL, PAGE_HAVE_EXCEL } from "constants/Constants";
import {
  ANALYTIC_PAGES,
  ANALYTIC_PAGES_INFO,
} from "constants/InsightsConstant";
import { handleTypeFilter } from "helpers/DateTimeFormatterHelper";
import { PATH_NAME } from "constants/Common";
import { getInsightsBreadcrumb } from "constants/BreadCrumbConstants";
import { getCurrentTime } from "helpers/DateTimeFormatterHelper";

//Styles
import styles from "./AnalyticsContainerPage.module.scss";

const AnalyticsContainerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { projectId } = useParams();

  const [type, setType] = useState({});
  const [isOnlyPDF, setIsOnlyPDF] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [dateTimeData, setDateTimeData] = useState({
    startDate: null,
    endDate: null
  });

  const listPathsOnlyPDF = [
    PAGE_WITHOUT_EXCEL.eventTimeline
  ];

  const listTypeShowDateSelector = [
    ANALYTIC_PAGES_INFO.communicationAnalyzer.value
  ];

  const { paramSearch: paramSearchHeatMap } = useSelector(
    (state) => state.heatMap
  );

  const getPath = () => pathname.substring(pathname.lastIndexOf("/") + 1);

  const handleGoBack = () => navigate(`/${PATH_NAME.matters}`);

  const getPageContent = (type) => {
    const index = ANALYTIC_PAGES.findIndex((item) => item.value === type);
    if (index === -1) return;
    else {
      const Component = ANALYTIC_PAGES[index].component;
      return <Component />;
    }
  };

  const onSubmitDate = (date) => {
    setShowDatePicker(false);
    let dataRequest = {
      start: date.dateTimeDataStart,
      end: date.dateTimeDataEnd,
      includeBlankDate: date.includeBlankDate,
      typeFilter: date.typeFilter,
    };
    setDateTimeData({
      startDate: dataRequest.start,
      endDate: dataRequest.end
    });
    let params = {};
    switch (type.value) {
      case ANALYTIC_PAGES_INFO.communicationAnalyzer.value:
        params = clone(paramSearchHeatMap);
        dispatch(setParamSearchHeatMap(dataRequest));
        dataRequest = Object.assign(params, dataRequest);
        dispatch(fetchHeatMap({ projectId, data: dataRequest }));
        break;
      default:
        break;
    }
    setShowDatePicker(false);
  };

  const checkIsOnlyPDF = () => {
    const currentPath = getPath();
    const isOnlyPDF = listPathsOnlyPDF.includes(currentPath);
    setIsOnlyPDF(isOnlyPDF);
  };

  // Export heatmap excel
  const exportHeatMapToExcel = async () => {
    setExportLoading(true);
    try {
      const dataResult = await exportHeatMapToExcelApi(
        projectId,
        paramSearchHeatMap
      );
      const url = window.URL.createObjectURL(dataResult.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `StreemView_CommunicationAnalyzer_${getCurrentTime()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const exportExcel = () => {
    const currentPath = getPath();
    switch (currentPath) {
      case PAGE_HAVE_EXCEL.communicationAnalyser:
        exportHeatMapToExcel();
        break;
      default:
        break;
    }
  };
  
  const handleShowDateFilter = () => {
      setShowDatePicker(true);
  };

  // Check pathname to render page content
  useEffect(() => {
    checkIsOnlyPDF();
    const lastSegment = getPath();
    const indexOfType = ANALYTIC_PAGES.findIndex(
      (item) => item.value === lastSegment?.toLocaleLowerCase()
    );
    setType({
      value: ANALYTIC_PAGES[indexOfType !== -1 ? indexOfType : 0].value,
      label: ANALYTIC_PAGES[indexOfType !== -1 ? indexOfType : 0].label,
    });
  }, []);

  const getDateFromObject = (params) => ({
    startDate: params.start,
    endDate: params.end,
    includeBlankDate: params.includeBlankDate,
    typeFilter: handleTypeFilter(params.start, params.end),
  });

  const getDateTimeShow = () => {
    const analyticPage = new Map()
      .set(
        ANALYTIC_PAGES_INFO.communicationAnalyzer.value,
        getDateFromObject(paramSearchHeatMap)
      )
    return analyticPage.has(type.value) ? analyticPage.get(type.value) : {};
  };

  useEffect(() => {
    checkIsOnlyPDF();
    
    const lastSegment = getPath();
    const indexOfType = ANALYTIC_PAGES.findIndex(
      (item) => item.value === lastSegment?.toLocaleLowerCase()
    );
    
    setType({
      value: ANALYTIC_PAGES[indexOfType !== -1 ? indexOfType : 0].value,
      label: ANALYTIC_PAGES[indexOfType !== -1 ? indexOfType : 0].label,
    });
  }, [pathname]);

  return (
    <Container fluid>
      <DateSelector
        showDatePicker={showDatePicker}
        handleClose={() => setShowDatePicker(false)}
        handleSubmit={onSubmitDate}
        data={getDateTimeShow()}
      />
      <div className="main">
        <div className={clsx(styles["container"], "main-body")}>
          <BreadCrumb
            goBack={handleGoBack}
            breadCrumbData={getInsightsBreadcrumb(getPath())}
          />
          <div className={styles["container-header"]}>
            <h2 className={styles["container-heading"]}>{type.label}</h2>
            <div className={styles["container-actions"]}>
              <div className={styles["container-actions-right"]}>
                {listTypeShowDateSelector.includes(type.value) && (
                  <div className={styles["calendar-icon"]}>
                    <img
                      src={
                        dateTimeData.startDate !== null ||
                        dateTimeData.endDate !== null
                          ? "/images/calendar-icon-checked.svg"
                          : "/images/calendar-icon.svg"
                      }
                      alt="Calendar Icon"
                      onClick={handleShowDateFilter}
                    />
                  </div>
                )}
                <PrintExport
                  screenName={type.label}
                  handleExport={exportExcel}
                  isDisplayTime
                  isExportExcel={!isOnlyPDF}
                  exportLoading={exportLoading}
                />
              </div>
            </div>
          </div>
          <div className={styles["container-body"]}>
            {getPageContent(type.value)}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AnalyticsContainerPage;
