import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import clsx from "clsx";

// Store
import { fetchTagsList } from "store/TagReducer";
import {
  setCreateExport,
  setParameterConfig,
} from "store/ExportManagementReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";

// Services
import { handleAbortRequest } from "services/ApiConfig";

// Components
import DropdownGeneral from "components/shared/dropdown-selector/dropdown-general/DropdownGeneral";
import DropdownExportConfig from "components/export-management/create-export/dropdown-export-config/DropdownExportConfig";

// Constant
import {
  MESSAGE_QUANTITY_CONFIG,
  MESSAGE_CONFIG_DEFAULT,
} from "constants/ExportConstant";
import { KEY_CODE } from "constants/Common";

// Helper
import { sortAlphabetListTag } from "helpers/FormatterHelper";
import { getValueReactSelect } from "helpers/CommonHelper";
import { getTagSelectedName } from "helpers/GetFileNameHelper";

// Styles
import styles from "./ExportConfiguration.module.scss";

const ExportConfiguration = (props) => {
  const { formik, isResetForm = false, setIsResetForm = () => { } } = props;
  const { projectId, exportId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const exportNameRef = useRef(null);
  const controlPrefixRef = useRef(null);
  const controlNumberRef = useRef(null);
  const volumePrefixRef = useRef(null);
  const volumeNumberRef = useRef(null);

  const [selectedTags, setSelectedTags] = useState([]);
  const [unselectedTags, setUnselectedTags] = useState([]);
  const [excludeTagsRequest, setExcludeTags] = useState([]);
  const [unExcludeTags, setUnExcludeTags] = useState([]);

  //Get data from store
  const {
    createExport: {
      parameterConfig: { tagID, exportAroundItems, excludeTags },
    },
  } = useSelector((state) => state.export);
  const {
    tags: { showTags = [], allTags = [] },
    loading: loadingTag,
  } = useSelector((state) => state.tag);

  const onKeyDown = (event) => {
    if (event.keyCode === KEY_CODE.enter) {
      if (exportNameRef.current.value) exportNameRef.current.blur();
      if (controlPrefixRef.current.value) controlPrefixRef.current.blur();
      if (controlNumberRef.current.value) controlNumberRef.current.blur();
      if (volumePrefixRef.current.value) volumePrefixRef.current.blur();
      if (volumeNumberRef.current.value) volumeNumberRef.current.blur();
    }
    if (event.keyCode === KEY_CODE.space) {
      if (
        event.target.name === "startingControlNumber" ||
        event.target.name === "startingVolumeNumber"
      )
        event.preventDefault();
    }
  };

  const changeMessageConfiguration = (event) =>
    dispatch(setParameterConfig({ exportAroundItems: event.value }));

  const getExcludeTagsSelect = (tag, isSelect = true) => {
    return {
      selectedTag: isSelect
        ? [...excludeTagsRequest, tag]
        : excludeTagsRequest.filter((item) => item.tagID !== tag.tagID),
      unselectTags: isSelect
        ? unExcludeTags.filter((item) => item.tagID !== tag.tagID)
        : [...unselectedTags, tag],
    };
  };

  const handleSelectExcludeTags = (tag, isSelect) => {
    handleAbortRequest();
    const { selectedTag, unselectTags } = getExcludeTagsSelect(tag, isSelect);
    setExcludeTags(selectedTag);
    setUnExcludeTags(unselectTags);
    const newListTag = selectedTag.map((item) => item.tagID);
    dispatch(setParameterConfig({ excludeTags: newListTag }));
    formik.setFieldValue("excludeTags", newListTag);
  };

  const handleSelectTag = (tag) => {
    handleAbortRequest();
    const selectedTag = [...selectedTags, tag];
    setSelectedTags(sortAlphabetListTag(selectedTag));
    setUnselectedTags(
      unselectedTags.filter((item) => item.tagID !== tag.tagID)
    );

    const newListTag = selectedTag.map((item) => item.tagID);
    dispatch(setParameterConfig({ tagID: newListTag }));
    formik.setFieldValue("tagID", newListTag);
  };

  const handleNotSelectTag = (tag) => {
    handleAbortRequest();
    const selectedTag = selectedTags.filter((item) => item.tagID !== tag.tagID);
    const unSelectedTag = [...unselectedTags, tag];
    setSelectedTags(selectedTag);
    setUnselectedTags(sortAlphabetListTag(unSelectedTag));

    const newListTag = selectedTag.map((item) => item.tagID);
    dispatch(setParameterConfig({ tagID: newListTag }));
    formik.setFieldValue("tagID", newListTag);
  };

  const handleClearTag = () => {
    handleAbortRequest();
    formik.setFieldValue("tagID", []);
    dispatch(setParameterConfig({ tagID: [] }));
    setSelectedTags([]);
    setUnselectedTags(sortAlphabetListTag([...showTags]));
  };

  const handleClearExcludeTag = () => {
    handleAbortRequest();
    formik.setFieldValue("excludeTags", []);
    dispatch(setParameterConfig({ excludeTags: [] }));
    setExcludeTags([]);
    setUnExcludeTags(sortAlphabetListTag([...showTags]));
  };

  const valueTagSelected = (tags) => {
    const totalTag = getTagSelectedName(
      state?.isPrint ? allTags : showTags,
      tags
    ).length;
    return totalTag > 0
      ? [{ label: `${totalTag} Tag${totalTag > 1 ? "s" : ""} selected` }]
      : [];
  };

  const resetDataConfig = () => {
    setSelectedTags([]); // set selected tags is default
    setUnselectedTags(sortAlphabetListTag([...showTags]) || []); // set unselected tags is default
  };

  useEffect(() => {
    const {
      exportName,
      startingControlPrefix,
      startingControlNumber,
      startingVolumePrefix,
      startingVolumeNumber,
    } = formik.values;
    dispatch(setCreateExport({ exportName }));
    dispatch(
      setParameterConfig({
        startingControlPrefix,
        startingControlNumber,
        startingVolumePrefix,
        startingVolumeNumber,
      })
    );
  }, [formik.values]);

  useEffect(() => {
    if (!isResetForm) return;
    resetDataConfig();
    setIsResetForm(false);
  }, [isResetForm]);

  useEffect(() => {
    if (!loadingTag) {
      setUnselectedTags(sortAlphabetListTag([...showTags]) || []);
      setUnExcludeTags(sortAlphabetListTag([...showTags]) || []);
    }
  }, [loadingTag]);

  const updateSelectTags = () => {
    const selectedTags = showTags.filter((item) => tagID.includes(item.tagID));
    const unselectedTags = showTags.filter(
      (item) => !tagID?.includes(item.tagID)
    );
    setSelectedTags(sortAlphabetListTag(selectedTags) || []);
    setUnselectedTags(sortAlphabetListTag(unselectedTags) || []);
  };

  const updateExcludeTags = () => {
    const excludeTagsAfter = showTags.filter((item) =>
      excludeTags?.includes(item.tagID)
    );
    const unExcludeTags = showTags.filter(
      (item) => !excludeTags.includes(item.tagID)
    );
    setExcludeTags(sortAlphabetListTag(excludeTagsAfter) || []);
    setUnExcludeTags(sortAlphabetListTag(unExcludeTags) || []);
  };

  useEffect(() => {
    if (showTags?.length === 0) return;
    if (state?.isCloneExport || exportId) {
      if (tagID?.length === 0)
        setUnselectedTags(sortAlphabetListTag([...showTags]));
      else updateSelectTags();

      if (excludeTags?.length === 0)
        setUnExcludeTags(sortAlphabetListTag([...showTags]));
      else updateExcludeTags();
      return;
    }
  }, [loadingTag, tagID, excludeTags]);

  useEffect(() => {
    resetDataConfig();
    dispatch(fetchTagsList({ projectId }));
    dispatch(fetchDataSourceList(projectId));
  }, [projectId]);

  return (
    <>
      <Form.Group className="app-form-group">
        <Form.Label className="app-form-label">
          Export Name
          <span className={styles["app-form-required"]}> *</span>
        </Form.Label>
        <Form.Control
          type="text"
          placeholder="Input your export name..."
          className={clsx(
            "app-form-input",
            styles["export-name-form"],
            formik.errors.exportName && formik.touched.exportName ? "error" : ""
          )}
          name="exportName"
          id="exportName"
          value={formik.values.exportName}
          onChange={formik.handleChange}
          onKeyDown={onKeyDown}
          ref={exportNameRef}
        />
        {formik.errors.exportName && formik.touched.exportName && (
          <Form.Text className={clsx(styles["form-error"], "error-text")}>
            {formik.errors.exportName}
          </Form.Text>
        )}
      </Form.Group>
      <div className={styles["export-title-header"]}>
        <h4>Export Configurations</h4>
        <p className={styles["header-line"]}></p>
      </div>

      <div className={styles["export-setting"]}>
        <div className={styles["export-setting-form"]}>
          <Form.Group className="app-form-group">
            <Form.Label className="app-form-label">
              Select Tag(s)
              <span className={styles["app-form-required"]}> *</span>
            </Form.Label>
            <DropdownExportConfig
              className={clsx(
                "dropdown-react-select w-100",
                styles["export-setting-select"],
                formik.errors.tagID && formik.touched.tagID
                  ? styles["error"]
                  : ""
              )}
              placeholder="-- Select Your Tag --"
              placeholderSearch="Search Tag Name"
              value={valueTagSelected(tagID)}
              isMulti={true}
              selectedOption={selectedTags}
              notSelectedOption={unselectedTags}
              onChangeSelect={handleSelectTag}
              onChangeNotSelect={handleNotSelectTag}
              isDisabled={loadingTag}
              isLoading={loadingTag}
              handleClearValue={handleClearTag}
            />
            {formik.errors.tagID && formik.touched.tagID && (
              <Form.Text className={clsx(styles["form-error"], "error-text")}>
                {formik.errors.tagID}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group className="app-form-group">
            <Form.Label className="app-form-label">
              <div className={styles["note-tooltip-group"]}>
                Exclude Tag(s)
                <img
                  className={styles["note-tooltip"]}
                  src="/images/note.svg"
                  alt="note"
                />
                <span className={styles["tooltip-description"]}>
                  All the selected tag(s) below will be excluded from the Export
                </span>
              </div>
            </Form.Label>
            <DropdownExportConfig
              className={clsx(
                "dropdown-react-select w-100",
                styles["export-setting-select"]
              )}
              placeholder="-- Select Your Tag --"
              placeholderSearch="Search Tag Name"
              value={valueTagSelected(excludeTags)}
              isMulti={true}
              selectedOption={excludeTagsRequest}
              notSelectedOption={unExcludeTags}
              onChangeSelect={(tag) => handleSelectExcludeTags(tag)}
              onChangeNotSelect={(tag) => handleSelectExcludeTags(tag, false)}
              isDisabled={loadingTag}
              isLoading={loadingTag}
              handleClearValue={handleClearExcludeTag}
            />
          </Form.Group>
          <Form.Group className="app-form-group">
            <Form.Label
              className={clsx(
                "app-form-label",
                styles["export-previous-label"]
              )}
            >
              Export Previous/Following # of Message
              <span className={styles["app-form-required"]}> *</span>
            </Form.Label>
            <DropdownGeneral
              className={clsx(
                "dropdown-react-select w-100",
                styles["export-setting-select"]
              )}
              classNameContainer={styles["select-container"]}
              isSearchable={false}
              value={getValueReactSelect(
                exportAroundItems,
                MESSAGE_CONFIG_DEFAULT,
                MESSAGE_QUANTITY_CONFIG
              )}
              options={MESSAGE_QUANTITY_CONFIG}
              onChange={changeMessageConfiguration}
            />
          </Form.Group>
        </div>
        <div className={styles["export-setting-form"]}>
          <Form.Group className="app-form-group">
            <div className={styles["setting-starting"]}>
              <div className={styles["starting-form"]}>
                <Form.Label className="app-form-label">
                  Enter Control Prefix
                  <span className={styles["app-form-required"]}> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Starting Control Prefix"
                  className={clsx(
                    "app-form-input",
                    styles["setting-starting-form"],
                    formik.errors.startingControlPrefix &&
                      formik.touched.startingControlPrefix
                      ? "error"
                      : ""
                  )}
                  name="startingControlPrefix"
                  id="startingControlPrefix"
                  value={formik.values.startingControlPrefix}
                  onChange={formik.handleChange}
                  onKeyDown={onKeyDown}
                  ref={controlPrefixRef}
                />
                {formik.errors.startingControlPrefix &&
                  formik.touched.startingControlPrefix && (
                    <Form.Text
                      className={clsx(
                        styles["form-error-starting"],
                        "error-text"
                      )}
                    >
                      {formik.errors.startingControlPrefix}
                    </Form.Text>
                  )}
              </div>
              <div className={styles["starting-form"]}>
                <Form.Label className="app-form-label">
                  Enter Control Number
                  <span className={styles["app-form-required"]}> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Starting Control Number"
                  className={clsx(
                    "app-form-input",
                    styles["setting-starting-form"],
                    formik.errors.startingControlNumber &&
                      formik.touched.startingControlNumber
                      ? "error"
                      : ""
                  )}
                  name="startingControlNumber"
                  id="startingControlNumber"
                  value={formik.values.startingControlNumber}
                  onChange={formik.handleChange}
                  onKeyDown={onKeyDown}
                  ref={controlNumberRef}
                />
                {formik.errors.startingControlNumber &&
                  formik.touched.startingControlNumber && (
                    <Form.Text
                      className={clsx(
                        styles["form-error-starting"],
                        "error-text"
                      )}
                    >
                      {formik.errors.startingControlNumber}
                    </Form.Text>
                  )}
              </div>
            </div>
            <div className={styles["setting-starting"]}>
              <div className={styles["starting-form"]}>
                <Form.Label className="app-form-label">
                  Enter Volume Prefix
                  <span className={styles["app-form-required"]}> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Starting Volume Prefix"
                  className={clsx(
                    "app-form-input",
                    styles["setting-starting-form"],
                    formik.errors.startingVolumePrefix &&
                      formik.touched.startingVolumePrefix
                      ? "error"
                      : ""
                  )}
                  name="startingVolumePrefix"
                  id="startingVolumePrefix"
                  value={formik.values.startingVolumePrefix}
                  onChange={formik.handleChange}
                  onKeyDown={onKeyDown}
                  ref={volumePrefixRef}
                />
                {formik.errors.startingVolumePrefix &&
                  formik.touched.startingVolumePrefix && (
                    <Form.Text
                      className={clsx(
                        styles["form-error-starting"],
                        "error-text"
                      )}
                    >
                      {formik.errors.startingVolumePrefix}
                    </Form.Text>
                  )}
              </div>
              <div className={styles["starting-form"]}>
                <Form.Label className="app-form-label">
                  Enter Volume Number
                  <span className={styles["app-form-required"]}> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Starting Volume Number"
                  className={clsx(
                    "app-form-input",
                    styles["setting-starting-form"],
                    formik.errors.startingVolumeNumber &&
                      formik.touched.startingVolumeNumber
                      ? "error"
                      : ""
                  )}
                  name="startingVolumeNumber"
                  id="startingVolumeNumber"
                  value={formik.values.startingVolumeNumber}
                  onChange={formik.handleChange}
                  onKeyDown={onKeyDown}
                  ref={volumeNumberRef}
                />
                {formik.errors.startingVolumeNumber &&
                  formik.touched.startingVolumeNumber && (
                    <Form.Text
                      className={clsx(
                        styles["form-error-starting"],
                        "error-text"
                      )}
                    >
                      {formik.errors.startingVolumeNumber}
                    </Form.Text>
                  )}
              </div>
            </div>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

ExportConfiguration.propTypes = {
  formik: PropTypes.any,
  isResetForm: PropTypes.bool,
  setIsResetForm: PropTypes.func,
};

export default ExportConfiguration;
