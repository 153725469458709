import axios, { authHeader } from "./ApiConfig";
import { removeUserInfo } from "../hook/auth";

// Constants
import { AUTH_CONSTANT } from "constants/AuthConstant";
import { PROJECT_CONSTANT } from "constants/ProjectConstant";
import { CONTENT_FILTER_CONSTANT } from "constants/ContentFilter";
import { LOCAL_STORAGE } from "constants/LocalStorage";

const logOut = () => {
  removeUserInfo();
  const header = authHeader();
  localStorage.removeItem(AUTH_CONSTANT.token);
  localStorage.removeItem(AUTH_CONSTANT.tokenForgotPass);
  localStorage.removeItem(AUTH_CONSTANT.timeout);
  localStorage.removeItem(LOCAL_STORAGE.previousPath);
  localStorage.removeItem(AUTH_CONSTANT.dontShowNotiDownLoad);
  localStorage.removeItem(PROJECT_CONSTANT.projectDetail);
  localStorage.removeItem(LOCAL_STORAGE.intelligentSearchPath);
  localStorage.removeItem(CONTENT_FILTER_CONSTANT());
  return axios.post("/auth/logout", {}, { headers: header });
};

const loginAuth0 = (accessToken) =>
  axios.post("/auth/Auth0Login", { accessToken });

export { logOut, loginAuth0 };
