import React from "react";
import PropTypes from "prop-types";

//Styles
import styles from "./Toggle.module.scss";
import clsx from "clsx";

const Toggle = (props) => {
  const { checked = false, onChange, disabled, className } = props;
  return (
    <label className={clsx(styles["toggle"], className)}>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span className={clsx(styles["slider"], styles["round"])}></span>
    </label>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Toggle;
