// Constants
import { DATA_TYPE, DATA_TITLE } from "constants/DataType";

const getTitleHeaderFromType = (type) => {
  if (!type) return "";
  const titleHeaderMap = new Map()
    .set(DATA_TYPE.callLog, DATA_TITLE.callLog)
    .set(DATA_TYPE.email, DATA_TITLE.email)
    .set(DATA_TYPE.voicemail, DATA_TITLE.voiceEmail)
    .set(DATA_TYPE.image, DATA_TITLE.image)
    .set(DATA_TYPE.audio, DATA_TITLE.audio)
    .set(DATA_TYPE.video, DATA_TITLE.video)
    .set(DATA_TYPE.note, DATA_TITLE.note)
    .set(DATA_TYPE.historyLink, DATA_TITLE.historyLink)
    .set(DATA_TYPE.file, DATA_TITLE.file)
    .set(DATA_TYPE.location, DATA_TITLE.location)
    .set(DATA_TYPE.network, DATA_TITLE.network)
    .set(DATA_TYPE.chat, DATA_TITLE.chat)
    .set(DATA_TYPE.database, DATA_TITLE.database)
    .set(DATA_TYPE.contact, DATA_TITLE.contact)
    .set(DATA_TYPE.creditCard, DATA_TITLE.creditCard);
  return titleHeaderMap.has(type) ? titleHeaderMap.get(type) : "";
};

const checkPreviewHasData = (data) => {
  if (!data) return "";
  const previewHasDataMap = new Map()
    .set(DATA_TYPE.voicemail, data.url)
    .set(DATA_TYPE.video, data.url)
    .set(DATA_TYPE.historyLink, data.url)
    .set(DATA_TYPE.audio, data.url)
    .set(DATA_TYPE.location, data.id)
    .set(DATA_TYPE.image, data.thumbnailURL || data.url)
    .set(DATA_TYPE.note, data.body);

  return previewHasDataMap.has(data.type)
    ? previewHasDataMap.get(data.type)
    : "";
};

export { getTitleHeaderFromType, checkPreviewHasData };
