import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import clsx from "clsx";

// Store
import { setCanExecuteHotKey } from "store/TagReducer";

// Components
import { Button } from "components/shared/button/Button";
import { DropdownSelector } from "components/shared/dropdown-selector/DropdownSelector";
import Checkbox from "components/shared/checkbox/Checkbox";

// Constants
import { SAVED_SEARCH_VALIDATION } from "constants/SavedSearchValidation";
import { COMMON_TEXT, KEY_CODE } from "constants/Common";
import { SEARCH_MODE } from "constants/IntelligentSearchConstant";

// Styles
import styles from "./PopupAddSavedSearch.module.scss";

const PopupAddSavedSearch = (props) => {
  const {
    isShow = false,
    handleClose,
    isCombineSearch = false,
    handleSubmit,
    isUpdate = false,
    isLoading,
    isUpdateName = false,
    data,
    mode = "",
    searchId = "",
  } = props;

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      searchName: data?.name || "",
      isPrivate: data?.searchID ? data?.type === COMMON_TEXT.private : true,
      valueCriteria: COMMON_TEXT.and,
    },
    validationSchema: Yup.object({
      searchName: Yup.string()
        .trim()
        .required(SAVED_SEARCH_VALIDATION.searchName.required)
        .max(100, SAVED_SEARCH_VALIDATION.searchName.maxLength),
      isPrivate: Yup.bool(),
    }),
    onSubmit: (values) => {
      const formData = {
        ...values,
        searchName: values.searchName.trim(),
        isPrivate: values.isPrivate ? COMMON_TEXT.private : COMMON_TEXT.global,
      };
      handleSubmit({ ...formData });
    },
  });

  const criteriaList = [{ name: COMMON_TEXT.and }, { name: COMMON_TEXT.or }];

  const getTitle = () => {
    if ([SEARCH_MODE.result, SEARCH_MODE.original].includes(mode))
      return "Add name for Saved Search";
    if (isUpdateName) {
      if (isCombineSearch) return "Update name for Combined Search";
      return "Update name for Saved Search";
    }
    if (isCombineSearch) return "Add name for Combined Search";
    if (isUpdate) return "Update for Saved Search";
    return "Add name for Saved Search";
  };

  const getButtonName = () => {
    if ([SEARCH_MODE.result, SEARCH_MODE.original].includes(mode))
      return "Save & Execute";
    if (isUpdateName || (isUpdateName && isCombineSearch)) return "Update";
    if (isCombineSearch) return "Add Saved Search";
    if (isUpdate) return "Update Saved Search";
    return "Add Saved Search";
  };

  const isDisabled = () =>
    formik.values.searchName === data?.name &&
    formik.values.isPrivate === !!(data?.type === COMMON_TEXT.private);

  const handleOnKeyDown = (event) => {
    if (event.keyCode !== KEY_CODE.enter) return;
    if (!searchId && isDisabled()) event.preventDefault();
    else formik.handleSubmit();
  };

  useEffect(() => {
    if (!data?.searchID) return;
    formik.setValues({
      searchName: data.name,
      isPrivate: data.type === COMMON_TEXT.private,
      valueCriteria: data.condition || "",
    });
  }, [data]);

  useEffect(() => {
    dispatch(setCanExecuteHotKey(false));
    return () => dispatch(setCanExecuteHotKey(true));
  }, []);

  return (
    <div onKeyDown={handleOnKeyDown}>
      <Modal
        className={styles["modal-container"]}
        show={isShow}
        onHide={handleClose}
        backdrop="static"
      >
        <div>
          <Modal.Header className={styles["modal-header"]}>
            <p className={styles["modal-header-title"]}>{getTitle()}</p>
            <div onClick={handleClose} className={styles["header-close"]}>
              <img src="/images/close-modal-icon.png" alt="Close" />
            </div>
          </Modal.Header>
          {isLoading ? (
            <div className={styles["loading"]}>
              <Spinner
                className="spinner-loading"
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <Modal.Body className={styles["modal-body"]}>
              <div className="mar_b16">
                <label className={styles["modal-body-label"]}>
                  Saved Search Name <span className="error text-danger">*</span>
                </label>
                <input
                  className={clsx("form-control", styles["modal-body-input"])}
                  type="text"
                  placeholder="Enter Saved Search Name"
                  id="searchName"
                  name="searchName"
                  value={formik.values.searchName}
                  onChange={formik.handleChange}
                />
                {formik.errors.searchName && formik.touched.searchName && (
                  <span className="error text-danger">
                    {formik.errors.searchName}
                  </span>
                )}
              </div>
              {isCombineSearch && !isUpdateName && (
                <div className="mar_b16">
                  <label className={styles["modal-body-label"]}>
                    Select Criteria
                  </label>
                  <DropdownSelector
                    widthStyle="auto"
                    size="md"
                    className="form-select"
                    name="valueCriteria"
                    itemList={criteriaList}
                    selectedItem={formik.values.valueCriteria}
                    onSelect={(event) => {
                      formik.setFieldValue("valueCriteria", event.name);
                    }}
                  />
                </div>
              )}
              <div className="d-flex align-items-center">
                <Checkbox
                  label="Set as private"
                  name="isPrivate"
                  id="isPrivate"
                  size="lg"
                  isChecked={formik.values.isPrivate}
                  handleClick={formik.handleChange}
                  value={formik.values.isPrivate}
                />
              </div>
            </Modal.Body>
          )}
          <Modal.Footer className={styles["modal-footer"]}>
            <Button
              name={
                [SEARCH_MODE.result, SEARCH_MODE.original].includes(mode)
                  ? COMMON_TEXT.skip
                  : COMMON_TEXT.cancel
              }
              handleClick={handleClose}
            />
            <Button
              name={getButtonName()}
              isBorder={false}
              className="btn-primary-fill"
              handleClick={formik.handleSubmit}
              isDisabled={(!searchId && isDisabled()) || isLoading}
            />
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

PopupAddSavedSearch.propTypes = {
  isShow: PropTypes.bool,
  isCombineSearch: PropTypes.bool,
  isUpdate: PropTypes.bool,
  isUpdateName: PropTypes.bool,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
  mode: PropTypes.string,
  searchId: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  condition: PropTypes.func,
};

export default PopupAddSavedSearch;
