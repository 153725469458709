import React from "react";
import PropTypes from "prop-types";
import styles from "./FormAction.module.scss";

const FormAction = ({ onSave, onCancel, onEdit, onDelete, isEdit = false }) => {
  const renderSaveAndCancel = () => (
    <div className={styles["form-action"]}>
      <span onClick={onSave}>
        <img src="/images/associated-save.svg" alt="save" />
      </span>
      <span onClick={onCancel}>
        <img src="/images/associated-delete.svg" alt="cancel" />
      </span>
    </div>
  );

  const renderEditAndDelete = () => (
    <div className={styles["actions"]}>
      {onEdit && (
        <span onClick={onEdit}>
          <img src="/images/edit-ic.png" alt="edit" />
        </span>
      )}
      {onDelete && (
        <span onClick={onDelete}>
          <img src="/images/delete-ic.png" alt="delete" />
        </span>
      )}
    </div>
  );

  return isEdit ? renderEditAndDelete() : renderSaveAndCancel();
};

FormAction.propTypes = {
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FormAction;
