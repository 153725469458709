import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import SearchFilter from "../search-input/search-filter/SearchFilter";
import SuggestionItem from "../search-input/suggestion-item/SuggestionItem";

// Styles
import styles from "./SuggestionList.module.scss";

const SuggestionList = ({
  suggestionList = [],
  placeholder,
  selectedItem,
  getDataSelected = () => {},
  clearSearch = false,
  hideSearchIcon = false,
}) => {
  const [searchInput, setSearchInput] = useState("");

  const filteredList = suggestionList?.filter(
    (item) =>
      item.label
        ?.trim()
        .toLowerCase()
        .indexOf(searchInput.trim().toLowerCase()) !== -1
  );

  const renderNoResultData = () => (
    <p className={styles["no-result"]}>No Result</p>
  );

  useEffect(() => {
    setSearchInput("");
  }, [clearSearch]);

  return (
    <div className={styles["wrap"]}>
      {placeholder && (
        <SearchFilter
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          placeholder={placeholder}
          handleClearSearch={() => setSearchInput("")}
          hideSearchIcon={hideSearchIcon}
        />
      )}
      {suggestionList?.length > 0 ? (
        <ul>
          {filteredList.length > 0 ? (
            <React.Fragment>
              {filteredList.map((item) => (
                <li
                  className={
                    item.value === selectedItem ? styles["active"] : ""
                  }
                  key={item.value}
                  onClick={() => getDataSelected(item)}
                  title={item.label}
                >
                  {searchInput ? (
                    <SuggestionItem
                      data={item.label}
                      input={searchInput.trim()}
                    />
                  ) : (
                    <span>{item.label}</span>
                  )}
                </li>
              ))}
            </React.Fragment>
          ) : (
            renderNoResultData()
          )}
        </ul>
      ) : (
        renderNoResultData()
      )}
    </div>
  );
};

SuggestionList.propTypes = {
  suggestionList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  placeholder: PropTypes.string,
  selectedItem: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clearSearch: PropTypes.bool,
  hideSearchIcon: PropTypes.bool,
  getDataSelected: PropTypes.func,
};

export default SuggestionList;
