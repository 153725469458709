import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

// Components
import { DateSelector } from "components/shared/date-picker/DateSelector";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";

// Styles
import styles from "./PreviewChatControl.module.scss";

const PreviewChatControl = (props) => {
  const {
    searchInput,
    onSearchByDate,
    onSearchKeyword,
    isLoading,
    onClearSearch,
    setIsShowPreviewModal,
  } = props;
  const isShowPreviewModal = useSelector(
    (state) => state.is.isShowPreviewModal
  );
  const datePickerData = useSelector(
    (state) => state.datePicker
  );

  const inputRef = useRef();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isModalPreview, setIsModalPreview] = useState(false);

  const onSearchHandle = () => {
    if (isLoading) return;
    const keyword = inputRef.current.value.trim();
    onSearchKeyword(keyword);
  };

  const handleOpenDatePicker = () => {
    if (isLoading) return;
    setShowDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setShowDatePicker(false);
  };

  const handleClearSearch = () => {
    if (isLoading) return;
    onClearSearch();
    onSearchKeyword("");
  };

  useEffect(() => {
    if (!isShowPreviewModal && isModalPreview) {
      if (searchInput?.length > 0) handleClearSearch();
      if (setIsShowPreviewModal) setIsShowPreviewModal(false);
    }
    setIsModalPreview(isShowPreviewModal);
  }, [isShowPreviewModal]);

  return (
    <div className={styles["preview-control-container"]}>
        <DateSelector
            showDatePicker={showDatePicker}
            handleClose={handleCloseDatePicker}
            handleSubmit={(data) => {
            onSearchByDate(data);
            handleCloseDatePicker();
            }}
            data={{ 
            startDate: datePickerData.messageViewDatePicker.dateTimeDataStart, 
            endDate: datePickerData.messageViewDatePicker.dateTimeDataEnd 
            }}
            stateName="messageViewDatePicker"
        />
        <div className={styles["preview-control-search"]}>
            <div className="mar_r10 cursor-pointer">
                <img
                    src={
                    datePickerData.messageViewDatePicker.dateTimeDataStart !== null ||
                    datePickerData.messageViewDatePicker.dateTimeDataEnd !== null
                        ? "/images/calendar-icon-checked.svg"
                        : "/images/calendar-icon.svg"
                    }
                    alt="icon"
                    onClick={handleOpenDatePicker}
                />
            </div>
            <SearchInputGeneral
                inputRef={inputRef}
                placeholder="Search"
                name="search"
                onSubmitSearch={onSearchHandle}
                handleClearSearch={handleClearSearch}
                searchInput={searchInput}
                hasPreventClearText={isLoading}
            />
        </div>
    </div>
  );
};

PreviewChatControl.propTypes = {
  searchInput: PropTypes.any,
  onSearchByDate: PropTypes.func,
  onSearchKeyword: PropTypes.func,
  isLoading: PropTypes.bool,
  onClearSearch: PropTypes.func,
  setIsShowPreviewModal: PropTypes.func
};

export { PreviewChatControl };
