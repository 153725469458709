import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Components
import InformationTabContainer from "../info-tab-container/InformationTabContainer";

// Constants
import { TIMELINE_INFORMATION } from "constants/DataType";
import { PARAMETERS } from "constants/Common";

const InfoAudio = ({ audioInfo }) => {
  const { duration } = useSelector((state) => state.mediaControl);

  const [infoResultList, setInfoResultList] = useState([]);

  useEffect(() => {
    const infoList = TIMELINE_INFORMATION.audio.map((item) => ({
      key: item.key,
      label: item.label,
    }));

    if (duration) {
      const durationIndex  = infoList.findIndex((item) => item.key === 'duration');
      if (durationIndex > -1) {
        infoList[durationIndex].value = duration;
      }
    }
    
    setInfoResultList(infoList);
  }, [duration]);

  return (
    <InformationTabContainer
      infoResultLabelList={infoResultList}
      dataType={audioInfo[PARAMETERS.type]}
      itemInfo={audioInfo}    
    />
  );
};

InfoAudio.propTypes = {
  audioInfo: PropTypes.object,
  duration: PropTypes.string,
};

export default InfoAudio;
