import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Hooks
import { useOnClickOutside } from "hook/click-outside";

// Components
import DropDownBox from "../dropdown-box/DropDownBox";

// Styles
import styles from "./TabList.module.scss";
import clsx from "clsx";

const TabList = (props) => {
  const {
    tabList = [],
    onClickTab = () => { },
    currentTab = {},
    size = "md",
  } = props;

  const menuRef = useRef(null);

  const [tabActive, setTabActive] = useState({
    parentTab: currentTab.parentTab,
    childTab: null,
  });
  const [parentHasChildId, setParentHasChildId] = useState(null);

  useEffect(() => {
    setTabActive(currentTab);
  }, [currentTab]);

  useOnClickOutside(menuRef, () => setParentHasChildId(null));

  return (
    <ul className={styles["tab-list"]} ref={menuRef}>
      {tabList.map((tab) => (
        <li
          key={tab.id}
          className={clsx(
            styles[tabActive.parentTab === tab.id && !tab.disabled ? "active" : ""],
            styles["tab-item"],
            styles[`${size}`],
            styles[tab.disabled ? "disabled" : ""]
          )}
          onClick={() => {
            if (tab.disabled) return;
            if (!tab?.children) {
              setTabActive({ parentTab: tab.id, childTab: null });
              onClickTab({ parentTab: tab.id, childTab: null });
              setParentHasChildId(null);
            } else setParentHasChildId(tab.id);
          }}
        >
          <span className={clsx(
            styles[tab?.children ? "arrow-down" : ""], 
            styles[tab?.disabled ? "disabled" : "normal"]
          )}>
            {tab.name}
          </span>
          {tab?.children?.length > 0 && (
            <div
              className={styles["children"]}
              hidden={parentHasChildId !== tab.id}
            >
              <DropDownBox
                dataList={tab.children}
                currentId={currentTab.childTab}
                onclickItem={({ id, path }) => {
                  setParentHasChildId(null);
                  onClickTab({ parentTab: tab.id, childTab: id });
                  if (path) setTabActive({ parentTab: tab.id, childTab: id });
                }}
              />
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

TabList.propTypes = {
  tabList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      path: PropTypes.string,
      disabled: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
          path: PropTypes.string,
        })
      ),
    })
  ),
  currentTab: PropTypes.shape({
    childTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    parentTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  size: PropTypes.oneOf(["md", "lg"]),
  onClickTab: PropTypes.func,
};

export default TabList;
