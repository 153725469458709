import { EVENT_KEY_LISTENER } from "constants/Common";
import { useEffect } from "react";

export const useOnClickOutside = (ref, handler, element) => {
  useEffect(() => {
    const listener = (event) => {
      if (
        !ref.current ||
        ref.current?.contains(event.target) ||
        element?.contains(event.target)
      )
        return;
      handler(event);
    };
    document.addEventListener(EVENT_KEY_LISTENER.mousedown, listener);
    document.addEventListener(EVENT_KEY_LISTENER.touchstart, listener);
    return () => {
      document.removeEventListener(EVENT_KEY_LISTENER.mousedown, listener);
      document.removeEventListener(EVENT_KEY_LISTENER.touchstart, listener);
    };
  }, [ref, handler]);
};
