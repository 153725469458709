export const mapSearchError = (data) => {
    let errorMessage = '';

    //this first condition should not typically happen
    if (!data || !data.errorCode || !data.message) {
      errorMessage = 'An error occured during search.';
    } 
    else if (data.errorCode === 'TokenizationException') {
      errorMessage = data.message;
    }
    else if (data.errorCode === 'InvalidSyntaxException') {
      errorMessage = 'Invalid Search Syntax: There’s an issue with the order of terms or operators in the search query.';
    }

    return errorMessage
  }