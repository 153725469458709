import isString from "lodash/isString";
import { getTimeUTC } from "./DateTimeFormatterHelper";

export const trimObject = (obj) =>
  Object.keys(obj).reduce((acc, curr) => {
    acc[curr] = isString(obj[curr]) ? obj[curr]?.trim() : obj[curr];
    return acc;
  }, {});

const removeEmpty = (obj) =>
  Object.fromEntries(Object.entries(obj).filter(([, value]) => value != null));

export const checkBodyConvertTimeToUTC = (obj = {}) => {
  const newObj = {
    ...obj,
    start: obj.start ? getTimeUTC(obj.start) : null,
    Start: obj.Start ? getTimeUTC(obj.Start) : null,
    end: obj.end ? getTimeUTC(obj.end) : null,
    End: obj.End ? getTimeUTC(obj.End) : null,
    startDate: obj.startDate ? getTimeUTC(obj.startDate) : null,
    endDate: obj.endDate ? getTimeUTC(obj.endDate) : null,
  };
  return removeEmpty(newObj);
};

export const isReadValue = (value) =>
  value !== "null" &&
  value !== "undefined" &&
  value !== null &&
  value !== undefined;

export const createFormData = (params) => {
  const formData = new FormData();
  Object.keys(params).forEach((param) => {
    formData.append([param], params[param]);
  });
  return formData;
};

export const isEmptyObject = (object = {}) => Object.keys(object).length === 0;

export const isAllFieldEmpty = (object = {}) =>
  Object.values(object).every((x) => x === null || x === "");

export const removeEmptyStringObject = (obj = {}) =>
  Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== ""));
