import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Services
import * as ProjectAdminService from "services/ProjectAdministrationService";
import * as EntityManagementService from "services/EntityManagementService";

import DefaultAvatar from "assets/images/default_avatar.png";

import { addUserAvatarUrl, addEntityAvatarUrl } from "store/AvatarReducer";

// Styles
import styles from './MatterAvatar.module.scss';

const MatterAvatar = (props) => {
    const {
        matterId,
        id,
        useDefault,
        avatarType,
        deferFetch } = props;

    const dispatch = useDispatch();
    const { userUrls, entityUrls, fetchComplete } = useSelector((state) => state.avatar);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(DefaultAvatar);

    useEffect(() => {
        const fetchAvatar = async () => {
            let url;
            try {
                if (avatarType === 'user') {
                    const urlIndex = userUrls.findIndex((item) => item.id === id);
                    if (urlIndex !== -1) {
                        url = userUrls[urlIndex].url;
                    } else {
                        const response = await ProjectAdminService.getMatterAvatar(matterId, id);
                        url = URL.createObjectURL(response.data);
                        dispatch(addUserAvatarUrl({ id, url }));
                    }
                } else {
                    const urlIndex = entityUrls.findIndex((item) => item.id === id);
                    if (urlIndex !== -1) {
                        url = entityUrls[urlIndex].url;
                    } else {
                        const response = await EntityManagementService.getEntityAvatar(matterId, id);
                        url = URL.createObjectURL(response.data);
                        dispatch(addEntityAvatarUrl({ id, url }));
                    }
                }
            } catch (error) {
                url = DefaultAvatar;
            }

            setAvatarUrl(url);
            setHasLoaded(true);
        };
        if (!hasLoaded && id && !deferFetch) {
            if (useDefault) {
                setHasLoaded(true);
            } else {
                fetchAvatar();
            }
        }
    }, [matterId, id]);

    useEffect(() => {
        if (!hasLoaded && id) {
            try {
                const numId = Number(id);
                const url = entityUrls.find((item) => item.id === numId)?.url;
                if (url) {
                    setAvatarUrl(url);
                }
            } catch {
                console.error('Error while loading avatar');
            }
            setHasLoaded(true);
        }
    }, [fetchComplete, entityUrls])

    return (
        <div className={styles["matter-avatar-container"]}>
            <img src={avatarUrl} alt="Matter Avatar" />
        </div>)
};

MatterAvatar.propTypes = {
    matterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    useDefault: PropTypes.bool,
    avatarType: PropTypes.string,
    deferFetch: PropTypes.bool,
};

export default MatterAvatar;