import React from "react";
import PropTypes from "prop-types";

// Components
import InformationTabContainer from "../info-tab-container/InformationTabContainer";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoDatabase = ({ databaseInfo }) => {
  return (
    <InformationTabContainer
      infoResultLabelList={TIMELINE_INFORMATION.database}
      dataType={databaseInfo[PARAMETERS.type]}
      itemInfo={databaseInfo}
    />
  )
};

InfoDatabase.propTypes = {
  databaseInfo: PropTypes.object,
};

export default InfoDatabase;
