import React from "react";
import PropTypes from "prop-types";

// helper
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoHistoryLink = ({ historyLinkInfo }) => {
  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) => (
          <InfoResultItem
            key={item.label}
            label={item.label}
            value={getInformationByKey(item.key, historyLinkInfo)}
            isIcon={item.key === PARAMETERS.typeLabel}
            type={historyLinkInfo[PARAMETERS.type]}
          />
        ))}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.historyLink)}</>;
};

InfoHistoryLink.propTypes = {
  historyLinkInfo: PropTypes.object,
};

export default InfoHistoryLink;
