import React from "react";
import PropTypes from "prop-types";
import { useToken } from "hook/auth";
import clsx from "clsx";

// Constants
import { ACTION_NAME } from "constants/ExportConstant";

// Styles
import styles from "./ActionExport.module.scss";

const ActionExport = ({ actionList = [], userCurrentId }) => {
  const { isAdmin } = useToken();
  return (
    <div className={styles["wrap"]}>
      {actionList.length > 0 ? (
        actionList.map((action, index) => {
          const { label, onClick, isActive = true, userCreateId } = action;

          // Check role display action export
          const unAlowActions = [
            ACTION_NAME.delete,
            ACTION_NAME.edit,
            ACTION_NAME.reschedule,
            ACTION_NAME.cancelSchedule,
            ACTION_NAME.run,
            ACTION_NAME.cancel,
            ACTION_NAME.download,
            ACTION_NAME.restart,
          ];

          if (unAlowActions.includes(label))
            if (userCurrentId !== userCreateId && !isAdmin()) return "";

          return (
            <div
              key={index}
              onClick={onClick}
              className={clsx(
                styles["status"],
                styles[isActive ? "" : "disable"]
              )}
            >
              {label}
            </div>
          );
        })
      ) : (
        <div className={clsx(styles["no-action"])}>No action</div>
      )}
    </div>
  );
};

ActionExport.propTypes = {
  actionList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      isActive: PropTypes.bool,
      onClick: PropTypes.func,
      userCreateId: PropTypes.string,
    })
  ),
  userCurrentId: PropTypes.string,
};

export default ActionExport;
