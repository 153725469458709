import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

export const Loading = (props) => {
  const { loading, isBlur = false } = props;
  return (
    <>
      {loading && (
        <div className="loading">
          <Spinner
            className="spinner-border-edit"
            variant="primary"
            animation="border"
          />
          <span
            className={`loading-background ${isBlur && "blur-background"}`}
          ></span>
        </div>
      )}
    </>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  isBlur: PropTypes.bool,
};
