import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import lowerCase from "lodash/lowerCase";
import clsx from "clsx";

//Components
import { DropdownSelector } from "components/shared/dropdown-selector/DropdownSelector";

//Constant
import { SEARCH_SELECTOR } from "constants/SearchSelect";

//Styles
import styles from "./KeyBlock.module.scss";

const allowPressNumeric = (e) => {
  exceptThisSymbols.includes(e.key) && e.preventDefault();
};

const methodList = [
  { name: SEARCH_SELECTOR.method.and },
  { name: SEARCH_SELECTOR.method.or },
  { name: SEARCH_SELECTOR.method.within },
  { name: SEARCH_SELECTOR.method.nested },
];
const exceptThisSymbols = ["e", "E", "+", "-", "."];

const KeyBlockNestedChild = (props) => {
  const {
    nestedData,
    index,
    parentIndex,
    nestLength,
    borderData,
    onUpdateQueries,
    onAddQueriesChild,
    queriesLength,
    onRemove,
  } = props;

  const [keywords, setKeyWords] = useState(nestedData.keyword.trim() || "");

  const handleInputKeywordsChild = (e) => {
    setKeyWords(e.target.value);
    onUpdateQueries({
      index,
      parentIndex,
      updateType: "keyword",
      data: e.target.value,
    });
  };

  const handleInputWithinWordsChild = (e) => {
    onUpdateQueries({
      index,
      parentIndex,
      updateType: "withinWords",
      data: e.target.value,
    });
  };

  const handleChangeMethodChild = (e) => {
    onUpdateQueries({
      index,
      parentIndex,
      updateType: "method",
      data: e.name,
    });
  };

  const handleAddQueriesChild = () => {
    if (
      !keywords.trim() &&
      lowerCase(nestedData.method) !== lowerCase(SEARCH_SELECTOR.method.nested)
    )
      return;
    onAddQueriesChild({ grandIndex: "", parentIndex });
  };

  const handleAddQueriesGrandchild = (e) => {
    onAddQueriesChild({ grandIndex: e.grandIndex, parentIndex: e.parentIndex });
  };

  const handleUpdateQueriesFromChild = (e) => {
    onUpdateQueries({
      index: e.index,
      parentIndex: e.parentIndex,
      grandIndex: e.grandIndex,
      updateType: e.updateType,
      data: e.data,
    });
  };

  const handleChangeNestedMethodChild = (e) => {
    onUpdateQueries({
      index: index,
      parentIndex: parentIndex,
      updateType: "nestedMethod",
      data: e.name,
    });
  };

  const handleRemoveChild = () => {
    if (nestedData.method !== SEARCH_SELECTOR.method.nested && nestLength < 3)
      return;
    onRemove({ index, parentIndex, data: nestedData });
  };

  useEffect(() => {
    setKeyWords(nestedData.keyword || "");
  }, [nestedData]);

  return (
    <div
      className={clsx(
        styles["kb-nested-child"],
        styles["kb-nested-child-1"],
        borderData.noBranchLine && styles["kb-no-border"],
        borderData.indexOfLastBorder === index &&
          borderData.indexOfLastBorder < queriesLength - 1 &&
          styles["kb-last-border"]
      )}
    >
      {index !== 0 && (
        <div
          className={clsx(
            styles["kb-nested-method"],
            lowerCase(nestedData.method) ===
              lowerCase(SEARCH_SELECTOR.method.nested) &&
              styles["kb-nested-method-main"]
          )}
        >
          <div className="d-flex align-items-center">
            <div className={styles["kb-link-type"]}>
              <DropdownSelector
                scrollable={false}
                itemList={methodList}
                size="sm"
                selectedItem={nestedData.method}
                onSelect={handleChangeMethodChild}
              />
            </div>
            {lowerCase(nestedData.method) ===
              lowerCase(SEARCH_SELECTOR.method.within) && (
              <>
                <input
                  className={clsx(
                    styles["kb-input"],
                    styles["kb-input-within"],
                    !nestedData.withinWords && styles["kb-within-empty"]
                  )}
                  value={nestedData.withinWords}
                  type="number"
                  onKeyDown={allowPressNumeric}
                  onChange={handleInputWithinWordsChild}
                  placeholder="Numbers"
                />
                <span className={styles["kb-within-words"]}>Words</span>
              </>
            )}
            {lowerCase(nestedData.method) ===
              lowerCase(SEARCH_SELECTOR.method.nested) && (
              <div className={clsx(styles["kb-link-type"], "ms-3")}>
                <DropdownSelector
                  scrollable={false}
                  itemList={methodList.filter(
                    (item) =>
                      ![
                        SEARCH_SELECTOR.method.nested,
                        SEARCH_SELECTOR.method.within,
                      ].includes(item.name)
                  )}
                  size="sm"
                  selectedItem={nestedData.nestedMethod}
                  onSelect={handleChangeNestedMethodChild}
                />
              </div>
            )}
            {lowerCase(nestedData.nestedMethod) ===
              lowerCase(SEARCH_SELECTOR.method.within) && (
              <>
                <input
                  className={clsx(
                    styles["kb-input"],
                    styles["kb-input-within"],
                    !nestedData.withinWords && styles["kb-within-empty"]
                  )}
                  value={nestedData.withinWords}
                  type="number"
                  onKeyDown={allowPressNumeric}
                  onChange={handleInputWithinWordsChild}
                  placeholder="Numbers"
                />
                <span className={styles["kb-within-words"]}>Words</span>
              </>
            )}
          </div>
          {lowerCase(nestedData.method) ===
            lowerCase(SEARCH_SELECTOR.method.nested) && (
            <img
              className="cursor-pointer"
              src="/images/icons/trash.svg"
              onClick={handleRemoveChild}
              alt="Trash Icon"
            />
          )}
        </div>
      )}
      {lowerCase(nestedData.method) !==
        lowerCase(SEARCH_SELECTOR.method.nested) && (
        <div
          className={clsx(
            styles["kb-actions"],
            index === queriesLength - 1 && styles["kb-actions-child"]
          )}
        >
          <input
            className={styles["kb-input"]}
            value={keywords}
            placeholder="Input keywords"
            onInput={handleInputKeywordsChild}
          />
          <img
            src={`/images/icons/trash${nestLength > 2 ? "" : "-disabled"}.svg`}
            onClick={handleRemoveChild}
            alt="Trash Icon"
          />
        </div>
      )}

      {nestedData.nested?.length > 0 &&
        nestedData.nested.map((item, ind) => (
          <KeyBlockNestedGrandchild
            key={ind}
            index={ind}
            parentIndex={index}
            grandIndex={parentIndex}
            queriesLength={nestedData.nested.length}
            grandchildData={item}
            onUpdateQueries={handleUpdateQueriesFromChild}
            onAddQueriesGrandChild={handleAddQueriesGrandchild}
            onRemove={(e) => onRemove(e)}
          />
        ))}
      {index === queriesLength - 1 && (
        <div
          className={clsx(
            styles["kb-add"],
            !keywords.trim() &&
              lowerCase(nestedData.method) !==
                lowerCase(SEARCH_SELECTOR.method.nested) &&
              styles["kb-add-disabled"]
          )}
          onClick={handleAddQueriesChild}
        >
          <img
            src={`/images/icons/add${
              !keywords.trim() &&
              lowerCase(nestedData.method) !==
                lowerCase(SEARCH_SELECTOR.method.nested)
                ? "-disabled"
                : ""
            }.svg`}
            alt="Add icon"
          />
          <span>Add more queries</span>
        </div>
      )}
    </div>
  );
};

const KeyBlockNestedGrandchild = (props) => {
  const {
    grandchildData,
    index,
    parentIndex,
    grandIndex,
    onUpdateQueries,
    queriesLength,
    onAddQueriesGrandChild,
    onRemove,
  } = props;

  const [grandchildKeyword, setGrandchildKeyword] = useState(
    grandchildData.keyword.trim() || ""
  );

  const handleAddQueriesGrandchild = () => {
    if (!grandchildKeyword.trim()) return;
    onAddQueriesGrandChild({ grandIndex, parentIndex });
  };

  const handleInputGrandchildKeyWord = (e) => {
    setGrandchildKeyword(e.target.value);
    onUpdateQueries({
      index,
      parentIndex,
      grandIndex,
      updateType: "keyword",
      data: e.target.value,
    });
  };

  const handleInputWithinWordsGrandChild = (e) => {
    onUpdateQueries({
      index,
      parentIndex,
      grandIndex,
      updateType: "withinWords",
      data: e.target.value,
    });
  };

  const handleMethodGrandChild = (e) => {
    onUpdateQueries({
      index,
      parentIndex,
      grandIndex,
      updateType: "method",
      data: e.name,
    });
  };

  const handleRemove = () => {
    if (queriesLength < 3) return;
    onRemove({ index, parentIndex, grandIndex, data: grandchildData });
  };

  useEffect(() => {
    setGrandchildKeyword(grandchildData.keyword || "");
  }, [grandchildData]);

  return (
    <div
      className={clsx(styles["kb-nested-child"], styles["kb-nested-child-2"])}
    >
      {index !== 0 && (
        <div className={styles["kb-nested-method"]}>
          <div className={styles["kb-link-type"]}>
            <DropdownSelector
              scrollable={false}
              itemList={methodList.filter(
                (item) => item.name !== SEARCH_SELECTOR.method.nested
              )}
              size="sm"
              selectedItem={grandchildData.method}
              onSelect={handleMethodGrandChild}
            />
          </div>
          {lowerCase(grandchildData.method) ===
            lowerCase(SEARCH_SELECTOR.method.within) && (
            <>
              <input
                className={clsx(
                  styles["kb-input"],
                  styles["kb-input-within"],
                  !grandchildData.withinWords && styles["kb-within-empty"]
                )}
                value={grandchildData.withinWords}
                type="number"
                onKeyDown={allowPressNumeric}
                onChange={handleInputWithinWordsGrandChild}
                placeholder="Numbers"
              />
              <span className={styles["kb-within-words"]}>Words</span>
            </>
          )}
        </div>
      )}
      {lowerCase(grandchildData.method) !==
        lowerCase(SEARCH_SELECTOR.method.nested) && (
        <div
          className={clsx(
            styles["kb-actions"],
            index === queriesLength - 1 && styles["kb-actions-child"],
            index === 0 && styles["kb-nested-first"]
          )}
        >
          <input
            className={styles["kb-input"]}
            value={grandchildKeyword}
            placeholder="Input keywords"
            onInput={handleInputGrandchildKeyWord}
          />
          <img
            src={`/images/icons/trash${
              queriesLength > 2 ? "" : "-disabled"
            }.svg`}
            onClick={handleRemove}
            alt="Trash Icon"
          />
        </div>
      )}
      {index === queriesLength - 1 && (
        <div
          className={clsx(
            styles["kb-add"],
            !grandchildData.keyword.trim() && styles["kb-add-disabled"]
          )}
          onClick={handleAddQueriesGrandchild}
        >
          <img
            src={`/images/icons/add${
              !grandchildData.keyword.trim() ? "-disabled" : ""
            }.svg`}
            alt="Add Icon"
          />
          <span>Add more queries</span>
        </div>
      )}
    </div>
  );
};

const KeyBlock = (props) => {
  const {
    data,
    index,
    onUpdateQueries,
    onAddQueries,
    onUpdateQueriesChild,
    queriesLength,
    onRemove,
  } = props;
  const [keywords, setKeyWords] = useState(data?.keyword.trim() || "");
  const handleInputKeyWords = (e) => {
    setKeyWords(e.target.value);
    onUpdateQueries({
      index,
      updateType: "keyword",
      data: e.target.value,
    });
  };

  const handleRemove = () => {
    if (!keywords.trim() && index === 0) return;
    onRemove({ index: index === 0 ? "" : index, data });
  };

  const handleSelectLinkType = (e) => {
    onUpdateQueries({
      index,
      updateType: "method",
      data: e.name,
    });
  };

  const handleInputWithinWords = (e) => {
    onUpdateQueries({
      index,
      updateType: "withinWords",
      data: e.target.value,
    });
  };

  const handleAddQuery = () => {
    if (
      !keywords.trim() &&
      lowerCase(data.method) !== lowerCase(SEARCH_SELECTOR.method.nested)
    )
      return;
    handleAddQueries();
  };

  const handleAddQueries = (e) => {
    onAddQueries(e);
  };

  const handleSelectLinkTypeNested = (e) => {
    onUpdateQueries({
      index,
      updateType: "nestedMethod",
      data: e.name,
    });
  };

  const handleUpdateQueriesChild = (e) => {
    onUpdateQueriesChild({ ...e });
  };

  const checkNoBranchLine = (nestQueries, nestItem, nestIndex) => {
    let noBranchLine = true;
    let borderList = [];
    nestQueries.forEach((item, index) => {
      if (index >= nestIndex && item.method !== SEARCH_SELECTOR.method.nested) {
        noBranchLine = false;
        borderList.push(index);
        return;
      }
    });
    if (nestIndex === 0 || nestItem.method !== SEARCH_SELECTOR.method.nested)
      noBranchLine = false;
    return {
      noBranchLine,
      indexOfLastBorder:
        borderList.length > 0 ? borderList[borderList.length - 1] : -1,
    };
  };

  useEffect(() => {
    setKeyWords(data?.keyword || "");
  }, [data]);

  return (
    <div className={styles["key-block"]}>
      {index !== 0 && (
        <div
          className={clsx(
            styles["kb-method"],
            lowerCase(data.method) ===
              lowerCase(SEARCH_SELECTOR.method.nested) &&
              styles["kb-method-nested"]
          )}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className={styles["kb-link-type"]}>
                <DropdownSelector
                  scrollable={false}
                  itemList={methodList}
                  size="sm"
                  selectedItem={data.method}
                  onSelect={handleSelectLinkType}
                />
              </div>
              {lowerCase(data.method) ===
                lowerCase(SEARCH_SELECTOR.method.within) && (
                <>
                  <input
                    className={clsx(
                      styles["kb-input"],
                      styles["kb-input-within"],
                      !data.withinWords && styles["kb-within-empty"]
                    )}
                    value={data.withinWords}
                    type="number"
                    onKeyDown={allowPressNumeric}
                    onChange={handleInputWithinWords}
                    placeholder="Numbers"
                  />
                  <span className={styles["kb-within-words"]}>Words</span>
                </>
              )}
              {lowerCase(data.method) ===
                lowerCase(SEARCH_SELECTOR.method.nested) && (
                <div className={clsx(styles["kb-link-type"], "ms-3")}>
                  <DropdownSelector
                    scrollable={false}
                    itemList={methodList.filter(
                      (item) =>
                        ![
                          SEARCH_SELECTOR.method.nested,
                          SEARCH_SELECTOR.method.within,
                        ].includes(item.name)
                    )}
                    size="sm"
                    selectedItem={data.nestedMethod}
                    onSelect={handleSelectLinkTypeNested}
                  />
                </div>
              )}
              {lowerCase(data.nestedMethod) ===
                lowerCase(SEARCH_SELECTOR.method.within) && (
                <>
                  <input
                    className={clsx(
                      styles["kb-input"],
                      styles["kb-input-within"],
                      !data.withinWords && styles["kb-within-empty"]
                    )}
                    value={data.withinWords}
                    type="number"
                    onKeyDown={allowPressNumeric}
                    onChange={handleInputWithinWords}
                    placeholder="Numbers"
                  />
                  <span className={styles["kb-within-words"]}>Words</span>
                </>
              )}
            </div>
            {lowerCase(data.method) ===
              lowerCase(SEARCH_SELECTOR.method.nested) && (
              <img
                className="cursor-pointer"
                src="/images/icons/trash.svg"
                onClick={handleRemove}
                alt="Trash icon"
              />
            )}
          </div>
        </div>
      )}
      <div className={styles["kb-actions"]}>
        {lowerCase(data.method) !==
          lowerCase(SEARCH_SELECTOR.method.nested) && (
          <>
            <input
              className={styles["kb-input"]}
              value={keywords}
              onChange={handleInputKeyWords}
              placeholder="Input keywords"
            />
            <img
              src={`/images/icons/trash${
                !keywords.trim() && index === 0 ? "-disabled" : ""
              }.svg`}
              onClick={handleRemove}
              alt="Trash icon"
            />
          </>
        )}
      </div>
      <div className={styles["kb-footer"]}>
        {data?.nested?.length > 0 &&
          data.nested.map((nestItem, nestIndex) => (
            <KeyBlockNestedChild
              key={nestIndex}
              nestLength={data.nested.length}
              queriesLength={data.nested.length}
              index={nestIndex}
              parentIndex={index}
              nestedData={nestItem}
              onUpdateQueries={handleUpdateQueriesChild}
              onAddQueriesChild={(e) => handleAddQueries(e)}
              onRemove={(e) => onRemove(e)}
              borderData={checkNoBranchLine(data.nested, nestItem, nestIndex)}
            />
          ))}
        <div className={styles["kb-method"]}>
          {index === queriesLength - 1 && (
            <div
              className={clsx(
                styles["kb-add"],
                !keywords.trim() &&
                  lowerCase(data.method) !==
                    lowerCase(SEARCH_SELECTOR.method.nested) &&
                  styles["kb-add-disabled"]
              )}
              onClick={handleAddQuery}
            >
              <img
                src={`/images/icons/add${
                  !keywords.trim() &&
                  lowerCase(data.method) !==
                    lowerCase(SEARCH_SELECTOR.method.nested)
                    ? "-disabled"
                    : ""
                }.svg`}
                alt="Add icon"
              />
              <span>Add more queries</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

KeyBlock.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  queriesLength: PropTypes.number.isRequired,
  onUpdateQueries: PropTypes.func,
  onAddQueries: PropTypes.func,
  onUpdateQueriesChild: PropTypes.func,
  onRemove: PropTypes.func,
};

KeyBlockNestedChild.propTypes = {
  nestedData: PropTypes.object,
  index: PropTypes.number.isRequired,
  parentIndex: PropTypes.number.isRequired,
  queriesLength: PropTypes.number.isRequired,
  nestLength: PropTypes.number,
  borderData: PropTypes.object,
  onUpdateQueries: PropTypes.func,
  onAddQueriesChild: PropTypes.func,
  onRemove: PropTypes.func,
};

KeyBlockNestedGrandchild.propTypes = {
  grandchildData: PropTypes.object,
  index: PropTypes.number.isRequired,
  queriesLength: PropTypes.number.isRequired,
  parentIndex: PropTypes.number.isRequired,
  grandIndex: PropTypes.number.isRequired,
  onUpdateQueries: PropTypes.func,
  onAddQueriesGrandChild: PropTypes.func,
  onRemove: PropTypes.func,
};

export default KeyBlock;
