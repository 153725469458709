export const GetMassTagToastId = (jobId) => {
    return `mass-tag-${jobId}`;
}

export const GetMassTagConfirmationMsg = (resultCount) => {
    return `Are you sure you want to apply the tag action to ${resultCount} result(s)?`
}

export const MASS_TAG_TOAST_MSG = {
    UNTAG_SUCCESS: (taggedCount) => `Untagged ${taggedCount} result(s)!`,
    TAG_SUCCESS: (taggedCount) => `Tagged ${taggedCount} result(s)!`,
    APPLY_ERR: "An error occurred while applying tags. Please try again."
}
