const findSavedSearchInList = (array, searchID) => {
  const data = array.find((item) => item.value === searchID);
  return {
    searchID: data?.value || 0,
    searchName: data?.label || "",
  };
};

const findItemInTagsList = (array = [], tagIDs = []) => {
  const filterArray = array.filter((item) =>
    tagIDs.find((tag) => tag.tagID === item.tagID)
  );
  return filterArray.map((item) => item.tagID);
};

const tagSelected = (
  tagIdList = [],
  showTags = []
) => {
  let existTag = true;
  const globalTag = showTags.some((item) => tagIdList.includes(item.tagID));
  if ((tagIdList.length === 0 || !globalTag)) return false;
  if (showTags.every((item) => tagIdList.includes(item.tagID)))
    existTag = false;
  return existTag
};

export { findSavedSearchInList, findItemInTagsList, tagSelected };
