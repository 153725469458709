import React from "react";
import PropTypes from "prop-types";
import { FadeLoader, ScaleLoader } from "react-spinners";

// Components
import { Modal } from "react-bootstrap";

// Constants
import { COLORS } from "constants/Common";

// styles
import styles from "./PopupNotice.module.scss";
import "../styles.scss";

export const PopupNotice = (props) => {
  const { isShow = true, content = "", loading = false, remainingTime } = props;

  return (
    <Modal show={isShow} className="popup-confirm-dialog-user">
      <div className={styles["wrap"]}>
        <p className={styles["title"]}>Notification</p>
        {!loading && <ScaleLoader color={COLORS.main} />}
        <p className={styles["footer"]}>{content}</p>
        {loading && (
          <div className={styles["footer-loading"]}>
            <FadeLoader
              className={styles["fade-loader"]}
              color={COLORS.blue}
              height={6}
              width={2}
              margin={-9}
            />
            {remainingTime && <p>{`Remaining Time: ${remainingTime}`}</p>}
          </div>
        )}
      </div>
    </Modal>
  );
};

PopupNotice.propTypes = {
  isShow: PropTypes.bool,
  loading: PropTypes.bool,
  content: PropTypes.string,
  remainingTime: PropTypes.string,
};
