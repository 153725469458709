import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useOnClickOutside } from "hook/click-outside";

// Components
import FormAction from "components/shared/form-action/FormAction";
import ErrorInline from "components/shared/error-inline/ErrorInline";

// Constants
import { KEY_CODE } from "constants/Common";

// Styles
import styles from "./FormInput.module.scss";

const FormInput = ({
  value = "",
  placeholder = "",
  onChange = () => {},
  handleSaveForm = () => {},
  handleCancelForm = () => {},
  clearFocus = () => {},
  onFocus = () => {},
  errorMsg = "",
}) => {
  const actionRef = useRef(null);
  const inputRef = useRef(null);
  const [showAction, setShowAction] = useState(false);

  useOnClickOutside(inputRef, () => handleClickOustSide(), actionRef?.current);

  const handleClickOustSide = () => {
    setShowAction(false);
    clearFocus(null);
    handleCancelForm();
    if (inputRef.current) inputRef.current.blur();
  };

  const onSave = () => {
    handleSaveForm();
    setShowAction(false);
  };

  const onCancel = () => {
    handleCancelForm();
    setShowAction(false);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === KEY_CODE.tab) event.preventDefault();
    if (event.keyCode === KEY_CODE.enter) {
      value ? onSave() : onCancel();
      if (inputRef.current) inputRef.current.blur();
    }
  };

  return (
    <div className={styles["wrap"]}>
      <input
        className={clsx(
          "btn-input",
          styles["form-control-input"],
          errorMsg ? "btn-input-error" : ""
        )}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={value.length > 0}
        onFocus={() => onFocus() || setShowAction(true)}
        ref={inputRef}
        onKeyDown={onKeyDown}
      />
      {errorMsg && (
        <div className={styles["form-error"]}>
          <ErrorInline errorMsg={errorMsg} touched />
        </div>
      )}
      {(showAction || value.length > 1) && (
        <div ref={actionRef} className={styles["form-action"]}>
          <FormAction onSave={onSave} onCancel={onCancel} />
        </div>
      )}
    </div>
  );
};

FormInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  handleSaveForm: PropTypes.func,
  handleCancelForm: PropTypes.func,
  clearFocus: PropTypes.func,
};

export default FormInput;
