import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";

//Components
import { AttachmentReview } from "components/communication-review/AttachmentReview";
import EmbedImageAttachment from "components/shared/file-components/embed-image-attachment/EmbedImageAttachment";

// Styles
import styles from "./PreviewImage.module.scss";

const PreviewImage = ({
  data,
  isDetailPage = false,
  isShowAttachment = false,
}) => {
  const { id, hasNative, hasThumbnail, attachmentID, tags = [] } = data;

  const showImage = hasThumbnail | hasNative;

  const { projectId } = useParams();
  const [isShowPopup, setIsShowPopup] = useState(false);

  return (
    <>
      <figure
        className={clsx(
          styles["preview-img-wrap"],
          tags?.length > 0 ? styles["preview-img-wrap-fr-tag"] : ""
        )}
        onClick={() => isShowAttachment && setIsShowPopup(true)}
      >
        {showImage ? (
          <div
            className={clsx(
              styles["loading-blur"],
              isDetailPage ? styles["h-100"] : "",
              isShowAttachment ? styles["cursor-pointer"] : ""
            )}
          >
            <EmbedImageAttachment
              attachmentId={id ?? attachmentID}
              matterId={projectId}
            />
          </div>
        ) : (
          <p>The item is not available. Please try later. </p>
        )}
      </figure>
      {isShowPopup && (
        <AttachmentReview
          show={isShowPopup}
          onHide={() => setIsShowPopup(false)}
          attachmentId={id ?? attachmentID}
          projectId={parseInt(projectId)}
        />
      )}
    </>
  );
};

PreviewImage.propTypes = {
  isDetailPage: PropTypes.bool,
  isShowAttachment: PropTypes.bool,
  data: PropTypes.shape({
    contentType: PropTypes.string,
    title: PropTypes.string,
    hasNative: PropTypes.bool,
    hasThumbnail: PropTypes.bool,
    attachmentID: PropTypes.string,
    id: PropTypes.string,
    tags: PropTypes.array,
  }),
};

export default PreviewImage;
