import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { useOnClickOutside } from "hook/click-outside";
import clsx from "clsx";

// Components
import SuggestionItem from "components/shared/search-input/suggestion-item/SuggestionItem";
import SearchFilter from "components/shared/search-input/search-filter/SearchFilter";

// Styles
import styles from "./DropdownAddNew.module.scss";

const DropdownAddNew = ({
  selectedItem = [],
  optionList = [],
  onChange = () => {},
  handleClearValue = () => {},
  handleAddNew = () => {},
  placeholderDropdown = "",
  placeholderSearch = "",
  iconSrc = "",
  titleAddNew = "",
}) => {
  const dropdownRef = useRef();
  const menuRef = useRef();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useOnClickOutside(menuRef, () => resetState(), dropdownRef?.current);

  const resetState = () => {
    setMenuIsOpen(false);
    setSearchInput("");
  };

  const onSelectValue = (selectedObj) => {
    onChange(selectedObj);
    resetState();
  };

  const filteredList = optionList?.filter(
    (item) =>
      item.label
        .trim()
        .toLowerCase()
        .indexOf(searchInput.trim().toLowerCase()) !== -1
  );

  const renderNoResultData = () => (
    <p className={styles["no-result"]}>No Result</p>
  );

  return (
    <div className={styles["wrap"]} ref={dropdownRef}>
      <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
        <Select
          className={clsx("dropdown-react-select", styles["dropdown-select"])}
          classNamePrefix="react-select"
          placeholder={placeholderDropdown}
          value={selectedItem}
          isSearchable={false}
          components={{
            Menu: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
      {selectedItem.length > 0 && (
        <img
          onClick={handleClearValue}
          className={styles["clear-icon"]}
          src="/images/icons/clear-icon-orange.svg"
          alt="Clear Icon"
        />
      )}
      <div
        className={clsx(styles["menu-list"], menuIsOpen ? "d-block" : "")}
        ref={menuRef}
      >
        <SearchFilter
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          placeholder={placeholderSearch}
          handleClearSearch={() => setSearchInput("")}
          size="sm"
        />
        {optionList?.length > 0 ? (
          <ul>
            {filteredList.length > 0 ? (
              <React.Fragment>
                {filteredList.map((item) => (
                  <li
                    className={
                      item.value === selectedItem[0]?.value
                        ? styles["active"]
                        : ""
                    }
                    key={item.value}
                    onClick={() => onSelectValue(item)}
                    title={item.label}
                  >
                    {searchInput ? (
                      <SuggestionItem
                        data={item.label}
                        input={searchInput.trim()}
                      />
                    ) : (
                      <span>{item.label}</span>
                    )}
                  </li>
                ))}
              </React.Fragment>
            ) : (
              renderNoResultData()
            )}
          </ul>
        ) : (
          renderNoResultData()
        )}
        <span className={styles["add-new"]} onClick={handleAddNew}>
          <img src={iconSrc} alt="add new" className={styles["add-new-icon"]} />
          <span>{titleAddNew}</span>
        </span>
      </div>
    </div>
  );
};

DropdownAddNew.propTypes = {
  placeholderDropdown: PropTypes.string,
  placeholderSearch: PropTypes.string,
  iconSrc: PropTypes.string,
  titleAddNew: PropTypes.string,
  selectedItem: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  optionList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  handleClearValue: PropTypes.func,
  handleAddNew: PropTypes.func,
};

export default DropdownAddNew;
