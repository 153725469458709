import axios, { authHeader, getAbortController } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const getExportSummaryApi = (projectId, data) => {
  return axios(`/exportManagement/${projectId}/getExportSummary`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data,
    signal: getAbortController().signal,
    timeout: 200_000, //200 seconds
  });
};

const createExportApi = (bodyData = {}) =>
  axios(`/exportManagement/export`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
  });

const editExportApi = (params = {}) => {
  const { projectId, exportID } = params;
  return axios(`/exportManagement/${projectId}/${exportID}`, {
    headers: authHeader(),
    method: METHOD_API.get,
  });
};

const getExportListApi = (projectId, bodyData) => {
  return axios(`/exportManagement/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
  });
};

const updateScheduleApi = (projectId, params) => {
  const { ExportID, ScheduleTime, ExportName } = params;
  return axios(`/exportManagement/${projectId}/updateSchedule`, {
    headers: authHeader(),
    method: METHOD_API.put,
    params: { ExportID, ScheduleTime, ExportName },
  });
};

const downloadExportApiFetch = (projectId, ExportID) => {
  return fetch(`${process.env.REACT_APP_API_URL}/exportManagement/${projectId}/${ExportID}/download`, {
    headers: authHeader(),
    method: METHOD_API.get,
  });
};

const deleteExportApi = (projectId, ExportID) => {
  return axios(`/exportManagement/${projectId}/${ExportID}`, {
    headers: authHeader(),
    method: METHOD_API.delete,
  });
};

const runExportApi = (projectId, ExportID) => {
  return axios(`/exportManagement/${projectId}/${ExportID}/run`, {
    headers: authHeader(),
    method: METHOD_API.post,
  });
};

const cancelExportApi = (projectId, ExportID) => {
  return axios(`/exportManagement/${projectId}/${ExportID}/cancel`, {
    headers: authHeader(),
    method: METHOD_API.post,
  });
};

export {
  getExportSummaryApi,
  createExportApi,
  editExportApi,
  getExportListApi,
  updateScheduleApi,
  downloadExportApiFetch,
  deleteExportApi,
  runExportApi,
  cancelExportApi,
};
