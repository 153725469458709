import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

// Components
import {
    PdfViewerComponent,
    Toolbar,
    Magnification,
    Navigation,
    ThumbnailView,
    TextSelection,
    TextSearch,
    Inject
} from '@syncfusion/ej2-react-pdfviewer';
import SpinnerLoading from '../shared/spinner-loading/SpinnerLoading';

// Services
import { convertPresentationToPdf } from 'services';

// Constants
import { ZERO_BYTE_ERROR, NOT_FOUND_ERROR, TOO_LARGE_ERROR, RENDER_ERROR } from 'constants/NativeViewerConstants';

// Styles
import styles from './PresentationAttachmentRenderer.module.scss';

const PresentationAttachmentRenderer = (props) => {
    const {
        attachmentId,
        projectId,
    } = props;

    const pdfViewerRef = useRef(null);

    const [showCannotRender, setShowCannotRender] = useState(false);
    const [cannotRenderMessage, setCannotRenderMessage] = useState('');
    const [pdfBase64, setPdfBase64] = useState('');
    const [documentLoaded, setDocumentLoaded] = useState(false);

    const fetchPdfPreview = useCallback(async () => {
        try {
            const response = await convertPresentationToPdf(projectId, attachmentId);
            if (response.data?.length === 0) {
                setShowCannotRender(true);
                setCannotRenderMessage(ZERO_BYTE_ERROR);
            }
            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPdfBase64(fileReader.result);
            };
            fileReader.readAsDataURL(response.data);
        } catch (error) {
            setShowCannotRender(true);
            let errorResponseData;
            if (error.response?.data) {
                const responseBlob = new Blob([error.response.data], { type: 'application/json' });
                try {
                    errorResponseData = JSON.parse(await responseBlob.text());
                } catch (parseError) {
                    errorResponseData = null;
                }
            }

            if (error.response?.status === 400 && errorResponseData?.message?.includes('file is empty')) {
                setCannotRenderMessage(ZERO_BYTE_ERROR);
            } else if (error.response?.status === 404) {
                setCannotRenderMessage(NOT_FOUND_ERROR);
            } else if (error.response?.status === 413) {
                setCannotRenderMessage(TOO_LARGE_ERROR);
            } else {
                setCannotRenderMessage(RENDER_ERROR);
            }
        }
    }, []);

    useLayoutEffect(() => {
        fetchPdfPreview();
    }, [projectId, attachmentId]);

    useEffect(() => {
        if (pdfBase64 && pdfViewerRef.current && !documentLoaded) {
            try {
                pdfViewerRef.current.dataBind();
                pdfViewerRef.current.documentPath = pdfBase64;
                setDocumentLoaded(true);
            } catch (error) {
                console.error(error);
                setShowCannotRender(true);
                setCannotRenderMessage('An error occurred while rendering this attachment.');
            }
        }
    }, [pdfBase64, pdfViewerRef.current, documentLoaded]);

    useEffect(() => {
        return () => {
            if (pdfViewerRef.current) {
                pdfViewerRef.current.unload();
                pdfViewerRef.current.destroy();
                setPdfBase64('');
            }
        };
    }, []);

    return showCannotRender ? (
        <div className={styles['cannot-render']}>
            <img
                src="/images/image-notfound.png"
                alt="not found"
            />
            <p>{cannotRenderMessage}</p>
        </div>
    ) : (
        <>
            {!documentLoaded &&
                <div className={styles['loading-spinner-container']}>
                    <SpinnerLoading isCenter={false} />
                </div>
            }
            <div className={!documentLoaded
                ? styles['pdf-renderer-hidden']
                : styles['pdf-renderer']}>
                <PdfViewerComponent
                    id="pdfViewer"
                    ref={pdfViewerRef}
                    resourceUrl={`${window.location.origin}/lib/ej2-pdfviewer-lib`}
                    toolbarSettings={{
                        showTooltip: true,
                        toolbarItems: ['PageNavigationTool', 'MagnificationTool', 'PanTool', 'SelectionTool', 'SearchOption'],
                        applyAnnotationToolbarSettings: [],
                        formDesignerToolbarItems: [],
                    }}
                    enablePrint={false}
                    height='100%'
                    width='100%'
                >
                    <Inject services={[
                        Magnification,
                        Toolbar,
                        Navigation,
                        ThumbnailView,
                        TextSelection,
                        TextSearch
                    ]} />
                </PdfViewerComponent>
            </div>
        </>
    )
};

PresentationAttachmentRenderer.propTypes = {
    attachmentId: PropTypes.string,
    projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default PresentationAttachmentRenderer;
