import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
    name: "notification",
    initialState: {
        notifHubConnection: null,
        matterSubscription: {
            currentId: 0,
            lastId: 0,
        },
    },
    reducers: {
        updateNotifHubConnection: (state, action) => {
            state.notifHubConnection = action.payload;
        },
        updateMatterSubscription: (state, action) => {
            state.matterSubscription = action.payload;
        },
    },
});

export const { 
    updateNotifHubConnection,
    updateMatterSubscription,
} = notificationSlice.actions;
export const NotificationReducer = notificationSlice.reducer;