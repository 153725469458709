import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import { PaginationResult } from "components/shared/paging/PaginationResult";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import EntityTable from "../entity-table/EntityTable";

// Constants
import { COLUMN_NAME_ENTITY } from "constants/EntityConstant";

// Styles
import styles from "./EntityTableView.module.scss";

const EntityTableView = ({
  isLoading = false,
  perPage,
  searchInput = "",
  pageNumber = 1,
  entityData = {},
  handlePagingItem = () => {},
  handleSortTable = () => {},
  handleEditEntity = () => {},
  getChecked = () => {},
  getCheckedAll = () => {},
  isCheckedAll = false,
}) => {

  const initSort = Object.values(COLUMN_NAME_ENTITY).map((item) => ({
    label: item.label,
    value: item.value,
    isAcs: true,
  }));

  const [sortColumns, setSortColumns] = useState(initSort);

  return (
    <div className={styles["entity-table-list"]}>
      <h5>People List</h5>
      <div className={styles["entity-table"]}>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <EntityTable
            searchInput={searchInput}
            tableData={entityData.items}
            isLoading={isLoading}
            handleSortTable={handleSortTable}
            handleEditEntity={handleEditEntity}
            getChecked={getChecked}
            getCheckedAll={getCheckedAll}
            isCheckedAll={isCheckedAll}
            initSort={initSort}
            sortColumns={sortColumns}
            updateSortColumns={setSortColumns}
          />
        )}
      </div>
      {entityData.totalRecords > 0 && (
        <PaginationResult
          forcePage={pageNumber - 1}
          perPage={perPage}
          totalRecord={entityData.totalRecords}
          pageItems={entityData.items.length}
          handlePagingClick={handlePagingItem}
          isSmall
          nameRecord={entityData.totalRecords > 1 ? "people" : "person"}
          isUpdateCurrentPage={isLoading}
        />
      )}
    </div>
  );
};

EntityTableView.propTypes = {
  isLoading: PropTypes.bool,
  isCheckedAll: PropTypes.bool,
  perPage: PropTypes.number,
  entityData: PropTypes.shape({
    items: PropTypes.array,
    totalRecords: PropTypes.number,
  }),
  searchInput: PropTypes.string,
  pageNumber: PropTypes.number,
  handlePagingItem: PropTypes.func,
  handleSortTable: PropTypes.func,
  handleEditEntity: PropTypes.func,
  getChecked: PropTypes.func,
  getCheckedAll: PropTypes.func,
};

export default EntityTableView;
