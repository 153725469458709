import { SIGNALR_SERVER_FUNCTIONS } from "constants/Common";

const subscribeToMatter = async (connection, matterId) => {
    assertMatterIdIsNumber(matterId);
    await connection.invoke(SIGNALR_SERVER_FUNCTIONS.SubscribeToMatter, Number(matterId));
};

const unsubscribeFromMatter = async (connection, matterId) => {
    assertMatterIdIsNumber(matterId);
    await connection.invoke(SIGNALR_SERVER_FUNCTIONS.UnsubscribeFromMatter, Number(matterId));
};

const assertMatterIdIsNumber = (matterId) => {
    if (typeof matterId !== typeof 1) {
        throw new Error(`ProjectId must be a number. Current type: ${typeof(matterId)}`);
    }
};

export { subscribeToMatter, unsubscribeFromMatter };