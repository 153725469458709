import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useToken } from "hook/auth";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import clsx from "clsx";

//Component
import Checkbox from "components/shared/checkbox/Checkbox";

//Constant
import { COMMON_TEXT, EXPORT_TAG } from "constants/Common";

//Styles
import styles from "./TagItem.module.scss";

const TagItem = (props) => {
  const {
    index,
    data,
    onCheckTagItem,
    checkSelectedTags,
    type = "available",
    onEditTag,
  } = props;
  const { isUser, isClientAdmin } = useToken();
  const grid = 8;
  const getItemStyle = (draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const userId = useSelector((state) => state.user.userInfo.id);

  const checkOverflow = (el) => {
    let tagItemInfo = document.getElementsByClassName("tag-item-info")[0];
    const isOverflowing = el.scrollWidth > tagItemInfo.scrollWidth;
    if (isOverflowing)
      el.title = `${data.name} ${data.hotKey ? `(${data.hotKey})` : ""}`;
    return isOverflowing;
  };

  const handleEditTag = () => {
    if (!isDisabledActions()) onEditTag();
  };

  const isDisabledActions = () => {
    if (EXPORT_TAG.id === data.tagID) return true;
    const userDisabled =
      isUser() && data.type === COMMON_TEXT.global && data.userId !== userId;
    const adminDisabled =
      isClientAdmin() &&
      data.type === COMMON_TEXT.global &&
      data.accountID?.slice(-3) === COMMON_TEXT.admin;
    return !!(userDisabled || adminDisabled);
  };

  const onLoadHandle = () => {
    const element = document.getElementsByClassName(`tag-${data.tagID}`)[0];
    if (element) checkOverflow(element);
  };

  useEffect(() => {
    onLoadHandle();
  }, [data?.name]);

  return (
    <Draggable draggableId={`${type}-${index}`} index={index}>
      {(provided) => (
        <div
          className={styles["tag-item"]}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={getItemStyle(provided.draggableProps.style)}
        >
          <div className={clsx(styles["tag-item-info"], "tag-item-info")}>
            <Checkbox
              size="lg"
              id={`tag-${data.tagID}`}
              handleClick={() => onCheckTagItem(data.tagID)}
              isChecked={checkSelectedTags.includes(data.tagID)}
            />
            <label
              className={styles["tag-item-info-color"]}
              htmlFor={`tag-${data.tagID}`}
              style={{ backgroundColor: data.color }}
            ></label>
            <label
              className={clsx(styles["tag-name"], `tag-${data.tagID}`)}
              htmlFor={`tag-${data.tagID}`}
            >
              {`${data.name} ${data.hotKey ? `(${data.hotKey})` : ""}`}
            </label>
          </div>
          <div className={styles["tag-item-action"]}>
            <span
              className={clsx(
                styles["tag-type"],
                data.type === COMMON_TEXT.global
                  ? styles["tag-type-global"]
                  : ""
              )}
            >
              {data.type || COMMON_TEXT.private}
            </span>
            <img
              src="/images/icons/edit-icon.svg"
              onClick={handleEditTag}
              className={isDisabledActions() ? styles["tag-edit-disabled"] : ""}
              alt="Edit"
            />
            <span
              className="d-flex align-items-center"
              {...provided.dragHandleProps}
            >
              <img
                className={styles["tag-drap-icon"]}
                src="/images/icons/index-tag-icon.svg"
                alt="Tag"
              />
            </span>
          </div>
        </div>
      )}
    </Draggable>
  );
};

TagItem.propTypes = {
  type: PropTypes.string,
  index: PropTypes.number,
  data: PropTypes.object,
  checkSelectedTags: PropTypes.array,
  onCheckTagItem: PropTypes.func,
  onEditTag: PropTypes.func,
};

export default TagItem;
