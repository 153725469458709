import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import SuggestionItem from "../suggestion-item/SuggestionItem";

// Constants
import { EVENT_KEY_LISTENER, KEY_CODE } from "constants/Common";

// Stores
import { setSuggestWord } from "store/CommonReducer";
import { setCanExecuteHotKey } from "store/TagReducer";

// Styles
import "./styles.scss";

const SearchInputGeneral = (props, ref) => {
  const {
    placeholder,
    suggestions = [],
    name,
    isAutoComplete = false,
    inputRef,
    onSubmitSearch = () => {},
    isFilterControl = false,
    handleClearSearch,
    searchInput = "",
    onClearSearchInput = () => {},
    hasPreventClearText = false,
    disabled = false,
  } = props;

  const dispatch = useDispatch();

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [focusItem, setFocusItem] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState(searchInput);
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [removeSearchKey, setRemoveSearchKey] = useState(false);
  const suggestionRef = useRef(null);
  const [isFlag, setIsFlag] = useState(true);

  const clearText = () => {
    if (hasPreventClearText) return;
    if (input?.length > 0 && !isFlag) {
      setRemoveSearchKey(true);
      setIsFlag(true);
    }
    setInput("");
    setShowClearIcon(false);
  };

  const handleClickOutside = (event) => {
    if (suggestionRef.current && !suggestionRef.current.contains(event.target))
      setShowSuggestions(false);
  };

  const handleHideDropdown = (event) => {
    if (event.keyCode === KEY_CODE.escape) setShowSuggestions(false);
  };

  const onChange = (e) => {
    if (hasPreventClearText) return;
    const userInput = e.target.value.trim();
    if (userInput.length > 0) {
      setShowClearIcon(true);
      setShowSuggestions(true);
    } else {
      setShowClearIcon(false);
      setShowSuggestions(false);
      // Reset to default data
      if (!isFlag) {
        setIsFlag(true);
        onSubmitSearch();
        onClearSearchInput();
      }
    }
    // Just get suggestion data when isAutoComplete set true
    isAutoComplete && dispatch(setSuggestWord(userInput));
    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions?.filter(
      (suggestion) =>
        suggestion?.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    setInput(e.target.value);
    setFilteredSuggestions(unLinked);
    setFocusItem(-1);
  };

  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.enter:
        e.preventDefault();
        setShowSuggestions(false);

        if (focusItem > -1) {
          setFocusItem(-1);
          setInput(filteredSuggestions[focusItem]);
        } else onSubmitHandle();
        break;
      case KEY_CODE.arrowUp:
        if (showSuggestions) {
          setFocusItem(
            focusItem === 0 ? filteredSuggestions.length - 1 : focusItem - 1
          );
        }
        break;
      case KEY_CODE.arrowDown:
        if (showSuggestions) {
          setFocusItem(
            focusItem === filteredSuggestions.length - 1 ? 0 : focusItem + 1
          );
        }
        break;

      default:
        break;
    }
  };

  const onSelectSuggestionHandle = (data) => {
    setFilteredSuggestions([]);
    setInput(data);
    setFocusItem(-1);
    setShowSuggestions(false);
  };

  const onSubmitHandle = () => {
    setShowSuggestions(false);
    if (input && input.trim().length > 0) {
      setIsFlag(false);
      onSubmitSearch();
      setInput(input.trim());
    }
  };

  const SuggestionList = useMemo(
    () =>
      filteredSuggestions.length > 0 && (
        <div className="suggestions-wrapper">
          <ul className="suggestions" id="suggestion-list" ref={suggestionRef}>
            {filteredSuggestions.map((suggestion, index) => {
              return (
                <li
                  key={index}
                  onClick={() => onSelectSuggestionHandle(suggestion)}
                  id={`suggestion-item-${index}`}
                >
                  <SuggestionItem data={suggestion} input={input?.trim()} />
                </li>
              );
            })}
          </ul>
        </div>
      ),
    [filteredSuggestions]
  );

  useImperativeHandle(
    ref,
    () => ({
      clearText,
    }),
    []
  );

  useEffect(() => {
    // Clear data
    if (searchInput) {
      clearText();
      onClearSearchInput();
      return;
    }

    document.addEventListener(
      EVENT_KEY_LISTENER.click,
      handleClickOutside,
      false
    );
    document.addEventListener(
      EVENT_KEY_LISTENER.keydown,
      handleHideDropdown,
      true
    );

    return () => {
      document.removeEventListener(
        EVENT_KEY_LISTENER.click,
        handleClickOutside,
        false
      );
      document.removeEventListener(
        EVENT_KEY_LISTENER.keydown,
        handleHideDropdown,
        true
      );
    };
  }, []);

  useEffect(() => {
    if (searchInput) setIsFlag(false);
    setInput(searchInput);
  }, [searchInput, hasPreventClearText]);

  useEffect(() => {
    input?.trim().length > 0 ? setShowClearIcon(true) : setShowClearIcon(false);
  }, [input]);

  useEffect(() => {
    if (!removeSearchKey) return;
    if (handleClearSearch) handleClearSearch();
    else onSubmitSearch();
    setRemoveSearchKey(false);
  }, [removeSearchKey]);

  // Update filter suggestions for case: initial suggestion not fixed
  useEffect(() => {
    if (suggestions.length > 0) {
      const isSuggestion = suggestions.includes(inputRef.current.value);
      setFilteredSuggestions(isSuggestion ? suggestions : []);
    }
  }, [suggestions]);

  // Check and scroll to suggestion item when focus item has changed, ENHANCE LATER
  useEffect(() => {
    if (focusItem >= 0) {
      // Remove class name all li item before set active item
      const ulTag = document.getElementById("suggestion-list");
      ulTag &&
        ulTag
          .querySelectorAll("li")
          .forEach((li) => li.classList.remove("suggestion-active"));
      // Set active item and scroll to
      const targetLi = document.getElementById(`suggestion-item-${focusItem}`);
      if (targetLi) {
        targetLi.classList.add("suggestion-active");
        targetLi.scrollIntoView({ block: "nearest", inline: "center" });
      }
    }
  }, [focusItem]);

  return (
    <div className="search-wrapper">
      <div className="input-autocomplete">
        <input
          className={clsx(
            "btn-input search-input",
            isFilterControl ? "input-filter-control" : ""
          )}
          type="text"
          autoComplete="off"
          ref={inputRef}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={input}
          placeholder={placeholder}
          onFocus={() => dispatch(setCanExecuteHotKey(false))}
          onBlur={() => dispatch(setCanExecuteHotKey(true))}
          disabled={disabled}
        />
        {isAutoComplete &&
          showSuggestions &&
          input &&
          filteredSuggestions.length > 0 &&
          SuggestionList}
      </div>
      {showClearIcon && (
        <img
          onClick={clearText}
          id="clearSearch"
          className="icon-clear"
          src="/images/clear-search.svg"
          alt="Clear Icon"
        />
      )}
      <img
        onClick={onSubmitHandle}
        className="icon-search"
        src="/images/search-icon.svg"
        alt="Search Icon"
      />
    </div>
  );
};
export default forwardRef(SearchInputGeneral);

SearchInputGeneral.propTypes = {
  inputRef: PropTypes.any,
  disabled: PropTypes.bool,
  isAutoComplete: PropTypes.bool,
  isFilterControl: PropTypes.bool,
  hasPreventClearText: PropTypes.bool,
  placeholder: PropTypes.string,
  searchInput: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.string,
  suggestions: PropTypes.array,
  handleClearSearch: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  onClearSearchInput: PropTypes.func,
};
