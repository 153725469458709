const ZOOM_MAP = {
  zoom_6: 6,
  zoom_10: 10,
  zoom_12: 12,
  zoom_15: 15,
};

const MAP_STYLE = {
  road: "road",
  satellite: "satellite",
  all: "all",
  auto: "auto",
};

export {
  ZOOM_MAP,
  MAP_STYLE
};
