import React from "react";
import PropTypes from "prop-types";

// Components
import SuggestionItem from "../search-input/suggestion-item/SuggestionItem";

// Helpers
import { convertStringEnter } from "helpers/CommonHelper";

// Styles
import styles from "./FilterItem.module.scss";
import clsx from "clsx";

const FilterItem = (props) => {
  // Get data from props
  const {
    data = {},
    iconSrc = "",
    iconClass = "",
    isSelected = true,
    onSelect = () => {},
    inputSearch = "",
    isEmoji = false,
    disable = false,
    isFormat = false,
  } = props;

  // Define icon select
  const iconSelect = isSelected
    ? "/images/minus-square-red-icon.svg"
    : "/images/plus-square-blue-icon.svg";

  const dataDisplay = `${convertStringEnter(data.label)}${
    isFormat ? ` (${data.value})` : ""
  }`;
  return (
    <div className={styles["select-item"]}>
      <div
        onClick={() => (isEmoji ? onSelect(data) : {})}
        className={clsx(styles["name-group"], isEmoji ? styles["emoji"] : "")}
        title={dataDisplay}
      >
        {iconSrc && !isEmoji && (
          <img className={styles["icon-type"]} src={iconSrc} alt="icon" />
        )}
        {iconClass && !isEmoji && (
          <span className={clsx(iconClass, styles["syncfusion-icon-circle-remove"])}></span>
        )}
        {isEmoji ? (
          <span>{data.label}</span>
        ) : (
          <SuggestionItem data={dataDisplay} input={inputSearch} />
        )}
        {data?.hotKey && <span className="ps-2">{`(${data.hotKey})`}</span>}
      </div>
      {!isEmoji && (
        <img
          className={clsx(
            styles["icon-select"],
            disable ? styles["not-allowed"] : ""
          )}
          onClick={!disable ? () => onSelect(data) : () => {}}
          src={iconSelect}
          alt={data.label}
        />
      )}
    </div>
  );
};

FilterItem.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    hotKey: PropTypes.string,
  }),
  iconSrc: PropTypes.string,
  iconClass: PropTypes.string,
  inputSearch: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  isEmoji: PropTypes.bool,
  disable: PropTypes.bool,
  isFormat: PropTypes.bool,
};

export default FilterItem;
