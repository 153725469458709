const SEARCH_SELECTOR = {
  method: {
    and: "And",
    or: "Or",
    within: "Within",
    nested: "Nested",
  },
  condition: {
    and: "AND",
    or: "OR",
  },
};

export { SEARCH_SELECTOR };
