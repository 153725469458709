import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import EmptyPage from "components/shared/empty-page/EmptyPage";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import SourceTypeList from "components/shared/source-type-list/SourceTypeList";
import FormInputControl from "components/shared/form-input-control/FormInputControl";
import DropdownAddNew from "components/shared/dropdown-selector/dropdown-add-new/DropdownAddNew";

// Constants
import { COLUMN_NAME } from "constants/IdentifiersConstant";
import { COMMON_TEXT } from "constants/Common";
import { DATE_TIME_TYPE, SORT_BY } from "constants/Constants";

// Helpers
import { toThousandsNumber } from "helpers/FormatterHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { convertStringEnter } from "helpers/CommonHelper";

//Styles
import styles from "./IdentifiersTable.module.scss";

const IdentifiersTable = (props) => {
  const {
    isLoading = false,
    identifierData = [],
    entityList = [],
    totalRecords = 0,
    handleSortTable = () => {},
    onRemovePreferredName = () => {},
    onAssignIdentifier = () => {},
    handleAddNewEntity = () => {},
  } = props;

  const initSort = Object.values(COLUMN_NAME).map((item) => ({
    label: item.label,
    value: item.value,
    isAcs: item.value === COLUMN_NAME.occurrences.value ? false : true,
  }));

  const [sortColumns, setSortColumns] = useState(initSort);
  const [isCreating, setIsCreating] = useState(false);
  const [identifierCurrentID, setIdentifierCurrentID] = useState(null);
  const [focusSelect, setFocusSelect] = useState(null);
  const [inputPreferredName, setInputPreferredName] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);
  const [identifierList, setIdentifierList] = useState([]);

  const classSort = (columnName) => {
    const obj = sortColumns.find((item) => item.value === columnName);
    return `sort ${obj?.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  const onSortTable = (columnName) => {
    const obj = sortColumns.find((item) => item.value === columnName);
    const newSortColumns = sortColumns.map((item) => ({
      value: item.value,
      label: item.label,
      isAcs: item.value === columnName ? !obj.isAcs : true,
    }));
    setSortColumns(newSortColumns);
    const sortParam = {
      columnSort: obj.value,
      orderBy: !obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
    };
    handleSortTable(sortParam);
  };

  const handleChangePreferredName = (event) => {
    const value = event.target.value;
    value?.length > 100 ? event.preventDefault() : setInputPreferredName(value);
  };

  const getFilterEntityName = (entityID) =>
    entityList.find((item) => item.value === Number(entityID))?.label;

  const updateStateComponent = ({
    isCreate = false,
    identifierID = null,
    preferredName = "",
  }) => {
    setIsCreating(isCreate);
    setIdentifierCurrentID(identifierID);
    setInputPreferredName(preferredName);
  };

  const handleSaveForm = (data) => {
    updateStateComponent({ preferredName: inputPreferredName?.trim() });
    const newPreferredName = { ...data, preferredName: inputPreferredName?.trim() };
    updateIdentifierList(data.identifierID, newPreferredName);
    const params = {
      identifier: data.identifier,
      preferredName: inputPreferredName?.trim(),
    };
    onRemovePreferredName(params);
  };

  const updateIdentifierList = (identifierID, newValue) =>
    setIdentifierList(
      identifierList.map((item) => {
        if (item.identifierID === identifierID) return newValue;
        else return item;
      })
    );

  const handleSelectEntity = (selectedObj, data) => {
    const newEntity = { ...data, entity: selectedObj.value };
    updateIdentifierList(data.identifierID, newEntity);
    setSelectedItem([selectedObj]);
    setFocusSelect(null);
    setIdentifierCurrentID(null);
    const params = {
      identifier: data.identifier,
      entityID: selectedObj.value,
    };
    onAssignIdentifier(params);
  };

  const handleRemoveEntity = (data) => {
    setSelectedItem([]);
    setFocusSelect(data.identifierID);
    const newEntity = { ...data, entity: null };
    updateIdentifierList(data.identifierID, newEntity);
    const params = {
      identifier: data.identifier,
      entityID: null,
    };
    onAssignIdentifier(params);
  };

  useEffect(() => {
    setIdentifierCurrentID(null);
    setIdentifierList(identifierData);
  }, [identifierData]);

  return (
    <div className={styles["wrap"]}>
      {isLoading ? (
        <SpinnerLoading />
      ) : totalRecords > 0 ? (
        <table>
          <thead>
            <tr>
              {initSort.map((item) => (
                <th key={item.value}>
                  <span
                    className={classSort(item.value)}
                    onClick={() =>
                      onSortTable(item.value, sortColumns[item.value])
                    }
                  >
                    {item.label}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {identifierList?.length > 0 &&
              identifierList.map((item) => (
                <tr key={item.identifierID}>
                  <td>
                    <span
                      title={convertStringEnter(item.identifier)}
                      className={styles["identifier"]}
                    >
                      <strong>
                        {convertStringEnter(item.identifier)?.trim() ||
                          COMMON_TEXT.default}
                      </strong>
                    </span>
                  </td>
                  <td>
                    {item.sources?.length > 0 ? (
                      <SourceTypeList
                        sourceList={item.sources.split(", ")}
                        limitSource={3}
                      />
                    ) : (
                      COMMON_TEXT.default
                    )}
                  </td>
                  <td className={styles["overflow-unset"]}>
                    <DropdownAddNew
                      selectedItem={
                        (focusSelect === item.identifierID && selectedItem) ||
                        entityList.filter(
                          (obj) => obj.value === Number(item.entity)
                        )
                      }
                      onChange={(event) => handleSelectEntity(event, item)}
                      optionList={entityList}
                      handleClearValue={() => handleRemoveEntity(item)}
                      placeholderDropdown="Select People"
                      placeholderSearch="Content"
                      iconSrc="/images/plus-icon-blue.svg"
                      titleAddNew="Add to New Person"
                      handleAddNew={() => {
                        handleAddNewEntity(item.identifier, item.likelyName);
                        setFocusSelect(null);
                      }}
                    />
                  </td>
                  <td className={styles["overflow-unset"]}>
                    <FormInputControl
                      isCreating={isCreating}
                      currentID={identifierCurrentID}
                      dataID={item.identifierID}
                      editValue={item.preferredName}
                      inputValue={inputPreferredName}
                      placeholder={
                        getFilterEntityName(item.entity) ||
                        convertStringEnter(item.likelyName)
                      }
                      onChange={handleChangePreferredName}
                      onFocus={() =>
                        updateStateComponent({
                          isCreate: true,
                          identifierID: item.identifierID,
                        })
                      }
                      clearFocus={setIdentifierCurrentID}
                      handleEditForm={() =>
                        updateStateComponent({
                          identifierID: item.identifierID,
                          preferredName: item.preferredName,
                        })
                      }
                      handleSaveForm={() => handleSaveForm(item)}
                      handleCancelForm={() =>
                        isCreating
                          ? updateStateComponent({ identifierID: null })
                          : updateStateComponent({
                              preferredName: inputPreferredName,
                            })
                      }
                    />
                  </td>
                  <td>
                    <span
                      title={convertStringEnter(item.likelyName)}
                      className={styles["likely-name"]}
                    >
                      {convertStringEnter(item.likelyName)?.trim() ||
                        COMMON_TEXT.default}
                    </span>
                  </td>
                  <td>
                    <span
                      title={convertStringEnter(item.namesPercent)}
                      className={styles["name-percent"]}
                    >
                      {convertStringEnter(item.namesPercent)?.trim() ||
                        COMMON_TEXT.default}
                    </span>
                  </td>
                  <td>
                    <strong>{toThousandsNumber(item.occurrences)}</strong>
                  </td>
                  <td>
                    {formatDateTime({
                      dateTime: item.dateFirst,
                      type: DATE_TIME_TYPE.MM_DD_YYYY,
                    }) || COMMON_TEXT.default}
                  </td>
                  <td>
                    {formatDateTime({
                      dateTime: item.dateLast,
                      type: DATE_TIME_TYPE.MM_DD_YYYY,
                    }) || COMMON_TEXT.default}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className={styles["no-result"]}>
          <EmptyPage messages={"No results found. Please try again."} />
        </div>
      )}
    </div>
  );
};

IdentifiersTable.propTypes = {
  isLoading: PropTypes.bool,
  identifierData: PropTypes.arrayOf(
    PropTypes.shape({
      dateFirst: PropTypes.string,
      dateLast: PropTypes.string,
      entity: PropTypes.string,
      identifier: PropTypes.string,
      identifierID: PropTypes.number,
      likelyName: PropTypes.string,
      namesPercent: PropTypes.string,
      occurrences: PropTypes.number,
      preferredName: PropTypes.string,
      sources: PropTypes.string,
    })
  ),
  entityList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string,
    })
  ),
  totalRecords: PropTypes.number,
  handleSortTable: PropTypes.func,
  onRemovePreferredName: PropTypes.func,
  onAssignIdentifier: PropTypes.func,
  handleAddNewEntity: PropTypes.func,
};

export default IdentifiersTable;
