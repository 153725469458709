import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Services
import { getUserAvatar } from "services";
import { getManageUserAvatar } from "services/UserAdministrationService";

// Components
import DefaultAvatar from "assets/images/default_avatar.png";

// Store
import { updateAllData } from "store/UserReducer";

// Styles
import styles from './UserAvatar.module.scss';

const UserAvatar = (props) => {
    const { userId } = props;

    const dispatch = useDispatch();
    const { avatarHasChanged } = useSelector((state) => state.user.userInfo);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(null);

    useEffect(() => {
        const fetchAvatar = async () => {
            let url;
            try {
                const response = userId ? await getManageUserAvatar(userId) : await getUserAvatar();
                url = URL.createObjectURL(response.data);
            } catch {
                url = DefaultAvatar;
            }
            
            setAvatarUrl(url);
            setHasLoaded(true);
        };

        if (!hasLoaded) {
            fetchAvatar();
        }

        return () => {
            URL.revokeObjectURL(avatarUrl);
        }
    }, [hasLoaded]);

    useEffect(() => {
        if (avatarHasChanged) {
            setAvatarUrl(null);
            setHasLoaded(false);
            dispatch(updateAllData({ avatarHasChanged: false }));
        }
    }, [avatarHasChanged]);

    return hasLoaded ? <div className={styles["user-avatar-container"]}>
        <img src={avatarUrl} alt="User Avatar" />
    </div> : <></>
};

UserAvatar.propTypes = {
    userId: PropTypes.string,
};

export default UserAvatar;