import { METHOD_API } from "constants/Common";
import axios, { authHeader, getAbortController } from "./ApiConfig";

const getManageTag = (params = {}) =>
  axios.get(`/manageTag`, {
    headers: authHeader(),
    params,
  });

const deleteTag = (tagIDs) =>
  axios.delete(`/manageTag/`, {
    headers: authHeader(),
    data: tagIDs,
  });

const createTag = (data) =>
  axios(`/manageTag`, {
    method: METHOD_API.post,
    headers: authHeader(),
    data,
  });

const updateAllTagsManagement = (data) =>
  axios("/manageTag/updateAll", {
    method: METHOD_API.put,
    headers: authHeader(),
    data,
  });

const updateTag = (data, tagId) =>
  axios(`/manageTag/${tagId}`, {
    method: METHOD_API.put,
    headers: authHeader(),
    data: data,
  });

const getTags = (params = {}) =>
  axios.get("/tag", {
    headers: authHeader(),
    params,
    signal: getAbortController().signal,
  });

export {
  getManageTag,
  deleteTag,
  createTag,
  updateAllTagsManagement,
  updateTag,
  getTags,
};
