import { DATE_TIME_TYPE } from "constants/Constants";
import { DATE_SELECTOR } from "constants/DateSelector";
import { upperCase } from "lodash";
import moment from "moment";

const checkAddZulu = (date) =>
  `${date}${date?.toString()?.slice(-1)?.toLowerCase() === "z" ? "" : "Z"}`;

const checkRemoveZulu = (date) =>
  date?.toString()?.replace("Z", "").replace("z", "");

const formatDateTime = ({ dateTime, type, mst = false, addZulu = true }) => {
  const check = moment(dateTime).isValid();
  if (!check) return "";
  return `${moment(
    new Date(addZulu ? checkAddZulu(dateTime) : checkRemoveZulu(dateTime))
  ).format(type)}${mst ? " MST" : ""}`;
};

const formatDateTimeWithOriginal = ({
  dateTime,
  original,
  newFormat,
  addZulu = true,
}) => {
  const check = moment(dateTime, original).isValid();
  if (!check) return "";
  return moment(addZulu ? checkAddZulu(dateTime) : dateTime, original).format(
    newFormat
  );
};

const getHour = (dateTime) => moment(dateTime).format(DATE_TIME_TYPE.HH);

const getMinute = (dateTime) => moment(dateTime).format(DATE_TIME_TYPE.mm);

const getTimeFrame = (dateTime) =>
  upperCase(moment(dateTime).format(DATE_TIME_TYPE.a));

const getCurrentTime = () =>
  moment(new Date()).format(DATE_TIME_TYPE.YYYYMMDDhhmmss);

const getCurrentTimeFormat = (formatTime) => {
  if (!formatTime) return DATE_TIME_TYPE.YYYYMMDDhhmmss;
  return moment(new Date()).format(formatTime);
}

const formatDateMessage = (date) => {
  const check = moment(date).isValid();
  return check
    ? moment(checkAddZulu(date)).format(DATE_TIME_TYPE.MM_DD_YYYY)
    : "";
};

const addDays = (date, days) => {
  const dateFormat = formatDateMessage(date);
  if (!dateFormat) return "";
  const new_date = moment(dateFormat)
    .add(days, "days")
    .format(DATE_TIME_TYPE.MM_DD_YYYY);
  return new_date;
};

const formatDuration = (secs) => {
  if (!secs) return "";
  const new_duration = moment.utc(secs * 1000).format(DATE_TIME_TYPE.HHmmss);
  return new_duration;
};

const handleTypeFilter = (start, end) => {
  if (!start && !end) return DATE_SELECTOR.type.before;
  if (start && end) return DATE_SELECTOR.type.between;
  if (start) return DATE_SELECTOR.type.after;
  if (end) return DATE_SELECTOR.type.before;
};

const getOrdinalNum = (n) => {
  return n > 0
    ? ["th", "st", "nd", "rd"][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10]
    : "";
};

const getTimeFromNow = (date) => {
  const check = moment(date).isValid();
  if (!check) return "";
  return moment(checkAddZulu(date)).fromNow();
};

const getTimeUTC = (time) => {
  if (!moment(time).isValid()) return null;
  const date = new Date(time);
  // Plus 20 years to prevent time convert to utc incorrect (case: < 1920)
  let newDate = new Date(
    date.getFullYear() + 20,
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  newDate = newDate.toUTCString().split(" GMT")[0];
  return moment(newDate)
    .subtract(20, "years")
    .format(DATE_TIME_TYPE.YYYYMMDD_hhmmss);
};

const formatHourMinSec = (duration = []) => {
  if (
    !parseInt(duration[0]) &&
    !parseInt(duration[1]) &&
    !parseInt(duration[2])
  )
    return "0 sec";
  let time = "";
  if (parseInt(duration[0])) time = `${parseInt(duration[0])} hour `;
  if (parseInt(duration[1])) time += `${parseInt(duration[1])} min `;
  if (parseInt(duration[2])) time += `${parseInt(duration[2])} sec `;
  return time;
};

const getHourByFrame = (frame, hour) => {
  let data = hour;
  if (frame === DATE_SELECTOR.timeFrame.pm) {
    if (data < 12 && data > 0) data += 12;
  } else {
    if (data > 12) data -= 12;
  }
  return data;
};

const getMinuteDisplay = (value) => {
  const valueParse = parseInt(value);
  return valueParse < 10 ? `0${valueParse}` : valueParse;
};

const checkTimeFrame = (check, hourEndValue) => {
  let hour = parseInt(hourEndValue);
  if (check === DATE_SELECTOR.timeFrame.pm && hourEndValue < 12)
    hour = parseInt(Number(hour) + 12);
  if (check === DATE_SELECTOR.timeFrame.am && hourEndValue >= 12)
    hour = parseInt(Number(hour) - 12);
  return hour < 10 ? `0${hour}` : hour;
};

const formatDataTimeValue = ({ date, timeFrame, hour, minute }) =>
  date ? `${date}T${checkTimeFrame(timeFrame, hour)}:${minute}` : null;

const convertDate = (str, option = "") => {
  const date = new Date(str),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);

  return option === DATE_TIME_TYPE.MM_DD_YYYY
    ? [month, day, date.getFullYear()].join("-")
    : [date.getFullYear(), month, day].join("-");
};

const convertSecondsToHMS = (sec) => {
  if (!sec || sec === "Infinity") return;
  const hours = Math.floor(sec / 3600);
  const minutes = Math.floor((sec % 3600) / 60);
  const seconds = sec % 60;

  let time = "";
  if (hours) time = `${hours} hour${hours > 1 ? "s" : ""} `;
  if (minutes) time += `${minutes} minute${minutes > 1 ? "s" : ""} `;
  if (seconds) time += `${seconds} second${seconds > 1 ? "s" : ""} `;
  return time;
};

export {
  getHour,
  getMinute,
  getTimeFrame,
  getCurrentTime,
  formatDateMessage,
  addDays,
  formatDuration,
  handleTypeFilter,
  getOrdinalNum,
  getTimeFromNow,
  formatDateTime,
  formatDateTimeWithOriginal,
  getTimeUTC,
  formatHourMinSec,
  getHourByFrame,
  getMinuteDisplay,
  formatDataTimeValue,
  convertDate,
  getCurrentTimeFormat,
  convertSecondsToHMS
};
