import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Constants
import { COLUMN_NAME_ENTITY } from "constants/EntityConstant";
import { DATE_TIME_TYPE, SOCIAL_LIST, SORT_BY } from "constants/Constants";
import { COMMON_TEXT } from "constants/Common";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getSourceIconById } from "helpers/CommonHelper";
import { toThousandsNumber } from "helpers/FormatterHelper";

// Components
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import Checkbox from "components/shared/checkbox/Checkbox";

// Styles
import styles from "./EntityTable.module.scss";

const EntityTable = (props) => {
  const {
    tableData = [],
    searchInput = "",
    isLoading = false,
    handleEditEntity = () => {},
    handleSortTable = () => {},
    getChecked = () => {},
    getCheckedAll = () => {},
    isCheckedAll = false,
    initSort = [],
    sortColumns = [],
    updateSortColumns = () => {},
  } = props;

  const classSort = (columnName) => {
    const obj = sortColumns.find((item) => item.value === columnName);
    return `sort ${obj?.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  const onSortTable = (columnName) => {
    const obj = sortColumns.find((item) => item.value === columnName);
    const newSortColumns = sortColumns.map((item) => ({
      value: item.value,
      label: item.label,
      isAcs: item.value === columnName ? !obj.isAcs : true,
    }));
    updateSortColumns(newSortColumns);
    const sortParam = {
      columnSort: obj.value,
      orderBy: !obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
    };
    handleSortTable(sortParam);
  };

  const showCombineList = (data) => {
    return data?.length > 1 ? (
      <div className={styles["combine-items"]}>
        <div className={styles["combine-info"]}>
          <span>{`${data?.length} Identifiers`}</span>
          <div className={styles["combine-list"]}>
            {data.map((item) => (
              <li
                key={item.id}
                className={styles["combine-list-item"]}
                title={item.value}
              >
                <img
                  src={getSourceIconById(SOCIAL_LIST, item.socialType)}
                  alt="icon"
                />
                <span>{item.value}</span>
              </li>
            ))}
          </div>
        </div>
      </div>
    ) : (
      <div className={styles["non-combine-info"]} title={data[0].value}>
        {data[0].value}
      </div>
    );
  };

  return (
    <div className={styles["wrap"]}>
      {isLoading ? (
        <SpinnerLoading />
      ) : tableData?.length > 0 ? (
        <table>
          <thead>
            <tr>
              {initSort.map((item) => (
                <th key={item.value}>
                  {item.value === COLUMN_NAME_ENTITY.checkbox.value ? (
                    <Checkbox
                      id="selectAll"
                      size="md"
                      isChecked={isCheckedAll}
                      handleClick={getCheckedAll}
                    />
                  ) : (
                    <span
                      className={clsx(
                        classSort(item.value),
                        [
                          COLUMN_NAME_ENTITY.checkbox.value,
                          COLUMN_NAME_ENTITY.action.value,
                        ].includes(item.value)
                          ? styles["no-sort"]
                          : styles["sort-column"]
                      )}
                      onClick={() => onSortTable(item.value)}
                    >
                      {item.label}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item.entityID}>
                <td>
                  <Checkbox
                    name="select entity"
                    id={item.entityID}
                    isChecked={item.checked}
                    handleClick={(event) => {
                      getChecked(event.target.checked, item.entityID);
                    }}
                    size="md"
                  />
                </td>
                <td>
                  <span className={styles["entity-name"]} title={item.name}>
                    {item.name}
                  </span>
                </td>
                <td>
                  {item.identifier?.length > 0
                    ? showCombineList(item.identifier)
                    : COMMON_TEXT.default}
                </td>
                <td>
                  <span title={item.jobTitle}>
                    {item.jobTitle || COMMON_TEXT.default}
                  </span>
                </td>
                <td>
                  <span title={item.company}>
                    {item.company || COMMON_TEXT.default}
                  </span>
                </td>
                <td>{toThousandsNumber(item.occurrences)}</td>
                <td>
                  {formatDateTime({
                    dateTime: item.dateFirst,
                    type: DATE_TIME_TYPE.MM_DD_YYYY,
                  }) || COMMON_TEXT.default}
                </td>
                <td>
                  {formatDateTime({
                    dateTime: item.dateLast,
                    type: DATE_TIME_TYPE.MM_DD_YYYY,
                  }) || COMMON_TEXT.default}
                </td>
                <td>
                  <span
                    className={styles["action"]}
                    onClick={() => handleEditEntity(item.entityID)}
                  >
                    Edit
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={styles["no-result"]}>
          <EmptyPage
            messages={
              searchInput
                ? "No results found. Please try again."
                : "This page is empty. Please create a new person"
            }
          />
        </div>
      )}
    </div>
  );
};

EntityTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      entityID: PropTypes.number,
      name: PropTypes.string,
      jobTitle: PropTypes.string,
      company: PropTypes.string,
      occurrences: PropTypes.number,
      dateFirst: PropTypes.string,
      dateLast: PropTypes.string,
      identifier: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.any,
          value: PropTypes.string,
          socialType: PropTypes.number
        })
      ),
    })
  ),
  initSort: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isAcs: PropTypes.bool,
    })
  ),
  sortColumns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      isAcs: PropTypes.bool,
    })
  ),
  searchInput: PropTypes.string,
  isLoading: PropTypes.bool,
  isCheckedAll: PropTypes.bool,
  handleSortTable: PropTypes.func,
  handleEditEntity: PropTypes.func,
  getChecked: PropTypes.func,
  getCheckedAll: PropTypes.func,
  updateSortColumns: PropTypes.func,
};

export default EntityTable;
