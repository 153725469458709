import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// Components
import InformationTabContainer from "../info-tab-container/InformationTabContainer";

// Constants and Helpers
import { TIMELINE_INFORMATION } from "constants/DataType";
import { PARAMETERS } from "constants/Common";

const InfoVideo = ({ videoInfo }) => {
  const { duration } = useSelector((state) => state.mediaControl);

  const [infoResultList, setInfoResultList] = useState([]);

  useEffect(() => {
    const infoList = TIMELINE_INFORMATION.video.map((item) => ({
      key: item.key,
      label: item.label,
    }));

    if (duration) {
      const durationIndex = infoList.findIndex((item) => item.key === 'duration');
      if (durationIndex > -1) {
        infoList[durationIndex].value = duration;
      }
    }

    setInfoResultList(infoList);
  }, [duration]);

  return (
    <InformationTabContainer
      infoResultLabelList={infoResultList}
      dataType={videoInfo[PARAMETERS.type]}
      itemInfo={videoInfo}    
    />
  );
};

InfoVideo.propTypes = {
  videoInfo: PropTypes.object,
};

export default InfoVideo;
