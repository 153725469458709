import React from "react";
import PropTypes from "prop-types";

// Constants
import styles from "./HighLightText.module.scss";

const HighLightText = (props) => {
  const {
    text,
    isAllEmoji,
    partIndex,
    highLightIndex,
    hightLight = "",
  } = props;

  const getClass = () => {
    if (isAllEmoji) {
      if (hightLight.includes(text))
        return partIndex === highLightIndex ? "emojis" : "highlight";
      return "";
    } else if (hightLight?.toLowerCase().includes(text?.toLowerCase()))
      return "highlight";
    return "";
  };

  return (
    <span id={`element-pos-${partIndex}`} className={styles[`${getClass()}`]}>
      {text}
    </span>
  );
};
HighLightText.propTypes = {
  text: PropTypes.string,
  hightLight: PropTypes.string,
  isAllEmoji: PropTypes.bool,
  highLightIndex: PropTypes.number,
  partIndex: PropTypes.number,
};

export default HighLightText;
