import axios, { authHeader } from "./ApiConfig";

const getProjects = (params = {}) =>
  axios.get(`/project`, {
    headers: authHeader(),
    params,
  });

const exportProjectExcel = (search) =>
  axios.get(`/project/exportExcel`, {
    headers: authHeader(),
    params: { search, TimeOffset: new Date().getTimezoneOffset() },
    responseType: "blob",
  });

export { getProjects, exportProjectExcel };
