import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./DropDownBox.module.scss";
import clsx from "clsx";

const DropDownBox = (props) => {
  const { dataList = [], onclickItem = () => {}, currentId } = props;

  const [activeItem, setActiveItem] = useState(currentId);

  useEffect(() => {
    setActiveItem(currentId);
  }, [currentId]);

  return (
    <ul className={styles["wrap"]}>
      {dataList
        .filter((data) => data.disable !== true)
        .map((item) => (
          <li
            onClick={(e) => {
              if (item.path) setActiveItem(item.id);
              onclickItem({ id: item.id, path: item.path });
              // Prevent click in parent element
              e.stopPropagation();
            }}
            key={item.id}
            className={clsx(styles[activeItem === item.id ? "active" : ""])}
          >
            {item.name}
          </li>
        ))}
    </ul>
  );
};
DropDownBox.propTypes = {
  dataList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  currentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onclickItem: PropTypes.func,
};

export default DropDownBox;
