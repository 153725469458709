import React from "react";
import PropTypes from "prop-types";

// Helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

// Constants
import { DATA_INFO_LABEL, PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

// Styles
import styles from "./InfoContact.module.scss";

const InfoContact = ({ contactInfo }) => {
  const renderContactName = () => {
    const { email, phone, name } = contactInfo;
    const emailList = email ? email.split(",") : [];
    const phoneList = phone ? phone.split(",") : [];
    return (
      <div className={styles["contact-name"]}>
        <InfoResultItem label={DATA_INFO_LABEL.contactName} value={name} />
        <img
          src="/images/select-icon.svg"
          alt="Select icon"
          hidden={emailList.length === 0 && phoneList.length === 0}
        />

        {(emailList.length > 0 || phoneList.length > 0) && (
          <div className={styles.info}>
            {emailList.map((item, index) => (
              <p key={index}>
                <img src="/images/email-icon.svg" alt="Email icon" />
                <span title={item}>{item}</span>
              </p>
            ))}
            {phoneList.map((item, index) => (
              <p key={index}>
                <img src="/images/phone-icon.svg" alt="Phone icon" />
                <span title={item}>{item}</span>
              </p>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) =>
          item.key === PARAMETERS.contactName ? (
            renderContactName()
          ) : (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={getInformationByKey(item.key, contactInfo)}
              isIcon={item.key === PARAMETERS.typeLabel}
              type={contactInfo[PARAMETERS.type]}
            />
          )
        )}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.contact)}</>;
};

InfoContact.propTypes = {
  contactInfo: PropTypes.object,
};

export default InfoContact;
