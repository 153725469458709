import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import _get from "lodash/get";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Form, Row, Col } from "react-bootstrap";

// Services
import { updateImage, updateUserProfile } from "services/UserProfileService";

// Constant
import { FILE_SIZE, IMAGE_TYPE_ACCEPTED_STRING } from "constants/Constants";
import { UserProfileValidation } from "constants/UserProfileValidation";
import { DEFAULT_NAME } from "constants/Common";

// Components
import UserAvatar from "components/shared/file-components/user-avatar/UserAvatar";
import ComponentLoading from "components/shared/loading/ComponentLoading";
import { Button as ButtonUpdate } from "components/shared/button/Button";

// Helpers
import { getFileTypeFromName } from "helpers/GetFileNameHelper";

// Store
import { updateAllData } from "store/UserReducer";

//Styles
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";

const UserInformation = ({ goBack, userProfile }) => {
  const dispatch = useDispatch();
  const userItems = {
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
  };

  const [showLoading, setShowLoading] = useState(false);
  const [userUpdate, setUserUpdate] = useState({});
  const [imgUpload, setImgUpload] = useState("");
  const [errors, setErrors] = useState(userItems);
  const [isValidImage, setIsValidImage] = useState({
    avatar: "",
    valid: false,
  });
  const [isChange, setIsChange] = useState();

  const imageHandler = (e) => {
    const imgUpload = e.target.files[0];
    if (!imgUpload) {
      return;
    }
    if (_get(imgUpload, "size") === 0) {
      setIsValidImage({
        avatar: UserProfileValidation.avatar.required,
        valid: true,
      });
      return;
    } else if (
      !IMAGE_TYPE_ACCEPTED_STRING.includes(
        getFileTypeFromName(_get(imgUpload, "name", ""))
      )
    ) {
      setIsValidImage({
        avatar: UserProfileValidation.avatar.file_type,
        valid: true,
      });
      return;
    } else if (_get(imgUpload, "size") > FILE_SIZE.MB20) {
      setIsValidImage({
        avatar: UserProfileValidation.avatar.file_size,
        valid: true,
      });
      return;
    }

    setIsValidImage({ avatar: "", valid: false });
    setImgUpload(imgUpload);
    setIsChange(true);
  };

  const resetValidation = () => {
    setErrors(userItems);
  };

  const isFormValid = () => {
    if (!userUpdate) return false;

    let isInvalid = userItems;

    resetValidation();

    if (userUpdate.firstName?.trim() === "") {
      isInvalid.firstName = UserProfileValidation.firstName.required;
    }

    if (userUpdate.firstName?.trim().length > 100) {
      isInvalid.firstName = UserProfileValidation.firstName.max_length;
    }

    if (userUpdate.lastName?.trim() === "") {
      isInvalid.lastName = UserProfileValidation.lastName.required;
    }

    if (userUpdate.lastName?.trim().length > 100) {
      isInvalid.lastName = UserProfileValidation.lastName.max_length;
    }

    if (userUpdate.userName?.trim() === "") {
      isInvalid.userName = UserProfileValidation.userName.required;
    }

    if (userUpdate.userName?.trim().length > 100) {
      isInvalid.userName = UserProfileValidation.userName.max_length;
    }

    setErrors(isInvalid);

    return (
      isInvalid.firstName === "" &&
      isInvalid.lastName === "" &&
      isInvalid.userName === "" &&
      isInvalid.email === ""
    );
  };

  const choosePhoto = () => {
    document.getElementById("uploadPhoto").click();
  };

  const handleUpdateUserProfile = async (e) => {
    e.preventDefault();
    if (!isChange) {
      return;
    }
    if (isFormValid() && !isValidImage.valid) {
      let errorMessage = {
        avatar: "",
        email: "",
      };
      setShowLoading(true);
      try {
        // Update avatar
        if (imgUpload) {
          await updateImage(imgUpload);
          dispatch(updateAllData({ avatarHasChanged: true }));
        }
        // Update profile
        const { lastName, firstName } = userUpdate;
        await updateUserProfile({ lastName, firstName });
        toast.success("Updated profile successfully.");
        setIsChange(false);
        dispatch(updateAllData({ lastName, firstName }));
      } catch (error) {
        errorMessage = { ...errorMessage, avatar: error.response?.data?.res };
        setErrors(errorMessage);
        toast.error("Update profile failed.");
      } finally {
        setShowLoading(false);
      }
    }
  };

  const handleFirstName = (e) => {
    setIsChange(true);
    setUserUpdate({
      ...userUpdate,
      firstName: e.target.value,
    });
  };

  const handleLastName = (e) => {
    setIsChange(true);
    setUserUpdate({
      ...userUpdate,
      lastName: e.target.value,
    });
  };

  useEffect(() => {
    setUserUpdate(userProfile);
  }, [userProfile]);

  return (
    <div className="user-profile__container">
      <h4 className="user-profile__container-title">User Information</h4>
      <ComponentLoading loading={showLoading} />
      {userUpdate && (
        <Form onSubmit={handleUpdateUserProfile}>
          <div className="user-information-form">
            <Form.Group controlId="formGridUpdatePhoto">
              <div className="user-information-form__photo d-flex align-items-center">
                <figure className="user-information-form__photo-img">
                  {imgUpload ? (
                    <img
                      src={URL.createObjectURL(imgUpload)}
                      alt="User Avatar"
                    />
                  ) : (
                    <UserAvatar />
                  )}
                </figure>
                <div className="user-information-form__photo-update">
                  <input
                    type="file"
                    hidden
                    id="uploadPhoto"
                    onChange={imageHandler}
                  />
                  <ButtonUpdate
                    handleClick={choosePhoto}
                    type="button"
                    className="user-information-form__photo-btn-update"
                    name="Upload Photo"
                    iconUrl="/images/ic-upload.svg"
                  />
                  {isValidImage.valid && (
                    <span className="error text-danger">
                      {isValidImage.avatar}
                    </span>
                  )}
                </div>
              </div>
            </Form.Group>
            <Row className="my-3 pb-4">
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>
                  First Name <span className="field-required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="First name"
                  defaultValue={_get(userUpdate, "firstName", "")}
                  onChange={handleFirstName}
                />
                {errors.firstName && (
                  <span className="error text-danger">{errors.firstName}</span>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>
                  Last Name <span className="field-required">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Last name"
                  defaultValue={_get(userUpdate, "lastName", "")}
                  onChange={handleLastName}
                />
                {errors.lastName && (
                  <span className="error text-danger">{errors.lastName}</span>
                )}
              </Form.Group>
            </Row>
            <Form.Group className="my-3 pb-4" controlId="formGridEmailAddress">
              <Form.Label>
                Email Address <span className="field-required">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="Email address"
                value={_get(userUpdate, "email", "")}
                disabled
              />
              {errors.email && (
                <span className="error text-danger">{errors.email}</span>
              )}
            </Form.Group>
            <Form.Group className="my-3 pb-2" controlId="formGridClientName">
              <Form.Label>Client Name</Form.Label>
              <Form.Control
                value={
                  _get(userUpdate, "client") || DEFAULT_NAME.downstreemAdmin
                }
                disabled
              />
            </Form.Group>
          </div>
          <div className="user-profile__container-actions">
            <ButtonUpdate
              className="btn-ok"
              name="Cancel"
              handleClick={goBack}
            />
            <ButtonUpdate
              isDisabled={!isChange || isValidImage.valid}
              name="Update"
              isBorder={false}
              className="btn-primary-fill"
              handleClick={handleUpdateUserProfile}
            />
          </div>
        </Form>
      )}
    </div>
  );
};
UserInformation.propTypes = {
  goBack: PropTypes.func,
  userProfile: PropTypes.any,
  loading: PropTypes.bool,
};
export { UserInformation };
