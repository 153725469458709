import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import DateTime from "react-datetime";
import { Spinner } from "react-bootstrap";

// Store
import {
  setParamSearchTimeLine,
  setValidTime,
} from "store/EventTimelineReducer";

// Constants
import { DATE_TIME_TYPE, MONTHS } from "constants/Constants";

//Styles
import styles from "./TimeLineSelector.module.scss";
import "react-datetime/css/react-datetime.css";
import clsx from "clsx";

const TimeLineSelector = ({ canChangeDate = false }) => {
  const dispatch = useDispatch();

  const SUNDAY = 0;

  const { validTime, paramSearch } = useSelector(
    (state) => state.eventTimeline
  );

  const currentDateDisplay = paramSearch?.date
    ? new Date(paramSearch?.date)
    : new Date();
  const getDays = (year, month) => new Date(year, month, 0).getDate();

  const [year, setYear] = useState(currentDateDisplay.getFullYear());
  const [month, setMonth] = useState(currentDateDisplay.getMonth() + 1);
  const [date, setDate] = useState(currentDateDisplay.getDate());
  const [totalDays, setTotalDays] = useState(
    getDays(currentDateDisplay.getFullYear(), currentDateDisplay.getMonth() + 1)
  );
  const [validMonths, setValidMonths] = useState([]);
  const [validDays, setValidDays] = useState([]);

  // valid years data
  const checkValidYears = (current) =>
    current.isBefore(new Date()) && validTime.years.includes(current.year());

  // Change year
  const handleChangeYear = (value) => {
    const currentYear = parseInt(value.format(DATE_TIME_TYPE.YYYY));
    dispatch(setValidTime({ currentYear }));
    setYear(currentYear);
    getRangeYears();
  };

  const renderDays = () => {
    const days = [];
    for (let i = 1; i <= totalDays; i++) {
      days.push({
        weekDay: new Date(`${month}/${i}/${year}`).getDay(),
        day: i,
      });
    }
    return days.map((item) => (
      <div
        className={
          styles[!validDays.includes(item.day.toString()) ? "days-disable" : ""]
        }
        key={item.day}
        onClick={() => {
          if (canChangeDate) {
            setDate(item.day);
            updateCurrentDate(item.day);
          }
        }}
      >
        <span
          className={clsx(
            date === item.day ? styles["active"] : "",
            SUNDAY === item.weekDay ? styles["sunday"] : ""
          )}
        >
          {item.day}
        </span>
      </div>
    ));
  };

  // To get ranges show year from ... to (12 year per page)
  const getRangeYears = async () => {
    const headerYearPicker = document.getElementsByClassName("rdtSwitch");
    const listOfYear = await document.getElementsByClassName("rdtYear"); // default lib show 12 year
    if (listOfYear.length === 0 || headerYearPicker.length === 0) return;
    // Get first and last year
    const range = `${listOfYear[0].innerText} - ${
      listOfYear[listOfYear.length - 1].innerText
    }`;
    if (range) headerYearPicker[0].innerText = range;
  };

  const updateCurrentDate = (dateCurrent, currentMonth) => {
    const monthRequest = currentMonth || month;
    const days = getDays(year, monthRequest);
    setTotalDays(days);
    setDate(dateCurrent);
    const dateSelected = new Date(year, monthRequest - 1, dateCurrent);
    dispatch(setParamSearchTimeLine({ date: dateSelected }));
  };

  const updateCurrentDateTime = (currentMonth) => {
    const monthRequest = currentMonth || month;
    const validDaysNew =
      validTime.months
        .find((monthDf) => monthDf.month === monthRequest)
        ?.day.split(",") || [];
    const dateHasData = !validTime.keepDate
      ? Number(validDaysNew[0]) || new Date().getUTCDate()
      : date;
    updateCurrentDate(dateHasData, monthRequest);
  };

  const onChangeMonth = (currentMonth) => {
    if (canChangeDate) {
      setMonth(currentMonth);
      if (validTime.months.length > 0) updateCurrentDateTime(currentMonth);
    }
  };

  useEffect(() => {
    setValidMonths(validTime.months.map((item) => item.month));
  }, [validTime]);

  // Active day that has the data
  useEffect(() => {
    const validDaysNew =
      validTime.months
        .find((monthDf) => monthDf.month === month)
        ?.day.split(",") || [];
    setValidDays(validDaysNew);
  }, [validTime, month]);

  useEffect(() => {
    if (validTime.months.length > 0 && !validTime.validTimeLoading)
      updateCurrentDateTime(null);
  }, [validTime.validTimeLoading]);

  useEffect(() => {
    setDate(currentDateDisplay.getDate());
    setMonth(currentDateDisplay.getMonth() + 1);
  }, [paramSearch?.date]);

  useEffect(() => {
    setYear(validTime.currentYear);    
  }, [validTime.currentYear]);

  useEffect(() => {
    if (validTime.months.length > 0 && validTime.keepDate !== true) 
      onChangeMonth(validTime.months[0].month);
  }, [validTime.months]);

  return (
    <div className={styles["container"]}>
      <div className={styles["year-picker-container"]}>
        <div className={styles["year-picker-dropdown"]}>
          {!validTime.validYearsLoading && !validTime.validTimeLoading ? (
            <DateTime
              initialViewMode="years"
              dateFormat={DATE_TIME_TYPE.YYYY}
              timeFormat={false}
              closeOnClickOutside
              closeOnSelect
              value={currentDateDisplay}
              className={styles["year-picker"]}
              onChange={handleChangeYear}
              inputProps={{ readOnly: true, disabled: !canChangeDate }}
              onNavigateForward={getRangeYears}
              onNavigateBack={getRangeYears}
              isValidDate={checkValidYears}
            />
          ) : (
            <Spinner animation="border" variant="primary" />
          )}
        </div>
        <div className={styles["months-list"]}>
          {MONTHS.map((item) => (
            <div
              key={item.value}
              onClick={() => onChangeMonth(item.value)}
              className={
                styles[(validMonths.includes(item.value) && !(validTime.validTimeLoading || validTime.validYearsLoading)) ? "" : "months-disable"]
              }
            >
              <span className={month === item.value ? styles["active"] : ""}>
                {item.label}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className={styles[!(validTime.validTimeLoading || validTime.validYearsLoading) ? "days-list" : "days-list-disable"]}>{renderDays()}</div>
    </div>
  );
};

TimeLineSelector.propTypes = {
  canChangeDate: PropTypes.bool,
};

export default TimeLineSelector;
