import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Components
import ExportPreview from "components/export-management/export-preview/ExportPreview";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import ActionExport from "../action-export/ActionExport";
import Checkbox from "components/shared/checkbox/Checkbox";
import { Button } from "components/shared/button/Button";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import { FadeLoader } from "react-spinners";

// Helpers & Constants
import { ACTION_NAME, STATUS_EXPORT } from "constants/ExportConstant";
import { DATE_TIME_TYPE, SORT_BY } from "constants/Constants";
import { getTagSelectedName } from "helpers/GetFileNameHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { COLORS } from "constants/Common";

// Service
import { getExportSummaryApi } from "services/ExportManagementService";
import { handleAbortRequest } from "services/ApiConfig";

// Stores
import { setCheckedStatus } from "store/ExportManagementReducer";

// Styles
import styles from "./ExportTable.module.scss";
import clsx from "clsx";
import { tagSelected } from "helpers/ExportHelpers";

const ExportTable = (props) => {
  const {
    exportDataList,
    onSortTable,
    sortColumns,
    loadingTable,
    sortType,
    applyFilterStatus,
    handleRunExport,
    handleCloneExport,
    handleDeleteExport,
    handleCancelExport,
    handleDownloadExport,
    handleCancelReschedule,
    openDatePicker,
    handleEditExport,
    isSearchExport,
    statusApply,
    loadingAction,
    handlePrintExport,
  } = props;

  const dispatch = useDispatch();
  const statusRef = useRef(null);
  const actionRef = useRef(null);
  const previewRef = useRef(null);
  const { projectId } = useParams();

  // Get data from redux
  const { allTags, showTags = [] } = useSelector((state) => state.tag.tags);
  const userCurrentId = useSelector((state) => state.user.userInfo.id);
  const { checkedStatus } = useSelector(
    (state) => state.export
  );

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [showAction, setShowAction] = useState("");
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [disPreview, setDisPreview] = useState("");
  const [dataTable, setDataTable] = useState(exportDataList);

  const STATUS_ALL = -1;
  const STATUS_LIST = Object.values(STATUS_EXPORT);
  const classSort = (columnName) => {
    const obj = sortColumns.find((item) => item.type === columnName);
    return `sort ${obj?.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  useOnClickOutside(statusRef, () => {
    setIsShowFilter(false);
    dispatch(setCheckedStatus(statusApply));
  });

  useOnClickOutside(actionRef, () => {
    setShowAction("");
  });

  useOnClickOutside(previewRef, () => {
    setDisPreview("");
    setLoadingPreview(false);
    handleAbortRequest();
  });

  // Get color for status of export
  const getColorStatus = (status) => {
    const data = STATUS_LIST.find((item) => Number(item.value) === status);
    return {
      borderColor: data.color,
      backgroundColor: data.background,
    };
  };

  const onSelectStatus = (value) => {
    if (Number(value) === STATUS_ALL)
      return [...STATUS_LIST.map((item) => item.value), STATUS_ALL];
    if (checkedStatus.length + 1 === STATUS_LIST.length)
      return [...checkedStatus, ...[STATUS_ALL, Number(value)]];
    return [...checkedStatus, Number(value)];
  };

  const onUnSelectStatus = (value) => {
    if (Number(value) === STATUS_ALL) return [];
    return checkedStatus.filter(
      (item) => item !== Number(value) && item !== STATUS_ALL
    );
  };

  // Handle select data filter by status
  const handleChecked = (data) => {
    const { checked, value } = data.target;
    if (checked) dispatch(setCheckedStatus(onSelectStatus(value)));
    else dispatch(setCheckedStatus(onUnSelectStatus(value)));
  };

  const renderActions = (status, exportId, userCreateId, exportName) => {
    let actionList = [];
    switch (status) {
      case STATUS_EXPORT.ready.value:
        actionList = [
          {
            label: ACTION_NAME.run,
            onClick: () => handleRunExport(exportId),
          },
          {
            label: ACTION_NAME.clone,
            onClick: () => handleCloneExport(exportId),
          },
          {
            label: ACTION_NAME.delete,
            onClick: () => handleDeleteExport(exportId),
            isActive: false,
          },
        ];
        break;
      case STATUS_EXPORT.inprogress.value:
        actionList = [
          {
            label: ACTION_NAME.cancel,
            onClick: () => handleCancelExport(exportId),
            isActive: false,
          },
        ];
        break;
      case STATUS_EXPORT.scheduled.value:
        actionList = [
          {
            label: ACTION_NAME.reschedule,
            onClick: () => openDatePicker(exportId),
          },
          {
            label: ACTION_NAME.cancelSchedule,
            onClick: () => handleCancelReschedule(exportId),
          },
          {
            label: ACTION_NAME.clone,
            onClick: () => handleCloneExport(exportId),
          },
          {
            label: ACTION_NAME.delete,
            onClick: () => handleDeleteExport(exportId),
            isActive: false,
          },
        ];
        break;
      case STATUS_EXPORT.completed.value:
        actionList = [
          {
            label: ACTION_NAME.download,
            onClick: () => {
              handleDownloadExport(exportId, exportName);
              setShowAction("");
            },
          },
          {
            label: ACTION_NAME.clone,
            onClick: () => handleCloneExport(exportId),
          },
          {
            label: ACTION_NAME.delete,
            onClick: () => handleDeleteExport(exportId),
            isActive: false,
          },
        ];
        break;
      case STATUS_EXPORT.fail.value:
        actionList = [
          {
            label: ACTION_NAME.restart,
            onClick: () => handleRunExport(exportId),
          },
          {
            label: ACTION_NAME.clone,
            onClick: () => handleCloneExport(exportId),
          },
          {
            label: ACTION_NAME.delete,
            onClick: () => handleDeleteExport(exportId),
            isActive: false,
          },
        ];
        break;
      case STATUS_EXPORT.draft.value:
        actionList = [
          {
            label: ACTION_NAME.edit,
            onClick: () => handleEditExport(exportId),
          },
          {
            label: ACTION_NAME.clone,
            onClick: () => handleCloneExport(exportId),
          },
          {
            label: ACTION_NAME.delete,
            onClick: () => handleDeleteExport(exportId),
            isActive: false,
          },
        ];
        break;
      default:
        break;
    }
    return (
      <span className={styles["icon-action"]}>
        <img
          onClick={() => setShowAction(exportId)}
          src="/images/menu-control.svg"
          alt="menu-action"
        />

        {showAction === exportId && (
          <div className={styles["action-control"]} ref={actionRef}>
            <ActionExport
              actionList={actionList
                .concat({
                  label: ACTION_NAME.print,
                  onClick: () => handlePrintExport(exportId),
                })
                .map((action) => ({
                  ...action,
                  userCreateId,
                }))}
              status={status}
              userCurrentId={userCurrentId}
            />
          </div>
        )}
      </span>
    );
  };

  const renderPreviewData = (exportData) => {
    const {
      exportConfiguration,
      scheduleTime,
      exportInformation,
    } = exportData;
    // get configuration data
    const {
      DateFormat,
      RSMFDocumentSize,
      TypeExport,
      TimeZone,
      EMLDocumentSize,
      TagID,
      Delimiter,
      ExcludeTags
    } = exportConfiguration ? JSON.parse(exportConfiguration) : {};

    // get summary data
    const {
      Cost,
      EmailCount,
      MessageCount,
      OtherTypeCount,
      PhysicalFileCount,
      Size,
      TotalItems,
      TotalFile,
    } = exportInformation ? JSON.parse(exportInformation) : {};

    // Define export configuration data
    const exportConfigurationData = {
      tagsSelected: getTagSelectedName(allTags, TagID),
      tagsExcluded: getTagSelectedName(allTags, ExcludeTags),
      typeExport: TypeExport,
      dateFormat: DateFormat,
      timeZone: TimeZone,
      delimiter: Delimiter,
      rsmfDocumentSize: RSMFDocumentSize,
      emlDocumentSize: EMLDocumentSize,
      scheduleTime: formatDateTime({
        dateTime: scheduleTime,
        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
      }),
    };

    // Define export summary data
    const summaryData = {
      messageCount: MessageCount,
      physicalFileCount: PhysicalFileCount,
      emailCount: EmailCount,
      otherTypeCount: OtherTypeCount,
      totalItems: TotalItems,
      size: Size,
      cost: Cost,
      totalFile: TotalFile,
    };
    return (
      <ExportPreview
        exportConfiguration={exportConfigurationData}
        summaryData={summaryData}
        isLoading={loadingPreview}
      />
    );
  };

  const showExportPreview = (exportID) => {
    setLoadingPreview(false);
    setDisPreview(exportID);
  };

  const updateSummaryData = async (
    exportID,
    exportConfig,
    userCreateIdOfRecord
  ) => {
    setLoadingPreview(true);
    setDisPreview(exportID);
    // get configuration data
    const {
      TagID = [],
      Condition,
      ExportAroundItems = 0,
      TypeExport = [],
    } = exportConfig ? JSON.parse(exportConfig) : {};
    const tagRequest = getTagSelectedName(allTags, TagID);

    try {
      let data = {};
      if (tagSelected(TagID, showTags) || userCreateIdOfRecord === userCurrentId) {
        const bodyRequest = {
          tagIDs: tagRequest.map((item) => item.tagID),
          conditionType: Condition,
          typeExport: TypeExport,
          exportAroundItems: ExportAroundItems,
        };
        const response = await getExportSummaryApi(projectId, bodyRequest);
        data = response.data;
      }

      const exportInformationRs = {
        MessageCount: data?.messageCount || 0,
        PhysicalFileCount: data?.physicalFileCount || 0,
        EmailCount: data?.emailCount || 0,
        OtherTypeCount: data?.otherTypeCount || 0,
        TotalItems: data?.totalItems || 0,
        Size: data?.size || 0,
        Cost: data?.cost || 0,
        TotalFile: data?.totalFile || 0,
      };

      // Update export data table latest
      setDataTable({
        ...dataTable,
        items: dataTable.items.map((exportData) => {
          if (exportData.exportID === exportID) {
            return {
              ...exportData,
              exportInformation: JSON.stringify(exportInformationRs),
            };
          }
          return exportData;
        }),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingPreview(false);
    }
  };

  useEffect(() => {
    setCheckedStatus([]);
  }, [projectId]);

  useEffect(() => {
    setShowAction("");
  }, [loadingAction]);

  useEffect(() => {
    setDataTable(exportDataList);
  }, [JSON.stringify(exportDataList)]);

  return (
    <div className={styles["wrap"]}>
      {loadingTable ? (
        <SpinnerLoading />
      ) : (
        <table>
          <thead>
            <tr>
              <th>
                <span
                  className={classSort(sortType.exportName)}
                  onClick={() => onSortTable(sortType.exportName)}
                >
                  Export Name
                </span>
              </th>
              <th>
                <span
                  className={classSort(sortType.userCreate)}
                  onClick={() => onSortTable(sortType.userCreate)}
                >
                  User
                </span>
              </th>
              <th>
                <span
                  className={classSort(sortType.createdDate)}
                  onClick={() => onSortTable(sortType.createdDate)}
                >
                  Created Date
                </span>
              </th>
              <th>
                <span
                  className={classSort(sortType.exportTime)}
                  onClick={() => onSortTable(sortType.exportTime)}
                >
                  Last Export Date/Time
                </span>
              </th>
              <th>
                <span
                  className={classSort(sortType.totalItems)}
                  onClick={() => onSortTable(sortType.totalItems)}
                >
                  # of items
                </span>
              </th>
              <th ref={statusRef}>
                <span
                  className={"sort-desc sort"}
                  onClick={() => setIsShowFilter(true)}
                >
                  Status
                </span>
                {isShowFilter && (
                  <div className={styles["status-control"]}>
                    <div className={styles["status-list"]}>
                      <Checkbox
                        key={STATUS_ALL}
                        name={"status all"}
                        label={"All Statuses"}
                        handleClick={handleChecked}
                        isChecked={checkedStatus.includes(STATUS_ALL)}
                        id="all"
                        value={STATUS_ALL}
                        labelSmall={true}
                      />
                      {STATUS_LIST.map((data) => (
                        <Checkbox
                          key={data.value}
                          name={"status"}
                          label={data.label}
                          handleClick={handleChecked}
                          isChecked={checkedStatus.includes(data.value)}
                          id={data.value}
                          value={data.value}
                          color={data.color}
                          labelSmall={true}
                        />
                      ))}
                    </div>
                    <div className={styles["btn-control"]}>
                      <Button
                        name="Cancel"
                        handleClick={() => {
                          setIsShowFilter(false);
                          dispatch(setCheckedStatus(statusApply));
                        }}
                        className="btn-small"
                      />
                      <Button
                        name="Apply"
                        className="btn-small-blue"
                        handleClick={() => {
                          setIsShowFilter(false);
                          applyFilterStatus(
                            checkedStatus.filter((item) => item !== STATUS_ALL)
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
              </th>
              <th>
                <span className={styles["no-sort"]}></span>
              </th>
            </tr>
          </thead>
          {dataTable.items.length > 0 ? (
            <tbody>
              {dataTable.items.map((item, index) => (
                <tr key={index}>
                  <td title={item.exportName}>
                    <span className={styles["not-trim-line"]}>
                      {item.exportName}
                    </span>
                  </td>
                  <td title={item.userCreate}>
                    <span
                      title={item.userCreate}
                      className={styles["not-trim-line"]}
                    >
                      {item.userCreate}
                    </span>
                  </td>
                  <td>
                    <span
                      title={formatDateTime({
                        dateTime: item.createdDate,
                        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmssA,
                      })}
                    >
                      {formatDateTime({
                        dateTime: item.createdDate,
                        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmssA,
                      })}
                    </span>
                  </td>
                  <td>
                    <span
                      title={formatDateTime({
                        dateTime: item.exportTime,
                        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmssA,
                      })}
                    >
                      {item.exportTime
                        ? formatDateTime({
                            dateTime: item.exportTime,
                            type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmssA,
                          })
                        : "-"}
                    </span>
                  </td>
                  <td ref={previewRef}>
                    {STATUS_EXPORT.completed.value === item.status ? (
                      <span
                        className={styles["item-count"]}
                        onClick={() => showExportPreview(item.exportID)}
                      >
                        #{item.totalItems}
                      </span>
                    ) : (
                      <span
                        className={clsx(
                          styles["item-count"],
                          styles["view-latest"]
                        )}
                        onClick={() =>
                          updateSummaryData(
                            item.exportID,
                            item.exportConfiguration,
                            item.userCreateId
                          )
                        }
                      >
                        Update Stats
                      </span>
                    )}
                    {disPreview === item.exportID && (
                      <div className={styles["preview-item"]}>
                        {renderPreviewData(item)}
                      </div>
                    )}
                  </td>
                  <td className={styles["statuses"]}>
                    <span
                      style={getColorStatus(item?.status)}
                      className={styles["status"]}
                    >
                      {
                        STATUS_LIST.find(
                          (status) => status.value === item.status
                        ).label
                      }
                      {[
                        STATUS_EXPORT.inprogress.value,
                        STATUS_EXPORT.canceling.value,
                      ].includes(item.status) && (
                        <FadeLoader
                          className={styles["loading"]}
                          color={COLORS.main}
                          height={6}
                          width={2}
                          margin={-10}
                          speedMultiplier={1}
                        />
                      )}
                    </span>
                  </td>
                  <td className={styles["actions"]}>
                    {loadingAction !== item.exportID &&
                      renderActions(
                        item.status,
                        item.exportID,
                        item.userCreateId,
                        item.exportName
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <div className={styles["no-result"]}>
              <EmptyPage
                messages={
                  isSearchExport
                    ? "No results found. Please try again."
                    : "You don't have any record yet."
                }
              />
            </div>
          )}
        </table>
      )}
    </div>
  );
};

ExportTable.propTypes = {
  exportDataList: PropTypes.object,
  sortType: PropTypes.object,
  sortColumns: PropTypes.array,
  statusApply: PropTypes.array,
  loadingTable: PropTypes.bool,
  isSearchExport: PropTypes.bool,
  loadingAction: PropTypes.string,
  onSortTable: PropTypes.func,
  applyFilterStatus: PropTypes.func,
  handleRunExport: PropTypes.func,
  handleCloneExport: PropTypes.func,
  handleDeleteExport: PropTypes.func,
  handleCancelExport: PropTypes.func,
  handleDownloadExport: PropTypes.func,
  handleEditExport: PropTypes.func,
  openDatePicker: PropTypes.func,
  handleCancelReschedule: PropTypes.func,
  handlePrintExport: PropTypes.func,
};

export default ExportTable;
