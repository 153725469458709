import React from "react";
import PropTypes from "prop-types";

// helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";
import { getFileNameFromUrl } from "helpers/GetFileNameHelper";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

// Constants
import { TIMELINE_INFORMATION } from "constants/DataType";
import { PARAMETERS } from "constants/Common";

// styles

const InfoVoiceMail = ({ voiceMailInfo }) => {
  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) =>
          item.key === PARAMETERS.title ? (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={getFileNameFromUrl(voiceMailInfo.url)}
            />
          ) : (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={getInformationByKey(item.key, voiceMailInfo)}
              isIcon={item.key === PARAMETERS.typeLabel}
              type={voiceMailInfo[PARAMETERS.type]}
            />
          )
        )}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.voicemail)}</>;
};

InfoVoiceMail.propTypes = {
  voiceMailInfo: PropTypes.object,
};

export default InfoVoiceMail;
