import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { camelCase, clone } from "lodash";

//Services
import { getISSearchResultApi, getISSearchResultDetailApi } from "services";
import { getGroupMsgInfo } from "services/GeneralReviewService";
import { getEmailReviewDetailApi } from "services/EmailReviewService";
import { getSearchHits } from "services/SearchService";

//Stores
import {
  resetStoreSearchResult,
  setGeneralInfoPreview,
  setGeneralReviews,
  setPaging,
  setParamSearch,
  resetGeneralInfoPreview,
  setIdDoc,
} from "store/ISReducer";
import { resetDateTimeMess } from "store/DatePickerReducer";
import { fetchTagsList } from "store/TagReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";

// Helpers and Constants
import { onConvertMessages } from "helpers/ConversationHelper";
import { CHAT_EMAIL_TYPE, DATA_TYPE } from "constants/DataType";
import { SORT_BY } from "constants/Constants";
import { PAGINATION } from "constants/Common";
import { trimObject } from "helpers/ObjectHelper";
import { nextRecord, previousRecord } from "helpers/PagingHelper";
import { COLUM_RESULT } from "constants/StreemConstant";
import { convertDataSourceInfo } from "helpers/DataSourceHelper";

// Components
import { Button } from "components/shared/button/Button";
import { PreviewTable } from "../preview-table/PreviewTable";
import InformationContainer from "components/intelligent-search/search-result/information/InformationContainer";
import PreviewResultContainer from "components/intelligent-search/search-result/preview/PreviewResultContainer";

// Styles
import styles from "./PreviewSearchModal.module.scss";

export const PreviewSearchModal = (props) => {
  const {
    isShow = false,
    handleClose,
    handleSubmit,
    requestBody,
    setIsShowPreviewModal,
  } = props;

  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [reviewType, setReviewType] = useState("");
  const [isSwitchChat, setIsSwitchChat] = useState(false);
  const [totalSearchCount, setTotalSearchCount] = useState(0);
  const [totalMessageContainKeyWord, setTotalMessageContainKeyWord] =
    useState(0);
  const [listPageNumber, setListPageNumber] = useState([]);
  const [currentID, setCurrentID] = useState(null);
  const [allFirstLoadMessages, setAllFirstLoadMessages] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isInfoLoading, setIsInfoLoading] = useState(false);
  const [filterPreview, setFilterPreview] = useState({
    End: null,
    Search: null,
    IsNewest: false,
    PageNumber: 1,
    Start: null,
    includeBlankDate: true,
  });

  // Store
  const { messageViewDatePicker: dateFilterMsg } = useSelector(
    (state) => state.datePicker
  );

  // Get data from store
  const {
    idDoc,
    isResultData: {
      generalReviews = [],
      generalInfoPreview = {},
      paramSearch = {},
      paging,
    },
  } = useSelector((state) => state.is);

  // Get data from store
  const allTags = useSelector((state) => state.tag.tags.showTags);

  const dataSourceList = useSelector(
    (state) => state.dataSource.dataSourceList
  );

  // Fetch result general review list
  const fetchSearchReview = async (body) => {
    setIsLoading(true);
    setIsPreviewLoading(true);
    try {
      let results = [];
      let totalRecords = 0;
      const trimData = trimObject(body);
      const dataResult = await getISSearchResultApi(projectId, trimData);
      results = dataResult?.data?.items;
      totalRecords = dataResult?.data?.totalRecords;
      if (results?.length === 0) setIsPreviewLoading(false);
      else {
        setCurrentID(results[0].id);
        setReviewType(results[0].type);
      }
      dispatch(setGeneralReviews(results || []));
      dispatch(setPaging({ totalRecords }));
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Get data detail for item
  const onSwitchRecord = (data) => {
    if (!isPreviewLoading && currentID !== data.id) {
      setCurrentID(data.id);
      setReviewType(data.type);
      dispatch(setPaging({ recordNumber: data.index }));
    }
  };

  //fetch data information of chat
  const fetchInformationChat = async (chatId) => {
    setIsInfoLoading(true);
    try {
      const { data } = await getGroupMsgInfo(projectId, chatId, {});
      if (!data) return;
      dispatch(
        setGeneralInfoPreview({
          participants: data?.participants,
          dataSourceID: generalReviews[paging.recordNumber]?.dataSourceID,
        })
      );
      setIsInfoLoading(false);
    } catch (error) {
      if (!error.response.status) return;
      setIsPreviewLoading(false);
    }
  };

  const fetchGroupMessage = async (chatId, isFetchInfo = true, param = {}) => {
    try {
      if (isFetchInfo) fetchInformationChat(chatId);
      const searchFilter = param.Search ?? paramSearch?.searchMsgInput
      const updatedFilter = { ...param, projectId, chatId, Search: searchFilter };
      SetPageStartValue(updatedFilter);
      const { data } = await getSearchHits(updatedFilter);
      let messages = onConvertMessages(data?.instantMessages, 0);
      const chatCurrent = generalReviews.find((item) => item.id === chatId);
      setAllFirstLoadMessages(messages);
      dispatch(
        setGeneralInfoPreview({
          deletedState: chatCurrent?.deleted,
          id: chatCurrent?.id,
          instantMessages: messages,
          type: chatCurrent?.type,
          chatID: chatCurrent?.id,
          totalMessage: data?.totalMessage,
          source: data?.sources,
        })
      );
      // set total search count when have search input
      if (paramSearch?.searchMsgInput) {
        setTotalSearchCount(data?.searchCount);
        setTotalMessageContainKeyWord(data?.messageCount);
      } else {
        setTotalSearchCount(0);
        setTotalMessageContainKeyWord(0);
      }
      setListPageNumber(data?.pageNumbers);
      setIsPreviewLoading(false);
    } catch (error) {
      if (!error.response.status) return;
      setIsPreviewLoading(false);
    } finally {
      setIsSwitchChat(false);
    }
  };

  const fetchEmailReviewDetail = async (threadId, param) => {
    if (!threadId) return;
    try {
      const { data } = await getEmailReviewDetailApi(
        projectId,
        threadId,
        param
      );
      dispatch(
        setGeneralInfoPreview({
          emails: data.email,
          participants: data.participants,
          id: threadId,
          dataSourceID: generalReviews[paging.recordNumber]?.dataSourceID,
          type: generalReviews[paging.recordNumber]?.type,
        })
      );
      setIsPreviewLoading(false);
    } catch (error) {
      if (!error.response.status) return;
      setIsPreviewLoading(false);
    }
  };

  // Get search result detail and dispatch to store
  const fetchGeneralDetail = async (type, threadId, param) => {
    try {
      const response = await getISSearchResultDetailApi(
        camelCase(type),
        projectId,
        threadId,
        param
      );
      dispatch(setGeneralInfoPreview(response.data));
      setIsPreviewLoading(false);
    } catch (error) {
      dispatch(resetGeneralInfoPreview());
      if (!error.response.status) return;
      setIsPreviewLoading(false);
    } finally {
      setIsSwitchChat(false);
    }
  };

  // Get search result detail and dispatch to store
  const getGeneralReviewDetail = ({
    id = "",
    type = "",
    param = {},
    isFetchInfo = true,
  }) => {
    if (!id) return;
    setIsPreviewLoading(true);
    setIsSwitchChat(true);
    setCurrentID(id);
    setReviewType(type);
    if ([DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(type))
      fetchGroupMessage(id, isFetchInfo, param);
    else if ([DATA_TYPE.email, DATA_TYPE.emailThread].includes(type))
      fetchEmailReviewDetail(id, param);
    else fetchGeneralDetail(type, id, param);
  };

  // Only switch record when previous loaded
  const onPreviousRecord = () => {
    if (paging.recordNumber === 0 || isPreviewLoading) return;
    else {
      setCurrentID(generalReviews[previousRecord(paging.recordNumber)]?.id);
      setReviewType(generalReviews[previousRecord(paging.recordNumber)]?.type);
      dispatch(
        setPaging({ recordNumber: previousRecord(paging.recordNumber) })
      );
    }
  };

  const onNextRecord = () => {
    if (paging.recordNumber === generalReviews.length - 1 || isPreviewLoading)
      return;
    else {
      setCurrentID(generalReviews[nextRecord(paging.recordNumber)]?.id);
      setReviewType(generalReviews[nextRecord(paging.recordNumber)]?.type);
      dispatch(setPaging({ recordNumber: nextRecord(paging.recordNumber) }));
    }
  };

  const onSetParamSearchStore = (keyword, isSecondSearch) => {
    if (isSecondSearch)
      dispatch(setParamSearch({ isSecondSearch, searchMsgInput: keyword }));
    else dispatch(setParamSearch({ searchMsgInput: keyword }));
  };

  const onSetParamIsNewestStore = (isNewest) => {
    dispatch(setParamSearch({ isNewest }));
  };

  const onSortTable = (data) => {
    let results = clone(generalReviews);
    results = sortData(results, data.sortType, data.columnSort);
    dispatch(setGeneralReviews(results || []));
    dispatch(setPaging({ recordNumber: 0 }));
    setReviewType(results[0]?.type);
    setCurrentID(results[0]?.id);
  };

  const renameKeys = (obj) =>
    Object.keys(obj).reduce((acc, key) => {
      acc[key.toLowerCase()] = obj[key];
      return acc;
    }, {});

  const sortData = (data, type, field) => {
    if (!field) return data;
    if (
      field === COLUM_RESULT.dateTime.key ||
      field === COLUM_RESULT.dateLast.key
    ) {
      return data?.sort((a, b) => {
        const valueA = new Date(a.dateTime);
        const valueB = new Date(b.dateTime);
        return type === SORT_BY.desc ? valueB - valueA : valueA - valueB;
      });
    } else {
      const results = data?.sort((a, b) => {
        const valueA = renameKeys(a)[field.toLowerCase()] || "";
        const valueB = renameKeys(b)[field.toLowerCase()] || "";
        if (
          field === COLUM_RESULT.itemsCount.key ||
          field === COLUM_RESULT.attachmentCount.key
        )
          return Number(valueA) - Number(valueB);
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      });
      return type === SORT_BY.desc ? results?.reverse() : results;
    }
  };

  const SetPageStartValue = (param) => {
    const messages = generalInfoPreview?.instantMessages;
  
    param.PageStartValue = messages?.length && param.PageNumber > 1
      ? messages.at(-1)?.order + 1 
      : 1;
  
    param.PageNumber = 1;
  }; 

  const setInstantMessagesStore = (lstMessages, totalMessage = -1) => {
    dispatch(
      setGeneralInfoPreview({
        ...generalInfoPreview,
        instantMessages: clone(lstMessages),
        totalMessage:
          totalMessage === -1 ? generalInfoPreview?.totalMessage : totalMessage,
      })
    );
  };

  const handleClosePopup = () => {
    if ([DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(reviewType))
      handleClose();
    setIsShowPreviewModal(false);
    // Clear date picker at message view
    dispatch(resetDateTimeMess());
  };

  const handleSearchEmailDetailByDate = (data) => {
    const dateFilter = {
      ...filterPreview,
      Start: data.dateTimeDataStart || null,
      End: data.dateTimeDataEnd || null,
      includeBlankDate: data.includeBlankDate,
    };
    setFilterPreview(dateFilter);
    getGeneralReviewDetail({
      id: currentID,
      type: reviewType,
      param: dateFilter,
      isFetchInfo: false,
    });
  };

  const fetchChatDetailApi = async (_, chatId, filter = {}) => {
    setFilterPreview({
      ...filterPreview,
      ...filter,
      includeBlankDate: filter.includeBlankDate,
    });
    const searchFilter = filter.Search ?? paramSearch?.searchMsgInput;
    const updatedFilter = { ...filter, projectId, chatId, Search: searchFilter };
    SetPageStartValue(updatedFilter);
    return await getSearchHits(updatedFilter);
  };

  const scrollViewRecord = () => {
    const element = document.getElementById(
      `focus-item-${paging.recordNumber}`
    );
    element &&
      element.scrollIntoView({
        block: "nearest",
        inline: "nearest",
        behavior: "smooth",
      });
  };

  const getDataSourceInfo = () => {
    const listDataSourceId =
      generalReviews[paging.recordNumber]?.dataSourceID?.split(/,\s*/) || [];
    return convertDataSourceInfo(dataSourceList, listDataSourceId);
  }

  const filterData = {
    loading: isInfoLoading,
    previewLoading: isPreviewLoading,
    dataSourceInfo: { getDataSourceInfo },
    participantInfo: {
      participants: generalInfoPreview?.participants,
      isRedColor: CHAT_EMAIL_TYPE.includes(reviewType),
    }
  };

  // Get result
  useEffect(() => {
    fetchSearchReview(requestBody);
    const isResultBody = {
      ...requestBody,
      paginationParams: {
        pageNumber: PAGINATION.pageNumberDefault,
        pageSize: PAGINATION.recordPerPageTable,
      },
    };
    dispatch(fetchTagsList({ projectId }));
    dispatch(fetchDataSourceList(projectId));
    dispatch(setParamSearch(isResultBody));
    dispatch(resetStoreSearchResult());
    return () => {
      dispatch(resetStoreSearchResult());
      dispatch(resetDateTimeMess());
    };
  }, []);

  useEffect(() => {
    if (generalReviews.length > 0) {
      setIsFirstLoad(false);
      dispatch(setIdDoc(generalReviews[0]?.docID));
      getGeneralReviewDetail({
        id: generalReviews[0].id,
        type: generalReviews[0].type,
      });
      scrollViewRecord();
    }
  }, [generalReviews]);

  useEffect(() => {
    setFilterPreview({ ...filterPreview, Search: paramSearch.searchMsgInput });
  }, [paramSearch.searchMsgInput]);

  useEffect(() => {
    if (paging.recordNumber >= 0) {
      if (generalReviews[paging.recordNumber]) {
        setIsFirstLoad(false);
        dispatch(setIdDoc(generalReviews[paging.recordNumber]?.docID));
        getGeneralReviewDetail({
          id: generalReviews[paging.recordNumber].id,
          type: generalReviews[paging.recordNumber].type,
          param: {
            ...filterPreview,
            PageNumber: PAGINATION.pageNumberDefault,
          },
        });
      }
      // Set scroll into view when click previous and next record
      scrollViewRecord();
    }
  }, [paging.recordNumber]);

  return (
    <Modal
      className={styles["modal-container"]}
      show={isShow}
      onHide={handleClosePopup}
    >
      <div>
        <Modal.Header className={styles["modal-header"]}>
          <div className={styles["modal-header-right"]}>
            <p className={styles["modal-header-title"]}>Preview Result</p>
            <p className={styles["modal-header-record"]}>
              {`Showing ${generalReviews.length} of ${generalReviews.length.toLocaleString()} ${
                generalReviews.length > 1 ? "items" : "item"
              }`}
            </p>
          </div>
          <div onClick={handleClosePopup} className={styles["header-close"]}>
            <img src="/images/close-modal-icon.png" alt="Close" />
          </div>
        </Modal.Header>
        <Modal.Body className={styles["modal-body"]}>
          <div>
            <PreviewTable
              paging={paging}
              loading={isLoading}
              isResultsData={generalReviews}
              onSort={onSortTable}
              onSwitchRecord={onSwitchRecord}
              onPreviousRecord={onPreviousRecord}
              onNextRecord={onNextRecord}
              isPreviewLoading={isPreviewLoading}
              dataSourceList={dataSourceList}
            />
          </div>
          <div className={styles["is-result-info-and-preview"]}>
            <div className={styles["is-result-info"]}>
              <InformationContainer
                displayType="preview"
                loading={
                  [DATA_TYPE.chat, DATA_TYPE.chatGroup].includes(reviewType)
                    ? isInfoLoading
                    : isLoading ||
                      (isPreviewLoading && generalReviews.length > 0)
                }
                reviewType={reviewType}
                data={generalInfoPreview}
                filterData={filterData}
                totalRecords={paging.totalRecords}
                showRecordControls={false}
                docID={idDoc}
              />
            </div>
            <div className={styles["is-result-preview"]}>
              <PreviewResultContainer
                showTagsList={allTags}
                displayType="preview"
                reviewType={reviewType}
                loading={
                  isLoading || (isPreviewLoading && generalReviews.length > 0)
                }
                setIsResultLoading={setIsLoading}
                data={generalInfoPreview}
                participants={generalInfoPreview?.participants}
                paging={paging}
                totalItemOfPage={generalReviews.length}
                searchMsgInput={paramSearch?.searchMsgInput || ""}
                dateTime={dateFilterMsg}
                totalSearchCount={totalSearchCount}
                setTotalSearchCount={setTotalSearchCount}
                totalMessageContainKeyWord={totalMessageContainKeyWord}
                setTotalMessageContainKeyWord={setTotalMessageContainKeyWord}
                listPageNumber={listPageNumber}
                setListPageNumber={setListPageNumber}
                setSearchMsgInputStore={onSetParamSearchStore}
                onSetParamIsNewestStore={onSetParamIsNewestStore}
                isSwitchChat={isSwitchChat}
                setInstantMessagesStore={setInstantMessagesStore}
                setIsShowPreviewModal={setIsShowPreviewModal}
                handleSearchEmailDetailByDate={handleSearchEmailDetailByDate}
                fetchChatDetailApi={fetchChatDetailApi}
                allFirstLoadMessages={allFirstLoadMessages}
                isFirstLoad={isFirstLoad}
                setIsFirstLoad={setIsFirstLoad}
                isShowOrigin={false}
                isShowCheckBox={false}
                onClearSearchMsg={() =>
                  dispatch(setParamSearch({ searchMsgInput: null }))
                }
                showTagComponent={false}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles["modal-footer"]}>
          <Button name="Cancel" handleClick={handleClosePopup} />
          <Button
            name="Execute Search"
            isBorder={false}
            className="btn-primary-fill"
            handleClick={handleSubmit}
          />
        </Modal.Footer>
      </div>
    </Modal>
  );
};

PreviewSearchModal.propTypes = {
  isShow: PropTypes.bool,
  requestBody: PropTypes.object,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  setIsShowPreviewModal: PropTypes.func,
};
