import axios, { authHeader } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const createIndexApi = (matterId, data) => {
  return axios.post(
    `/indexManagement/createIndexJob?matterId=${matterId}`, data, {
      headers: authHeader(),
    }
  );
};

const convertCompressImageApi = (projectId, dataSourceId) => {
  return axios.get(`/helper/${projectId}?dataSourceID=${dataSourceId}`, {
    headers: authHeader(),
    timeout: 0
  });
};

const loadProcedureApi = () => {
  return axios.get(`/helper/loadProcedure`, {
    headers: authHeader(),
    timeout: 0
  });
};

const hashingPasswordApi = (params = {}) => {
  const { projectId, connectionString, password } = params;
  return axios.get(
    `/helper/hashingPassword`,
    {
      headers: authHeader(),
      params: {
        projectId,
        connectionString,
        password
      },
    }
  );
};

const reloadHeatmapDataApi = (projectId, isContactData = false) => {
  return axios.get(
    `/azure/reloadHeatmapData?projectId=${projectId}&isContactData=${isContactData}`,
    {
      headers: authHeader(),
      timeout: 0,
    }
  );
};
const updatePartyToContactApi = (projectId) => {
  return axios.get(
    `/azure/updatePartyToContact?projectId=${projectId}`,
    {
      headers: authHeader(),
      timeout: 0
    }
  );
};

const createProjectDBApi = (projectId) => {
  return axios.get(
    `/azure/createProjectDb?projectId=${projectId}`,
    {
      headers: authHeader(),
      timeout: 0
    }
  );
};

const createClientApi = (params) =>
  axios(`/projectAdmin/createClient`, {
    headers: authHeader(),
    method: METHOD_API.post,
    params,
    timeout: 0,
  });

export {
  createIndexApi,
  convertCompressImageApi,
  loadProcedureApi,
  hashingPasswordApi,
  reloadHeatmapDataApi,
  updatePartyToContactApi,
  createProjectDBApi,
  createClientApi,
}