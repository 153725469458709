import React from "react";
import { Route, Routes } from "react-router-dom";
import { registerLicense } from '@syncfusion/ej2-base';

// Components
import LoginAuth0 from "pages/auth/LoginAuth0";
import AuthLanding from "pages/dashboard/AuthLanding";
import ToastCustom from "components/toast/ToastCustom";
import PrivateRoute from "./routes/PrivateRoute";

// Styles
import "../src/assets/css/common.scss";
import "../src/assets/css/_base.scss";
import "../src/assets/css/normalize.scss";

const App = () => {
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);

  return (
    <div id="container" className="app-container">
      <ToastCustom />
      <Routes>
        <Route path="callback" element={<AuthLanding />} />
        <Route path="/login" element={<LoginAuth0 />} />
        <Route path="*" element={<PrivateRoute />} />
      </Routes>
    </div>
  );
};

export default App;
