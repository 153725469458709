import React, { Component } from "react";
import PropTypes from "prop-types";

// Components
import Header from "components/header/Header";
import ErrorResult from "./ErrorResult";

// Constants & Helpers
import { ERROR_STATUS } from "constants/StatusCodeConstant";

// Only Error Boundaries make by class component
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <Header errorCode={ERROR_STATUS.badRequest} />
          <ErrorResult
            imgErr="/images/error-boundary.png"
            status="This page isn't working"
            contentHTML="Currently unable to handle your request. <br/>
              Please reload this page."
            hasButton
          />
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.any,
};

export default ErrorBoundary;
