import React, { useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

// Components
import TabList from "components/shared/tab-list/TabList";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import IdentifiersManagement from "pages/manage-identifiers/IdentifiersManagement";
import EntitiesManagement from "pages/manage-entity/EntitiesManagement";

// Constants
import { breadCrumbPeople } from "constants/BreadCrumbConstants";
import { PAGE_NAME, PATH_NAME } from "constants/Common";

// styles
import styles from "./IdentifierEntityManagement.module.scss";

const componentList = [
  { id: 1, name: PAGE_NAME.managePeople, component: <EntitiesManagement /> },
  { id: 2, name: PAGE_NAME.manageIdentifiers, component: <IdentifiersManagement /> },
];

const IdentifierEntityManagement = () => {
  const navigate = useNavigate();

  const [currentTabId, setCurrentTabId] = useState(componentList[0].id);

  const goBack = () => navigate(`/${PATH_NAME.matters}`);

  return (
    <div className={clsx(styles["wrap"], "main")}>
      <BreadCrumb
        goBack={goBack}
        breadCrumbData={breadCrumbPeople}
      />
      <div className={styles["tab-control"]}>
        <TabList
          currentTab={{ parentTab: currentTabId }}
          tabList={componentList.map(({ id, name }) => ({ id, name }))}
          onClickTab={({ parentTab }) => setCurrentTabId(parentTab)}
          size="md"
        />
      </div>
      {componentList.find((tab) => tab.id === currentTabId).component}
    </div>
  );
};

export default IdentifierEntityManagement;
