const COLUMN_NAME_ENTITY = {
  checkbox: {
    value: "checkbox",
    label: "",
  },
  name: {
    value: "name",
    label: "Name",
  },
  identifier: {
    value: "identifierCount",
    label: "Associated Identifiers",
  },
  jobTitle: {
    value: "jobTitle",
    label: "Job Title",
  },
  company: {
    value: "company",
    label: "Company Name",
  },
  occurrences: {
    value: "occurrences",
    label: "Occurrences",
  },
  dateFirst: {
    value: "dateFirst",
    label: "Date First",
  },
  dateLast: {
    value: "dateLast",
    label: "Date Last",
  },
  action: {
    value: "action",
    label: "Action",
  },
};

const ENTITY_VALIDATION = {
  avatar: {
    required: "Image file should not be empty.",
    file_size: "Image file should be less than or equal to 20MB.",
    file_type:
      "Please upload image file with one of the following extensions: *jpeg, *jpg, *png.",
  },
  name: {
    required: "Person name is required.",
    max_length: "Person name must be less than or equal to 100 characters.",
  },
  jobTitle: {
    max_length: "Job Title must be less than or equal to 100 characters.",
  },
  notes: {
    max_length: "Note must be less than or equal to 500 characters.",
  },
  company: {
    max_length: "Company must be less than or equal to 100 characters.",
  },
  email: {
    required: "This field is required.",
    invalid: "Please input a valid email address.",
    max_length: "The email address must have 3 to 100 characters.",
    exists: "You have already added this identifier.",
  },
  phone: {
    required: "This field is required.",
    invalid: "Please input a valid phone number.",
    max_length: "The phone number must have 9 to 20 numbers.",
    exists: "You have already added this identifier.",
  },
  social: {
    required: "This field is required.",
    max_length: "The app name must have 3 to 100 characters.",
    exists: "You have already added this identifier.",
  },
  existsDB: {
    oneContact: "This identifier already exists in another people.",
    manyContact: "These identifiers already exist in another people.",
  },
};

export { COLUMN_NAME_ENTITY, ENTITY_VALIDATION };
