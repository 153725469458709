import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import clsx from "clsx";

// Hooks
import { useToken } from "hook/auth";
import { useOnClickOutside } from "hook/click-outside";

// Components
import UserFilter from "components/project-detail/UserFilter";
import ComponentLoading from "components/shared/loading/ComponentLoading";
import ConfirmDeleteMemberPopup from "components/project-detail/ConfirmDeleteMemberPopup";

// Services
import { getProjectUsers } from "services/ProjectAdministrationService";
import { assignUser, removeUser } from "services/ProjectDetailMemberService";

// Styles
import styles from "./AssignUserManagement.module.scss";
import MatterAvatar from "components/shared/file-components/matter-avatar/MatterAvatar";

const AssignUserManagement = ({
  users = [],
  projectId,
  updateUsers,
  isLoading,
  onHide,
  show = false,
}) => {
  const searchInputRef = useRef(null);
  const popupSearchRef = useRef(null);
  const { isClientAdmin, isAdmin } = useToken();

  const [suggestions, setSuggestions] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
  const [idUserConfirmRemove, setIdUserConfirmRemove] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchSuggestions = async () => {
    try {
      const result = await getProjectUsers(projectId);
      if (!result) return;
      setSuggestions(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showConfirmRemoveUser = (userId) => {
    setIsDeleteConfirm(true);
    setIdUserConfirmRemove(userId);
  };

  const deleteUser = async () => {
    setLoading(true);
    setIsDeleteConfirm(false);
    try {
      const result = await removeUser(idUserConfirmRemove, projectId);
      if (!result) return;
      updateUsers();
      fetchSuggestions();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
      setIdUserConfirmRemove(null);
    }
  };

  const onAssignUser = async (suggestion) => {
    if (!suggestion.id) return;
    setLoading(true);
    try {
      await assignUser(suggestion.id, projectId);
      updateUsers();
      fetchSuggestions();
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  useOnClickOutside(popupSearchRef, () => {
    if (isPopupOpen) {
      setIsPopupOpen(false);
      setIsDeleteConfirm(false);
      setIdUserConfirmRemove(null);
    }
  });

  useEffect(() => {
    (isAdmin() || isClientAdmin()) && fetchSuggestions();
  }, [projectId]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className={styles["modal-container"]}
      dialogClassName={styles["modal-dialog"]}
      contentClassName={styles["modal-content"]}
    >
      <div ref={popupSearchRef}>
        <ComponentLoading loading={isLoading || loading} />
        <ConfirmDeleteMemberPopup
          show={isDeleteConfirm}
          onClickYes={deleteUser}
          onClickNo={() => setIsDeleteConfirm(false)}
        />
        <h4 className={styles["add-member-popup__title"]}>Assign User</h4>
        <div className={styles["add-member-popup__close"]}>
          <img
            src="/images/close-modal-icon.png"
            alt="Close"
            onClick={() => {
              onHide();
              setIsPopupOpen(false);
            }}
          />
        </div>
        <div className={styles["add-member-popup__search"]}>
          <UserFilter
            inputRef={searchInputRef}
            isAutoComplete
            suggestions={suggestions}
            placeholder="Search users for assign"
            name="search"
            onSubmitSearch={onAssignUser}
          />
        </div>
        <div
          className={clsx(
            styles["add-member-popup__members"],
            styles["member-list"]
          )}
        >
          {users.length > 0 &&
            users.map((user) => {
              return (
                <div
                  className={styles["member-list__item"]}
                  key={`member-${user.userId}`}
                >
                  <figure className={styles["user-avatar"]}>
                    <MatterAvatar
                      id={user.userId}
                      matterId={projectId}
                      avatarType="user"
                    />
                  </figure>
                  <div className={styles["user-name"]}>{user.name}</div>
                  <div className={styles["user-action"]}>
                    <button
                      type="button"
                      className={styles["btn-remove-user"]}
                      onClick={() => showConfirmRemoveUser(user.userId)}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          {users.length === 0 && (
            <div className={styles["no-users"]}>
              There are no users assigned yet.
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

AssignUserManagement.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      userId: PropTypes.string,
    })
  ),
  projectId: PropTypes.any,
  isLoading: PropTypes.bool,
  show: PropTypes.bool,
  updateUsers: PropTypes.func,
  onHide: PropTypes.func,
};

export default AssignUserManagement;
