import { COMMON_TEXT } from "constants/Common";
import { DATE_TIME_TYPE } from "../constants/Constants";
import * as DateTimeHelper from "./DateTimeFormatterHelper";

export const getFormattedDateTime = (date, appendUtcLabel) => {
    try {
        if (date === null || date === undefined) {
            return null;
        }

        const dateObj = new Date(date);
        const formattedDate = DateTimeHelper.formatDateTime({
            dateTime: dateObj.toISOString(),
            type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
        });

        return `${formattedDate}${appendUtcLabel ? " UTC" : ""}`;
    } catch {
        return null;
    }
};

export const getStringDisplay = (value) => {
    if (value && typeof value === "string" && value.startsWith("[") && value.endsWith("]")) {
        try {
            const parsedValue = JSON.parse(value);
            return parsedValue.join(", ");
        } catch {
            return value;
        }
    }
    return value;
};

export const getLocationMapValue = (value) => (value ? value : COMMON_TEXT.unknown);

export const formatDisplayBytes = (input) => {
    if (input == null || isNaN(input)) {
        return null;
    }

    try {
        const bytes = Number(input);

        if (bytes < 0) {
            return null;
        }

        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        if (bytes === 0) return '0 Bytes';

        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const size = Math.pow(1024, i);

        return `${(bytes / size).toFixed(2)} ${sizes[i]}`;
    } catch {
        return null;
    }
}