import React, { useCallback, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import clsx from "clsx";

// Services
import { getHeatMapApi, getHeatMapQuickSelect } from "services/HeatMapService";

// Redux
import {
  clearHeatmapData,
  setHeatMapData,
  setLoadingHeatMap,
  setHeatMapQuickSelectData,
  setParamSearchHeatMap,
  paramSearchInitHeatMap,
} from "store/HeatMapReducer";
import { clearDatePickerData, updateDateTime } from "store/DatePickerReducer";
import { handleTypeFilter } from "helpers/DateTimeFormatterHelper";

// Component
import { HeatMap } from "components/heatmap/HeatMap";
import QuickFilter from "components/shared/quick-filter/QuickFilter";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import EmptyPage from "components/shared/empty-page/EmptyPage";

// Constants
import { QUICK_SELECT_ITEM } from "constants/Common";

// Helpers
import {
  convertContact,
  convertFilter,
  getFilterValue,
  getFilterLabel,
} from "helpers/CommonHelper";

// Styles
import styles from "./HeatMapPage.module.scss";

export const HeatMapPage = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const inputRef = useRef();
  const searchInputRef = useRef("");

  const [isEmptyData, setIsEmptyData] = useState(false);
  const [isError, setIsError] = useState(false);
  const [loadingQuickSelect, setLoadingQuickSelect] = useState(false);
  const [quickFilterData, setQuickFilterData] = useState([]);
  const [isFirstLoadComponent, setIsFirstLoadComponent] = useState(true);

  // Get param search heatmap from redux
  const {
    paramSearch,
    quickFilter,
    heatMapData,
    loading: loadingHeatMap,
  } = useSelector((state) => state.heatMap);
  const { dateTimeDataStart, dateTimeDataEnd } = useSelector(
    (state) => state.datePicker.datePicker
  );

  // Fetch heatmap data from API when paramSearch changed
  const getHeatMapDataFromApi = async (paramSearch) => {
    try {
      setIsError(false);
      dispatch(setLoadingHeatMap(true));
      const heatMapResult = await getHeatMapApi(projectId, paramSearch);
      // set heatmap data to redux
      dispatch(setHeatMapData(heatMapResult?.data));
      if (!heatMapResult?.data || (Array.isArray(heatMapResult?.data) && heatMapResult?.data.length === 0))
        setIsEmptyData(true);
    } catch (error) {
      dispatch(setHeatMapData({ data: [], listName: [] }));
      setIsEmptyData(true);
      setIsError(true);
      console.log(error);
    } finally {
      dispatch(setLoadingHeatMap(false));
    }
  };

  // Set param search heatmap to redux store
  const onSearchHandle = () => {
    if (paramSearch.IsValidSearch) {
      const inputValue = inputRef.current.value?.trim();
      dispatch(setParamSearchHeatMap({ Search: inputValue }));
      getHeatMapDataFromApi({
        ...paramSearch,
        Search: inputValue,
        DataSources: paramSearch.DataSources.map((item) => item.dataSourceId),
      });
    }
  };

  const fetchHeatMapFilter = async () => {
    setLoadingQuickSelect(true);
    try {
      const dataResult = await getHeatMapQuickSelect(projectId);
      // Update to redux-store
      dispatch(
        setHeatMapQuickSelectData({
          entities: dataResult?.data?.entities,
          participants: dataResult?.data?.participants,
          dataTypes: dataResult?.data?.dataType,
          dataSources: dataResult?.data?.custodianSources,
        })
      );
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingQuickSelect(false);
    }
  };

  const applyQS = useCallback((data) => {
    const {
      participants = [],
      entities = [],
      dataTypes = [],
      dataSources = [],
    } = data;
    // Check display screen empty data when filter
    const totalContact = [...participants, ...entities].length;
    if (totalContact < 2) {
      setIsEmptyData(true);
      return;
    }
    setIsEmptyData(false);

    const params = {
      ...paramSearch,
      Entities: getFilterValue(entities),
      Participants: getFilterValue(participants),
      Types: getFilterValue(dataTypes),
      DataSources: dataSources.map((item) => item.value),
    };

    // Set param search (Name, Type, DataSource) heatmap to redux store
    dispatch(
      setParamSearchHeatMap({
        ...params,
        DataSources: dataSources.map((item) => ({
          custodianName: item.label,
          dataSourceId: item.value,
          dataSourceType: item.sourceType,
        })),
      })
    );
    getHeatMapDataFromApi(params);
  });

  const resetParamSearch = (isResetFilter = false) => {
    const param = {
      ...paramSearch,
      Types: [],
      DataSources: [],
      Entities: [],
      Participants: [],
      Search: null,
    };
    dispatch(setParamSearchHeatMap(param));
    setIsEmptyData(false);
    isResetFilter && getHeatMapDataFromApi(param);
  };

  // Filter contacts has a quick filter
  const filterContact = (contacts) => {
    const array = contacts.filter((contact) =>
      heatMapData.listName.find((item) => item.id === contact.id)
    );
    return array.map((item) => item.id);
  };

  useEffect(() => {
    // Fetch heatmap data from API when paramSearch and projectId changed
    const dataQuery = {
      ...paramSearch,
      DataSources: paramSearch.DataSources.map((item) => item.dataSourceId),
    };
    fetchHeatMapFilter();
    getHeatMapDataFromApi(dataQuery);
    dispatch(
      updateDateTime({
        dateTimeDataStart: paramSearch.start || null,
        dateTimeDataEnd: paramSearch.end || null,
      })
    );
    setIsFirstLoadComponent(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoadComponent) {
      // Clear data when projectId change
      dispatch(clearHeatmapData());
      dispatch(clearDatePickerData());
      fetchHeatMapFilter();
      getHeatMapDataFromApi(paramSearchInitHeatMap);
    }
  }, [projectId]);

  useEffect(() => {
    dispatch(
      updateDateTime({
        typeFilter: handleTypeFilter(dateTimeDataStart, dateTimeDataEnd),
      })
    );
  }, [dateTimeDataStart, dateTimeDataEnd]);

  // Set default selected for entities and contact
  useEffect(() => {
    if (!loadingHeatMap && !loadingQuickSelect) {
      const { entities = [], participants = [] } = quickFilter;
      dispatch(
        setParamSearchHeatMap({
          Entities: filterContact(entities),
          Participants: filterContact(participants),
        })
      );
    }
  }, [
    loadingHeatMap,
    loadingQuickSelect,
    JSON.stringify(heatMapData.listName),
  ]);

  useEffect(() => {
    if (!loadingQuickSelect) {
      setQuickFilterData([
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataSources.value),
          filterType: QUICK_SELECT_ITEM.dataSources.value,
          filterData: {
            dataSelected: convertFilter(
              paramSearch.DataSources,
              QUICK_SELECT_ITEM.dataSources.value
            ),
            dataUnSelect: convertFilter(
              quickFilter.dataSources,
              QUICK_SELECT_ITEM.dataSources.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.entities.value),
          filterType: QUICK_SELECT_ITEM.entities.value,
          filterData: {
            dataSelected: convertFilter(
              convertContact(quickFilter.entities, paramSearch.Entities),
              QUICK_SELECT_ITEM.entities.value
            ),
            dataUnSelect: convertFilter(
              quickFilter.entities,
              QUICK_SELECT_ITEM.entities.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.identifiers.value),
          filterType: QUICK_SELECT_ITEM.participants.value,
          filterData: {
            dataSelected: convertFilter(
              convertContact(
                quickFilter.participants,
                paramSearch.Participants
              ),
              QUICK_SELECT_ITEM.participants.value
            ),
            dataUnSelect: convertFilter(
              quickFilter.participants,
              QUICK_SELECT_ITEM.participants.value
            ),
          },
        },
        {
          filterLabel: getFilterLabel(QUICK_SELECT_ITEM.dataTypes.value),
          filterType: QUICK_SELECT_ITEM.dataTypes.value,
          filterData: {
            dataSelected: convertFilter(paramSearch.Types),
            dataUnSelect: convertFilter(quickFilter.dataTypes),
          },
        },
      ]);
    }
  }, [loadingQuickSelect, paramSearch.Entities, paramSearch.Participants]);

  return (
    <div className={clsx(styles["heatmap-page"], "main")}>
      <div className={styles["heatmap-page-content"]}>
        <div className={styles["quick-select-heatmap"]}>
          <QuickFilter
            data={quickFilterData}
            loading={loadingQuickSelect}
            onApply={applyQS}
            onReset={() => resetParamSearch(true)}
            totalDisable={20}
          />
        </div>
        <div className={styles["heatmap-wrap"]}>
          <div className={styles["heatmap-container"]}>
            <div className={styles["heatmap-header"]}>
              <h5 className={styles["heatmap-title"]}>Heatmap</h5>
              <SearchInputGeneral
                inputRef={inputRef}
                placeholder="Search"
                name="search"
                onSubmitSearch={onSearchHandle}
                ref={searchInputRef}
                searchInput={paramSearch?.Search || ""}
              />
            </div>
            {loadingHeatMap ? (
              <Spinner
                className={styles.loading}
                animation="border"
                variant="primary"
              />
            ) : (
              isEmptyData ? (
                <div className={styles["heatmap-empty-data"]}>
                  <EmptyPage messages= {isError 
                    ? "An error has occured" 
                    : "No results found"} 
                  />
                </div>
              ) : (
                <HeatMap
                  loading={loadingHeatMap}
                  projectId={projectId}
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
