import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import BenzAMRRecorder from "benz-amr-recorder";

// Components
import AmrConvertAudio from "components/shared/amr-convert-audio/AmrConvertAudio";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import DownloadAttachmentButton from "components/shared/download-attachment-button/DownloadAttachmentButton";

//Constants
import { FILE_TYPE } from "constants/DataType";

// Services
import { streamNativeMedia } from "services";

// Helpers
import { formatDuration } from "helpers/DateTimeFormatterHelper";

// Store
import { setDuration } from "store/MediaControlReducer";

// Styles
import styles from "./PreviewAudio.module.scss";

const PreviewAudio = (props) => {
  const {
    matterId,
    attachmentId,
    filename,
    allowEventPropagation = false
  } = props;

  const dispatch = useDispatch();

  const audioRef = useRef(null);
  const amrRef = useRef(null);

  const [url, setUrl] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);
  const [contentType, setContentType] = useState("");
  const [hasFailed, setHasFailed] = useState(false);
  const [isMissing, setIsMissing] = useState(false);
  const [canPlay, setCanPlay] = useState(false);

  const isAmrAudio = (type, name) => type?.includes(FILE_TYPE.audioAmr) || name?.includes(".amr");

  const renderTypeAudio = () => {
    if (isAmrAudio(contentType, filename) && url !== "") {
      return <AmrConvertAudio
        src={url}
        amr={amrRef.current}
        onFailure={() => {
          setHasFailed(true)
        }}
        allowEventPropagation={allowEventPropagation}
      />;
    } else if (canPlay) {
      return (
        <>
          <div className={styles["failed"]}>
            <div className={styles["error-msg"]}>File format is unsupported</div>
            {filename && <p>{filename}</p>}
          </div>
          <DownloadAttachmentButton matterId={matterId} attachmentId={attachmentId} />
        </>
      )
    } else if (isMissing) {
      return (<div className={styles["failed"]}>
        <div className={styles["error-msg"]}>Audio attachment is missing</div>
        {filename && <p>{filename}</p>}
      </div>)
    } else if (hasFailed) {
      return (<div className={styles["failed"]}>
        <div className={styles["error-msg"]}>Error loading audio preview</div>
        {filename && <p>{filename}</p>}
      </div>)
    }

    return (
      <audio ref={audioRef} controls controlsList="nodownload" src={url} />
    )
  };

  useEffect(() => {
    if (!hasLoaded && attachmentId) {
      const fetchAttachment = async () => {
        try {
          const response = await streamNativeMedia(matterId, attachmentId);
          if (response) {
            let url = URL.createObjectURL(new Blob([response.data]));
            const responseContentType = response.headers["content-type"];
            setContentType(responseContentType);
            setUrl(url);

            if (!isAmrAudio(responseContentType, filename)) {
              const media = new Audio();
              media.addEventListener("loadedmetadata", () => {
                dispatch(setDuration(formatDuration(media.duration)));
              });

              media.addEventListener("error", () => {
                setCanPlay(true);
                setHasFailed(true);
              });
              media.src = url;
            } else {
              amrRef.current = new BenzAMRRecorder();
            }
          }
        } catch (error) {
          console.error(error);
          if (error.response?.status === 404) {
            setIsMissing(true);
          } else {
            setHasFailed(true);
          }
        } finally {
          setHasLoaded(true);
        }
      }

      fetchAttachment();

      return () => {
        URL.revokeObjectURL(url);
        dispatch(setDuration(""));
      }
    }
  }, [attachmentId]);

  useEffect(() => {
    return () => {
      if (amrRef.current?.isInit()) {
        amrRef.current.stop();
      }
    };
  }, [amrRef]);

  return (
    <div>
      {hasLoaded ? (
        <div
          className={styles["audio-wrap"]}
          onClick={allowEventPropagation ? undefined : (e) => e.stopPropagation()}
        >
          {renderTypeAudio()}
        </div>
      ) : (
        <SpinnerLoading />
      )}
    </div>
  );
};

PreviewAudio.propTypes = {
  matterId: PropTypes.string,
  attachmentId: PropTypes.string,
  filename: PropTypes.string,
  allowEventPropagation: PropTypes.bool
};

export default PreviewAudio;
