import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { isEmpty, omitBy, get, camelCase } from "lodash";
import { toast } from "react-toastify";
import { useToken } from "hook/auth";
import clsx from "clsx";
import { useOnClickOutside } from "hook/click-outside";

// Services
import {
  deleteSavedSearch,
  getCombineSearch,
  getManageSearch,
  getManageSearchDetail,
  updateSearchName,
} from "services/SavedSearchService";

// Stores
import {
  clearSavedSearchCondition,
  setSavedSearchCondition,
} from "store/SearchConditionReducer";
import {
  resetStoreSearchResult,
  setParamSearch as setParamSearchResult,
  resetIsData,
} from "store/ISReducer";
import { setIsMatterDisable } from "store/CommonReducer";
import { fetchDataSourceList } from "store/DataSourceReducer";

//Components
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import Checkbox from "components/shared/checkbox/Checkbox";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import { Button } from "components/shared/button/Button";
import SavedSearchQueries from "components/intelligent-search/saved-search/saved-search-queries/SavedSearchQueries";
import PopupAddSavedSearch from "components/intelligent-search/saved-search/popup-add-saved-search/PopupAddSavedSearch";
import { CombineSearch } from "components/intelligent-search/saved-search/combine-search/CombineSearch";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";

//Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { isReadValue } from "helpers/ObjectHelper";
import {
  handleConsolidatedType,
  handleDataQueries,
} from "helpers/SavedSearchHelpers";
import { formatListDataSource } from "helpers/CommonHelper";

//Constants
import {
  DATE_TIME_TYPE,
  SCREEN_WIDTH_3839,
  SORT_BY,
} from "constants/Constants";
import { LOCAL_STORAGE } from "constants/LocalStorage";
import { COMMON_TEXT, PATH_NAME } from "constants/Common";
import { SAVED_SEARCH_QUERIES } from "constants/SavedSearchConstant";
import { breadCrumbSavedSearch } from "constants/BreadCrumbConstants";

//Styles
import styles from "./SavedSearchPage.module.scss";

export const SavedSearchPage = () => {
  const { projectId } = useParams();
  const inputRef = useRef();
  const actionRef = useRef();
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { isAdmin } = useToken();

  const MAX_ITEMS_COMBINE = 2;
  const TOTAL_ITEM = {
    screen4K: 20,
    fullHD: 10,
  };
  const perPage =
    window.innerWidth > SCREEN_WIDTH_3839
      ? TOTAL_ITEM.screen4K
      : TOTAL_ITEM.fullHD;

  const sortType = {
    type: "type",
    searchName: "searchName",
    user: "user",
    count: "count",
    dateCreated: "dateCreated",
    dateLastRun: "dateLastRun",
  };

  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingPopup, setLoadingPopup] = useState(false);
  const [savedSearchData, setSavedSearchData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [showAction, setShowAction] = useState(false);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const [count, setCount] = useState(0);
  const [combineItems, setCombineItems] = useState([]);
  const [searchID, setSearchID] = useState("");
  const [rerender, setRerender] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [isUpdateName, setIsUpdateName] = useState(false);
  const [dataEdit, setDataEdit] = useState({});
  const [isLoading, setIsLoading] = useState();
  const [isCombine, setIsCombine] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  // Store
  const { searchInput, pageIndex, columnSort, orderBy, sortColumns } =
    useSelector((state) => state.searchCondition.savedSearch);
  const userInfo = useSelector((state) => state.user.userInfo);
  const dataSourceList = useSelector((state) => state.dataSource.dataSourceList);

  const onSortTable = (columnName) => {
    const obj = sortColumns.find((item) => item.type === columnName);
    const newSortColumns = sortColumns.map((item) => ({
      type: item.type,
      isAcs: item.type === columnName ? !obj.isAcs : true,
    }));
    dispatch(
      setSavedSearchCondition({
        columnSort: columnName,
        orderBy: !obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
        sortColumns: newSortColumns,
      })
    );
    setRerender(!rerender);
  };

  const classSort = (columnName) => {
    const obj = sortColumns.find((item) => item.type === columnName);
    return `sort ${obj?.isAcs ? SORT_BY.sortAsc : SORT_BY.sortDesc}`;
  };

  // fetch manage search data
  const fetchManageSearchData = async (searchInput, pageNumber) => {
    setLoadingTable(true);
    const params = {
      PaginationParams: {
        ColumnSort: columnSort,
        OrderBy: orderBy,
        PageSize: perPage,
        PageNumber: pageNumber,
      },
      Search: searchInput,
    };
    try {
      const dataResult = await getManageSearch(projectId, params);
      setSavedSearchData(get(dataResult, "data.items", []));
      setSuggestions(get(dataResult, "data.selectName", []));
      setTotalRecord(get(dataResult, "data.totalRecords", 0));
      setIsFirstLoad(false);
      setLoadingTable(false);
    } catch (error) {
      setSavedSearchData([]);
      setTotalRecord(0);
      if (!error.response?.status) return;
      setLoadingTable(false);
    }
  };

  //fetch manage search by id
  const fetchManageSearchById = async (searchID) => {
    setLoadingPopup(true);
    try {
      const dataResult = await getManageSearchDetail(projectId, searchID);
      setDataEdit(get(dataResult, "data.object", {}));
    } catch {
      setDataEdit({});
    } finally {
      setLoadingPopup(false);
    }
  };

  // handle paging save search list
  const handlePagingClick = (event) => {
    dispatch(
      setSavedSearchCondition({
        searchInput,
        pageIndex: event.selected + 1,
      })
    );
    setRerender(!rerender);
  };

  //reset data
  const resetData = () => {
    setSearchID("");
    setIsUpdateName(false);
    setShowAction(false);
    setDataEdit({});
  };

  //reset checkbox
  const resetCheckbox = () => {
    setCombineItems([]);
    setCount(0);
  };

  const goBack = () => {
    if (state?.fromScreen)
      navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME[camelCase(state.fromScreen)]}`);
    else navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.streems}`);
    dispatch(clearSavedSearchCondition());
  };

  const handleAddNewSearch = () => {
    localStorage.setItem(
      LOCAL_STORAGE.intelligentSearchPath,
      PATH_NAME.savedSearches
    );
    navigate(
      `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}`,
      {
        state: { fromScreen: PATH_NAME.savedSearches },
      }
    );
    localStorage.removeItem(`${LOCAL_STORAGE.searchData}${projectId}`);
    localStorage.removeItem(`${LOCAL_STORAGE.dataQuery}${projectId}`);
    dispatch(clearSavedSearchCondition());
    dispatch(resetIsData())
  };

  const onHandleExecuteSearch = (item) => {
    if (!item) return;
    dispatch(resetStoreSearchResult()); // reset store search result when run saved search
    const { searchID, includeBlankDate, isCombination, sources, entities } =
      item;
    dispatch(setParamSearchResult({ isShowRecents: false }));
    navigate(
      `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}/${PATH_NAME.searchResult}/${searchID}`,
      {
        state: {
          fromScreen: PATH_NAME.savedSearches,
        },
      }
    );
    localStorage.setItem(
      `${LOCAL_STORAGE.searchData}${projectId}`,
      JSON.stringify({
        ...item,
        dataSources: formatListDataSource(
          dataSourceList,
          sources.map((source) => source.dataSourceId.toString())
        ),
        entities: entities?.map((item) => item.id || item),
        includeBlankDate: isCombination ? true : includeBlankDate, // if search data have isCombination set default value includeBlankDate = true
      })
    );
    localStorage.removeItem(`${LOCAL_STORAGE.dataQuery}${projectId}`);
    localStorage.removeItem(LOCAL_STORAGE.intelligentSearchPath);
  };

  const onHandleEditSearch = (searchID) => {
    localStorage.setItem(
      LOCAL_STORAGE.intelligentSearchPath,
      PATH_NAME.savedSearches
    );
    navigate(
      `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.intelligentSearch}/${searchID}`,
      {
        state: { fromScreen: PATH_NAME.savedSearches },
      }
    );
    localStorage.removeItem(`${LOCAL_STORAGE.searchData}${projectId}`);
    localStorage.removeItem(`${LOCAL_STORAGE.dataQuery}${projectId}`);
  };

  const onHandleDeleteSearch = async () => {
    setIsLoading(true);
    setConfirmDelete(false);
    setLoadingTable(true);
    try {
      await deleteSavedSearch(projectId, searchID);
      if (savedSearchData.length % perPage === 1 && pageIndex !== 1) {
        dispatch(
          setSavedSearchCondition({
            searchInput,
            pageIndex: pageIndex - 1,
          })
        );
      }
      setRerender(!rerender);
      toast.success("Search deleted successfully");
    } catch (err) {
      console.log(err);
      toast.error("Search deleted failed");
    } finally {
      resetCheckbox();
      setIsLoading(false);
    }
  };

  // Open popup confirm delete search
  const onDeleteSearch = (searchID) => {
    if (isLoading) return;
    setSearchID(searchID);
    setConfirmDelete(true);
  };

  const onHandleUpdateName = (searchID, isCombine) => {
    fetchManageSearchById(searchID);
    setSearchID(searchID);
    setIsUpdateName(true);
    setShowPopup(true);
    setIsCombine(isCombine);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    resetData();
  };

  const handleCheck = (searchID, searchName) => {
    if (combineItems?.some((combine) => combine.searchID === searchID)) {
      setCombineItems((prev) =>
        prev.filter((item) => item.searchID !== searchID)
      );
      setCount(count - 1);
    } else {
      setCombineItems([...combineItems, { searchID, searchName }]);
      setCount(count + 1);
    }
  };

  const handleCombineSearch = async (data) => {
    if (!data) return;
    handleClosePopup();
    setLoadingTable(true);
    let params = {
      name: data.searchName,
      type: data.isPrivate,
      condition: data.valueCriteria,
      queryJSON: combineItems,
    };
    params = omitBy(params, isEmpty);
    try {
      await getCombineSearch(projectId, params);
      if (pageIndex !== 1)
        dispatch(
          setSavedSearchCondition({
            searchInput,
            pageIndex: 1,
            columnSort: sortType.dateCreated,
            orderBy: SORT_BY.desc,
          })
        );
      setRerender(!rerender);
      toast.success("Search combined successfully");
    } catch (error) {
      console.log(error);
      toast.success("Search combined failed");
    } finally {
      resetCheckbox();
    }
  };

  const handleUpdateName = async (data) => {
    if (!data) return;
    handleClosePopup();
    setLoadingTable(true);
    const params = {
      type: data.isPrivate,
      name: data.searchName,
    };
    try {
      await updateSearchName(projectId, searchID, params);
      setRerender(!rerender);
      toast.success("Search updated successfully");
    } catch (error) {
      console.log(error);
      toast.error("Search updated failed");
    } finally {
      resetCheckbox();
    }
  };

  const handleMenuAction = (index) =>
    setShowAction(showAction !== index ? index : null);

  const onSearchHandle = () => {
    setIsSearch(true);
    dispatch(
      setSavedSearchCondition({
        searchInput: inputRef?.current?.value?.trim(),
        pageIndex: 1,
      })
    );
    setRerender(!rerender);
  };

  const renderSearchQueries = (keyValueList, data) =>
    keyValueList.map((item, index) => (
      <SavedSearchQueries
        key={index}
        label={handleConsolidatedType(item.key, data)}
        dataQueries={handleDataQueries(item.key, data, dataSourceList)}
      />
    ));

  //handle Click outside
  useOnClickOutside(actionRef, () => setShowAction(false));

  const showPopupCombine = () => {
    setIsCombine(true);
    setShowPopup(true);
  };

  const handleRoleActions = (searchItem) =>
    isAdmin() || searchItem.userID === userInfo?.id;

  // Re call API when param search change
  useEffect(() => {
    dispatch(setIsMatterDisable(false));
    fetchManageSearchData(searchInput, pageIndex);
    dispatch(fetchDataSourceList(projectId));
    resetData();
  }, [rerender]);

  // Reset screen when projectId change
  useEffect(() => {
    if (!isFirstLoad) {
      fetchManageSearchData("", 1);
      dispatch(fetchDataSourceList(projectId));
      resetData();
      dispatch(clearSavedSearchCondition());
    }
  }, [projectId]);

  return (
    <div className={clsx(styles["is-saved-search-page"], "main")}>
      <BreadCrumb
        goBack={goBack}
        breadCrumbData={breadCrumbSavedSearch()}
      />
      <div className={styles["is-header"]}>
        <div className="d-flex align-items-center">
          <h2 className={styles["is-header-title"]}>Saved Searches</h2>
        </div>
        <div className="d-flex align-items-center">
          <Button
            isDisabled={count !== MAX_ITEMS_COMBINE || loadingTable}
            name="Combine Searches"
            altIcon="Combine Searches"
            handleClick={showPopupCombine}
          />
          <Button
            name="Add New Search"
            iconUrl="/images/plus-icon-white.svg"
            altIcon="Add New Search"
            className="btn-primary-fill"
            handleClick={handleAddNewSearch}
            isDisabled={loadingTable}
          />
          {showPopup && (
            <PopupAddSavedSearch
              handleClose={handleClosePopup}
              handleSubmit={
                isUpdateName ? handleUpdateName : handleCombineSearch
              }
              isShow={showPopup}
              isCombineSearch={isCombine}
              isLoading={loadingPopup}
              isUpdateName={isUpdateName}
              data={dataEdit}
            />
          )}
          <PopupConfirm
            isShow={showConfirmDelete}
            handleClose={() => setConfirmDelete(false)}
            handleSubmit={onHandleDeleteSearch}
            title="Confirmation"
            content="Are you sure you want to delete this search?"
            textConfirm={COMMON_TEXT.yes}
            textReject={COMMON_TEXT.no}
            handleReject={() => setConfirmDelete(false)}
            type={COMMON_TEXT.remove}
            isDeleteUser
          />
        </div>
      </div>
      <div className={styles["saved-search-body"]}>
        <div className={styles["saved-search-body-box"]}>
          <SearchInputGeneral
            inputRef={inputRef}
            placeholder="Search Saved Search"
            name="search"
            onSubmitSearch={onSearchHandle}
            suggestions={suggestions}
            isAutoComplete={true}
            searchInput={searchInput || ""}
          />
        </div>
        {loadingTable ? (
          <Spinner
            className={styles["loading"]}
            animation="border"
            variant="primary"
          />
        ) : (
          <>
            {savedSearchData?.length ? (
              <>
                <div className={styles["count-saved-search"]}>
                  <span>
                    {Number(totalRecord)} Saved Search
                    {totalRecord > 1 ? "es" : ""}
                  </span>
                </div>
                <div className={styles["table-saved-search"]}>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <span
                            className={classSort(sortType.type)}
                            onClick={() =>
                              onSortTable(sortType.type, sortColumns.type)
                            }
                          >
                            Type
                          </span>
                        </th>
                        <th>
                          <span
                            className={classSort(sortType.searchName)}
                            onClick={() =>
                              onSortTable(
                                sortType.searchName,
                                sortColumns.searchName
                              )
                            }
                          >
                            Search Name
                          </span>
                        </th>
                        <th>
                          <span
                            className={classSort(sortType.user)}
                            onClick={() =>
                              onSortTable(sortType.user, sortColumns.user)
                            }
                          >
                            User
                          </span>
                        </th>
                        <th>
                          <span className={styles["no-sort"]}>Queries</span>
                        </th>
                        <th>
                          <span
                            className={classSort(sortType.count)}
                            onClick={() =>
                              onSortTable(sortType.count, sortColumns.count)
                            }
                          >
                            Count
                          </span>
                        </th>
                        <th>
                          <span
                            className={classSort(sortType.dateCreated)}
                            onClick={() =>
                              onSortTable(
                                sortType.dateCreated,
                                sortColumns.dateCreated
                              )
                            }
                          >
                            Date Created
                          </span>
                        </th>
                        <th>
                          <span
                            className={classSort(sortType.dateLastRun)}
                            onClick={() =>
                              onSortTable(
                                sortType.dateLastRun,
                                sortColumns.dateLastRun
                              )
                            }
                          >
                            Date Last Run
                          </span>
                        </th>
                        <th>
                          <span className={styles["no-sort"]}>Combine</span>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {savedSearchData?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <span>{item.type}</span>
                          </td>
                          <td>
                            <span title={item.name}>{item.name}</span>
                          </td>
                          <td>
                            <span title={item.userName}>{item.userName}</span>
                          </td>
                          <td className={styles["search-queries"]}>
                            <div className={styles["search-queries-item"]}>
                              {item.isCombination ? (
                                <CombineSearch
                                  queryJSON={item.queryJSON}
                                  condition={item.condition}
                                />
                              ) : (
                                <>
                                  {renderSearchQueries(
                                    SAVED_SEARCH_QUERIES.queries,
                                    item
                                  )}
                                </>
                              )}
                            </div>
                          </td>
                          <td>
                            <span>
                              {isReadValue(item.count)
                                ? item.count
                                : COMMON_TEXT.default}
                            </span>
                          </td>
                          <td>
                            <span>
                              {isReadValue(item.dateCreated) ? (
                                <>
                                  {formatDateTime({
                                    dateTime: item.dateCreated,
                                    type: DATE_TIME_TYPE.MM_DD_YYYY,
                                  })}
                                </>
                              ) : (
                                COMMON_TEXT.default
                              )}
                            </span>
                          </td>
                          <td>
                            <span>
                              {isReadValue(item.dateLastRun) ? (
                                <>
                                  {formatDateTime({
                                    dateTime: item.dateLastRun,
                                    type: DATE_TIME_TYPE.MM_DD_YYYY,
                                  })}
                                </>
                              ) : (
                                COMMON_TEXT.default
                              )}
                            </span>
                          </td>
                          <td>
                            <Checkbox
                              id={`checkbox-search-${index}`}
                              size="sm"
                              className={styles["checkbox-savedsearch"]}
                              handleClick={() =>
                                handleCheck(item.searchID, item.name)
                              }
                              isChecked={combineItems?.some(
                                (combine) => combine.searchID === item.searchID
                              )}
                              disabled={count === MAX_ITEMS_COMBINE && !combineItems?.some(x => x.searchID === item.searchID)}
                            />
                          </td>
                          <td className={styles["more-action"]}>
                            <span className={styles["img-action"]}>
                              <img
                                src="/images/menu-control.svg"
                                alt="menu-control"
                                onClick={() => handleMenuAction(index)}
                              />
                              {showAction === index && (
                                <div ref={actionRef}>
                                  <ul className={styles["menu-action"]}>
                                    <li>
                                      <span
                                        onClick={() =>
                                          onHandleExecuteSearch(item)
                                        }
                                      >
                                        Run Search
                                      </span>
                                    </li>
                                    {handleRoleActions(item) && (
                                      <>
                                        <li>
                                          <span
                                            onClick={() =>
                                              onHandleUpdateName(
                                                item.searchID,
                                                item.isCombination
                                              )
                                            }
                                          >
                                            Edit Name
                                          </span>
                                        </li>
                                        <li>
                                          <span
                                            onClick={() =>
                                              onDeleteSearch(item.searchID)
                                            }
                                          >
                                            Delete Search
                                          </span>
                                        </li>
                                        {!item.isCombination && (
                                          <li>
                                            <span
                                              onClick={() =>
                                                onHandleEditSearch(
                                                  item.searchID
                                                )
                                              }
                                            >
                                              Edit Saved Search
                                            </span>
                                          </li>
                                        )}
                                      </>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <PaginationResult
                  perPage={perPage}
                  forcePage={pageIndex - 1}
                  totalRecord={Number(totalRecord)}
                  pageItems={savedSearchData?.length}
                  handlePagingClick={handlePagingClick}
                  isSmall
                />
              </>
            ) : (
              <div className={styles["no-result"]}>
                <EmptyPage
                  messages={
                    isSearch
                      ? "No results found. Please try again."
                      : `You don't have any Save Search yet.`
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
