import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Constants & Helpers
import { EVENT_KEY_LISTENER } from "constants/Common";
import { SOURCE_TYPE_LIST } from "constants/Constants";
import { getSourceIconById } from "helpers/CommonHelper";

// styles
import styles from "./ParticipantsInformation.module.scss";

const ParticipantsInformation = ({
  participants = [],
  isLoading = false,
  isTable = false,
  isRedColor = false,
  isShowCollapse = true,
  isShowTitle = true,
}) => {
  const [hoveredCart, setHoveredCart] = useState(-1);
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => setIsOpen(!isOpen);
  const showCartHandler = (i) => setHoveredCart(i);
  const hideCartHandler = () => setHoveredCart(-1);

  const handleScroll = () => {
    for (let i = 0; i < participants?.length; i++) {
      const element = document.getElementById("participants-drop-info-" + i);
      if (element) element.style.display = "none";
    }
  };

  useEffect(() => {
    window.addEventListener(EVENT_KEY_LISTENER.scroll, handleScroll, {
      passive: true,
    });
    return () =>
      window.removeEventListener(EVENT_KEY_LISTENER.scroll, handleScroll);
  }, [handleScroll]);

  return (
    <div
      className={clsx(
        styles["information-participants"],
        isTable ? styles["container-table-view"] : ""
      )}
    >
      <div className={styles["information-participants-label"]}>
        {isShowTitle && <p className={styles["label-title"]}>Participants</p>}
        {isShowCollapse && (
          <img
            src="/images/icons/collapse-icon.svg"
            alt="Collapse icon"
            className={clsx(
              "cursor-pointer",
              isOpen ? styles["collapse-icon"] : styles["expand-icon"]
            )}
            onClick={handleToggle}
          />
        )}
      </div>
      <div hidden={!isOpen}>
        {!isLoading ? (
          participants?.length > 0 ? (
            <ul className={styles["information-participants-list"]}>
              {participants.map((participant, participantIndex) => {
                const { messageCount, name, identifier } = participant;
                return (
                  <li
                    className={styles["participant-item"]}
                    key={participantIndex}
                  >
                    <div className={styles["participants-email"]}>
                      <img
                        className="mar_r5"
                        src="/images/people-black-icon.svg"
                        alt="People icon"
                      />
                      <div className={styles["participants-info"]}>
                        <p
                          className={clsx(
                            styles["participants-name"],
                            messageCount === 0 && isRedColor
                              ? styles["color-red"]
                              : ""
                          )}
                        >
                          {name}
                        </p>
                        <span
                          className={clsx(
                            styles["participants-desc"],
                            messageCount === 0 && isRedColor
                              ? styles["color-red"]
                              : ""
                          )}
                          title={
                            identifier.length > 0 ? identifier[0]?.value : ""
                          }
                        >
                          {identifier.length > 0 ? identifier[0]?.value : ""}
                        </span>
                      </div>
                    </div>
                    <div
                      className={styles["participants-action"]}
                      onMouseLeave={hideCartHandler}
                      onMouseEnter={(i) => showCartHandler(i)}
                      hidden={identifier.length <= 1}
                    >
                      <img src="/images/select-icon.svg" alt="Select icon" />
                      <div
                        style={{
                          top: hoveredCart.clientY + "px",
                          left: hoveredCart.clientX - 215 + "px",
                          display: hoveredCart === -1 && "none",
                        }}
                        className={styles["participants-drop-info"]}
                        id={`participants-drop-info-${participantIndex}`}
                      >
                        <ul className={styles["participants-drop-info-group"]}>
                          {identifier.length > 1 &&
                            identifier.slice(1).map((item) => (
                              <li
                                key={item.id}
                                className={styles["participant-item-group"]}
                              >
                                <img
                                  src={getSourceIconById(
                                    SOURCE_TYPE_LIST,
                                    item.socialType
                                  )}
                                  alt="Phone icon"
                                />
                                <span title={item.value}>{item.value}</span>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className={styles["empty-page"]}>No participants found.</p>
          )
        ) : (
          <div className={styles["spinner-container"]}>
            <Spinner
              className={styles["spinner-loading"]}
              animation="border"
              variant="primary"
              role="status"
            />
          </div>
        )}
      </div>
    </div>
  );
};

ParticipantsInformation.propTypes = {
  isLoading: PropTypes.bool,
  isTable: PropTypes.bool,
  isRedColor: PropTypes.bool,
  isShowCollapse: PropTypes.bool,
  isShowTitle: PropTypes.bool,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      messageCount: PropTypes.number,
      name: PropTypes.string,
      identifier: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
        })
      ),
    })
  ),
};

export { ParticipantsInformation };
