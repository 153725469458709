import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    disableMatter: false,
    isGoToOrigin: false,
    isPausedDb: false,
    suggestWord: "",
  },
  reducers: {
    setIsMatterDisable: (state, action) => {
      state.disableMatter = action.payload;
    },
    setIsGoToOrigin: (state, action) => {
      state.isGoToOrigin = action.payload;
    },
    setIsPausedDb: (state, action) => {
      state.isPausedDb = action.payload;
    },
    setSuggestWord: (state, action) => {
      state.suggestWord = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setIsMatterDisable,
  setIsGoToOrigin,
  setIsPausedDb,
  setSuggestWord,
} = commonSlice.actions;
export const CommonReducer = commonSlice.reducer;
