import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Service
import { handleAbortRequest } from "services/ApiConfig";

// Store
import { resetStoreExport } from "store/ExportManagementReducer";
import { setIsMatterDisable } from "store/CommonReducer";

// Components
import ExportPreview from "components/export-management/export-preview/ExportPreview";
import ExportDetail from "components/export-management/create-export/export-details/ExportDetail";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";

// Constants
import { breadCrumbCreateExport } from "constants/BreadCrumbConstants";
import { COMMON_TEXT, PATH_NAME } from "constants/Common";
import { BUTTON_ACTION, MSG_CONFIRM } from "constants/ExportConstant";
import { DATE_TIME_TYPE } from "constants/Constants";

// Helpers
import { getTagSelectedName } from "helpers/GetFileNameHelper";
import { formatDateTime } from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./CreateExportPage.module.scss";

const CreateExportPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { projectId, exportId } = useParams();

  const [showPopup, setShowPopup] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);
  const [breadCrumbData, setBreadCrumbData] = useState({});
  const [isChangeData, setIsChangeData] = useState(false);

  //Get data from store
  const {
    createExport: {
      scheduleTime,
      exportInformation,
      isScheduleExport,
      parameterConfig: {
        typeExport,
        tagID,
        excludeTags,
        dateFormat,
        timeZone,
        delimiter,
        rsmfDocumentSize,
        emlDocumentSize,
      },
    },
    loadingExportSummary,
    currentTime,
  } = useSelector((state) => state.export);
  const { showTags, allTags } = useSelector((state) => state.tag.tags);
  const { createExport, originalData } = useSelector((state) => state.export);
  // get summary data
  const {
    Cost,
    EmailCount,
    MessageCount,
    OtherTypeCount,
    PhysicalFileCount,
    Size,
    TotalItems,
    TotalFile,
  } = exportInformation ? JSON.parse(exportInformation) : {};

  const DEFAULT_ESTIMATED_SIZE = 8.02;
  const dataPreview = {
    summaryData: {
      messageCount: MessageCount,
      physicalFileCount: PhysicalFileCount,
      emailCount: EmailCount,
      otherTypeCount: OtherTypeCount,
      totalItems: TotalItems,
      size: Size || DEFAULT_ESTIMATED_SIZE,
      cost: Cost,
      totalFile: TotalFile,
    },
    exportConfiguration: {
      tagsSelected: state?.isPrint
        ? getTagSelectedName(allTags, tagID)
        : getTagSelectedName(showTags, tagID),
      tagsExcluded: state?.isPrint
        ? getTagSelectedName(allTags, excludeTags)
        : getTagSelectedName(showTags, excludeTags),
      typeExport,
      delimiter,
      dateFormat,
      timeZone,
      rsmfDocumentSize,
      emlDocumentSize,
      scheduleTime: formatDateTime({
        dateTime: isScheduleExport ? scheduleTime || currentTime : "",
        type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmA,
      }),
    },
  };

  const handleSubmitPopup = (action) => {
    setShowPopup(false);
    if ([buttonAction, action].includes(BUTTON_ACTION.back))
      return navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.exportManagement}`);
    if ([buttonAction, action].includes(BUTTON_ACTION.breadCrumb))
      return handleNavigate(breadCrumbData);
    return navigate(`/${PATH_NAME.matters}`);
  };

  const goBack = () => {
    if (isChangeData) {
      setButtonAction(BUTTON_ACTION.back);
      setShowPopup(true);
      return;
    }
    handleSubmitPopup(BUTTON_ACTION.back);
  };

  const handleNavigate = (item) => {
    if (item.value === PATH_NAME.matters) navigate(`/${PATH_NAME.matters}`);
    else if (item.value === projectId)
      navigate(`/${PATH_NAME.matters}/${projectId}`);
    else
      navigate(
        `/${PATH_NAME.matters}/${projectId}/${
          item.value?.toLowerCase() || PATH_NAME.exportManagement
        }`
      );
  };

  const handleBreadCrumbData = (item) => {
    if (isChangeData) {
      setButtonAction(BUTTON_ACTION.breadCrumb);
      setBreadCrumbData(item);
      setShowPopup(true);
      return;
    }
    handleSubmitPopup(BUTTON_ACTION.breadCrumb);
  };

  useEffect(() => {
    if (exportId) dispatch(setIsMatterDisable(true));
    else dispatch(setIsMatterDisable(false));

    return () => {
      handleAbortRequest();
      dispatch(resetStoreExport());
      dispatch(setIsMatterDisable(false));
    };
  }, [exportId]);

  useEffect(() => {
    if (JSON.stringify(createExport) !== originalData)
      return setIsChangeData(true);
    setIsChangeData(false);
  }, [JSON.stringify(createExport), isChangeData]);

  return (
    <div
      className={clsx(
        styles["create-export"],
        "main",
        state?.isPrint ? styles["no-events"] : ""
      )}
    >
      <div className={styles["bread-crumb"]}>
        <BreadCrumb
          goBack={goBack}
          breadCrumbData={breadCrumbCreateExport(exportId)}
          setShowPopup={setShowPopup}
          handleBackBreadCrumb={handleBreadCrumbData}
          isPopup={true}
        />
      </div>
      <div className={styles["create-export-header"]}>
        <h2>{`${exportId ? "Edit" : "Create"} Export`}</h2>
      </div>
      <div className={styles["export-form-block"]}>
        <div className={styles["export-detail"]}>
          <ExportDetail />
          <p className={styles["export-detail-footer-line"]}></p>
        </div>
        <div className={styles["export-preview"]}>
          <ExportPreview
            summaryData={dataPreview.summaryData}
            exportConfiguration={dataPreview.exportConfiguration}
            isLoading={loadingExportSummary}
          />
        </div>
      </div>
      <PopupConfirm
        isShow={showPopup}
        handleClose={() => setShowPopup(false)}
        handleSubmit={handleSubmitPopup}
        content={MSG_CONFIRM.back}
        textConfirm={COMMON_TEXT.yes}
        textReject={COMMON_TEXT.cancel}
        handleReject={() => setShowPopup(false)}
        isBackdropStatic={true}
      />
    </div>
  );
};

export default CreateExportPage;
