import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Spinner } from "react-bootstrap";

// Helpers
import { toThousandsNumber } from "helpers/FormatterHelper";

// Components
import ItemResultInline from "../item-result-inline/ItemResultInline";

// Styles
import styles from "./EntityStatistics.module.scss";

const EntityStatistics = ({
  statisticData: {
    messageCount = 0,
    imageCount = 0,
    tagsCount = 0,
    emailCount = 0,
    senderCount = 0,
    receiptCount = 0,
  },
  loading = false,
}) => {
  const statistic = [
    {
      label: "Messages",
      value: toThousandsNumber(messageCount),
    },
    {
      label: "Images",
      value: toThousandsNumber(imageCount),
    },
    {
      label: "Tagged items",
      value: toThousandsNumber(tagsCount),
    },
  ];

  const statisticEmail = [
    {
      label: "eMail",
      value: toThousandsNumber(emailCount),
    },
    {
      label: "Sender",
      value: toThousandsNumber(senderCount),
    },
    {
      label: "Recipient",
      value: toThousandsNumber(receiptCount),
    },
  ];

  const renderStatistics = (data = []) =>
    data?.map((item) => (
      <ItemResultInline
        key={item.label}
        label={item.label}
        value={item.value}
        fwBold={true}
      />
    ));

  return (
    <div className={styles["statistics-wrap"]}>
      <h5>
        <span className={styles["title"]}>Statistics</span>
        {loading && (
          <span>
            <Spinner variant="primary" animation="border" />
          </span>
        )}
      </h5>
      <div className={clsx(styles["statistics-row"])}>
        <div className={styles["statistics-column"]}>
          {renderStatistics(statistic)}
        </div>
        <div className={styles["statistics-column"]}>
          {renderStatistics(statisticEmail)}
        </div>
      </div>
    </div>
  );
};

EntityStatistics.propTypes = {
  statisticData: PropTypes.shape({
    messageCount: PropTypes.number,
    imageCount: PropTypes.number,
    tagsCount: PropTypes.number,
    emailCount: PropTypes.number,
    senderCount: PropTypes.number,
    receiptCount: PropTypes.number,
  }),
  loading: PropTypes.bool,
};

export default EntityStatistics;
