import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import SpinnerLoading from '../../spinner-loading/SpinnerLoading';

// Services
import { downloadImageAttachment } from 'services';

// Styles
import styles from './EmbedImageAttachment.module.scss';

const EmbedImageAttachment = (props) => {
    const { matterId, attachmentId, optionalFileName, maximizeImageSize = false, onClick } = props;

    const [isLoading, setIsLoading] = useState(true);
    const [imageSrc, setImageSrc] = useState('');
    const [hasFailed, setHasFailed] = useState(false);
    const [imageNotFound, setImageNotFound] = useState(false);

    const notAvailableAltText = () => {
        return `The embedded attachment is missing${optionalFileName ? `: ${optionalFileName}` : ''}`;
    }

    const renderFailedToLoad = () => {
        return `The embedded attachment failed to load${optionalFileName ? `: ${optionalFileName}` : ''}`;
    }

    const renderImage = () => {
        let altText = optionalFileName ?? "attachment";

        if (imageNotFound) {
            altText = notAvailableAltText();
        } else if (hasFailed) {
            altText = renderFailedToLoad();
        }

        return (
            <img
                src={imageSrc}
                alt={altText}
                onClick={onClick}
                className={maximizeImageSize ? styles["embedded-image-large"] : styles["embedded-image"]}
            />
        )
    }

    const renderImageContainer = () => (
        <div className={styles["image-container"]}>
            {renderImage()}
        </div>
    );

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await downloadImageAttachment(matterId, attachmentId);
                const imageUrl = URL.createObjectURL(response.data);
                setImageSrc(imageUrl);
            } catch (error) {
                if (error.response?.status === 404 || (error.response?.status === 400 && error.response?.data?.message?.includes('file is empty'))) {
                    setImageNotFound(true);
                } else {
                    setHasFailed(true);
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchImage();

        return () => {
            URL.revokeObjectURL(imageSrc);
        };
    }, [attachmentId, matterId]);

    return isLoading ? <SpinnerLoading /> : renderImageContainer();
};

EmbedImageAttachment.propTypes = {
    matterId: PropTypes.string.isRequired,
    attachmentId: PropTypes.string.isRequired,
    optionalFileName: PropTypes.string,
    maximizeImageSize: PropTypes.bool,
    onClick: PropTypes.func
};

export default EmbedImageAttachment;
