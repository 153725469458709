import React, { useState } from "react";
import { Collapse } from "react-bootstrap";
import PropTypes from "prop-types";

// Styles
import styles from "./CollapseSelect.module.scss";

const CollapseSelect = ({ name, color = "#FFB0AC", data = [] }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={styles["collapse"]}>
      <div
        onClick={() => setOpen(!open)}
        aria-controls={`collapse-${name}`}
        aria-expanded={open}
        className={styles["collapse-wrap"]}
      >
        <div className={styles["collapse-info"]}>
          <div
            className={styles["collapse-color"]}
            style={{ background: color }}
          ></div>
          <div className={styles["collapse-title"]}>{name}</div>
        </div>
        <div className={styles["collapse-show"]}>
          <div>{`(${data.length})`}</div>
          {open ? (
            <img
              src="/images/icons/caret-up.svg"
              alt="icon"
              className={styles["arrow-up"]}
            />
          ) : (
            <img
              src="/images/icons/caret-up.svg"
              alt="icon"
              className={styles["arrow-down"]}
            />
          )}
        </div>
      </div>
      <Collapse in={open}>
        <div id={`collapse-${name}`} className={styles["collapse-data"]}>
          {data.map((el, index) => (
            <div className={styles["collapse-data-item"]} key={index}>
              {el}
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

export default CollapseSelect;

CollapseSelect.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  data: PropTypes.array,
};
