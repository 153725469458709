import React, { useEffect, useState, useRef, createRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

//Components
import { Button } from "../button/Button";
import Checkbox from "../checkbox/Checkbox";

// Constants
import {
  EVENT_KEY_LISTENER,
  KEY_CODE,
  QUICK_SELECT_ITEM,
} from "constants/Common";
import { COMMON_REGEX } from "constants/RegexConstant";

//styles
import styles from "./SearchSelector.module.scss";

const SearchSelector = (props) => {
  const {
    reverseDropdown = false,
    title,
    selectList = [],
    selectName,
    selectValue,
    onSubmit,
    data = [],
    suggestions = [],
    placeholder = "Search",
  } = props;

  const suggestionRef = useRef(null);
  const suggestionItemRefs = useRef([]);

  const [checkedList, setCheckedList] = useState(data || []);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [keySearch, setKeySearch] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState(
    selectList || []
  );
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);

  suggestionItemRefs.current = filteredSuggestions.map(
    (_, i) => suggestionItemRefs.current[i] ?? createRef()
  );

  const handleClickOutside = (event) => {
    if (
      suggestionRef.current &&
      !suggestionRef.current.contains(event.target)
    ) {
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }
  };

  const handleHideDropdown = (event) => {
    if (event.keyCode === KEY_CODE.escape) {
      setShowSuggestions(false);
      setActiveSuggestionIndex(-1);
    }
  };

  const onSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    if (selectValue === "dataSourceId") {
      e.target.checked ? setCheckedList([...selectList]) : setCheckedList([]);
    } else {
      e.target.checked
        ? setCheckedList(
            selectList.map((li) =>
              selectValue && title !== QUICK_SELECT_ITEM.entities.label
                ? li[selectValue]
                : li
            )
          )
        : setCheckedList([]);
    }
  };

  const onCheck = (item, e) => {
    const { checked } = e.target;
    checked
      ? setCheckedList([
          ...checkedList,
          selectValue === "value" || selectValue === "id"
            ? item[selectValue]
            : item,
        ])
      : setCheckedList(
          checkedList.filter(
            (i) =>
              JSON.stringify(i) !==
              JSON.stringify(
                selectValue === "value" || selectValue === "id"
                  ? item[selectValue]
                  : item
              )
          )
        );
  };

  const handleSearch = (e) => {
    const userInput = e.target.value.trim();
    // Filter our suggestions that don't contain the user's input
    const unLinked = userInput
      ? suggestions.filter(
          (suggestion) =>
            (selectName ? suggestion[selectName] : suggestion)
              .toLowerCase()
              .indexOf(userInput.toLowerCase()) > -1
        )
      : [];
    setKeySearch(e.target.value);
    setFilteredSuggestions(unLinked);
    setShowSuggestions(true);
  };

  const onSelectSuggestionHandle = (ref, selectedItem) => {
    const indexOfSelectList = selectList.indexOf(selectedItem);
    const selectDom = document.getElementById(
      `search-selector-${title}-${indexOfSelectList}`
    );
    selectDom.classList.add(styles["ss-item-selected"]);
    selectDom.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
    setFilteredSuggestions([]);
    setKeySearch("");
    setActiveSuggestionIndex(-1);
    setShowSuggestions(false);
    setTimeout(() => {
      selectDom.classList.remove(styles["ss-item-selected"]);
    }, 2000);
  };

  const submitShowSuggestions = () => {
    const unLinked = suggestions.filter(
      (suggestion) =>
        (selectName ? suggestion[selectName] : suggestion)
          .toLowerCase()
          .indexOf(keySearch.toLowerCase()) > -1
    );
    setFilteredSuggestions(unLinked);
    setShowSuggestions(true);
  };

  const onKeyDown = (e) => {
    switch (e.keyCode) {
      case KEY_CODE.enter:
        e.preventDefault();
        setShowSuggestions(false);
        if (activeSuggestionIndex > -1) {
          const itemSelectedIndex = selectList.findIndex(
            (item) =>
              JSON.stringify(item) ===
              JSON.stringify(filteredSuggestions[activeSuggestionIndex])
          );
          onSelectSuggestionHandle(
            suggestionItemRefs.current[itemSelectedIndex],
            selectList[itemSelectedIndex]
          );
          setActiveSuggestionIndex(-1);
        } else {
          keySearch.trim() && submitShowSuggestions();
        }
        break;
      case KEY_CODE.arrowUp:
        return setActiveSuggestionIndex(
          activeSuggestionIndex === 0
            ? filteredSuggestions.length - 1
            : activeSuggestionIndex - 1
        );
      case KEY_CODE.arrowDown:
        return setActiveSuggestionIndex(
          activeSuggestionIndex === filteredSuggestions.length - 1
            ? 0
            : activeSuggestionIndex + 1
        );

      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener(
      EVENT_KEY_LISTENER.click,
      handleClickOutside,
      false
    );
    document.addEventListener(
      EVENT_KEY_LISTENER.keydown,
      handleHideDropdown,
      true
    );
    return () => {
      document.removeEventListener(
        EVENT_KEY_LISTENER.click,
        handleClickOutside,
        false
      );
      document.removeEventListener(
        EVENT_KEY_LISTENER.keydown,
        handleHideDropdown,
        true
      );
    };
  }, []);

  useEffect(() => {
    setIsCheckAll(
      !!(checkedList?.length === selectList?.length && selectList?.length > 0)
    );
  }, [checkedList]);

  useEffect(() => {
    setCheckedList(data || []);
  }, [data]);

  /* Highlight keyword in suggestions */
  const SuggestionItem = ({ data }) => {
    if (!keySearch.trim()) {
      return <span>{selectName ? data[selectName] : data}</span>;
    }
    const escapeRegExp = (str = "") => str.replace(COMMON_REGEX.escape, "\\$1");
    const regex = new RegExp(`(${escapeRegExp(keySearch)})`, "gi");
    const parts = (selectName ? data[selectName] : data).split(regex);
    return (
      <span>
        {parts
          .filter((part) => part)
          .map((part, i) =>
            regex.test(part) ? (
              <span className={styles["highlight"]} key={i}>
                {part}
              </span>
            ) : (
              <span className={styles["no-highlight"]} key={i}>
                {part}
              </span>
            )
          )}
      </span>
    );
  };

  const SuggestionsList = () => {
    return (
      filteredSuggestions.length > 0 && (
        <div
          className={clsx(
            styles["suggestions-wrapper"],
            reverseDropdown &&
              filteredSuggestions.length > 4 &&
              styles["suggestions-wrapper-reverse"]
          )}
        >
          <ul
            className={styles["suggestions"]}
            id="suggestion"
            ref={suggestionRef}
          >
            {filteredSuggestions.map((suggestion, index) => {
              let className;
              // Flag the active suggestion with a class
              if (index === activeSuggestionIndex) {
                className = "suggestion-active";
              }
              return (
                <li
                  className={styles[className]}
                  key={index}
                  ref={suggestionItemRefs.current[index]}
                  onClick={() =>
                    onSelectSuggestionHandle(
                      suggestionItemRefs.current[index],
                      suggestion
                    )
                  }
                >
                  <SuggestionItem data={suggestion} />
                </li>
              );
            })}
          </ul>
        </div>
      )
    );
  };

  return (
    <div className={styles["search-selector"]}>
      <div className={styles["ss-header"]}>
        <h3>{title}</h3>
        <Button
          name="Update"
          className="btn-secondary"
          isDisabled={JSON.stringify(data) === JSON.stringify(checkedList)}
          handleClick={() => onSubmit(checkedList)}
        />
      </div>
      <div className={styles["ss-body"]}>
        <div className={styles["ss-actions"]}>
          <Checkbox
            name="Select all"
            label="Select all"
            id={`select-all-${title}`}
            handleClick={onSelectAll}
            isChecked={isCheckAll}
            disabled={selectList.length === 0}
            labelSmall
          />
          <div className={styles["ss-search"]}>
            <img src="/images/search-icon.svg" alt="search" />
            <input
              placeholder={placeholder}
              value={keySearch}
              onChange={handleSearch}
              onKeyDown={onKeyDown}
              disabled={selectList.length === 0}
            />
            {showSuggestions && <SuggestionsList />}
          </div>
        </div>
        <ul className={styles["ss-check-list"]}>
          {selectList?.length > 0 ? (
            selectList.map((item, index) => (
              <li
                className={clsx("form-check", styles["ss-check-item"])}
                key={index}
                id={`search-selector-${title}-${index}`}
                title={selectName ? item[selectName] : item}
              >
                <Checkbox
                  name={selectName ? item[selectName] : item}
                  label={selectName ? item[selectName] : item}
                  id={selectValue ? item[selectValue] : item}
                  handleClick={(e) => onCheck(item, e)}
                  isChecked={JSON.stringify(checkedList).includes(
                    JSON.stringify(selectValue ? item[selectValue] : item)
                  )}
                  labelSmall
                />
              </li>
            ))
          ) : (
            <li className={styles["ss-no-result"]}>No result</li>
          )}
        </ul>
      </div>
    </div>
  );
};

SearchSelector.propTypes = {
  reverseDropdown: PropTypes.bool,
  title: PropTypes.string,
  selectName: PropTypes.string,
  selectValue: PropTypes.string,
  placeholder: PropTypes.string,
  data: PropTypes.array,
  suggestions: PropTypes.array,
  selectList: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default SearchSelector;
