import React from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

// Components
import ErrorInline from "components/shared/error-inline/ErrorInline";

// Styles
import styles from "./FieldInput.module.scss";

const FieldInput = (props) => {
  const {
    name,
    label,
    placeHolder,
    required = false,
    disabled = false,
  } = props;
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className={styles["field-container"]}>
          <label>
            {label}
            {required && <span className="asterisk"> *</span>}
          </label>
          <input
            type="text"
            {...field}
            placeholder={placeHolder}
            className={meta.touched && meta.error ? "input-error" : ""}
            disabled={disabled}
          />
          {meta.touched && meta.error && (
            <div className={styles["form-error"]}>
              <ErrorInline errorMsg={meta.error} touched={meta.touched} />
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

FieldInput.propTypes = {
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FieldInput;
