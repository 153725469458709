import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

// Styles
import "./styles.scss";

const ComponentLoading = (props) => {
  const { loading } = props;
  return (
    <>
      {loading && (
        <div className="component-loading">
          <Spinner
            className="spinner-border-edit"
            variant="primary"
            animation="border"
          />
          <span className="loading-background"></span>
        </div>
      )}
    </>
  );
};

ComponentLoading.propTypes = {
  loading: PropTypes.bool,
};

export default ComponentLoading;
