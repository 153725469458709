import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSelectListProject } from "services/ProjectDetailService";

export const fetchProjectList = createAsyncThunk(
  "project/fetchProjectList",
  async () => {
    const projectData = await getSelectListProject();
    return projectData;
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projects: [],
    loading: false,
    projectDetail: {},
  },
  reducers: {
    setProjectDetail: (state, action) => {
      state.projectDetail = action.payload;
    },
  },
  extraReducers: {
    [fetchProjectList.pending]: (state) => {
      state.loading = true;
    },
    [fetchProjectList.rejected]: (state) => {
      state.loading = false;
    },
    [fetchProjectList.fulfilled]: (state, action) => {
      state.loading = false;
      state.projects = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setProjectDetail } = projectSlice.actions;
export const ProjectReducer = projectSlice.reducer;
