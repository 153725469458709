import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _get from "lodash/get";

// Components
import EventTimelineDetail from "components/event-timeline/event-timeline-detail/EventTimelineDetail";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import PrintExport from "components/shared/print-export/PrintExport";

// Services
import {
  exportMessageResultToExcel,
  exportEmailResultToExcel,
} from "services/EventTimelineService";

// Store
import {
  setItemDetailData,
  setParamSearchTimeLine,
} from "store/EventTimelineReducer";
import { resetDateTimeMess } from "store/DatePickerReducer";
import { setIsGoToOrigin, setIsMatterDisable } from "store/CommonReducer";

// Constants
import { CHAT_EMAIL_TYPE, DATA_TYPE } from "constants/DataType";
import { PATH_NAME, PRINT_SCREEN_NAME } from "constants/Common";
import { breadCrumbItemDetail } from "constants/BreadCrumbConstants";
import { LOCAL_STORAGE } from "constants/LocalStorage";

// Helpers
import { getFileName } from "helpers/GetFileNameHelper";
import { getCurrentTime } from "helpers/DateTimeFormatterHelper";

// Styles
import styles from "./ItemDetailPage.module.scss";

const ItemDetailPage = () => {
  const { projectId } = useParams();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [headerTitle, setHeaderTitle] = useState(
    _get(state, "headerTitle", "")
  );
  const [eventTimelineType, setEventTimelineType] = useState(
    _get(state, "eventTimeLineType", "")
  );
  const [eventTimelineID, setEventTimelineID] = useState(
    _get(state, "id", null)
  );
  const pageName = _get(state, "pageName", null);
  const titleActivity = _get(state, "titleActivity", null);

  const { messageViewDatePicker: dateFilterMsg } = useSelector(
    (state) => state.datePicker
  );
  const { itemDetailData, paramSearch } = useSelector(
    (state) => state.eventTimeline
  );

  const handleGoBack = () => {
    if (state?.pathname) navigate(state.pathname);
    else navigate(-1);
  };

  const getBreadCrumb = () => {
    if (!state) return;
    const { pathname, searchResultId, headerTitle, timelineDetailTitle } = state;
    const previousPath = localStorage.getItem(LOCAL_STORAGE.previousPath);
    const intelligentSearchPath = localStorage.getItem(LOCAL_STORAGE.intelligentSearchPath);
    
    return breadCrumbItemDetail({
      pathname: getFileName(pathname),
      fromScreen: previousPath,
      searchResultId,
      itemDetailTitle: headerTitle,
      timelineDetailTitle,
      intelligentSearchPath,
    });
  }

  const redirectToReview = () =>
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.streems}`);

  const downloadFile = (blobData, fileName) => {
    const url = window.URL.createObjectURL(blobData);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const exportMessageToExcel = async () => {
    if (eventTimelineType !== DATA_TYPE.chat) return;
    setIsLoading(true);
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
      dateFilterMsg;
    const params = {
      Start: dateTimeDataStart,
      End: dateTimeDataEnd,
      PageNumber: 1,
      includeBlankDate: includeBlankDate,
      isNewest: paramSearch?.isNewest,
      chatID: itemDetailData?.chatID,
      pageName: state?.pageName,
    };
    try {
      const result = await exportMessageResultToExcel(
        projectId,
        eventTimelineID,
        params
      );
      downloadFile(result.data, `StreemView_Message_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const exportEmailDetailToExcel = async () => {
    if (eventTimelineType !== DATA_TYPE.email) return;
    setIsLoading(true);
    const { dateTimeDataStart, dateTimeDataEnd, includeBlankDate } =
      dateFilterMsg;
    const params = {
      Start: dateTimeDataStart,
      End: dateTimeDataEnd,
      includeBlankDate: includeBlankDate,
      PageNumber: 1,
      isNewest: paramSearch?.isNewest,
      pageName: state?.pageName,
    };
    try {
      const result = await exportEmailResultToExcel(
        projectId,
        eventTimelineID,
        params
      );
      downloadFile(result.data, `StreemView_Email_${getCurrentTime()}.xlsx`);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExport = () => {
    if (
      eventTimelineType === DATA_TYPE.chat ||
      eventTimelineType === DATA_TYPE.chatGroup
    )
      exportMessageToExcel();
    else exportEmailDetailToExcel();
  };

  useEffect(() => {
    // keep this code for implement later when complete event-timeline page
    if (!state || !state.id) redirectToReview();
    else {
      setEventTimelineType(state.eventTimeLineType);
      setHeaderTitle(state.headerTitle);
      setEventTimelineID(state.id);
    }
    dispatch(setIsMatterDisable(true));
    return () => {
      dispatch(setParamSearchTimeLine({ searchMsgInput: null }));
      dispatch(resetDateTimeMess());
      dispatch(setIsMatterDisable(false));
      dispatch(setIsGoToOrigin(false));
    };
  }, []);

  return (
    <Container fluid>
      <div className="main" id="main">
        <div className={styles["back-btn"]}>
          <BreadCrumb
            goBack={handleGoBack}
            breadCrumbData={getBreadCrumb()}
          />
        </div>
        <div className={styles["et-detail-header"]}>
          <h2>{headerTitle}</h2>
          <PrintExport
            screenName={PRINT_SCREEN_NAME.eventTimelineDetail}
            isDisplayTime
            handleExport={handleExport}
            isExportExcel={CHAT_EMAIL_TYPE.includes(eventTimelineType)}
          />
        </div>
        <EventTimelineDetail
          eventTimelineType={eventTimelineType}
          eventTimelineID={eventTimelineID}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          pageName={pageName}
          titleActivity={titleActivity}
          dataDetail={itemDetailData}
          updateStoreDetail={(data) => dispatch(setItemDetailData(data))}
          searchMsgInput={paramSearch?.searchMsgInput}
        />
      </div>
    </Container>
  );
};

export default ItemDetailPage;
