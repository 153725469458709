import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";

// Services
import {
  getStatisticApi,
  getSuggestionIdentifierApi,
} from "services/EntityManagementService";
import { handleAbortRequest } from "services/ApiConfig";

// Components
import { Button } from "components/shared/button/Button";
import IdentifierList from "./identifier-list/IdentifierList";
import FormGroupValidation from "../form-group-validation/FormGroupValidation";
import EntityStatistics from "../entity-statistics/EntityStatistics";
import CreateEntityAvatar from "../avatar/CreateEntityAvatar";
import AssociatedIdentifierForm from "../associated-identifiers/identifier-form/AssociatedIdentifierForm";
import ErrorInline from "../error-inline/ErrorInline";

// Constants
import { FILE_SIZE, IMAGE_TYPE_ACCEPTED_STRING } from "constants/Constants";
import { ENTITY_VALIDATION } from "constants/EntityConstant";

// Helpers
import { getFileTypeFromName } from "helpers/GetFileNameHelper";

// Store
import { removeEntityAvatarUrl } from "store/AvatarReducer";

// Styles
import styles from "./CreateEntityModal.module.scss";

const CreateEntityModal = (props) => {
  const {
    isShow = false,
    entityID = null,
    onHide = () => {},
    onSubmitForm = () => {},
    handleDeleteEntity = () => {},
    errorIdentifierList = [],
    setErrorIdentifierList = () => {},
    entityDataDefault,
    likelyName = "",
  } = props;

  const { projectId } = useParams();
  const dispatch = useDispatch();

  const [entityData, setEntityData] = useState(entityDataDefault);
  const [identifiersSuggestion, setIdentifiersSuggestion] = useState([]);
  const [isLoadingSuggest, setIsLoadingSuggest] = useState(false);
  const [isSelectFile, setIsSelectFile] = useState(false);
  const [statisticLoading, setStatisticLoading] = useState(false);
  const [statisticData, setStatisticData] = useState({});
  const [disableBtnSave, setDisableBtnSave] = useState(false);

  const handleValidateAvatar = (value) =>
    Yup.mixed()
      .test({
        message: value.file_type,
        test: (image) =>
          image
            ? IMAGE_TYPE_ACCEPTED_STRING.includes(
                getFileTypeFromName(image.name)
              )
            : true,
      })
      .test({
        message: value.file_size,
        test: (image) => (image ? image.size <= FILE_SIZE.MB20 : true),
      })
      .test({
        message: value.required,
        test: (image) => (image ? image.size !== 0 : true),
      });

  const formik = useFormik({
    initialValues: {
      avatar: "",
      name: "",
      jobTitle: "",
      company: "",
      notes: "",
      identifier: [],
    },
    validationSchema: Yup.object({
      avatar: isSelectFile ? handleValidateAvatar(ENTITY_VALIDATION.avatar) : null,
      name: Yup.string()
        .trim()
        .required(ENTITY_VALIDATION.name.required)
        .max(100, ENTITY_VALIDATION.name.max_length),
      jobTitle: Yup.string().max(100, ENTITY_VALIDATION.jobTitle.max_length),
      company: Yup.string().max(100, ENTITY_VALIDATION.company.max_length),
      notes: Yup.string().max(500, ENTITY_VALIDATION.notes.max_length),
    }),
    onSubmit: (values) => handleSubmitForm(values),
  });

  const formData = [
    {
      label: "Person Name",
      id: "name",
      isRequired: true,
      value: formik.values.name,
      error: formik.errors.name,
      touched: formik.touched.name,
    },
    {
      label: "Job title",
      id: "jobTitle",
      value: formik.values.jobTitle,
      error: formik.errors.jobTitle,
      touched: formik.touched.jobTitle,
    },
    {
      label: "Company",
      id: "company",
      value: formik.values.company,
      error: formik.errors.company,
      touched: formik.touched.company,
    },
    {
      label: "Note",
      isTextArea: true,
      id: "notes",
      value: formik.values.notes,
      error: formik.errors.notes,
      touched: formik.touched.notes,
    },
  ];

  const handleSubmitForm = async (data) => {
    const params = {
      ...data,
      entityID,
      identifier: data.identifier?.map((item) => ({
        value: item.value,
        typeID: item.socialType,
      })),
    };
    onSubmitForm(params);
  };

  const handleSelectAvatar = (event) => {
    const { files } = event.currentTarget;
    if (files.length > 0) {
      setIsSelectFile(true);
      formik.setFieldValue("avatar", files[0]);
      dispatch(removeEntityAvatarUrl(entityID));
    }
  };

  const onHideModal = () => {
    onHide();
    formik.resetForm();
  };

  const fetchSuggestionIdentifier = async (likelyName = "") => {
    try {
      setIsLoadingSuggest(true);
      const result = await getSuggestionIdentifierApi(
        projectId,
        likelyName.trim() || formik.values.name.trim()
      );
      const associatedContactList =
        entityData?.identifier.map((item) => item.value) || [];
      //Filter identifier suggestion not same value with associated identifier
      setIdentifiersSuggestion(
        result.data
          .filter(
            (suggestion) =>
              !associatedContactList.includes(suggestion.identifier)
          )
          .map((item) => ({ ...item, identifierId: item.identifier }))
      );
    } catch (error) {
      console.log(error);
      setIdentifiersSuggestion([]);
    } finally {
      setIsLoadingSuggest(false);
    }
  };

  // Update identifiers for entity and formik form
  const updateIdentifiersForEntity = (identifiers) => {
    formik.setFieldValue("identifier", identifiers);
    setEntityData({ ...entityData, identifier: identifiers });
  };

  const handleAddIdentifier = (data) => {
    const associatedContactList = entityData?.identifier.map(
      (item) => item.value
    );
    // Check the added data is the same as the existing data
    if (associatedContactList?.includes(data.value)) {
      setErrorIdentifierList([data.value]);
      return;
    } else {
      setErrorIdentifierList([]);
      const identifiers = [
        ...(entityData?.identifier || []),
        { ...data, id: uuidv4() },
      ];
      setIdentifiersSuggestion(
        identifiersSuggestion.filter((item) => item.identifier !== data.value)
      );
      updateIdentifiersForEntity(identifiers);
    }
  };

  // Delete identifier list
  const handleDeleteIdentifier = (data) => {
    setErrorIdentifierList([]);
    setIdentifiersSuggestion(
      identifiersSuggestion.filter((item) => item.identifier !== data)
    );
  };

  const fetchStatistic = async (identifiers = []) => {
    handleAbortRequest();
    try {
      setStatisticLoading(true);
      const result = await getStatisticApi(projectId, identifiers);
      setStatisticData(result.data);
    } catch (error) {
      console.log(error);
    } finally {
      setStatisticLoading(false);
    }
  };

  useEffect(() => {
    // Set data for formik form when exist entityID
    if (entityID) 
      formik.setValues({
        avatar: "",
        name: entityData.name,
        jobTitle: entityData.jobTitle || "",
        company: entityData.company || "",
        notes: entityData.notes || "",
        identifier: entityData?.identifier || [],
      });
    else {
      // Set data for formik form when exist likelyName and auto suggest list
      formik.setValues({ name: likelyName });
      likelyName && fetchSuggestionIdentifier(likelyName);
    }
  }, []);

  useEffect(() => {
    if (entityData?.identifier) return;
    setIdentifiersSuggestion(
      identifiersSuggestion.filter(
        (suggestion) =>
          !entityData?.identifier.some(
            (item) => item.value === suggestion.identifier
          )
      )
    );
  }, [isLoadingSuggest]);

  useEffect(() => {
    fetchStatistic(
      entityData?.identifier
        ? entityData.identifier.map((item) => item.value)
        : []
    );
  }, [JSON.stringify(entityData?.identifier)]);

  useEffect(() => {
    isSelectFile && formik.setFieldTouched("avatar", true);
  }, [formik.values.avatar]);

  return (
    <Modal
      show={isShow}
      onHide={onHide}
      centered
      backdrop="static"
      className={styles["wrap"]}
    >
      <ModalHeader className={styles["modal-header"]}>
        <h3 className={styles["title"]}>
          {entityID ? "Edit Person" : "Create Person"}
        </h3>
        <div onClick={onHide} className={styles["close-icon"]}>
          <img src="/images/close-modal-icon.png" alt="Close" />
        </div>
      </ModalHeader>
      <ModalBody className={styles["modal-body"]}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="d-flex">
            <div className={styles["entity-profile"]}>
              <CreateEntityAvatar
                matterId={projectId}
                entityId={entityID}
                handleSelectAvatar={handleSelectAvatar}
              />
              <p className={styles["form-error"]}>
                <ErrorInline
                  errorMsg={formik.errors.avatar}
                  touched={formik.touched.avatar}
                />
              </p>
            </div>
            <div className={styles["entity-statistic"]}>
              <EntityStatistics
                statisticData={statisticData}
                loading={statisticLoading}
              />
            </div>
          </div>
          <div className={styles["group-info"]}>
            <div className={styles["group-info-left"]}>
              {formData.map((item) => (
                <FormGroupValidation
                  key={item.id}
                  label={item.label}
                  id={item.id}
                  isRequired={item.isRequired}
                  isTextArea={item.isTextArea}
                  value={item.value}
                  errors={item.error}
                  touched={item.touched}
                  onChange={formik.handleChange}
                />
              ))}
            </div>
            <div className={styles["group-info-right"]}>
              <AssociatedIdentifierForm
                data={entityData?.identifier}
                onChangeData={updateIdentifiersForEntity}
                errorIdentifierList={errorIdentifierList}
                updateErrorIdentifierList={setErrorIdentifierList}
                getStatusUpdate={setDisableBtnSave}
              />
              {errorIdentifierList.length > 0 && (
                <div className={styles["form-error"]}>
                  <ErrorInline
                    errorMsg={
                      errorIdentifierList.length > 1
                        ? ENTITY_VALIDATION.existsDB.manyContact
                        : ENTITY_VALIDATION.existsDB.oneContact
                    }
                    touched
                  />
                </div>
              )}
            </div>
          </div>
          <IdentifierList
            data={identifiersSuggestion}
            isLoading={isLoadingSuggest}
            handleSeeSuggestion={fetchSuggestionIdentifier}
            handleAddIdentifier={handleAddIdentifier}
            handleDeleteIdentifier={handleDeleteIdentifier}
          />
        </Form>
      </ModalBody>
      <ModalFooter className={styles["modal-footer"]}>
        <div className={styles["btn-delete"]}>
          {entityID && (
            <Button
              name="Delete Person"
              isBorder={false}
              className="btn-warning-lg"
              handleClick={handleDeleteEntity}
            />
          )}
        </div>
        <div className={styles["btn-control"]}>
          <Button name="Cancel" handleClick={onHideModal} />
          <Button
            name={entityID ? "Save Changes" : "Create New"}
            className="btn-primary-fill"
            handleClick={formik.handleSubmit}
            isDisabled={disableBtnSave || errorIdentifierList.length > 0}
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};
CreateEntityModal.propTypes = {
  isShow: PropTypes.bool,
  entityID: PropTypes.number,
  likelyName: PropTypes.string,
  errorIdentifierList: PropTypes.arrayOf(PropTypes.string),
  setErrorIdentifierList: PropTypes.func,
  onHide: PropTypes.func,
  onSubmitForm: PropTypes.func,
  handleDeleteEntity: PropTypes.func,
  entityDataDefault: PropTypes.any,
};

export default CreateEntityModal;
