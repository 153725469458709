import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/shared/button/Button";
import "./ConfirmDeleteMemberPopup.scss";

const ConfirmDeleteMemberPopup = ({ show, onClickYes, onClickNo }) => {
  if (show) {
    return (
      <div className="confirm-delete-member-bg">
        <div className="confirm-delete-member-popup">
          <div className="w-100 d-flex align-items-center justify-content-center flex-wrap">
            <p>Are you sure to remove the user?</p>
            <div className="buttons">
              <Button
                className="btn-secondary-md"
                handleClick={(e) => onClickNo(e)}
                name="Cancel"
              />
              <Button
                className="btn-warning-md"
                isBorder={false}
                handleClick={(e) => onClickYes(e)}
                name="Remove"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

ConfirmDeleteMemberPopup.displayName = "Confirm delete member popup";

ConfirmDeleteMemberPopup.propTypes = {
  show: PropTypes.bool,
  onClickYes: PropTypes.func,
  onClickNo: PropTypes.func,
};

export default ConfirmDeleteMemberPopup;
