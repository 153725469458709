import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

// Helpers
import { getSourceIconByName } from "helpers/CommonHelper";

// Styles
import styles from "./ViewAppDetail.module.scss";

const ViewAppDetail = ({ appList = [] }) => {
  const moreIconRef = useRef(null);
  const listRef = useRef(null);
  const [showDetail, setShowDetail] = useState(false);

  useOnClickOutside(moreIconRef, () => setShowDetail(false), listRef.current);

  return (
    <div className={styles["view-detail"]}>
      <img
        className={styles["more-icon"]}
        src="/images/see-more.svg"
        alt="More"
        onClick={() => setShowDetail(!showDetail)}
        ref={moreIconRef}
      />
      <ul className={styles["app-list"]} hidden={!showDetail} ref={listRef}>
        {appList.map((item) => (
          <li className={styles["app-list-item"]} key={item}>
            <img
              src={getSourceIconByName(item, "other")}
              alt="source-icon"
              className={styles["source-icon"]}
            />
            <span className={styles["app-name"]} title={item}>
              {item}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

ViewAppDetail.propTypes = {
  appList: PropTypes.arrayOf(PropTypes.string),
};

export default ViewAppDetail;
