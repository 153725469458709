import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

// Constants
import { COLORS } from "constants/Common";

// Styles
import "react-circular-progressbar/dist/styles.css";

const CircularProgressBar = (props) => {
  const {
    value = 0,
    text = 0,
    strokeWidth = 6,
    width = 0,
    height = 0,
    styles = {
      textSize: "2.4rem",
      textColor: `${COLORS.main}`,
      pathColor: `${COLORS.main}`,
    },
  } = props;

  return (
    <div style={{ width: width, height: height }}>
      <CircularProgressbar
        value={value}
        text={`${text}%`}
        strokeWidth={strokeWidth}
        styles={buildStyles(styles)}
      />
    </div>
  );
};

CircularProgressBar.propTypes = {
  value: PropTypes.number,
  text: PropTypes.number,
  strokeWidth: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  styles: PropTypes.shape({
    textSize: PropTypes.string,
    textColor: PropTypes.string,
    pathColor: PropTypes.string,
  }),
};

export default CircularProgressBar;
