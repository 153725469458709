import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import _get from "lodash/get";

// Components
import { Button as ButtonUpdate } from "components/shared/button/Button";
import { Loading } from "components/shared/loading/Loading";
import DefaultAvatar from "assets/images/default_avatar.png";
import MatterAvatar from "../file-components/matter-avatar/MatterAvatar";

// Constants
import { ENTITY_VALIDATION } from "constants/EntityConstant";
import { FILE_SIZE, IMAGE_TYPE_ACCEPTED_STRING } from "constants/Constants";
import { FILE_TYPE } from "constants/DataType";

// Helpers
import { getFileTypeFromName } from "helpers/GetFileNameHelper";

// Services
import { uploadAvatarApi } from "services/EntityManagementService";

// Store
import { removeEntityAvatarUrl } from "store/AvatarReducer";

// Styles
import "./styles.scss";

export const UploadAvatar = (props) => {
  const {
    isShow = false,
    handleClose,
    handleUploadImage,
    entityId,
    avatarEntity,
  } = props;

  const { projectId } = useParams();

  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [messageErr, setMessageErr] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle update avatar
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (loading) return;
    if (messageErr === "" && selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append(FILE_TYPE.image, selectedFile);
      try {
        await uploadAvatarApi(projectId, entityId, formData);
        const url = URL.createObjectURL(selectedFile);
        dispatch(removeEntityAvatarUrl(entityId));
        handleUploadImage({ url, entityID: entityId });
        toast.success("Update avatar successfully.");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseUpload = () => {
    setProfileImg(avatarEntity);
    handleClose();
    setMessageErr("");
  };

  const chooseImage = () => {
    document.getElementById("uploadImage").click();
  };

  const imageHandler = (e) => {
    const imgUpload = e.target.files[0];
    let messageError = "";

    if (_get(imgUpload, "size") === 0) {
      messageError = ENTITY_VALIDATION.avatar.required;
    } else if (
      !IMAGE_TYPE_ACCEPTED_STRING.includes(
        getFileTypeFromName(_get(imgUpload, "name", ""))
      )
    ) {
      messageError = ENTITY_VALIDATION.avatar.file_type;
    } else if (_get(imgUpload, "size") > FILE_SIZE.MB20) {
      messageError = ENTITY_VALIDATION.avatar.file_size;
    }

    setMessageErr(messageError);
    if (messageError) return;

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };

    reader.readAsDataURL(imgUpload);
    setSelectedFile(imgUpload);
  };

  useEffect(() => {
    if (profileImg === "/images/avatar-update.svg") {
      setMessageErr(ENTITY_VALIDATION.avatar.required);
    }
    setProfileImg(DefaultAvatar);
  }, [avatarEntity]);

  return (
    <>
      <Modal
        className="upload-avatar-container"
        show={isShow}
        onHide={handleCloseUpload}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Upload Avatar</Modal.Title>
          </Modal.Header>
          <input
            type="file"
            accept={IMAGE_TYPE_ACCEPTED_STRING}
            hidden
            id="uploadImage"
            onChange={imageHandler}
          />
          <div className="upload-body">
            <div className="upload-body__image">
              <div className="img-container" onClick={chooseImage}>
                <figure>
                  {profileImg ? (
                    <img src={profileImg} alt="Avatar" />
                  ) : (
                    <MatterAvatar matterId={projectId} id={entityId} avatarType="entity" />
                  )}
                </figure>
              </div>
            </div>
            <div className="upload-body__control">
              <ButtonUpdate
                handleClick={chooseImage}
                type="button"
                name="Upload Photo"
                iconUrl="/images/ic-upload.svg"
              />
              <p>Maximum image size is 20 MB (jpeg, png, jpg)</p>
            </div>
          </div>
          <Modal.Footer>
            <div className="msg-err-container">
              <img
                hidden={!messageErr}
                src="/images/err-icon.svg"
                alt="Error icon"
              />
              <span>{messageErr}</span>
            </div>
            <div className="btn-control">
              <ButtonUpdate
                className="btn-secondary-md"
                name="Cancel"
                handleClick={handleCloseUpload}
              />
              <ButtonUpdate
                isDisabled={!!messageErr}
                type="submit"
                name="Confirm"
                className="btn-secondary-md"
                isBorder={false}
                handleClick={() =>
                  profileImg !== "/images/avatar-update.svg" &&
                  messageErr === "" &&
                  handleCloseUpload()
                }
              />
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <Loading loading={loading} isBlur />
    </>
  );
};

UploadAvatar.propTypes = {
  isShow: PropTypes.bool,
  avatarEntity: PropTypes.string,
  entityId: PropTypes.number,
  handleClose: PropTypes.func,
  handleUploadImage: PropTypes.func,
  imageHandler: PropTypes.func,
};
