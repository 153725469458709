import store from "../store/index";

export const clampedAll = async ({
  array = [],
  clamp = 1,
  setListByte = () => {},
  listBytes,
  onProgress = () => {},
  importId = "",
  }) => {
  const { length } = array;
  const maxExecutions = Math.min(length, clamp);
  const executions = [...array];
  const results = [];
  if (!maxExecutions) return Promise.resolve(results);

  await Promise.all(
    Array.from({ length: maxExecutions }).map(async () => {
      while (executions.length) {
        const index = length - executions.length;
        const execution = executions.shift();
        if (execution) {
          const state = store.getState();
          if (state.importBlob.isCancelImport) {
            throw new Error('Upload canceled');
          }
          results[index] = await execution();
          onProgress();
          setListByte([...listBytes,...results].filter((item)=> item?.name), importId);
        }
      }
    })
  );
  return results;
};
