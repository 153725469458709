import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

// Styles
import styles from "./SpinnerLoading.module.scss";

const SpinnerLoading = ({ isCenter = true }) => {
  return (
    <div className={styles[isCenter ? "loading-container" : ""]}>
      <Spinner variant="primary" animation="border" />
    </div>
  );
};

SpinnerLoading.propTypes = {
  isCenter: PropTypes.bool,
};

export default SpinnerLoading;
