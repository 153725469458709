import React, { useEffect, useState, useRef, createRef } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

//Constant
import { COMMON_REGEX } from "constants/RegexConstant";
import { EVENT_KEY_LISTENER, KEY_CODE } from "constants/Common";

//Styles
import "./UserFilter.scss";

const UserFilter = (props) => {
  const {
    placeholder,
    suggestions = [],
    name,
    isAutoComplete,
    inputRef,
    onSubmitSearch,
  } = props;

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [input, setInput] = useState("");
  const suggestionRef = useRef(null);
  const suggestionItemRefs = useRef([]);
  const [suggestionItem, setSuggestionItem] = useState({});

  suggestionItemRefs.current = filteredSuggestions.map(
    (_, i) => suggestionItemRefs.current[i] ?? createRef()
  );

  const handleHideDropdown = (event) => {
    if (event.keyCode === KEY_CODE.escape) setShowSuggestions(false);
  };

  const onChange = (event) => {
    const userInput = event.target.value.trim().toLowerCase();

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.firstName.toLowerCase().indexOf(userInput) > -1 ||
        suggestion.lastName.toLowerCase().indexOf(userInput) > -1 ||
        suggestion.email.toLowerCase().indexOf(userInput) > -1
    );

    setInput(event.target.value);
    setFilteredSuggestions(unLinked);
    setShowSuggestions(true);
  };

  const onKeyDown = (event) => {
    switch (event.keyCode) {
      case KEY_CODE.enter:
        event.preventDefault();
        setShowSuggestions(false);
        if (activeSuggestionIndex > -1) {
          setActiveSuggestionIndex(-1);
          setInput(
            `${filteredSuggestions[activeSuggestionIndex].firstName} ${filteredSuggestions[activeSuggestionIndex].lastName}`
          );
          setSuggestionItem(filteredSuggestions[activeSuggestionIndex]);
        } else {
          onSubmitHandle();
        }
        break;
      case KEY_CODE.arrowUp:
        return activeSuggestionIndex === 0
          ? setActiveSuggestionIndex(filteredSuggestions.length - 1)
          : setActiveSuggestionIndex(activeSuggestionIndex - 1);
      case KEY_CODE.arrowDown:
        return activeSuggestionIndex === filteredSuggestions.length - 1
          ? setActiveSuggestionIndex(0)
          : setActiveSuggestionIndex(activeSuggestionIndex + 1);

      default:
        break;
    }
  };

  const onSelectSuggestionHandle = (suggestion) => {
    setFilteredSuggestions([]);
    setInput("");
    setActiveSuggestionIndex(-1);
    setShowSuggestions(false);
    onSubmitSearch(suggestion);
  };

  const onSubmitHandle = () => {
    if (!input.trim()) return;
    setFilteredSuggestions([]);
    setInput("");
    setActiveSuggestionIndex(-1);
    setShowSuggestions(false);
    onSubmitSearch(suggestionItem);
    setSuggestionItem({});
  };

  /* Highlight keyword in suggestions */
  const SuggestionItem = ({ data }) => {
    if (!input.trim()) {
      return (
        <div>
          <div className="name">{data.firstName + " " + data.lastName}</div>
          <div className="email">{data.email}</div>
        </div>
      );
    }

    const userName = data.firstName + " " + data.lastName;

    const escapeRegExp = (str = "") => str.replace(COMMON_REGEX.escape, "\\$1");
    const regex = new RegExp(`(${escapeRegExp(input.trim())})`, "gi");
    const parts = userName.split(regex);

    return (
      <div>
        <div className="name">
          {parts.map((part, i) => (
            <span
              className={regex.test(part) ? "highlight" : "no-highlight"}
              key={i}
            >
              {part}
            </span>
          ))}
        </div>
        <div className="email">{data.email}</div>
      </div>
    );
  };

  const SuggestionsList = () =>
    filteredSuggestions.length ? (
      <div className="suggestions-wrapper">
        <ul
          className="suggestions suggestion-users"
          id="suggestion"
          ref={suggestionRef}
        >
          {filteredSuggestions.map((suggestion, index) => (
            <li
              className={
                index === activeSuggestionIndex ? "suggestion-active" : ""
              }
              key={index}
              ref={suggestionItemRefs.current[index]}
              onClick={() => onSelectSuggestionHandle(suggestion)}
            >
              <SuggestionItem data={suggestion} />
            </li>
          ))}
        </ul>
      </div>
    ) : (
      ""
    );

  useOnClickOutside(
    suggestionRef,
    () => showSuggestions && setShowSuggestions(false)
  );

  useEffect(() => {
    document.addEventListener(
      EVENT_KEY_LISTENER.keydown,
      handleHideDropdown,
      true
    );
    return () => {
      document.removeEventListener(
        EVENT_KEY_LISTENER.keydown,
        handleHideDropdown,
        true
      );
    };
  }, []);

  return (
    <div className="search-wrapper">
      <div className="input-autocomplete">
        <input
          id="search-input"
          className="btn-input"
          type="text"
          autoComplete="off"
          ref={inputRef}
          name={name}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={input}
          placeholder={placeholder}
        />
        {isAutoComplete && showSuggestions && input && <SuggestionsList />}
      </div>
      <div onClick={onSubmitHandle}>
        <img
          id="search-project-submit"
          className="icon-search"
          src="/images/search-icon.svg"
          alt="Search Icon"
        />
      </div>
    </div>
  );
};

UserFilter.propTypes = {
  inputRef: PropTypes.any,
  isAutoComplete: PropTypes.bool,
  name: PropTypes.string,
  data: PropTypes.string,
  placeholder: PropTypes.string,
  suggestions: PropTypes.array,
  onSubmitSearch: PropTypes.func,
};

export default UserFilter;
