import axios, { authHeader } from "./ApiConfig";

const applyAttachmentTag = async (matterId, attachmentId, data) => {
    return await axios.post(`tag/${matterId}/attachmentTag/${attachmentId}`, 
    data, {
        headers: authHeader(),
    });
};

const applyAttachmentUntag = async (matterId, attachmentId, data) => {
    return await axios.post(`tag/${matterId}/attachmentUntag/${attachmentId}`, 
    data, {
        headers: authHeader(),
    });
};

export {
    applyAttachmentTag,
    applyAttachmentUntag
};