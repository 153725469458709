import axios, { authHeader, getAbortController } from "./ApiConfig";
import { checkBodyConvertTimeToUTC } from "helpers/ObjectHelper";
import { METHOD_API } from "constants/Common";

const getQueryTypes = (projectId) =>
  axios.get(`/review/${projectId}/quickSelect`, {
    headers: authHeader(),
    signal: getAbortController().signal
  });

const getISSearchResultApi = (projectId, data = {}) =>
  axios(`/intelligentSearch/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: checkBodyConvertTimeToUTC(data),
    signal: getAbortController().signal,
  });

const getISSearchResultDetailApi = (path, projectId, isResultId, params = {}) =>
  axios(`/intelligentSearch/${path}/${projectId}/${isResultId}`, {
    headers: authHeader(),
    method: METHOD_API.get,
    params: checkBodyConvertTimeToUTC(params),
    signal: getAbortController().signal,
    timeout: 0,
  });

const getManageSearchDetailApi = (projectId, SearchID, data = {}) =>
  axios(`/manageSearch/${projectId}/${SearchID}/run`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: checkBodyConvertTimeToUTC(data),
    signal: getAbortController().signal,
    timeout: 0,
  });

const exportMessageResultToExcel = (projectId, isResultId, params = {}) => {
  params = checkBodyConvertTimeToUTC(params);
  return axios(
    `/intelligentSearch/chat/${projectId}/${isResultId}/exportExcel`,
    {
      headers: authHeader(),
      method: METHOD_API.get,
      params: {
        ...params,
        TimeOffset: new Date().getTimezoneOffset(),
      },
      responseType: "blob",
    }
  );
};

const exportGroupMessageToExcel = (projectId, chatId, params = {}) => {
  params = checkBodyConvertTimeToUTC(params);
  return axios(
    `/communicationReview/${projectId}/exportGroupMessageToExcel/${chatId}`,
    {
      headers: authHeader(),
      method: METHOD_API.get,
      params: {
        ...params,
        TimeOffset: new Date().getTimezoneOffset(),
      },
      responseType: "blob",
    }
  );
};

const exportEmailResultToExcel = (projectId, isResultId, params = {}) => {
  params = checkBodyConvertTimeToUTC(params);
  return axios(
    `/intelligentSearch/email/${projectId}/${isResultId}/exportExcel`,
    {
      headers: authHeader(),
      method: METHOD_API.get,
      params: {
        ...params,
        TimeOffset: new Date().getTimezoneOffset(),
      },
      responseType: "blob",
    }
  );
};

const exportISResultToExcel = (projectId, data = {}) => {
  data = checkBodyConvertTimeToUTC(data);
  return axios(`/intelligentSearch/${projectId}/exportExcel`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: {
      ...data,
      timeOffset: new Date().getTimezoneOffset(),
    },
    responseType: "blob",
  });
};

const exportManageSearchToExcel = (projectId, SearchID, data = {}) => {
  data = checkBodyConvertTimeToUTC(data);
  return axios(`/manageSearch/${projectId}/${SearchID}/exportExcelRunSearch`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: {
      ...data,
      timeOffset: new Date().getTimezoneOffset(),
    },
    responseType: "blob",
  });
};

const addSaveSearch = (data) =>
  axios(`/manageSearch/${data.projectId}`, {
    method: METHOD_API.post,
    headers: authHeader(),
    data: checkBodyConvertTimeToUTC(data),
  });

export {
  getQueryTypes,
  getISSearchResultApi,
  getISSearchResultDetailApi,
  getManageSearchDetailApi,
  exportEmailResultToExcel,
  exportISResultToExcel,
  exportManageSearchToExcel,
  exportMessageResultToExcel,
  exportGroupMessageToExcel,
  addSaveSearch
};
