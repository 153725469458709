import axios, { authHeader, authHeaderFormData } from "./ApiConfig";

// Constants & Helpers
import { METHOD_API } from "constants/Common";
import { createFormData } from "helpers/ObjectHelper";

const getManageUser = (params = {}) => {
  const {
    Search,
    PaginationParams: { PageSize, PageNumber, ColumnSort, OrderBy },
  } = params;

  return axios.get(`/manageUser/`, {
    headers: authHeader(),
    params: {
      Search,
      "PaginationParams.PageSize": PageSize,
      "PaginationParams.PageNumber": PageNumber,
      "PaginationParams.ColumnSort": ColumnSort,
      "PaginationParams.OrderBy": OrderBy,
    },
  });
};

const getManageUserByID = (userId) =>
  axios.get(`/manageUser/${userId}`, {
    headers: authHeader(),
  });

const deleteUser = (userId) =>
  axios.delete(`/manageUser/${userId}`, {
    headers: authHeader(),
  });

const editUser = (userId, params) => {
  const formData = createFormData(params);
  return axios.put(`/manageUser/${userId}`, formData, {
    headers: authHeaderFormData(),
  });
};

const createUser = (params) => {
  const formData = createFormData(params);
  return axios.post(`/user/create`, formData, {
    headers: authHeaderFormData()
  });
};

const confirmEmailApi = (body) =>
  axios({
    method: METHOD_API.post,
    url: `/manageUser/confirmEmail`,
    data: body,
  });

const createPasswordApi = (body) =>
  axios({
    method: METHOD_API.post,
    url: `/manageUser/createPassword`,
    data: body,
  });

const getManageUserAvatar = (userId) => {
  return axios.get(`/manageUser/${userId}/avatar`, {
    headers: authHeader(),
    responseType: "blob",
  });
}

export {
  getManageUser,
  getManageUserByID,
  deleteUser,
  editUser,
  createUser,
  confirmEmailApi,
  createPasswordApi,
  getManageUserAvatar
};
