export const MATTER_MANAGEMENT_TOAST = {
  matterNameExists: "Matter name already exists",
  noDatabase:
    "This matter cannot be activated because it does not have a database yet. Please set up a database for this matter and retry.",
  createMatter: {
    initiated: "Creating matter...",
    success: "Matter has been successfully created.",
    error: "Failed to create matter.",
  }
};
