import React, { useRef, useState } from "react";
import { Field } from "formik";
import PropTypes from "prop-types";

// Components
import ErrorInline from "components/shared/error-inline/ErrorInline";
import { Button } from "components/shared/button/Button";

// Styles
import styles from "./FieldUpload.module.scss";

const FieldUpload = (props) => {
  const { name, label, placeHolder, required = false } = props;

  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("");

  const handleFileButtonClick = () => fileInputRef.current.click();
  const handleFileInputChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue(name, file);
      setFileName(file.name);
    }
  };

  return (
    <Field name={name}>
      {({ field, meta, form: { setFieldValue } }) => (
        <div className={styles["field-container"]}>
          <label>
            {label}
            {required && <span className="asterisk"> *</span>}
          </label>
          <div className={styles["field-upload"]}>
            <input
              type="text"
              {...field}
              placeholder={placeHolder}
              className={meta.touched && meta.error ? "input-error" : ""}
              readOnly
              title={fileName}
              value={fileName}
            />
            <input
              hidden
              type="file"
              ref={fileInputRef}
              onChange={(event) => handleFileInputChange(event, setFieldValue)}
            />
            <Button name="Browse" handleClick={handleFileButtonClick} />
          </div>
          {meta.touched && meta.error && (
            <div className={styles["form-error"]}>
              <ErrorInline errorMsg={meta.error} touched={meta.touched} />
            </div>
          )}
        </div>
      )}
    </Field>
  );
};

FieldUpload.propTypes = {
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default FieldUpload;
