import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { PropTypes } from "prop-types";
import clone from "lodash/clone";
import { useDispatch, useSelector } from "react-redux";

//Constant
import { SEARCH_SELECTOR } from "constants/SearchSelect";

//Store
import { setIsData, setRemovedQueries } from "store/ISReducer";

//Components
import KeyBlock from "../key-block/KeyBlock";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";

//Styles
import styles from "./SearchGlobal.module.scss";


const SearchGlobal = (props, ref) => {
  const { globalQueries, onChangeQueries } = props;

  const dispatch = useDispatch();

  const initialQueries = { keyword: "", method: "", withinWords: "" };
  const newQueries = {
    keyword: "",
    method: SEARCH_SELECTOR.method.and,
    withinWords: "",
  };
  const newQueriesNested = [
    { keyword: "", method: "", withinWords: "" },
    { keyword: "", method: SEARCH_SELECTOR.method.and, withinWords: "" },
  ];
  const [dataQueries, setDataQueries] = useState(
    globalQueries.length ? globalQueries : [initialQueries]
  );
  const [isShowRemoveFull, setIsShowRemoveFull] = useState(false);
  const queriesRemoved = useSelector((state) => state.is.queriesRemoved);

  useImperativeHandle(
    ref,
    () => ({
      handleUpdateData,
      handleUndoRemove,
      handleClearSearch,
    }),
    [dataQueries]
  );

  const handleClearSearch = () => {
    setDataQueries([initialQueries]);
  };

  const changeDataQueries = () => {
    queriesRemoved && dispatch(setRemovedQueries(""));
  };

  const addDataQueriesNested = ({
    dataQueries,
    dataNested,
    dataPush,
    parentIndex,
  }) => {
    dataQueries.forEach((item, index) => {
      dataPush.push(
        index === parentIndex ? { ...item, nested: clone(dataNested) } : item
      );
    });
    return dataPush;
  };

  // Import array to handle dataQueries
  const handleDataQueriesByArray = (array, event) => {
    let newQueriesArray = [];
    let tempItem = {};
    array.forEach((item, index) => {
      if (index !== event.index) tempItem = item;
      else if (event.updateType !== "method")
        tempItem = { ...item, [event.updateType]: event.data };
      else if (
        item.method.toLowerCase() !==
          SEARCH_SELECTOR.method.nested.toLowerCase() &&
        event.data.toLowerCase() === SEARCH_SELECTOR.method.nested.toLowerCase()
      ) {
        tempItem = {
          ...item,
          [event.updateType]: event.data,
          nestedMethod: SEARCH_SELECTOR.method.and,
          nested: clone(newQueriesNested),
        };
      } else {
        tempItem = {
          ...item,
          [event.updateType]: event.data,
          nestedMethod: "",
          nested: "",
        };
      }
      newQueriesArray.push(tempItem);
    });
    return newQueriesArray;
  };

  const handleAddQueries = (data) => {
    const grandIndex = data?.grandIndex || "";
    const parentIndex = data?.parentIndex || "";
    let newQueriesArray = [];
    let newQueriesGrandChildArray = [];
    let newQueriesChildArray = [];
    changeDataQueries();
    if (grandIndex && !isNaN(grandIndex)) {
      const grandchildData = dataQueries[grandIndex].nested[parentIndex].nested;
      newQueriesGrandChildArray = [...grandchildData, newQueries];
      newQueriesChildArray = addDataQueriesNested({
        dataQueries: dataQueries[grandIndex].nested,
        dataNested: newQueriesGrandChildArray,
        dataPush: newQueriesChildArray,
        parentIndex,
      });
      newQueriesArray = addDataQueriesNested({
        dataQueries,
        dataNested: newQueriesChildArray,
        dataPush: newQueriesArray,
        parentIndex: grandIndex,
      });
    } else if (parentIndex && !isNaN(parentIndex)) {
      newQueriesChildArray = [...dataQueries[parentIndex].nested, newQueries];
      newQueriesArray = addDataQueriesNested({
        dataQueries: dataQueries,
        dataNested: newQueriesChildArray,
        dataPush: newQueriesArray,
        parentIndex,
      });
    } else newQueriesArray = clone([...dataQueries, newQueries]);
    setDataQueries(clone(newQueriesArray));
  };

  const handleUpdateQueries = (event) => {
    const newQueriesArray = handleDataQueriesByArray(dataQueries, event);
    setDataQueries(clone(newQueriesArray));
    changeDataQueries();
  };

  const handleUpdateQueriesChild = (event) => {
    const parentIndex = event.parentIndex;
    const grandIndex = event.grandIndex;
    let newQueriesArray = [];
    const newQueriesGrandChildArray = [];
    let newQueriesChildArray = [];
    if (grandIndex || !isNaN(grandIndex)) {
      const grandchildQueries =
        dataQueries[grandIndex].nested[parentIndex].nested;
      grandchildQueries.forEach((item, index) => {
        if (index === event.index)
          newQueriesGrandChildArray.push({
            ...item,
            [event.updateType]: event.data,
          });
        else newQueriesGrandChildArray.push(item);
      });
      newQueriesChildArray = addDataQueriesNested({
        dataQueries: dataQueries[grandIndex].nested,
        dataNested: newQueriesGrandChildArray,
        dataPush: newQueriesChildArray,
        parentIndex,
      });

      newQueriesArray = addDataQueriesNested({
        dataQueries,
        dataNested: newQueriesChildArray,
        dataPush: newQueriesArray,
        parentIndex: grandIndex,
      });
    } else {
      newQueriesChildArray = handleDataQueriesByArray(
        dataQueries[event.parentIndex].nested,
        event
      );

      newQueriesArray = addDataQueriesNested({
        dataQueries,
        dataNested: newQueriesChildArray,
        dataPush: newQueriesArray,
        parentIndex,
      });
    }
    setDataQueries(clone(newQueriesArray));
    changeDataQueries();
  };

  //Handle update data to Consolidated Query
  const handleUpdateData = () => {
    dispatch(
      setIsData({
        globalSearch:
          dataQueries.length === 1 && !dataQueries[0].keyword
            ? []
            : clone(dataQueries),
      })
    );
    dispatch(setRemovedQueries(""));
  };

  //Handle Remove all query data
  const handleRemoveFull = () => {
    setIsShowRemoveFull(false);
    setDataQueries([initialQueries]);
    dispatch(setRemovedQueries({ index: "", data: clone(dataQueries) }));
  };

  //Handle Remove any query
  const handleRemove = (e) => {
    if (e.index === "") setIsShowRemoveFull(true);
    else {
      dispatch(setRemovedQueries({ ...e }));
      let newQueriesArray = [];
      let newChildArray = [];
      let newGrandchildArray = [];
      let parentIndex = e.parentIndex;
      let grandIndex = e.grandIndex;
      dataQueries.forEach((item, index) => {
        if (grandIndex || !isNaN(grandIndex)) {
          if (index !== grandIndex) newQueriesArray.push(item);
          else {
            item.nested.forEach((childItem, childIndex) => {
              if (childIndex !== parentIndex) newChildArray.push(childItem);
              else {
                newGrandchildArray = [
                  ...childItem.nested.filter(
                    (_, grandchildIndex) => grandchildIndex !== e.index
                  ),
                ];
                newChildArray.push({
                  ...childItem,
                  nested: clone(newGrandchildArray),
                });
              }
            });
            newQueriesArray.push({ ...item, nested: clone(newChildArray) });
          }
        } else if (parentIndex || !isNaN(parentIndex)) {
          if (index !== parentIndex) newQueriesArray.push(item);
          else {
            newChildArray = [
              ...item.nested.filter((_, ind) => ind !== e.index),
            ];
            newQueriesArray.push({ ...item, nested: clone(newChildArray) });
          }
        } else {
          if (e.index !== index) newQueriesArray.push(item);
        }
      });

      setDataQueries(clone(newQueriesArray));
    }
  };

  const handleUndoRemove = () => {
    if (queriesRemoved.index === "") {
      setDataQueries(clone(queriesRemoved.data));
    } else {
      let newQueriesArray = [];
      let newChildArray = [];
      let newGrandchildArray = [];
      let parentIndex = queriesRemoved.parentIndex;
      let grandIndex = queriesRemoved.grandIndex;
      dataQueries.forEach((item, index) => {
        if (grandIndex && !isNaN(grandIndex)) {
          if (index !== grandIndex) newQueriesArray.push(item);
          else {
            item.nested.forEach((childItem, childIndex) => {
              if (childIndex !== parentIndex) newChildArray.push(childItem);
              else {
                childItem.nested.forEach((grandchildItem, grandchildIndex) => {
                  if (grandchildIndex === queriesRemoved.index)
                    newGrandchildArray.push(queriesRemoved.data);
                  newGrandchildArray.push(grandchildItem);
                });
                if (queriesRemoved.index > childItem.nested.length - 1)
                  newGrandchildArray.push(queriesRemoved.data);
                newChildArray.push({
                  ...childItem,
                  nested: clone(newGrandchildArray),
                });
              }
            });
            newQueriesArray.push({ ...item, nested: clone(newChildArray) });
          }
        } else if (parentIndex && !isNaN(parentIndex)) {
          if (index !== parentIndex) newQueriesArray.push(item);
          else {
            item.nested.forEach((childItem, childIndex) => {
              if (childIndex === queriesRemoved.index)
                newChildArray.push(queriesRemoved.data);
              newChildArray.push(childItem);
            });
            if (queriesRemoved.index > item.nested.length - 1)
              newChildArray.push(queriesRemoved.data);
            newQueriesArray.push({ ...item, nested: clone(newChildArray) });
          }
        } else {
          if (index === queriesRemoved.index)
            newQueriesArray.push(queriesRemoved.data);
          newQueriesArray.push(item);
        }
      });
      if (
        isNaN(parentIndex) &&
        isNaN(grandIndex) &&
        queriesRemoved.index > dataQueries.length - 1
      ) {
        newQueriesArray.push(queriesRemoved.data);
      }
      setDataQueries(clone(newQueriesArray));
    }
    dispatch(setRemovedQueries(""));
  };

  useEffect(() => {
    setDataQueries(globalQueries.length ? globalQueries : [initialQueries]);
  }, [globalQueries]);

  useEffect(() => {
    onChangeQueries(dataQueries);
  }, [JSON.stringify(dataQueries)]);

  return (
    <div className={styles["search-global"]}>
      <PopupConfirm
        isShow={isShowRemoveFull}
        handleClose={() => setIsShowRemoveFull(false)}
        handleSubmit={handleRemoveFull}
        content="All the search content will be deleted if you remove the first one. Are you sure you want to remove?"
        textConfirm="Remove"
        type="remove"
      />
      {dataQueries.length &&
        dataQueries.map((item, index) => (
          <KeyBlock
            key={index}
            data={item}
            queriesLength={dataQueries.length}
            index={index}
            onUpdateQueries={handleUpdateQueries}
            onAddQueries={handleAddQueries}
            onUpdateQueriesChild={handleUpdateQueriesChild}
            onRemove={handleRemove}
          />
        ))}
    </div>
  );
};

export default forwardRef(SearchGlobal);

SearchGlobal.propTypes = {
  globalQueries: PropTypes.array,
  onChangeQueries: PropTypes.func,
};
