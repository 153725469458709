import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, Spinner, Card } from "react-bootstrap";
import clsx from "clsx";
import _get from "lodash/get";

// Store
import {
  setParamSearchTimeLine,
  setValidTime,
} from "store/EventTimelineReducer";
import { setEntityAvatarUrls, setFetchAvatarComplete } from "store/AvatarReducer";

// Components
import TimeLineSelector from "components/shared/time-line-selector/TimeLineSelector";
import { DropdownSelector } from "components/shared/dropdown-selector/DropdownSelector";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import ItemTimeLine from "../item-timeline/ItemTimeLine";
import CustomToggle from "../accordion/CustomToggle";
import { MemberList } from "../members/MemberList";

// Helpers
import { formatDateTime, getOrdinalNum } from "helpers/DateTimeFormatterHelper";

// Services
import { getListEventTimelineApi } from "services/EventTimelineService";
import { getEntityAvatar } from "services/EntityManagementService";

// Constants
import { DATA_TYPE, DATA_TYPE_LABEL } from "constants/DataType";
import { DATE_TIME_TYPE, SEARCH_BY_TIME_LIST } from "constants/Constants";

// Styles
import styles from "./TimelineOverview.module.scss";

const TimelineOverview = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const inputRef = useRef();

  const timeZoneOffset = parseInt(new Date().getTimezoneOffset());

  const { paramSearch, validTime } = useSelector(
    (state) => state.eventTimeline
  );

  const { entityUrls } = useSelector((state) => state.avatar);

  const [timeLineData, setTimeLineData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [optionSelected, setOptionSelected] = useState(
    paramSearch.optionSelected
      ? paramSearch.optionSelected
      : SEARCH_BY_TIME_LIST[4]
  );

  const currentDate = paramSearch?.date ? paramSearch?.date : new Date();

  const getEventTimeLine = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const dateRequest = new Date(
        Date.UTC(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate()
        )
      );
      dateRequest.setUTCHours(0, 0, 0, 0);
      const body = {
        start: dateRequest,
        interval: optionSelected.value,
        searchInput: paramSearch.searchMsgInput,
        dataSources: paramSearch.dataSources?.map((item) => item.dataSourceId),
        dataTypes: paramSearch.dataTypes,
        entities: paramSearch.entities,
        participants: paramSearch.participants,
        tags: paramSearch.tags,
        timeZoneOffset
      };

      const result = await getListEventTimelineApi(projectId, body);

      dispatch(setValidTime({ keepDate: false }));
      // TO-DO: implement address on backend
      setTimeLineData(_get(result, "data", []));
      await preloadAvatars(result.data);
      // Scroll to top
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error) {
      setTimeLineData([]);
      setIsError(true);
      console.log(error);
    } finally {
      setIsLoading(false);
      setFetchAvatarComplete(true);
    }
  };

  const isEntityIdANumber = (id) => {
    try {
      const num = Number(id);
      return Number.isInteger(num) && num >= 0;
    } catch (error) {
      return false;
    }
  };

  const preloadAvatars = async (data) => {
    const participantIDs = [];
    data?.forEach(item => {
      item.events?.forEach((event) => event?.groupEvents?.forEach(group => {
        group?.forEach(item => {
          item?.participantDTO?.forEach(participant => {
            if (!participantIDs.includes(participant.id) && participant.hasAvatar){
              participantIDs.push(participant.id);
            }
          });
        });
      }));
    });
    const updatedUrls = [];
    for (const id of participantIDs) {
      if (!isEntityIdANumber(id)) {
        continue;
      }
      const numEntityId = Number(id);
      const urlIndex = entityUrls.findIndex((item) => item.id ===numEntityId);
      if (urlIndex !== -1) {
        continue;
      }
      try {
        const response = await getEntityAvatar(projectId, id);
        const url = URL.createObjectURL(response.data);
        updatedUrls.push({ id: numEntityId, url });
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
    }
    dispatch(setEntityAvatarUrls([...entityUrls, ...updatedUrls]));
  };

  const handleChangeOptionTime = (e) => {
    setOptionSelected(e);
    dispatch(setParamSearchTimeLine({ optionSelected: e }));
  };

  const onSearchHandle = () => {
    if (isLoading) return;
    const keyword = inputRef.current.value.trim();
    dispatch(setParamSearchTimeLine({ searchMsgInput: keyword }));
  };

  const handleClearSearch = () => {
    if (isLoading) return;
    dispatch(setParamSearchTimeLine({ searchMsgInput: null }));
  };

  const getTotalRecord = (list) => list?.reduce((n, { total }) => n + total, 0);

  const getUniqueListBy = (arr, key) => [
    ...new Map(arr.map((item) => [item[key], item])).values(),
  ];

  const sortByDate = (list) =>
    list.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

  const sortByDateGroup = (list) => {
    const sortGroupItem = sortByDate(list).sort(
      (a, b) => new Date(a[0].dateTime) - new Date(b[0].dateTime)
    );
    return Object.values(sortGroupItem);
  };

  const convertObjtoArr = (data) => {
    if (typeof data !== "object") return data;
    return Object.values(data);
  };

  const getParticipants = (eventList) => {
    if (!eventList) return;
    const participantsList = eventList.map((events) => events.participantDTO);
    let participants = [].concat.apply([], participantsList);
    participants = getUniqueListBy(participants, "name");
    return <MemberList
      data={participants}
      isEntity
      matterId={projectId}
      deferAvatarFetch={true}
    />;
  };

  const getTypeByOriginalValue = (type) =>
    DATA_TYPE_LABEL[type.toLowerCase()] || "";

  // Convert from original type to display type and Sort list by type
  const renderTimeLineByType = (list = []) => {
    const listSort = list
      .map((item) => ({
        ...item,
        type: {
          iconName: item.type.value,
          value: getTypeByOriginalValue(item.type.value),
        },
      }))
      ?.sort((a, b) => a.type.value.localeCompare(b.type.value));
    return listSort?.map((item) => {
      const { groupEvents = [], events = [] } = item;
      const groupEventsLength = groupEvents.length;
      return (
        <Accordion key={item.type.value} defaultActiveKey={item.type.value}>
          <Card className={clsx(styles["card"], styles["card-type"])}>
            <Card.Header className={styles["card-header"]}>
              <CustomToggle
                eventKey={item.type.value}
                type={item.type.value}
                iconName={item.type.iconName}
              ></CustomToggle>
              <p className={styles["break-line"]}></p>
              <>{getParticipants([...events, ...groupEvents].flat(1))}</>
              <span className={styles["total-record"]}>{item.total}</span>
              <CustomToggle
                eventKey={item.type.value}
                isArrowToggle
              ></CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={item.type.value}>
              <Card.Body className={styles["card-body-type"]}>
                <div className={clsx(
                  item?.type.value === DATA_TYPE.chat
                    ? styles["time-line-list-2-columns"]
                    : item?.type.value === DATA_TYPE.emailCamelCase
                      ? styles["time-line-list-1-column"]
                      : styles["time-line-list"]
                )}>
                  {sortByDateGroup(groupEvents).map((item2, index) => ( // This is where the group events (chat) is mapped and turned into a singular timeline item
                    <div
                      key={index}
                      className={
                        groupEventsLength > 0
                          ? styles["time-line-list-group"]
                          : ""
                      }
                    >
                      <ItemTimeLine
                        data={convertObjtoArr(item2)}
                        currentIndex={index + 1}
                        isGroupEvents={groupEventsLength > 0}
                      />
                    </div>
                  ))}
                  {sortByDate(events).map((item3, index) => (
                    <div key={item3.id}>
                      <ItemTimeLine
                        data={item3}
                        currentIndex={groupEventsLength + index + 1}
                      />
                    </div>
                  ))}
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    });
  };

  const renderTimeLine = (item) => (
    <Accordion defaultActiveKey={item.startTime} key={item.startTime}>
      <Card className={styles["card"]}>
        <Card.Header className={styles["card-header"]}>
          <CustomToggle eventKey={item.startTime}>
            {item.startTime}
          </CustomToggle>
          <p className={styles["break-line"]}></p>
          <span className={styles["total-record"]}>{item.total}</span>
          <CustomToggle eventKey={item.startTime} isArrowToggle></CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey={item.startTime}>
          <Card.Body className={styles["card-body"]}>
            {renderTimeLineByType(item.events)}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );

  const renderDateTime = () => {
    var dayText = formatDateTime({
      dateTime: currentDate,
      type: "dddd",
      addZulu: false,
    });

    const monthText = currentDate.toLocaleString('default', { month: 'long' });

    var dayNumber = formatDateTime({
      dateTime: currentDate,
      type: DATE_TIME_TYPE.D,
      addZulu: false,
    });

    var dayOrdinal = getOrdinalNum(
      formatDateTime({
        dateTime: currentDate,
        type: DATE_TIME_TYPE.D,
        addZulu: false,
      })
    );

    return (
      <p>
        {dayText}, {monthText} {dayNumber}{dayOrdinal}
      </p>
    );
  };

  useEffect(() => {
    if (!isLoading && paramSearch.date !== null && !(validTime.validTimeLoading || validTime.validYearLoading))
      getEventTimeLine();
  }, [paramSearch.date]);

  useEffect(() => {
    if (!isLoading)
      getEventTimeLine();
  }, [optionSelected])

  return (
    <div className={styles["timeline"]}>
      <div className={styles["timeline-header"]}>
        <p className={styles["title"]}>Timeline</p>
        <div className={styles["preview-control-interval"]}>
          <DropdownSelector
            allowAutoScroll={false}
            size="md"
            itemList={SEARCH_BY_TIME_LIST}
            selectedItem={optionSelected.name}
            onSelect={handleChangeOptionTime}
            className={styles["timeline-dropdown"]}
            isDisable={isLoading || (validTime.validTimeLoading || validTime.validYearLoading)}
          />
        </div>
        <div className={styles["preview-control-search"]}>
          <SearchInputGeneral
              inputRef={inputRef}
              placeholder="Search"
              name="search"
              onSubmitSearch={onSearchHandle}
              handleClearSearch={handleClearSearch}
              searchInput={paramSearch.searchMsgInput}
              hasPreventClearText={isLoading}
          />
        </div>
      </div>
      <div className={styles["content"]}>
        <div className={styles["time-line-selector"]}>
          <TimeLineSelector canChangeDate={!isLoading} />
        </div>
        <div className={styles["time-line-info"]}>
          <div className={styles["time-line-info-header"]}>
            <div className={!(validTime.months.length === 0 || validTime.years.length === 0) ? "" : styles["hidden"]}>
              {renderDateTime()}
            </div>
          </div>
        </div>
        <div className={styles["timeline-body"]}>
          {isLoading || validTime.validTimeLoading || (getTotalRecord(timeLineData) === 0 && (validTime.validTimeLoading || validTime.validYearLoading)) ? (
            <div className={styles["loading-container"]}>
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className={styles["timeline-content"]}>
              {getTotalRecord(timeLineData) === 0 ||
                validTime.months.length === 0 || validTime.years.length === 0 ? (
                <div className={styles["empty-data"]}>
                  <EmptyPage
                    messages={
                      isError ? "An error has occurred" : "No results found"
                    }
                  />
                </div>
              ) : (
                <>
                  {timeLineData.map(
                    (item) => item?.total > 0 && renderTimeLine(item)
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimelineOverview;
