import React from "react";
import { useState } from "react";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { PropTypes } from "prop-types";
import camelCase from "lodash/camelCase";

// Styles
import styles from "./CustomToggle.module.scss";

const CustomToggle = ({
  children,
  eventKey,
  isArrowToggle = false,
  type = "",
  iconName = "",
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const decoratedOnClick = useAccordionButton(eventKey, () => {
    setIsOpen(!isOpen);
  });

  return (
    <>
      {isArrowToggle ? (
        <>
          <img
            src="/images/icons/arrow-down.svg"
            alt={`Arrow ${isOpen ? "up" : "down"}`}
            onClick={decoratedOnClick}
            className={isOpen ? styles["arrow-up"] : styles["arrow-down"]}
          />
        </>
      ) : (
        <>
          {type ? (
            <div className={styles["type-icon"]}>
              {iconName && (
                <img
                  src={`/images/icons/${camelCase(iconName)}.svg`}
                  alt={iconName}
                />
              )}
              <span className={styles["type"]}>{type}</span>
            </div>
          ) : (
            <button type="button" className={styles["time-btn"]}>
              {children}
            </button>
          )}
        </>
      )}
    </>
  );
};

CustomToggle.propTypes = {
  children: PropTypes.any,
  eventKey: PropTypes.any,
  isArrowToggle: PropTypes.bool,
  type: PropTypes.string,
  iconName: PropTypes.string,
};

export default CustomToggle;
