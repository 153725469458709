import axios, { authHeader } from "./ApiConfig";

const applyMassTag = async (matterId, data) => {
    return await axios.post(`tag/${matterId}/massTag`, 
    data, {
        headers: authHeader(),
    });
};

const applyMassUntag = async (matterId, data) => {
    return await axios.post(`tag/${matterId}/massUntag`, 
    data, {
        headers: authHeader(),
    });
};


const applyMassTagToSavedSearch = async (matterId, searchId, data) => {
    return await axios.post(`tag/${matterId}/massTagSavedSearch/${searchId}`, 
    data, {
        headers: authHeader(),
        timeout: 1200000,
    });
};

export {
    applyMassTag,
    applyMassUntag,
    applyMassTagToSavedSearch,
};