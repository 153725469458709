import React, { useRef } from "react";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import { useToken } from "hook/auth";

// Constants
import { MATTER_VALIDATION } from "constants/Validations";
import { KEY_CODE } from "constants/Common";

// Components
import { Button } from "components/shared/button/Button";
import FieldSelect from "components/shared/field/field-select/FieldSelect";
import FieldInput from "components/shared/field/field-input/FieldInput";

// Styles
import styles from "./CreateMatterModal.module.scss";

const CreateMatterModal = (props) => {

  const { show = false, onClose, onSubmit, clientList } = props;

  const btnSubmitRef = useRef(null);
  const { isAdmin } = useToken();

  const handleSubmit = () => {
    if (btnSubmitRef.current) btnSubmitRef?.current?.click();
  };

  const matterSchema = Yup.object().shape({
    clientId: isAdmin()
      ? Yup.string().required(MATTER_VALIDATION.required)
      : Yup.string().notRequired(),
    matterName: Yup.string()
      .trim()
      .required(MATTER_VALIDATION.required)
      .max(128, MATTER_VALIDATION.matterName_max),
    clientMatterReference: Yup.string()
      .trim()
      .max(128, MATTER_VALIDATION.reference_max),
  });

  const onKeyDown = (event) => {
    if (event.keyCode === KEY_CODE.enter) event.preventDefault();
  };
  
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      backdrop="static"
      className={styles["modal-container"]}
      dialogClassName={styles["modal-dialog"]}
      contentClassName={styles["modal-content"]}
    >
      <Modal.Header closeButton className={styles["modal-header"]}>
        <Modal.Title>Add New Matter</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["modal-body"]}>
        <Formik
          initialValues={{
            clientId: "",
            matterName: "",
            clientMatterReference: "",
          }}
          validationSchema={matterSchema}
          onSubmit={(values, { setFieldError }) =>
            onSubmit(values, setFieldError)
          }
        >
          <Form className={styles["form-container"]} onKeyDown={onKeyDown}>
            {isAdmin() && (
              <FieldSelect
                label="Client"
                name="clientId"
                placeHolder="Select Client"
                placeHolderSearch="Search Clients"
                options={clientList}
                required
                hideSearchIcon={true}
              />
            )}
            <div className={styles["field-input"]}>
              <FieldInput
                label="Matter Name"
                name="matterName"
                placeHolder="Enter Matter Name..."
                required
              />
              <FieldInput
                label="Client Matter Reference"
                name="clientMatterReference"
                placeHolder="Client Matter Reference..."
              />
            </div>
            <button ref={btnSubmitRef} type="submit" hidden>
              Submit
            </button>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer className={styles["modal-footer"]}>
        <div className={styles["group-btn"]}>
          <Button name="Cancel" handleClick={onClose} />
          <Button
            className="btn-primary-fill"
            name="Add Matter"
            type="submit"
            handleClick={handleSubmit}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

CreateMatterModal.propTypes = {
  show: PropTypes.bool,
  clientList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateMatterModal;
