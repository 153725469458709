import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Styles
import styles from "./Accordion.module.scss";

// Components
import SpinnerLoading from "../spinner-loading/SpinnerLoading";

const Accordion = ({
  handleExpansion = () => {},
  accordionText = 'View More',
  accordionImage
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExpansionCallback = () => {
    setIsLoading(true);
    handleExpansion();
  };

  return (
    <div className={clsx(styles["accordion-container"])}>
      {isLoading ? (
        <div className={styles["accordion-spinner"]}>
          <SpinnerLoading />
        </div>
      ) : (
        <button 
          onClick={handleExpansionCallback}
          className={clsx(styles["accordion-button"])}
        >
          {accordionText}
          {accordionImage && <img
            src={accordionImage}
            alt="Accordion" 
            className={clsx(styles["accordion-image"])}
          />}
        </button>
      )}
    </div>
  );
};

Accordion.propTypes = {
  handleExpansion: PropTypes.func,
  accordionText: PropTypes.string,
  accordionImage: PropTypes.string,
  accordionLoading: PropTypes.bool
};

export default Accordion;
