import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import store from "./store";
import { Provider } from "react-redux";
import { ScrollToTop } from "./ScrollToTop";
import { Auth0Provider } from "@auth0/auth0-react";
import ErrorBoundary from "components/shared/error/ErrorBoundary";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={`${window.location.origin}/callback`}
        onRedirectCallback={() => {}}
      >
        <Provider store={store}>
          <ScrollToTop>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </ScrollToTop>
        </Provider>
      </Auth0Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
