import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import camelCase from "lodash/camelCase";
import PropTypes from "prop-types";
import clsx from "clsx";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getDeletedStatus } from "helpers/TextHelper";
import { getDuration, handleStringToolong } from "helpers/FormatterHelper";
import { getDataSourceNameFromID, renderClassSort } from "helpers/CommonHelper";

// Components
import EmptyPage from "components/shared/empty-page/EmptyPage";
import SourceTypeList from "components/shared/source-type-list/SourceTypeList";

// constants
import { TYPE_CLICK, COMMON_TEXT } from "constants/Common";
import { DATE_TIME_TYPE, SORT_BY } from "constants/Constants";
import { DATA_TYPE } from "constants/DataType";
import { COLUM_RESULT, INITIAL_SORT } from "constants/StreemConstant";

// Styles
import styles from "./PreviewTable.module.scss";

export const PreviewTable = (props) => {
  const {
    loading,
    isResultsData,
    onSort,
    onSwitchRecord,
    paging,
    onPreviousRecord,
    onNextRecord,
    isLoadingDetail = false,
    dataSourceList,
  } = props;

  const initSort = { ...INITIAL_SORT, DateLast: SORT_BY.asc };

  const [sortColumns, setSortColumns] = useState(initSort);

  // Handle sort table
  const onSortTable = (column, type) => {
    // Only sort one column per time
    const sortType =
      type === ""
        ? SORT_BY.asc
        : type === SORT_BY.asc
        ? SORT_BY.desc
        : SORT_BY.asc;
    if (
      column !== COLUM_RESULT.dateTime.key ||
      column !== COLUM_RESULT.dateLast.key
    )
      setSortColumns({
        ...initSort,
        DateTime: SORT_BY.asc,
        DateLast: SORT_BY.asc,
        [column]: sortType,
      });
    else setSortColumns({ ...initSort, [column]: sortType });
    onSort({
      sortType: sortType,
      columnSort: column,
    });
  };

  // Handle change icon sort
  const classSort = (columnName) => renderClassSort(columnName, styles);

  const getDescription = (data) => {
    const { description, type } = data;
    if (description) {
      if ([DATA_TYPE.callLog, DATA_TYPE.voicemail].includes(type))
        return getDuration(description);
      else {
        if (type === DATA_TYPE.chatGroup)
          return handleStringToolong(description, 39);
        else return <span>{description}</span>;
      }
    } else return COMMON_TEXT.default;
  };

  return (
    <div className={styles["is-result"]}>
      {loading ? (
        <Spinner variant="primary" animation="border" />
      ) : (
        <>
          {paging.totalRecords > 0 ? (
            <div className={styles["is-result-list"]}>
              <div className={styles["table-wrap"]}>
                <table>
                  <thead>
                    <tr>
                      {Object.values(COLUM_RESULT).map((item) => (
                        <th key={item.key}>
                          <a
                            className={clsx(
                              classSort(sortColumns[item.key]),
                              [
                                COLUM_RESULT.type.key,
                                COLUM_RESULT.dataSourceID.key,
                                COLUM_RESULT.participants.key,
                              ].includes(item.key)
                                ? styles["disable-pointer"]
                                : ""
                            )}
                            onClick={() =>
                              onSortTable(item.key, sortColumns[item.key])
                            }
                          >
                            {item.label}
                          </a>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {isResultsData.map((item, index) => (
                      <tr
                        key={index}
                        id={`focus-item-${index}`}
                        onClick={() =>
                          onSwitchRecord({
                            id: item.id,
                            type: item.type,
                            index,
                          })
                        }
                        className={
                          paging.recordNumber === index
                            ? styles["conv-select"]
                            : ""
                        }
                      >
                        <td>
                          <img
                            src={`/images/icons/${camelCase(item.type)}.svg`}
                            alt="img-message"
                          />
                        </td>
                        <td>
                          {item?.source ? (
                            <SourceTypeList
                              sourceList={item.source.split(", ")}
                              limitSource={3}
                            />
                          ) : (
                            COMMON_TEXT.default
                          )}
                        </td>
                        <td
                          title={
                            item.dataSourceID &&
                            getDataSourceNameFromID(
                              dataSourceList,
                              item.dataSourceID.split(/,\s*/)
                            )
                          }
                        >
                          {item.dataSourceID
                            ? getDataSourceNameFromID(
                                dataSourceList,
                                item.dataSourceID.split(/,\s*/)
                              )
                            : COMMON_TEXT.default}
                        </td>
                        <td>
                          {item.participants ? (
                            <div className={styles["participant"]}>
                              <span
                                title={item.participants}
                                className={styles["background"]}
                              >
                                <span>{item.participants}</span>
                              </span>
                            </div>
                          ) : (
                            COMMON_TEXT.default
                          )}
                        </td>
                        <td>
                          {item.dateTime
                            ? formatDateTime({
                                dateTime: item.dateTime,
                                type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
                                mst: false,
                              })
                            : COMMON_TEXT.default}
                        </td>
                        <td>
                          {item.dateLast
                            ? formatDateTime({
                                dateTime: item.dateLast,
                                type: DATE_TIME_TYPE.MM_DD_YYYY_LT,
                                mst: false,
                              })
                            : COMMON_TEXT.default}
                        </td>
                        <td
                          title={item.description}
                          className={styles["description"]}
                        >
                          {getDescription(item)}
                        </td>
                        <td>
                          {[
                            DATA_TYPE.emailThread,
                            DATA_TYPE.email,
                            DATA_TYPE.chatGroup,
                            DATA_TYPE.chat,
                          ].includes(item.type)
                            ? item?.itemsCount
                            : COMMON_TEXT.default}
                        </td>
                        <td>{item.attachmentCount || COMMON_TEXT.default}</td>
                        <td>{getDeletedStatus(item.deleted)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className={styles["bottom-container"]}>
                <p className={styles["detail-view-txt"]}>Detail View</p>
                <div className={styles["paging-container"]}>
                  {isResultsData?.length > 1 && (
                    <>
                      <button
                        type="button"
                        className={clsx(
                          styles["btn-message-prev"],
                          "btn mar_r10"
                        )}
                        onClick={onPreviousRecord}
                        disabled={paging.recordNumber === 0 || isLoadingDetail}
                      >
                        <img
                          src={`/images/ic-prev${
                            paging.recordNumber === 0 || isLoadingDetail
                              ? "-disabled"
                              : ""
                          }.svg`}
                          alt={TYPE_CLICK.previous}
                        />
                      </button>
                      <button
                        type="button"
                        className={clsx(styles["btn-message-next"], "btn")}
                        onClick={onNextRecord}
                        disabled={
                          paging.recordNumber === isResultsData.length - 1 ||
                          isLoadingDetail
                        }
                      >
                        <img
                          src={`/images/ic-next${
                            paging.recordNumber === isResultsData.length - 1 ||
                            isLoadingDetail
                              ? "-disabled"
                              : ""
                          }.svg`}
                          alt={TYPE_CLICK.next}
                        />
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <EmptyPage
              messages="No results found. Please try again."
              isImgnotFound={true}
            />
          )}
        </>
      )}
    </div>
  );
};

PreviewTable.propTypes = {
  searchInput: PropTypes.string,
  loading: PropTypes.bool,
  isLoadingDetail: PropTypes.bool,
  paging: PropTypes.object,
  isResultsData: PropTypes.array,
  inputRef: PropTypes.any,
  dataSourceList: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      custodianName: PropTypes.string,
      dataSourceID: PropTypes.number,
    })
  ),
  onClickPagingHandle: PropTypes.func,
  onSubmitSearch: PropTypes.func,
  onSubmitDate: PropTypes.func,
  onSort: PropTypes.func,
  onSwitchRecord: PropTypes.func,
  onPreviousRecord: PropTypes.func,
  onNextRecord: PropTypes.func,
};
