import { createSlice } from "@reduxjs/toolkit";

const mediaControlSlice = createSlice({
  name: "mediaControl",
  initialState: {
    duration: "",
  },
  reducers: {
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDuration } = mediaControlSlice.actions;
export const MediaControlReducer = mediaControlSlice.reducer;