import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Services
import { getLinkPreviewApi } from "services/CommonService";

// Constants
import { RESPONSE_STATUS } from "constants/StatusCodeConstant";

// Styles
import styles from "./LinkPreviewAPI.module.scss";

export const LinkPreviewAPI = ({ url, isSmall = true }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    image: "",
    description: "",
    title: "",
    link: "",
  });

  const controller = new AbortController();
  const signal = controller.signal;

  const getPreviewAPI = () => {
    setIsLoading(true);
    getLinkPreviewApi(url, signal)
      .then((res) => {
        if (res.status !== RESPONSE_STATUS.success) return;
        return res.json();
      })
      .then((response) => {
        const { title, description, image, url } = response;
        setData({
          image,
          title,
          description,
          link: url,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPreviewAPI();
    return () => controller.abort();
  }, []);

  return (
    !isLoading && (
      <div className={clsx(
        styles["section"],
        isSmall ? styles["section-sm"] : ""
      )}>
        <div className={styles["container"]}>
          <a className={styles["content"]} href={url} target="blank">
            <img
              src={data.image || "/images/file-attach.jpeg"}
              alt="thumbnail"
            />
            <div className={styles["is-clipped"]}>
              <div className={styles["title"]}>{data.title}</div>
              <div className={styles["description"]}>{data.description}</div>
              <div className={styles["linkURL"]}>{data.link}</div>
            </div>
          </a>
        </div>
      </div>
    )
  );
};

LinkPreviewAPI.propTypes = {
  url: PropTypes.string,
  isSmall: PropTypes.bool,
};
