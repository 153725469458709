import React from "react";
import PropTypes from "prop-types";

// Helpers
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";

// Constants
import { PARAMETERS } from "constants/Common";
import { TIMELINE_INFORMATION } from "constants/DataType";

const InfoFinancial = ({ financialInfo }) => {
  const renderInfo = (keyValueList) => {
    return (
      <>
        {keyValueList.map((item) => (
          <InfoResultItem
            key={item.label}
            label={item.label}
            value={getInformationByKey(item.key, financialInfo)}
            isIcon={item.key === PARAMETERS.typeLabel}
            type={financialInfo[PARAMETERS.type]}
          />
        ))}
      </>
    );
  };

  return <>{renderInfo(TIMELINE_INFORMATION.creditCard)}</>;
};

InfoFinancial.propTypes = {
  financialInfo: PropTypes.object,
};

export default InfoFinancial;
