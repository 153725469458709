import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import camelCase from "lodash/camelCase";
import clsx from "clsx";
import { useSelector } from "react-redux";

// Constants
import {
  DATA_INFO_LABEL,
  PARAMETERS,
  COMMON_TEXT,
} from "constants/Common";
import { DATE_TIME_TYPE } from "constants/Constants";
import { CHAT_EMAIL_TYPE } from "constants/DataType";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { getNewTypeByOriginalType } from "helpers/DataTypeConvertHelper";
import { urlify } from "helpers/TextHelper";
import { convertDataSourceInfo } from "helpers/DataSourceHelper";

//Components
import { ParticipantsInformation } from "components/chat-email-information/participants/ParticipantsInformation";
import DataSourceInformation from "components/chat-email-information/data-source-info/DataSourceInformation";

// Styles
import styles from "./InfoResultItem.module.scss";

const InfoResultItem = (props) => {
  const { label, value, isIcon, type, isReview } = props;

  const [displayValue, setDisplayValue] = useState();

  const dataSourceList = useSelector(
    (state) => state.dataSource.dataSourceList
  );

  const renderInfoResult = () => {
    if (label?.toLowerCase() === PARAMETERS.locations) {
      return (
        <div className={styles["list-container"]}>
          {displayValue?.map((item) => (
            <div key={`${item.timeStamp}`}>
              <p className={styles["location-value"]} title={item.longitude}>
                Longitude: {item.longitude}
              </p>
              <p className={styles["location-value"]} title={item.latitude}>
                Latitude: {item.latitude}
              </p>
              <p className={styles["location-value"]}>
                Date/Time:{" "}
                {formatDateTime({
                  dateTime: item.timeStamp,
                  type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmssA,
                })}
              </p>
            </div>
          ))}
        </div>
      );
    } else if (label === DATA_INFO_LABEL.dataSource) {
      return displayValue ? (
        <div className={styles["data-source"]}>
          <DataSourceInformation
            dataSources={convertDataSourceInfo(
              dataSourceList,
              displayValue.split(", ")
            )}
            isShowCollapse={false}
            isShowTitle={false}
          />
        </div>
      ) : (
        <p className={styles["unknown"]}>{COMMON_TEXT.unknown}</p>
      );
    } else if (label === DATA_INFO_LABEL.participants) {
      return (
        <>
          {displayValue?.length > 0 ? (
            <div className={styles["participants"]}>
              <ParticipantsInformation
                participants={displayValue}
                isRedColor={CHAT_EMAIL_TYPE.includes(type)}
                isShowCollapse={false}
                isShowTitle={false}
              />
            </div>
          ) : (
            <p className={styles["unknown"]}>{COMMON_TEXT.unknown}</p>
          )}
        </>
      );
    } else {
      return (
        <p
          className={clsx(
            styles["call-log-value"],
            displayValue?.length > 45 && styles["call-log-value-block"]
          )}
          title={displayValue || COMMON_TEXT.unknown}
        >
          {isIcon && (
            <img
              src={`/images/icons/${
                type
                  ? camelCase(isReview ? type : getNewTypeByOriginalType(type))
                  : "no-datatype"
              }.svg`}
              alt={`Data type ${type}`}
            />
          )}
          {["Link details", "URL"].includes(label) ? (
            displayValue ? (
              urlify(displayValue, null, true, true)
            ) : (
              <p>{COMMON_TEXT.unknown}</p>
            )
          ) : !displayValue ? (
            COMMON_TEXT.unknown
          ) : (
            displayValue
          )}
        </p>
      );
    }
  };

  useEffect(() => {
    setDisplayValue(value);
  }, [value]);

  return (
    <div className={clsx(styles["call-log-item"])}>
      <p className={styles["call-log-label"]}>{label}</p>
      {renderInfoResult()}
    </div>
  );
};

InfoResultItem.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  displayType: PropTypes.string,
  value: PropTypes.any,
  isIcon: PropTypes.bool,
  isReview: PropTypes.bool,
};

export default InfoResultItem;
