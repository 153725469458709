import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { PropTypes } from "prop-types";
import clsx from "clsx";
import { useOnClickOutside } from "hook/click-outside";

// Components
import SuggestionItem from "components/shared/search-input/suggestion-item/SuggestionItem";
import SearchFilter from "components/shared/search-input/search-filter/SearchFilter";
import PreviewSavedSearch from "components/shared/preview-saved-search/PreviewSavedSearch";

// Styles
import styles from "./DropdownExportConfig.module.scss";

const DropdownExportConfig = (props) => {
  const {
    className = "",
    options = [],
    onChange = () => {},
    value = [],
    placeholder = "Select",
    placeholderSearch = "Search",
    isDisabled = false,
    isLoading = false,
    isMulti = false,
    selectedOption = [],
    notSelectedOption = [],
    onChangeSelect = () => {},
    onChangeNotSelect = () => {},
    isClearable = true,
    handleClearValue = () => {},
    dataSourceList = () => {},
  } = props;

  const dropdownRef = useRef();
  const menuRef = useRef();

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  useOnClickOutside(
    menuRef,
    () => dropdownRef?.current && setMenuIsOpen(false),
    dropdownRef?.current
  );

  const handleChangeMenu = (searchID) => {
    onChange(searchID);
    setMenuIsOpen(false);
  };

  const handleClearSearch = () => setSearchInput("");

  const handleSearchSuggestion = (event) => setSearchInput(event.target.value);

  const renderSearchSuggestion = () => {
    const filteredList = options.filter(
      (item) =>
        item.label
          ?.trim()
          .toLowerCase()
          .indexOf(searchInput.trim().toLowerCase()) !== -1
    );
    return (
      <>
        {filteredList.length > 0 ? (
          <ul className={styles["menu-list"]}>
            {filteredList.map((item) => (
              <PreviewSavedSearch
                savedSearch={{
                  searchID: item.value,
                  searchName: item.label,
                }}
                selectedItem={value[0]?.value}
                searchInput={searchInput}
                handleChangeMenu={handleChangeMenu}
                key={item.value}
                isReset={!menuIsOpen}
                dataSourceList={dataSourceList}
              />
            ))}
          </ul>
        ) : (
          <p className={styles["no-result"]}>No Result</p>
        )}
      </>
    );
  };

  const renderTagSuggestion = ({ options, isSelected = false }) => {
    const filteredList = options?.filter(
      (item) =>
        item.name
          .trim()
          .toLowerCase()
          .indexOf(searchInput.trim().toLowerCase()) !== -1
    );
    return (
      <>
        <p className={styles["list-title"]}>{`${
          isSelected ? "Not Selected" : "Selected"
        } (${filteredList?.length})`}</p>
        {filteredList.length > 0 ? (
          <ul className={styles["menu-multi-list"]}>
            {filteredList.map((item, key) => (
              <li
                className={styles["menu-multi-list-item"]}
                key={key}
                id={`search-${item.name}`}
              >
                <div>
                  <span
                    style={{ backgroundColor: item.color }}
                    className={styles["tag-color"]}
                  ></span>
                  <span>{item.hotKey ? `(${item.hotKey})` : ""}</span>
                  {searchInput ? (
                    <span className={styles["tag-name"]} title={item.name}>
                      <SuggestionItem
                        data={item.name}
                        input={searchInput.trim()}
                      />
                    </span>
                  ) : (
                    <span className={styles["tag-name"]} title={item.name}>
                      {item.name}
                    </span>
                  )}
                  <span> - </span>
                  <span>{item.type}</span>
                </div>
                {isSelected ? (
                  <img
                    src="/images/plus-square-blue-icon.svg"
                    alt="select"
                    onClick={() => onChangeSelect(item)}
                  />
                ) : (
                  <img
                    src="/images/minus-square-red-icon.svg"
                    alt="unselect"
                    onClick={() => onChangeNotSelect(item)}
                  />
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p className={styles["no-result"]}>No Result</p>
        )}
      </>
    );
  };

  const menuList = () => {
    return (
      <div
        className={clsx(styles["menu"], menuIsOpen ? "d-block" : "")}
        ref={menuRef}
      >
        <SearchFilter
          value={searchInput}
          onChange={handleSearchSuggestion}
          placeholder={placeholderSearch}
          handleClearSearch={handleClearSearch}
        />
        {renderSearchSuggestion()}
      </div>
    );
  };

  const menuListMulti = () => {
    return (
      <div
        className={clsx(styles["menu"], menuIsOpen ? "d-block" : "")}
        ref={menuRef}
      >
        <SearchFilter
          value={searchInput}
          onChange={handleSearchSuggestion}
          placeholder={placeholderSearch}
          handleClearSearch={handleClearSearch}
        />
        {renderTagSuggestion({ options: selectedOption })}
        {renderTagSuggestion({
          options: notSelectedOption,
          isSelected: true,
        })}
      </div>
    );
  };

  useEffect(() => {
    handleClearSearch();
  }, [menuIsOpen]);

  return (
    <div className={styles["dropdown-container"]} ref={dropdownRef}>
      <div onClick={() => !isDisabled && setMenuIsOpen(!menuIsOpen)}>
        <Select
          className={className}
          classNamePrefix="react-select"
          placeholder={placeholder}
          value={value}
          isSearchable={false}
          components={{
            Menu: () => null,
            IndicatorSeparator: () => null,
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
      </div>
      {isClearable && value.length > 0 && (
        <img
          onClick={handleClearValue}
          className={styles["clear-icon"]}
          src="/images/clear-search.svg"
          alt="Clear Icon"
        />
      )}
      {isMulti ? menuListMulti() : menuList()}
    </div>
  );
};

DropdownExportConfig.propTypes = {
  value: PropTypes.array,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderSearch: PropTypes.string,
  options: PropTypes.array,
  selectedOption: PropTypes.array,
  notSelectedOption: PropTypes.array,
  dataSourceList: PropTypes.arrayOf(
    PropTypes.shape({
      deviceName: PropTypes.string,
      custodianName: PropTypes.string,
      dataSourceID: PropTypes.number,
      dataSourceTypeId: PropTypes.number,
      dataSourceTypeName: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  onChangeSelect: PropTypes.func,
  onChangeNotSelect: PropTypes.func,
  handleClearValue: PropTypes.func,
};

export default DropdownExportConfig;
