// Helper
import { sortAlphabetListTag } from "./FormatterHelper";

export const getFileName = (url, title) => {
  if (!url) return title;
  const parts = url.split("/");
  return parts?.length > 0 ? parts[parts.length - 1] : "";
};

export const getFileNameFromUrl = (url = "") => {
  if (!url) return;
  const parts = url.split("/");
  return parts?.length > 0 ? parts[parts.length - 1] : "";
};

export const getFileTypeFromName = (fileName = "") => {
  if (!fileName) return;
  const fileNameSplit = fileName.split(".");
  return fileNameSplit
    ? fileNameSplit[fileNameSplit.length - 1].toLowerCase()
    : "";
};

export const getProjectName = (projectList = [], projectId) =>
  projectList.find((item) => item.projectId === parseInt(projectId))
    ?.projectName;

export const getDataSourceName = (id, sources = []) =>
  sources.find((source) => source.dataSourceID === id)?.dataSourceName;

export const getTagSelectedName = (allTag = [], tagSelected = []) => {
  const intersection = allTag.filter((element) =>
    tagSelected.includes(element.tagID)
  );
  const sortTags = sortAlphabetListTag(intersection);
  if (!sortTags) return [];
  return sortTags;
};
