import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import { RichTextEditorComponent, HtmlEditor, Inject } from '@syncfusion/ej2-react-richtexteditor';
import { downloadAttachmentHtml } from 'services';

// Constants
import { ZERO_BYTE_ERROR, TOO_LARGE_ERROR, RENDER_ERROR } from 'constants/NativeViewerConstants';

// Styles
import styles from './RichTextAttachmentRenderer.module.scss';

const RichTextAttachmentRenderer = (props) => {
  const {
    attachmentId,
    projectId,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [htmlRenderString, setHtmlRenderString] = useState("");
  const [showCannotRenderAttach, setShowCannotRenderAttach] = useState(false);
  const [cannotRenderMsg, setCannotRenderMsg] = useState("");

  const sanitizeHtmlString = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.innerHTML
      .split('\n')
      .filter(line => line.trim() !== '') // Filter out empty lines
      .join('\n');
  };

  useEffect(() => {
    const fetchAttachmentHtml = async () => {
      try {
        const response = await downloadAttachmentHtml(projectId, attachmentId);
        const reader = new FileReader();
          reader.onload = (e) => {
            setHtmlRenderString(e.target.result);
            setIsLoading(false);
          };
          reader.readAsText(response.data, "UTF-8");
      } catch (error) {
        setShowCannotRenderAttach(true);
        if (error.response?.status === 400 && error.response?.data?.message?.includes('file is empty')) {
          setCannotRenderMsg(ZERO_BYTE_ERROR);
        } else if (error.response?.status === 404) {
          setCannotRenderMsg('Preiew is not available for this attachment.');
        } else if (error.response?.status === 413) {
          setCannotRenderMsg(TOO_LARGE_ERROR);
        } else {
          setCannotRenderMsg(RENDER_ERROR);
        }
        setIsLoading(false);
      }
    };

    fetchAttachmentHtml();
  }, [attachmentId, projectId]);

  useEffect(() => {
    return () => {
      setHtmlRenderString("");
    }
  }, []);

  return (
    showCannotRenderAttach ? (
      <div className={styles['cannot-render']}>
        <img
          src="/images/image-notfound.png"
          alt="not found"
        />
        <p>{cannotRenderMsg}</p>
      </div>
    ) : (
      <>
        {isLoading && 
                <div className={styles['loading-spinner-container']}>
                    <SpinnerLoading isCenter={false} />    
                </div>}
        <div className={
          isLoading
            ? styles['html-renderer-hidden']
            : styles['html-renderer']
        }>
          <RichTextEditorComponent
            value={sanitizeHtmlString(htmlRenderString)}
            readonly={true}
            toolbarSettings={{
              enable: false,
            }}
            editorMode='HTML'
          >
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </>
    )
  )
};

RichTextAttachmentRenderer.propTypes = {
  attachmentId: PropTypes.string,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default RichTextAttachmentRenderer;
