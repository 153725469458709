import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./MenuSelectTag.module.scss";

const MenuSelectTag = ({ tagList, checkedList, onChange, disabled }) => {
  return (
    <ul className={styles["dropdown-menu-select"]}>
      {tagList.map((item) => (
        <li key={item.tagID}>
          <span style={{ backgroundColor: item.color }} />
          <p title={`${item.name} ${item.hotKey ? `(${item.hotKey})` : ""}`}>
            {`${item.name} ${item.hotKey ? `(${item.hotKey})` : ""}`}
          </p>
          <input
            id={item.tagID}
            className={styles["form-check-input"]}
            value={item.tagID}
            type="checkbox"
            checked={checkedList?.some(
              (value) => parseInt(value) === item.tagID
            )}
            onChange={(event) => onChange(event, item.tagID)}
            disabled={disabled}
          />
        </li>
      ))}
    </ul>
  );
};

MenuSelectTag.propTypes = {
  tagList: PropTypes.arrayOf(
    PropTypes.shape({
      tagID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      hotKey: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  checkedList: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
};

export default MenuSelectTag;
