import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Components
import { Button } from "components/shared/button/Button";
import { OnlyDateSelector } from "components/shared/date-picker/OnlyDateSelector";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import ExportTable from "../../components/export-management/export-table/ExportTable";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import PrintExport from "components/shared/print-export/PrintExport";

// Services
import {
  cancelExportApi,
  deleteExportApi,
  downloadExportApiFetch,
  editExportApi,
  getExportListApi,
  runExportApi,
  updateScheduleApi,
} from "services/ExportManagementService";

// Constants
import { breadCrumbExportManagement } from "constants/BreadCrumbConstants";
import {
  DATE_TIME_TYPE,
  SCREEN_WIDTH_3839,
  SORT_BY,
} from "constants/Constants";
import { COMMON_TEXT, PATH_NAME } from "constants/Common";
import {
  ACTION_NAME,
  EXPORT_TYPE,
  MSG_CONFIRM,
  STATUS_EXPORT,
} from "constants/ExportConstant";
import { EXPORT_VALIDATIONS } from "constants/Validations";

// Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { formatDateTimeWithOriginal } from "helpers/DateTimeFormatterHelper";

// Stores
import { fetchTagsList } from "store/TagReducer";
import {
  setCheckedStatus,
  setCreateExport,
  setExportParam,
  setParamCondition,
} from "store/ExportManagementReducer";

//Styles
import styles from "./ExportManagementPage.module.scss";
import { downloadFileWithFetch } from "helpers/DownloadFileHelper";

const sortType = {
  exportName: "exportName",
  userCreate: "userCreate",
  createdDate: "createdDate",
  exportTime: "exportTime",
  totalItems: "totalItems",
};

export const ExportManagementPage = () => {
  const inputRef = useRef();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const TOTAL_ITEM = {
    screen4K: 20,
    fullHD: 10,
  };
  const perPage =
    window.innerWidth > SCREEN_WIDTH_3839
      ? TOTAL_ITEM.screen4K
      : TOTAL_ITEM.fullHD;

  const userID = useSelector((state) => state.user.userInfo.id);
  const {
    exportNotification: exportNotice,
    paramSearch,
    paramCondition: { isCreateExport, isCreateExportScreen },
  } = useSelector((state) => state.export);

  const allTags = useSelector((state) => state.tag.tags.allTags);

  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingAction, setLoadingAction] = useState("");
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [isShowPicker, setIsShowDatePicker] = useState(false);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [exportID, setExportID] = useState("");
  const [disConfirmPopup, setDisConfirmPopup] = useState(false);
  const [errorMsgDate, setErrorMsgDate] = useState("");
  const [messageConfirm, setMessageConfirm] = useState({
    status: 1,
    message: "",
  });
  const [exportDataList, setExportDataList] = useState({
    items: [],
    selectName: [],
    totalRecords: 0,
  });
  const [sortColumns, setSortColumns] = useState([
    { type: sortType.exportName, isAcs: true },
    { type: sortType.userCreate, isAcs: false },
    { type: sortType.createdDate, isAcs: true },
    { type: sortType.exportTime, isAcs: true },
    { type: sortType.totalItems, isAcs: true },
  ]);

  const paramSearchInit = {
    searchInput: "",
    exportStatuses: [],
    paginationParams: {
      columnSort: sortType.createdDate,
      orderBy: SORT_BY.desc,
      pageSize: perPage,
      pageNumber: 1,
    },
  };

  const goBack = () => navigate(`/${PATH_NAME.matters}`);

  const onSortTable = (columnName) => {
    setLoadingTable(true);
    const obj = sortColumns.find((item) => item.type === columnName);
    const newSortColumns = sortColumns.map((item) => ({
      type: item.type,
      isAcs: item.type === columnName ? !obj.isAcs : true,
    }));
    const sortParam = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        columnSort: obj.type,
        orderBy: obj.isAcs ? SORT_BY.asc : SORT_BY.desc,
      },
    };
    setSortColumns(newSortColumns);
    fetchExportList(projectId, sortParam);
  };

  const navigatorToCreateExport = () => {
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.createExport}`);
    dispatch(
      setParamCondition({
        isCreateExport: true,
        isCreateExportScreen: true,
      })
    );
  };

  // Handle search export
  const onSubmitSearch = async () => {
    setLoadingTable(true);
    const paramsRequest = {
      ...paramSearch,
      searchInput: inputRef.current.value.trim(),
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: 1,
      },
    };
    dispatch(setExportParam(paramsRequest));
    await fetchExportList(projectId, paramsRequest);
    setCurrentPage(0);
  };

  // Handle run exports
  const handleRunExport = async (exportID) => {
    setLoadingAction(exportID);
    try {
      updateExportProp(exportID, { status: STATUS_EXPORT.inprogress.value });
      await runExportApi(projectId, exportID);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAction("");
    }
  };

  // Handle clone export
  const handleCloneExport = (
    exportID,
    { isPrint = false, exportName = "", status }
  ) => {
    const exportData = exportDataList.items.find(
      (item) => item.exportID === exportID
    );
    const exportConfig = JSON.parse(exportData.exportConfiguration);
    const cloneExport = {
      projectId,
      userID,
      exportName: exportData.exportName,
      exportTime: exportData.exportTime,
      scheduleTime: exportData.scheduleTime,
      isScheduleExport: exportData.scheduleTime ? true : false,
      action: 0,
      status: exportData.status,
      totalItems: exportData.totalItems,
      exportInformation: exportData.exportInformation || "",
      parameterConfig: {
        // remove exportEML when clone export
        typeExport: exportConfig.TypeExport.filter(
          (item) =>
            ![EXPORT_TYPE.exportEML].includes(item)
        ),
        tagID: allTags
          ?.filter((item) => exportConfig.TagID.includes(item.tagID))
          .map((item) => item.tagID),
        excludeTags: allTags
          ?.filter((item) => exportConfig.ExcludeTags?.includes(item.tagID))
          .map((item) => item.tagID),
        condition: exportConfig.Condition,
        exportAroundItems: exportConfig.ExportAroundItems,
        startingControlPrefix: exportConfig.StartingControlPrefix,
        startingControlNumber: exportConfig.StartingControlNumber,
        startingVolumePrefix: exportConfig.StartingVolumePrefix,
        startingVolumeNumber: exportConfig.StartingVolumeNumber,
        rsmfDocumentSize: exportConfig.RSMFDocumentSize,
        emlDocumentSize: exportConfig.EMLDocumentSize,
        delimiter: exportConfig.Delimiter,
        dateFormat: exportConfig.DateFormat,
        timeZone: exportConfig.TimeZone,
      },
    };
    dispatch(setCreateExport(cloneExport));
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.createExport}`, {
      state: {
        isCloneExport: true,
        isPrint,
        exportName,
        status,
      },
    });
    dispatch(
      setParamCondition({
        isCreateExport: false,
        isCreateExportScreen: true,
      })
    );
  };

  // Update status of export record
  const updateExportProp = (exportID, data) => {
    if (!exportDataList.items.length) return;
    setExportDataList({
      ...exportDataList,
      items: exportDataList.items.map((item) => {
        if (item.exportID === exportID) return { ...item, ...data };
        return item;
      }),
    });
  };

  const openPopupConfirm = (exportID, msgConfirm) => {
    setMessageConfirm(msgConfirm);
    setExportID(exportID);
    setDisConfirmPopup(true);
  };

  // Handle cancel export
  const handleCancelExport = async (exportID) => {
    try {
      updateExportProp(exportID, { status: STATUS_EXPORT.canceling.value });
      await cancelExportApi(projectId, exportID);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadExport = async (exportID, exportName) => {
    try {
      const fetchPromise = downloadExportApiFetch(projectId, exportID);
      await downloadFileWithFetch(fetchPromise, `${exportName}.zip`);
    } catch (error) {
      console.log(error);
    }
  };

  // Handle edit export
  const handleEditExport = (exportID) => {
    navigate(
      `/${PATH_NAME.matters}/${projectId}/${PATH_NAME.exportManagement}/${exportID}`
    );
    dispatch(
      setParamCondition({
        isCreateExport: false,
        isCreateExportScreen: true,
      })
    );
  };

  const openDatePicker = (exportID) => {
    setExportID(exportID);
    const exportData = exportDataList.items.find(
      (item) => item.exportID === exportID
    );
    setScheduleTime(exportData.scheduleTime);
    setIsShowDatePicker(true);
    setErrorMsgDate("");
  };

  const closeReSchedule = () => {
    setScheduleTime(null);
    setIsShowDatePicker(false);
  };

  const handleUpdateSchedule = async (date) => {
    // Checking valid reschedule date time
    if (new Date(date) < new Date()) {
      setErrorMsgDate(EXPORT_VALIDATIONS.scheduleTime.error);
      return;
    }

    try {
      // Checking export is running or not
      setLoadingDetail(true);
      const result = await editExportApi({ projectId, exportID });
      if (result.data.status !== STATUS_EXPORT.scheduled.value) {
        setErrorMsgDate(EXPORT_VALIDATIONS.scheduleTime.canNotReschedule);
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDetail(false);
    }

    setErrorMsgDate("");
    setLoadingTable(true);
    try {
      await updateScheduleApi(projectId, {
        ExportID: exportID,
        ScheduleTime: formatDateTime({
          dateTime: new Date(date),
          addZulu: false,
        }),
        ExportName: exportDataList.items.find(
          (item) => item.exportID === exportID
        ).exportName,
      });
      setScheduleTime(null);
      fetchExportList(projectId, paramSearch);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const cancelReschedule = async () => {
    setLoadingTable(true);
    try {
      await updateScheduleApi(projectId, {
        ExportID: exportID,
        ScheduleTime: "",
        ExportName: exportDataList.items.find(
          (item) => item.exportID === exportID
        ).exportName,
      });
      fetchExportList(projectId, paramSearch);
    } catch (error) {
      console.log(error);
      setLoadingTable(false);
    }
  };

  // Handle delete schedule
  const handleDeleteExport = async () => {
    setLoadingTable(true);
    const currentPage =
      paramSearch.paginationParams.pageNumber > 1
        ? paramSearch.paginationParams.pageNumber - 1
        : 1;
    const isEqualTotal = currentPage * 10 + 1 === exportDataList.totalRecords;
    let paramSearchRequest = paramSearch;

    // Update page number after delete export
    if (isEqualTotal) {
      paramSearchRequest = {
        ...paramSearch,
        paginationParams: {
          ...paramSearch.paginationParams,
          pageNumber: currentPage,
        },
      };
    }

    try {
      await deleteExportApi(projectId, exportID);
      setExportDataList({
        ...exportDataList,
        items: exportDataList.items.filter(
          (item) => item.exportID !== exportID
        ),
      });
      await fetchExportList(projectId, paramSearchRequest);
      isEqualTotal && setCurrentPage(currentPage - 1);
    } catch (error) {
      console.log(error);
      setLoadingTable(false);
    }
  };

  // Handle confirm popup
  const handleConfirmPopup = () => {
    setDisConfirmPopup(true);
    if (messageConfirm.status === ACTION_NAME.delete) handleDeleteExport();
    else if (messageConfirm.status === ACTION_NAME.cancel) handleCancelExport();
    else if (messageConfirm.status === ACTION_NAME.reschedule)
      cancelReschedule();
    setDisConfirmPopup(false);
  };

  // Paging
  const handlePagingClick = (e) => {
    setLoadingTable(true);
    setCurrentPage(e.selected);
    const sortParam = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: e.selected + 1,
      },
    };
    fetchExportList(projectId, sortParam);
  };

  // handle filter export by status
  const applyFilterStatus = async (checkedStatus) => {
    setLoadingTable(true);
    const paramsRequest = {
      ...paramSearch,
      exportStatuses: checkedStatus,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: 1,
      },
    };
    await fetchExportList(projectId, paramsRequest);
    dispatch(setExportParam(paramsRequest));
    setCurrentPage(0);
  };

  // Fetch export data from API
  const fetchExportList = async (projectIdRequest, bodyRequest) => {
    setLoadingTable(true);
    const paramsRequest = {
      ...paramSearch,
      ...bodyRequest,
    };
    dispatch(setExportParam(paramsRequest));
    try {
      const res = await getExportListApi(projectIdRequest, bodyRequest);
      setExportDataList(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingTable(false);
    }
  };

  const handlePrintExport = (exportId) => {
    handleCloneExport(exportId, {
      isPrint: true,
      exportName: exportDataList.items.find(
        (item) => item.exportID === exportId
      ).exportName,
      status: STATUS_EXPORT.completed.value,
    });
  };

  // Get export data when page render the first
  useEffect(() => {
    dispatch(fetchTagsList({ projectId }));
    fetchExportList(
      projectId,
      isCreateExport || !isCreateExportScreen ? paramSearchInit : paramSearch
    );

    if (isCreateExport || !isCreateExportScreen) {
      dispatch(setCheckedStatus([]));
      setCurrentPage(0);
    } else setCurrentPage(paramSearch.paginationParams.pageNumber - 1);

    dispatch(
      setParamCondition({
        isCreateExport: false,
        isCreateExportScreen: false,
      })
    );
  }, [projectId]);

  // Update status real time
  const updateStatusRealTime = async () => {
    if (exportNotice.exportId) {
      if (STATUS_EXPORT.completed.value !== exportNotice.exportStatus) {
        updateExportProp(exportNotice.exportId, {
          status: exportNotice.exportStatus,
        });
        if (exportNotice.exportId === exportID)
          setErrorMsgDate(EXPORT_VALIDATIONS.scheduleTime.canNotReschedule);
      } else {
        const res = await getExportListApi(projectId, paramSearchInit);
        // Update export info after run export completed
        updateExportProp(
          exportNotice.exportId,
          res.data.items.find((item) => item.exportID === exportNotice.exportId)
        );
      }
      setLoadingAction("");
    }
  };

  // Update status real time
  useEffect(() => {
    !loadingTable && updateStatusRealTime();
  }, [JSON.stringify(exportNotice), loadingTable]);

  const pdfFileName = `Export Management_${formatDateTimeWithOriginal({
    dateTime: new Date(),
    original: DATE_TIME_TYPE.LT,
    newFormat: DATE_TIME_TYPE.YYYYMMDDhhmmss,
    addZulu: false,
  })}`;

  return (
    <div className={clsx(styles["wrap"], "main")}>
      <BreadCrumb goBack={goBack} breadCrumbData={breadCrumbExportManagement} />
      <div className={styles["header"]}>
        <h2 className={styles["header-title"]}>Export management</h2>
        <div className={styles["header-control"]}>
          <PrintExport
            screenName={pdfFileName}
            isDisplayTime
            isExportExcel={false}
          />
        </div>
      </div>
      <div className={styles["export-body"]}>
        <div className={styles["export-body-box"]}>
          <p className={styles["content-title"]}>Exports</p>
          <div className={styles["control-header"]}>
            <div className={styles["wrap-btn"]}>
              <Button
                name="Create New Export"
                iconUrl="/images/plus-icon-white.svg"
                altIcon="Add New Search"
                className="btn-primary-fill"
                handleClick={navigatorToCreateExport}
                isDisabled={loadingTable}
              />
            </div>
            <SearchInputGeneral
              inputRef={inputRef}
              placeholder="Search Exports"
              name="search"
              onSubmitSearch={onSubmitSearch}
              suggestions={exportDataList.selectName}
              isAutoComplete={true}
              searchInput={paramSearch.searchInput}
            />
          </div>
        </div>
        <>
          <div className={styles["total-export"]}>
            <span>
              {Number(exportDataList.totalRecords)} Export
              {exportDataList.totalRecords > 1 ? "s" : ""}
            </span>
          </div>
          <div className={styles["export-table"]}>
            <ExportTable
              exportDataList={exportDataList}
              sortColumns={sortColumns}
              sortType={sortType}
              loadingTable={loadingTable}
              statusApply={paramSearch.exportStatuses}
              loadingAction={loadingAction}
              isSearchExport={
                paramSearch.searchInput || paramSearch.exportStatuses.length > 0
              }
              onSortTable={onSortTable}
              applyFilterStatus={applyFilterStatus}
              handleCloneExport={(exportId) => handleCloneExport(exportId, {})}
              handleDownloadExport={handleDownloadExport}
              handleEditExport={handleEditExport}
              handleRunExport={handleRunExport}
              openDatePicker={openDatePicker}
              handlePrintExport={handlePrintExport}
              handleCancelExport={handleCancelExport}
              handleDeleteExport={(exportID) =>
                openPopupConfirm(exportID, {
                  status: ACTION_NAME.delete,
                  message: MSG_CONFIRM.delete,
                })
              }
              handleCancelReschedule={(exportID) =>
                openPopupConfirm(exportID, {
                  status: ACTION_NAME.reschedule,
                  message: MSG_CONFIRM.cancel,
                })
              }
            />
          </div>
          {exportDataList.totalRecords > 0 && (
            <PaginationResult
              perPage={10}
              forcePage={currentPage}
              totalRecord={Number(exportDataList.totalRecords)}
              pageItems={exportDataList.items.length}
              handlePagingClick={handlePagingClick}
              isSmall
              isUpdateCurrentPage={loadingTable}
            />
          )}
        </>
      </div>
      {scheduleTime && (
        <OnlyDateSelector
          title="Reschedule Export"
          showDatePicker={isShowPicker}
          handleClose={closeReSchedule}
          initDate={formatDateTime({
            dateTime: scheduleTime,
            type: DATE_TIME_TYPE.MM_DD_YYYY_hhmmssA,
          })}
          handleSubmit={handleUpdateSchedule}
          btnSubmitName="Reschedule"
          disableBtnReset={true}
          disableBtnCancel={false}
          disableType="before"
          errorMsg={errorMsgDate}
          loading={loadingDetail}
        />
      )}
      <PopupConfirm
        isShow={disConfirmPopup}
        handleClose={() => setDisConfirmPopup(false)}
        handleSubmit={handleConfirmPopup}
        title="Confirmation"
        content={messageConfirm.message}
        textConfirm={COMMON_TEXT.yes}
        textReject={COMMON_TEXT.no}
        handleReject={() => setDisConfirmPopup(false)}
        type={COMMON_TEXT.remove}
      />
    </div>
  );
};
