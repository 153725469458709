import { PARAMETERS } from "constants/Common";
import { EMAIL_SEND_MODE } from "constants/Constants";
import { FIELD_VALIDATION } from "constants/RegexConstant";
import {
  countRepeatedWords,
  detectTextInHtml,
  filterWords,
} from "./TextHelper";

export const getEmailToOrCc = (type, emailParties, isEmail) => {
  let emailListResult = "";
  emailParties?.forEach((item) => {
    if (item.role === type)
      emailListResult = `${emailListResult} ${
        PARAMETERS.emails === isEmail ? item.email : item.name
      } < ${item.email} >,`;
  });
  return emailListResult
    ? emailListResult?.slice(0, emailListResult.length - 1)
    : "";
};

export const getMailSender = (emailParties = []) => {
  const emails = emailParties?.filter(
    (item) => item.role === "From"
  );
  return emails.length >= 1 ? emails[0] : { email: "", name: "", role: "" };
};

export const getEmailByRole = (mailList = [], role) => {
  if (mailList.length === 0) return "";
  const list = mailList.filter((item) => item.role === role);
  return list ? list.map((mail) => mail.email).join(", ") : "";
};

const getCountEmailHighlight = (
  searchInput = "",
  email,
  isSearchBody = true
) => {
  const { subject = "", emailParties, body = "" } = email;
  const textArr = [
    subject,
    isSearchBody ? getMailSender(emailParties)?.name : "",
    getMailSender(emailParties)?.email,
    getEmailToOrCc(EMAIL_SEND_MODE.to, emailParties),
    getEmailToOrCc(EMAIL_SEND_MODE.cc, emailParties),
    isSearchBody ? detectTextInHtml(body || "") : "",
  ];

  // Get total search highlight in a one thread
  let countText = 0;
  searchInput.split(FIELD_VALIDATION.keywordReplaceHighlight).forEach((item) => {
    textArr.forEach((text) => {
      let count = text
        ?.trim()
        .toLowerCase()
        .split(item.trim().toLowerCase()).length;
      if (count > 1) countText += count - 1;
    });
  });
  return countText;
};

export const getTotalSearchEmail = (searchInput, emailList,isSearchBody) => {
  let totalSearchCount = 0;
  let totalEmailContainKeyword = 0;
  emailList?.forEach((email) => {
    const countHighlight = getCountEmailHighlight(searchInput, email,isSearchBody );
    totalSearchCount += countHighlight;
    if (countHighlight > 0) totalEmailContainKeyword++;
  });
  return { totalEmailContainKeyword, totalSearchCount };
};

export const getEmailWordClouds = (data) => {
  const textContainHtml = data?.email.filter((item) =>
    item.body?.includes("<html")
  );
  if (textContainHtml.length > 0) {
    let htmlText = "";
    data.email.forEach((email) => {
      htmlText += ` ${email.body}`;
    });
    return filterWords(countRepeatedWords(htmlText));
  }
  return data?.wordCloud;
};
