// Components
import EventTimelinePage from "pages/event-timeline/EventTimelinePage";
import { HeatMapPage } from "pages/heatmap/HeatMapPage";

// Constant
import { PATH_NAME, PAGE_NAME } from "./Common";

export const ANALYTIC_PAGES_INFO = {
  eventTimeline: {
    label: PAGE_NAME.eventTimeline,
    value: PATH_NAME.eventTimeline,
  },
  communicationAnalyzer: {
    label: PAGE_NAME.communicationAnalyzer,
    value: PATH_NAME.communicationAnalyzer,
  }
};

export const ANALYTIC_PAGES = [
  {
    ...ANALYTIC_PAGES_INFO.eventTimeline,
    component: EventTimelinePage,
  },
  {
    ...ANALYTIC_PAGES_INFO.communicationAnalyzer,
    component: HeatMapPage,
  }
];

export const ANALYTIC_LIST = [
  ANALYTIC_PAGES_INFO.eventTimeline,
  ANALYTIC_PAGES_INFO.communicationAnalyzer,
];

export const ANALYTICS_TYPE = [
  {
    id: 2,
    name: PAGE_NAME.eventTimeline,
    img: "timeline",
    path: ANALYTIC_PAGES_INFO.eventTimeline.value,
  },
  {
    id: 4,
    name: PAGE_NAME.communicationAnalyzer,
    img: "communication-analyzer",
    path: ANALYTIC_PAGES_INFO.communicationAnalyzer.value,
  }
];