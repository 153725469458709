import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import clsx from "clsx";

// Services
import {
  assignIdentifierApi,
  exportExcelIdentifierApi,
  getEntitySelectionApi,
  getIdentifiersApi,
  getSourceTypeApi,
  removePreferredNameApi,
} from "services/IdentifiersService";
import { handleAbortRequest } from "services/ApiConfig";
import {
  createNewEntityApi,
  uploadAvatarApi,
} from "services/EntityManagementService";

// Constants
import { COLUMN_NAME, IDENTIFIER_LIST } from "constants/IdentifiersConstant";
import { SCREEN_WIDTH_3839, SORT_BY, TOTAL_ITEM } from "constants/Constants";
import { PRINT_SCREEN_NAME } from "constants/Common";
import { FILE_TYPE } from "constants/DataType";
import { ERROR_STATUS } from "constants/StatusCodeConstant";

// Helpers
import { toThousandsNumber } from "helpers/FormatterHelper";
import { sortAlphabet } from "helpers/CommonHelper";
import { getSourceIconByName } from "helpers/CommonHelper";
import { trimObject } from "helpers/ObjectHelper";

// Components
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import { PaginationResult } from "components/shared/paging/PaginationResult";
import IdentifiersTable from "components/manage-identifiers/identifiers-table/IdentifiersTable";
import DropdownFilter from "components/shared/dropdown-filter/DropdownFilter";
import PrintExport from "components/shared/print-export/PrintExport";
import CreateEntityModal from "components/shared/create-entity-modal/CreateEntityModal";
import { Loading } from "components/shared/loading/Loading";

// Styles
import styles from "./IdentifiersManagement.module.scss";

const IdentifiersManagement = () => {
  const { projectId } = useParams();
  const inputRef = useRef(null);

  const perPage =
    window.innerWidth > SCREEN_WIDTH_3839
      ? TOTAL_ITEM.screen4K
      : TOTAL_ITEM.fullHD;
  const initData = {
    items: [],
    totalRecords: 0,
    selectName: [],
  };
  const ALL_IDENTIFIER = 1;
  const initParamSearch = {
    search: "",
    isSuggestName: true,
    identifierType: ALL_IDENTIFIER,
    sources: [],
    paginationParams: {
      pageNumber: 1,
      pageSize: perPage,
      columnSort: COLUMN_NAME.occurrences.value,
      orderBy: SORT_BY.desc,
    },
  };

  const [loadingTable, setLoadingTable] = useState(true);
  const [entityList, setEntityList] = useState([]);
  const [paramSearch, setParamSearch] = useState(initParamSearch);
  const [identifierList, setIdentifierList] = useState(initData);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [identifierItem, setIdentifierItem] = useState({
    identifierId: "",
    likelyName: "",
  });
  const [errorIdentifierList, setErrorIdentifierList] = useState([]);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const identifierFilterInit = [
    {
      key: "type",
      label: "Type",
      options: IDENTIFIER_LIST,
      checkedList: [],
    },
    {
      key: "sources",
      label: "Source(s)",
      options: [],
      checkedList: [],
    },
  ]
  const [identifierFilter, setIdentifierFilter] = useState(identifierFilterInit);

  const fetchIdentifiersData = async (params) => {
    try {
      setLoadingTable(true);
      const result = await getIdentifiersApi(projectId, params);
      const data = result.data;
      setIdentifierList({
        items: data.items,
        totalRecords: data.totalRecords,
        selectName: data.selectName,
      });
      setLoadingTable(false);
    } catch (error) {
      if (!error.response.status) return;
      setIdentifierList(initData);
      setLoadingTable(false);
    }
  };

  const fetchSourceType = async () => {
    try {
      const result = await getSourceTypeApi(projectId);
      setIdentifierFilter(
        identifierFilterInit.map((item) => {
          if (item.key === "sources")
            return {
              ...item,
              options: result.data?.map((item) => ({
                value: item,
                label: item,
                icon: getSourceIconByName(item, "other"),
              })),
            };
          return item;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchEntitySelection = async () => {
    try {
      const result = await getEntitySelectionApi(projectId);
      const entities = result.data?.map((item) => ({
        value: item.entityID,
        label: item.name,
      }));
      setEntityList(sortAlphabet(entities));
    } catch (error) {
      console.log(error);
      setEntityList([]);
    }
  };

  const handleSortTable = (sortParam) => {
    handleAbortRequest();
    const params = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        columnSort: sortParam.columnSort,
        orderBy: sortParam.orderBy,
      },
    };
    setParamSearch(params);
    fetchIdentifiersData(params);
  };

  const handlePagingTable = (event) => {
    handleAbortRequest();
    const params = {
      ...paramSearch,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: event.selected + 1,
      },
    };
    setParamSearch(params);
    fetchIdentifiersData(params);
  };

  const handleSubmitSearch = () => {
    handleAbortRequest();
    const keyword = inputRef.current.value?.trim() || "";
    const params = {
      ...paramSearch,
      search: keyword,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: 1,
      },
    };
    setParamSearch(params);
    fetchIdentifiersData(params);
  };

  const onRemovePreferredName = ({ identifier = "", preferredName = "" }) => {
    try {
      removePreferredNameApi(projectId, identifier, preferredName);
    } catch (error) {
      console.log(error);
    }
  };

  const onAssignIdentifier = async ({ identifier, entityID }) => {
    try {
      await assignIdentifierApi(projectId, identifier, entityID);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportToExcel = async () => {
    try {
      setExportLoading(true);
      const params = {
        ...paramSearch,
        timeOffset: new Date().getTimezoneOffset(),
      };
      const result = await exportExcelIdentifierApi(projectId, params);
      const url = window.URL.createObjectURL(result.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Identifiers.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
    } finally {
      setExportLoading(false);
    }
  };

  const handleSaveFilter = (data) => {
    const params = {
      ...paramSearch,
      identifierType:
        data.type?.length === 1 ? Number(data.type) : ALL_IDENTIFIER,
      sources: data.sources,
      paginationParams: {
        ...paramSearch.paginationParams,
        pageNumber: 1,
      },
    };
    const getIdentifierFilter = identifierFilter.map((item) => {
      const value = data[item.key]
      return {
        ...item,
        checkedList:
          item.options.length === value.length ? value.concat(item.key) : value,
      };
    });
    setIdentifierFilter(getIdentifierFilter);
    setParamSearch(params);
    fetchIdentifiersData(params);
  };

  const handleSubmitForm = async (params) => {
    try {
      setIsLoadingModal(true);
      setLoadingTable(true);
      setErrorIdentifierList([]);
      const formData = new FormData();
      formData.append(FILE_TYPE.image, params.avatar);
      const trimParam = trimObject(params);
      const result = await createNewEntityApi(projectId, trimParam);
      await uploadAvatarApi(projectId, result.data.entityID, formData);
      setShowEntityModal(false);
      fetchEntitySelection();
      await onAssignIdentifier({
        identifier: identifierItem.identifierId,
        entityID: result.data.entityID,
      });
      fetchIdentifiersData(paramSearch);
    } catch (error) {
      console.log(error);
      setLoadingTable(false);
      if (error.response.status === ERROR_STATUS.badRequest)
        setErrorIdentifierList(error.response.data?.errorIdentifiers);
    } finally {
      setIsLoadingModal(false);
    }
  };

  useEffect(() => {
    fetchSourceType();
    fetchEntitySelection();
    fetchIdentifiersData(initParamSearch);
    setParamSearch(initParamSearch);
    setIdentifierFilter(identifierFilterInit);
  }, [projectId]);

  return (
    <div className={clsx(styles["wrap"])}>
      <Loading loading={isLoadingModal} isBlur />
      {showEntityModal && (
        <CreateEntityModal
          isShow={showEntityModal}
          onHide={() => {
            setShowEntityModal(false);
            setErrorIdentifierList([]);
          }}
          onSubmitForm={handleSubmitForm}
          errorIdentifierList={errorIdentifierList}
          setErrorIdentifierList={setErrorIdentifierList}
          likelyName={identifierItem.likelyName}
        />
      )}
      <div className={styles["header-control"]}>
        <h2>Manage Identifiers</h2>
        <div className={styles["print-export"]}>
          <PrintExport
            screenName={PRINT_SCREEN_NAME.manageIdentifiers}
            handleExport={handleExportToExcel}
            exportLoading={exportLoading}
            isDisplayTime
          />
        </div>
      </div>
      <div className={styles["container"]}>
        <div className={styles["header"]}>
          <h3>Identifiers List</h3>
          <SearchInputGeneral
            inputRef={inputRef}
            placeholder="Search"
            isAutoComplete={true}
            name="search"
            onSubmitSearch={handleSubmitSearch}
            searchInput={paramSearch.search}
            suggestions={identifierList.selectName}
          />
        </div>
        <div className={styles["sub-header"]}>
          <DropdownFilter
            filterTitle="Identifiers Filter"
            subTitle="Choose options for filter identifiers"
            filterOption={identifierFilter}
            handleSaveFilter={handleSaveFilter}
          />
          <span className={styles["identifiers-total"]}>{`${toThousandsNumber(
            identifierList.totalRecords
          )} ${
            identifierList.totalRecords > 1 ? "Identifiers" : "Identifier"
          }`}</span>
        </div>
        <div className={styles["identifiers-table"]}>
          <IdentifiersTable
            isLoading={loadingTable}
            identifierData={identifierList.items}
            entityList={entityList}
            totalRecords={identifierList.totalRecords}
            handleSortTable={handleSortTable}
            onRemovePreferredName={onRemovePreferredName}
            onAssignIdentifier={onAssignIdentifier}
            handleAddNewEntity={(identifierId, likelyName) => {
              setIdentifierItem({ identifierId, likelyName });
              setShowEntityModal(true);
            }}
          />
        </div>
        {identifierList.totalRecords > 0 && (
          <PaginationResult
            perPage={perPage}
            forcePage={paramSearch.paginationParams.pageNumber - 1}
            totalRecord={identifierList.totalRecords}
            pageItems={identifierList.items?.length}
            handlePagingClick={handlePagingTable}
            isSmall
            isUpdateCurrentPage={loadingTable}
            nameRecord={
              identifierList.totalRecords > 1 ? "identifiers" : "identifier"
            }
          />
        )}
      </div>
    </div>
  );
};

export default IdentifiersManagement;
