import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import clsx from "clsx";

// Components
import { Button } from "components/shared/button/Button";
import Checkbox from "components/shared/checkbox/Checkbox";

// Services
import { createTag } from "services/TagManagementService";

// Redux
import { fetchTagsList } from "store/TagReducer";

// Constants
import { TAG_COLORS } from "constants/Constants";
import { TAG_VALIDATION } from "constants/Validations";
import { COMMON_TEXT } from "constants/Common";

// Styles
import styles from "./TagModal.module.scss";

const TagModal = (props) => {
  const {
    data,
    isShow = false,
    type,
    apiErrorMessageProp,
    handleClose,
    handleSubmit,
    isSubmitting = false,
  } = props;

  const dispatch = useDispatch();
  const { projectId } = useParams();
  const hotKeyButtonRef = useRef(null);

  const [tagColor] = useState(data?.color || "");
  const [tagColors, setTagColors] = useState(
    type === COMMON_TEXT.add ? TAG_COLORS.slice(0, 3) : TAG_COLORS
  );
  const [hotKey, setHotKey] = useState(data?.hotKey || null);
  const [errorMessage, setErrorMessage] = useState({});
  const [apiErrorMessage, setApiErrorMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const pressKeyText = "Press any button on your keyboard to add";
  const maxLengthTagName = 100;
  const TAG_COLORS_DEFAULT = "#e66465";

  const tagError = {
    nameExist: "name exist",
    colorExist: "color exist",
    hotKeyExist: "hotkey exist",
  };

  const formik = useFormik({
    initialValues: {
      tagName: data?.name || "",
      tagColor: tagColor,
      hotKey: hotKey,
      isPrivate: data ? data.type === COMMON_TEXT.private : true,
    },
    onSubmit: (values) => {
      let { tagName, tagColor, hotKey } = values;
      validationTag(tagName.trim(), tagColor.trim());
      if (tagName.trim() || tagColor.trim() || hotKey.trim()) {
        let formData = {
          ...values,
          type: values.isPrivate ? COMMON_TEXT.private : COMMON_TEXT.global,
          tagName: values.tagName.trim(),
        };

        if (type === COMMON_TEXT.add) {
          hotAddTag(formData);
        } else {
          if (type === COMMON_TEXT.edit)
            formData = {
              ...formData,
              zIndex: data.zIndex,
              status: data.status,
            };
          handleSubmit({ ...formData });
        }
      }
      setFirstLoad(false);
    },
  });

  const validationTag = (name, color) => {
    let errors = {};
    if (name) {
      if (name.length > maxLengthTagName)
        errors = { ...errors, tagName: TAG_VALIDATION.tagName.maxLength };
    } else errors = { ...errors, tagName: TAG_VALIDATION.tagName.required };
    if (!color) errors = { ...errors, tagColor: TAG_VALIDATION.color.required };
    setErrorMessage(errors);
  };

  useEffect(() => {
    setErrorMessage({
      ...errorMessage,
      ...apiErrorMessage,
      ...apiErrorMessageProp,
    });
  }, [apiErrorMessageProp, apiErrorMessage]);

  const hotAddTag = async (data) => {
    if (isLoading) return;
    setIsLoading(true);
    let params = {
      name: data.tagName,
      color: data.tagColor,
      hotKey: data.hotKey,
      type: data.isPrivate ? COMMON_TEXT.private : COMMON_TEXT.global,
      status: true,
      projectId: projectId,
    };
    try {
      await createTag(params);
      dispatch(fetchTagsList({ projectId }));
      handleClose();
      toast.success("Create tag successfully.");
    } catch (error) {
      let response = error.response.data;
      let apiError = {};
      if (response.includes(tagError.nameExist))
        apiError = { ...apiError, tagName: TAG_VALIDATION.tagName.exist };
      if (response.includes(tagError.colorExist))
        apiError = { ...apiError, tagColor: TAG_VALIDATION.color.exist };
      if (response.includes(tagError.hotKeyExist))
        apiError = { ...apiError, hotKey: TAG_VALIDATION.hotKey.exist };
      setApiErrorMessage(apiError);
    } finally {
      setIsLoading(false);
    }
  };

  const chooseColor = () => {
    document.getElementById("color-picker").click();
  };

  const onHandleColorPicker = (e) => {
    let colors = [...tagColors];
    if (type === COMMON_TEXT.add) {
      if (tagColors.length === 4) {
        colors[3] = e.target.value;
      }
      if (tagColors.length === 3) {
        colors.push(e.target.value);
      }
    } else {
      if (tagColors.length === 9) {
        colors[8] = e.target.value;
      }
      if (tagColors.length === 8) {
        colors.push(e.target.value);
      }
    }
    setTagColors(colors);
    if (!colors.includes(tagColor)) formik.values.tagColor = "";
  };

  //handle select hotKey
  const keyboardEvents = (e) => {
    if (hotKey && hotKey !== pressKeyText) return;
    e.preventDefault();
    const upperKey = e.key?.trim() ? e.key.toUpperCase() : e.code.toUpperCase();
    setHotKey(upperKey);
    formik.setFieldValue("hotKey", upperKey);
  };

  const handleAddHotKey = () => {
    setHotKey(pressKeyText);
    hotKeyButtonRef.current.handleFocus();
    formik.values.hotKey = "";
  };

  const isDisabled = () => {
    return (
      formik.values.tagName === data.name &&
      formik.values.tagColor === data.color &&
      hotKey === data.hotKey &&
      formik.values.isPrivate === !!(data.type === COMMON_TEXT.private)
    );
  };

  useEffect(() => {
    if (
      type === COMMON_TEXT.edit &&
      data.color &&
      !TAG_COLORS.includes(data.color)
    ) {
      setTagColors([...TAG_COLORS, data.color]);
    }
  }, [data]);

  useEffect(() => {
    if (firstLoad) return;
    const { tagName, tagColor } = formik.values;
    validationTag(tagName.trim(), tagColor.trim());
  }, [formik.values]);

  return (
    <>
      {type === COMMON_TEXT.add ? (
        <form
          onSubmit={formik.handleSubmit}
          className={clsx(styles["modal-container"], styles["tab-container"])}
        >
          <Modal.Header
            className={clsx(styles["modal-header"], styles["tab-header"])}
          >
            <div>
              <p
                className={clsx(
                  styles["modal-header-title"],
                  styles["tab-title"]
                )}
              >
                <img
                  src="/images/right_move_ic.svg"
                  alt="back"
                  className="cursor-pointer me-2"
                  onClick={handleClose}
                />
                Create new tag
              </p>
              <span></span>
            </div>
            <div onClick={formik.handleSubmit} className={styles["tab-close"]}>
              Done
            </div>
          </Modal.Header>
          <Modal.Body
            className={clsx(styles["modal-body"], styles["tab-body"])}
          >
            <div className="mar_b16">
              <label htmlFor="email" className={styles["modal-body-label"]}>
                Tag name
              </label>
              <input
                className={clsx("form-control", styles["modal-body-input"])}
                name="tagName"
                type="text"
                placeholder="Please input tag name..."
                value={formik.values.tagName}
                onChange={(event) => {
                  formik.handleChange(event);
                }}
              />
              {errorMessage?.tagName && (
                <span className="error text-danger">
                  {errorMessage.tagName}
                </span>
              )}
            </div>
            <div className="mar_b16">
              <label
                htmlFor="role"
                className={clsx("d-flex mar_b14", styles["modal-body-label"])}
              >
                Select Color
              </label>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {tagColors.map((color, index) => (
                    <label
                      className={styles["radio-color-group"]}
                      key={index}
                      onClick={() => {
                        formik.handleChange(color);
                      }}
                      htmlFor={`${color}-${index}`}
                    >
                      <input
                        className={styles["radio-color"]}
                        name="tagColor"
                        id={`${color}-${index}`}
                        type="radio"
                        value={color}
                        checked={color === formik.values.tagColor}
                        style={{ backgroundColor: color }}
                        onChange={formik.handleChange}
                      />
                      <span
                        className={styles["check-mark"]}
                        style={{ backgroundColor: color }}
                      ></span>
                      {color === formik.values.tagColor && (
                        <span
                          className={styles["border-check-mark"]}
                          style={{ borderColor: color }}
                        ></span>
                      )}
                    </label>
                  ))}
                </div>
                <div
                  className={clsx(
                    "d-flex align-items-center",
                    styles["more-color"]
                  )}
                >
                  <span>More Colors</span>
                  <div className={styles["color-picker-wrapper"]}>
                    <img
                      src="/images/icons/more-color-icon.svg"
                      alt="more-icon"
                      onClick={chooseColor}
                    />
                    <input
                      type="color"
                      id="color-picker"
                      defaultValue={TAG_COLORS_DEFAULT}
                      onChange={onHandleColorPicker}
                      className={styles["hidden"]}
                    />
                  </div>
                </div>
              </div>
              {errorMessage?.tagColor && (
                <span className="error text-danger">
                  {errorMessage?.tagColor}
                </span>
              )}
            </div>
            <div className="mar_b16">
              <label htmlFor="role" className={styles["modal-body-label"]}>
                Select Hotkey
              </label>
              <div className="d-flex align-center">
                <Button
                  ref={hotKeyButtonRef}
                  name={hotKey || "Click to add Hotkey"}
                  className={
                    hotKey && hotKey !== pressKeyText
                      ? "btn-gray"
                      : hotKey === pressKeyText
                      ? "btn-medium-dashed"
                      : "btn-medium"
                  }
                  handleClick={handleAddHotKey}
                  onKeyDown={keyboardEvents}
                />
                {hotKey && hotKey !== pressKeyText && (
                  <img
                    className={clsx(
                      "ms-3 cursor-pointer",
                      styles["modal-re-choose"]
                    )}
                    src="/images/icons/return-icon.svg"
                    alt="Return"
                    onClick={handleAddHotKey}
                  />
                )}
              </div>
              {errorMessage?.hotKey && (
                <span className="error text-danger">
                  {errorMessage?.hotKey}
                </span>
              )}
            </div>
            <div>
              <Checkbox
                handleClick={formik.handleChange}
                label="Set as private"
                name="isPrivate"
                id="tag-id"
                size="lg"
                value={formik.values.isPrivate}
                isChecked={formik.values.isPrivate}
              />
            </div>
          </Modal.Body>
        </form>
      ) : (
        <Modal
          className={styles["modal-container"]}
          show={isShow}
          onHide={handleClose}
        >
          <form onSubmit={formik.handleSubmit}>
            <Modal.Header className={styles["modal-header"]}>
              <p className={styles["modal-header-title"]}>
                {type === COMMON_TEXT.create ? "Create new tag" : "Edit tag"}
              </p>
              <div onClick={handleClose} className="cursor-pointer">
                <img src="/images/close-modal-icon.png" alt="Close" />
              </div>
            </Modal.Header>
            <Modal.Body className={styles["modal-body"]}>
              <div className="mar_b16">
                <label htmlFor="email" className={styles["modal-body-label"]}>
                  Tag name
                </label>
                <input
                  className={clsx("form-control", styles["modal-body-input"])}
                  name="tagName"
                  type="text"
                  placeholder="Please input tag name..."
                  value={formik.values.tagName}
                  onChange={(event) => {
                    formik.handleChange(event);
                  }}
                />
                {errorMessage?.tagName && (
                  <span className="error text-danger">
                    {errorMessage?.tagName}
                  </span>
                )}
              </div>
              <div className="mar_b16">
                <label
                  htmlFor="role"
                  className={clsx("d-flex mar_b16", styles["modal-body-label"])}
                >
                  Select Color
                </label>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    {tagColors.map((color, index) => (
                      <label
                        className={styles["radio-color-group"]}
                        key={index}
                        onClick={() => {
                          formik.handleChange(color);
                        }}
                        htmlFor={`${color}-${index}`}
                      >
                        <input
                          className={styles["radio-color"]}
                          name="tagColor"
                          id={`${color}-${index}`}
                          type="radio"
                          value={color}
                          checked={color === formik.values.tagColor}
                          style={{ backgroundColor: color }}
                          onChange={formik.handleChange}
                        />
                        <span
                          className={styles["check-mark"]}
                          style={{ backgroundColor: color }}
                        ></span>
                        {color === formik.values.tagColor && (
                          <span
                            className={styles["border-check-mark"]}
                            style={{ borderColor: color }}
                          ></span>
                        )}
                      </label>
                    ))}
                  </div>
                  <div
                    className={clsx(
                      "d-flex align-items-center",
                      styles["more-color"]
                    )}
                  >
                    <span>More Colors</span>
                    <div className={styles["color-picker-wrapper"]}>
                      <img
                        src="/images/icons/more-color-icon.svg"
                        alt="more-icon"
                        onClick={chooseColor}
                      />
                      <input
                        type="color"
                        id="color-picker"
                        onChange={onHandleColorPicker}
                        className={styles["hidden"]}
                      />
                    </div>
                  </div>
                </div>
                {errorMessage?.tagColor && (
                  <span className="error text-danger">
                    {errorMessage?.tagColor}
                  </span>
                )}
              </div>
              <div className="mar_b16">
                <label htmlFor="role" className={styles["modal-body-label"]}>
                  Select Hotkey
                </label>
                <div className="d-flex align-center">
                  <Button
                    ref={hotKeyButtonRef}
                    name={hotKey || "Click to add Hotkey"}
                    className={
                      hotKey && hotKey !== pressKeyText
                        ? "btn-gray"
                        : hotKey === pressKeyText
                        ? "btn-medium-dashed"
                        : "btn-medium"
                    }
                    handleClick={handleAddHotKey}
                    onKeyDown={keyboardEvents}
                  />
                  {hotKey && hotKey !== pressKeyText && (
                    <img
                      className={clsx(
                        "ms-3 cursor-pointer",
                        styles["modal-re-choose"]
                      )}
                      src="/images/icons/return-icon.svg"
                      alt="Return"
                      onClick={handleAddHotKey}
                    />
                  )}
                </div>
                {errorMessage?.hotKey && (
                  <span className="error text-danger">
                    {errorMessage?.hotKey}
                  </span>
                )}
              </div>
              <div>
                <Checkbox
                  handleClick={formik.handleChange}
                  label="Set as private"
                  name="isPrivate"
                  id="tag-id"
                  size="lg"
                  value={formik.values.isPrivate}
                  isChecked={formik.values.isPrivate}
                />
              </div>
            </Modal.Body>
            <Modal.Footer className={styles["modal-footer"]}>
              <Button name="Cancel" handleClick={handleClose} />
              <Button
                name={
                  type === COMMON_TEXT.create ? "Create New Tag" : "Edit Tag"
                }
                type="submit"
                isBorder={false}
                className="btn-primary-fill"
                isDisabled={
                  (type === COMMON_TEXT.edit && isDisabled()) || isSubmitting
                }
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
};

TagModal.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string.isRequired,
  isShow: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  apiErrorMessageProp: PropTypes.object,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default TagModal;
