import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { get } from "lodash";
import moment from 'moment';
import clsx from "clsx";

// Services
import * as searchTermService from "services/SearchTermService"

// Store
import { 
  setScopeValue,
  resetScopeValue,
  setSearchTermsRunning 
} from "store/SearchTermsReducer";

// Components
import SearchTerms from "components/search-terms/SearchTerms";
import ScopeFilter from "components/scope-filter/ScopeFilter";
import AddSearchModal from "components/shared/add-search-modal/AddSearchModal";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";
import { Button } from "components/shared/button/Button";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";

// Constants
import {
  PAGE_NAME,
  PATH_NAME,
  COMMON_TEXT
} from "constants/Common";
import { breadCrumbSearch } from "constants/BreadCrumbConstants";
import { 
  GetRunSearchTermsToastId,
  RUN_SEARCH_TERMS_TOAST_MSG
} from "constants/SearchTermsConstants";

// Styles
import styles from "./SearchTermsPage.module.scss";

const SearchPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoBack = () => navigate(`/${PATH_NAME.matters}`);

  const [showAddSearchModal, setShowAddSearchModal] = useState(false);
  const [gridActionFiring, setGridActionFiring] = useState(false);

  const [searchTerms, setSearchTerms] = useState(null);
  const [searchTermId, setSearchTermId] = useState(null);
  const [updatedSearchTerm, setUpdatedSearchTerm] = useState(null);
  const [previousSearchTerm, setPreviousSearchTerm] = useState(null);

  const [showConfirmEditPopup, setShowConfirmEditPopup] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

  const onShowConfirmEditPopup = () => setShowConfirmEditPopup(true);
  const onHideConfirmEditPopup = () => setShowConfirmEditPopup(false);

  const onShowConfirmDeletePopup = () => setShowConfirmDeletePopup(true);
  const onHideConfirmDeletePopup = () => setShowConfirmDeletePopup(false);

  const {
    searchTermsRunning = {}
  } = useSelector((state) => state.searchTerms);

  const allTermsComplete = searchTerms && searchTerms.every(term => term.status === 'Completed');
  const normalizeStatus = (status) => {
    const statusMapping = {
      'Outdated': 'Out of Date',
      'Added': 'Not Ran',
      'Executing': 'Executing',
      'Completed': 'Completed',
      'Failed': 'Failed',
      'Deleted': 'Marked for Deletion',
      'Updated' : 'Marked for Update',
    };
  
    return statusMapping[status] || status;
  };
  
  const formatStatus = (searchTerms) => {
    searchTerms.forEach(term => {
      term.status = normalizeStatus(term.status);
    });
  };

  const convertAndFormatDateTime = (searchTerms) => {
    searchTerms.forEach(term => {
      if (term.lastRan !== null) {
        const utcLastRan = moment.utc(term.lastRan);
        term.lastRan = utcLastRan.local().format('MM/DD/YYYY h:mm A');
      }
    });
  };

  const fetchSearchTerms = async () => {
    setGridActionFiring(true);

    try {
      setSearchTerms(null);
      const response = await searchTermService.GetSearchTerms(projectId);
      let searchTerms = response.data;

      convertAndFormatDateTime(searchTerms);
      formatStatus(searchTerms);

      setSearchTerms(searchTerms);
    } catch (error) {
      toast.error("Failed to retrieve search terms.");
      setSearchTerms(null);
      console.log(error);
    } finally {
      setGridActionFiring(false);
    }
  };

  const handleSetSearchTerms = async (params) => {
    setGridActionFiring(true);

    try {
      await searchTermService.AddSearchTerms(projectId, params.searchTermItems);
      await fetchSearchTerms();
    } catch (error) {
      toast.error("Search terms failed to save.");
      console.log(error);
    } finally {
      setGridActionFiring(false);
    }
  };

  const handleUpdateSearchTerm = async (searchID, searchTerm) => {
    setGridActionFiring(true);

    try {
      await searchTermService.UpdateSearchTerm(projectId, searchID, { searchTerm: searchTerm});
      await fetchSearchTerms();
    } catch (error) {
      toast.error("Failed to edit search term.");
      console.log(error)
    } finally {
      setGridActionFiring(false);
    }
  };

  const handleRunSearchTerms = async () => {
    setGridActionFiring(true);
    dispatch(setSearchTermsRunning(true));

    try {
      setSearchTerms(null);
      var result = await searchTermService.RunSearchTerms(projectId);

      toast.loading(`Running search terms, tagging results...`, {
        autoClose: false,
        toastId: GetRunSearchTermsToastId(result.data.jobId)
      });
    } catch (error) {
      toast.update(GetRunSearchTermsToastId(result.data.jobId), {
        render: RUN_SEARCH_TERMS_TOAST_MSG.APPLY_ERR,
        type: toast.TYPE.ERROR,
        autoClose: false,
      });
      
      console.log(error)
      setGridActionFiring(false);
      dispatch(setSearchTermsRunning(false));
    }
  };

  const handleDeleteSearchTerm = async (searchID) => {
    setGridActionFiring(true);

    try {
      await searchTermService.DeleteSearchTerm(projectId, searchID);
      await fetchSearchTerms();
    } catch (error) {
      toast.error("Failed to delete search term.");
      console.log(error)
    } finally {
      setGridActionFiring(false);
    }
  };

  const handleGetSearchTermScope = async () => {
    try {
      const { data } = await searchTermService.GetSearchTermsScope(projectId);

      dispatch(
        setScopeValue({
            dataSources: get(data, "dataSources", []),
            dataTypes: get(data, "dataTypes", []),
            people: get(data, "people", [])
        })
      );
    } catch (error) {
      toast.error("Failed to retrieve the search terms scope.");
      dispatch(resetScopeValue());
      console.log(error);
    }
  }

  const handleSetSearchTermScope = async (params) => {
    var searchTermsScope = mapSearchTermScopeParameters(params);

    try {
      await searchTermService.UpdateSearchTermsScope(projectId, searchTermsScope);

      dispatch(setScopeValue(searchTermsScope));
    } catch (error) {
      toast.error("Search terms scope failed to save.");
      console.log(error);
    }
  }

  const mapSearchTermScopeParameters = (params) => {
    const searchTermsScope = {
      dataSources: params.dataSources ? params.dataSources.map(item => item.value) : null,
      dataTypes: params.dataTypes ? params.dataTypes.map(item => item.value) : null,
      people: params.entities ? params.entities.map(item => item.value) : null
    };

    return searchTermsScope;
  }

  const handleSearchTermGridAction = async (args) => {
    // Triggers when a cell that is being edited is saved
    if (args.requestType === "save")
    {
      const currentSearchTermObject = searchTerms.find(item => item.searchID === args.data.searchID);
      const newSearchTerm = args.data.requestedTerm;
      const currentSearchId = args.data.searchID;

      if(currentSearchTermObject && currentSearchTermObject.requestedTerm !== newSearchTerm)
      {
        setPreviousSearchTerm(currentSearchTermObject.requestedTerm);
        setSearchTermId(currentSearchId);
        setUpdatedSearchTerm({
          requestedTerm: newSearchTerm
        });

        onShowConfirmEditPopup();
      }
    }
    else if (args.requestType === 'delete')
    {
      args.cancel = true;

      const currentSearchId = args.data[0].searchID;
      setSearchTermId(currentSearchId);

      onShowConfirmDeletePopup();
    }
  }

  const resetSearchTerm = () => {
    const searchTerm = searchTerms.find(item => item.searchID === searchTermId);

    if (searchTerm) {
      searchTerm.requestedTerm = previousSearchTerm;
      setPreviousSearchTerm(null);
    }
  }

  const resetModal = () => {
    setShowAddSearchModal(false);
  };

  const confirmEditPopup = {
    value: 2,
    content: "The previous search term will be overwriten. Are you sure you want to continue?",
    textConfirm: COMMON_TEXT.confirm,
    textReject: COMMON_TEXT.cancel,
    type: "confirm",
    handleSubmit: () => {
        onHideConfirmEditPopup();
        handleUpdateSearchTerm(searchTermId, updatedSearchTerm);
    },
    handleReject: () => {
        onHideConfirmEditPopup();
        resetSearchTerm();
    }
  };

  const confirmDeletePopup = {
    value: 2,
    content: "The selected search term will be permanently deleted. Are you sure you want to continue?",
    textConfirm: COMMON_TEXT.confirm,
    textReject: COMMON_TEXT.cancel,
    type: "confirm",
    handleSubmit: () => {
        onHideConfirmDeletePopup();
        handleDeleteSearchTerm(searchTermId);
    },
    handleReject: () => {
        onHideConfirmDeletePopup();
    }
  };

  useEffect(() => {
    fetchSearchTerms();
  }, [projectId]);

  useEffect(() => {
    if (!searchTermsRunning && gridActionFiring) {
      const fetchData = async () => {
        await fetchSearchTerms();
        setGridActionFiring(false);
      };

      fetchData();
    }
  }, [searchTermsRunning]);

  return (
    <>
      <PopupConfirm
          isShow={showConfirmEditPopup}
          handleClose={() => onHideConfirmEditPopup()}
          handleSubmit={confirmEditPopup.handleSubmit}
          content={confirmEditPopup.content}
          textConfirm={confirmEditPopup.textConfirm}
          type={confirmEditPopup.type}
          textReject={confirmEditPopup.textReject}
          handleReject={confirmEditPopup.handleReject}
      />
      <PopupConfirm
          isShow={showConfirmDeletePopup}
          handleClose={() => onHideConfirmDeletePopup()}
          handleSubmit={confirmDeletePopup.handleSubmit}
          content={confirmDeletePopup.content}
          textConfirm={confirmDeletePopup.textConfirm}
          type={confirmDeletePopup.type}
          textReject={confirmDeletePopup.textReject}
          handleReject={confirmDeletePopup.handleReject}
      />
      <Container fluid>
        <Row className={clsx("main", styles["search-terms-page"])}>
          <div>
            {showAddSearchModal && (
              <AddSearchModal
                isShow={showAddSearchModal}
                onHide={resetModal}
                onSubmit={handleSetSearchTerms}
              />
            )}
            <BreadCrumb goBack={handleGoBack} breadCrumbData={breadCrumbSearch()} />
            <div className={styles["search-dashboard__header"]}>
              <h2 className={styles["search-dashboard__header-title"]}>
                {PAGE_NAME.search}
              </h2>
              <div className={styles["search-dashboard__header-control"]}>
                <div className={styles["search-dashboard__header-control__button2"]}>
                  <Button
                    name="Run Search Terms"
                    className="btn-primary-fill"
                    handleClick={() => handleRunSearchTerms()}
                    disabled={allTermsComplete || searchTermsRunning}
                  />
                </div>
                <Button
                  name="Add Search Terms"
                  iconUrl="/images/plus-icon-white.svg"
                  altIcon="Add Search"
                  className="btn-primary-fill"
                  handleClick={() => setShowAddSearchModal(true)}
                  disabled={searchTermsRunning}
                />
              </div>
            </div>
            <div className={styles["search-terms-container"]}>
              <div className={styles["search-terms-scope-filter"]}>
                <ScopeFilter
                  handleGetScope={async () => {
                    await handleGetSearchTermScope();
                  }}
                  handleSetScope={async (params) => {
                    await handleSetSearchTermScope(params);
                    await fetchSearchTerms();
                  }}
                />
              </div>
              <div className={styles["search-terms"]}>
                <SearchTerms
                  searchTerms={searchTerms}
                  gridActionFiring={gridActionFiring}
                  handleSearchTermGridAction={handleSearchTermGridAction}
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SearchPage;