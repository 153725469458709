import { DATA_TYPE, DATA_TYPE_LABEL } from "constants/DataType";

export const getNewTypeToCallApi = (type) =>
  [DATA_TYPE.wifi].includes(type) ? DATA_TYPE.network : type;

export const getNewTypeByOriginalType = (type) =>
  [DATA_TYPE.network, DATA_TYPE.wifi].includes(type) ? DATA_TYPE.other : type;

export const getNewLabelType = (type) => {
  if ([DATA_TYPE.chat].includes(type)) return DATA_TYPE_LABEL.messagesChat;
  return [DATA_TYPE_LABEL.network, DATA_TYPE.wifi].includes(type)
    ? DATA_TYPE_LABEL.other
    : type;
};
