import axios, { authHeader, getAbortController } from "./ApiConfig";

// Constants & Helpers
import { METHOD_API } from "constants/Common";
import { checkBodyConvertTimeToUTC } from "helpers/ObjectHelper";

const getGeneralReviewQuickSelect = (projectId) =>
  axios.get(`/review/${projectId}/quickSelect`, {
    headers: authHeader(),
    signal: getAbortController().signal,
  });

const getGeneralReviewDetailApi = (path, projectId, isResultId, params = {}) =>
  axios(`/intelligentSearch/${path}/${projectId}/${isResultId}`, {
    headers: authHeader(),
    method: METHOD_API.get,
    params: checkBodyConvertTimeToUTC(params),
    signal: getAbortController().signal,
    timeout: 0,
  });

const getGroupMsgInfo = (projectId, chatId, params = {}) =>
  axios(`/communicationReview/${projectId}/informationGroup/${chatId}`, {
    headers: authHeader(),
    method: METHOD_API.get,
    params: checkBodyConvertTimeToUTC(params),
    signal: getAbortController().signal,
    timeout: 0,
  });

const postGeneralReviewSelectTags = (
  projectId,
  chatID,
  tags,
  instantMessages,
  activeTags
) =>
  axios(`/resultHandler/${projectId}/tag`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: {
      chatID,
      tags,
      instantMessages,
      activeTags,
    },
  });

const postSelectTags = (projectId, params) =>
  axios(`/tag/${projectId}/messageTag`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: params,
    timeout: 0,
  });

const postSelectTagsGeneral = (projectId, dataType, data) =>
  axios(`/tag/${projectId}/${dataType}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: data,
  });

const exportGeneralReviewToExcel = (projectId, paramBody) => {
  paramBody = checkBodyConvertTimeToUTC(paramBody);
  return axios(`/review/${projectId}/exportExcel`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: {
      ...paramBody,
      timeOffset: new Date().getTimezoneOffset(),
    },
    timeout: 0,
    responseType: "blob",
  });
};

export {
  getGeneralReviewQuickSelect,
  postGeneralReviewSelectTags,
  postSelectTags,
  postSelectTagsGeneral,
  exportGeneralReviewToExcel,
  getGeneralReviewDetailApi,
  getGroupMsgInfo,
};
