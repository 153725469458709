import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import EmptyPage from "components/shared/empty-page/EmptyPage";
import { Button } from "components/shared/button/Button";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";

// Styles
import styles from "./IdentifierList.module.scss";

const IdentifierList = ({
  data = [],
  isLoading = false,
  handleSeeSuggestion = () => {},
  handleAddIdentifier = () => {},
  handleDeleteIdentifier = () => {},
}) => {
  const [identifierData, setIdentifierData] = useState([]);
  const [deletedIdentifierList, setDeletedIdentifierList] = useState([]);

  useEffect(() => {
    //Remove identifiers the same value in list removed
    setIdentifierData(
      data.filter((item) => !deletedIdentifierList.includes(item.identifierId))
    );
  }, [data]);

  return (
    <div className={styles["wrap"]}>
      <div className={styles["header"]}>
        <h5>{`Identifiers List ${
          identifierData.length > 0 ? `(${identifierData.length})` : ""
        }`}</h5>
        <Button
          name="Suggest Identifiers"
          className="btn-medium-blue"
          handleClick={() => {
            handleSeeSuggestion();
            setDeletedIdentifierList([]);
          }}
        />
      </div>
      <div className={styles["body"]}>
        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <table>
            <thead>
              <tr>
                <th>Identifiers</th>
                <th>Preferred Name</th>
                <th>Likely Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {identifierData?.length > 0 &&
                identifierData.map((item) => (
                  <tr key={item.identifierId}>
                    <td>
                      <span title={item.identifierId}>
                        <strong>{item.identifierId}</strong>
                      </span>
                    </td>
                    <td>
                      {item.preferredName ? (
                        <span>{item.preferredName}</span>
                      ) : (
                        <span className={styles["likely-name"]}>
                          {item.likelyName}
                        </span>
                      )}
                    </td>
                    <td>{item.likelyName}</td>
                    <td className={styles["action"]}>
                      <img
                        src="/images/add-ic.svg"
                        alt="add"
                        onClick={() =>
                          handleAddIdentifier({
                            value: item.identifierId,
                            socialType: item.socialType,
                          })
                        }
                      />
                      <img
                        src="/images/associated-delete.svg"
                        alt="delete"
                        onClick={() => {
                          handleDeleteIdentifier(item.identifierId);
                          setDeletedIdentifierList([
                            ...deletedIdentifierList,
                            item.identifierId,
                          ]);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
        {!identifierData?.length && !isLoading && (
          <div className={styles["no-result"]}>
            <EmptyPage messages="No suggestions found" size="sm" />
          </div>
        )}
      </div>
    </div>
  );
};

IdentifierList.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      identifierId: PropTypes.string,
      preferredName: PropTypes.string,
      likelyName: PropTypes.string,
      socialType: PropTypes.number,
    })
  ),
  handleSeeSuggestion: PropTypes.func,
  handleAddIdentifier: PropTypes.func,
  handleDeleteIdentifier: PropTypes.func,
};

export default IdentifierList;
