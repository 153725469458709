import React from "react";
import { LinkPreviewAPI } from "components/shared/link-preview-api/LinkPreviewAPI";
import { getListIndexHighlight, subStringSearch } from "./HighLightTextHelper";
import HighLightText from "components/shared/highlight/HighLightText";
import { COLORS, COMMON_TEXT } from "constants/Common";
import {
  FIELD_VALIDATION,
  URL_REGEX,
  URL_REGEX_INSIDE_TAG,
} from "constants/RegexConstant";
import { EMAIL_KEY } from "constants/EmailPreview";

export const replaceAllText = (text, oldWord, newWord) => {
  while (text?.indexOf(oldWord) !== -1) text = text?.replace(oldWord, newWord);
  return text;
};

const getUrlList = (text) => text.replace(URL_REGEX, (url) => `/()/${url}/()/`);

const spaceBeforeRegex = (char) => new RegExp(`[ ]+(?=[${char}])`, "g");
const spaceAfterRegex = (char) => new RegExp(`(?<=[${char}])\\s+`, "g");

// Remove once email includes hit positions
const highlightText = ({
  text,
  key,
  isAllEmoji,
  id,
  listIndex,
  listEmojis,
  highlight,
  index,
  isBold,
}) => {
  let partsUrl = [];
  let result = {};
  let element = <>{}</>;
  const highlights = isAllEmoji
    ? listEmojis
    : highlight
        .replaceAll(
          spaceBeforeRegex(FIELD_VALIDATION.keywordReplaceHighlight),
          ""
        )
        .replaceAll(
          spaceAfterRegex(FIELD_VALIDATION.keywordReplaceHighlight),
          ""
        )
        .split(FIELD_VALIDATION.keywordReplaceHighlight);
  if (text && highlight) {
    const data = subStringSearch(text, highlights, isAllEmoji);
    result = getListIndexHighlight({ data, id, highlights, listIndex, key });
    if (!result) return;
    partsUrl = result.parts;
    listIndex = result.listIndex;
  } else partsUrl.push(text);
  if (partsUrl.length !== 1) {
    element = (
      <span style={{ fontWeight: isBold ? "bold" : "" }}>
        {partsUrl?.map((partUrl, i) => (
          <HighLightText
            key={i}
            highLightIndex={index}
            partIndex={partUrl?.index}
            isAllEmoji={isAllEmoji}
            hightLight={highlight}
            text={partUrl?.text}
          />
        ))}
      </span>
    );
  } else
    element = (
      <span style={{ fontWeight: isBold ? "bold" : "" }}>
        {partsUrl[0]?.text}{" "}
      </span>
    );
  return element;
};

const highlightTextByHitPosition = ({ text, hitPositions, searchTermHitPositions, isBold }) => {
  let parts = [];
  let currentIndex = 0;

  const mergedHitPositions = 
    (hitPositions || []).map(hit => ({ ...hit, isSearchTerm: false }))
      .concat(
        (searchTermHitPositions || [])
          .filter(searchHit => !(hitPositions || []).some(hit => hit.offset === searchHit.offset && hit.length === searchHit.length))
          .map(hit => ({ ...hit, isSearchTerm: true }))
      );

  const sortedHitPositions = mergedHitPositions.toSorted((a, b) => {
    if (a.offset === b.offset)
      return a.isSearchTerm ? -1 : 1; 

    return a.offset - b.offset;
  });

  sortedHitPositions.forEach((hit) => {
    const start = hit.offset;
    const length = hit.length;

    if (currentIndex < start)
      parts.push({ text: text.slice(currentIndex, start), isHighlighted: false });

    parts.push({
      text: text.slice(start, start + length),
      isHighlighted: true,
      highlightColor: hit.isSearchTerm ? "#ffff00" : "#00ffff"
    });

    currentIndex = start + length;
  });

  if (currentIndex < text.length)
    parts.push({ text: text.slice(currentIndex), isHighlighted: false });

  return (
    <span style={{ fontWeight: isBold ? "bold" : "" }}>
      {parts.map((part, i) => (
        <span key={i} style={{ backgroundColor: part.isHighlighted ? part.highlightColor : "transparent" }}>
          {part.text}
        </span>
      ))}
    </span>
  );
};

export const displayTextHighlighted = (param) => {
  if (URL_REGEX.test(param?.text)) {
    const listLinkAndMessage = getUrlList(param?.text).split("/()/");
    return listLinkAndMessage.map((item, indexLink) => {
      const linkResult = item.replace("<", "").replace(">", "");
      
      const highlightItem = param.hitPositions || param.searchTermHitPositions
        ? highlightTextByHitPosition(param)
        : highlightText({
          ...param,
          text: item,
          key: indexLink + Math.max(...Object.values(EMAIL_KEY)),
        });

      if (item.includes("http")) {
        return (
          <div key={indexLink}>
            <a
              className="link-preview"
              target="_blank"
              rel="noopener noreferrer"
              href={linkResult}
            >
              {highlightItem}
            </a>
            <div className="pb-2">
              {param?.showThumbnail && <LinkPreviewAPI url={linkResult} />}
            </div>
          </div>
        );
      }
      return <span key={indexLink}>{highlightItem}</span>;
    });
  }

  return param.hitPositions || param.searchTermHitPositions
    ? highlightTextByHitPosition(param) 
    : highlightText(param);
};

export const urlify = (
  text,
  className,
  hidePreview = false,
  hideText = false
) => {
  let element = <>{text}</>;
  if (URL_REGEX.test(text)) {
    const listLinkAndMessage = getUrlList(text).split("/()/");
    element = (
      <>
        {listLinkAndMessage.map((item, index) => {
          const linkResult = item.replace("<", "").replace(">", "");
          return item.includes("http") ? (
            <div key={index}>
              <a
                className={className}
                target="_blank"
                rel="noopener noreferrer"
                href={linkResult}
                title={item}
              >
                {item}
              </a>
              <div className="pb-2">
                {!hidePreview && <LinkPreviewAPI url={linkResult} />}
              </div>
            </div>
          ) : (
            !hideText && <span key={index}>{item}</span>
          );
        })}
      </>
    );
  }
  return element;
};

export const getDeletedStatus = (value) => {
  switch (value?.toLowerCase()) {
    case COMMON_TEXT.intact:
      return COMMON_TEXT.no;
    case COMMON_TEXT.deleted:
      return COMMON_TEXT.yes;
    default:
      return COMMON_TEXT.unknown;
  }
};

export const checkAndReplaceTagEquivalent = (text) => {
  const newTxt = text
    .replace(/<((?!a )[^>]*)>/g, "&lt;$1&gt;")
    .replace(/&lt;\/a&gt;/g, "</a>");
  return newTxt;
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const splitWordSearch = (inputWord = "", isSecondSearch) => {
  const data = inputWord
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll(spaceBeforeRegex("&|"), "")
    .replaceAll(spaceAfterRegex("&|"), "")
    .replaceAll("|", ";")
    .replaceAll("&", ";");
  return isSecondSearch ? data : data.replaceAll(`"`, "");
};

export const isHtmlTag = (str = "") => {
  const htmlTag = ["<", ">", "&lt;", "&gt;"];
  return htmlTag.filter((item) => str?.includes(item)).length > 0;
};

export const renderPercent = (value) => value * 100 + COMMON_TEXT.percent;

export const convertRTFToPlain = (rtf) => {
  rtf = rtf.replace(/\\par[d]?/g, "");
  return rtf
    .replace(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")
    .trim();
};

const articleWords = [
  "on",
  "at",
  "in",
  "off",
  "of",
  "for",
  "to",
  "the",
  "a",
  "is",
  "are",
];

export const injectLinkToHtml = (str) => {
  str = str.replaceAll("&amp;lt;", "<br><").replaceAll("&amp;gt;", "><br>");
  let text = "";
  if (URL_REGEX_INSIDE_TAG.test(str)) {
    const listLinkAndEmail = getUrlList(str).split("/()/");
    listLinkAndEmail.forEach((item) => {
      if (item.includes("http") && !item.includes(`"`)) {
        text += `<br>
                  <div class="link-preview-default"> 
                      <a target="_blank" href ="${item}" style="color:${COLORS.blue}" > ${item} </a>
                  </div> 
                 <br>`;
      } else text += item;
    });
  } else text += str;
  return text;
};

export const detectTextInHtml = (str = "") => {
  return str
    ?.replace(/(<style[\w\W]+style>)/g, "")
    .replace(/(<([^>]+)>)/gi, "");
};

const noBreadSpace = "&#xa0;";

export const countRepeatedWords = (sentence) => {
  let words = detectTextInHtml(sentence)
    .replaceAll(",", " ")
    .replaceAll(".", " ")
    .split(" ");
  let wordMap = {};
  words.forEach((word) => {
    if (
      !articleWords.includes(word) &&
      !word.includes(noBreadSpace) &&
      word.length > 1
    ) {
      let currentWordCount = wordMap[word];
      let count = currentWordCount ? currentWordCount : 0;
      wordMap[word] = count + 1;
    }
  });
  return wordMap;
};

export const filterWords = (wordMap) => {
  const WORDS_MAX = 25;
  const data = Object.entries(wordMap)
    .map((item) => ({ value: item[0], count: item[1] }))
    .sort((a, b) => b.count - a.count);
  return data.length <= WORDS_MAX ? data : data.slice(0, WORDS_MAX);
};
