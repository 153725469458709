import React from "react";
import PropTypes from "prop-types";

// Components
import PreviewNotAvailable from "../preview-not-available/PreviewNotAvailable";
import { LinkPreviewAPI } from "components/shared/link-preview-api/LinkPreviewAPI";

// Styles
import styles from "./PreviewHistoryLink.module.scss";

const PreviewHistoryLink = ({ url }) => {
  return url ? (
    <div className={styles["history-link-wrap"]}>
      <a
        className={styles["history-link-content"]}
        target="_blank"
        rel="noopener noreferrer"
        href={url}
        title={url}
      >
        {url}
      </a>
      <div className={styles["history-link-preview"]}>
        <LinkPreviewAPI url={url} isSmall={false} />
      </div>
    </div>
  ) : (
    <PreviewNotAvailable message="The item is not available. Please try later." />
  );
};

PreviewHistoryLink.propTypes = {
  url: PropTypes.string,
};

export default PreviewHistoryLink;
