import React, { useState } from "react";
import { Form, Formik } from "formik";
import { BeatLoader } from "react-spinners";

// Services
import { createProjectDBApi } from "services/StreemViewConfigServices";

// Components
import FieldInput from "components/shared/field/field-input/FieldInput";
import { Button } from "components/shared/button/Button";
import { COLORS } from "constants/Common";

// Styles
import styles from "./CreateProjectDatabase.module.scss";

const CreateProjectDatabase = () => {

  const [loading, setLoading] = useState(false);

  const handleCreateProjectDb = async (projectId) => {
    setLoading(true);
    try {
      await createProjectDBApi(projectId);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles["wrap"]}>
      <h3>Create Project DB</h3>
      <Formik
        initialValues={{ projectId: "" }}
        onSubmit={(values) => handleCreateProjectDb(values.projectId)}
      >
        {(form) => (
          <Form className={styles["form"]}>
            <FieldInput
              label="Project ID"
              name="projectId"
              placeHolder="Project ID"
              required
            />
            <div className={styles["footer"]}>
              {loading ? (
                <BeatLoader color={COLORS.black} loading={true} size={8} />
              ) : (
                <Button
                  name="Create Project DB"
                  className="btn-primary-fill"
                  handleClick={form.handleSubmit}
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreateProjectDatabase.propTypes = {};

export default CreateProjectDatabase;
