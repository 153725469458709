import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useToken } from "hook/auth";
import clone from "lodash/clone";
import clsx from "clsx";

// APIs
import {
  createTag,
  deleteTag,
  getManageTag,
  updateAllTagsManagement,
  updateTag,
} from "services/TagManagementService";

// Components
import { Button } from "components/shared/button/Button";
import EmptyPage from "components/shared/empty-page/EmptyPage";
import TagModal from "components/tag-management/tag-modal/TagModal";
import { PopupConfirm } from "components/shared/popup/PopupConfirm";
import TagItem from "components/tag-management/tag-item/TagItem";
import SearchInputGeneral from "components/shared/search-input/search-input-general/SearchInputGeneral";
import BreadCrumb from "components/shared/bread-crumb/BreadCrumb";

// Constants
import { TAG_VALIDATION } from "constants/Validations";
import {
  COMMON_TEXT,
  EXPORT_TAG,
  MAIN_TAG_ID_LIST,
  PATH_NAME,
} from "constants/Common";
import { RESPONSE_STATUS } from "constants/StatusCodeConstant";
import { breadCrumbTagManagement } from "constants/BreadCrumbConstants";

// React DND
import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Styles
import styles from "./TagManagement.module.scss";

const TagManagement = () => {
  const navigate = useNavigate();
  const inputRef = useRef();
  const searchInputRef = useRef();
  const { projectId } = useParams();
  const { isUser, isClientAdmin } = useToken();

  const tagError = {
    nameExist: "name exist",
    colorExist: "color exist",
    hotKeyExist: "hotkey exist",
  };

  const [tagData, setTagData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [loadingSelectedTag, setLoadingSelectedTag] = useState(false);
  const [loadingAvailableTag, setLoadingAvailableTag] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [checkAvailableTag, setCheckAvailableTag] = useState([]);
  const [checkSelectedTag, setCheckSelectedTag] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [reloading, setReloading] = useState(true);
  const [minIndexAvailable, setMinIndexAvailable] = useState(0);
  const [minIndexSelected, setMinIndexSelected] = useState(0);
  const [availableData, setAvailableData] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [modalType, setModalType] = useState(COMMON_TEXT.create);
  const [dataEdit, setDataEdit] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  const [isSearching, setIsSearching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const reRender = () => setReloading(!reloading);

  //fetch data tags form api
  const fetchManageTag = async () => {
    setLoadingSelectedTag(true);
    setLoadingAvailableTag(true);

    const params = {
      ProjectId: projectId,
      SearchInput: inputRef.current.value?.trim() || "",
    };

    try {
      const result = await getManageTag(params);
      if (!result || !result.data) return;
      setTagData(clone(result.data.tags));
      setOriginalData(clone(result.data.tags));
      setSuggestions(result.data.selectName);
      !params.SearchInput && handleGetMinIndex(result.data.tags);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSelectedTag(false);
      setLoadingAvailableTag(false);
    }
  };

  //handle data when checked available tag
  const handleCheckAvailableTag = (tagID) => {
    if (checkAvailableTag.includes(tagID)) {
      setCheckAvailableTag((prevState) =>
        prevState.filter((id) => id !== tagID)
      );
    } else {
      setCheckAvailableTag([...checkAvailableTag, tagID]);
    }
  };

  //Handle move tags from selected list to available list
  const handleMoveTag = (type) => {
    const newTagData = [];
    if (type === COMMON_TEXT.selected) {
      tagData.forEach((tag) => {
        if (checkAvailableTag.includes(tag.tagID)) {
          newTagData.unshift({
            ...tag,
            status: true,
            zIndex: minIndexSelected - 1 - checkAvailableTag.indexOf(tag.tagID),
          });
        } else newTagData.push(tag);
      });
      setCheckAvailableTag([]);
    } else {
      tagData.forEach((tag) => {
        if (checkSelectedTag.includes(tag.tagID)) {
          newTagData.unshift({
            ...tag,
            status: false,
            zIndex: minIndexAvailable - 1 - checkSelectedTag.indexOf(tag.tagID),
          });
        } else newTagData.push(tag);
      });
      setCheckSelectedTag([]);
    }
    handleGetMinIndex(newTagData);
    setTagData(clone(newTagData));
  };

  //handle data when checked selected tag
  const handleCheckSelectedTag = (tagID) => {
    if (checkSelectedTag.includes(tagID))
      setCheckSelectedTag((prevState) =>
        prevState.filter((id) => id !== tagID)
      );
    else setCheckSelectedTag([...checkSelectedTag, tagID]);
  };

  //Handle move all tags from Available to Selected and vice versa
  const handleMoveAllTags = (type = "") => {
    const newTagsNeedMove = [];
    if (type === COMMON_TEXT.available) {
      availableData.forEach((tag, index) =>
        newTagsNeedMove.push({
          ...tag,
          status: true,
          zIndex: minIndexSelected - 1 - index,
        })
      );
      setCheckAvailableTag([]);
      setTagData([...newTagsNeedMove, ...selectedData]);
      return;
    }
    selectedData.forEach((tag, index) =>
      newTagsNeedMove.push({
        ...tag,
        status: false,
        zIndex: minIndexAvailable - 1 - index,
      })
    );
    setCheckSelectedTag([]);
    setTagData([...newTagsNeedMove, ...availableData]);
  };

  //handle delete tag
  const onHandleDeleteTag = async () => {
    setShowConfirmDelete(false);
    const tagIDs = checkAvailableTag.concat(checkSelectedTag);
    const params = {
      projectId: projectId,
      tagIDs,
    };
    try {
      const response = await deleteTag(params);
      if (response.status === RESPONSE_STATUS.success) {
        setCheckAvailableTag([]);
        setCheckSelectedTag([]);
        // Update tag newest after delete tag
        const tagNewest = tagData.filter(
          (item) => !tagIDs.includes(item.tagID)
        );
        setTagData(tagNewest);
        setOriginalData(tagNewest);
        toast.success(response.data);
      }
    } catch (error) {
      console.log(error);
      toast.error("Fail, try again, please!");
    }
  };

  const handleSubmitTag = async (data) => {
    setIsSubmitting(true);
    setErrorMessage({});
    const { tagName, tagColor, type, hotKey } = data;
    let params = {
      name: tagName,
      color: tagColor,
      hotKey,
      type,
      projectId: projectId,
    };
    if (modalType === COMMON_TEXT.create) {
      try {
        const response = await createTag(params);
        if (!response || !response.data) return;
        setShowPopup(false);
        setTagData([response.data, ...tagData]);
        toast.success("Add new tag successfully!");
      } catch (error) {
        handleSetError(error.response.data);
      } finally {
        setIsSubmitting(false);
      }
      return;
    }
    params = { ...params, zIndex: data.zIndex };
    try {
      const response = await updateTag(params, dataEdit.tagID);
      if (!response || !response.data) return;
      const newTagData = [];
      const tagOriginal = originalData.find(
        (tag) => tag.tagID === response.data.tagID
      );

      tagData.forEach((item) => {
        if (item.tagID === response.data.tagID)
          newTagData.push({
            ...tagOriginal,
            ...response.data,
          });
        else newTagData.push(item);
      });
      setTagData(clone(newTagData));
      setShowPopup(false);
      toast.success("Edit tag successfully!");
    } catch (error) {
      handleSetError(error.response.data);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSetError = (response) => {
    let apiError = {};
    if (response.includes(tagError.nameExist))
      apiError = { ...apiError, tagName: TAG_VALIDATION.tagName.exist };
    if (response.includes(tagError.colorExist))
      apiError = { ...apiError, tagColor: TAG_VALIDATION.color.exist };
    if (response.includes(tagError.hotKeyExist))
      apiError = { ...apiError, hotKey: TAG_VALIDATION.hotKey.exist };
    setErrorMessage(apiError);
  };

  // Handle order zIndex Tags List
  const handleOrderTagsList = (tagsList, type) => {
    if (!tagsList || tagsList.length === 0) return;
    const minIndex = Math.min(...tagsList.map((item) => item.zIndex));
    const newTagsList = [
      ...tagsList.map((tag, index) => {
        return { ...tag, zIndex: minIndex + index };
      }),
    ];
    if (type === COMMON_TEXT.active) {
      setSelectedData(clone(newTagsList));
      setTagData([...newTagsList, ...availableData]);
      return;
    }
    setAvailableData(clone(newTagsList));
    setTagData([...selectedData, ...newTagsList]);
  };

  //Handle order zIndex Original List
  const handleOrderOriginalTags = () => {
    if (!originalData || originalData.length === 0) return;
    let availableTags = clone(originalData.filter((tag) => !tag.status));
    let selectTags = clone(originalData.filter((tag) => tag.status));
    if (availableTags.length > 0) {
      const minIndex = Math.min(...availableTags.map((item) => item.zIndex));
      availableTags = [
        ...availableTags.map((tag, index) => {
          return { ...tag, zIndex: minIndex + index };
        }),
      ];
    }
    if (selectTags.length > 0) {
      const minIndex = Math.min(...selectTags.map((item) => item.zIndex));
      selectTags = [
        ...selectTags.map((tag, index) => {
          return { ...tag, zIndex: minIndex + index };
        }),
      ];
    }
    setOriginalData([...selectTags, ...availableTags]);
  };

  //Order tag list with zIndex
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  //Handle drag completed
  const onDragEnd = (result, type) => {
    if (!result.destination || result.destination.index === result.source.index)
      return;
    const items = reorder(
      type === COMMON_TEXT.active ? selectedData : availableData,
      result.source.index,
      result.destination.index
    );
    type === COMMON_TEXT.active
      ? setSelectedData(clone(items))
      : setAvailableData(clone(items));
  };

  const updateTags = async () => {
    setLoadingAvailableTag(true);
    setLoadingSelectedTag(true);
    const data = { tags: tagData, projectId: projectId };
    const params = {
      ProjectId: projectId,
      SearchInput: "",
    };
    try {
      await updateAllTagsManagement(data);
      setOriginalData(clone(tagData));
      getManageTag(params).then((response) => {
        handleGetMinIndex(response.data.tags);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingAvailableTag(false);
      setLoadingSelectedTag(false);
    }
  };

  const handleShowTagModal = (type, data = null) => {
    setErrorMessage({});
    setDataEdit(data);
    setShowPopup(true);
    setModalType(type);
  };

  //Handle check function with each role
  const handleDisabledWithRole = () => {
    let selectedTags = [...checkAvailableTag, ...checkSelectedTag];
    if (selectedTags.includes(EXPORT_TAG.id)) return true;
    if (isUser()) {
      let haveGlobalTag = tagData?.some(
        (tag) =>
          tag.type === COMMON_TEXT.global && selectedTags.includes(tag.tagID)
      );
      return haveGlobalTag;
    } else if (isClientAdmin()) {
      let haveGlobalSuperTag = tagData?.some(
        (tag) =>
          tag.type === COMMON_TEXT.global &&
          selectedTags.includes(tag.tagID) &&
          tag.accountID?.slice(-3) === COMMON_TEXT.admin
      );
      return haveGlobalSuperTag;
    }
    return false;
  };

  //Handle get min index
  const handleGetMinIndex = (tagList) => {
    let availableTags = clone(tagList.filter((tag) => !tag.status));
    let selectedTags = clone(tagList.filter((tag) => tag.status));
    setMinIndexAvailable(
      availableTags.length > 0
        ? Math.min(...availableTags.map((item) => item.zIndex))
        : 0
    );
    setMinIndexSelected(
      selectedTags.length > 0
        ? Math.min(...selectedTags.map((item) => item.zIndex))
        : 0
    );
  };

  const getMainTagsChecked = () => {
    const allTags = [
      ...checkSelectedTag,
      ...checkAvailableTag,
      ...MAIN_TAG_ID_LIST,
    ];
    return allTags.filter((item, index) => index !== allTags.indexOf(item));
  };

  useEffect(() => {
    fetchManageTag();
  }, [projectId, reloading]);

  useEffect(() => {
    setAvailableData(clone(tagData.filter((tag) => !tag.status)));
    setSelectedData(clone(tagData.filter((tag) => tag.status)));
  }, [JSON.stringify(tagData)]);

  useEffect(() => {
    handleOrderOriginalTags();
  }, [JSON.stringify(originalData)]);

  useEffect(() => {
    handleOrderTagsList(selectedData, COMMON_TEXT.active);
  }, [JSON.stringify(selectedData)]);

  useEffect(() => {
    handleOrderTagsList(availableData, "");
  }, [JSON.stringify(availableData)]);

  return (
    <div className={clsx(styles["tag-management-page"], "main")}>
      {showPopup && (
        <TagModal
          data={dataEdit}
          handleClose={() => setShowPopup(false)}
          isShow={showPopup}
          type={modalType}
          apiErrorMessageProp={errorMessage}
          onClearErrorMessage={() => setErrorMessage({})}
          handleSubmit={handleSubmitTag}
          isSubmitting={isSubmitting}
        />
      )}
      <PopupConfirm
        isShow={showConfirmDelete}
        handleClose={() => setShowConfirmDelete(false)}
        handleSubmit={onHandleDeleteTag}
        content="Are you sure you want to delete this tag?"
        textConfirm="Remove"
        type="remove"
      />
      <BreadCrumb
        goBack={() => navigate(`/${PATH_NAME.matters}`)}
        breadCrumbData={breadCrumbTagManagement}
      />
      <div className={styles["tag-header"]}>
        <h2 className={styles["tag-header-title"]}>Tag Management</h2>
      </div>
      <div className={styles["tag-management-body"]}>
        <div className="d-flex justify-content-end">
          <SearchInputGeneral
            placeholder="Search Tags"
            inputRef={inputRef}
            ref={searchInputRef}
            name="search"
            onSubmitSearch={() => {
              reRender();
              setIsSearching(true);
            }}
            suggestions={suggestions}
            isAutoComplete={true}
            handleClearSearch={() => {
              setIsSearching(false);
              reRender();
            }}
          />
        </div>
        <div
          className={clsx(
            "d-flex justify-content-between",
            styles["tags-table"]
          )}
        >
          <div className={styles["available-tags"]}>
            <h3>Available Tags</h3>
            <div className={styles["selected-tags-content"]}>
              {loadingAvailableTag ? (
                <Spinner
                  className={styles["loading"]}
                  animation="border"
                  variant="primary"
                />
              ) : availableData?.length ? (
                <DragDropContext onDragEnd={(e) => onDragEnd(e, "")}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {availableData.map((item, index) => (
                          <TagItem
                            key={index}
                            index={index}
                            data={item}
                            checkSelectedTags={checkAvailableTag}
                            onCheckTagItem={handleCheckAvailableTag}
                            onEditTag={() =>
                              handleShowTagModal(COMMON_TEXT.edit, item)
                            }
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className={styles["no-result"]}>
                  <EmptyPage
                    size="md"
                    messages={
                      isSearching
                        ? "No results found. Please try again."
                        : "Don't have any tags available."
                    }
                  />
                </div>
              )}
            </div>
            <Button
              name="Create New Tag"
              handleClick={() => handleShowTagModal(COMMON_TEXT.create)}
            />
          </div>
          <div className={styles["tags-option"]}>
            <div className={styles["tags-option-icon"]}>
              <Button
                iconUrl="/images/icons/arrow-right.svg"
                altIcon="arrow"
                className="btn-icon"
                isDisabled={!checkAvailableTag.length}
                handleClick={() => handleMoveTag(COMMON_TEXT.selected)}
              />
              <Button
                iconUrl="/images/icons/arrow-left.svg"
                altIcon="arrow"
                className="btn-icon"
                isDisabled={!checkSelectedTag.length}
                handleClick={() => handleMoveTag(COMMON_TEXT.available)}
              />
            </div>
            <div className={styles["tags-option-icon"]}>
              <Button
                iconUrl="/images/icons/arrow-right-all.svg"
                altIcon="arrow-all"
                className="btn-icon"
                isDisabled={
                  loadingAvailableTag ||
                  loadingSelectedTag ||
                  !availableData.length
                }
                handleClick={() => handleMoveAllTags(COMMON_TEXT.available)}
              />
              <Button
                iconUrl="/images/icons/arrow-left-all.svg"
                altIcon="arrow-all"
                className="btn-icon"
                isDisabled={
                  loadingAvailableTag ||
                  loadingSelectedTag ||
                  !selectedData.length
                }
                handleClick={handleMoveAllTags}
              />
            </div>
            <div className={styles["tags-option-icon"]}>
              <Button
                iconUrl="/images/icons/trash-blue-icon.svg"
                altIcon="trash-icon"
                className="btn-icon"
                handleClick={() => setShowConfirmDelete(true)}
                isDisabled={
                  !!(!checkAvailableTag?.length && !checkSelectedTag?.length) ||
                  handleDisabledWithRole() ||
                  getMainTagsChecked().length > 0
                }
              />
            </div>
          </div>
          <div className={styles["selected-tags"]}>
            <h3>Selected Tags</h3>
            <div className={styles["selected-tags-content"]}>
              {loadingSelectedTag ? (
                <Spinner
                  className={styles["loading"]}
                  animation="border"
                  variant="primary"
                />
              ) : selectedData?.length ? (
                <DragDropContext
                  onDragEnd={(event) => onDragEnd(event, COMMON_TEXT.active)}
                >
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {selectedData.map((item, index) => (
                          <TagItem
                            key={index}
                            data={item}
                            index={index}
                            type={COMMON_TEXT.active}
                            checkSelectedTags={checkSelectedTag}
                            onCheckTagItem={handleCheckSelectedTag}
                            onEditTag={() =>
                              handleShowTagModal(COMMON_TEXT.edit, item)
                            }
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className={styles["no-result"]}>
                  <EmptyPage
                    size="md"
                    messages={
                      isSearching
                        ? "No results found. Please try again."
                        : "Don't have any tags selected."
                    }
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-end">
              <Button
                name="Save Changes"
                className="btn-primary-fill"
                isDisabled={
                  JSON.stringify(originalData) === JSON.stringify(tagData)
                }
                handleClick={updateTags}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TagManagement;
