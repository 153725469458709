import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";

export const scopeValueInit = {
  dataSources: [],
  dataTypes: [],
  people: []
};

const initialState = {
  scopeFilter: {
    dataSources: [],
    dataTypes: [],
    people: []
  },
  scopeValue: scopeValueInit,
  searchTermsRunning: false
};

const searchTermsSlice = createSlice({
  name: "searchTerms",
  initialState,
  reducers: {
    setScopeFilter: (state, action) => {
      state.scopeFilter = {
        ...state.scopeFilter,
        ...action.payload,
      };
    },
    setScopeValue: (state, action) => {
      state.scopeValue = isEmpty(action.payload)
        ? scopeValueInit
        : {
            ...state.scopeValue,
            ...action.payload
          };
    },
    setSearchTermsRunning: (state, action) => {
      state.searchTermsRunning = action.payload;
    },
    resetScopeValue: (state) => {
      state.scopeValue = { ...state.scopeValue, ...initialState.scopeValue };
    },
  },
});

export const {
    setScopeFilter,
    setScopeValue,
    setSearchTermsRunning,
    resetScopeValue
} = searchTermsSlice.actions;

export const SearchTermsReducer = searchTermsSlice.reducer;
