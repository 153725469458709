import clsx from "clsx";
import { COLORS, COMMON_TEXT, QUICK_SELECT_ITEM } from "constants/Common";
import { SORT_BY, SOURCE_TYPE_LIST, TAG_ACTION } from "constants/Constants";
import { DATA_TYPE } from "constants/DataType";
import { LOCAL_STORAGE } from "constants/LocalStorage";
import { convertDataSourceInfo } from "./DataSourceHelper";

const renderClassSort = (columnName = "", styles) => {
  let sortStyle = "";
  if (columnName === SORT_BY.desc) sortStyle = SORT_BY.sortDesc;
  if (columnName === SORT_BY.asc) sortStyle = SORT_BY.sortAsc;
  return clsx(styles.sort, styles[sortStyle]);
};

const handleUpdateTag = (oldTag, tagToExecutes, type) => {
  const existingTags = oldTag ?? [];

  let newTags;
  if (type === TAG_ACTION.unTag)
    newTags = existingTags.filter((tag) => !tagToExecutes.includes(tag));
  else
    newTags = [...existingTags, ...tagToExecutes];

  return [...new Set(newTags)];
};

const renderCurrency = (value, location = "en-US") =>
  value ? value.toLocaleString(location) : "0";

const displayLargeBorderDrag = (elementId) => {
  document.getElementById(
    elementId
  ).style.border = `0.2rem dashed ${COLORS.main}`;
};

const displaySmallBorderDrag = (elementId) => {
  document.getElementById(
    elementId
  ).style.border = `0.1rem dashed ${COLORS.main}`;
};

const clearBorderDrag = (elementId) => {
  document.getElementById(elementId).style.border = "unset";
};

const getValueReactSelect = (value, defaultValue, options) => [
  options.find((item) => item.value === value) || defaultValue,
];

const convertFilter = (filters = [], type = "") => {
  return filters.map((item) => {
    if (QUICK_SELECT_ITEM.dataSources.value === type) {
      const {
        dataSourceId = "",
        custodianName = "",
        dataSourceType = "",
      } = item;
      return {
        value: dataSourceId.toString(),
        label: custodianName,
        sourceType: dataSourceType,
      };
    }

    if (QUICK_SELECT_ITEM.tags.value === type) {
      return {
        value: item.tagID,
        label: item.name,
        hotKey: item.hotKey,
      };
    }
    if (
      QUICK_SELECT_ITEM.entities.value === type ||
      QUICK_SELECT_ITEM.participants.value === type
    ) {
      return {
        value: item.id,
        label: item.name,
      };
    }
    // Default return Emojis and Datatype
    return {
      value: item,
      label: item,
    };
  });
};

// Find filter item by value and response labels
const getFilterLabel = (value = "") => {
  if (!value) return;
  const result = Object.values(QUICK_SELECT_ITEM).find(
    (item) => item.value === value
  );
  return { label: result.label || "", labelShort: result.labelShort || "" };
};

// Convert contacts to object
const convertContact = (allContacts = [], contacts = []) => {
  return allContacts.filter((item) => contacts.includes(item.id));
};

// Convert tags to object
const convertTag = (allTag = [], tagIds = []) => {
  const tagsCompare = tagIds.map((item) => Number(item));
  return allTag.filter((item) => tagsCompare.includes(item.tagID));
};

// Convert emojis to object
const convertEmoji = (allEmojis = [], emojis = []) => {
  return allEmojis.filter((item) => emojis.includes(item));
};

// Convert source to object
const convertDataSource = (allSources = [], sources = []) => {
  const sourceCompare = sources.map((item) => Number(item.dataSourceId));
  return allSources.filter((item) => sourceCompare.includes(item.dataSourceId));
};

const getFilterValue = (filters = []) => {
  return filters.map((item) => item.value);
};

const getFilterSourceId = (filters = []) =>
  filters.map((item) => Number(item.dataSourceId));

const convertTypeToObject = (listAll = [], listName = []) =>
  listAll.filter((item) => listName.includes(item.value));

const keepHtmlEntity = (string = "") => string?.replace("&", "&amp;");

const getDataQuery = (queryLength = 0, query = "") =>
  queryLength === 1 ? query?.slice(1, query?.length - 1) : query;

const sortAlphabet = (array) =>
  array.sort((a, b) => a.label.localeCompare(b.label));

const isNumber = (value = "") => (!isNaN(Number(value)) ? true : false);

const getSourceIconByName = (sourceName = "", defaultIcon = "") => {
  const source = SOURCE_TYPE_LIST.find((item) =>
    sourceName?.toLowerCase().includes(item.name?.toLowerCase())
  );
  return source
    ? `/images/icons/${source.icon}.svg`
    : `/images/icons/${defaultIcon}.svg`;
};

const getSourceIconById = (array = [], sourceId = 0) => {
  const source = array.find((item) => item.id === sourceId);
  return source
    ? `/images/icons/${source.icon}.svg`
    : "/images/icons/other.svg";
};

const convertStringEnter = (string = "") => string?.replace("\n", "\\n");

// Get type from when navigate from heatmap or communication analysis to review page
const getTypeNavigateToReview = (types = []) => {
  let newTypes = [];
  types.forEach((item) => {
    if (item === DATA_TYPE.email) newTypes = [...newTypes, DATA_TYPE.email];
    if ([DATA_TYPE.smsMms, DATA_TYPE.iMessage, DATA_TYPE.chat].includes(item))
      newTypes = [...newTypes, DATA_TYPE.chat];
  });
  if (newTypes.length === 0) newTypes = [DATA_TYPE.chat, DATA_TYPE.email];
  newTypes = [...new Set(newTypes)];
  return newTypes;
};

const setPathToLocalStorage = (path) => {
  if (path) localStorage.setItem(LOCAL_STORAGE.previousPath, path);
};

const getTypeIconSrc = (array = [], sourceType = "") => {
  const item = array.find(
    (item) => item.name?.toLowerCase() === sourceType?.toLowerCase()
  );
  return item?.icon ? `/images/icons/${item.icon}.svg` : "";
};

const removeQueryStringURL = (url = "") => {
  const urlObj = new URL(url);
  urlObj.search = "";
  return urlObj.toString();
};

const getDataSourceNameFromID = (dataSourceList = [], listSourceId = []) => {
  const data =
    dataSourceList?.filter((item) =>
      listSourceId.includes(item.dataSourceID.toString())
    ) || [];
  return data.length > 0
    ? data
        .map((item) => `${item.deviceName} - ${item.custodianName}`)
        .join("; ")
    : COMMON_TEXT.default;
};

const formatListDataSource = (dataSourceList = [], listSourceId = []) => {
  return convertDataSourceInfo(dataSourceList, listSourceId).map((item) => ({
    custodianName: `${item.deviceName} - ${item.custodianName}`,
    dataSourceId: item.dataSourceID,
    dataSourceType: item.dataSourceTypeName,
  }));
};

export {
  renderClassSort,
  handleUpdateTag,
  renderCurrency,
  displayLargeBorderDrag,
  displaySmallBorderDrag,
  getValueReactSelect,
  convertFilter,
  getFilterLabel,
  convertContact,
  convertTag,
  getFilterValue,
  convertEmoji,
  convertDataSource,
  convertTypeToObject,
  keepHtmlEntity,
  getFilterSourceId,
  getDataQuery,
  isNumber,
  sortAlphabet,
  getSourceIconByName,
  getSourceIconById,
  convertStringEnter,
  getTypeNavigateToReview,
  setPathToLocalStorage,
  getTypeIconSrc,
  removeQueryStringURL,
  getDataSourceNameFromID,
  clearBorderDrag,
  formatListDataSource
};
