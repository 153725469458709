export const isPopupClipped = (popupElement) => {
  const popupRect = popupElement.getBoundingClientRect();
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  const screenHeight =
    window.innerHeight || document.documentElement.clientHeight;

  if (
    popupRect.top < 0 ||
    popupRect.left < 0 ||
    popupRect.right > screenWidth ||
    popupRect.bottom > screenHeight
  ) {
    // Popup is clipped by the screen
    return true;
  }

  // Popup is fully visible
  return false;
};
