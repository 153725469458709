import React from "react";

const NoProjectsFounded = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100">
      <div>
        <div className="not-found-image">
          <img src="/images/image-notfound.png" alt="not found" />
        </div>
        <p className="project-no-results">
          No results found. Please try again.
        </p>
      </div>
    </div>
  );
};

NoProjectsFounded.displayName = "No Projects Founded";

export default NoProjectsFounded;
