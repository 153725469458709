import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import _shuffle from "lodash/shuffle";
import clsx from "clsx";

//Constants
import { PIE_COLORS } from "constants/Constants";

// Styles
import styles from "./WordCloud.module.scss";

const WordCloud = ({
  data,
  isLoading,
  onClick,
  isTable,
  listConversation = [],
}) => {
  const [words, setWords] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fontSizes = Array.from({ length: 25 }, (x, i) => i + 20).reverse();
    const fontWeights = ["700", "600", "500", "400", "300"];
    const wordArr = [];
    for (let i = 0; i < data?.length; i++) {
      wordArr.push({
        value: data[i].value,
        style: {
          color: PIE_COLORS[Math.floor(Math.random() * PIE_COLORS.length)],
          fontSize: `${fontSizes[i] / 10}rem`,
          fontWeight: fontWeights[parseInt(i / 5)],
        },
      });
    }
    setWords(_shuffle(wordArr));
  }, [data]);

  return (
    <div
      className={clsx(
        styles["container"],
        isTable && styles["container-table-view"]
      )}
    >
      <div className={styles["word-cloud-label"]}>
        <p className={styles["title-cloud"]}>Word Cloud</p>
        <img
          src="/images/icons/collapse-icon.svg"
          alt="collapse-icon"
          className={clsx(
            "cursor-pointer",
            styles[isOpen ? "collapse-icon" : "expand-icon"]
          )}
          onClick={handleToggle}
        />
      </div>
      {isOpen && (
        <>
          {!isLoading ? (
            <div className={styles["word-cloud-list"]}>
              {listConversation?.length > 0 && words?.length > 0 ? (
                words.map((word, wordIndex) => (
                  <a
                    key={wordIndex}
                    style={word?.style}
                    className={styles["word"]}
                    onClick={() => onClick(word?.value)}
                  >
                    {word?.value}
                  </a>
                ))
              ) : (
                <p className={styles["no-result"]}>No words found.</p>
              )}
            </div>
          ) : (
            <div className={styles["spinner-container"]}>
              <Spinner
                className={styles["spinner-loading"]}
                animation="border"
                variant="primary"
                role="status"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

WordCloud.propTypes = {
  isLoading: PropTypes.bool,
  isTable: PropTypes.bool,
  data: PropTypes.array,
  listConversation: PropTypes.array,
  onClick: PropTypes.func,
};

export default WordCloud;
