import axios, { authHeader } from "./ApiConfig";
import { checkBodyConvertTimeToUTC } from "helpers/ObjectHelper";
import { METHOD_API } from "constants/Common";

const getHeatMapQuickSelect = (projectId) =>
  axios.get(`/heatmap/${projectId}/quickSelect`, {
    headers: authHeader(),
  });

const getHeatMapApi = (ProjectId, data) =>
  axios(`/heatmap/${ProjectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: checkBodyConvertTimeToUTC(data),
    timeout: 360000
  });

const exportHeatMapToExcelApi = (ProjectId, data) => {
  data = checkBodyConvertTimeToUTC(data);
  return axios(`/heatmap/${ProjectId}/exportExcel`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data,
    timeout: 360000,
    responseType: "blob",
  });
};

export { getHeatMapQuickSelect, getHeatMapApi, exportHeatMapToExcelApi };
