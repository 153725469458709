export const scrollEmojisTopInItself = () => {
  const emojisWrap = document.getElementsByClassName(
    "rct-node-expanded emojis"
  );
  if (emojisWrap.length > 0) emojisWrap[0].children[1].scrollTop = 0;
};

export const scrollElementToBottom = (id) => {
  const element = document.getElementById(id);
  if (!element) return;
  element.scrollTop = element.scrollHeight;
};

export const scrollElementToTop = (id) => {
  const element = document.getElementById(id);
  if (!element) return;
  element.scrollTop = 0;
};
