import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";

// Stores
import {
  resetStoreReview,
  setParamSearchReview,
} from "store/GeneralReviewReducer";
import { sourceDetailInit } from "store/DataSourceReducer";

// Constants
import { DATA_SOURCE_TYPES, DATE_TIME_TYPE } from "constants/Constants";
import { COMMON_TEXT, PATH_NAME } from "constants/Common";
import { JOB_CATEGORY } from "constants/DataSourceConstant";

//Components
import { Button } from "components/shared/button/Button";
import ItemResultInline from "components/shared/item-result-inline/ItemResultInline";
import ViewAppDetail from "../view-app-detail/ViewAppDetail";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import StatusView from "components/shared/status-view/StatusView";
import EmptyPage from "components/shared/empty-page/EmptyPage";

// Hook
import { useToken } from "hook/auth";

//Helpers
import { formatDateTime } from "helpers/DateTimeFormatterHelper";
import { toThousandsNumber } from "helpers/FormatterHelper";
import { getTypeIconSrc } from "helpers/CommonHelper";

//Styles
import styles from "./DataSourceCard.module.scss";

const DataSourceCard = ({
  isLoading = false,
  sourceDetail = sourceDetailInit,
  getStatusSource = () => {},
  onViewLog = () => {},
  totalRecord = 0,
  disableBtnViewLog = false,
  hiddenBtnViewLog = false,
  onEditSource,
}) => {
  const { isUser } = useToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const {
    importedDate,
    analystName,
    custodianName,
    dataSourceDescription,
    dataSourceType,
    identifier,
    sourceId,
    jobCategory,
    importProcess,
    nativeStatus,
    info: {
      appsInstalled = "[]",
      attachments,
      chat,
      iMessage,
      emailAttachments,
      emails,
      messages,
      photos,
      smsMms,
      videos,
    },
  } = sourceDetail;

  const goToReviewScreen = (source = {}) => {
    const {
      custodianName = "",
      dataSourceId = 0,
      dataSourceType = "",
      dataSourceDescription = "",
    } = source;
    const dataSources = [
      {
        custodianName: `${dataSourceDescription} - ${custodianName}`,
        dataSourceId,
        dataSourceType,
      },
    ];
    dispatch(resetStoreReview());
    dispatch(setParamSearchReview({ dataSources }));
    navigate(`/${PATH_NAME.matters}/${projectId}/${PATH_NAME.streems}`, {
      state: { fromScreen: PATH_NAME.dataSources },
    });
  };

  const INSTALLED_APPS = "Installed apps";

  // show has data or no data, 0
  const formatNumber = (value) =>
    value ? toThousandsNumber(value) : COMMON_TEXT.default;

  // show has time and no time
  const formatDateTimeSource = (timeResult) =>
    timeResult
      ? formatDateTime({
          dateTime: timeResult,
          type: DATE_TIME_TYPE.MM_DD_YYYY,
        })
      : COMMON_TEXT.default;

  const appsInstalledList = appsInstalled ? JSON.parse(appsInstalled) : [];

  const sourceDetailInfo = [
    {
      label: "Added",
      value: formatDateTimeSource(importedDate),
    },
    {
      label: "Analyst",
      value: analystName || COMMON_TEXT.default,
      fwBold: true,
    },
    {
      label: "Message count",
      value: formatNumber(messages),
      fwBold: true,
      children: [
        {
          label: "SMS/MMS",
          value: formatNumber(smsMms),
        },
        {
          label: "Chat",
          value: formatNumber(chat),
        },
        {
          label: "iMessage",
          value: formatNumber(iMessage),
        },
      ],
    },
    {
      label: "Attachment count",
      value: formatNumber(attachments),
      fwBold: true,
    },
    {
      label: "Installed apps",
      value: formatNumber(appsInstalledList.length),
    },
    {
      label: "Photo count",
      value: formatNumber(photos),
      fwBold: true,
    },
    {
      label: "Video count",
      value: formatNumber(videos),
      fwBold: true,
    },
    {
      label: "Email",
      value: formatNumber(emails),
      fwBold: true,
      children: [
        {
          label: "Email attachments",
          value: formatNumber(emailAttachments),
        },
      ],
    },
  ];

  return (
    <div className={styles["wrap"]}>
      <div className={styles["source-item"]}>
        {isLoading ? (
          <SpinnerLoading />
        ) : sourceDetail && totalRecord > 0 ? (
          <Fragment>
            <div className={styles["source-item-header"]}>
              <div className={styles["source-name"]}>
                <div
                  className={clsx(
                    styles["source-info-img"],
                    jobCategory === JOB_CATEGORY.draft.label
                      ? styles["source-info-img-none"]
                      : ""
                  )}
                >
                  <img src="/images/custodian-icon.svg" alt="Custodian" />
                </div>
                <p className={styles["source-info-name"]} title={custodianName}>
                  {custodianName}
                </p>
              </div>
              {!isUser() && (
                <img
                  src="/images/icons/edit-icon-blue.svg"
                  alt="Custodian"
                  onClick={onEditSource}
                />
              )}
            </div>
            <div className={styles["source-item-body"]}>
              <div className={styles["source-device"]}>
                <p className={styles["source-type"]}>
                  <img
                    src={getTypeIconSrc(DATA_SOURCE_TYPES, dataSourceType)}
                    alt="Data source type"
                  />
                  <span>{dataSourceType}</span>
                </p>
                <div className={styles["source-id"]}>
                  <span>{sourceId}</span>
                  {importProcess && (
                    <StatusView status={getStatusSource(importProcess, nativeStatus)} />
                  )}
                </div>
                <p
                  className={styles["source-description"]}
                  title={dataSourceDescription}
                >
                  {dataSourceDescription}
                </p>
                <p className={styles["identifier"]} title={identifier}>
                  {identifier}
                </p>
              </div>
              <div
                className={clsx(
                  styles["source-info"],
                  isUser() ? styles["source-info-user"] : ""
                )}
              >
                {sourceDetailInfo.map((item) =>
                  item.label === INSTALLED_APPS ? (
                    <div className={styles["info-item"]} key={item.label}>
                      <p className={styles["info-label"]}>{item.label}</p>
                      <div className={styles["view-detail-app"]}>
                        {appsInstalledList.length !== 0 && (
                          <ViewAppDetail appList={appsInstalledList} />
                        )}
                        <span className={styles["info-value"]}>
                          <strong>{item.value}</strong>
                        </span>
                      </div>
                    </div>
                  ) : (
                    <Fragment key={item.label}>
                      <ItemResultInline
                        label={item.label}
                        value={item.value}
                        fwBold={item.fwBold}
                      />
                      {item?.children?.length > 0 &&
                        item.children.map((child) => (
                          <div
                            className={styles["info-item-child"]}
                            key={child.label}
                          >
                            <ItemResultInline
                              label={child.label}
                              value={child.value}
                            />
                          </div>
                        ))}
                    </Fragment>
                  )
                )}
              </div>
            </div>
            <div className={styles["source-item-footer"]}>
              {!hiddenBtnViewLog && (
                <Button
                  name="View Log"
                  className="btn-medium"
                  handleClick={onViewLog}
                  isDisabled={disableBtnViewLog}
                />
              )}
              <Button
                isDisabled={jobCategory === JOB_CATEGORY.draft.label}
                name="Go to source"
                className="btn-medium-blue"
                handleClick={() => goToReviewScreen(sourceDetail)}
              />
            </div>
          </Fragment>
        ) : (
          <div className={styles["no-result"]}>
            <EmptyPage
              messages="Please select a Data Source to view details"
              size="md"
            />
          </div>
        )}
      </div>
    </div>
  );
};

DataSourceCard.propTypes = {
  isLoading: PropTypes.bool,
  disableBtnViewLog: PropTypes.bool,
  hiddenBtnViewLog: PropTypes.bool,
  totalRecord: PropTypes.number,
  onEditSource: PropTypes.func,
  sourceDetail: PropTypes.shape({
    analystName: PropTypes.string,
    importedDate: PropTypes.string,
    custodianName: PropTypes.string,
    dataSourceDescription: PropTypes.string,
    dataSourceType: PropTypes.string,
    sourceId: PropTypes.string,
    identifier: PropTypes.string,
    jobCategory: PropTypes.string,
    importProcess: PropTypes.number,
    nativeStatus: PropTypes.number,
    info: PropTypes.shape({
      appsInstalled: PropTypes.string,
      attachments: PropTypes.number,
      chat: PropTypes.number,
      iMessage: PropTypes.number,
      emailAttachments: PropTypes.number,
      emails: PropTypes.number,
      messages: PropTypes.number,
      photos: PropTypes.number,
      smsMms: PropTypes.number,
      videos: PropTypes.number,
    }),
  }),
  getStatusSource: PropTypes.func,
  onViewLog: PropTypes.func,
};

export default DataSourceCard;
