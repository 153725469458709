import axios, { authHeader, getAbortController } from "./ApiConfig";

const getProjectDetailApi = (ProjectId) =>
  axios.get(`/project/${ProjectId}`, {
    headers: authHeader(),
    params: {
      TimeZoneOffset: new Date().getTimezoneOffset(),
    },
    signal: getAbortController().signal,
  });

const getSelectListProject = () =>
  axios.get(`/project/selectList`, {
    headers: authHeader(),
  });

export { getProjectDetailApi, getSelectListProject };
