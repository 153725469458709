import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useOnClickOutside } from "hook/click-outside";

// Components
import { Button } from "../button/Button";
import FilterOption from "./filter-option/FilterOption";

// Styles
import styles from "./DropdownFilter.module.scss";

const DropdownFilter = ({
  filterTitle = "",
  subTitle = "",
  filterOption = [],
  handleSaveFilter = () => {},
}) => {
  const dropdownRef = useRef(null);
  const optionRef = useRef(null);

  const [isShowOption, setIsShowOption] = useState(false);
  const [dataSelected, setDataSelected] = useState([]);

  useOnClickOutside(optionRef, () => resetState(), dropdownRef?.current);

  const resetState = () => {
    setIsShowOption(false);
    setDataSelected(
      filterOption.map((item) => ({
        key: item.key,
        data: item.checkedList,
      }))
    );
  };

  const getData = (result) => {
    setDataSelected(
      dataSelected.map((item) =>
        item.key === result.key ? { ...item, data: result.data } : item
      )
    );
  };

  useEffect(() => {
    setDataSelected(
      filterOption.map((item) => ({
        key: item.key,
        data: item.checkedList.filter((value) => value !== item.key),
      }))
    );
  }, [filterOption]);

  return (
    <div className={styles["wrap"]}>
      <div
        className={styles["filter-title"]}
        ref={dropdownRef}
        onClick={() => setIsShowOption(!isShowOption)}
      >
        <img
          src="/images/icons/filter-icon.svg"
          alt="filter"
          className={styles["filter-icon"]}
        />
        <span>
          <h5>{filterTitle}</h5>
          <img
            src="/images/icons/arrow-down.svg"
            alt="Arrow down"
            className={styles["arrow-icon"]}
          />
        </span>
      </div>
      {isShowOption && (
        <div className={styles["menu-filter"]} ref={optionRef}>
          <div className={styles["menu-filter-header"]}>
            <h5>{filterTitle}</h5>
            <p>{subTitle}</p>
            <img
              src="/images/close-modal-icon.png"
              alt="close"
              className={styles["close-icon"]}
              onClick={resetState}
            />
          </div>
          <div className={styles["menu-filter-body"]}>
            {filterOption.map((item) => (
              <FilterOption
                key={item.key}
                filterLabel={item.label}
                filterValue={item.key}
                options={item.options}
                listDataChecked={item.checkedList}
                getData={getData}
              />
            ))}
          </div>
          <div className={styles["menu-filter-footer"]}>
            <Button
              name="Cancel"
              className="btn-medium"
              handleClick={resetState}
            />
            <Button
              name="Save"
              className="btn-medium-blue"
              handleClick={() => {
                const dataResult = {};
                dataSelected.forEach((item) => {
                  dataResult[`${item.key}`] = item.data;
                });
                handleSaveFilter(dataResult);
                resetState();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

DropdownFilter.propTypes = {
  filterTitle: PropTypes.string,
  subTitle: PropTypes.string,
  filterOption: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      option: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
          icon: PropTypes.string,
        })
      ),
      checkedList: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  handleSaveFilter: PropTypes.func,
};

export default DropdownFilter;
