const downloadFileWithFetch = async (fetchApiPromise, fileName) => {
  try {
    const StreamSaver = window.streamSaver;
    StreamSaver.mitm = `${window.location.origin}/lib/streamsaver/mitm.html`;
    const fileStream = StreamSaver.createWriteStream(fileName);

    const response = await Promise.resolve(fetchApiPromise);

    if (!response.ok) {
      throw new Error('Download failed');
    }

    const readableStream = response.body;

    if (window.WritableStream && readableStream.pipeTo) {
      // This is more efficient and preferred if supported
      await readableStream.pipeTo(fileStream);
    } else {
      // Fallback for older browsers
      const writer = fileStream.getWriter();
      const reader = readableStream.getReader();

      const pump = async () => {
        const { done, value } = await reader.read();
        if (done) {
          writer.close();
          return;
        }
        await writer.write(value);
        pump();
      }

      pump();
    }

  } catch (error) {
    console.log(error);
  }
};

const getFileNameFromContentDisposition = (contentDisposition) => {
  if (!contentDisposition) {
      return '';
  }

  const fileName = contentDisposition.split(';')
      .map((item) => item.trim())
      .filter((item) => item.startsWith('filename='))
      .map((item) => item.split('=')[1])[0];

  return fileName ? fileName.replace(/['"]/g, '') : '';
};

export {
  getFileNameFromContentDisposition,
  downloadFileWithFetch
};
