import axios, { authHeader, getAbortController } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const getIdentifiersApi = (projectId, params = {}) =>
  axios(`/identifier/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    signal: getAbortController().signal,
    data: params,
  });

const removePreferredNameApi = (projectId, identifier, preferredName) =>
  axios(`/identifier/${projectId}/removePreferredName`, {
    headers: authHeader(),
    method: METHOD_API.get,
    signal: getAbortController().signal,
    params: {
      Identifier: identifier,
      PreferredName: preferredName,
    },
  });

const assignIdentifierApi = (projectId, identifier, entityId) =>
  axios(`/identifier/${projectId}/assignIdentifier`, {
    headers: authHeader(),
    method: METHOD_API.get,
    signal: getAbortController().signal,
    params: {
      Identifier: identifier,
      entityID: entityId,
    },
  });

const getSourceTypeApi = (projectId) =>
  axios(`/identifier/${projectId}/sourceType`, {
    headers: authHeader(),
    method: METHOD_API.get,
  });

const exportExcelIdentifierApi = (projectId, params) =>
  axios(`/identifier/${projectId}/exportExcel`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: params,
    responseType: "blob",
  });

const getEntitySelectionApi = (projectId) =>
  axios(`/entityManagement/${projectId}/getEntitySelection`, {
    headers: authHeader(),
    method: METHOD_API.get,
    signal: getAbortController().signal,
  });

export {
  getIdentifiersApi,
  removePreferredNameApi,
  assignIdentifierApi,
  getEntitySelectionApi,
  getSourceTypeApi,
  exportExcelIdentifierApi,
};
