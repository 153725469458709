import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";

// Components
import { Button } from "components/shared/button/Button";

// Constants
import { COMMON_TEXT } from "constants/Common";

// Styles
import styles from "./AddSearchModal.module.scss";
import { PopupConfirm } from "../popup/PopupConfirm";

const AddSearchModal = (props) => {
  const {
    isShow = false,
    onHide = () => {},
    onSubmit = () => {},
  } = props;

  const [searchTerms, setSearchTerms] = useState("");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const onShowConfirmPopup = () => setShowConfirmPopup(true);
  const onHideConfirmPopup = () => setShowConfirmPopup(false)

  const handleSubmit = async (data) => {
    const requestedTermsList = searchTerms.split('\n').filter(term => term.trim() !== '')
    const searchTermsObject = {
      searchTerms: requestedTermsList.map(requestedTerm => ({
        requestedTerm: requestedTerm
      }))
    };
    
    const params = {
      ...data,
      searchTermItems: searchTermsObject
    };

    onSubmit(params);
    onHideModal();
  };

  const handleInputSearch = (e) => {
    setSearchTerms(e.target.value);
  };

  const onHideModal = () => {
    onHide();
  };

  const confirmPopup = {
    value: 2,
    content: "Your unsaved search terms will be lost. Are you sure you want to continue?",
    textConfirm: COMMON_TEXT.confirm,
    textReject: COMMON_TEXT.cancel,
    type: "confirm",
    handleSubmit: () => {
        onHideModal();
        onHideConfirmPopup();
    }
  };

  return (
    <>
      <PopupConfirm
        isShow={showConfirmPopup}
        handleClose={() => onHideConfirmPopup()}
        handleSubmit={confirmPopup.handleSubmit}
        content={confirmPopup.content}
        textConfirm={confirmPopup.textConfirm}
        type={confirmPopup.type}
        textReject={confirmPopup.textReject}
        handleReject={() => onHideConfirmPopup()}
      />
      <Modal
        show={isShow && !showConfirmPopup}
        onHide={onHide}
        centered
        backdrop="static"
        className={styles["wrap"]}
      >
        <ModalHeader className={styles["modal-header"]}>
          <h3 className={styles["title"]}>
            {"Add Search Terms"}
          </h3>
          <div className={styles["note-tooltip-group"]}>
            <img className={styles["note-tooltip"]} src="/images/note.svg" alt="note" />
            <span className={styles["tooltip-description"]}>
              To add multiple search terms, place each search term on a separate line.
            </span>
          </div>
          <div onClick={onHide} className={styles["close-icon"]}>
            <img src="/images/close-modal-icon.png" alt="Close" />
          </div>
        </ModalHeader>
        <ModalBody className={styles["modal-body"]}>
          <textarea
            className={styles["sb-input"]}
            value={searchTerms}
            onChange={handleInputSearch}
            placeholder="Input search terms (press Enter for a new line)"
          />
        </ModalBody>
        <ModalFooter className={styles["modal-footer"]}>
          <div className={styles["btn-control"]}>
            <Button name="Cancel" handleClick={searchTerms === "" ? onHideModal : onShowConfirmPopup} />
            <Button
              name={"Add Terms"}
              className="btn-primary-fill"
              handleClick={handleSubmit}
              isDisabled={false}
            />
          </div>
        </ModalFooter>
      </Modal>
    </>
  );  
};

AddSearchModal.propTypes = {
  isShow: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func
};

export default AddSearchModal;