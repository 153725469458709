export const mapSearchHitError = (data) => {
    let errorMessage = '';

    //this first condition should not typically happen
    if (!data || !data.errorCode || !data.message) {
      errorMessage = 'An error occured while obtaining search hits.';
    } 
    else if (data.errorCode === 'SearchTermSummaryFormatException') {
      errorMessage = `Error obtaining persistent highlights: ${data.message}`;
    }

    return errorMessage
  }