import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import { Button } from "components/shared/button/Button";
import TagModal from "components/tag-management/tag-modal/TagModal";
import MenuSelectTag from "../menu-select-tag/MenuSelectTag";

// Constants and Helpers
import { COMMON_TEXT, EXPORT_TAG } from "constants/Common";
import { TAG_ACTION } from "constants/Constants";

// Store
import { setCanExecuteHotKey } from "store/TagReducer";

// Styles
import styles from "./TagControl.module.scss";

const TagControl = (props) => {
  const {
    handleSaveSelectTags,
    handleIsAddTag,
    disableTag = false,
    disableTagButton,
    disableApplyButton = false,
    disableRemoveButton,
  } = props;

  const dispatch = useDispatch();

  const [tagSelected, setTagSelected] = useState([]);
  const [showAddTagTab, setShowAddTagTab] = useState(false);
  const [isShowMenuTag, setIsShowMenuTag] = useState(false);
  const [isShowMenuUnTag, setIsShowMenuUntag] = useState(false);

  const { tags = [], isExecutingTag } = useSelector((state) => state.tag);

  const selectTags = tags.selectTags.filter(
    (tag) => tag.tagID !== EXPORT_TAG.id
  );

  const handleSelectTag = (event, tagID) => {
    const { checked } = event.currentTarget;
    setTagSelected((prev) =>
      checked ? [...prev, tagID] : prev.filter((val) => val !== tagID)
    );
  };

  useEffect(() => handleIsAddTag(showAddTagTab), [showAddTagTab]);

  useEffect(() => {
    dispatch(setCanExecuteHotKey(!showAddTagTab));
  }, [showAddTagTab]);

  useEffect(() => {
    setTagSelected([]);
  }, [isShowMenuTag, isShowMenuUnTag]);

  useEffect(() => {
    setIsShowMenuTag(false);
    setIsShowMenuUntag(false);
  }, [isExecutingTag]);

  return (
    !disableTag && (
      <Dropdown
        className={styles["tag-button"]}
        onToggle={() => {
          setShowAddTagTab(false);
          setIsShowMenuTag(!isShowMenuTag);
        }}
        show={isShowMenuTag}
      >
        <Dropdown.Toggle
          type="button"
          className={clsx(
            "mar_r16",
            styles["btn"],
            styles["btn-select-tag"],
            !disableTagButton && styles["active"]
          )}
          id="dropdown-basic2"
          disabled={disableTagButton || isExecutingTag}
        >
          <span>Tag</span>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className={clsx(
            styles["dropdown-menu"],
            styles["dropdown-menu-tag"]
          )}
        >
          {showAddTagTab ? (
            <TagModal
              handleClose={() => setShowAddTagTab(false)}
              isShow={showAddTagTab}
              type={COMMON_TEXT.add}
            />
          ) : (
          <>
            <div className={styles["menu-header"]}>
              <p className={styles["label-title"]}>Select Tags</p>
              <span
                  className={clsx(
                    styles["add-hot-tag"],
                    isExecutingTag ? styles["disabled"] : ""
                  )}
                  onClick={() => setShowAddTagTab(true)}
                >
                  + Add
                </span>
            </div>
            <MenuSelectTag
              tagList={selectTags}
              checkedList={tagSelected}
              onChange={handleSelectTag}
              disabled={isExecutingTag}
            />
            <div className={clsx(styles["dropdown-menu-action"])}>
              <Dropdown.Item className={`${styles["dropdown-item"]} ${styles["cancel-button"]}`}>
                <Button
                  name="Cancel"
                  handleClick={() => setTagSelected([])}
                  className="btn-secondary-md"
                />
              </Dropdown.Item>
              <Dropdown.Item
                className={`${styles["dropdown-item"]} ${styles["remove-button"]}`}
                disabled={!tagSelected?.length || disableRemoveButton}
              >
                <Button
                  handleClick={() =>
                    handleSaveSelectTags(tagSelected, TAG_ACTION.unTag)
                  }
                  isDisabled={!tagSelected?.length || disableRemoveButton}
                  className="btn-secondary-md"
                  isBorder={false}
                  name="Remove"
                />
              </Dropdown.Item>
              <Dropdown.Item
                  className={`${styles["dropdown-item"]} ${styles["apply-button"]}`}
                  disabled={!tagSelected?.length || disableApplyButton}
                >
                  <Button
                    handleClick={() =>
                      handleSaveSelectTags(tagSelected, TAG_ACTION.tag)
                    }
                    isDisabled={!tagSelected?.length || isExecutingTag || disableApplyButton}
                    className="btn-secondary-md"
                    isBorder={false}
                    name="Apply"
                  />
                </Dropdown.Item>
            </div>
          </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    )     
  );
};

TagControl.propTypes = {
  disableTag: PropTypes.bool,
  disableTagButton: PropTypes.bool,
  disableApplyButton: PropTypes.bool,
  disableRemoveButton: PropTypes.bool,
  handleSaveSelectTags: PropTypes.func,
  handleIsAddTag: PropTypes.func,
};

export default TagControl;
