import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./ItemResultInline.module.scss";

const ItemResultInline = ({ label = "", value = "", fwBold = false }) => {
  return (
    <dl key={label} className={styles["wrap"]}>
      <dt>{`${label}:`}</dt>
      <dd title={value}>{fwBold ? <strong>{value}</strong> : value}</dd>
    </dl>
  );
};

ItemResultInline.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fwBold: PropTypes.bool,
};

export default ItemResultInline;

