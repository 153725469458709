import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTags } from "services/TagManagementService";

export const fetchTagsList = createAsyncThunk(
  "tag/fetchTagsList",
  async (params) => {
    const tagData = await getTags(params);
    return tagData;
  }
);

const convertTagsWithDefaultHotKey = (tags = []) =>
  tags.map((item) => ({ ...item, hotKey: item.hotKey || "" }));

const tagSlice = createSlice({
  name: "tag",
  initialState: {
    tags: {
      selectTags: [],
      showTags: [],
    },
    loading: true,
    canExecuteHotKey: true,
    isExecutingTag: false,
  },
  reducers: {
    setTags: (state, action) => {
      state.tags = { ...state.tags, ...action.payload };
    },
    setCanExecuteHotKey: (state, action) => {
      state.canExecuteHotKey = action.payload;
    },
    setIsExecutingTag: (state, action) => {
      state.isExecutingTag = action.payload;
    },
  },
  extraReducers: {
    [fetchTagsList.pending]: (state) => {
      state.loading = true;
    },
    [fetchTagsList.rejected]: (state) => {
      state.loading = false;
    },
    [fetchTagsList.fulfilled]: (state, action) => {
      let {
        selectTags = [],
        showTags = [],
        allTags = [],
      } = action.payload.data;
      state.loading = false;
      state.tags = {
        selectTags: convertTagsWithDefaultHotKey(selectTags),
        showTags: convertTagsWithDefaultHotKey(showTags),
        allTags: convertTagsWithDefaultHotKey(allTags),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTags, setCanExecuteHotKey, setIsExecutingTag } =
  tagSlice.actions;
export const TagReducer = tagSlice.reducer;
