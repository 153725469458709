import React, { useState } from "react";
import clsx from "clsx";
import { BeatLoader } from "react-spinners";

// Services
import { hashingPasswordApi } from "services/StreemViewConfigServices";

// Constants
import { COLORS } from "constants/Common";

// Components
import { Button } from "components/shared/button/Button";

// Styles
import styles from "./HashingDatabasePassword.module.scss";

const HashingDatabasePassword = () => {
  const [projectId, setProjectId] = useState("");
  const [connectionString, setConnectionString] = useState("");
  const [password, setPassword] = useState("");
  const [hashingPasswordLoading, setHashingPasswordLoading] = useState(false);

  const fromGroupData = {
    projectId: {
      label: "Project ID",
      value: projectId,
    },
    connectionString: {
      label: "Connection String",
      value: connectionString,
    },
    password: {
      label: "Password",
      value: password,
    },
  };

  const handleHashingPassword = async () => {
    setHashingPasswordLoading(true);
    try {
      const result = await hashingPasswordApi({
        projectId,
        connectionString,
        password,
      });
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setHashingPasswordLoading(false);
    }
  };

  const handleOnChangeInput = (event, label) => {
    if (label === fromGroupData.connectionString.label)
      return setConnectionString(event.target.value);
    if (label === fromGroupData.password.label)
      return setPassword(event.target.value);
    return setProjectId(event.target.value);
  };

  const renderFromGroup = () =>
    Object.values(fromGroupData).map((item, index) => (
      <div className={clsx(styles["form-group"], "app-form-group")} key={index}>
        <label className="app-form-label">
          {item.label}
          <span className={styles["form-required"]}> *</span>
        </label>
        <input
          className={clsx("form-control", styles["form-control-input"])}
          placeholder={`Enter ${item.label}`}
          value={item.value}
          onChange={(event) => handleOnChangeInput(event, item.label)}
        />
      </div>
    ));

  return (
    <div className={styles["hashing-password"]}>
      <h3>Hashing Database Password</h3>
      {renderFromGroup()}
      {hashingPasswordLoading ? (
        <BeatLoader
          color={COLORS.black}
          loading={true}
          size={8}
          className={styles["beat-loader"]}
        />
      ) : (
        <Button
          name="Hashing Database Password"
          className="btn-primary-fill"
          handleClick={handleHashingPassword}
        />
      )}
    </div>
  );
};

export default HashingDatabasePassword;
