import axios, { authHeader, authHeaderFormData, getAbortController } from "./ApiConfig";
import { METHOD_API } from "constants/Common";

const getEntityApi = (projectId, bodyData = {}) =>
  axios(`/entityManagement/${projectId}/getAllEntity`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
  });

const getEntityDetailApi = (projectId, entityId) =>
  axios.get(`/entityManagement/${projectId}/${entityId}`, {
    headers: authHeader(),
  });

const deleteEntityApi = (projectId, entityIds) =>
  axios.delete(`/entityManagement/${projectId}`, {
    headers: authHeader(),
    data: { entityIds },
  });

const uploadAvatarApi = (projectId, entityId, formData) =>
  axios({
    method: METHOD_API.put,
    url: `/entityManagement/${projectId}/${entityId}/avatar`,
    data: formData,
    headers: authHeaderFormData(),
  });

const getSuggestionIdentifierApi = (projectId, entityName) =>
  axios.get(`/entityManagement/${projectId}/getSuggestionIdentifier`, {
    headers: authHeader(),
    params: { entityName },
  });

const createNewEntityApi = (projectId, bodyData) =>
  axios(`/entityManagement/${projectId}`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: bodyData,
  });

const exportExcelEntityApi = (projectId, bodyData) =>
  axios(`/entityManagement/${projectId}/exportExcel`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data: {
      ...bodyData,
      TimeOffset: new Date().getTimezoneOffset(),
    },
    responseType: "blob",
  });

const editEntityApi = (projectId, entityID, bodyData) =>
  axios(`/entityManagement/${projectId}/${entityID}`, {
    headers: authHeader(),
    method: METHOD_API.put,
    data: bodyData,
  });

const getStatisticApi = (projectId, identifiers = []) =>
  axios({
    method: METHOD_API.post,
    url: `/entityManagement/${projectId}/entityStatistics`,
    data: { identifiers },
    headers: authHeader(),
    signal: getAbortController().signal,
  });

const getEntityAvatar = (matterId, entityId) =>
  axios.get(`/entityManagement/${matterId}/${entityId}/avatar`, {
    headers: authHeader(),
    responseType: "blob",
  });

export {
  getEntityApi,
  deleteEntityApi,
  uploadAvatarApi,
  getSuggestionIdentifierApi,
  createNewEntityApi,
  editEntityApi,
  exportExcelEntityApi,
  getEntityDetailApi,
  getStatisticApi,
  getEntityAvatar,
};
