import axios, { authHeader, getAbortController } from "./ApiConfig";

// Helpers & Constants
import { checkBodyConvertTimeToUTC } from "helpers/ObjectHelper";
import { METHOD_API } from "constants/Common";

const getEmailReviewDetailApi = (projectId, emailId, params = {}) =>
  axios(`/emailReview/${projectId}/${emailId}`, {
    headers: authHeader(),
    method: METHOD_API.get,
    params: checkBodyConvertTimeToUTC(params),
    signal: getAbortController().signal,
    timeout: 600000,
  });

const executeTagEmailApi = (projectId, data = {}) =>
  axios(`/tag/${projectId}/emailTag`, {
    headers: authHeader(),
    method: METHOD_API.post,
    data,
    timeout: 0,
  });

const exportEmailDetailToExcelApi = (projectId, emailId, params = {}) => {
  params = checkBodyConvertTimeToUTC(params);
  return axios(
    `/emailReview/exportEmailDetailToExcel/${projectId}/${emailId}`,
    {
      headers: authHeader(),
      method: METHOD_API.get,
      params: {
        ...params,
        TimeOffset: new Date().getTimezoneOffset(),
      },
      responseType: "blob",
    }
  );
};
export {
  getEmailReviewDetailApi,
  executeTagEmailApi,
  exportEmailDetailToExcelApi,
};
