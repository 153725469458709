import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import InfoResultItem from "../info-result-item/InfoResultItem";
import TabList from 'components/shared/tab-list/TabList';
import { ParticipantsInformation } from "components/chat-email-information/participants/ParticipantsInformation";
import WordCloud from "components/word-cloud/WordCloud";
import EmojiCloud from "components/emoji-cloud/EmojiCloud";
import DataSourceInformation from "components/chat-email-information/data-source-info/DataSourceInformation";
import Tags from "components/tags/Tags";

//Constants and Helpers
import { PARAMETERS } from "constants/Common";
import { getInformationByKey } from "helpers/RenderInformationHelper";

// Styles
import styles from "./InformationTabContainer.module.scss";

const InformationTabContainer = ({ infoResultLabelList, dataType, itemInfo, filterData }) => {
  const tabs = [
    ...(filterData && Object.keys(filterData).length > 0 ? [{ id: 1, name: "Summary" }] : []),
    { id: filterData ? 2 : 1, name: "General" },
    { id: filterData ? 3 : 2, name: "Extended" },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  const isValidValue = (value) => value && value !== '' && !(typeof value === 'object' && Object.keys(value).length === 0);

  const renderSummaryTabContent = () => (
    <div className={styles["rw-wrap"]}>
      <div className={styles["review-info"]}>
        {filterData.dataSourceInfo?.getDataSourceInfo?.().length > 0 && (
          <DataSourceInformation
            isLoading={filterData.loading}
            dataSources={filterData.dataSourceInfo.getDataSourceInfo()}
          />
        )}
        {filterData.participantInfo?.participants?.length > 0 && (
          <ParticipantsInformation
            isLoading={filterData.loading}
            participants={filterData.participantInfo.participants}
            isRedColor={filterData.participantInfo.isRedColor}
          />
        )}
        {filterData.tagProps?.selectTags?.length > 0 && (
          <Tags
            isLoading={filterData.loading}
            isPreviewLoading={filterData.previewLoading}
            tagList={filterData.tagProps.selectTags}
            onSelectTags={filterData.tagProps.handleSelectedTagsChange}
          />
        )}
        {filterData.wordCloudProps?.data?.length > 0 && (
          <WordCloud
            listConversation={filterData.wordCloudProps.listConversation}
            isLoading={filterData.loading}
            data={filterData.wordCloudProps.data}
            onClick={filterData.wordCloudProps.onClick}
          />
        )}
        {filterData.emojiCloudProps?.data?.length > 0 && (
          <EmojiCloud
            listConversation={filterData.emojiCloudProps.listConversation}
            isLoading={filterData.loading}
            data={filterData.emojiCloudProps.data}
            onClick={filterData.emojiCloudProps.onClick}
          />
        )}
      </div>
    </div>
  );

  const renderGeneralTabContent = () => {
    const filteredItems = infoResultLabelList?.filter(item =>
      isValidValue(item.value) || isValidValue(getInformationByKey(item.key, itemInfo))
    );

    return (
      <div className={styles["general-tab-wrap"]}>
        {filteredItems.length > 0 ? (
          filteredItems.map((item) => (
            <InfoResultItem
              key={item.label}
              label={item.label}
              value={item.value || getInformationByKey(item.key, itemInfo)}
              isIcon={item.key === PARAMETERS.typeLabel}
              type={dataType}
            />
          ))
        ) : (
          <div className={styles["no-data"]}>No general information available</div>
        )}
      </div>
    );
  };

  const renderExtendedTabContent = () => (
    <div className={styles["extended-tab-wrap"]}>
      {itemInfo?.extendedMetadataFields?.length > 0 ? (
        itemInfo.extendedMetadataFields
          .filter((field) => isValidValue(field.fieldValue))
          .map((field) => (
            <InfoResultItem
              key={field.fieldName}
              label={field.fieldName}
              value={field.fieldValue}
              isIcon={false}
              type={dataType}
            />
          ))
      ) : (
        <div className={styles["no-data"]}>No extended information available.</div>
      )}
    </div>
  );

  const renderContent = () => {
    switch (tabs.find(tab => tab.id === currentTab)?.name) {
      case "General":
        return renderGeneralTabContent();
      case "Extended":
        return renderExtendedTabContent();
      case "Summary":
        return renderSummaryTabContent();
      default:
        return null;
    }
  };

  return (
    <div className={styles["info-data-container"]}>
      <div className={styles["info-tab-list"]}>
        <TabList
          tabList={tabs}
          currentTab={{ parentTab: currentTab, childTab: null }}
          size="md"
          onClickTab={({ parentTab }) => setCurrentTab(parentTab)}
        />
      </div>
      <div className={styles["info-result-list"]}>
        {renderContent()}
      </div>
    </div>
  );
};

InformationTabContainer.propTypes = {
  infoResultLabelList: PropTypes.array,
  dataType: PropTypes.string,
  itemInfo: PropTypes.object,
  filterData: PropTypes.object
};

export default InformationTabContainer;