//Intelligent Search Reducer

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quickSelect: {
    dataSources: [],
    dataTypes: [],
    entities: [],
    participants: [],
    tags: [],
  },
  typesChecked: [],
  paramSearch: {
    searchMsgInput: null,
    date: null,
    dataSources: [],
    dataTypes: [],
    entities: [],
    participants: [],
    tags: [],
    paginationParams: {
      columnSort: "DateTime",
      orderBy: "desc",
    },
    isNewest: false,
  },
  eventTimelines: [],
  eventTimelineDetail: {},
  itemDetailData: {},
  validTime: {
    years: [],
    months: [],
    currentYear: new Date().getFullYear(),
    validTimeLoading: true,
    validYearsLoading: true,
    keepDate: false,
  },
  paging: {
    totalRecords: 0,
    recordNumber: 0,
    pageNumber: 0,
  },
  isTimelineOrigin: false,
};

const eventTimelineSlice = createSlice({
  name: "eventTimeLine",
  initialState,
  reducers: {
    setQuickSelect: (state, action) => {
      state.quickSelect = {
        ...state.quickSelect,
        ...action.payload,
      };
    },
    setEventTimeline: (state, action) => {
      state.eventTimelines = action.payload;
    },
    setEventTimelineDetail: (state, action) => {
      state.eventTimelineDetail = {
        ...state.eventTimelineDetail,
        ...action.payload,
      };
    },
    setPaging: (state, action) => {
      state.paging = {
        ...state.paging,
        ...action.payload,
      };
    },
    setTypesChecked: (state, action) => {
      state.typesChecked = action.payload;
    },
    setParamSearchTimeLine: (state, action) => {
      state.paramSearch = {
        ...state.paramSearch,
        ...action.payload,
      };
    },
    setValidTime: (state, action) => {
      state.validTime = { ...state.validTime, ...action.payload };
    },
    resetStoreTimeline: () => {
      return initialState;
    },
    setItemDetailData: (state, action) => {
      state.itemDetailData = {
        ...state.itemDetailData,
        ...action.payload,
      };
    },
    setIsTimelineOrigin: (state, action) => {
      state.isTimelineOrigin = action.payload;
    },
    resetTimeLineDetail: (state) => {
      state.eventTimelineDetail = initialState.eventTimelineDetail;
    },
    resetItemDetailData: (state) => {
      state.itemDetailData = initialState.itemDetailData;
    },
  },
});
export const {
  setQuickSelect,
  setEventTimeline,
  setEventTimelineDetail,
  setPaging,
  setTypesChecked,
  resetStoreTimeline,
  setParamSearchTimeLine,
  setValidTime,
  setItemDetailData,
  setIsTimelineOrigin,
  resetTimeLineDetail,
  resetItemDetailData,
} = eventTimelineSlice.actions;
export const EventTimelineReducer = eventTimelineSlice.reducer;
