import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Components
import MatterAvatar from "components/shared/file-components/matter-avatar/MatterAvatar";

// Constant
import { COMMON_TEXT } from "constants/Common";

// Styles
import styles from "./MemberList.module.scss";

const MemberList = ({
  data = [],
  matterId,
  className = "",
  limitMember = 4,
  canHover = true,
  size = "sm",
  showFromBottom = false,
  isEntity = false,
  deferAvatarFetch = false,
}) => {
  const uniqueData = data
    ?.filter((item) => item.name?.trim().length > 0)
    .sort((a, b) => a?.name?.trim().localeCompare(b?.name?.trim()));

  const getName = (name = "") => (name ? name?.trim() : COMMON_TEXT.unknown);

  const renderAvatarComponent = (user) => (
    <MatterAvatar
      matterId={matterId}
      id={user.userId ?? user.id}
      useDefault={!user.hasAvatar}
      avatarType={isEntity ? "entity" : "user"}
      deferFetch={deferAvatarFetch}
    />
  );

  return (
    <div className={className}>
      <dd>
        <div
          className={clsx(
            styles["project-items_users"],
            styles[`project-items_users_${size}`]
          )}
        >
          {uniqueData.length > limitMember ? (
            <>
              {uniqueData.map(
                (user, index) =>
                  index < limitMember && (
                    <Fragment key={`exists-user-${index}`}>
                      <div
                        className={styles["project-detail-members_item"]}
                        key={index}
                      >
                        <a>
                          {renderAvatarComponent(user)}
                        </a>
                        {canHover && (
                          <ul
                            className={clsx(
                              styles["dropdown-menu"],
                              styles["dropdown-menu-info"],
                              styles[
                              showFromBottom
                                ? "dropdown-menu-show-bottom"
                                : ""
                              ]
                            )}
                            aria-labelledby="dropdownMenuLink"
                          >
                            <li>
                              <figure>
                                <a>
                                  {renderAvatarComponent(user)}
                                </a>
                              </figure>
                              <p className={styles["user-name"]}>
                                {getName(user.name)}
                              </p>
                            </li>
                          </ul>
                        )}
                      </div>
                      {index === limitMember - 1 && (
                        <div
                          className={clsx(
                            styles["project-detail-members_item"],
                            styles["avatar-count"]
                          )}
                        >
                          <p>+{uniqueData.length - limitMember}</p>
                          {canHover && (
                            <ul
                              className={clsx(
                                styles["dropdown-menu"],
                                styles["dropdown-menu-info"],
                                styles[
                                showFromBottom
                                  ? "dropdown-menu-show-bottom"
                                  : ""
                                ]
                              )}
                              aria-labelledby="dropdownMenuLink"
                            >
                              {uniqueData.map(
                                (user2, index2) =>
                                  index2 >= limitMember && (
                                    <li key={index2}>
                                      <figure>
                                        {renderAvatarComponent(user2)}
                                      </figure>
                                      <p className={styles["user-name"]}>
                                        {getName(user2.name)}
                                      </p>
                                    </li>
                                  )
                              )}
                            </ul>
                          )}
                        </div>
                      )}
                    </Fragment>
                  )
              )}
            </>
          ) : (
            <>
              {uniqueData.map((user, index) => (
                <div
                  className={styles["project-detail-members_item"]}
                  key={index}
                >
                  <a>
                    {renderAvatarComponent(user)}
                  </a>
                  {canHover && (
                    <ul
                      className={clsx(
                        styles["dropdown-menu"],
                        styles["dropdown-menu-info"],
                        styles[
                        showFromBottom ? "dropdown-menu-show-bottom" : ""
                        ]
                      )}
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <figure>
                          {renderAvatarComponent(user)}
                        </figure>
                        <p className={styles["user-name"]}>
                          {getName(user.name)}
                        </p>
                      </li>
                    </ul>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </dd>
    </div>
  );
};

MemberList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.string,
    name: PropTypes.string,
    avatar: PropTypes.string,
    hasAvatar: PropTypes.bool,
  })),
  matterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  size: PropTypes.string,
  limitMember: PropTypes.number,
  canHover: PropTypes.bool,
  showFromBottom: PropTypes.bool,
  isEntity: PropTypes.bool,
  deferAvatarFetch: PropTypes.bool,
};

export { MemberList };
