import { CONSOLIDATED_TYPE, } from "constants/Constants";
import { PARAMETERS_SAVED_SEARCH } from "constants/SavedSearchConstant";
import { convertDataSourceInfo } from "./DataSourceHelper";

const handleConsolidatedType = (key, data) => {
  let type = {};
  if (!data) return;
  const value = data[key];
  switch (key) {
    case PARAMETERS_SAVED_SEARCH.sources: {
      if (value?.length > 0) type = CONSOLIDATED_TYPE.source;
      break;
    }
    case PARAMETERS_SAVED_SEARCH.entities: {
      if (value?.length > 0) type = CONSOLIDATED_TYPE.entity;
      break;
    }
    case PARAMETERS_SAVED_SEARCH.dataTypes: {
      if (value?.length > 0) type = CONSOLIDATED_TYPE.datatype;
      break;
    }
    case PARAMETERS_SAVED_SEARCH.date: {
      if (!data.startDate && !data.endDate) type = {};
      else type = CONSOLIDATED_TYPE.date;
      break;
    }
    case PARAMETERS_SAVED_SEARCH.queryJSON: {
      if (value === "{}") return {};
      const query = data.queryJSON ? JSON.parse(data.queryJSON) : {};
      type = query?.globalSearch?.length > 0 ? CONSOLIDATED_TYPE.global : undefined
      break;
    }
    default:
      return {};
  }
  return type;
};

const handleDataQueries = (key, data, dataSourceList) => {
  if (!data) {
    return;
  }
  const value = data[key];
  switch (key) {
    case PARAMETERS_SAVED_SEARCH.sources: {
      return {
        sources: convertDataSourceInfo(
          dataSourceList,
          value?.map((item) => item.dataSourceId.toString())
        ),
      };
    }
    case PARAMETERS_SAVED_SEARCH.entities:
      return { entities: value };
    case PARAMETERS_SAVED_SEARCH.dataTypes:
      return { dataTypes: value };
    case PARAMETERS_SAVED_SEARCH.date:
      return {
        startDate: data.startDate,
        endDate: data.endDate,
      };
    case PARAMETERS_SAVED_SEARCH.queryJSON: {
      if (value === "{}") {
        return {};
      }
      const query = data.queryJSON ? JSON.parse(data.queryJSON) : {};
      let queries = {};
      if (query?.globalSearch?.length > 0) {
        queries = { globalSearch: value };
      }
      return queries;
    }
    default:
      return {};
  }
};

export { handleConsolidatedType, handleDataQueries };
