import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Components
import { Button } from "components/shared/button/Button";
import Checkbox from "components/shared/checkbox/Checkbox";
import { UploadAvatar } from "components/shared/popup/UploadAvatar";
import EntityStatistics from "components/shared/entity-statistics/EntityStatistics";
import AssociatedIdentifierView from "components/shared/associated-identifiers/identifier-view/AssociatedIdentifierView";

// Constants
import { SOCIAL_ENUM } from "constants/Constants";

// Styles
import styles from "./EntityCard.module.scss";
import MatterAvatar from "components/shared/file-components/matter-avatar/MatterAvatar";

const EntityCard = ({
  entityData = {},
  checked = false,
  getChecked = () => {},
  handleEditEntity = () => {},
  handleUploadAvatar = () => {},
  matterId,
}) => {
  const [showUpload, setShowUpload] = useState(false);
  const [isChecked, setIsChecked] = useState(checked);
  const [updatedAvatar, setUpdatedAvatar] = useState(null);

  const {
    entityID = 0,
    name = "",
    jobTitle = "",
    company = "",
    notes = "",
    image = "",
    identifier = [],
    messageCount = 0,
    imageCount = 0,
    tagsCount = 0,
    emailCount = 0,
    senderCount = 0,
    receiptCount = 0,
  } = entityData;

  // Making associate data
  const makeAssociatedData = () => {
    const data = { phones: [], emails: [], socials: [] };
    identifier.forEach((item) => {
      if (item.socialType === SOCIAL_ENUM.phone)
        data.phones = [...data.phones, item];
      else if (item.socialType === SOCIAL_ENUM.email)
        data.emails = [...data.emails, item];
      else data.socials = [...data.socials, item];
    });
    return data;
  };

  const handleSelectEntity = (event) => {
    setIsChecked(event.target.checked);
    getChecked(event.target.checked, entityID);
  };

  const onHandleUploadAvatar = (data) => {
    setUpdatedAvatar(data.url);
    handleUploadAvatar(data);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={styles["wrap"]}>
      <UploadAvatar
        isShow={showUpload}
        handleClose={() => setShowUpload(false)}
        handleUploadImage={onHandleUploadAvatar}
        entityId={entityID}
        avatarEntity={image}
      />
      <div className={styles["select-entity"]}>
        <Checkbox
          name="select entity"
          id={entityID}
          isChecked={isChecked}
          handleClick={handleSelectEntity}
          size="lg"
        />
      </div>
      <div className={styles["entity-avatar"]}>
        <figure>
          {updatedAvatar ? (
            <img src={updatedAvatar} alt="avatar" />
          ) : (
            <MatterAvatar matterId={matterId} id={entityID} avatarType="entity" />
          )}
        </figure>
        <span
          className={styles["entity-avatar-update"]}
          onClick={() => setShowUpload(true)}
        >
          <img src="/images/avatar-update.png" alt="upload" />
        </span>
      </div>
      <div className={styles["entity-info"]}>
        <p className={styles["entity-name"]} title={name}>
          {name}
        </p>
        <p className={styles["company"]} title={company}>
          {company || "-Company Name-"}
        </p>
        <p className={styles["job-title"]} title={jobTitle}>
          {jobTitle || "-Job Title-"}
        </p>
      </div>
      <div className={styles["entity-note"]}>
        <label className={styles["label"]}>Note</label>
        <p title={notes}>{notes || "-"}</p>
      </div>
      <div className={styles["entity-statistics"]}>
        <EntityStatistics
          statisticData={{
            messageCount,
            imageCount,
            tagsCount,
            emailCount,
            senderCount,
            receiptCount,
          }}
        />
      </div>
      <div className={styles["associated-identifier"]}>
        <AssociatedIdentifierView associated={makeAssociatedData()} />
      </div>
      <div className={styles["edit-button"]}>
        <Button name="Edit Person" handleClick={handleEditEntity} />
      </div>
    </div>
  );
};

EntityCard.propTypes = {
  entityData: PropTypes.shape({
    entityID: PropTypes.number,
    name: PropTypes.string,
    jobTitle: PropTypes.string,
    company: PropTypes.string,
    note: PropTypes.string,
    image: PropTypes.string,
    messageCount: PropTypes.number,
    imageCount: PropTypes.number,
    tagsCount: PropTypes.number,
    emailCount: PropTypes.number,
    senderCount: PropTypes.number,
    receiptCount: PropTypes.number,
    identifier: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.any,
        value: PropTypes.string,
        socialType: PropTypes.number,
      })
    ),
  }),
  checked: PropTypes.bool,
  getChecked: PropTypes.func,
  handleEditEntity: PropTypes.func,
  handleUploadAvatar: PropTypes.func,
  matterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EntityCard;
