import React from "react";
import PropTypes from "prop-types";

//helpers
import { checkCoordinate } from "helpers/LocationHelper";

//Components
import AzureMaps from "components/shared/azure-maps/AzureMaps";

//Styles
import styles from "./PreviewLocation.module.scss";

const PreviewLocation = (props) => {
  const { dataAttach, multipleLocation = false } = props;
  return (
    <div className={styles["location-container"]}>
      {multipleLocation ? (
        <>
          {dataAttach && dataAttach.length > 0 ? (
            <AzureMaps dataAttach={dataAttach} />
          ) : (
            <p>Location Information is not available.</p>
          )}
        </>
      ) : (
        <>
          {checkCoordinate(
            dataAttach.position.latitude,
            dataAttach.position.longitude
          ) ? (
            <AzureMaps
              dataAttach={[{ ...dataAttach.position }]}
              isMultiple={false}
            />
          ) : (
            <p>Location Information is not available.</p>
          )}
        </>
      )}
    </div>
  );
};

PreviewLocation.propTypes = {
  dataAttach: PropTypes.any,
  multipleLocation: PropTypes.bool,
};

export default PreviewLocation;
