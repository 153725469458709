import React, { useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

// Services
import {
  createIndexApi,
  loadProcedureApi,
  reloadHeatmapDataApi,
  updatePartyToContactApi,
} from "services/StreemViewConfigServices";

// Components
import ConfigWithProjectId from "components/streemview-config/config-general/ConfigWithProjectId";
import HashingDatabasePassword from "components/streemview-config/hashing-password/HashingDatabasePassword";
import ConvertCompressImage from "components/streemview-config/config-image/ConvertCompressImage";
import CreateProjectDatabase from "components/streemview-config/create-project-database/CreateProjectDatabase";
import CreateClient from "components/streemview-config/create-client/CreateClient";
import ConfigIndex from "components/streemview-config/config-index/ConfigIndex";

// Styles
import styles from "./StreemViewConfigPage.module.scss";
import MassTagSavedSearch from "components/streemview-config/mass-tag-saved-search/MassTagSavedSearch";

const StreemViewConfigPage = () => {
  const [selectId, setSelectId] = useState({
    createIndex: null,
    convertCompressImage: null,
    reloadHeatmapData: null,
    updatePartyToContact: null,
    reloadContactData: null,
  });

  const [createIndexLoading, setCreateIndexLoading] = useState(false);
  const [loadProcedureLoading, setLoadProcedureLoading] = useState(false);
  const [reloadHeatMapLoading, setReloadHeatMapLoading] = useState(false);
  const [updatePartyLoading, setUpdatePartyLoading] = useState(false);
  const [reloadContactLoading, setReloadContactLoading] = useState(false);

  // Store
  const { projects, loading: projectLoading } = useSelector(
    (state) => state.projects
  );

  const projectListOption = projects.map((project) => ({
    value: project.projectId,
    label: project.projectName,
  }));


  const handleCreateIndex = async (indexOptions) => {
    setCreateIndexLoading(true);
    try {
      const result = await createIndexApi(selectId.createIndex, {
        extractAttachments: indexOptions?.extractAttachments
      });
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setCreateIndexLoading(false);
    }
  };

  const handleReloadHeatMapData = async () => {
    setReloadHeatMapLoading(true);
    try {
      const result = await reloadHeatmapDataApi(selectId.reloadHeatmapData);
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setReloadHeatMapLoading(false);
    }
  };

  const handleUpdatePartyToContact = async () => {
    setUpdatePartyLoading(true);
    try {
      const result = await updatePartyToContactApi(selectId.updatePartyToContact);
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatePartyLoading(false);
    }
  };

  const handleLoadProcedure = async () => {
    setLoadProcedureLoading(true);
    try {
      const result = await loadProcedureApi();
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setLoadProcedureLoading(false);
    }
  };

  const handleReloadContactData = async () => {
    setReloadContactLoading(true);
    try {
      const result = await reloadHeatmapDataApi(
        selectId.reloadContactData,
        true
      );
      if (!result) return;
    } catch (error) {
      console.log(error);
    } finally {
      setReloadContactLoading(false);
    }
  };

  const handleSelectProject = (type, data) =>
    setSelectId({
      ...selectId,
      [type]: data.value,
    });

  return (
    <div className={clsx(styles["config-page"], "main")}>
      <div className={styles["config-header"]}>
        <h2>StreemView Configuration</h2>
      </div>
      <div className={styles["config-body"]}>
        {/* Create index UI */}
        <ConfigIndex
          titleConfig="Create and Run Index Job"
          isLoading={createIndexLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("createIndex", event)
          }
          handleRunConfig={handleCreateIndex}
          projectId={selectId.createIndex}
        />
        <hr />

        {/* Reload heatmap data */}
        <ConfigWithProjectId
          titleConfig="Reload Heatmap Data"
          isLoading={reloadHeatMapLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("reloadHeatmapData", event)
          }
          handleRunConfig={handleReloadHeatMapData}
          projectId={selectId.reloadHeatmapData}
        />
        <hr />

        {/* Reload source data */}
        <ConfigWithProjectId
          titleConfig="Reload Contact Data"
          isLoading={reloadContactLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("reloadContactData", event)
          }
          handleRunConfig={handleReloadContactData}
          projectId={selectId.reloadContactData}
        />
        <hr />

        {/* Update Party to Contact */}
        <ConfigWithProjectId
          titleConfig="Update Party To Contact"
          isLoading={updatePartyLoading}
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("updatePartyToContact", event)
          }
          handleRunConfig={handleUpdatePartyToContact}
          projectId={selectId.updatePartyToContact}
        />
        <hr />

        {/* Convert and compress image UI */}
        <ConvertCompressImage
          titleConfig="Run Thumbnail Generation/Attachment Extraction"
          isLoadingProject={projectLoading}
          options={projectListOption}
          onChangeProjectId={(event) =>
            handleSelectProject("convertCompressImage", event)
          }
          projectId={selectId.convertCompressImage}
        />
        <hr />

        {/* Load procedure UI */}
        <ConfigWithProjectId
          titleConfig="Load Procedure"
          isLoading={loadProcedureLoading}
          handleRunConfig={handleLoadProcedure}
          isShowProjectId={false}
        />
        <hr />

        {/* Hashing Database password UI */}
        <HashingDatabasePassword />
        <hr />

        {/* Create Project Database */}
        <CreateProjectDatabase />
        <hr />

        {/* Create Client */}
        <CreateClient />
        <hr />

        {/* Mass Apply Tag to Saved Search Hits */}
        <MassTagSavedSearch
          projectListOptions={projectListOption}
        />
        <hr />

      </div>
    </div>
  );
};

export default StreemViewConfigPage;
