import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDataSourceListApi } from "services/DataSourceService";
import { PAGINATION } from "constants/Common";
import { SORT_BY } from "constants/Constants";
import { COLUMN_NAME_SOURCE } from "constants/DataSourceConstant";

export const fetchDataSourceList = createAsyncThunk(
  "custodian/fetchDataSourceList",
  async (projectId) => await getDataSourceListApi(projectId)
);

export const paramSearchDataSourceInit = {
  search: "",
  paginationParams: {
    columnSort: COLUMN_NAME_SOURCE.createdDate.value,
    orderBy: SORT_BY.desc,
    pageNumber: PAGINATION.pageNumberDefault,
    pageSize: PAGINATION.screen.medium,
  },
  jobCategories: [],
  importProcesses: [],
  nativeStatuses: [],
};

export const sourceDetailInit = {
  collectionDate: null,
  createdDate: null,
  importedDate: null,
  analystName: "",
  custodianName: "",
  dataSourceDescription: "",
  dataSourceId: 0,
  dataSourceType: "",
  identifier: "",
  sourceId: "",
  jobCategory: "",
  importProcess: 1,
  nativeStatus: 1,
  hasLoadFile: false,
  loadFileType: 0,
  uploadProgress: 0,
  uploadedSize: 0,
  totalSize: 0,
  importStage: 0,
  info: {
    appsInstalled: "[]",
    attachments: 0,
    chat: 0,
    iMessage: 0,
    emailAttachments: 0,
    emails: 0,
    messages: 0,
    photos: 0,
    smsMms: 0,
    videos: 0,
  },
};

const initialState = {
  dataSourceManagement: {
    loading: true,
    editLoading: false,
    paramSearchDataSource: paramSearchDataSourceInit,
    sourceData: {
      sourceList: [],
      totalRecords: 0,
      suggestions: [],
    },
    sourceDetail: sourceDetailInit,
    importStatus: {
      userId: "",
      status: 0,
      stage: "",
      dataSourceId: 0,
      timeStamp: null,
      messageType: "",
      hasLoadFile: false,
    },
    importLog: {
      userId: "",
      category: "",
      message: "",
      dataSourceId: 0,
      timeStamp: null,
    },
    importStage: {
      stage: 0,
      dataSourceId: 0,
    }
  },
  dataSourceList: [],
  loadingSource: true,
};

const dataSourceSlice = createSlice({
  name: "dataSource",
  initialState,
  reducers: {
    setDataSourceLoading: (state, action) => {
      state.dataSourceManagement.loading = action.payload;
    },
    setParamSearchDataSource: (state, action) => {
      state.dataSourceManagement.paramSearchDataSource = {
        ...state.dataSourceManagement.paramSearchDataSource,
        ...action.payload,
      };
    },
    setSourceData: (state, action) => {
      state.dataSourceManagement.sourceData = {
        ...state.dataSourceManagement.sourceData,
        ...action.payload,
      };
    },
    setSourceDetail: (state, action) => {
      state.dataSourceManagement.sourceDetail = {
        ...state.dataSourceManagement.sourceDetail,
        ...action.payload,
      };
    },
    setImportStatus: (state, action) => {
      state.dataSourceManagement.importStatus = {
        ...state.dataSourceManagement.importStatus,
        ...action.payload,
      };
    },
    setImportStage: (state, action) => {
      state.dataSourceManagement.importStage = action.payload;
    },
    setImportLog: (state, action) => {
      state.dataSourceManagement.importLog = {
        ...state.dataSourceManagement.importLog,
        ...action.payload,
      };
    },
    setEditLoading: (state, action) => {
      state.dataSourceManagement.editLoading = action.payload;
    },
    resetStoreDataSource: (state) => {
      state.dataSourceManagement = initialState.dataSourceManagement;
    },
    updateDataSourceProgress: (state, action) => {
      const { dataSourceId, uploadProgress, totalSize, uploadedSize } = action.payload;
      const sourceIndex = state.dataSourceManagement.sourceData.sourceList.findIndex(source => source.dataSourceId === dataSourceId);
      if (sourceIndex !== -1) {
        state.dataSourceManagement.sourceData.sourceList[sourceIndex].uploadProgress = uploadProgress;
        state.dataSourceManagement.sourceData.sourceList[sourceIndex].totalSize = totalSize;
        state.dataSourceManagement.sourceData.sourceList[sourceIndex].uploadedSize = uploadedSize;
      }
      if (state.dataSourceManagement.sourceDetail.dataSourceId === dataSourceId) {
        state.dataSourceManagement.sourceDetail.uploadProgress = uploadProgress;
      }
    },
  },
  extraReducers: {
    [fetchDataSourceList.pending]: (state) => {
      state.loadingSource = true;
    },
    [fetchDataSourceList.rejected]: (state) => {
      state.loadingSource = false;
    },
    [fetchDataSourceList.fulfilled]: (state, action) => {
      state.loadingSource = false;
      state.dataSourceList = action.payload.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setDataSourceLoading,
  setParamSearchDataSource,
  setSourceData,
  setSourceDetail,
  setImportStatus,
  setImportStage,
  setImportLog,
  resetStoreDataSource,
  setEditLoading,
  updateDataSourceProgress,
} = dataSourceSlice.actions;
export const DataSourceReducer = dataSourceSlice.reducer;
