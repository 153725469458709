import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Styles
import styles from "./SearchFilter.module.scss";

const SearchFilter = (props) => {
  const {
    placeholder = "",
    value = "",
    onChange = () => {},
    handleClearSearch = () => {},
    size = "", //sm,md,lg
    onFocus = () => {},
    onSearch,
    hideSearchIcon = false,
  } = props;

  const [showClearIcon, setShowClearIcon] = useState(false);

  useEffect(() => {
    if (value.trim().length > 0) setShowClearIcon(true);
    else setShowClearIcon(false);
  }, [value]);

  return (
    <div className={clsx(styles["search-filter"], styles[size])}>
      <input
        type="text"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        className={styles["search-box"]}
        onFocus={onFocus}
      />
      {showClearIcon && (
        <img
          onClick={handleClearSearch}
          className={clsx(
            styles["clear-icon"],
            hideSearchIcon ? styles["margin-position"] : ""
          )}
          src="/images/clear-search.svg"
          alt="Clear Icon"
        />
      )}
      {!hideSearchIcon && (
        <img
          className={styles["search-icon"]}
          src="/images/search-icon.svg"
          alt="Search Icon"
          onClick={onSearch}
        />
      )}
    </div>
  );
};

SearchFilter.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  size: PropTypes.string,
  hideSearchIcon: PropTypes.bool,
  onChange: PropTypes.func,
  handleClearSearch: PropTypes.func,
  onFocus: PropTypes.func,
  onSearch: PropTypes.func,
};

export default SearchFilter;
