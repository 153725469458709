import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

// Constants
import { COMMON_TEXT } from "constants/Common";

// Styles
import styles from "./CombineSearch.module.scss";

export const CombineSearch = (props) => {
  const { queryJSON, condition } = props;

  const query = JSON.parse(queryJSON);

  const newArr = query?.map((item) => item.SearchName);
  newArr?.splice(1, 0, condition);

  const renderClassName = (item) =>
    item.length > 25
      ? clsx(styles["title"], styles["title-dot"])
      : styles["title"];

  return (
    <>
      {newArr?.map((item, index) => (
        <div
          key={index}
          className={clsx(
            styles["combine"],
            styles["container"],
            ([COMMON_TEXT.and, COMMON_TEXT.or].includes(item) ||
              item.length < 25) &&
              styles["container-fluid"]
          )}
        >
          <div className={styles["content"]}>
            <p
              className={renderClassName(item)}
              title={item.length > 25 ? item : ""}
            >
              {item}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

CombineSearch.propTypes = {
  queryJSON: PropTypes.string,
  condition: PropTypes.string,
};
