import { getAddressFromLocation } from "services/CommonService";

export const checkCoordinate = (latitude, longitude) => {
  try {
    return (
      !isNaN(parseFloat(longitude)) &&
      !isNaN(parseFloat(latitude)) &&
      181 > parseFloat(longitude) > -181 &&
      91 > parseFloat(latitude) > -91
    );
  } catch (error) {
    return false;
  }
};

export const getAddressAzureMap = async (latitude, longitude) => {
  if (!latitude || !longitude) return;
  const latitudeMap = parseFloat(latitude) || 0;
  const longitudeMap = parseFloat(longitude) || 0;
  let address = "";

  try {
    const { data } = await getAddressFromLocation(latitudeMap, longitudeMap);
    if (!data) return;
    const { freeformAddress, countryCodeISO3 } = data.addresses[0].address;
    if (!freeformAddress || !countryCodeISO3) return;
    address = `${freeformAddress}, ${countryCodeISO3}`;
    return address;
  } catch (error) {
    console.log(error);
  }
};
