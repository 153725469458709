import { STATUS_IMPORT_BLOB } from "constants/Common";

export const convertDataSourceInfo = (allDataSource = [], listSourceId = []) =>
  allDataSource?.filter((item) =>
    listSourceId?.includes(item.dataSourceID.toString())
  );

export const handleTotalFileImport = (files = []) => {
  if (files.length === 0) return;
  const bytes = files.map((item) => item?.size || 0);
  const blobBytes = bytes?.reduce((x, y) => x + y) || 0;
  const blobCount = bytes?.length || 0;
  return { blobBytes, blobCount };
};

export const getTotalSizeInFiles = (files = []) => {
  let count = 0;
  files?.length > 0 && files?.forEach((item) => (count += item?.size || 0));
  return count;
};

export const getPercent = (id, listByte, importQueue) => {
  const uploadedSize = getTotalSizeInFiles(listByte);
  const totalSize = getTotalSizeInFiles(
    importQueue.find((item) => item.id === id)?.files || []
  );
  const currentPercent =
    (uploadedSize / totalSize) * 100;
  return {
    percent: currentPercent <= 100 ? currentPercent : 100,
    totalSize,
    uploadedSize
  };
};

export const findIdCanImport = (importQueue = [], currentImportId) => {
  const importsReady = importQueue.filter(
    (item) => item.status === STATUS_IMPORT_BLOB.ready && item.id !== currentImportId
  );
  return importsReady.length > 0 ? importsReady[0].id : "";
};
