import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

// Helpers
import { getProjectName } from "helpers/GetFileNameHelper";
import { toThousandsNumber } from "helpers/FormatterHelper";

// Constants
import {
  DATE_FORMAT_INFO,
  DELIMITER_TYPE,
  DOCUMENT_SIZE,
  EXPORT_TIME_FORMAT,
  EXPORT_TYPE,
} from "constants/ExportConstant";
import { DELETE_STATUS_TAG } from "constants/TagConstant";

// Styles
import styles from "./ExportPreview.module.scss";

const ExportPreview = (props) => {
  const {
    summaryData: {
      messageCount = 0,
      physicalFileCount = 0,
      emailCount = 0,
      otherTypeCount = 0,
      totalItems = 0,
      totalFile = 0,
    },
    exportConfiguration: {
      tagsSelected = [],
      typeExport = [],
      tagsExcluded = [],
      dateFormat = "",
      timeZone = 1,
      delimiter = "",
      rsmfDocumentSize = 1,
      emlDocumentSize = 1,
      scheduleTime = "",
    },
    isLoading = false,
  } = props;

  // Get data from store
  const projectList = useSelector((state) => state.projects.projects) || [];
  const { projectId } = useParams();
  const [exportTypeList, setExportTypeList] = useState([]);

  const summaryData = [
    {
      label: "Messages:",
      value: toThousandsNumber(messageCount),
    },
    {
      label: "Loose Files:",
      value: toThousandsNumber(physicalFileCount),
    },
    {
      label: "Emails:",
      value: toThousandsNumber(emailCount),
    },
    {
      label: "Other Data Type:",
      value: toThousandsNumber(otherTypeCount),
    },
  ];

  const summaryTotal = [
    {
      label: "Total items:",
      value: toThousandsNumber(totalItems),
    },
    {
      label: "Number of Files:",
      value: toThousandsNumber(totalFile),
    },
  ];

  const getExportType = () => {
    let result = typeExport.map((item) => {
      if (item === EXPORT_TYPE.exportDAT)
        return `Export DAT (${
          Object.values(DELIMITER_TYPE).find((item) => item.value === delimiter)
            ?.label
        })`;
      if (item === EXPORT_TYPE.exportNativeFiles)
        return "Export Native File(s)";
      if (item === EXPORT_TYPE.exportRSMF)
        return `Export RSMF (${
          Object.values(DOCUMENT_SIZE).find(
            (item) => item.value === rsmfDocumentSize
          )?.label
        })`;
      if (item === EXPORT_TYPE.exportEML)
        return `Export EML (${
          Object.values(DOCUMENT_SIZE).find(
            (item) => item.value === emlDocumentSize
          )?.label
        })`;
      return "";
    });
    if (scheduleTime)
      result = result.concat(`Schedule Export (${scheduleTime})`);
    setExportTypeList(result);
  };

  useEffect(() => {
    getExportType();
  }, [typeExport, delimiter, emlDocumentSize, rsmfDocumentSize, scheduleTime]);

  return (
    <div className={styles["export-preview"]}>
      <h3>Export Preview</h3>
      <div className={styles["export-preview-content"]}>
        <h2 className={styles["export-preview-name"]}>
          {getProjectName(projectList, projectId)}
        </h2>
        <div className={styles["export-summary"]}>
          <h3 className={styles["export-summary-title"]}>Export Summary</h3>
          {isLoading ? (
            <div className={styles["export-summary-loading"]}>
              <Spinner
                className={styles["spinner-border"]}
                animation="border"
                variant="primary"
              />
            </div>
          ) : (
            <>
              <ul className={styles["export-summary-info"]}>
                {summaryData.map((item, index) => (
                  <li
                    key={index}
                    className={styles["export-summary-info-item"]}
                  >
                    <span>{item.label}</span>
                    <span>{item.value}</span>
                  </li>
                ))}
              </ul>
              <hr />
              <ul className={styles["export-summary-info"]}>
                {summaryTotal.map((item, index) => (
                  <div key={index}>
                    {index === 1 && (
                      <span className={styles["note-info"]}>
                        (include incomplete thread)
                      </span>
                    )}
                    <li className={styles["export-summary-info-item"]}>
                      <span>{item.label}</span>
                      <span>{item.value}</span>
                    </li>
                  </div>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className={styles["export-config"]}>
          <h3 className={styles["export-config-title"]}>
            Export Configurations
          </h3>
          <label className={styles["export-config-label"]}>
            Tags Selected ({tagsSelected.length})
          </label>
          <p className={styles["export-config-value"]}>
            {tagsSelected.map((tag, index) => {
              return (
                <span
                  key={index}
                  className={
                    tag.deleteStatus === DELETE_STATUS_TAG.inActive
                      ? styles["through-text"]
                      : ""
                  }
                >
                  {index < tagsSelected.length - 1 ? `${tag.name}, ` : tag.name}
                </span>
              );
            })}
          </p>
          <label className={styles["export-config-label"]}>
            Tags Excluded ({tagsExcluded.length})
          </label>
          <p className={styles["export-config-value"]}>
            {tagsExcluded.map((tag, index) => {
              return (
                <span
                  key={index}
                  className={
                    tag.deleteStatus === DELETE_STATUS_TAG.inActive
                      ? styles["through-text"]
                      : ""
                  }
                >
                  {index < tagsExcluded.length - 1 ? `${tag.name}, ` : tag.name}
                </span>
              );
            })}
          </p>
          <ul className={styles["export-config-list"]}>
            {exportTypeList.map(
              (item, index) =>
                item && (
                  <li key={index} className={styles["export-config-list-item"]}>
                    <img
                      src="/images/icons/checked-icon-blue.svg"
                      alt="checked-icon"
                    />
                    <span className={styles["export-config-value"]}>
                      {item}
                    </span>
                  </li>
                )
            )}
          </ul>
          <label className={styles["export-config-label"]}>Date Format</label>
          <p className={styles["export-config-value"]}>
            {
              Object.values(DATE_FORMAT_INFO).find(
                (item) => item.value === dateFormat
              )?.label
            }
          </p>
          <label className={styles["export-config-label"]}>Time Format</label>
          <p className={styles["export-config-value"]}>
            {
              Object.values(EXPORT_TIME_FORMAT).find(
                (item) => item.value === timeZone
              )?.label
            }
          </p>
        </div>
      </div>
    </div>
  );
};

ExportPreview.propTypes = {
  summaryData: PropTypes.shape({
    messageCount: PropTypes.number,
    physicalFileCount: PropTypes.number,
    emailCount: PropTypes.number,
    otherTypeCount: PropTypes.number,
    totalItems: PropTypes.number,
    totalFile: PropTypes.number,
  }),
  exportConfiguration: PropTypes.shape({
    tagsSelected: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        deleteStatus: PropTypes.string,
      })
    ),
    tagsExcluded: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        deleteStatus: PropTypes.string,
      })
    ),
    typeExport: PropTypes.array,
    dateFormat: PropTypes.string,
    timeZone: PropTypes.number,
    delimiter: PropTypes.string,
    rsmfDocumentSize: PropTypes.number,
    emlDocumentSize: PropTypes.number,
    scheduleTime: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

export default ExportPreview;
