import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import clsx from "clsx";

// Helpers & Constants
import { EVENT_KEY_LISTENER, KEY_CODE } from "constants/Common";
import { uppercaseFirstLetterEachWord } from "helpers/FormatterHelper";

// Styles
import styles from "./DropdownSelector.module.scss";

export const DropdownSelector = (props) => {
  const {
    isBorder = true,
    type = "button",
    className = "",
    scrollable = true,
    itemList,
    onSelect,
    selectedItem,
    size, //sm, md, lg
    widthStyle = "", //auto
    isDisable,
    isLoading,
    isNormalLetter = false,
    allowAutoScroll = true,
    ...rest
  } = props;

  const [toggle, setToggle] = useState(false);
  const [focusItem, setFocusItem] = useState(selectedItem); // used for set focus item to display when still not set value
  const [selectByUpDown, setSelectByUpDown] = useState(false); // used for handle show toggle, prevent toggle always set true when select item by enter

  const dropdownRef = useRef(null);

  const onToggleMenuHandle = () => {
    if (isDisable) return;
    if (!selectByUpDown) {
      setToggle(!toggle);
    } else {
      setSelectByUpDown(false);
    }
  };

  useEffect(() => {
    if (selectedItem && scrollable) {
      let index =
        itemList && itemList.findIndex((item) => item.name === selectedItem);
      let element = document.getElementById(`item-${index}`);
      element && allowAutoScroll && element.scrollIntoView();
      setFocusItem(selectedItem);
    }
  }, [toggle]);

  const handleSelect = (selectedItem) => {
    setToggle(false);
    onSelect(selectedItem);
    setFocusItem(selectedItem?.name);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      EVENT_KEY_LISTENER.click,
      handleClickOutside,
      false
    );
    return () => {
      document.removeEventListener(
        EVENT_KEY_LISTENER.click,
        handleClickOutside,
        false
      );
    };
  }, []);

  const onKeyDown = (e) => {
    if (!toggle || !e.keyCode) return;
    const itemListSize = itemList.length;
    const currentFocusIndex = itemList.findIndex(
      (item) => item.name === focusItem
    );
    switch (e.keyCode) {
      case KEY_CODE.arrowUp:
        if (currentFocusIndex > 0) {
          setFocusItem(itemList[currentFocusIndex - 1].name);
          const element = document.getElementById(
            `item-${currentFocusIndex - 1}`
          );
          element?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "center",
          });
        }
        if (currentFocusIndex === 0) {
          setFocusItem(itemList[itemListSize - 1].name);
          const element = document.getElementById(`item-${itemListSize - 1}`);
          element?.scrollIntoView();
        }
        break;
      case KEY_CODE.arrowDown:
        if (currentFocusIndex < itemListSize - 1) {
          setFocusItem(itemList[currentFocusIndex + 1].name);
          const element = document.getElementById(
            `item-${currentFocusIndex + 1}`
          );
          element?.scrollIntoView({
            behavior: "auto",
            block: "nearest",
            inline: "center",
          });
        }
        if (currentFocusIndex === itemListSize - 1) {
          setFocusItem(itemList[0].name);
          const element = document.getElementById(`item-${0}`);
          element?.scrollIntoView();
        }
        break;
      case KEY_CODE.enter:
        onSelect(itemList[currentFocusIndex]);
        setSelectByUpDown(true);
        setToggle(false);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    setFocusItem(selectedItem);
  }, [selectedItem])

  return (
    <div
      className={clsx(
        styles["dropdown"],
        styles[size],
        widthStyle === "auto" ? styles["dropdown-auto"] : ""
      )}
      ref={dropdownRef}
      onKeyDown={onKeyDown}
    >
      <button
        {...rest}
        type={type}
        onClick={onToggleMenuHandle}
        className={clsx(
          className,
          isBorder ? styles["btn-border"] : styles["btn-border-none"],
          isDisable ? styles["disabled"] : ""
        )}
        disabled={isDisable}
      >
        {isLoading ? (
          <span>Loading...</span>
        ) : (
          <span>
            {isNormalLetter
              ? selectedItem
              : uppercaseFirstLetterEachWord(selectedItem)}
          </span>
        )}
        <img
          src={`/images/icons/arrow-down${
            size === "lg" || size === "md" ? "" : "-blue"
          }.svg`}
          alt="Arrow down"
        />
      </button>
      {toggle && (
        <ul className={styles["dropdown-selectors"]}>
          {itemList &&
            itemList.map((item, index) => (
              <li
                id={`item-${index}`}
                key={index}
                className={focusItem === item.name ? styles.selected : ""}
                onClick={() => handleSelect(item)}
              >
                {isNormalLetter
                  ? item.name
                  : uppercaseFirstLetterEachWord(item.name)}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

DropdownSelector.propTypes = {
  type: PropTypes.string,
  altIcon: PropTypes.string,
  isBorder: PropTypes.bool,
  isDisable: PropTypes.bool,
  scrollable: PropTypes.bool,
  className: PropTypes.string,
  itemList: PropTypes.array,
  selectedItem: PropTypes.string,
  size: PropTypes.string,
  widthStyle: PropTypes.string,
  isLoading: PropTypes.bool,
  isNormalLetter: PropTypes.bool,
  allowAutoScroll: PropTypes.bool,
  onSelect: PropTypes.func,
};
