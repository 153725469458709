import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SpinnerLoading from "components/shared/spinner-loading/SpinnerLoading";
import styles from "./SearchTerms.module.scss";
import { 
  ColumnsDirective, 
  ColumnDirective, 
  GridComponent, 
  VirtualScroll, 
  Sort, 
  Selection,
  Inject,
  Edit,
  Toolbar,
  ExcelExport,
  PdfExport
} from "@syncfusion/ej2-react-grids";

const SearchTerms = ({
  searchTerms,
  gridActionFiring = false,
  handleSearchTermGridAction = () => {}
}) => {
  const [forceReRender, setForceReRender] = useState(false);

  const settings = { type: 'Multiple' };
  const loadingIndicator = { indicatorType: 'Shimmer' };

  const editOptions = { allowEditing: true, allowDeleting: true };
  const toolbarOptions = ['Edit', 'Update', 'Delete', 'Cancel', 'ExcelExport', 'PdfExport'];

  let grid;

  const toolbarClick = (args) => {
    if (grid) {
      if (args.item.id.includes('excelexport')) {
        const excelExportProperties = {
          fileName: 'SearchTerms.xlsx',
          exportType: 'AllPages',
          columns: [
            { field: 'requestedTerm', headerText: 'Search Term', width: 350 },
            { field: 'messageHits', headerText: 'Message Hits', width: 100 },
            { field: 'conversationHits', headerText: 'Conversation Hits', width: 140 },
            { field: 'status', headerText: 'Status', width: 120 },
            { field: 'lastRan', headerText: 'Last Run Date', width: 180 },
          ],
        };
        grid.excelExport(excelExportProperties);
      }
      if (args.item.id.includes('pdfexport')) {
        const pdfExportProperties = {
          fileName: 'SearchTerms.pdf',
          exportType: 'AllPages'
        };
        grid.pdfExport(pdfExportProperties);
      }
    }
  };

  const renderSpinner = () => {
    return (
      gridActionFiring 
      ? <div className='spinner'>
          <SpinnerLoading />
        </div> 
      : <div className='empty-record'>
          <p>No search terms found</p>
        </div>
    );
  }

  const actionBegin = async (args) => {
    handleSearchTermGridAction(args);
  }

  const triggerReRender = () => {
    setForceReRender(prevState => !prevState);
  }

  useEffect(() => {
    triggerReRender();
  }, [searchTerms]);

  return (
    <div className={styles["search-terms-dashboard__table"]}>
      <GridComponent 
        dataSource={searchTerms ? searchTerms.slice().reverse() : searchTerms}
        allowSorting={true}
        enableVirtualization={true} 
        selectionSettings={settings} 
        enableHover={false} 
        editSettings={editOptions} 
        toolbar={toolbarOptions} 
        height={913} 
        actionBegin={actionBegin} 
        loadingIndicator={loadingIndicator} 
        emptyRecordTemplate={renderSpinner}
        key={forceReRender ? "ReRenderKey" : "Key"}
        allowExcelExport={true}
        allowPdfExport={true}
        toolbarClick={toolbarClick}
        ref={(g) => (grid = g)}
      >
        <ColumnsDirective>
          <ColumnDirective field='searchID' headerText='SearchID' visible={false} isPrimaryKey={true}/>
          <ColumnDirective field='requestedTerm' headerText='Search Term' autoFit={true} width='440' allowSorting={true}></ColumnDirective>
          <ColumnDirective field='messageHits' headerText='Message Hits' autoFit={true} isIdentity={true}></ColumnDirective>
          <ColumnDirective field='conversationHits' headerText='Conversation Hits' autoFit={true} isIdentity={true}></ColumnDirective>
          <ColumnDirective field='status' headerText='Status' autoFit={true} isIdentity={true}></ColumnDirective>
          <ColumnDirective field='lastRan' headerText='Last Run Date' autoFit={true} isIdentity={true}></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[VirtualScroll, Sort, Selection, Edit, Toolbar, ExcelExport, PdfExport]} />
      </GridComponent>
    </div>
  );
};

SearchTerms.propTypes = {
  searchTerms: PropTypes.array,
  gridActionFiring: PropTypes.bool,
  handleSearchTermGridAction: PropTypes.func,
};

export default SearchTerms;
